<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col-sm-12">
        <div class="d-flex justify-content-between">
          <h4 class="card-title">
            {{
              !canEdit && training
                ? nomeTreino
                : canEdit && training
                ? "Edição Treino"
                : "Novo Treino"
            }}
          </h4>
          <button
            type="button"
            class="btn btn-lg btn-outline-danger btn-icon"
            (click)="activeModal.close('Close click')"
          >
            <i class="fa fa-close"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-content">
    <div class="card-body">
      <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
        <aw-wizard-step
          *ngIf="!(!canEdit && training)"
          [stepTitle]="'Treino'"
          [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }"
        >
          <form #firstForm="ngForm" class="editForm" novalidate>
            <br />
            <div
              class="form-row"
              *ngIf="
                !training &&
                trainingModels.length > 0 &&
                !verificaTreinoDeAvaliacaoPreenchido() &&
                !isModel
              "
            >
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="nome"
                    >Você pode selecionar um treino modelo</label
                  >
                  <ng-select
                    [notFoundText]="'Nenhum treino encontrado'"
                    [items]="trainingModels"
                    bindLabel="name"
                    name="model"
                    placeholder="Selecionar Modelo"
                    id="basic-form-model"
                    [(ngModel)]="trainingModelselecionada"
                    (ngModelChange)="onselectModel()"
                    required="true"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="tipotreino">{{
                    ultimoTipoSelecionado
                      ? ultimoTipoSelecionado
                      : "Tipo de Treino"
                  }}</label>
                  <ng-select
                    [notFoundText]="'Nenhum tipo encontrado'"
                    [items]="tipos"
                    [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                    bindLabel="name"
                    name="model"
                    placeholder="Selecionar Tipo"
                    id="tipotreino"
                    [(ngModel)]="tipoSelecionado"
                    (ngModelChange)="toggleTipoTreino()"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div
              class="form-row"
              *ngIf="
                tipoSelecionado &&
                (tipoSelecionado.id == 2 || tipoSelecionado.id == 3)
              "
            >
              <div [ngClass]="group ? 'col-md-6 col-12' : 'col-12'">
                <div class="form-group mb-2">
                  <label for="volt"
                    >Voltas <span style="color: red">*</span></label
                  >
                  <div class="input-group">
                    <input
                      type="text"
                      autocomplete="off"
                      id="volt"
                      mask="0*"
                      class="form-control"
                      name="volt"
                      [(ngModel)]="newTraining.laps"
                      #volt="ngModel"
                      gt="0"
                      required
                    />
                  </div>
                  <small
                    class="form-text text-muted danger"
                    *ngIf="volt.errors?.required || volt.errors?.gt"
                    >Você deve informar a quantidade de voltas</small
                  >
                </div>
              </div>
              <div class="col-md-6 col-12" *ngIf="group">
                <div
                  class="form-group mb-2"
                  placement="top"
                  ngbTooltip="Válido para quando você ocultar as variáveis do último exercício."
                >
                  <label for="volt">Intervalo entre voltas</label>
                  <div class="input-group">
                    <ngb-timepicker
                      name="breakDuration"
                      [spinners]="false"
                      [seconds]="true"
                      [(ngModel)]="newTraining.breakTime"
                      required
                      #duration1="ngModel"
                    ></ngb-timepicker>
                    <small
                      class="form-text text-muted danger"
                      *ngIf="duration1.errors?.required"
                      >Você deve informar a duração do intervalo</small
                    >
                    <!--<input type="text" autocomplete="off" id="breakTime" mask="0*" class="form-control" name="breakTime" [(ngModel)]="newTraining.breakTime">-->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="form-row mb-2"
              *ngIf="tipoSelecionado && tipoSelecionado.id != 4 && !isModel"
            >
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="agend">Agendamento</label>
                  <div class="input-group">
                    <input
                      type="text"
                      autocomplete="off"
                      id="agend"
                      placeholder="00/00/0000"
                      class="form-control"
                      name="agend"
                      [(ngModel)]="agendamento"
                      ngbDatepicker
                      #agend="ngbDatepicker"
                      [container]="'body'"
                    />
                    <div class="input-group-append">
                      <button
                        type="button"
                        class="btn btn-secondary input-group-text"
                        (click)="agend.toggle()"
                      >
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-12">
                <div class="form-group">
                  <label for="agend">Vencimento</label>
                  <div class="input-group">
                    <input
                      type="text"
                      id="vencimento"
                      placeholder="00/00/0000"
                      class="form-control"
                      name="vencimento"
                      [(ngModel)]="vencimento"
                      ngbDatepicker
                      #venc="ngbDatepicker"
                      [container]="'body'"
                    />
                    <div class="input-group-append">
                      <button
                        type="button"
                        class="btn btn-secondary input-group-text"
                        (click)="venc.toggle()"
                      >
                        <i class="fa fa-calendar"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="inputAvaliacao"
                    >Nome <span style="color: red">*</span></label
                  >
                  <input
                    type="text"
                    autocomplete="off"
                    id="inputAvaliacao"
                    class="form-control"
                    name="inputAvaliacao"
                    [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                    [(ngModel)]="newTraining.name"
                    #inputAvaliacao="ngModel"
                    required
                  />
                  <small
                    class="form-text text-muted danger"
                    *ngIf="inputAvaliacao.errors?.required"
                    >Informe um nome para o treino</small
                  >
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="obst">Observações</label>
                  <textarea
                    id="obst"
                    rows="3"
                    class="form-control"
                    name="obst"
                    maxlength="500"
                    [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                    [(ngModel)]="newTraining.note"
                    #inputObs="ngModel"
                    placeholder="Você pode escrever observações sobre este treino"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <div class="d-md-none">
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-icon float-left"
                    (click)="activeModal.close('Close click')"
                  >
                    <i class="fa fa-arrow-left"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-icon float-right"
                    [disabled]="!verificaPreenchimentoTreino()"
                    awNextStep
                  >
                    <i class="fa fa-arrow-right"></i>
                  </button>
                </div>
                <div class="d-none d-md-block">
                  <button
                    type="button"
                    class="btn mr-2"
                    [ngClass]="canEdit ? 'btn-danger' : 'btn-secondary'"
                    (click)="activeModal.close('Close click')"
                  >
                    {{ canEdit ? "Cancelar" : "Voltar" }}
                  </button>
                  <button
                    type="button"
                    class="btn gradient-mint"
                    [disabled]="!verificaPreenchimentoTreino()"
                    awNextStep
                  >
                    {{ canEdit ? "Próximo Passo" : "Avançar" }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step
          [stepTitle]="'Exercícios'"
          [navigationSymbol]="{ symbol: '&#xf059;', fontFamily: 'FontAwesome' }"
        >
          <form
            #workForm="ngForm"
            class="editForm mb-2"
            style="
              border: 2px #92929257 dashed;
              padding-left: 7px;
              padding-right: 7px;
              padding-top: 5px;
              padding-bottom: 5px;
              border-radius: 50px;
            "
            novalidate
          >
            <ng-template [ngIf]="!group">
              <h4 class="head text-center mt-3">
                {{
                  !(!canEdit && training)
                    ? ultimoTipoSelecionado +
                      " - " +
                      (isEditingExercise
                        ? "Atualização Exercício"
                        : "Cadastro Exercícios")
                    : ultimoTipoSelecionado +
                      " - " +
                      (isEditingExercise
                        ? "Atualização Exercício"
                        : "Cadastro Exercícios")
                }}
              </h4>
            </ng-template>
            <ng-template [ngIf]="group">
              <div class="form-row mt-3">
                <div class="col-md-6 col-12">
                  <h4 class="head text-center">
                    {{
                      !(!canEdit && training)
                        ? ultimoTipoSelecionado +
                          " - " +
                          (isEditingExercise
                            ? "Atualização Exercício"
                            : "Cadastro Exercícios")
                        : ultimoTipoSelecionado +
                          " - " +
                          (isEditingExercise
                            ? "Atualização Exercício"
                            : "Cadastro Exercícios")
                    }}
                  </h4>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group mr-2 float-right">
                    <label for="ttt" class="mr-2">Ocultar Variáveis</label>
                    <ui-switch
                      class="switchery switchery-warning"
                      id="ttt"
                      [checked]="switchHideVariables"
                      (change)="changeSwitchVariables($event)"
                    ></ui-switch>
                  </div>
                </div>
              </div>
            </ng-template>
            <br />
            <div class="form-row">
              <div class="col-md-12 col-12">
                <div class="float-right d-none d-md-block" role="group">
                  <button
                    type="button"
                    class="btn btn-lg btn-warning mb-md-0"
                    (click)="openExercise()"
                  >
                    <i class="fa fa-plus"></i> Exercício
                  </button>
                </div>
                <div class="btn-group btn-group-lg d-md-none" role="group">
                  <button
                    type="button"
                    class="btn btn-lg btn-warning mb-md-0"
                    (click)="openExercise()"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div
                [ngClass]="
                  exercicioSelecionado
                    ? exercicioSelecionado &&
                      tipoSelecionado &&
                      tipoSelecionado.id != 4
                      ? 'col-md-3 col-12'
                      : 'col-md-4 col-12'
                    : 'col-md-6 col-12'
                "
              >
                <div class="form-group mb-2">
                  <label for="catselect">Selecione uma categoria</label>
                  <ng-select
                    [items]="categoriesAndExercicies"
                    [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                    [notFoundText]="'Nenhuma categoria encontrada'"
                    bindLabel="name"
                    name="catselect"
                    placeholder="Selecionar Categoria"
                    id="catselect"
                    [(ngModel)]="categoriaSelecionada"
                    (ngModelChange)="onselectCategory()"
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{ item.name }}</span>
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                    >
                      <span
                        >{{ item.name
                        }}<small class="text-muted float-right">{{
                          item.user ? "personal" : "plataforma"
                        }}</small></span
                      >
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div
                [ngClass]="
                  exercicioSelecionado
                    ? exercicioSelecionado &&
                      tipoSelecionado &&
                      tipoSelecionado.id != 4
                      ? 'col-md-3 col-12'
                      : 'col-md-5 col-12'
                    : 'col-md-6 col-12'
                "
              >
                <div class="form-group mb-2">
                  <label for="exercselect">Selecione um exercício</label>
                  <ng-select
                    [items]="exercicies"
                    [disabled]="
                      exercicies.length == 0 ||
                      verificaTreinoDeAvaliacaoPreenchido()
                    "
                    [notFoundText]="'Nenhum exercício encontrado'"
                    bindLabel="Exercise.name"
                    name="blable"
                    placeholder="Selecionar Exercício"
                    id="blable"
                    [(ngModel)]="exercicioSelecionado"
                    (ngModelChange)="onselectExercicio()"
                    required
                  >
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{ item.Exercise.name }}</span>
                    </ng-template>
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                      let-search="searchTerm"
                    >
                      <span
                        >{{ item.Exercise.name
                        }}<small class="text-muted float-right">{{
                          item.Exercise.user ? "personal" : "plataforma"
                        }}</small></span
                      >
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="col-md-3 col-12" *ngIf="exercicioSelecionado">
                <div class="form-group mb-2">
                  <label for="exercselect">Mídia</label>
                  <ul class="list-unstyled mb-0">
                    <li
                      class="d-inline-block mr-2"
                      style="vertical-align: middle"
                      *ngIf="
                        exercicioSelecionado.Exercise &&
                        exercicioSelecionado.Exercise.url_video
                      "
                    >
                      <div class="radio radio-sm">
                        <input
                          type="radio"
                          name="size-radio-1"
                          [value]="true"
                          id="size-sm"
                          (click)="switchMidia(true)"
                          [(ngModel)]="isVideo"
                        />
                        <label for="size-sm"
                          ><a
                            class="text-primary"
                            (click)="viewMidia(true, contentVideo, contentGif)"
                            >Vídeo</a
                          ></label
                        >
                      </div>
                    </li>
                    <li
                      class="d-inline-block"
                      style="vertical-align: middle"
                      *ngIf="
                        exercicioSelecionado.Exercise &&
                        exercicioSelecionado.Exercise.url_gif
                      "
                    >
                      <div class="radio radio-sm">
                        <input
                          type="radio"
                          name="size-radio-1"
                          [value]="false"
                          id="size-default"
                          (click)="switchMidia(false)"
                          [(ngModel)]="isVideo"
                        />
                        <label for="size-default"
                          ><a
                            class="text-primary"
                            (click)="viewMidia(false, contentVideo, contentGif)"
                            >GIF</a
                          ></label
                        >
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="col-md-3 col-12"
                *ngIf="
                  exercicioSelecionado &&
                  tipoSelecionado &&
                  tipoSelecionado.id != 4
                "
                placement="top"
                ngbTooltip="Você pode marcar este exercício como aquecimento. Assim ele será executado antes dos exercícios principais."
              >
                <div class="form-group mb-2">
                  <label for="exercselect">Aquecimento</label>
                  <div class="input-group">
                    <ui-switch
                      class="switchery switchery-warning"
                      id="aqc"
                      [checked]="switchAquecimentoMode"
                      (change)="switchAquecimento($event)"
                    ></ui-switch>
                  </div>
                </div>
              </div>
            </div>
            <ng-template
              [ngIf]="
                ((tipoSelecionado && tipoSelecionado.id == 1) ||
                  switchAquecimentoMode) &&
                !switchHideVariables
              "
            >
              <div class="form-row">
                <div
                  class="col-md-6 col-12"
                  [ngClass]="
                    tipoSelecionado &&
                    tipoSelecionado.id != 1 &&
                    switchAquecimentoMode
                      ? 'col-md-12 col-12'
                      : 'col-md-6 col-12'
                  "
                >
                  <div class="form-group mb-2">
                    <label for="series"
                      >Séries
                      <span
                        *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                        style="color: red"
                        >*</span
                      ></label
                    >
                    <div class="input-group">
                      <input
                        type="text"
                        id="series"
                        mask="0*"
                        class="form-control"
                        name="series"
                        [(ngModel)]="exercicie.series"
                        #series="ngModel"
                        gt="0"
                        required
                      />
                    </div>
                    <small
                      class="form-text text-muted danger"
                      *ngIf="
                        (series.errors?.required || series.errors?.gt) &&
                        tipoSelecionado &&
                        tipoSelecionado.id == 1
                      "
                      >Você deve informar a quantidade de séries</small
                    >
                  </div>
                </div>
                <div
                  class="col-md-6 col-12"
                  *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                >
                  <div class="form-group mb-2">
                    <label for="series">Associar</label>
                    <ng-select
                      [items]="listaExerciciosFiltrados"
                      placement="top"
                      ngbTooltip="Informe se deseja unificar um exercício com este. (bisets, trisets, etc)"
                      [notFoundText]="'Nenhum exercício encontrado'"
                      name="conjugado"
                      bindLabel="name"
                      bindValue="tempId"
                      placeholder="Selecionar Exercício"
                      id="conjugado"
                      [(ngModel)]="exercicie.conjugado"
                      (clear)="clearing()"
                      clearable="true"
                    ></ng-select>
                  </div>
                </div>
              </div>
              <div
                class="form-row"
                *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
              >
                <div class="col-md-2 col-12">
                  <div class="form-group mb-2">
                    <label for="rep">Tipo</label>
                    <div class="input-group">
                      <div class="d-md-none" role="group">
                        <ui-switch
                          class="switchery switchery-warning"
                          size="small"
                          id="bla"
                          [checked]="switchRepetitionAndDuration"
                          (change)="switchRepAndDur($event)"
                        ></ui-switch>
                      </div>
                      <div class="d-none d-md-block">
                        <ui-switch
                          class="switchery switchery-warning"
                          id="bla"
                          [checked]="switchRepetitionAndDuration"
                          (change)="switchRepAndDur($event)"
                        ></ui-switch>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-12">
                  <div class="form-group mb-2">
                    <label for="rep"
                      >{{
                        switchRepetitionAndDuration ? "Duração" : "Repetições"
                      }}<span style="color: red">*</span></label
                    >
                    <ng-template [ngIf]="!switchRepetitionAndDuration">
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          id="rep"
                          class="form-control"
                          name="rep"
                          [(ngModel)]="exercicie.repetitions"
                          maxlength="11"
                          #repetitions="ngModel"
                          required
                          aria-describedby="group-repetition"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-repetition"
                            >x</span
                          >
                        </div>
                      </div>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="
                          repetitions.errors?.required || repetitions.errors?.gt
                        "
                        >Você deve informar a quantidade de repetições</small
                      >
                    </ng-template>
                    <ng-template [ngIf]="switchRepetitionAndDuration">
                      <ngb-timepicker
                        name="timerDuration"
                        [spinners]="false"
                        [seconds]="true"
                        [(ngModel)]="exercicie.timeDuration"
                        required
                        #duration1="ngModel"
                      ></ngb-timepicker>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="duration1.errors?.required"
                        >Você deve informar a duração</small
                      >
                    </ng-template>
                  </div>
                </div>
                <div
                  [ngClass]="
                    exercicie.loadFeedback
                      ? 'col-md-3 col-12'
                      : 'col-md-5 col-12'
                  "
                >
                  <div class="form-group mb-2">
                    <div class="d-md-none" role="group">
                      <label for="carga">Carga Total</label>
                    </div>
                    <div class="d-none d-md-block">
                      <label for="carga">Carga sugerida total</label>
                    </div>
                    <div class="input-group">
                      <div
                        class="input-group-prepend"
                        placement="top"
                        ngbTooltip="Clique para informar peso corporal."
                      >
                        <div class="input-group-text" id="checkbox-addon1">
                          <div class="checkbox checkbox-sm">
                            <input
                              type="checkbox"
                              id="checkboxInputGroup"
                              [(ngModel)]="exercicie.corporalLoad"
                              (change)="zeraCarga()"
                              name="checkbox"
                            />
                            <label for="checkboxInputGroup"></label>
                          </div>
                        </div>
                      </div>
                      <input
                        type="text"
                        autocomplete="off"
                        id="carga"
                        class="form-control"
                        [disabled]="exercicie.corporalLoad"
                        name="carga"
                        [(ngModel)]="exercicie.load"
                        #carga="ngModel"
                        gt="0"
                        aria-describedby="checkbox-addon1"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="group-carga"
                          >KG</span
                        >
                      </div>
                    </div>
                    <small
                      class="form-text text-muted danger"
                      *ngIf="carga.errors?.required || carga.errors?.gt"
                      >Você deve informar a carga total</small
                    >
                  </div>
                </div>
                <div class="col-md-2 col-12" *ngIf="exercicie.loadFeedback">
                  <div class="form-group mb-2">
                    <label for="loadFeedback">Feedback Carga Total</label>
                    <div class="input-group">
                      <input
                        type="text"
                        autocomplete="off"
                        id="loadFeedback"
                        class="form-control"
                        name="loadFeedback"
                        [disabled]="true"
                        [value]="
                          exercicie.loadFeedback != null
                            ? exercicie.loadFeedback
                            : 'N/A'
                        "
                        aria-describedby="group-loadFeedback"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="group-loadFeedback"
                          >KG</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template
              [ngIf]="
                tipoSelecionado &&
                (tipoSelecionado.id == 2 || tipoSelecionado.id == 3) &&
                !switchHideVariables
              "
            >
              <div class="form-row">
                <div class="col-md-2 col-12">
                  <div class="form-group mb-2">
                    <label for="rep">Tipo</label>
                    <div class="input-group">
                      <div class="d-md-none" role="group">
                        <ui-switch
                          class="switchery switchery-warning"
                          size="small"
                          id="bla"
                          [checked]="switchRepetitionAndDuration"
                          (change)="switchRepAndDur($event)"
                        ></ui-switch>
                      </div>
                      <div class="d-none d-md-block">
                        <ui-switch
                          class="switchery switchery-warning"
                          id="bla"
                          [checked]="switchRepetitionAndDuration"
                          (change)="switchRepAndDur($event)"
                        ></ui-switch>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-12">
                  <div class="form-group mb-2">
                    <label for="rep"
                      >{{
                        switchRepetitionAndDuration ? "Duração" : "Repetições"
                      }}<span style="color: red">*</span></label
                    >
                    <ng-template [ngIf]="!switchRepetitionAndDuration">
                      <div class="input-group">
                        <input
                          type="text"
                          autocomplete="off"
                          id="rep"
                          class="form-control"
                          name="rep"
                          [(ngModel)]="exercicie.repetitions"
                          #repetitions="ngModel"
                          required
                          aria-describedby="group-repetition"
                          maxlength="11"
                        />
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-repetition"
                            >x</span
                          >
                        </div>
                      </div>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="
                          repetitions.errors?.required || repetitions.errors?.gt
                        "
                        >Você deve informar a quantidade de repetições</small
                      >
                    </ng-template>
                    <ng-template [ngIf]="switchRepetitionAndDuration">
                      <ngb-timepicker
                        name="timerDuration"
                        [spinners]="false"
                        [seconds]="true"
                        [(ngModel)]="exercicie.timeDuration"
                        required
                        #duration1="ngModel"
                      ></ngb-timepicker>
                      <small
                        class="form-text text-muted danger"
                        *ngIf="duration1.errors?.required"
                        >Você deve informar a duração 2</small
                      >
                    </ng-template>
                  </div>
                </div>

                <div
                  [ngClass]="
                    exercicie.loadFeedback
                      ? 'col-md-3 col-12'
                      : 'col-md-5 col-12'
                  "
                >
                  <div class="form-group mb-2">
                    <div class="d-md-none" role="group">
                      <label for="carga">Carga Total</label>
                    </div>
                    <div class="d-none d-md-block">
                      <label for="carga">Carga sugerida total</label>
                    </div>
                    <div class="input-group">
                      <div
                        class="input-group-prepend"
                        placement="top"
                        ngbTooltip="Clique para informar peso corporal."
                      >
                        <div class="input-group-text" id="checkbox-addon1">
                          <div class="checkbox checkbox-sm">
                            <input
                              type="checkbox"
                              id="checkboxInputGroup"
                              [(ngModel)]="exercicie.corporalLoad"
                              (change)="zeraCarga()"
                              name="checkbox"
                            />
                            <label for="checkboxInputGroup"></label>
                          </div>
                        </div>
                      </div>
                      <input
                        type="text"
                        autocomplete="off"
                        id="carga"
                        class="form-control"
                        [disabled]="exercicie.corporalLoad"
                        name="carga"
                        [(ngModel)]="exercicie.load"
                        #carga="ngModel"
                        gt="0"
                        aria-describedby="checkbox-addon1"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="group-carga"
                          >KG</span
                        >
                      </div>
                    </div>
                    <small
                      class="form-text text-muted danger"
                      *ngIf="carga.errors?.required || carga.errors?.gt"
                      >Você deve informar a carga total</small
                    >
                  </div>
                </div>
                <div class="col-md-2 col-12" *ngIf="exercicie.loadFeedback">
                  <div class="form-group mb-2">
                    <label for="loadFeedback">Feedback Carga Total</label>
                    <div class="input-group">
                      <input
                        type="text"
                        autocomplete="off"
                        id="loadFeedback"
                        class="form-control"
                        name="loadFeedback"
                        [disabled]="true"
                        [value]="
                          exercicie.loadFeedback != null
                            ? exercicie.loadFeedback
                            : 'N/A'
                        "
                        aria-describedby="group-loadFeedback"
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="group-loadFeedback"
                          >KG</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template
              [ngIf]="
                tipoSelecionado &&
                tipoSelecionado.id !== 4 &&
                !switchHideVariables
              "
            >
              <div class="form-row">
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <label for="carga">Velocidade</label>
                    <ng-select
                      [items]="velocidade"
                      [notFoundText]="'Nenhuma velocidade encontrada'"
                      name="velocidade"
                      placeholder="Selecionar Velocidade"
                      id="exercselect"
                      [(ngModel)]="exercicie.speed"
                    ></ng-select>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <label for="interval"
                      >Descanso<span
                        *ngIf="
                          tipoSelecionado.id != 2 && exercicie.conjugado == null
                        "
                        style="color: red"
                        >*</span
                      ></label
                    >
                    <div class="input-group">
                      <input
                        type="text"
                        autocomplete="off"
                        id="interval"
                        mask="9*"
                        class="form-control"
                        name="interval"
                        [(ngModel)]="exercicie.breakTime"
                        #interval="ngModel"
                        gt="0"
                        aria-describedby="group-interval"
                        [required]="
                          tipoSelecionado.id == 3 ||
                          (tipoSelecionado.id == 1 &&
                            exercicie.conjugado == null)
                        "
                      />
                      <div class="input-group-append">
                        <span class="input-group-text" id="group-interval"
                          >segundos</span
                        >
                      </div>
                    </div>
                    <small
                      class="form-text text-muted danger"
                      *ngIf="
                        (interval.errors?.required || interval.errors?.gt) &&
                        exercicie.conjugado == null
                      "
                      >Você deve informar o intervalo</small
                    >
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template
              [ngIf]="
                tipoSelecionado &&
                tipoSelecionado.id == 4 &&
                !exercicie.loadFeedback &&
                !exercicie.repetitionFeedback &&
                !exercicie.timeFeedback &&
                !switchHideVariables
              "
            >
              <div class="form-row mb-2 mt-2">
                <div class="col-md-4 col-12">
                  <div
                    class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0"
                    placement="top"
                    ngbTooltip="Informe se deseja solicitar o total de repetições."
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="color-switch-1"
                      [(ngModel)]="exercicie.showRepetitionFeedback"
                      name="showRepetitionFeedback"
                    />
                    <label
                      class="custom-control-label mr-1"
                      for="color-switch-1"
                    >
                      <span>Total de Repetições</span>
                    </label>
                  </div>
                </div>
                <div
                  class="col-md-4 col-12"
                  placement="top"
                  ngbTooltip="Informe se deseja solicitar o tempo total de execução."
                >
                  <div
                    class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0"
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="color-switch-2"
                      [(ngModel)]="exercicie.showTimeFeedback"
                      name="showTimeFeedback"
                    />
                    <label
                      class="custom-control-label mr-1"
                      for="color-switch-2"
                    >
                      <span>Tempo Total de Execução</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div
                    class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0"
                    placement="top"
                    ngbTooltip="Informe se deseja solicitar a carga total utilizada."
                  >
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="color-switch-3"
                      [(ngModel)]="exercicie.showLoadFeedback"
                      name="showLoadFeedback"
                    />
                    <label
                      class="custom-control-label mr-1"
                      for="color-switch-3"
                    >
                      <span>Total de Carga</span>
                    </label>
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="obse">Observações</label>
                  <textarea
                    id="obse"
                    rows="3"
                    class="form-control"
                    name="obse"
                    maxlength="500"
                    [disabled]="verificaTreinoDeAvaliacaoPreenchido()"
                    [(ngModel)]="exercicie.note"
                    #inputObse="ngModel"
                    placeholder="Você pode escrever observações sobre este exercício"
                  ></textarea>
                </div>
              </div>
            </div>
            <ng-template
              [ngIf]="
                tipoSelecionado &&
                tipoSelecionado.id !== 4 &&
                !switchHideVariables
              "
            >
              <div class="row">
                <div class="col-md-8 col-12">
                  <p
                    role="button"
                    tabindex="0"
                    placement="top"
                    ngbTooltip="Aqui você pode configurar periodizações automáticas de qualquer variável deste exercício e a partir de qualquer execução deste treino. Caso não queira alterar alguma variável na periodização basta não preencher a mesma."
                  >
                    Periodizações Automáticas
                    <i class="fa fa-question-circle"></i>
                  </p>
                </div>
                <div
                  class="col-md-4 col-12"
                  *ngIf="!isAddingProgression || !isEditingProgression"
                >
                  <button
                    type="button"
                    class="btn btn-sm btn-outline-secondary btn-icon float-right"
                    (click)="liberaCadastroProgressao()"
                  >
                    <i class="fa fa-plus-circle"></i
                    ><span class="d-none d-md-inline-block ml-2"
                      >adicionar periodização</span
                    >
                  </button>
                </div>
              </div>
              <div
                class="row mb-2"
                *ngIf="isAddingProgression || isEditingProgression"
              >
                <div class="col-sm">
                  <ui-switch
                    class="switchery switchery-warning"
                    [checked]="switchExecutionAndDate"
                    (change)="switchExeAndDate($event)"
                  >
                  </ui-switch>
                </div>
              </div>
              <!-- <div class="row mb-2" *ngIf="isAddingProgression|| isEditingProgression">
                <div class="col-sm" style="align-self: center;">
                  <button type="button" class="btn btn-block btn-outline-danger btn-icon" (click)="cancelaCadastroProgressao()" style="margin-top: 7px"><i class="fa fa-close"></i></button>
                </div>
              </div> -->
              <div
                class="row mb-2"
                *ngIf="isAddingProgression || isEditingProgression"
              >
                <div class="col-sm" *ngIf="switchExecutionAndDate">
                  <div class="form-group">
                    <label for="pe"
                      >Execução
                      <i
                        class="fa fa-question-circle"
                        style="cursor: pointer"
                        role="button"
                        tabindex="0"
                        placement="top"
                        [ngbTooltip]="tipExecProg"
                      ></i
                    ></label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="pe"
                      class="form-control"
                      mask="9*"
                      name="pe"
                      [(ngModel)]="newProgression.execution"
                      #ipe="ngModel"
                      required
                    />
                  </div>
                </div>
                <div class="col-sm" *ngIf="!switchExecutionAndDate">
                  <div class="form-group">
                    <label for="progressionDate"
                      >Data
                      <i
                        class="fa fa-question-circle"
                        style="cursor: pointer"
                        role="button"
                        tabindex="0"
                        placement="top"
                        [ngbTooltip]="tipDataProg"
                      ></i
                    ></label>
                    <input
                      type="date"
                      autocomplete="off"
                      id="progressionDate"
                      class="form-control"
                      name="progressionDate"
                      [(ngModel)]="newProgression.progressionDate"
                      #iprogressionDate="ngModel"
                      required
                    />
                  </div>
                </div>
                <div
                  class="col-sm"
                  *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                >
                  <div class="form-group">
                    <label for="ps"
                      >Séries
                      <i
                        class="fa fa-question-circle"
                        style="cursor: pointer"
                        role="button"
                        tabindex="0"
                        placement="top"
                        [ngbTooltip]="tipSerProg"
                      ></i
                    ></label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="ps"
                      class="form-control"
                      mask="9*"
                      name="ps"
                      [(ngModel)]="newProgression.series"
                      #ips="ngModel"
                    />
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label for="pr"
                      >{{
                        switchRepetitionAndDuration ? "duração" : "repetições"
                      }}
                      <i
                        class="fa fa-question-circle"
                        style="cursor: pointer"
                        role="button"
                        tabindex="0"
                        placement="top"
                        [ngbTooltip]="tipDurProg"
                      ></i
                    ></label>
                    <ng-template [ngIf]="switchRepetitionAndDuration">
                      <ngb-timepicker
                        name="timerDuration"
                        [spinners]="false"
                        [seconds]="true"
                        [(ngModel)]="newProgression.timeDuration"
                        required
                        #duration1="ngModel"
                      ></ngb-timepicker>
                    </ng-template>
                    <ng-template [ngIf]="!switchRepetitionAndDuration">
                      <input
                        type="text"
                        autocomplete="off"
                        id="pra"
                        class="form-control"
                        name="pra"
                        [(ngModel)]="newProgression.repetitions"
                        #ipra="ngModel"
                        maxlength="11"
                      />
                    </ng-template>
                  </div>
                </div>
              </div>
              <div
                class="row mb-2"
                *ngIf="isAddingProgression || isEditingProgression"
              >
                <div class="col-sm">
                  <div class="form-group">
                    <label for="pc"
                      >Carga
                      <i
                        class="fa fa-question-circle"
                        style="cursor: pointer"
                        role="button"
                        tabindex="0"
                        placement="top"
                        [ngbTooltip]="tipCarProg"
                      ></i
                    ></label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="pc"
                      class="form-control"
                      mask="9*"
                      name="pc"
                      [(ngModel)]="newProgression.load"
                      #ipc="ngModel"
                    />
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label for="pv"
                      >Velocidade
                      <i
                        class="fa fa-question-circle"
                        style="cursor: pointer"
                        role="button"
                        tabindex="0"
                        placement="top"
                        [ngbTooltip]="tipVelProg"
                      ></i
                    ></label>
                    <ng-select
                      [items]="velocidade"
                      [notFoundText]="'Nenhuma velocidade encontrada'"
                      name="pv"
                      id="pv"
                      [(ngModel)]="newProgression.speed"
                    ></ng-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label for="pd"
                      >Descanso
                      <i
                        class="fa fa-question-circle"
                        style="cursor: pointer"
                        role="button"
                        tabindex="0"
                        placement="top"
                        [ngbTooltip]="tipDesProg"
                      ></i
                    ></label>
                    <input
                      type="text"
                      autocomplete="off"
                      id="pd"
                      class="form-control"
                      mask="9*"
                      name="pd"
                      [(ngModel)]="newProgression.breakTime"
                      #ipd="ngModel"
                    />
                  </div>
                </div>
                <!-- <div class="col-sm" style="align-self: center;">
                  <button type="button" class="btn btn-block gradient-mint btn-icon" style="margin-top: 7px" [disabled]="!verifyNewProgression()" (click)="isEditingProgression ? atualizaProgressao() : cadastraProgressao()"><i class="fa fa-save"></i></button>
                </div> -->
              </div>
              <div
                class="row mb-2"
                *ngIf="isAddingProgression || isEditingProgression"
              >
                <div class="col-sm" style="align-self: center">
                  <button
                    type="button"
                    class="btn btn-block btn-outline-danger btn-icon"
                    (click)="cancelaCadastroProgressao()"
                    style="margin-top: 7px"
                  >
                    <i class="fa fa-close"></i>
                  </button>
                </div>
                <div class="col-sm" style="align-self: center"></div>
                <div class="col-sm" style="align-self: center">
                  <button
                    type="button"
                    class="btn btn-block gradient-mint btn-icon"
                    style="margin-top: 7px"
                    [disabled]="!verifyNewProgression()"
                    (click)="
                      isEditingProgression
                        ? atualizaProgressao()
                        : cadastraProgressao()
                    "
                  >
                    <i class="fa fa-save"></i>
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="thead-dark">
                        <tr>
                          <ng-template #tipExecProg
                            >Execução inicial que vai começar esta periodização.
                            Informe obrigatoriamente uma execução maior que
                            1.</ng-template
                          >
                          <ng-template #tipDataProg
                            >Data inicial que vai começar esta
                            periodização.</ng-template
                          >
                          <ng-template #tipDurProg>{{
                            switchRepetitionAndDuration
                              ? "Novo tempo de duração em segundos"
                              : "Nova quantidade de repetições"
                          }}</ng-template>
                          <ng-template #tipCarProg
                            >Novo valor de carga total.</ng-template
                          >
                          <ng-template #tipVelProg
                            >Novo valor de velocidade.</ng-template
                          >
                          <ng-template #tipDesProg
                            >Novo tempo de descanso.</ng-template
                          >
                          <ng-template #tipSerProg
                            >Nova quantidade de séries.</ng-template
                          >
                          <ng-template #tipStatProg
                            >Status indicando se a periodização já foi realizada
                            ou não.</ng-template
                          >
                          <th class="text-center p-2">
                            execução inicial
                            <i
                              class="fa fa-question-circle"
                              style="cursor: pointer"
                              role="button"
                              tabindex="0"
                              placement="top"
                              [ngbTooltip]="tipExecProg"
                            ></i>
                          </th>
                          <th class="text-center p-2">
                            data inicial
                            <i
                              class="fa fa-question-circle"
                              style="cursor: pointer"
                              role="button"
                              tabindex="0"
                              placement="top"
                              [ngbTooltip]="tipDataProg"
                            ></i>
                          </th>
                          <th
                            class="text-center p-2"
                            *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                          >
                            série
                            <i
                              class="fa fa-question-circle"
                              style="cursor: pointer"
                              role="button"
                              tabindex="0"
                              placement="top"
                              [ngbTooltip]="tipSerProg"
                            ></i>
                          </th>
                          <th class="text-center p-2">
                            {{
                              switchRepetitionAndDuration
                                ? "duração"
                                : "repetições"
                            }}
                            <i
                              class="fa fa-question-circle"
                              style="cursor: pointer"
                              role="button"
                              tabindex="0"
                              placement="top"
                              [ngbTooltip]="tipDurProg"
                            ></i>
                          </th>
                          <th class="text-center p-2">
                            carga total
                            <i
                              class="fa fa-question-circle"
                              style="cursor: pointer"
                              role="button"
                              tabindex="0"
                              placement="top"
                              [ngbTooltip]="tipCarProg"
                            ></i>
                          </th>
                          <th class="text-center p-2">
                            velocidade
                            <i
                              class="fa fa-question-circle"
                              style="cursor: pointer"
                              role="button"
                              tabindex="0"
                              placement="top"
                              [ngbTooltip]="tipVelProg"
                            ></i>
                          </th>
                          <th class="text-center p-2">
                            descanso
                            <i
                              class="fa fa-question-circle"
                              style="cursor: pointer"
                              role="button"
                              tabindex="0"
                              placement="top"
                              [ngbTooltip]="tipDesProg"
                            ></i>
                          </th>
                          <th class="text-center p-2">
                            status
                            <i
                              class="fa fa-question-circle"
                              style="cursor: pointer"
                              role="button"
                              tabindex="0"
                              placement="top"
                              [ngbTooltip]="tipStatProg"
                            ></i>
                          </th>
                          <th class="text-center p-2">#</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let progression of progressions">
                          <td class="text-center p-2">
                            {{
                              progression.execution
                                ? progression.execution
                                : "--"
                            }}
                          </td>
                          <td class="text-center p-2">
                            {{
                              progression.progressionDate
                                ? formatDateBrazil(progression.progressionDate)
                                : "--"
                            }}
                          </td>
                          <td
                            class="text-center p-2"
                            *ngIf="tipoSelecionado && tipoSelecionado.id == 1"
                          >
                            {{ progression.series ? progression.series : "--" }}
                          </td>
                          <td class="text-center p-2">
                            {{
                              switchRepetitionAndDuration
                                ? progression.duration
                                  ? progression.duration
                                  : "--"
                                : progression.repetitions
                                ? progression.repetitions
                                : "--"
                            }}
                          </td>
                          <td class="text-center p-2">
                            {{ progression.load ? progression.load : "--" }}
                          </td>
                          <td class="text-center p-2">
                            {{ progression.speed ? progression.speed : "--" }}
                          </td>
                          <td class="text-center p-2">
                            {{
                              progression.breakTime
                                ? progression.breakTime
                                : "--"
                            }}
                          </td>
                          <td class="text-center p-2">
                            {{ progression.status ? "Realizada" : "Pendente" }}
                          </td>
                          <td class="text-center p-2">
                            <div
                              class="btn-group btn-group-sm pl-0 pr-0"
                              role="group"
                            >
                              <button
                                type="button"
                                [disabled]="progression.status"
                                (click)="editProgression(progression)"
                                class="btn btm-sm gradient-mint btn-icon round"
                              >
                                <i class="fa fa-edit"></i>
                              </button>
                              <button
                                type="button"
                                [disabled]="progression.status"
                                (click)="deleteProgression(progression)"
                                class="btn btm-sm btn-danger btn-icon round"
                              >
                                <i class="fa fa-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr *ngIf="!progressions || progressions.length == 0">
                          <td colspan="9">
                            <p class="text-center">
                              Nenhuma periodização cadastrada
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="showFeedback">
              <div class="form-row">
                <div class="col-md-4 col-12">
                  <div class="form-group mb-2">
                    <label for="loadFeedback">Feedback Carga Total</label>
                    <div class="input-group">
                      <input
                        type="text"
                        autocomplete="off"
                        id="loadFeedback"
                        class="form-control"
                        name="loadFeedback"
                        [disabled]="true"
                        [value]="
                          exercicie.loadFeedback != null
                            ? exercicie.loadFeedback
                            : 'N/A'
                        "
                        aria-describedby="group-loadFeedback"
                      />
                      <div
                        class="input-group-append"
                        *ngIf="exercicie.loadFeedback"
                      >
                        <span class="input-group-text" id="group-loadFeedback"
                          >KG</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group mb-2">
                    <label for="repetitionFeedback">Feedback Repetições</label>
                    <div class="input-group">
                      <input
                        type="text"
                        autocomplete="off"
                        id="repetitionFeedback"
                        class="form-control"
                        name="repetitionFeedback"
                        [disabled]="true"
                        [value]="
                          exercicie.repetitionFeedback != null
                            ? exercicie.repetitionFeedback
                            : 'N/A'
                        "
                        aria-describedby="group-repetitionFeedback"
                      />
                      <div
                        class="input-group-append"
                        *ngIf="exercicie.repetitionFeedback"
                      >
                        <span
                          class="input-group-text"
                          id="group-repetitionFeedback"
                          >X</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group mb-2">
                    <label for="timeFeedback">Feedback Execução</label>
                    <div class="input-group">
                      <input
                        type="text"
                        autocomplete="off"
                        id="timeFeedback"
                        class="form-control"
                        name="timeFeedback"
                        [disabled]="true"
                        [value]="
                          exercicie.timeFeedback != null
                            ? exercicie.timeFeedback
                            : 'N/A'
                        "
                        aria-describedby="group-timeFeedback"
                      />
                      <div
                        class="input-group-append"
                        *ngIf="exercicie.timeFeedback"
                      >
                        <span class="input-group-text" id="group-timeFeedback"
                          >Seg</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="isEditingExercise">
              <div class="form-row mb-2">
                <div class="col-md-6 col-12">
                  <button
                    type="button"
                    class="btn btn-lg btn-light float-left"
                    (click)="cancelarAtualizacaoExercicio()"
                    style="border-bottom-left-radius: 25px"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-md-6 col-12">
                  <button
                    type="button"
                    *ngIf="verificaExercicio(null)"
                    class="btn btn-lg btn-warning float-right"
                    style="border-bottom-right-radius: 25px"
                    [disabled]="!verificaPreenchimentoExercicio()"
                    (click)="atualizarExercicio()"
                  >
                    Editar Exercício
                  </button>
                </div>
              </div>
            </ng-template>
            <ng-template
              [ngIf]="
                !isEditingExercise && !verificaTreinoDeAvaliacaoPreenchido()
              "
            >
              <div class="form-row mb-2">
                <div class="col-12">
                  <button
                    type="button"
                    class="btn btn-lg btn-warning float-right"
                    style="border-bottom-right-radius: 25px"
                    [disabled]="!verificaPreenchimentoExercicio()"
                    (click)="adicionarExercicio()"
                  >
                    Adicionar Exercício
                  </button>
                </div>
              </div>
            </ng-template>
          </form>
          <form>
            <div class="form-row">
              <ul
                class="list-group mb-2 draglist"
                cdkDropList
                style="width: 100%; overflow-y: auto; max-height: 400px"
                (cdkDropListDropped)="drop($event)"
              >
                <li
                  *ngFor="let exercicio of listaExercicios"
                  cdkDrag
                  class="list-group-item"
                  [ngStyle]="{
                    'border-top-left-radius':
                      listaExercicios.indexOf(exercicio) == 0 ? '50px' : '0',
                    'border-top-right-radius':
                      listaExercicios.indexOf(exercicio) == 0 ? '50px' : '0',
                    'border-bottom-left-radius':
                      listaExercicios.indexOf(exercicio) + 1 ==
                      listaExercicios.length
                        ? '50px'
                        : '0',
                    'border-bottom-right-radius':
                      listaExercicios.indexOf(exercicio) + 1 ==
                      listaExercicios.length
                        ? '50px'
                        : '0'
                  }"
                >
                  <div class="media">
                    <span class="dot align-middle mr-2">{{
                      listaExercicios.indexOf(exercicio) + 1
                    }}</span>
                    <div class="media-body">
                      <h5 class="mt-0">{{ exercicio.name }}</h5>
                      <span>{{
                        getLabelCategory(exercicio.category) +
                          (getNameExercise(exercicio)
                            ? " - conjugado com " + getNameExercise(exercicio)
                            : "")
                      }}</span>
                    </div>
                    <i
                      class="fa fa-reorder mr-2 text-muted"
                      cdkDragHandle
                      style="cursor: pointer"
                    ></i>
                    <div class="d-none d-md-block">
                      <button
                        type="button"
                        class="btn btn-outline-secondary btn-icon round mr-1 mb-1"
                        placement="left"
                        *ngIf="tipoSelecionado && tipoSelecionado.id != 4"
                        ngbTooltip="Duplicar exercício."
                        (click)="duplicarExercicio(exercicio)"
                      >
                        <i class="fa fa-copy"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-outline-primary btn-icon round mr-1 mb-1"
                        placement="left"
                        role="button"
                        tabindex="0"
                        [ngbTooltip]="
                          tipoSelecionado &&
                          tipoSelecionado.id == 4 &&
                          training &&
                          training.TrainingExecution &&
                          training.TrainingExecution.length > 0
                            ? 'Visualizar exercício'
                            : 'Editar exercício.'
                        "
                        (click)="editarExercicio(exercicio)"
                      >
                        <i class="fa fa-edit"></i>
                      </button>
                      <button
                        type="button"
                        [disabled]="listaExercicios.length <= 1"
                        class="btn btn-outline-danger btn-icon round mr-1 mb-1"
                        placement="left"
                        ngbTooltip="Remover exercício."
                        (click)="removerExercicio(exercicio)"
                        *ngIf="verificaExercicio(exercicio)"
                      >
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                    <div class="d-md-none" role="group">
                      <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                        <button
                          type="button"
                          class="btn btn-outline-warning btn-sm dropdown-toggle"
                          ngbDropdownToggle
                        >
                          <i class="ft-more-vertical"></i>
                        </button>
                        <div ngbDropdownMenu class="dropdown-menu" role="menu">
                          <a
                            class="dropdown-item"
                            (click)="duplicarExercicio(exercicio)"
                            *ngIf="tipoSelecionado && tipoSelecionado.id != 4"
                            >Duplicar exercício</a
                          >
                          <a
                            class="dropdown-item"
                            (click)="editarExercicio(exercicio)"
                            >{{
                              tipoSelecionado &&
                              tipoSelecionado.id == 4 &&
                              training &&
                              training.TrainingExecution &&
                              training.TrainingExecution.length > 0
                                ? "Visualizar exercício"
                                : "Editar exercício."
                            }}</a
                          >
                          <a
                            class="dropdown-item"
                            (click)="removerExercicio(exercicio)"
                            *ngIf="verificaExercicio(exercicio)"
                            >Remover exercício</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div class="form-row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-lg btn-secondary mr-2"
                  (click)="
                    !canEdit && training
                      ? activeModal.close('Close click')
                      : null
                  "
                  awPreviousStep
                >
                  Voltar
                </button>
                <button
                  type="button"
                  class="btn btn-lg gradient-mint"
                  [disabled]="
                    listaExercicios.length == 0 ||
                    !verificaPreenchimentoTreino() ||
                    verificaTreinoDeAvaliacaoPreenchido()
                  "
                  (click)="cadastrarTreino()"
                >
                  {{ idTreino ? "Salvar Treino" : "Finalizar" }}
                </button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
</div>

<ng-template #contentVideo>
  <ng-template
    [ngIf]="
      urlToPlay.startsWith('https://www.youtube.com/') ||
      urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <div class="embed-container">
      <iframe [src]="safeURL" frameborder="0" allowfullscreen></iframe>
    </div>
  </ng-template>
  <ng-template
    [ngIf]="
      !urlToPlay.startsWith('https://www.youtube.com/') &&
      !urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <video controls style="width: 100%">
      <source [src]="urlToPlay" type="video/mp4" />
    </video>
  </ng-template>
</ng-template>
<ng-template #contentGif>
  <IMG style="width: 100%" [src]="urlToPlay" />
</ng-template>

<ngx-spinner></ngx-spinner>
