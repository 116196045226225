import { Component, Input } from "@angular/core";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-free-test-modal",
  templateUrl: "./free-test-modal.component.html",
  styleUrls: ["./free-test-modal.component.scss"],
})
export class FreeTestModalComponent {
  @Input("modalRef") modalRef: NgbModalRef | null = null;

  apiCall = this.api.new().silent();

  constructor(
    private api: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}

  submit() {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall.post("/seller/iago/free-test").subscribe((response) => {
      this.spinner.hide();

      if (response.success) {
        this.toastr.success("Seu teste começou!", "Sucesso!");
        this.modalRef?.close("close modal");

        setTimeout(window.location.reload, 1000);
      }
    });
  }
}
