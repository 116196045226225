import { Component, OnInit } from "@angular/core";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CustomAdapter } from "app/shared/services/dateAdapter.service";
import { CustomDateParserFormatter } from "app/shared/services/dateParserFormatter.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Api from "../helpers/api";
import App from "../helpers/app";
import { ExcelService } from "../helpers/excel.service";

@Component({
  selector: "app-admin-usuarios",
  templateUrl: "./admin-usuarios.component.html",
  styleUrls: ["./admin-usuarios.component.scss"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class AdminUsuariosComponent implements OnInit {
  usuarios = [];
  usuariosFiltered = [];
  filterName;
  filterEmail;
  filterTipo;
  filterStatus;
  diasGratuitosInput: string = null;
  tipos = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: "1",
      name: "Aluno",
    },
    {
      id: "0",
      name: "Personal",
    },
  ];
  userView;
  lastModal = null;
  offset = 0;
  limit = 1000;
  page = 1;
  pageSize = 5;
  apiCall = this.api.new().silent();

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday());
  }

  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private app: App,
    private excelService: ExcelService,
    private api: Api,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {}

  async ngOnInit() {
    const self = this;

    self.usuarios = [];
    self.usuariosFiltered = [];
    self.userView = null;
    self.diasGratuitosInput = null;
    self.filterName = null;
    self.filterEmail = null;
    self.filterTipo = null;
    self.filterStatus = null;
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    let dataUsuarios = await self.apiCall
      .get(`users/${self.offset}/${self.limit}`)
      .pipe(take(1))
      .toPromise();
    let totalRows = [];
    let hasMoreRows = false;
    if (
      dataUsuarios.return &&
      dataUsuarios.return.count &&
      dataUsuarios.return.count > self.offset + self.limit
    )
      hasMoreRows = true;
    if (dataUsuarios.return && dataUsuarios.return.rows) {
      for (let t of dataUsuarios.return.rows) {
        totalRows.push(t);
      }
    }
    while (hasMoreRows) {
      self.offset = self.offset + 1000;
      // self.limit = self.limit + 500
      dataUsuarios = await self.apiCall
        .get(`users/${self.offset}/${self.limit}`)
        .pipe(take(1))
        .toPromise();
      if (
        dataUsuarios.return &&
        dataUsuarios.return.count &&
        dataUsuarios.return.count > self.offset + self.limit
      ) {
        hasMoreRows = true;
      } else {
        hasMoreRows = false;
      }
      if (dataUsuarios.return && dataUsuarios.return.rows) {
        for (let t of dataUsuarios.return.rows) {
          totalRows.push(t);
        }
      }
    }

    if (totalRows) {
      self.usuarios = JSON.parse(
        JSON.stringify(totalRows.filter((t) => t.type != "2"))
      );
      self.usuarios = self.usuarios.sort(
        (a, b) => a.name && a.name.localeCompare(b.name)
      );
      self.usuariosFiltered = self.usuarios;
    }
    await self.spinner.hide();
  }

  filter() {
    this.usuariosFiltered = this.usuarios;
    if (this.filterName) {
      this.usuariosFiltered = this.usuariosFiltered.filter(
        (x) =>
          x.name && x.name.toLowerCase().includes(this.filterName.toLowerCase())
      );
    }
    if (this.filterEmail) {
      this.usuariosFiltered = this.usuariosFiltered.filter((x) =>
        x.email.toLowerCase().includes(this.filterEmail.toLowerCase())
      );
    }
    if (this.filterTipo && this.filterTipo.id) {
      this.usuariosFiltered = this.usuariosFiltered.filter(
        (x) => x.type == this.filterTipo.id
      );
    }
    if (this.filterStatus && this.filterStatus.id) {
      this.usuariosFiltered = this.usuariosFiltered.filter(
        (x) => x.status == this.filterStatus.id
      );
    }
  }

  exportarListaUsuarios() {
    const dadosexport = this.usuariosFiltered;

    this.excelService.exportAsExcelFile(
      JSON.parse(JSON.stringify(dadosexport)),
      "listausuarios"
    );
  }

  getDiasGratuitos(freeUntil) {
    if (!freeUntil) {
      return 0;
    }
    var today = moment();
    let diferenca = moment(freeUntil).diff(today, "days");
    if (diferenca > 0) {
      return diferenca;
    } else {
      return 0;
    }
  }
  openModal(content, lastUser, refresh) {
    this.userView = lastUser;
    this.lastModal = this.modalService.open(content, { centered: true });
    this.lastModal.result.then(
      (result) => {
        if (refresh) this.ngOnInit();
      },
      (reason) => {
        if (refresh) this.ngOnInit();
      }
    );
  }

  async gerarSenhaProvisoria(content, lastUser) {
    if (lastUser.provisoryPassword) {
      this.openModal(content, lastUser, true);
    } else {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const data = await this.apiCall
        .put("users/generate/pass", lastUser)
        .pipe(take(1))
        .toPromise();
      this.spinner.hide();
      if (data.return) {
        this.openModal(content, data.return, true);
      }
    }
  }
  liberaAtualizacaoDiasGratuitos(content, lastUser) {
    if (lastUser.freeUntil) {
      const freeDaysSplited = lastUser.freeUntil
        ? moment(lastUser.freeUntil).format("DD/MM/YYYY").split("/")
        : null;

      if (freeDaysSplited) {
        this.diasGratuitosInput = this.dateAdapter.toModel({
          day: Number(freeDaysSplited[0]),
          month: Number(freeDaysSplited[1]),
          year: Number(freeDaysSplited[2]),
        });
      }
    }
    this.openModal(content, lastUser, false);
  }
  deleteAssinatura(user) {
    const self = this;
    if (!user || !user.id) {
      return;
    }
    self.app
      .confirm(
        "Tem certeza?",
        "Você realmente deseja cancelar a assinatura deste usuário?"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("seller/me/subscription/" + user.id)
            .subscribe(async (data) => {
              await self.ngOnInit();
              self.spinner.hide();
              if (data.success) {
                await this.app.alert(
                  "Tudo certo!",
                  "Assinatura cancelada com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  async toggleStatus(user) {
    user.status = !user.status;
    await this.atualizaUsuario(user);
  }
  formatDate(lastLogin) {
    return moment(lastLogin).format("DD/MM/YYYY HH:mm");
  }
  formatDateWithoutTime(lastLogin) {
    return moment(lastLogin).format("DD/MM/YYYY");
  }
  async atualizaDiasGratuitos() {
    if (
      this.diasGratuitosInput?.replace("/", "")?.replace("-", "") &&
      moment(
        this.diasGratuitosInput?.replace("/", "")?.replace("-", ""),
        "DDMMYYYY"
      ).toDate()
    ) {
      this.userView.freeUntil = moment(
        this.diasGratuitosInput?.replace("/", "")?.replace("-", ""),
        "DDMMYYYY"
      ).toDate();
      await this.atualizaUsuario(this.userView);
    }
  }
  async toggleType(user) {
    if (user.type == 1) {
      user.type = 0;
    }
    await this.atualizaUsuario(user);
  }
  async atualizaUsuario(user) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const dataUsuarios = await this.apiCall
      .put("users/update", user)
      .pipe(take(1))
      .toPromise();
    this.spinner.hide();
    if (dataUsuarios.success) {
      await this.app.alert(
        "Tudo certo!",
        "Usuário atualizado com sucesso!",
        "success"
      );
      if (this.lastModal) {
        this.lastModal.close();
      }
      this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", dataUsuarios.message, "error");
    }
  }
}
