<div class="modal-header">
  <h4 class="modal-title">Informações do Usuário</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="modalService.dismissAll()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <p><strong>Nome:</strong> {{ usuario.name }}</p>
    </div>
    <div class="col-6">
      <p><strong>Email:</strong> {{ usuario.email }}</p>
    </div>
  </div>
  <h5>Relações:</h5>
  <table class="table">
    <thead>
      <tr>
        <th>ID</th>
        <th>Data de Criação</th>
        <th>Nome do Personal</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let relation of usuario.relations">
        <td>{{ relation.id }}</td>
        <td>{{ formatDate(relation.createdAt) }}</td>
        <td>{{ relation.sellerName }}</td>
        <td
          [ngClass]="{
            'status-active': relation.status,
            'status-inactive': !relation.status
          }"
        >
          {{ relation.status ? "Ativo" : "Inativo" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div class="d-flex justify-content-end mr-2">
  <button
    type="button"
    class="btn btn-warning mb-2"
    (click)="modalService.dismissAll()"
  >
    Fechar
  </button>
</div>
