import { Component, Input, OnInit } from "@angular/core";
import App from "../../helpers/app";
import * as moment from "moment";
@Component({
  selector: "app-payment-pix-personal",
  templateUrl: "./payment-pix-personal.component.html",
  styleUrls: ["./payment-pix-personal.component.scss"],
})
export class PaymentPixPersonalComponent implements OnInit {
  @Input() invoice: any;
  pixKey: string;
  constructor(private app: App) {}

  ngOnInit() {
    if (this.invoice) {
      this.pixKey = this.invoice.pix.qrcode_text;
    }
  }

  copyPixKey() {
    navigator.clipboard.writeText(this.pixKey).then(
      () => {
        this.app.alert(
          "Chave PIX copiada para a área de transferência.",
          "success"
        );
      },
      (err) => {
        console.error("Erro ao copiar a chave PIX: ", err);
      }
    );
  }
}
