  <div class="order-bump">
    <div class="order-bump-image">
        <img src="https://opersonaldigital.com.br/wp-content/uploads/2022/06/Mockups-e1676054044126.png.webp" alt="Imagem do produto" />
    </div>
    <div class="order-bump-content">
      <h5>{{ title }}</h5>
      <p>{{ description }}</p>
      <div class="price">{{ price }}</div>
      <div class="order-bump-checkbox">
        <input 
          type="checkbox" 
          id="addToOrder" 
          [(ngModel)]="isChecked" 
          (change)="onCheckboxChange()"
        />
        <label for="addToOrder">Adicionar a Compra</label>
      </div>
    </div>
  </div>
  