import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, of } from "rxjs";
import * as moment from "moment";
import { catchError, map, switchMap, take } from "rxjs/operators";
import App from "../../helpers/app";
import Api from "../../helpers/api";

@Component({
  selector: "app-gerenciar-faturas",
  templateUrl: "./gerenciar-faturas.component.html",
  styleUrls: ["./gerenciar-faturas.component.scss"],
})
export class GerenciarFaturasComponent implements OnInit {
  apiCall = this.api.new().silent();
  invoice$: Observable<any> = of(null);
  errorMessage: string | null = null;

  constructor(
    private app: App,
    private api: Api,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.invoice$ = this.route.params.pipe(
      take(1),
      switchMap(params => {
        const invoiceId = params["invoiceId"];
        if (invoiceId) {
          return this.apiCall.get(`/finance/invoice/${invoiceId}`).pipe(
            map(response => response.return),
            catchError(error => {
              console.error("API error:", error);
              this.errorMessage = "Erro ao buscar dados da fatura";
              return of(null);
            })
          );
        } else {
          this.errorMessage = "Não foi possível localizar a fatura. Parâmetro InvoiceId ausente.";
          return of(null);
        }
      })
    );
    this.invoice$.subscribe(invoice => {
      if (invoice) {
        this.errorMessage = null;
      } else {
        this.errorMessage = "Fatura não localizada";
      }
      this.spinner.hide();
    });
  }

  async getInvoiceData(invoiceId: string): Promise<void> {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
  
    try {
      const response: any = await this.apiCall
        .get(`/finance/invoice/${invoiceId}`)
        .pipe(take(1))
        .toPromise();
      if (response && response.return) {
        this.invoice$ = response.return;
        this.errorMessage = null;
      } else {
        this.errorMessage = "Fatura não localizada";
        this.invoice$ = null;
      }
    } catch (error) {
      this.invoice$ = null;
      this.errorMessage = "Erro ao buscar dados da fatura";
      console.error("API error:", error);
    } finally {
      this.spinner.hide();
    }
  }
  

  formatDateWithoutTime(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  getStatusClass( status: string): string {
      switch (status) {
        case 'pending':
         return 'status-pending';
        case "paid":
          return "status-paid";
        case "canceled":
          return "status-canceled";
        case "refunded":
          return "status-refunded";
        default:
          return "status-pending";
    }
   
  }
}
