import { Injectable } from "@angular/core";
import {
  FormExerciseUnifiedType
} from "../../treinos-edit.types";
import { CheckboxSelectionServiceInterface } from "./checkbox-selection.types";

@Injectable({
  providedIn: "root",
})
export class CheckboxSelectionService
  implements CheckboxSelectionServiceInterface
{
  selectedRows = new Set<number | string>();

  constructor() {}

  addRowSelection(id: number | string): void {
    this.selectedRows.add(id);
  }

  removeRowSelection(id: number | string): void {
    this.selectedRows.delete(id);
  }

  toggleRowSelection(id: number | string): void {
    if (this.selectedRows.has(id)) {
      this.removeRowSelection(id);
    } else {
      this.addRowSelection(id);
    }
  }

  toggleAllRows(event: Event, exercises: FormExerciseUnifiedType[]): void {
    const target = event.target as HTMLInputElement;

    if (target?.checked) {
      exercises.forEach((exercise) => {
        this.selectedRows.add(exercise.id);
      });
    } else {
      this.reset();
    }
  }

  isRowSelected(id: number | string): boolean {
    return this.selectedRows.has(id);
  }

  isAllRowsSelected(exercises: FormExerciseUnifiedType[]): boolean {
    return exercises.every((exercise) => this.selectedRows.has(exercise.id));
  }

  getSelectedRows() {
    return Array.from(this.selectedRows);
  }

  reset() {
    this.selectedRows.clear();
  }
}
