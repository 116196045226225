<div class="card m-0">
  <div class="card-header py-3 bg-primary position-relative">
    <h4 class="card-title text-center white font-medium-4 m-0">Treino</h4>
    <button
      type="button"
      title="Fechar"
      class="btn btn-primary btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
      (click)="close()"
    >
      <i class="fa fa-times font-bold-400 white" aria-hidden="true"></i>
    </button>
  </div>
  <div class="card-content mt-2 px-1 px-md-2">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div
            class="w-100 d-flex flex-column justify-content-center align-items-start"
          >
            <span class="text-small-2 text-bold-500">Exercício:</span>
            <div
              class="w-100 d-flex justify-content-center align-items-center mt-2"
            >
              <span
                *ngIf="!editMode"
                class="w-100 mt-2 text-secondary text-small-2"
                >{{ selectedNewExercise.name }}</span
              >
              <div
                *ngIf="editMode"
                class="w-100 position-relative"
                [ngClass]="{
                  'select-open': selectOpen && !selectedNewExerciseValue
                }"
              >
                <ng-select
                  id="add-exercises"
                  [items]="exercisesList"
                  [loading]="exercisesLoading"
                  [typeahead]="input$"
                  [notFoundText]="'Nenhum exercício encontrado'"
                  [loadingText]="'Carregando...'"
                  bindLabel="name"
                  placeholder="Selecione um exercício"
                  class="w-100 ng-custom-select"
                  [(ngModel)]="selectedNewExercise"
                  (open)="toggleSelect()"
                  (close)="toggleSelect()"
                  (scrollToEnd)="fetchMore(exercise.searchTerm)"
                  #exercise
                >
                  <ng-template ng-option-tmp let-item="item">
                    <span class="font-size-3 font-size-md-5 text-bold-400"
                      >{{ item.name
                      }}<span
                        class="text-muted float-right font-size-3 font-size-md-5 text-bold-400"
                        >{{ item.categoryName }}</span
                      ></span
                    >
                  </ng-template>
                </ng-select>
                <i
                  class="ft-search grey lighten-1 text-bold-600 search-icon__input"
                ></i>
              </div>
              <button
                type="button"
                [title]="editMode ? 'Para de editar' : 'Habilitar edição'"
                (click)="toggleEditMode()"
                class="btn btn-sm ml-1 ml-md-2"
              >
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="ft-edit"
                >
                  <g id="Frame 342">
                    <g id="Group 25">
                      <path
                        id="Vector"
                        d="M15.7726 15.5184V18.1358H18.9909C18.2595 18.961 17.3609 19.6211 16.3547 20.0724C15.3486 20.5236 14.258 20.7557 13.1553 20.7531C11.0735 20.7508 9.0777 19.9228 7.60567 18.4508C6.13364 16.9787 5.30566 14.9829 5.30337 12.9011H2.68606C2.68183 15.0299 3.32795 17.1092 4.53792 18.8607C5.7479 20.6121 7.46401 21.9522 9.45654 22.7016C11.4491 23.4509 13.623 23.5738 15.6872 23.0537C17.7515 22.5336 19.6077 21.3953 21.0072 19.7913V23.3704H23.6246V15.5184H15.7726Z"
                        fill="#A3A3A3"
                      />
                      <path
                        id="Vector_2"
                        d="M13.1553 2.43182C11.6679 2.43948 10.199 2.76243 8.84553 3.37936C7.49207 3.99629 6.28479 4.89318 5.30335 6.01087V2.43182H2.68604V10.2838H10.538V7.66645H7.31973C8.05113 6.84125 8.94974 6.1811 9.95586 5.72984C10.962 5.27858 12.0526 5.04655 13.1553 5.04914C15.237 5.05143 17.2329 5.87942 18.7049 7.35145C20.177 8.82348 21.0049 10.8193 21.0072 12.9011H23.6245C23.6214 10.1254 22.5173 7.4644 20.5546 5.50172C18.592 3.53904 15.9309 2.43501 13.1553 2.43182Z"
                        fill="#A3A3A3"
                      />
                    </g>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div
            *ngIf="!!selectedNewExerciseValue"
            class="mt-4 w-100 d-flex flex-column justify-content-center align-items-start"
          >
            <span class="text-small-2 text-bold-500"
              >Selecione a forma de exibição do exercício:</span
            >
            <div class="preview-container mt-2">
              <ng-template
                [ngIf]="
                  type === 'video' &&
                  (selectedNewExerciseValue.urlVideo ||
                    selectedNewExerciseValue.safeURL)
                "
              >
                <ng-template
                  [ngIf]="
                    selectedNewExerciseValue.urlVideo.startsWith(
                      'https://www.youtube.com/'
                    ) ||
                    selectedNewExerciseValue.urlVideo.startsWith(
                      'http://www.youtube.com/'
                    )
                  "
                >
                  <div class="embed-container">
                    <iframe
                      [src]="selectedNewExerciseValue.safeURL"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </ng-template>
                <ng-template
                  [ngIf]="
                    !selectedNewExerciseValue.urlVideo.startsWith(
                      'https://www.youtube.com/'
                    ) &&
                    !selectedNewExerciseValue.urlVideo.startsWith(
                      'http://www.youtube.com/'
                    )
                  "
                >
                  <video controls style="width: 100%">
                    <source
                      [src]="selectedNewExerciseValue.urlVideo"
                      type="video/mp4"
                    />
                  </video>
                </ng-template>
              </ng-template>
              <ng-template
                [ngIf]="type === 'gif' && selectedNewExerciseValue.urlGif"
              >
                <img
                  style="width: 100%"
                  [src]="selectedNewExerciseValue.urlGif"
                />
              </ng-template>
            </div>
            <div
              id="media-type"
              class="input-group w-100 d-flex justify-content-center align-items-center mt-2"
            >
              <div
                class="radio radio-sm d-inline-block mr-3"
                [ngbTooltip]="
                  !selectedNewExerciseValue.urlGif
                    ? 'Não há gif para este exercício'
                    : ''
                "
              >
                <input
                  id="gif-switch"
                  type="radio"
                  name="media-type"
                  [disabled]="!selectedNewExerciseValue.urlGif"
                  [checked]="type === 'gif'"
                  (click)="setMediaType('gif')"
                />
                <label for="gif-switch" class="normal-case cursor-pointer"
                  >Gif</label
                >
              </div>
              <div
                class="radio radio-sm d-inline-block"
                [ngbTooltip]="
                  !selectedNewExerciseValue.urlVideo
                    ? 'Não há vídeo para este exercício'
                    : ''
                "
              >
                <input
                  id="video-switch"
                  type="radio"
                  name="media-type"
                  [disabled]="!selectedNewExerciseValue.urlVideo"
                  [checked]="type === 'video'"
                  (click)="setMediaType('video')"
                />
                <label for="video-switch" class="normal-case cursor-pointer"
                  >Vídeo</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row mb-0">
      <div class="col">
        <div
          class="w-100 d-flex justify-content-between align-items-center px-0 px-md-3"
        >
          <button
            type="button"
            title="Cancelar"
            class="btn btn-outline-primary text-primary px-4"
            (click)="close()"
          >
            Cancelar
          </button>
          <button
            type="button"
            title="Adicionar exercício"
            class="btn btn-primary px-4 white"
            (click)="save()"
          >
            Adicionar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
