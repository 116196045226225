<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300"style="padding-bottom: 1.5rem;">Notificações de atualizações</h2>
    </div>
  </div>
</div>
<div class="row mb-2">
  
  <div class="col" >
    <div class=" float-right d-none d-md-block" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openAtualizacao(null, atualizacaoDetail)" >Adicionar Atualização</button>
    </div>
    <div class="btn-group btn-group-lg d-md-none float-right" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openAtualizacao(null, atualizacaoDetail)" ><i class="fa fa-plus"></i></button>
    </div>
    
    
  </div>
</div>


<div class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <ul class="list-group">
          <li *ngFor="let atualizacao of atualizacoes | slice: (pageTr-1) * pageSize : (pageTr-1) * pageSize + pageSize" class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">{{atualizacao.title}}</h5>
                <p>{{atualizacao.description}}</p>

              </div>

              
              <div class=" d-md-none" role="group">
                <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                  <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                    <i class="ft-more-vertical"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                  
                    <a class="dropdown-item"  (click)="openAtualizacao(atualizacao, atualizacaoDetail)">Visualizar atualização</a>
                    <a class="dropdown-item" (click)="ondeletarAtualizacao(atualizacao)">Deletar atualização</a>
                  </div>
                </div>
              </div>
              <div class="btn-group btn-group-lg d-none d-md-block" role="group">
                              
                <button type="button"  class="btn btn-outline-warning" placement="top" ngbTooltip="Visualizar atualização." (click)="openAtualizacao(atualizacao, atualizacaoDetail)"><i class="fa fa-edit"></i></button>
                <button type="button"  class="btn btn-outline-warning" placement="top" ngbTooltip="Deletar atualização." (click)="ondeletarAtualizacao(atualizacao)"><i class="fa fa-trash"></i></button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="atualizacoes.length>0">
  <div class="col">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        [(page)]="pageTr"
        [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
        [collectionSize]="atualizacoes.length"></ngb-pagination>
    </div>
  </div>
</div>
<div class="row" *ngIf="atualizacoes.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <ul class="list-group p-0">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhuma notificação de atualização.</h5>
                <span>Cadastre a sua primeira atualização!</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


<ng-template #atualizacaoDetail let-modal>
  <ngx-spinner></ngx-spinner>

  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Update</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br/>
              
              
             
              <div class="form-group mb-2">
                <label for="name">Título da Atualização</label>
                <input type="text" id="name" class="form-control" name="name" [(ngModel)]="atualizacao.title" #inputName="ngModel" required>
              </div>
              <div class="form-group mb-2">
                <label for="name">Descrição da Atualização</label>
                <textarea id="obse" rows="2" class="form-control" name="obse"  #atualizacaoDescription maxlength="255"  [(ngModel)]="atualizacao.description" #inputObse="ngModel" placeholder="Descreva a atualização."></textarea>
              </div>
              <div class="form-row__description">
                {{ 255 - atualizacaoDescription.value.length }} caracteres restantes.
              </div>
              
              
             
              

              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block" [ngClass]="atualizacao && atualizacao.id ? 'btn-danger' : 'btn-secondary'" (click)="modal.close('Close click')">{{atualizacao && atualizacao.id ? 'Fechar' : 'Cancelar'}}</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="inserirOuAtualizarAtualizacao(modal)" [disabled]="!atualizacao || !atualizacao.title || !atualizacao.description">{{atualizacao && atualizacao.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
