import { Component, OnInit } from "@angular/core";
import Api from "../helpers/api";
import App from "../helpers/app";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "admin-funil-recuperacao",
  templateUrl: "./admin-funil-recuperacao.component.html",
  styleUrls: ["./admin-funil-recuperacao.component.scss"],
})
export class AdminFunilRecuperacaoComponent implements OnInit {
  apiCall = this.api.new();
  subContas = [];
  subContasFiltered = [];
  page = 1;
  pageSize = 10;
  subAccountSelected = null;
  lastModal = null;
  filterEmail;
  filterStatusContato;
  filterTipoFunil;
  funnels = [];
  recoveryContacts = [];
  recoveryContactsFiltered = [];
  statusAtual = null
  detalheContato = null;
  assinaturaSelecionada = null;
  recoveryId = null;
  churnReason = null;
  churnReasonList = [
    "Adaptação",
    "Falta de funcionalidades",
    "Migração de app",
    "Motivos pessoais",
    "Mudou de profissão",
    "Não atende mais online",
    "Problemas financeiros",
    "Outros",
  ];
  statusContato = [
    {
      id: "1",
      name: "Em Recuperação",
    },
    {
      id: "2",
      name: "Não Convertido",
    },
    {
      id: "3",
      name: "Convertido",
    },
  ];
  funil = [
    {
      id: "1",
      name: "Novos",
    },
    {
      id: "2",
      name: "Renovação",
    },
    {
      id: "3",
      name: "Devedores",
    },
  ];
  isChurn = false;

  constructor(
    private modalService: NgbModal,
    private api: Api,
    private app: App,
    private spinner: NgxSpinnerService
  ) {}

  async ngOnInit() {
    const self = this;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const recoveryContacts = await self.apiCall
      .get("recoveryContacts")
      .pipe(take(1))
      .toPromise();
    if (recoveryContacts) this.recoveryContacts = recoveryContacts.return;

    this.recoveryContactsFiltered = this.recoveryContacts;

    console.log(this.recoveryContacts);

    const funnels = await self.apiCall
      .get("recoveryContactFunnels")
      .pipe(take(1))
      .toPromise();
    if (funnels) this.funnels = funnels.return;

    self.spinner.hide();
  }

  filter() {
    this.recoveryContactsFiltered = this.recoveryContacts;
    if (this.filterEmail) {
      this.recoveryContactsFiltered = this.recoveryContactsFiltered.filter(
        (x) =>
          x.User.email.toLowerCase().includes(this.filterEmail.toLowerCase())
      );
    }
    if(this.filterStatusContato){
      this.recoveryContactsFiltered = this.recoveryContactsFiltered.filter(
        (x) =>
          x.recoveryStatus == this.filterStatusContato.id
      );
    }
    if(this.filterTipoFunil){
      console.log(this.filterTipoFunil);
      this.recoveryContactsFiltered = this.recoveryContactsFiltered.filter(
        (x) =>
          x.funnelId == this.filterTipoFunil.id
      );
    }
  }

  async openAtualizaContato(usuario, modal) {
    console.log(usuario);
    this.statusAtual = null;
    this.detalheContato = null;
    this.recoveryId = usuario.id;
    this.lastModal = this.modalService.open(modal, {
      centered: true,
      keyboard: false,
    });
  }

  async openIugu(account_id: string) {
    const iuguUrl = `https://alia.iugu.com/marketplace/subaccounts/${account_id}`;
    window.open(iuguUrl, "_blank");
  }

  formatDate(date) {
    return moment(date).format("DD/MM/YY");
  }

  async updateRecovery(){
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let data

    let body = {
      recoveryStatus: this.statusAtual.id,
      recoveryDetails: this.detalheContato ? this.detalheContato : "",
      isChurn: this.isChurn,
      churnReason: this.churnReason,
      churnDate: this.isChurn ? new Date() : null
    }

    data = await this.apiCall.put(`recoveryContact/${this.recoveryId}`, body).pipe(take(1))
    .toPromise();

    if (data.success) {
        this.spinner.hide();
        await this.app.alert(
          "Tudo certo!",
          "Contato Salvo com Sucesso",
          "success"
        );
        this.lastModal.close("Close click");
        this.ngOnInit();
      } else {
        await this.app.alert("Ops :(", data.message, "error");
        this.spinner.hide();
      }
   
  }

  getBadge(status) {
    if (status == 1) {
      return "<span class='badge badge-warning'>Em Recuperação</span>";
    } else if (status == 3) {
      return "<span class='badge badge-success'>Recuperado</span>";
    }else if (status == 2) {
      return "<span class='badge badge-danger'>Não Recuperado</span>";
    }
  }

  openNotificacoesInteligentes(phoneCustomer) {
    let phoneFormated = phoneCustomer.replace("+", "");
    if (phoneFormated.length == 11) {
      phoneFormated = phoneFormated.padStart(13, "55");
    }
    window.open(
      `https://app.notificacoesinteligentes.com/leads/?phone=%2B${phoneFormated}`,
      "_blank"
    );
  }
  
  openWhatsapp(phoneCustomer) {
    let phoneFormated = phoneCustomer.replace("+", "");
    if (phoneFormated.length == 11) {
      phoneFormated = phoneFormated.padStart(13, "55");
    }
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneFormated}`,
      "_blank"
    );
  }
}
