import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../shared/auth/auth.service";
import {DragulaService} from "ng2-dragula";
import {NgxSpinnerService} from "ngx-spinner";
import {DomSanitizer} from "@angular/platform-browser";
import Me from "../helpers/me";
import {SocialAuthService} from "angularx-social-login";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";



@Component({
  selector: 'app-admin-novidades',
  templateUrl: './admin-novidades.component.html',
  styleUrls: ['./admin-novidades.component.scss']
})

export class AdminNovidadesComponent implements OnInit {
  apiCall = this.api.new().silent();
  pageTr = 1
  pageSize = 10
  atualizacoes = []

  atualizacao = {
    id : null,
    title : null,
    description : null,
  }

  inputAtualizacao = null
  user = null
  constructor(private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private dragulaService: DragulaService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private me: Me,
    private socialAuthService: SocialAuthService,
    private app: App,
    private api: Api) {
    this.me.get().pipe(take(1)).toPromise().then((user)=>{
  
  
        this.user = user
  
      })
    }

  async ngOnInit(){
    const self = this
    self.atualizacoes = []

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    self.apiCall.get('updatessistema').subscribe(async cat => {
      self.atualizacoes = cat.return
      self.atualizacoes = cat.return
      self.spinner.hide()
    });



  }


  inserirOuAtualizarAtualizacao(modal){
    if(!this.user || !this.user.id){
      return
    }
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      let chamada
      let body = {
        atualizacao: {
          id : this.atualizacao.id ? this.atualizacao.id : null,
          title: this.atualizacao.title,
          description : this.atualizacao.description,
          user : this.user.id
        }
      }
      if(this.atualizacao.id){
        chamada = this.apiCall.put('updatessistema', body)
  
      }else{
        chamada = this.apiCall.post('updatessistema', body)
  
      }
      chamada.subscribe(async data => {
        if(data.success){
          this.spinner.hide()
          await this.app.alert('Tudo certo!', this.atualizacao.id ? 'Sua atualização foi editada com sucesso' : 'Sua atualização foi cadastrada com sucesso', 'success')
          modal.close('Close click')
          this.ngOnInit()
        }else{
          await this.app.alert('Ops :(', data.message, 'error')
          this.spinner.hide()
        }
      });
    }



    openAtualizacao(atualizacao, template) {
      if(atualizacao){
        this.atualizacao = {...atualizacao}
      }else{
        this.atualizacao = {
          id : null,
          title : null,
          description : null
        }
      }
      const modalRef = this.modalService.open(template);
  
    }

    ondeletarAtualizacao(atualizacao){
      const self = this
      if(!atualizacao || !atualizacao.id){
        return
      }
      self.app.confirm('Tem certeza?', 'Você realmente deseja deletar esta atualização?').then(d=>{
        if(d){
          self.spinner.show(undefined,
            {
              type: 'ball-triangle-path',
              size: 'medium',
              bdColor: 'rgba(0, 0, 0, 0.8)',
              color: '#fff',
              fullScreen: true
            });
          self.apiCall.delete('updatessistema/'+atualizacao.id).subscribe(async data => {
            self.spinner.hide()
            if(data.success){
              self.ngOnInit()
              await this.app.alert('Tudo certo!',  'Atualização deletada com sucesso', 'success')
            }else{
              await this.app.alert('Ops :(', data.message, 'error')
            }
          });
        }
      })
    }

}
