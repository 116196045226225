import { Component, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-share-site",
  templateUrl: "./share-site.component.html",
  styleUrls: ["./share-site.component.scss"],
})
export class ShareSiteComponent {
  @Input() siteUrl: string = "";
  @Input() whatsappUrl: string = "";

  constructor(
    private toastr: ToastrService,
    public activeModal: NgbActiveModal
  ) {}

  copyUrl() {
    const input = document.querySelector(".share-url") as HTMLInputElement;
    input.select();
    const success = document.execCommand("copy");

    if (success) {
      this.toastr.success("URL copiada com sucesso!");
    } else {
      this.toastr.error("Falha ao copiar URL.");
    }
  }

  closeModal() {
    this.activeModal.dismiss("Cross click");
  }
}
