import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ADMINROUTES: RouteInfo[] = [
  {
    path: '/page/admin', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/admin/buscar-usuario', title: 'Buscar Usuário', icon: 'ft-search', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  // {
  //   path: '/page/usuarios', title: 'Usuários', icon: 'ft-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
  {
    path: '/page/cupons', title: 'Cupons', icon: 'ft-dollar-sign', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/admin/offers', title: 'Ofertas', icon: 'ft-rotate-ccw', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/admin/atualizacoes', title: 'Atualizações', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/admin/indique-e-ganhe', title: 'Indique e Ganhe', icon: 'ft-rotate-ccw', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/admin/assinaturas', title: 'Assinaturas', icon: 'fa fa-list-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/admin/meu-personal-digital', title: 'Meu Personal Digital', icon: 'fa fa-users', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Intermediação', icon: 'fa fa-user', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: 
    [
      { path: '/page/admin/validacao-subcontas', title: 'Validação de Subcontas', icon: 'fa fa-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/page/admin/listar-subcontas', title: 'Listar Subcontas', icon: 'fa fa-list', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'OPD Academy', icon: 'fa fa-graduation-cap', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: 
    [
      { path: '/page/admin/opd-academy-aulas', title: 'Aulas', icon: 'fa fa-book', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/page/admin/opd-academy-categorias', title: 'Categorias', icon: 'fa fa-list-ol', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '/page/admin/acompanhar-contestacao', title: 'Acompanhar Contestações', icon: 'fa fa-university', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '/page/admin/recovery-funnel', title: 'Funil de Recuperação', icon: 'fa fa-filter', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  // {
  //   path: '/page/admin/historico-assinaturas', title: 'Acompanhar Histórico de Assinaturas', icon: 'fa fa-database', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  // },
];
/*
{
  path: '', title: 'Treinos', icon: 'ft-arrow-right submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
  submenu: [
  { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
  { path: '/YOUR-ROUTE-PATH', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
]
},*/
