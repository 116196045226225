<ngx-spinner></ngx-spinner>

<!--Login Page Starts-->
<section id="login">
  <div class="row auth-height full-height-vh m-0 black">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                <img src="../../../../assets/img/gallery/vector-web-laranja.png" alt="" class="img-fluid" width="400" height="330">
              </div>
              <div class="col-lg-6 col-12 px-4 py-3">
                <div class="row text-center" style="margin-top: 130px" *ngIf="error">
                  <div class="col text-center">
                    <h6>{{error}}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner></ngx-spinner>
