import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import {
  ExecutionType,
  RelationsType,
} from "app/personal/pages/acompanhamento-execucoes/acompanhamento-execucoes.types";
import { TableExpansionService } from "app/personal/pages/treinos/treinos-edit/services";
import * as moment from "moment";
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { LoadEvolutionModalComponent } from "../load-evolution-modal/load-evolution-modal.component";

@Component({
  selector: "app-datatable-component",
  templateUrl: "./datatable-component.component.html",
  styleUrls: ["./datatable-component.component.scss"],
})
export class DatatableComponentComponent implements OnInit, OnDestroy {
  apiCall = this.api.new().silent();

  @Input("isLoading") isLoading: boolean = false;
  @Input("isFetching") isFetching: boolean = false;
  @Input("expanded") expandedRows: Set<number> = new Set<number>();
  @Input("relations") relations: RelationsType | null = null;

  @Output("fetchEvolutions") _fetchEvolutions: EventEmitter<boolean> =
    new EventEmitter();
  @Output("toggleRowExpansion") _toggleRowExpansion: EventEmitter<number> =
    new EventEmitter();
  @Output("refetchAfterChangeSolved")
  _refetchAfterChangeSolved: EventEmitter<void> = new EventEmitter();

  @ViewChild("scrollElement") scrollElement: ElementRef<HTMLDivElement> | null =
    null;

  executions: ExecutionType[] = [];

  clientDevice: "desktop" | "mobile" | null = null;

  additionalExpansionService: TableExpansionService | null = null;

  constructor(
    private api: Api,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService
  ) {
    this.additionalExpansionService = new TableExpansionService();
  }

  ngOnInit(): void {
    this.setDeviceType();
  }

  initializeInfiniteScroll() {
    this.scrollElement?.nativeElement.addEventListener("scroll", (event) =>
      this.onBottomReached(event)
    );
  }

  fetchEvolutions(fetchNextPage?: boolean) {
    this._fetchEvolutions.emit(fetchNextPage);
  }

  markAsSolvedOrUnsolved(execution: ExecutionType) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall
      .post("training/execution/checksolved", {
        executionId: execution.id,
        customerId: execution.customerId,
        isSolved: execution.isSolved !== true,
      })
      .subscribe((response) => {
        this.spinner.hide();

        if (response.error) {
          this.toastr.error("Erro ao resolver execução!");
          return;
        }

        this.toastr.success("Execução resolvida com sucesso!");
        this._refetchAfterChangeSolved.emit();
      });
  }

  hasFeedback(execution: ExecutionType) {
    return !!execution.hasExerciseComment || !!execution.observation;
  }

  hasLoadEvolution(execution: ExecutionType) {
    return !!execution.loadNotification;
  }

  editExercise(execution: ExecutionType) {
    const relationId =
      this.relations?.relations.find(
        (relation) => relation.id === execution.relation
      )?.id || null;

    if (relationId) {
      const data = {
        training: execution.trainingId ? execution.trainingId : null,
        isOnTour: false,
        canEdit: true,
        relation: relationId,
      };
      const encodedData = btoa(JSON.stringify(data))
        .replace("==", "")
        .replace("=", "");

      this.router.navigate(["/page/treino/details/" + encodedData]);
    }
  }

  openLoadEvolutionModal(evolution: ExecutionType, exerciseId?: number) {
    const instance = this.modalService.open(LoadEvolutionModalComponent, {
      centered: true,
      size: "lg",
    }).componentInstance as LoadEvolutionModalComponent;

    instance.exercises = evolution.exercises;
    instance.trainingId = evolution.trainingId;
    instance.exerciseSelected =
      evolution.exercises.find((exercise) => exercise.id === exerciseId)?.id ||
      null;
  }

  setDeviceType() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktop = this.deviceService.isDesktop();

    this.clientDevice =
      isMobile || isTablet ? "mobile" : isDesktop ? "desktop" : null;
  }

  toggleRowExpansion(id: number) {
    this._toggleRowExpansion.emit(id);

    if (!this.additionalExpansionService?.isRowExpanded(id)) {
      this.additionalExpansionService?.toggleRowExpansion(id);
    }
  }

  toggleRowContentRender(id: number) {
    this.additionalExpansionService?.toggleRowExpansion(id);
  }

  isRowExpanded(id: number) {
    return this.expandedRows.has(id);
  }

  isRowContentRendered(id: number) {
    return this.additionalExpansionService?.isRowExpanded(id);
  }

  formatDate(date: string, format: string = "DD/MM") {
    return moment(date).format(format);
  }

  preventDefault(event: Event, execute?: { func: Function; args: any[] }) {
    event.preventDefault();
    event.stopPropagation();

    if (execute) {
      const { func, args } = execute;

      func(...args);
    }
  }

  getRatingLabel(rating: number) {
    switch (rating) {
      case 1:
        return "Muito leve";
      case 2:
        return "Leve";
      case 3:
        return "Ótimo";
      case 4:
        return "Pesado";
      case 5:
        return "Muito pesado";
      default:
        return "-";
    }
  }

  onBottomReached(event: Event) {
    const element = event.target as HTMLDivElement;

    if (
      element.scrollTop + element.clientHeight >=
        element.scrollHeight - 65 * 3 &&
      !this.isLoading &&
      !this.isFetching
    ) {
      this.fetchEvolutions(true);
    }
  }

  removeScrollListener() {
    this.scrollElement?.nativeElement.removeEventListener("scroll", (event) =>
      this.onBottomReached(event)
    );
  }

  ngOnDestroy(): void {
    this.removeScrollListener();
  }
}
