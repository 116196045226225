import { Component, OnInit } from '@angular/core';
import Api from "../helpers/api";
import App from "../helpers/app";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from 'rxjs/operators';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-acompanhar-contestacao',
  templateUrl: './admin-acompanhar-contestacao.component.html',
  styleUrls: ['./admin-acompanhar-contestacao.component.scss']
})
export class AdminAcompanharContestacaoComponent implements OnInit {

  apiCall = this.api.new()
  chargebacks = []
  page = 1
  pageSize = 10
  chargebackSelected = null
  lastModal = null
  

  constructor(private modalService: NgbModal,
    private api: Api,
    private app: App,
    private spinner: NgxSpinnerService,) { }

async ngOnInit() {
  const self = this

  self.spinner.show(undefined,
    {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });

    const chargebacks = await self.apiCall.get('admin/users/chargebacks').pipe(take(1)).toPromise()
    console.log('Chargebacks', chargebacks.return)

    if(chargebacks) this.chargebacks = chargebacks.return
    self.spinner.hide()
}

async selecionarChargeback(chargeback, modal) {
  const self = this

  this.chargebackSelected = chargeback
  this.lastModal = modal

  this.lastModal = this.modalService.open(modal, { centered: true, size: "lg" });

}

confirmarContestacao(chargeback){
  const self = this
  console.log(chargeback)

  Swal.fire({
    title: "Deseja dar baixa nessa contestação?",
    text: 'Deseja dar baixa?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    confirmButtonText: "Confirmar",
    cancelButtonText: "Cancelar"
}).then((result) => {
  if (result.isConfirmed) {

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      }
    );

    self.apiCall.post('admin/users/confirmContest', {id : chargeback.id}).subscribe(async data => {
      if(data.success){
        this.spinner.hide()
        await this.app.alert('Tudo certo!', 'Confirmado com sucesso', 'success')
        this.lastModal.close('Close click')
        this.ngOnInit()
      }else{
        await this.app.alert('Ops :(', 'error')
      }
    });
  }
})
}

formatDate(date) {
  return moment(date).format('DD/MM/YYYY')
}

}
