import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
} from "@angular/core";
import { productSalesData } from "app/personal/pages/finance-reports/graphics.types";

@Component({
  selector: "app-products-chart",
  templateUrl: "./products-chart.component.html",
  styleUrls: ["./products-chart.component.scss"],
})
export class ProductsChartComponent implements OnInit, OnChanges {
  @Input() data: productSalesData[] | undefined;
  public chartOptions: any = {};

  ngOnInit(): void {
    if (this.data) {
      this.updateChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.updateChart();
    }
  }

  private updateChart(): void {
    if (this.data && this.data.length > 0) {
      const seriesData = this.data.map((p) => p.quantity);
      const labels = this.data.map((p) => p.product);

      this.chartOptions = {
        series: seriesData,
        chart: {
          width: 600,
          type: "pie",
        },
        labels: labels,
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        title: {
          text: "Produtos",
          align: "left",
        },
      };
    } else {
      this.chartOptions = {
        series: [],
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      };
    }
  }
}
