import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import {
  ExerciseFeedback,
  ExerciseType,
  TrainingHistory,
} from "app/personal/pages/acompanhamento-execucoes/acompanhamento-execucoes.types";
import * as moment from "moment";
import { ApexOptions } from "ng-apexcharts";

const $primary = "#ff6c22",
  $success = "#40C057",
  $info = "#2F8BE6",
  $warning = "#F77E17",
  $danger = "#F55252";

const themeColors = [$primary, $warning, $success, $danger, $info];

@Component({
  selector: "app-load-evolution-modal",
  templateUrl: "./load-evolution-modal.component.html",
  styleUrls: ["./load-evolution-modal.component.scss"],
})
export class LoadEvolutionModalComponent implements OnInit {
  apiCall = this.api.new().silent();

  trainingId: number | null = null;

  activeTabId: "graph" | "table" = "graph";

  exercises: ExerciseType[] = [];
  exerciseSelected: number | null = null;

  trainingHistory: TrainingHistory | null = null;

  exerciseFeedbackCalculatedData: ExerciseFeedback | null = null;

  filterPeriod: {
    days: 7 | 30 | 60 | 90 | 365;
    description: string;
  }[] = [
    { days: 7, description: "Últimos 7 dias" },
    { days: 30, description: "Últimos 30 dias" },
    { days: 60, description: "Últimos 60 dias" },
    { days: 90, description: "Últimos 90 dias" },
    { days: 365, description: "Últimos 12 meses" },
  ];
  filterPeriodSelected: 7 | 30 | 60 | 90 | 365 = 365;

  isLoading: boolean = false;

  lineAreaChartOptions: ApexOptions | null = null;

  page = 1;
  pageSize = 10;

  constructor(private activeModal: NgbActiveModal, private api: Api) {
    this.lineAreaChartOptions = {
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false,
        },
      },
      colors: themeColors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      series: [
        {
          name: "Carga",
          data: [],
        },
      ],
      legend: {
        offsetY: -10,
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        title: {
          text: "Kg",
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
        y: {
          formatter: function (value) {
            return value + " kg";
          },
        },
      },
    };
  }

  ngOnInit(): void {
    if (this.trainingId) {
      this.isLoading = true;

      this.apiCall
        .get<TrainingHistory>("training/historico/" + this.trainingId)
        .subscribe((response) => {
          this.isLoading = false;

          if (response.error) return;

          this.trainingHistory = response.return;

          this.calculateGraphData();
        });
    } else {
      this.calculateGraphData();
    }
  }

  calculateGraphData() {
    this.changeExercise(this.exerciseSelected);
    this.changePeriod();
  }

  changePeriod() {
    let exercise = this.trainingHistory?.historicalExercicesFeedback.find(
      (e) => e.id === this.exerciseSelected
    ) as ExerciseFeedback;

    if (!exercise) {
      const exercise = this.exercises.find(
        (e) => e.id === this.exerciseSelected
      );

      if (!exercise) {
        this.exerciseFeedbackCalculatedData = null;
        return;
      }

      this.exerciseFeedbackCalculatedData = {
        id: exercise.id,
        name: exercise.name,
        feedbackData: [],
        historicalLoad: [],
        historicalDate: [],
        historicalLoadTable: [],
        historicalDateTable: [],
        historicalDataforTable: [],
      };

      return;
    }

    const startDate = moment().subtract(this.filterPeriodSelected, "days");

    exercise.feedbackData = exercise.feedbackData.filter((f) =>
      moment(f.date).isAfter(startDate)
    );

    exercise.historicalLoad = [];
    exercise.historicalDate = [];
    exercise.historicalLoadTable = [];
    exercise.historicalDateTable = [];
    exercise.historicalDataforTable = [];

    for (let data of exercise.feedbackData) {
      const date = moment(data.date).format("DD/MM/YYYY");

      if (data.feedback != null && date != null) {
        exercise.historicalDataforTable.push({
          date: date,
          feedback: data.feedback,
        });
        exercise.historicalLoadTable.push(data.feedback);
        exercise.historicalDateTable.push(date);
      }

      exercise.historicalLoadTable.push(data.feedback);
      exercise.historicalDateTable.push(date);

      if (data.feedback != null && Number.isInteger(data.feedback)) {
        exercise.historicalLoad.push(data.feedback);
        exercise.historicalDate.push(date);
      }
    }

    this.exerciseFeedbackCalculatedData = exercise;
  }

  changeExercise(exerciseSelected: ExerciseType | number | null | undefined) {
    const exerciseId =
      typeof exerciseSelected === "number"
        ? exerciseSelected
        : exerciseSelected?.id;

    let exercise = this.trainingHistory?.historicalExercicesFeedback.find(
      (e) => e.id === exerciseId
    ) as ExerciseFeedback;

    if (!exercise) {
      const exercise = this.exercises.find((e) => e.id === exerciseId);

      if (!exercise) {
        this.exerciseFeedbackCalculatedData = null;
        return;
      }

      this.exerciseFeedbackCalculatedData = {
        id: exercise.id,
        name: exercise.name,
        feedbackData: [],
        historicalLoad: [],
        historicalDate: [],
        historicalLoadTable: [],
        historicalDateTable: [],
        historicalDataforTable: [],
      };

      return;
    }

    exercise["historicalLoad"] = [];
    exercise["historicalDate"] = [];
    exercise["historicalLoadTable"] = [];
    exercise["historicalDateTable"] = [];
    exercise["historicalDataforTable"] = [];

    for (let data of exercise.feedbackData) {
      const date = moment(data.date).format("DD/MM/YYYY");

      if (data.feedback != null && date != null) {
        exercise.historicalDataforTable.push({
          date: date,
          feedback: data.feedback,
        });
        exercise.historicalLoadTable.push(data.feedback);
        exercise.historicalDateTable.push(date);
      }

      exercise.historicalLoadTable.push(data.feedback);
      exercise.historicalDateTable.push(date);

      if (data.feedback != null && Number.isInteger(data.feedback)) {
        exercise.historicalLoad.push(data.feedback);
        exercise.historicalDate.push(date);
      }
    }

    this.exerciseFeedbackCalculatedData = exercise;
  }

  close() {
    this.activeModal.close();
  }
}
