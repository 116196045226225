<div class="card m-0 position-relative">
  <button
    type="button"
    (click)="closeModal()"
    class="btn btn-danger btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
  >
    <i class="ft-x font-medium-2 m-0"></i>
  </button>

  <div class="card-header">
    <h4 class="card-title">
      {{ newProduct.id ? "Edite seu produto" : "Cadastre seu produto" }}
    </h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
        <aw-wizard-step
          [stepTitle]="'Produto'"
          [navigationSymbol]="{
            symbol: '&#xf05a;',
            fontFamily: 'FontAwesome'
          }"
        >
          <form #productData="ngForm" class="editForm" novalidate>
            <br />
            <div class="row text-left mb-4">
              <div class="col-sm-12">
                <button
                  class="btn btn-warning border-right-warning mb-2 mb-md-0"
                  (click)="helperInfos(1, inputsHelper)"
                  placement="top"
                >
                  <i class="fa fa-question-circle"></i> Veja como preencher as
                  informações
                </button>
              </div>
            </div>

            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nome">
                      Insira o nome do seu produto
                      <i
                        class="fa fa-info-circle"
                        ngbTooltip="Ex: Consultoria Online - Plano Trimestral"
                      >
                      </i>
                    </label>

                    <input
                      type="text"
                      id="nome"
                      class="form-control"
                      [(ngModel)]="newProduct.name"
                      name="nome"
                      placeholder="Ex: Consultoria Online - Plano Trimestral"
                      #inputnameplan="ngModel"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputnameplan.errors?.required"
                    >
                      Informe o Nome
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nome"
                      >Descrição do Produto
                      <i
                        class="fa fa-info-circle"
                        ngbTooltip="Insira as principais informações sobre seu produto: o que é, como o aluno irá receber, vigência, etc"
                      ></i
                    ></label>
                    <textarea
                      id="desc"
                      rows="2"
                      class="form-control"
                      name="desc"
                      #planDescription
                      maxlength="255"
                      [(ngModel)]="newProduct.description"
                      #inputDesc="ngModel"
                      placeholder="Insira as principais informações sobre seu produto: o que é, como o aluno irá receber, vigência, etc"
                      required
                    ></textarea>
                    <div class="form-row__description">
                      {{ 255 - planDescription.value.length }} caracteres
                      restantes.
                    </div>
                    <small
                      *ngIf="inputDesc.errors?.required"
                      class="form-text text-muted danger"
                    >
                      Informe a Descrição
                    </small>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row mb-4">
              <div class="col-12 col-lg-6 mb-2">
                <div class="w-100 d-flex flex-column align-items-center">
                  <button
                    type="button"
                    placement="top"
                    (click)="selecionarImagemProduto()"
                    class="w-100 btn btn-lg btn-outline-warning"
                    style="font-weight: 600"
                  >
                    Imagem do Produto
                  </button>
                  <img
                    *ngIf="product_image_url"
                    [src]="product_image_url"
                    width="350"
                    class="w-100 mt-2 mx-auto"
                  />
                </div>
              </div>
              <div class="col-12 col-lg-6 mb-2">
                <div class="w-100 d-flex flex-column align-items-center">
                  <button
                    type="button"
                    class="w-100 btn btn-lg btn-outline-warning"
                    style="font-weight: 600"
                    (click)="selecionarImagemLogoObrigado()"
                    placement="top"
                  >
                    Sua Logo
                  </button>
                  <img
                    *ngIf="delivery_logo_url"
                    [src]="delivery_logo_url"
                    width="350"
                    class="w-100 mt-2 mx-auto"
                  />
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-lg btn-danger mr-2"
                  (click)="closeModal()"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  class="btn btn-lg btn-success"
                  [disabled]="!productData.valid"
                  awNextStep
                >
                  Próximo Passo
                </button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step
          [stepTitle]="'Pagamento'"
          [navigationSymbol]="{
            symbol: '&#xf05a;',
            fontFamily: 'FontAwesome'
          }"
        >
          <form #productData="ngForm" class="editForm" novalidate>
            <br />
            <div class="row text-left mb-4">
              <div class="col-sm-12">
                <button
                  class="btn btn-warning border-right-warning mb-2 mb-md-0"
                  (click)="helperInfos(1, inputsHelper)"
                  placement="top"
                >
                  <i class="fa fa-question-circle"></i> Veja como preencher as
                  informações
                </button>
              </div>
            </div>

            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="nome">selecione o tipo de cobrança</label>
                  <ng-select
                    [items]="tiposPlanos"
                    bindLabel="label"
                    name="tipoCobranca"
                    placeholder="Selecionar Cobrança"
                    id="basic-form-model"
                    [(ngModel)]="newProduct.plan_type"
                    [disabled]="newProduct.id"
                    required="true"
                  ></ng-select>
                </div>
              </div>
              <div class="col-12">
                <div
                  class="form-group"
                  *ngIf="
                    newProduct &&
                    newProduct.plan_type &&
                    newProduct.plan_type.id == 1
                  "
                >
                  <div class="controls">
                    <label for="monthly_value">Valor Mensal a Cobrar</label>
                    <input
                      type="text"
                      id="value"
                      class="form-control"
                      prefix="R$ "
                      mask="separator.2"
                      thousandSeparator="."
                      [(ngModel)]="productsPlans.monthly.value_cents"
                      name="monthly_value"
                      placeholder="Valor Total do Plano"
                      #inputpriceplan="ngModel"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputpriceplan.errors?.required"
                      >Informe o Preço</small
                    >
                  </div>
                </div>
                <div
                  class="form-group"
                  *ngIf="
                    newProduct &&
                    newProduct.plan_type &&
                    newProduct.plan_type.id == 0
                  "
                >
                  <div class="controls">
                    <label for="monthly_value">Valor Total a Cobrar</label>
                    <input
                      type="text"
                      id="value"
                      class="form-control"
                      prefix="R$ "
                      mask="separator.2"
                      thousandSeparator="."
                      [(ngModel)]="newProduct.value_cents"
                      name="monthly_value"
                      placeholder="Valor Total do Plano"
                      #inputpriceplan="ngModel"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputpriceplan.errors?.required"
                      >Informe o Preço</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <div
              class="form-row"
              *ngIf="
                newProduct &&
                newProduct.plan_type &&
                newProduct.plan_type.id == 1
              "
            >
              <div class="col-12">
                <div class="form-group">
                  <div class="controls">
                    <label for="monthly_value"
                      >Cobrar durante quantos meses?</label
                    ><br />
                    <small
                      >Digite 0 (zero) se não quer que a cobrança tenha
                      término</small
                    >
                    <input
                      type="number"
                      id="value"
                      class="form-control"
                      [(ngModel)]="productsPlans.monthly.max_cycles"
                      name="max_cycles"
                      placeholder="Cobrar durante quantos meses?"
                      #inputmaxcycles="ngModel"
                      [disabled]="newProduct.id"
                      required
                    />
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputmaxcycles.errors?.required"
                      >Informe a quantidade de meses.</small
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group scrollable-box-2">
                  <div class="controls">
                    <label for="tipoPagamentoEscolhido"
                      >Tipo de Pagamento</label
                    >
                    <ng-select
                      [appendTo]="'.scrollable-box-2'"
                      [notFoundText]="'Nenhuma forma de Pagamento encontrada'"
                      [items]="tiposPagamento"
                      bindLabel="label"
                      name="model"
                      placeholder="Selecionar Formas de Pagamento"
                      id="tiposPagamento"
                      [(ngModel)]="tipoPagamentoEscolhido"
                      (change)="checkFormasPagamento()"
                      multiple="true"
                      #inputtipopagamento="ngModel"
                      required="true"
                    >
                      <ng-template
                        ng-multi-label-tmp
                        let-items="items"
                        let-clear="clear"
                      >
                        <div
                          *ngIf="tipoPagamentoEscolhido.length == 1"
                          class="ng-values-list"
                        >
                          <div
                            class="ng-value"
                            *ngFor="let item of items | slice : 0 : 1"
                          >
                            <span class="ng-value-label">{{ item.label }}</span>
                            <span
                              class="ng-value-icon right"
                              (click)="clear(item)"
                              >×</span
                            >
                          </div>
                        </div>
                        <div
                          *ngIf="tipoPagamentoEscolhido.length > 1"
                          class="ng-summary-list"
                        >
                          <div class="ng-value">
                            <span class="ng-value-label"
                              >{{
                                tipoPagamentoEscolhido.length
                              }}
                              Selecionados</span
                            >
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                    <small
                      class="form-text text-muted danger"
                      *ngIf="inputtipopagamento.errors?.required"
                      >Informe o Tipo de Pagamento</small
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div
                class="col-md-12 col-12"
                *ngIf="
                  newProduct &&
                  newProduct.plan_type &&
                  newProduct.plan_type.id == 1
                "
              >
                <div class="form-group">
                  <div class="controls">
                    <label for="ttt">Atrelar perfil do Aluno?</label>
                    <ui-switch
                      class="switchery switchery-warning"
                      id="ttt"
                      [checked]="isAtrelarAluno"
                      (change)="changeAtrelarAluno($event)"
                    ></ui-switch>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-lg btn-secondary mr-2"
                  awPreviousStep
                >
                  Voltar
                </button>
                <button
                  type="button"
                  class="btn btn-lg btn-success"
                  [disabled]="!productData.valid"
                  awNextStep
                >
                  Próximo Passo
                </button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step
          [stepTitle]="'Entrega'"
          [navigationSymbol]="{
            symbol: '&#xf15b;',
            fontFamily: 'FontAwesome'
          }"
        >
          <form #deliveryData="ngForm" class="editForm" novalidate>
            <div class="row text-left mb-4">
              <div class="col-sm-12">
                <button
                  class="btn btn-warning border-right-warning mb-2 mb-md-0"
                  (click)="helperInfos(2, inputsHelper)"
                  placement="top"
                >
                  <i class="fa fa-question-circle"></i> Veja como preencher as
                  informações
                </button>
              </div>
            </div>
            <p class="text-center">
              Insira as informações necessárias que irão aparecer para o
              cliente, logo após a compra do seu produto.
            </p>
            <br />
            <div class="form-group mb-2">
              <p class="text-center text-bold-600">
                CONTEÚDO DA PÁGINA DE OBRIGADO
                <i
                  class="fa fa-info-circle"
                  ngbTooltip="Insira as principais informações sobre seu produto: o que é, como o aluno irá receber, vigência, etc"
                ></i>
              </p>
              <small class="text-center"
                >Insira uma imagem de "compra concluída" ou um vídeo de
                agradecimento com os próximos passos do seu cliente</small
              >
            </div>
            <div class="form-row mb-2">
              <div class="col text-center">
                <div class="d-md-none">
                  <div
                    class="btn-group btn-lg p-0"
                    style="width: 50%"
                    role="group"
                  >
                    <button
                      type="button"
                      class="btn btn-lg gradient-mint"
                      (click)="toggleVideoOrGif(true, true)"
                      style="width: 33%"
                      placement="top"
                      ngbTooltip="Você pode inserir um link de um vídeo do Youtube para a página de compra finalizada."
                    >
                      <i class="fa fa-youtube"></i>
                    </button>
                    <button
                      type="button"
                      style="width: 33%"
                      class="btn btn-lg gradient-mint"
                      (click)="toggleVideoOrGif(true, false)"
                      placement="top"
                      ngbTooltip="Você pode fazer upload do vídeo da página de obrigado."
                    >
                      <i class="fa fa-upload"></i>
                    </button>
                    <button
                      type="button"
                      style="width: 33%"
                      class="btn btn-lg gradient-mint"
                      (click)="toggleVideoOrGif(false, false)"
                      placement="top"
                      ngbTooltip="Você pode fazer upload de uma imagem para a página de obrigado."
                    >
                      <i class="fa fa-image"></i>
                    </button>
                  </div>
                </div>
                <div class="d-none d-md-block">
                  <div
                    class="btn-group btn-lg p-0"
                    style="width: 50%"
                    role="group"
                  >
                    <button
                      type="button"
                      class="btn btn-lg gradient-mint"
                      (click)="toggleVideoOrGif(true, true)"
                      style="width: 33%"
                      placement="top"
                      ngbTooltip="Você pode inserir um link de um vídeo do Youtube para a página de compra finalizada."
                    >
                      Link do YouTube
                    </button>
                    <button
                      type="button"
                      style="width: 33%"
                      class="btn btn-lg gradient-mint"
                      (click)="toggleVideoOrGif(true, false)"
                      placement="top"
                      ngbTooltip="Você pode fazer upload do vídeo da página de obrigado."
                    >
                      Upload Video
                    </button>
                    <button
                      type="button"
                      style="width: 33%"
                      class="btn btn-lg gradient-mint"
                      (click)="toggleVideoOrGif(false, false)"
                      placement="top"
                      ngbTooltip="Você pode fazer upload de uma imagem para a página de obrigado."
                    >
                      Upload Imagem
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <p class="text-center">
                Seu vídeo não está em formato MP4?
                <a
                  href="https://convert-video-online.com/pt"
                  target="_blank"
                  rel="noopener noreferrer"
                  >Clique aqui para converter.</a
                >
              </p>
            </div>
            <div class="form-row mb-2" *ngIf="url_video">
              <div class="col text-center">
                <ng-template
                  [ngIf]="
                    url_video.startsWith('https://www.youtube.com/') ||
                    url_video.startsWith('http://www.youtube.com/')
                  "
                >
                  <div id="video" #videoYtb class="auto-resizable-iframe">
                    <div>
                      <iframe
                        frameborder="0"
                        allowfullscreen=""
                        [src]="safeURL"
                      ></iframe>
                    </div>
                  </div>
                </ng-template>
                <ng-template
                  [ngIf]="
                    !url_video.startsWith('https://www.youtube.com/') &&
                    !url_video.startsWith('http://www.youtube.com/')
                  "
                >
                  <video
                    #video
                    id="video"
                    controls
                    style="
                      margin-left: auto;
                      margin-right: auto;
                      max-width: 500px;
                    "
                  >
                    <source [src]="url_video" type="video/mp4" />
                  </video>
                </ng-template>
              </div>
            </div>
            <div class="form-row mb-4" *ngIf="delivery_image_url">
              <div class="col text-center">
                <img
                  [src]="delivery_image_url"
                  style="
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 350px;
                  "
                />
              </div>
            </div>
            <hr />
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nome"
                      >Informações da Página de Entrega do Produto
                      <i
                        class="fa fa-info-circle"
                        ngbTooltip="Mensagem que seu cliente irá receber imediatamente após a compra do seu produto."
                      ></i
                    ></label>

                    <textarea
                      id="desc"
                      rows="2"
                      class="form-control"
                      name="desc"
                      #delivery_instructions
                      maxlength="255"
                      [(ngModel)]="newProduct.delivery_instructions"
                      #instructions="ngModel"
                      placeholder="Mensagem que seu cliente irá receber imediatamente após a compra do seu produto."
                      required
                    ></textarea>
                    <div class="form-row__description">
                      {{ 255 - delivery_instructions.value.length }}
                      caracteres restantes.
                    </div>
                    <small
                      class="form-text text-muted danger"
                      *ngIf="instructions.errors?.required"
                      >Informe as Informações da Página de Entrega do
                      Produto</small
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="nome"
                      >Link de Acesso ao Produto
                      <i
                        class="fa fa-info-circle"
                        ngbTooltip="Insira aqui o link de acesso ao seu produto (link de convite do app, desafio, ebook, etc)"
                      ></i
                    ></label>
                    <input
                      type="url"
                      id="delivery_link"
                      class="form-control"
                      name="delivery_link"
                      [(ngModel)]="newProduct.delivery_link"
                      #inputDesc="ngModel"
                      placeholder="Insira aqui o link de acesso ao seu produto (link de convite do app, desafio, ebook, etc)"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-lg btn-light mr-2"
                  awPreviousStep
                >
                  Voltar
                </button>
                <button
                  type="button"
                  class="btn btn-lg btn-success"
                  (click)="newProduct.id ? submitUpdatePlan() : submitPlan()"
                  [disabled]="!deliveryData.valid"
                  awNextStep
                >
                  Cadastrar Produto
                </button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
</div>

<ng-template #inputsHelper let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">
        {{ helperType == 1 ? "Página de Compra" : "Página de Obrigado" }}
      </h4>
    </div>
    <div class="card-body">
      <div class="col text-center">
        <img
          *ngIf="helperImage"
          [src]="helperImage"
          style="margin-left: auto; margin-right: auto; width: 100%"
        />
      </div>
    </div>
  </div>
</ng-template>
