import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

export type FeedbackType = "positive" | "negative" | "neutral";

@Component({
  selector: "app-final-feedback",
  templateUrl: "./final-feedback.component.html",
  styleUrls: ["./final-feedback.component.scss"],
})
export class FinalFeedbackComponent {
  apiCall = this.api.new().silent();

  feedback: FeedbackType | null = null;
  notes: string | null = null;

  loading = false;

  @Input() trainingId: number | null = null;
  @Input() callback: (action: "close" | "feedback") => void = () => {};

  constructor(
    private activeModal: NgbActiveModal,
    private api: Api,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  close() {
    this.activeModal.close();
  }

  closeButton() {
    this.close();
    this.callback("close");
  }

  setFeedback(feedback: FeedbackType) {
    this.feedback = feedback;
  }

  send() {
    this.loading = true;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall
      .post("training/generatorFeedback", {
        feedback: {
          training: this.trainingId,
          feedback: (() => {
            switch (this.feedback) {
              case "positive":
                return 3;
              case "negative":
                return 1;
              case "neutral":
                return 2;
            }
          })(),
          notes: this.notes,
          step: "last",
        },
      })
      .subscribe((response) => {
        this.loading = false;
        this.spinner.hide();

        if (!response.success) {
          this.toastr.error("Erro ao enviar feedback!");
          return;
        }

        this.toastr.success("Feedback enviado com sucesso!");
        this.close();
        this.callback("feedback");
      });
  }
}
