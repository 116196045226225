<div class="container">
  <div class="boleto-container">
    <div class="boleto-header">
      <div class="boleto-info">
        <div class="linha-digitavel">{{ bankSlip.digitable_line }}</div>
        <div class="dados-boleto">
          <span><strong>Vencimento:</strong> {{ formatDate(invoice.dueDate) }}</span>
          <span><strong>Valor:</strong> R$ {{ invoice.items[0].price }}</span>
        </div>
      </div>
    </div>
    <div class="boleto-body">
      <div class="boleto-section">
        <div class="boleto-sacado">
          <span><strong>Sacado:</strong></span>
          <span>{{ invoice.payerName }}</span>
        </div>
        <div class="boleto-sacado">
          <span><strong>CPF/CNPJ:</strong></span>
          <span>{{ maskCnpjCpf(invoice.bankSlip.recipient_cpf_cnpj) }}</span>
        </div>
      </div>
      <div class="boleto-section">
        <div class="boleto-descricao">
          <span><strong>Descrição:</strong></span>
          <span>{{ invoice.items[0].description }}</span>
        </div>
      </div>
      <div class="boleto-section">
        <div class="boleto-total-nosso-numero">
          <div class="boleto-total">
            <span><strong>Total:</strong></span>
            <span>{{ invoice.total }}</span>
          </div>
        </div>
      </div>
      <div class="boleto-codigo-barras">
        <div class="linha-digitavel">{{ bankSlip.digitable_line }}</div>
        <img [src]="bankSlip.barcode" alt="Código de Barras">
        <button (click)="copyBarcode()" class="btn-warning">Copiar Código de Barras</button>
      </div>
    </div>
  </div>
</div>

