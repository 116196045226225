<div class="card m-0">
  <div class="card-header py-3 bg-primary position-relative">
    <h4 class="card-title text-center white font-medium-4 m-0">
      Evolução de Carga
    </h4>
    <button
      type="button"
      title="Fechar"
      class="btn btn-primary btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
      (click)="close()"
    >
      <i class="fa fa-times font-bold-400 white" aria-hidden="true"></i>
    </button>
  </div>
  <div class="card-content">
    <div class="card-body px-3">
      <div
        class="w-100 d-flex flex-column flex-lg-row justify-content-start align-items-center justify-content-lg-center"
      >
        <div class="form-group select-container mb-lg-0 mr-lg-3">
          <ng-select
            [clearable]="false"
            [notFoundText]="'Nenhum exercicio encontrado'"
            [items]="filterPeriod"
            bindLabel="description"
            bindValue="days"
            name="filterPeriod"
            placeholder="Selecionar Grupo"
            id="filterPeriod"
            [disabled]="isLoading"
            (change)="changePeriod()"
            [(ngModel)]="filterPeriodSelected"
          >
          </ng-select>
        </div>
        <div class="form-group select-container mb-0">
          <ng-select
            [clearable]="false"
            [notFoundText]="'Nenhum exercício encontrado'"
            [items]="exercises"
            bindLabel="name"
            bindValue="id"
            name="filteredExercise"
            placeholder="Selecionar Exercício"
            id="filterExercise"
            [disabled]="isLoading"
            (change)="changeExercise($event)"
            [(ngModel)]="exerciseSelected"
          >
          </ng-select>
        </div>
      </div>
      <div class="w-100 mt-2">
        <ul
          ngbNav
          [(activeId)]="activeTabId"
          #nav="ngbNav"
          class="nav-tabs w-100 d-flex justify-content-center border-0"
        >
          <li ngbNavItem [ngbNavItem]="'graph'">
            <a ngbNavLink href="javascript:void(0)">
              <button
                type="button"
                class="btn btn-sm d-flex justify-content-center align-items-center font-bold-400"
              >
                Gráfico
              </button>
            </a>
            <ng-template ngbNavContent>
              <div
                *ngIf="isLoading && exerciseSelected"
                class="w-100 d-flex justify-content-center align-items-center overflow-hidden py-4"
              >
                <div
                  class="d-flex justify-content-center align-items-center load-spinner-content"
                >
                  <div class="load-spinner">
                    <i class="ft-loader text-primary"></i>
                  </div>
                  <span class="ml-2 font-size-4 font-size-md-5"
                    >Carregando...</span
                  >
                </div>
              </div>
              <div
                *ngIf="
                  (!exerciseFeedbackCalculatedData && !isLoading) ||
                  (!exerciseSelected && isLoading)
                "
                class="w-100 d-flex justify-content-center py-3"
              >
                <span class="font-size-5 font-size-md-6">
                  Selecione um exercício para ver o gráfico
                </span>
              </div>
              <div
                *ngIf="exerciseFeedbackCalculatedData && !isLoading"
                class="row"
              >
                <div class="col">
                  <div class="card m-0">
                    <div class="card-header">
                      <h4 class="card-title text-center mb-2">
                        {{
                          exerciseFeedbackCalculatedData
                            ? exerciseFeedbackCalculatedData.name
                            : "-----"
                        }}
                      </h4>
                    </div>
                    <div class="card-content">
                      <div class="card-body px-0">
                        <div class="w-100 d-flex justify-content-center">
                          <span
                            class="text-center"
                            *ngIf="
                              !exerciseFeedbackCalculatedData ||
                              (exerciseFeedbackCalculatedData &&
                                exerciseFeedbackCalculatedData.historicalLoad &&
                                exerciseFeedbackCalculatedData.historicalLoad
                                  .length == 0)
                            "
                          >
                            Sem dados de feedback para o período ou exercício
                            selecionado!
                          </span>
                        </div>
                        <div
                          id="line-chart1"
                          class="height-400"
                          *ngIf="
                            exerciseFeedbackCalculatedData.historicalLoad
                              .length > 0
                          "
                        >
                          <apx-chart
                            [series]="[
                              {
                                name: lineAreaChartOptions.series[0].name,
                                data: exerciseFeedbackCalculatedData.historicalLoad
                              }
                            ]"
                            [colors]="lineAreaChartOptions.colors"
                            [chart]="lineAreaChartOptions.chart"
                            [xaxis]="lineAreaChartOptions.xaxis"
                            [yaxis]="lineAreaChartOptions.yaxis"
                            [title]="lineAreaChartOptions.title"
                            [dataLabels]="lineAreaChartOptions.dataLabels"
                            [stroke]="lineAreaChartOptions.stroke"
                            [grid]="lineAreaChartOptions.grid"
                            [tooltip]="lineAreaChartOptions.tooltip"
                            [plotOptions]="lineAreaChartOptions.plotOptions"
                            [fill]="lineAreaChartOptions.fill"
                            [legend]="lineAreaChartOptions.legend"
                            [labels]="
                              exerciseFeedbackCalculatedData.historicalDate
                            "
                            [markers]="lineAreaChartOptions.markers"
                            [theme]="lineAreaChartOptions.theme"
                            [responsive]="lineAreaChartOptions.responsive"
                          ></apx-chart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li ngbNavItem [ngbNavItem]="'table'">
            <a ngbNavLink href="javascript:void(0)">
              <button
                type="button"
                class="btn btn-sm d-flex justify-content-center align-items-center font-bold-400"
              >
                Tabela
              </button>
            </a>
            <ng-template ngbNavContent>
              <div
                *ngIf="isLoading && exerciseSelected"
                class="w-100 d-flex justify-content-center align-items-center overflow-hidden py-4"
              >
                <div
                  class="d-flex justify-content-center align-items-center load-spinner-content"
                >
                  <div class="load-spinner">
                    <i class="ft-loader text-primary"></i>
                  </div>
                  <span class="ml-2 font-size-4 font-size-md-5"
                    >Carregando...</span
                  >
                </div>
              </div>
              <div
                *ngIf="
                  (!exerciseFeedbackCalculatedData && !isLoading) ||
                  (!exerciseSelected && isLoading)
                "
                class="w-100 d-flex justify-content-center py-3"
              >
                <span class="font-size-5 font-size-md-6">
                  Selecione um exercício para ver a tabela
                </span>
              </div>
              <div
                *ngIf="exerciseFeedbackCalculatedData && !isLoading"
                class="row"
              >
                <div class="col">
                  <div class="card m-0">
                    <div class="card-header">
                      <h4 class="card-title text-center mb-2">
                        {{
                          exerciseFeedbackCalculatedData
                            ? exerciseFeedbackCalculatedData.name
                            : "-----"
                        }}
                      </h4>
                    </div>
                    <div class="card-content">
                      <div class="card-body px-0">
                        <div class="w-100 d-flex justify-content-center">
                          <span
                            class="text-center"
                            *ngIf="
                              !exerciseFeedbackCalculatedData ||
                              (exerciseFeedbackCalculatedData &&
                                exerciseFeedbackCalculatedData.historicalDataforTable &&
                                exerciseFeedbackCalculatedData
                                  .historicalDataforTable.length == 0)
                            "
                          >
                            Sem dados de feedback para o período ou exercício
                            selecionado!
                          </span>
                        </div>
                        <div
                          class="table text-center m-0"
                          *ngIf="
                            exerciseFeedbackCalculatedData
                              .historicalDataforTable.length > 0
                          "
                        >
                          <table class="w-100 table">
                            <thead>
                              <tr>
                                <th>Data</th>
                                <th>Carga</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let f of exerciseFeedbackCalculatedData.historicalDataforTable
                                    | slice
                                      : (page - 1) * pageSize
                                      : (page - 1) * pageSize + pageSize
                                "
                              >
                                <td>{{ f.date }}</td>
                                <td>{{ f.feedback }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div
                        *ngIf="
                          exerciseFeedbackCalculatedData.historicalDataforTable &&
                          exerciseFeedbackCalculatedData.historicalDataforTable
                            .length > 0
                        "
                        class="form-row m-0 p-2"
                      >
                        <div class="d-flex justify-content-center mx-auto">
                          <ngb-pagination
                            style="margin-top: 10px"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            [maxSize]="2"
                            [rotate]="true"
                            [boundaryLinks]="true"
                            size="sm"
                            [collectionSize]="
                              exerciseFeedbackCalculatedData
                                .historicalDataforTable.length
                            "
                          >
                          </ngb-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="w-100 mt-2">
        <div [ngbNavOutlet]="nav"></div>
      </div>
    </div>
  </div>
</div>
