export const timeObjToSec = (
  timeObj: Partial<{
    hour: number;
    minute: number;
    second: number;
  }>
) => {
  const hour = timeObj.hour || 0;
  const minute = timeObj.minute || 0;
  const second = timeObj.second || 0;

  return hour * 3600 + minute * 60 + second;
};
