<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem">
        Painel Financeiro
      </h2>
    </div>
  </div>
</div>

<div class="row text-left mb-2">
  <div class="col-sm-12">
    <p class="sub-texto">
      Tem alguma dúvida com seu painel financeiro, assista o video abaixo
      <a
        href="javascript:void(0)"
        class="text-warning pulsingButton"
        (click)="OpenModalXl(contentVideo)"
        style="text-decoration: underline; cursor: pointer"
      >
        Ver Vídeo Tutorial
      </a>
    </p>
  </div>
</div>

<div class="row">
  <div class="col-12 col-lg-6">
    <div class="card">
      <div class="card-content">
        <div class="card-body p-0 mt-1">
          <div class="media">
            <div class="row">
              <div class="col-auto">
                <h5
                  style="text-align: left; margin-left: 12px; margin-top: 4px"
                >
                  Autosaque:
                </h5>
              </div>
              <div class="col-auto">
                <ui-switch
                  checked
                  style="align-items: center; justify-content: space-between"
                  class="switchery mr-2 ml-4"
                  placement="top"
                  ngbTooltip="Ative ou desative o saque automatico."
                  (click)="updateAcceptAutoWithdraw()"
                  name="autoWithdraw"
                  [(ngModel)]="autoWithdraw"
                  ngDefaultControl
                ></ui-switch>
              </div>
            </div>
            <div class="col-auto">
              <span
                style="
                  display: flex;
                  justify-content: end;
                  color: #9e9e9e;
                  align-items: center;
                  text-align: center;
                "
                *ngIf="autoWithdraw"
                >Ativado
                <iright
                  class="fa fa-question-circle"
                  style="color: #9e9e9e; align-items: center; margin-left: 10px"
                  placement="top"
                  ngbTooltip="Com o autosaque ativado, toda vez que um pagamento for liberado, será automaticamente sacado para a sua conta bancária cadastrada."
                ></iright
              ></span>

              <span
                style="
                  display: flex;
                  justify-content: space-between;
                  color: #9e9e9e;
                  align-items: center;
                "
                >Desativado
                <i
                  class="fa fa-question-circle"
                  style="color: #9e9e9e; align-items: center; margin-left: 10px"
                  placement="top"
                  ngbTooltip="Com o autosaque Desativado, toda vez que um pagamento for liberado, não será automaticamente sacado para a sua conta bancária cadastrada."
                ></i
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-auto text-right mt-4">
    <button
      class="btn btn-warning border-right-warning mb-2 mb-md-0"
      (click)="openModalSaque(withDrawBalance)"
    >
      <i class="ft-share"></i> Solicitar Saque
    </button>
  </div>
</div>

<div class="row">
  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 warning">
                {{ balancetoWithDraw ? balancetoWithDraw : "R$ 0,00" }}
              </h3>
              <span
                >Saldo Disponível
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="Saldo que está disponível para saque atualmente."
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-user warning font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 warning">
                {{ receivableBalance ? receivableBalance : "R$ 0,00" }}
              </h3>
              <span
                >Saldo a Receber
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="Saldo futuro que está no período de liberação"
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-pie-chart warning font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 secondary">
                {{ balanceInCurrentMonth ? balanceInCurrentMonth : "R$ 0,00" }}
              </h3>
              <span
                >Total de Vendas Mês Atual
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="Valor total de vendas no mês atual."
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-clock secondary font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-3 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 warning">
                {{
                  chargebacks && chargebacks.items
                    ? chargebacks.items.length
                    : 0
                }}
              </h3>
              <span
                >Total de Contestações
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="Número de contestações."
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i
                class="fa fa-exclamation-triangle warning font-large-2 float-right"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row text-left">
  <!--Pills Starts-->
  <div class="col-md-12 col-lg-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <ngb-tabset type="pills">
            <ngb-tab title="Gráficos de Faturamento">
              <ng-template ngbTabContent>
                <div *ngIf="financialData">
                  <div style="display: flex; justify-content: center">
                    <select (change)="onPeriodChange($event.target.value)">
                      <option value="week">Última Semana</option>
                      <option value="month">Último Mês</option>
                      <option value="year" selected>Último Ano</option>
                    </select>
                  </div>

                  <!-- Gráfico de Faturamento -->
                  <app-invoicing-chart
                    [data]="financialData.faturamento"
                  ></app-invoicing-chart>

                  <!-- Gráfico de Vendas -->
                  <app-sales-chart
                    [data]="financialData.vendas"
                  ></app-sales-chart>

                  <!-- Gráfico de Produtos Vendidos -->
                  <app-products-chart
                    [data]="productSalesData"
                  ></app-products-chart>
                </div>
              </ng-template>
            </ngb-tab>

            <ngb-tab title="Faturas">
              <ng-template ngbTabContent>
                <div
                  class="row mb-2 mt-2"
                  *ngIf="invoices && invoices.length > 0"
                >
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <div class="table text-center m-0">
                          <table
                            class="table-striped table-responsive"
                            style="width: 100%"
                          >
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>
                                  <input
                                    type="text"
                                    id="costumerName"
                                    class="form-control"
                                    style="
                                      background-color: white;
                                      height: 100%;
                                    "
                                    placeholder="Nome do Cliente"
                                    name="customerName"
                                    [(ngModel)]="filterCustomerName"
                                    (keyup)="filter()"
                                  />
                                </th>
                                <th>Data de Pagamento</th>
                                <th>
                                  <ng-select
                                    [items]="statusFatura"
                                    name="statusFatura"
                                    bindLabel="name"
                                    placeholder="Status Fatura"
                                    id="statusFatura"
                                    (change)="filter()"
                                    [(ngModel)]="filterStatusFatura"
                                  ></ng-select>
                                </th>
                                <th>Valor Total</th>
                                <th>Valor Líquido</th>
                                <th>Data de Liberação</th>
                                <th>Status de Liberação</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let invoice of invoicesFiltered
                                    | slice
                                      : (page - 1) * pageSize
                                      : (page - 1) * pageSize + pageSize
                                "
                              >
                                <td>
                                  {{ formatId(invoice.invoiceId) }}
                                  <a
                                    class="button btn btn-default"
                                    (click)="copiarId(invoice.invoiceId)"
                                    ngbTooltip="Copiar ID da Fatura."
                                    ><i class="fa fa-clipboard"></i
                                  ></a>
                                </td>
                                <td>{{ invoice.payer_name }}</td>
                                <td>
                                  {{
                                    invoice.paidAt
                                      ? formatDate(invoice.paidAt)
                                      : "---"
                                  }}
                                </td>
                                <td>
                                  {{ returnInvoiceStatus(invoice.status) }}
                                </td>
                                <td>
                                  {{
                                    invoice.total
                                      ? formatPreco(invoice.total)
                                      : "---"
                                  }}
                                </td>
                                <td>
                                  {{
                                    invoice.liquid
                                      ? formatPreco(invoice.liquid)
                                      : "---"
                                  }}
                                </td>
                                <td>
                                  {{
                                    invoice.releaseDate
                                      ? formatDate(invoice.releaseDate)
                                      : "---"
                                  }}
                                </td>
                                <td>
                                  {{
                                    invoice.releaseStatus
                                      ? invoice.releaseStatus == "done"
                                        ? "Liberado"
                                        : "Pendente"
                                      : "---"
                                  }}
                                </td>
                                <td>
                                  <div
                                    class="dropdown btn-group-sm mr-1 mb-1"
                                    ngbDropdown
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-outline-warning btn-sm dropdown-toggle"
                                      ngbDropdownToggle
                                    >
                                      <i class="ft-more-vertical"></i>
                                    </button>
                                    <div
                                      ngbDropdownMenu
                                      class="dropdown-menu"
                                      role="menu"
                                    >
                                      <a
                                        class="dropdown-item"
                                        (click)="reembolsarFatura(invoice)"
                                        >Reembolsar Pagamento</a
                                      >
                                      <a
                                        class="dropdown-item"
                                        *ngIf="invoice"
                                        (click)="openSecureUrl(invoice)"
                                        >Visualizar Fatura</a
                                      >
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="form-row" style="margin: 5px">
                        <div
                          class="d-flex justify-content-center"
                          style="margin-left: auto; margin-right: auto"
                        >
                          <ngb-pagination
                            style="margin-top: 10px"
                            *ngIf="invoicesFiltered.length > 0"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            [maxSize]="2"
                            [rotate]="true"
                            [boundaryLinks]="true"
                            size="sm"
                            [collectionSize]="invoicesFiltered.length"
                          ></ngb-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-2 mt-2" *ngIf="invoicesFiltered.length == 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <p>Nenhuma fatura localizada!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>

            <ngb-tab title="Saques">
              <ng-template ngbTabContent>
                <div class="row mb-2 mt-2" *ngIf="withdraws.length > 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <div class="table text-center m-0">
                          <table
                            class="table-striped table-responsive"
                            style="width: 100%"
                          >
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Data Solicitação</th>
                                <th>Data Prevista de Pagamento</th>
                                <th>Valor</th>
                                <th>Situação</th>
                                <th>Banco</th>
                                <th>Agência</th>
                                <th>Conta</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let withdraw of withdrawsFiltered
                                    | slice
                                      : (page - 1) * pageSize
                                      : (page - 1) * pageSize + pageSize
                                "
                              >
                                <td>
                                  {{ formatId(withdraw.withdrawRequestsId)
                                  }}<a
                                    class="button btn btn-default"
                                    (click)="
                                      copiarId(withdraw.withdrawRequestsId)
                                    "
                                    ngbTooltip="Copiar ID do Saque."
                                    ><i class="fa fa-clipboard"></i
                                  ></a>
                                </td>
                                <td>{{ formatDate(withdraw.created_at) }}</td>
                                <td>
                                  {{ formatDateNotLocale(withdraw.paying_at) }}
                                </td>
                                <td>{{ withdraw.amount }}</td>
                                <td>
                                  {{ returnWithdrawStatus(withdraw.status) }}
                                </td>
                                <td>
                                  {{ withdraw.bank ? withdraw.bank : "---" }}
                                </td>
                                <td>
                                  {{
                                    withdraw.bank_ag ? withdraw.bank_ag : "---"
                                  }}
                                </td>
                                <td>
                                  {{
                                    withdraw.bank_cc ? withdraw.bank_cc : "---"
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="form-row" style="margin: 5px">
                        <div
                          class="d-flex justify-content-center"
                          style="margin-left: auto; margin-right: auto"
                        >
                          <ngb-pagination
                            style="margin-top: 10px"
                            *ngIf="withdrawsFiltered.length > 0"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            [maxSize]="2"
                            [rotate]="true"
                            [boundaryLinks]="true"
                            size="sm"
                            [collectionSize]="withdrawsFiltered.length"
                          ></ngb-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-2 mt-2" *ngIf="withdraws.length == 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <p>Nenhum pedido de saque localizado</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>

            <ngb-tab title="Contestações">
              <ng-template ngbTabContent>
                <div class="row mb-2 mt-2" *ngIf="withdraws.length > 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <div class="table text-center m-0">
                          <table
                            class="table-striped table-responsive"
                            style="width: 100%"
                          >
                            <thead>
                              <tr>
                                <th>ID</th>
                                <th>Data Contestação</th>
                                <th>Data Limite Resposta</th>
                                <th>Status</th>
                                <th>ID Fatura</th>
                                <th>Cliente</th>
                                <th>Valor</th>
                                <th>Data do Pagamento</th>
                                <th>Status de Envio</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                *ngFor="
                                  let chargeback of chargebacks.items
                                    | slice
                                      : (page - 1) * pageSize
                                      : (page - 1) * pageSize + pageSize
                                "
                              >
                                <td>
                                  {{ formatId(chargeback.id)
                                  }}<a
                                    class="button btn btn-default"
                                    (click)="copiarId(chargeback.id)"
                                    ngbTooltip="Copiar ID do Saque."
                                    ><i class="fa fa-clipboard"></i
                                  ></a>
                                </td>
                                <td>{{ formatDate(chargeback.created_at) }}</td>
                                <td>{{ formatDate(chargeback.expires_at) }}</td>
                                <td>{{ formatStatus(chargeback.status) }}</td>
                                <td>{{ formatId(chargeback.invoice_id) }}</td>
                                <td>{{ chargeback.fatura.payer_name }}</td>
                                <td>
                                  {{ formatPreco(chargeback.fatura.total) }}
                                </td>
                                <td>
                                  {{ formatDate(chargeback.fatura.paidAt) }}
                                </td>
                                <td>
                                  <a
                                    class="button btn btn-default"
                                    *ngIf="chargeback.feedback == 'expirado'"
                                    ngbTooltip="Prazo do envio expirado."
                                    ><i class="fa fa-window-minimize"></i
                                  ></a>
                                  <a
                                    class="button btn btn-default"
                                    *ngIf="chargeback.feedback == 'pendente'"
                                    (click)="disputarContestacao(chargeback)"
                                    ngbTooltip="Documentação não enviada."
                                    ><i class="fa fa-hourglass-half"></i
                                  ></a>
                                  <a
                                    class="button btn btn-default"
                                    *ngIf="chargeback.feedback == 'enviado'"
                                    ngbTooltip="Documentação enviada."
                                    ><i class="fa fa-check-square-o"></i
                                  ></a>
                                </td>

                                <td>
                                  <div
                                    class="dropdown btn-group-sm mr-1 mb-1"
                                    ngbDropdown
                                  >
                                    <button
                                      type="button"
                                      class="btn btn-outline-warning btn-sm dropdown-toggle"
                                      ngbDropdownToggle
                                    >
                                      <i class="ft-more-vertical"></i>
                                    </button>
                                    <div
                                      ngbDropdownMenu
                                      class="dropdown-menu"
                                      role="menu"
                                    >
                                      <a
                                        class="dropdown-item"
                                        (click)="acatarContestacao(chargeback)"
                                        >Acatar Contestação</a
                                      >
                                      <a
                                        class="dropdown-item"
                                        *ngIf="
                                          chargeback.feedback == 'pendente'
                                        "
                                        (click)="
                                          disputarContestacao(chargeback)
                                        "
                                        >Disputar Contestação</a
                                      >
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="form-row" style="margin: 5px">
                        <div
                          class="d-flex justify-content-center"
                          style="margin-left: auto; margin-right: auto"
                        >
                          <ngb-pagination
                            style="margin-top: 10px"
                            *ngIf="withdrawsFiltered.length > 0"
                            [(page)]="page"
                            [pageSize]="pageSize"
                            [maxSize]="2"
                            [rotate]="true"
                            [boundaryLinks]="true"
                            size="sm"
                            [collectionSize]="withdrawsFiltered.length"
                          ></ngb-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-2 mt-2" *ngIf="withdraws.length == 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0">
                        <p>Nenhum pedido de saque localizado</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
  <!--Pills Ends-->
</div>

<ng-template #withDrawBalance>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col">
            <h5>Solicitar Saque</h5>
            <p>
              <b>Saldo Disponível: </b
              >{{ balancetoWithDraw ? balancetoWithDraw : "R$ 0,00" }}
            </p>
            <div class="form-group">
              <div class="controls">
                <label for="value_cents">Valor a Sacar</label>
                <input
                  type="text"
                  id="inputWithdraw"
                  class="form-control"
                  prefix="R$"
                  mask="separator.2"
                  thousandSeparator="."
                  [(ngModel)]="inputWithdraw"
                  name="inputWithdraw"
                  placeholder="Valor do Saque"
                  #inputwithdraw="ngModel"
                  required
                />
                <small
                  class="form-text text-muted danger"
                  *ngIf="inputwithdraw.errors?.required"
                  >Informe o Valor do Saque</small
                >
                <small
                  class="form-text text-muted danger"
                  *ngIf="inputWithdraw > avaiableBalance"
                  >Valor solicitado é maior que o valor disponível</small
                >
                <small
                  class="form-text text-muted danger"
                  *ngIf="inputWithdraw && inputWithdraw < 5.0"
                  >O Valor mínimo para saque é de R$ 5</small
                >
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-lg btn-warning btn-block mb-md-0"
                [disabled]="!disableWithdraw || inputWithdraw > avaiableBalance"
                (click)="iuguWithdrawRequest(modal)"
              >
                Solicitar Saque
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentVideo>
  <!-- <video controls style="width: 100%;">
    <source [src]="'https://files.wecodde.com/file/wecodde/03+-+relat%C3%B3rios+e+extratos.mp4'" type="video/mp4">
  </video> -->
  <div class="embed-container">
    <iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
