<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem;">Indique e Ganhe</h1>
    </div>
  </div>
</div>
<div class="row mb-2 mt-2" *ngIf="personais.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <div class="table text-center m-0">
          <table class="table-striped" style="width: 100%;">
            <thead>
              <tr>
                <th>Personal</th>
                <th>Email</th>
                <th>Telefone</th>
                <th>Saldo Liberado</th>
                <th>Saldo Pendente</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let p of personais| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                <td>{{p.name}}</td>
                <td>{{p.email}}</td>
                <td>{{p.phone ? p.phone : 'Sem telefone'}}</td>
                <td>{{formatPreco(p.saldoLiberado)}}</td>
                <td>{{formatPreco(p.saldoPendente)}}</td>
                <td>
                  <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                    <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                      <i class="ft-more-vertical"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu" role="menu">
                      <a class="dropdown-item" (click)="openPersonalIndicacao(p, pagamentoDetail)">Gerar Pagamento</a>
                      <a class="dropdown-item" *ngIf="p.UserBankAccounts && p.UserBankAccounts.length>0" (click)="viewBankAccount(p.UserBankAccounts, bankAccount)">Ver Dados Bancários</a>
                      <a class="dropdown-item" *ngIf="p.phone" (click)="openWhatsapp(p.phone)">Enviar Mensagem</a>
                      <a class="dropdown-item" *ngIf="p.phone" (click)="notificaCadatroConta(p)">Notifica Cadastro Conta</a>

                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
          <ngb-pagination style="margin-top: 10px" *ngIf="personais.length>0" [(page)]="page" [pageSize]="pageSize"
            [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm" [collectionSize]="personais.length">
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="personais.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum Personal cadastrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>



<ng-template #pagamentoDetail let-modal>
  <ngx-spinner></ngx-spinner>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Pagamento Personal</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <p *ngIf="faturasPersonal.length==0"> Nehuma fatura localizada </p>
                <table class="table-striped" style="width: 100%;" *ngIf="faturasPersonal.length>0">
                  <thead>
                    <tr>
                      <th>ID Fatura</th>
                      <th>Data Pagamento</th>
                      <th>Plano</th>
                      <th>Valor Comissão</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let comissao of faturasPersonal">
                      <td>{{comissao.invoiceId}}</td>
                      <td>{{formatDate(comissao.invoicePaymentDate)}}</td>
                      <td>{{formatPlan(comissao.planIdentifier)}}</td>
                      <td>{{formatPreco(comissao.commissionValue)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3 col-12">
              <div class="form-group mb-2">
                <label for="name">Valor Comissão</label>
                <input type="text" id="saldoComissao" class="form-control" name="saldoComissao"
                  [disabled]="faturasPersonal.length==0" [(ngModel)]="saldoComissao" #inputName="ngModel" required>
              </div>
            </div>
          </div>
          <div class="form-row mt-4">
            <div class="col-6 text-center">
              <button type="button" class="btn btn-lg btn-block btn-danger"
                (click)="modal.close('Close click')">Cancelar</button>
            </div>
            <div class="col-6 text-center">
              <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="gerarPagamento(modal)"
                [disabled]="faturasPersonal.length==0 || !saldoComissao">Pagar Comissão</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #bankAccount let-modal>
  <ngx-spinner></ngx-spinner>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Dados Bancários</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
          <div class="form-row">
            <div class="col-12">
              <p><b>Nome:</b> {{bankAccountSelected[0].accountHolder}}</p>
              <p><b>CPF:</b> {{bankAccountSelected[0].userCpf}}</p>
              <p><b>Banco:</b> {{bankAccountSelected[0].bank}}</p>
              <p><b>Agência:</b> {{bankAccountSelected[0].agency}}</p>
              <p><b>Conta:</b> {{bankAccountSelected[0].account}}</p>
              <p><b>Tipo Conta:</b> {{formatAccountType(bankAccountSelected[0].accountType)}}</p>
              <p><b>Chave PIX:</b> {{bankAccountSelected[0].accountPix}}</p>
            </div>
          </div>
      </div>
    </div>
  </div>
</ng-template>