import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-midia-store",
  templateUrl: "./midia-store.component.html",
  styleUrls: ["./midia-store.component.scss"],
})
export class MidiaStoreComponent implements OnInit {
  @Input() contactTypes: { id: number; name: string; icon: string }[] = [];
  contactForm: FormGroup;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      contact: ["", Validators.required],
      contactTypeId: ["", Validators.required],
    });
  }

  submit() {
    if (this.contactForm.valid) {
      this.activeModal.close(this.contactForm.value);
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
