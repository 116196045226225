import { Component, Input, OnInit } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-view-exercise",
  templateUrl: "./view-exercise.component.html",
  styleUrls: ["./view-exercise.component.scss"],
})
export class ViewExerciseComponent implements OnInit {
  type?: "video" | "gif";

  @Input() exercisePreviewData: {
    name: string | null;
    inititalType: "video" | "gif" | null;
    urlVideo: string | null;
    urlGif: string | null;
    safeURL: SafeResourceUrl | null;
  } | null = null;
  @Input() callback: (type?: "video" | "gif") => void = () => {};

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    if (!this.exercisePreviewData) return;

    if (this.exercisePreviewData.inititalType) {
      this.type = this.exercisePreviewData.inititalType;
    } else if (
      this.exercisePreviewData.urlVideo &&
      this.exercisePreviewData.urlGif
    ) {
      this.type = "gif";
    } else if (
      this.exercisePreviewData.urlGif &&
      !this.exercisePreviewData.urlVideo
    ) {
      this.type = "gif";
    } else if (
      !this.exercisePreviewData.urlGif &&
      this.exercisePreviewData.urlVideo
    ) {
      this.type = "video";
    }
  }

  close() {
    this.activeModal.close();
  }

  setMediaType(type: "video" | "gif") {
    this.type = type;
  }

  save() {
    this.callback(this.type);
    this.activeModal.close();
  }
}
