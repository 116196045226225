import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../shared/auth/auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-verificacao-boleto',
  templateUrl: './verificacao-boleto.component.html',
  styleUrls: ['./verificacao-boleto.component.scss']
})
export class VerificacaoBoletoComponent implements OnInit {
  apiCall = this.api.new().silent();
  email
  error
  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private me: Me,
              private app: App,
              private api: Api) {
    this.route.queryParams.subscribe((params)=>{
      if(params['email']) {
        this.email = params['email']
      }
    })
  }
  async ngOnInit(){
    const self = this
    self.error = null
    if(!self.email){
      self.error = 'Usuário não informado.'
      return
    }
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const retorno = await self.apiCall.get('seller/me/boleto/verificacao/'+self.email).pipe(take(1)).toPromise()
    if(!retorno.success){
      self.spinner.hide()
      self.error = retorno.message
      return
    }

    if(retorno.return){
      window.location.href = retorno.return
    }

  }

}
