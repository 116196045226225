<div class="row">
  <div class="col-12">
    <swiper [config]="swiperConfig" #swiperGallery class="swiper-gallery">
      <div class="swiper-wrapper">
        <div class="swiper-slide px-5" *ngFor="let products of productsMatrix">
          <div
            *ngFor="let product of products; let i = index"
            class="d-flex flex-column bg-white rounded-lg border-grey shadow-sm overflow-hidden"
            [ngClass]="{ 'mr-2': !!products[i + 1] }"
          >
            <div class="product-banner-container bg-grey bg-darken-2 mb-1">
              <img
                [src]="product.imgDesktop"
                alt="Banner do produto"
                class="w-100 img-fluid product-banner"
              />
              <div
                class="destaque"
                [style.backgroundColor]="selectedColor"
                [style.color]="calculateTextColor(selectedColor)"
                *ngIf="product.isFeatured == true"
              >
                <p>Destaque</p>
              </div>
            </div>
            <div class="p-3">
              <span class="d-block font-size-9 text-bold-600 mb-2">
                {{ product.title }}
              </span>
              <samp *ngFor="let plan of productsMatrix.Plans">
                <p class="products-cycles">
                  {{ getIntervalTypeInPortuguese(plan.interval_type) }}
                </p>
              </samp>
              <!-- <div
                *ngIf="!!product.value_with_discount"
                class="d-flex align-items-center mb-1"
              >
                <span
                  class="font-size-6 text-bold-600 grey lighten-1 value-discounted"
                >
                  {{ integerToReal(product.value) }}
                </span>
                <span
                  class="font-size-6 text-bold-600 badge badge-pill bg-light-success ml-1"
                >
                  {{ product.discount_percent }}
                </span>
              </div> -->
              <span
                class="d-block font-size-8 text-bold-600 mb-2"
                [style.color]="selectedColor"
              >
                {{
                  product.value_with_discount
                    ? integerToReal(product.value_with_discount)
                    : integerToReal(product.value)
                }}
              </span>
              <p class="mb-2 font-size-6 grey text-bold-500">
                {{
                  expandedDescriptions[i]
                    ? product.description
                    : getShortDescription(product.description)
                }}
              </p>
              <button
                class="btn btn-link p-0"
                (click)="toggleDescription(i)"
                [style.color]="selectedColor"
              >
                {{ expandedDescriptions[i] ? "Mostrar menos" : "Mostrar mais" }}
              </button>
              <button
                type="button"
                (click)="openCheckout(product.url)"
                class="w-100 btn rounded-sm"
                [style.backgroundColor]="selectedColor"
                [style.color]="calculateTextColor(selectedColor)"
              >
                Comprar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="productsMatrix.length > 1"
        role="button"
        title="Anterior"
        [ngStyle]="{
          'background-color': 'white',
          'border-color': selectedColor,
          color: selectedColor
        }"
        class="swiper-button-prev btn btn-sm p-0 shadow-none round"
        (click)="goPrev()"
      ></div>

      <div
        *ngIf="productsMatrix.length > 1"
        role="button"
        title="Próximo"
        [ngStyle]="{
          'background-color': 'white',
          'border-color': selectedColor,
          color: selectedColor
        }"
        class="swiper-button-next btn btn-sm p-0 shadow-none round"
        (click)="goNext()"
      ></div>
    </swiper>
    <div class="w-100 d-flex mt-3">
      <div class="mx-auto d-flex">
        <button
          *ngFor="let p of productsMatrix; let i = index"
          type="button"
          (click)="goToIndex(i)"
          class="btn btn-sm p-1 m-0 round mr-2"
          [ngStyle]="{
            'background-color':
              swiperGallery?.swiper?.activeIndex === i ? selectedColor : '#ccc',
            color: calculateTextColor(
              swiperGallery?.swiper?.activeIndex === i ? selectedColor : '#ccc'
            )
          }"
        ></button>
      </div>
    </div>
  </div>
</div>
