  <div class="row text-left">
    <div class="col-sm-12">
      <div class="d-flex justify-content-between mb-0">
        <h1 class="text-bold-300" style="padding: 1.5rem;">Listar Subcontas</h1>
      </div>
    </div>
  </div>

  <div class="row mb-2 mt-2" *ngIf="subContas.length > 0">
    <div class="col">
      <div class="card-content">
        <div class="card-body p-0">
          <div class="table text-center m-0">
            <div class="table-responsive">
              <table class="table-striped" style="width: 100%; overflow-x: auto;">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th><input type="text" id="email"  class="form-control" style="background-color: white; height: 100%" placeholder="Email" name="email" [(ngModel)]="filterEmail" (keyup)="filter()" ></th>
                    <th>ID Conta</th>
                    <th>CPF/CNPJ</th>
                    <th>Situação Iugu</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let s of subContasFiltered| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                    <td>{{s.User.name}}</td>
                    <td>{{s.User.email}}</td>
                    <td>{{s.account_id}}</td>
                    <td>{{s.cpf}}</td>
                    <td>{{s.can_receive ? 'Liberada' : 'Pendente'}}</td>
                    <td>
                      <!-- <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                        <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                          <i class="ft-more-vertical"></i>
                        </button>
                        <div ngbDropdownMenu class="dropdown-menu" role="menu">
                          <a class="dropdown-item" (click)="analisarSubconta(s, subAccountData)">Visualizar</a>
                          <a class="dropdown-item" (click)="openIugu(s.account_id)">Ver na Iugu</a>
                        </div>
                      </div> -->

                      <a class="btn btn-outline-warning btn-sm" (click)="analisarSubconta(s, subAccountData)">
                        <i class="fa fa-eye text-warning"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="form-row" style="margin: 5px">
          <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
            <ngb-pagination style="margin-top: 10px" *ngIf="subContas.length>0" [(page)]="page" [pageSize]="pageSize"
              [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm" [collectionSize]="subContas.length">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="subContas.length == 0">
    <div class="col">
      <div class="card-content">
        <div class="card-body p-0 mt-2">
          <ul class="list-group">
            <li class="list-group-item">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Nenhuma validação pendente.</h5>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  
  <ngx-spinner></ngx-spinner>
  
  
  
  <ng-template #subAccountData let-modal>
    
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Visualizar</h2>
      </div>
      <div class="card-content">
        <div class="card-body">
          <h6>Dados Bancários</h6><br>
          <div class="form-row">                      
            <div class="col-8">
              <b>Banco:</b> {{subAccountSelected.bank_name ? subAccountSelected.bank_name : "Banco Não Cadastrado"}}                
            </div>
            <div class="col-4">
              <b>Tipo de Conta:</b> {{subAccountSelected.bank_account_type ? subAccountSelected.bank_account_type : "Tipo de Conta Não Cadastrada"}}                
            </div>
          </div>
          <div class="form-row">
            <div class="col-8">
              <b>Número da Conta:</b> {{subAccountSelected.bank_account ? subAccountSelected.bank_account : "Sem Número da Conta"}}                
            </div>
            <div class="col-4">
              <b>Agência:</b> {{subAccountSelected.bank_agency ? subAccountSelected.bank_agency : "Agência não cadastrada"}}                
            </div>
          </div>
          <hr>
          <h6>Informações de Pagamento</h6><br>
          <div class="form-row">                      
            <div class="col-12">
              <b>Saque Liberado:</b> {{subAccountSelected.can_receive == 1 ? "Sim" : "Não"}}                
            </div>
            <br><br>
            <div class="col-4">
              <b>Pagamento com Cartão:</b> {{subAccountSelected.creditcard_payment == 1 ? "Sim" : "Não"}}                
            </div>
            <div class="col-4">
              <b>Pagamento com Boleto:</b> {{subAccountSelected.bankslip_payment == 1 ? "Sim" : "Não"}}                
            </div>
            <div class="col-4">
              <b>Pagamento com Pix:</b> {{subAccountSelected.pix_payment == 1 ? "Sim" : "Não"}}                
            </div>
          </div>
          <hr>
          <h6>Informações de Validação</h6><br>
          <div class="form-row">                      
            <div class="col-12">
              <b>Validação de Identidade:</b> {{subAccountSelected.identity_validation == 1 ? "Aguardando" : subAccountSelected.identity_validation == 2 ? "Problema na validação da conta" : "Conta validada"}}               
            </div>
            <div class="col-12">
              <b>Observação:</b> {{subAccountSelected.identity_validation_message ? subAccountSelected.identity_validation_message : " Nenhuma. "}}                
            </div>
            <br><br>
            <div class="col-12">
              <b>Status da Verificação:</b> {{subAccountSelected.verification_status ? "Aceito." : " - "}}                
            </div>
            <div class="col-12">
              <b>Observação:</b> {{subAccountSelected.verification_feedback ? subAccountSelected.verification_feedback : " Nenhuma. "}}                
            </div>
          </div>
          <hr>
          
          <div class="form-row mt-4">
            <div class="col-6 text-center">
              <button type="button" class="btn btn-lg btn-block btn-danger"
                (click)="modal.close('Close click')">Cancelar</button>
            </div>
            <div class="col-6 text-center">
              <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="openIugu(subAccountSelected.account_id)"
                >Ver na Iugu</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ngx-spinner></ngx-spinner>