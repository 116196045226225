<ngx-spinner></ngx-spinner>

<!--Login Page Starts-->
<section id="login">
  <div class="row auth-height full-height-vh m-0 black">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0">
              <div class="col-lg-6 d-none d-lg-flex justify-content-center align-items-center auth-img-bg p-3">
                       <img [src]="product.product_image" *ngIf="product && product.product_image" alt="" class="img-fluid" width="400">
                       <img *ngIf="product && !product.product_image" src="../../../../assets/img/gallery/vector-web-laranja.png"  alt="" class="img-fluid" width="400">
              </div>
              <div class="col-lg-6 col-12 px-4 py-3">
                <div class="row mb-2" *ngIf="product && product.delivery_logo">
                  <div class="col text-center">
                    
                    <img [src]="product.delivery_logo" alt="" class="img-fluid" width="180px">

                </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col text-center" style="width: 300px;">
                    <h4 class="text-warning text-center" *ngIf="product && product.name">{{product.name}}</h4>
                     <p *ngIf="product && product.description">{{product.description}}</p>
                  </div>
                </div>

            
                
                <!--<div class="row mb-2" *ngIf="product && product.description">
                    <div class="col">

                      <p class="text-center">{{product.description}}</p>
                  </div>
                  </div>-->
                <form #exForm="ngForm" class="editForm" novalidate>
                  <div class="form-row">
                    <div class="col-12">
                      <div class="form-group">
                        <div class="controls">
                          <label for="nome">Nome Completo</label>
                          <input type="text" id="nome" class="form-control" [(ngModel)]="usuario.nome" name="nome" placeholder="Nome" disabled>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <div class="controls">
                          <label for="email">Email</label>
                          <input type="email" id="email" class="form-control" [(ngModel)]="usuario.email" name="email" placeholder="Email" disabled>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <div class="controls">
                          <label for="phone">Telefone</label><br>
                          <ngx-intl-tel-input [preferredCountries]="preferredCountries"
                          [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false"
                          [selectedCountryISO]="CountryISO.Brazil" [maxLength]="15"
                          [phoneValidation]="true" [separateDialCode]="separateDialCode"
                          [numberFormat]="PhoneNumberFormat.National" name="phone" searchCountryPlaceholder="Selecione o País" [(ngModel)]="usuario.phone" disabled>
                        </ngx-intl-tel-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                      <div class="col">
                        <div class="form-group mb-4">
                          <label for="tipopagamento">Forma de pagamento</label>
                          <ng-select [items]="formasdePagamento" bindLabel="label" name="tipopagamento"
                           placeholder="Selecionar Forma" id="tipopagamento"
                            [(ngModel)]="formaEscolhida" [clearable]="false"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group mb-4">
                          <label for="planoEscolhido">Planos</label>
                          <ng-select [items]="plans"  bindLabel="name" name="planoEscolhido"  placeholder="Selecionar Plano" id="planoEscolhido" [(ngModel)]="planoEscolhido" [clearable]="false"></ng-select>
                        </div>
                      </div>
                    </div>
                    <h5 class="text-center text-warning mb-4" *ngIf="planoEscolhido && formaEscolhida && formaEscolhida.id!='bank_slip'">
                      <span>{{formatPrice(planoEscolhido.value_cents, 'cartao') + verificaPlano('cartao')}}</span>
                    </h5>
                    <h5 class="text-center text-warning mb-4" *ngIf="planoEscolhido && formaEscolhida && formaEscolhida.id=='bank_slip'">
                      <span>{{formatPrice(planoEscolhido.value_cents, 'boleto') + verificaPlano('boleto')}}</span>
                    </h5>
                    <h4 class="text-center text-warning mb-4"
                      *ngIf="planoEscolhido && (promo1 || promo2 || promo3 || cupomRetornado)">{{priceToShow()}}</h4>
                    <form id="boleto-form" style="margin-left: auto; margin-right: auto;"
                      *ngIf="formaEscolhida && formaEscolhida.id=='bank_slip'">
                      <div class="form-row">
                        <div class="col">
                          <div class="form-group">
                            <div class="controls">
                              <label for="cpf_cnpj">CPF/CNPJ</label>
                              <input type="text" id="cpf_cnpj" class="form-control" [(ngModel)]="endereco.cpf_cnpj"
                                name="cpf_cnpj" mask="000.000.000-00||00.000.000/0000-00" placeholder="CPF/CNPJ">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col">
                          <div class="form-group">
                            <div class="controls">
                              <label for="cep">CEP</label>
                              <input type="text" id="cep" class="form-control" [(ngModel)]="endereco.zip_code"
                                name="nome" mask="00000-000" placeholder="Cep" (change)="consultaCep()">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-8 col-12">
                          <div class="form-group">
                            <div class="controls">
                              <label for="street">Rua/Avenida</label>
                              <input type="text" id="street" class="form-control" [(ngModel)]="endereco.street"
                                name="street" placeholder="Rua/Avenida">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="form-group">
                            <div class="controls">
                              <label for="number">Número</label>
                              <input type="text" id="number" class="form-control" [(ngModel)]="endereco.number"
                                name="number" placeholder="Número">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col">
                          <div class="form-group">
                            <div class="controls">
                              <label for="district">Bairro</label>
                              <input type="text" id="district" class="form-control" [(ngModel)]="endereco.district"
                                name="district" placeholder="Bairro">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="col-md-8 col-12">
                          <div class="form-group">
                            <div class="controls">
                              <label for="city">Cidade</label>
                              <input type="text" id="city" class="form-control" [(ngModel)]="endereco.city" name="city"
                                placeholder="Cidade">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4 col-12">
                          <div class="form-group">
                            <div class="controls">
                              <label for="state">Estado</label>
                              <ng-select [items]="estados" name="state" placeholder="Estado" id="state"
                                [(ngModel)]="endereco.state"></ng-select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div class="form-row" *ngIf="formaEscolhida && formaEscolhida.id=='credit_card'">
                      <form id="payment-form" style="text-align: center; margin-left: auto; margin-right: auto;"
                        target="_blank" [ngClass]="getCreditCard()" method="POST">
                        <div class="usable-creditcard-form">
                          <div class="specifcwrapper">
                            <div class="input-group nmb_a">
                              <div class="icon ccic-brand"></div>
                              <input autocomplete="off" class="credit_card_number" data-iugu="number"
                                placeholder="Número do Cartão" type="text" [(ngModel)]="novoCartao.number"
                                name="ccNumber" ccNumber />
                            </div>
                            <div class="input-group nmb_b">
                              <div class="icon ccic-cvv"></div>
                              <input autocomplete="off" class="credit_card_cvv" data-iugu="verification_value"
                                placeholder="CVV" maxlength="4" type="text" [(ngModel)]="novoCartao.cvv" name="cvv"
                                ccCVC />
                            </div>
                            <div class="input-group nmb_c">
                              <div class="icon ccic-name"></div>
                              <input class="credit_card_name" data-iugu="full_name" placeholder="Titular do Cartão"
                                type="text" [(ngModel)]="novoCartao.name" name="name" />
                            </div>
                            <div class="input-group nmb_d">
                              <div class="icon ccic-exp"></div>
                              <input autocomplete="off" class="credit_card_expiration" data-iugu="expiration"
                                placeholder="MM/AA" mask="00/00" type="text" [(ngModel)]="novoCartao.date" name="date"
                                ccExp />
                            </div>
                          </div>
                          <p *ngIf="erroCartao" class="text-muted text-danger">{{erroCartao}}</p>
                        </div>
                      </form>
                    </div>
                    <br />
                    <div class="form-row mt-5">
                      <div class="col text-center">
                        <p>Ao clicar no botão Assinar você aceita os <a
                            href="https://ajuda.opersonaldigital.com.br/links-uteis/termos-de-servico"
                            src="www.google.com" target="_blank">Termos de serviço</a> e <a
                            href="https://ajuda.opersonaldigital.com.br/links-uteis/termos-de-uso" src="www.google.com"
                            target="_blank">Termos de uso</a> </p>

                      </div>
                    </div>
                    <div class="form-row ">
                      <div class="col text-center">
                        <button type="button" class="btn btn-lg bg-success text-white btn-block"
                          (click)="formaEscolhida && formaEscolhida.id=='credit_card' ? cadastrarCartaoEAssinar() : assinar()"
                          [disabled]="disableAssinar()">Assinar</button>
                      </div>
                    </div>
                    <div class="form-row">
                      <p class="text-center" *ngIf="planoEscolhido && (promo1 || promo2 || promo3 || cupomRetornado)">
                        {{'*Renovação automática ativada. Preço a partir da renovação: '+planoEscolhido.price}}</p>
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner></ngx-spinner>
<jjva-ngx-whatsapp-button [ngx_whatsapp_phone]="'+558398576703'"
  [ngx_whatsapp_text]="'Olá, estou no checkout e preciso de ajuda!'"></jjva-ngx-whatsapp-button>