<p class="paragrafo">Dados Pessoais</p>
<div class="dados-pessoais">
  <form *ngIf="usuario">
    <label>
      Nome
      <input type="text" [value]="usuario.name" readonly/>
    </label>
    <label>
      ID
      <input type="text" [value]="usuario.id" readonly/>
    </label>
    <label>
      Email
      <input type="text" [value]="usuario.email" readonly/>
    </label>
    <label>
      Telefone
      <input type="text" [value]="usuario.phone" readonly/>
    </label>
  </form>
</div>
