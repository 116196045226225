import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import { ProductType } from "app/personal/pages/finance-store/services-and-products/services-and-products.types";
import { CloudStorageService } from "app/shared/services/cloud-storage/cloud-storage.service";
import { isImageFile, openFilePicker } from "app/util/upload/file-picker";
import { FileUploader } from "ng2-file-upload";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Component({
  selector: "app-image-upload-modal",
  templateUrl: "./image-upload-modal.component.html",
  styleUrls: ["./image-upload-modal.component.scss"],
  providers: [CloudStorageService],
})
export class ImageUploadModalComponent implements OnInit {
  @Output() imageSelected = new EventEmitter<File>();

  apiCall = this.api.new().silent();

  product_image: File | string | null = null;
  product_image_url: File | string | null = null;
  product_image_sha1: File | string | null = null;

  uploader: FileUploader = new FileUploader({
    isHTML5: true,
    allowedFileType: ["JPEG", "JPG", "PNG"],
    maxFileSize: 2000,
  });

  defaultProduct: ProductType | null = null;
  coverImage: string | null = null;

  useImgAsStoreBg = false;

  isLoading: boolean = false;

  selectedImage: File | null = null;
  isUploadingImage = false;

  hasBaseDropZoneOver = false;

  @Input("callback") callback: ((status: "success" | "error") => void) | null =
    null;

  constructor(
    private activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private cloudStorage: CloudStorageService,
    private api: Api
  ) {}

  ngOnInit(): void {
    if (this.defaultProduct?.cover) {
      this.coverImage = this.defaultProduct?.cover;
    }
  }

  selecionarImagemProduto(event: any): void {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.selectedImage = e.target.result;
      };
      reader.readAsDataURL(file);
    } else {
      console.log("Nenhuma imagem selecionada.");
    }
  }

  salvarImagem(): void {
    if (this.selectedImage) {
      this.imageSelected.emit(this.selectedImage);
      this.activeModal.dismiss("closed-by-user");
    } else {
      alert("Nenhuma imagem selecionada!");
    }
  }

  onImageChange(event: any): void {
    if (event.target.files && event.target.files[0]) {
      this.selectedImage = event.target.files[0];
    }
  }

  openFilePicker() {
    openFilePicker({
      accept: "image/png, image/jpeg, image/jpg",
      multiple: false,
      callback: (files) => {
        if (files.length < 0) return;

        const file = files[0];
        if (!file) return;

        if (!isImageFile(file)) return;

        this.isUploadingImage = true;
        this.cloudStorage
          .upload(file, {
            fileType: file.type,
            fileExt: file.name.split(".").pop() || "",
          })
          .subscribe((url) => {
            if (url) {
              // preloading image
              const img = new Image();
              img.src = url;
              img.onload = () => {
                this.coverImage = url;
                this.isUploadingImage = false;
              };
              img.onerror = () => (this.isUploadingImage = false);
            } else {
              this.isUploadingImage = false;
            }
          });
      },
    });
  }

  onfileOver(e: boolean): void {
    if (e !== this.hasBaseDropZoneOver) {
      this.hasBaseDropZoneOver = e;
    }
  }

  onFileDrop(files: FileList) {
    if (files.length < 0) return;

    const file = files[0];
    if (!file) return;

    if (!isImageFile(file)) return;

    this.isUploadingImage = true;
    this.cloudStorage
      .upload(file, {
        fileType: file.type,
        fileExt: file.name.split(".").pop() || "",
      })
      .subscribe((url) => {
        if (url) {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            this.coverImage = url;
            this.isUploadingImage = false;
          };
          img.onerror = () => (this.isUploadingImage = false);
        } else {
          this.isUploadingImage = false;
        }
      });
  }

  closeModal() {
    this.activeModal.dismiss("closed-by-user");
  }
}
