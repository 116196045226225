<div class="row">
  <div class="col-sm-12 text-left">
    <h2 class="text-bold-300" style="padding-bottom: 1.5rem">Acompanhamento</h2>
    <h5 class="text-bold-300">
      Analise o histórico completo de treinos dos seus alunos, com todos os
      feedbacks e evoluções de carga.
    </h5>
  </div>
</div>

<div class="row mt-4">
  <div class="col-12 col-sm-6 col-lg-4">
    <div
      class="form-control d-flex justify-content-start align-items-center px-0 py-0"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        class="ml-2 flex-shrink-0"
      >
        <path
          d="M10.4062 9.12247C12.0375 6.87575 11.8419 3.70566 9.81765 1.68113C7.5759 -0.560375 3.92802 -0.560375 1.68655 1.68113C-0.555511 3.92316 -0.555511 7.57044 1.68655 9.8125C3.71077 11.8367 6.88087 12.0323 9.1273 10.4014L9.57174 10.8452L10.8504 9.5666L10.4062 9.12247ZM8.70277 8.69794C7.07327 10.3271 4.43093 10.3274 2.80115 8.69794C1.1714 7.06816 1.1714 4.42553 2.80115 2.79603C4.43096 1.16653 7.07327 1.16653 8.70277 2.79603C10.3325 4.42553 10.3325 7.06816 8.70277 8.69794Z"
          fill="#BDBDBD"
        />
        <path
          d="M3.41577 3.72523C2.56958 4.81235 2.49939 6.30792 3.20464 7.46317C3.27158 7.5722 3.4143 7.60701 3.52364 7.54004C3.6327 7.4731 3.6672 7.33038 3.60058 7.22135V7.2216C2.99474 6.23098 3.05527 4.94154 3.78127 4.01001C3.8602 3.90913 3.84208 3.7632 3.74089 3.68485C3.64005 3.60595 3.49414 3.62407 3.41577 3.72523Z"
          fill="#BDBDBD"
        />
        <path
          d="M15.6719 13.7063L11.3545 9.84961L9.8551 11.349L13.7114 15.6667C14.1036 16.174 14.8097 16.0995 15.4442 15.465C16.0784 14.8308 16.1795 14.0984 15.6719 13.7063Z"
          fill="#BDBDBD"
        />
      </svg>
      <input
        type="text"
        placeholder="Pesquisar Aluno"
        [(ngModel)]="search"
        class="w-100 d-flex ml-2 my-0 border-0 bg-transparent h-100 form-control px-0 py-0"
      />
      <button
        *ngIf="search && search.length > 0"
        type="button"
        title="Limpar"
        class="btn btn-light btn-sm rounded-pill close-button d-flex justify-content-center align-items-center"
        (click)="resetSearch()"
      >
        <i class="fa fa-times font-bold-300 white" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div id="tour-step__2-3" class="col-12 mt-2">
    <div class="row my-0">
      <div class="col-12">
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="d-none d-md-flex align-items-center btn btn-sm btn-filter shadow-none border-0 invisible"
          >
            <i class="d-block icon-equalizer mr-2"></i>
            Filtrar
          </button>
          <ul
            ngbNav
            [(activeId)]="activeTabId"
            #nav="ngbNav"
            class="nav-tabs flex-wrap flex-grow-1 d-flex justify-content-center border-0"
          >
            <li ngbNavItem [ngbNavItem]="'all'" class="nav-tab-item">
              <a ngbNavLink href="javascript:void(0)">
                <button
                  type="button"
                  class="w-100 btn btn-sm p-0 d-flex justify-content-center align-items-center font-size-4 font-size-md-5 font-bold-400"
                >
                  <svg
                    width="18"
                    height="11"
                    viewBox="0 0 18 11"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 mr-1 mr-md-2"
                  >
                    <path
                      d="M4.76466 1.18544H17.553C17.8393 1.18544 18.0714 1.41756 18.0714 1.70388C18.0714 1.99021 17.8393 2.22233 17.553 2.22233H4.76466C4.47833 2.22233 4.24621 1.99021 4.24621 1.70388C4.24621 1.41756 4.47833 1.18544 4.76466 1.18544ZM4.76466 4.98737H17.553C17.8393 4.98737 18.0714 5.21948 18.0714 5.50581C18.0714 5.79214 17.8393 6.02425 17.553 6.02425H4.76466C4.47833 6.02425 4.24621 5.79214 4.24621 5.50581C4.24621 5.21948 4.47833 4.98737 4.76466 4.98737ZM4.76466 8.96211H17.553C17.8393 8.96211 18.0714 9.19423 18.0714 9.48055C18.0714 9.76688 17.8393 9.999 17.553 9.999H4.76466C4.47833 9.999 4.24621 9.76688 4.24621 9.48055C4.24621 9.19423 4.47833 8.96211 4.76466 8.96211ZM1.82681 2.74077C1.25415 2.74077 0.789917 2.27653 0.789917 1.70388C0.789917 1.13123 1.25415 0.666992 1.82681 0.666992C2.39946 0.666992 2.8637 1.13123 2.8637 1.70388C2.8637 2.27653 2.39946 2.74077 1.82681 2.74077ZM1.82681 6.5427C1.25415 6.5427 0.789917 6.07846 0.789917 5.50581C0.789917 4.93316 1.25415 4.46892 1.82681 4.46892C2.39946 4.46892 2.8637 4.93316 2.8637 5.50581C2.8637 6.07846 2.39946 6.5427 1.82681 6.5427ZM1.82681 10.5174C1.25415 10.5174 0.789917 10.0532 0.789917 9.48055C0.789917 8.9079 1.25415 8.44366 1.82681 8.44366C2.39946 8.44366 2.8637 8.9079 2.8637 9.48055C2.8637 10.0532 2.39946 10.5174 1.82681 10.5174Z"
                      fill="currentColor"
                    />
                  </svg>
                  Todos
                </button>
              </a>
              <ng-template ngbNavContent>
                <app-datatable-component
                  #datatableAll
                  [isLoading]="$isLoading | async"
                  [isFetching]="$isFetching | async"
                  [expanded]="getExpanded()"
                  [relations]="$relations | async"
                  (fetchEvolutions)="fetchEvolutions($event)"
                  (toggleRowExpansion)="toggleRowExpansion($event)"
                  (refetchAfterChangeSolved)="refetchEvolutions()"
                ></app-datatable-component>
              </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="'importants'" class="nav-tab-item">
              <a ngbNavLink href="javascript:void(0)">
                <button
                  type="button"
                  class="w-100 btn btn-sm p-0 d-flex justify-content-center align-items-center font-size-4 font-size-md-5 font-bold-400"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="flex-shrink-0 mr-1 mr-md-2"
                  >
                    <path
                      d="M16.0774 10.7797L13.7022 8.40454M9.36311 12.4583H4.32739M6.00596 9.10114H4.32739M9.36311 6.58329C9.36311 7.08127 9.51078 7.56807 9.78744 7.98213C10.0641 8.39619 10.4573 8.71891 10.9174 8.90948C11.3775 9.10005 11.8838 9.14992 12.3722 9.05276C12.8606 8.95561 13.3092 8.71581 13.6614 8.36368C14.0135 8.01155 14.2533 7.56291 14.3504 7.0745C14.4476 6.58608 14.3977 6.07982 14.2072 5.61974C14.0166 5.15967 13.6939 4.76643 13.2798 4.48977C12.8658 4.2131 12.3789 4.06543 11.881 4.06543C11.2132 4.06543 10.5728 4.3307 10.1006 4.80289C9.62838 5.27508 9.36311 5.91551 9.36311 6.58329Z"
                      stroke="currentColor"
                      stroke-width="1.11905"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.7203 12.458V14.9759C12.7203 15.1985 12.6319 15.4119 12.4745 15.5693C12.3171 15.7267 12.1036 15.8151 11.881 15.8151H1.80956C1.58697 15.8151 1.37349 15.7267 1.2161 15.5693C1.0587 15.4119 0.970276 15.1985 0.970276 14.9759V1.54729C0.970276 1.3247 1.0587 1.11123 1.2161 0.953829C1.37349 0.796432 1.58697 0.708008 1.80956 0.708008H11.881"
                      stroke="currentColor"
                      stroke-width="1.11905"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Mais importantes
                </button>
              </a>
              <ng-template ngbNavContent>
                <app-datatable-component
                  #datatableImportants
                  [isLoading]="$isLoading | async"
                  [isFetching]="$isFetching | async"
                  [expanded]="getExpanded()"
                  [relations]="$relations | async"
                  (fetchEvolutions)="fetchEvolutions($event)"
                  (toggleRowExpansion)="toggleRowExpansion($event)"
                  (refetchAfterChangeSolved)="refetchEvolutions()"
                ></app-datatable-component>
              </ng-template>
            </li>
            <li ngbNavItem [ngbNavItem]="'solveds'" class="nav-tab-item">
              <a ngbNavLink href="javascript:void(0)">
                <button
                  type="button"
                  class="w-100 btn btn-sm p-0 d-flex justify-content-center align-items-center font-size-4 font-size-md-5 font-bold-400"
                >
                  <i
                    class="ft-check font-size-8 flex-shrink-0 mr-1 mr-md-2"
                  ></i>
                  Resolvidos
                </button>
              </a>
              <ng-template ngbNavContent>
                <app-datatable-component
                  #datatableSolveds
                  [isLoading]="$isLoading | async"
                  [isFetching]="$isFetching | async"
                  [expanded]="getExpanded()"
                  [relations]="$relations | async"
                  (fetchEvolutions)="fetchEvolutions($event)"
                  (toggleRowExpansion)="toggleRowExpansion($event)"
                  (refetchAfterChangeSolved)="refetchEvolutions()"
                ></app-datatable-component>
              </ng-template>
            </li>
          </ul>
          <div
            ngbDropdown
            #dropdown="ngbDropdown"
            container="body"
            class="dropdown btn-group-sm"
          >
            <button
              id="tour-step__5__clickable-item"
              ngbDropdownToggle
              type="button"
              placement="left"
              ngbTooltip="Abrir filtros"
              container="body"
              class="d-flex align-items-center btn btn-sm btn-filter shadow-none border-0"
            >
              <i class="d-block icon-equalizer mr-2"></i>
              Filtrar
            </button>
            <div
              id="tour-step__5"
              ngbDropdownMenu
              role="group"
              class="dropdown-menu px-3 py-2"
              aria-labelledby="tour-step__5__clickable-item"
            >
              <span
                class="d-block mb-3 mt-1 font-size-4 font-size-md-5 text-bold-600 text-left"
                >Filtrar por:</span
              >
              <div class="checkbox checkbox-sm position-relative mb-2">
                <input
                  id="with-observation"
                  type="checkbox"
                  [checked]="isFilterActive('withObservation')"
                  (change)="toggleFilter('withObservation')"
                />
                <label
                  for="with-observation"
                  class="normal-case cursor-pointer"
                >
                  {{ getFilterLabel("withObservation") }}
                </label>
              </div>
              <div class="checkbox checkbox-sm position-relative mb-2">
                <input
                  id="with-load-notification"
                  type="checkbox"
                  [checked]="isFilterActive('withLoadNotification')"
                  (change)="toggleFilter('withLoadNotification')"
                />
                <label
                  for="with-load-notification"
                  class="normal-case cursor-pointer"
                >
                  {{ getFilterLabel("withLoadNotification") }}
                </label>
              </div>
              <div class="checkbox checkbox-sm position-relative mb-2">
                <input
                  id="heavy-feedback"
                  type="checkbox"
                  [checked]="isFilterActive('heavyFeedback')"
                  (change)="toggleFilter('heavyFeedback')"
                />
                <label for="heavy-feedback" class="normal-case cursor-pointer">
                  {{ getFilterLabel("heavyFeedback") }}
                </label>
              </div>
              <div class="checkbox checkbox-sm position-relative mb-3">
                <input
                  id="light-feedback"
                  type="checkbox"
                  [checked]="isFilterActive('lightFeedback')"
                  (change)="toggleFilter('lightFeedback')"
                />
                <label for="light-feedback" class="normal-case cursor-pointer">
                  {{ getFilterLabel("lightFeedback") }}
                </label>
              </div>
              <div class="w-100 d-flex justify-content-end mb-1">
                <button
                  type="button"
                  class="btn btn-sm text-primary font-size-4 font-size-md-5"
                  (click)="applyFilters(); dropdown.close()"
                >
                  Filtrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isFiltered()" class="col-12 mt-3">
        <div
          class="w-100 d-flex flex-wrap justify-content-start active-filters-block"
        >
          <div
            *ngFor="let filter of getActiveFilters(); let i = index"
            class="d-flex justify-content-between align-items-center badge badge-filter border-0 rounded-0 py-2 pr-1"
          >
            <span class="d-block grey font-size-3 font-size-md-4 leading-none">
              {{ getFilterLabel(filter) }}
            </span>
            <button
              type="button"
              (click)="unsetFilter(filter)"
              class="d-block btn btn-sm grey darken-1 bg-transparent border-0 shadow-none ml-2 mr-1 p-0 leading-none text-center"
            >
              <i class="ft-x font-size-3 font-size-md-4"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 mt-3 thin-col">
        <div [ngbNavOutlet]="nav" class="p-0"></div>
      </div>
    </div>
  </div>
  <div *ngIf="!hasFeedback && !($isLoading | async)" class="col-12 mt-3">
    <div class="w-100 px-1 px-md-0 d-flex justify-content-center">
      <div class="card border feedback-container shadow-card m-0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div
                class="w-100 d-flex flex-column justify-content-between align-items-start px-md-3"
              >
                <div
                  class="w-100 d-flex flex-column align-items-center justify-content-center"
                >
                  <span class="main-label font-size-5"
                    >O que está achando dessa experiência?</span
                  >
                  <div
                    class="w-100 position-relative d-flex justify-content-center align-items-center mt-2"
                  >
                    <div class="d-inline-block mr-2 mr-md-4">
                      <input
                        id="fd-negatve"
                        type="radio"
                        name="feedback"
                        [disabled]="feedbackLoading"
                        [checked]="feedback === 'negative'"
                        (click)="setFeedback('negative')"
                        class="hidden-input"
                      />
                      <label
                        for="fd-negatve"
                        class="d-flex justify-content-center align-items-center cursor-pointer"
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 57 58"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          [ngClass]="{
                            'feedback-icon__disabled':
                              feedback && feedback !== 'negative'
                          }"
                        >
                          <path
                            d="M28.3459 3.61279C23.3626 3.61279 18.4911 5.09053 14.3476 7.85913C10.2041 10.6277 6.97467 14.5628 5.06762 19.1669C3.16058 23.7709 2.66161 28.837 3.63381 33.7246C4.60601 38.6122 7.00572 43.1017 10.5295 46.6255C14.0532 50.1493 18.5428 52.549 23.4304 53.5212C28.318 54.4934 33.3841 53.9944 37.9881 52.0874C42.5921 50.1803 46.5273 46.9509 49.2959 42.8074C52.0645 38.6638 53.5422 33.7924 53.5422 28.8091C53.5422 22.1266 50.8876 15.7178 46.1624 10.9926C41.4372 6.26739 35.0284 3.61279 28.3459 3.61279ZM28.3459 50.8558C23.9855 50.8558 19.723 49.5628 16.0974 47.1402C12.4719 44.7177 9.64607 41.2745 7.97741 37.246C6.30875 33.2175 5.87215 28.7846 6.72282 24.508C7.5735 20.2313 9.67325 16.303 12.7565 13.2197C15.8398 10.1364 19.7682 8.03663 24.0448 7.18595C28.3215 6.33527 32.7543 6.77187 36.7829 8.44053C40.8114 10.1092 44.2546 12.935 46.6771 16.5605C49.0996 20.1861 50.3927 24.4486 50.3927 28.8091C50.3927 34.6562 48.0699 40.2639 43.9353 44.3984C39.8008 48.533 34.1931 50.8558 28.3459 50.8558Z"
                            fill="#F03D3D"
                          />
                          <path
                            d="M39.6212 25.7854C41.1867 25.7854 42.4558 24.5163 42.4558 22.9508C42.4558 21.3853 41.1867 20.1162 39.6212 20.1162C38.0557 20.1162 36.7866 21.3853 36.7866 22.9508C36.7866 24.5163 38.0557 25.7854 39.6212 25.7854Z"
                            fill="#F03D3D"
                          />
                          <path
                            d="M17.9681 25.7854C19.5336 25.7854 20.8027 24.5163 20.8027 22.9508C20.8027 21.3853 19.5336 20.1162 17.9681 20.1162C16.4026 20.1162 15.1335 21.3853 15.1335 22.9508C15.1335 24.5163 16.4026 25.7854 17.9681 25.7854Z"
                            fill="#F03D3D"
                          />
                          <path
                            d="M28.5978 31.9585C26.3386 31.9589 24.1122 32.4993 22.1043 33.5348C20.0964 34.5703 18.3651 36.0707 17.0548 37.9111C16.8125 38.2515 16.7154 38.6742 16.7848 39.0862C16.8542 39.4981 17.0845 39.8657 17.4248 40.1079C17.7652 40.3502 18.1879 40.4473 18.5999 40.3779C19.0119 40.3084 19.3794 40.0782 19.6216 39.7378C20.6285 38.3236 21.9553 37.1671 23.4937 36.3627C25.0322 35.5582 26.7389 35.1285 28.4749 35.1085C30.2109 35.0886 31.927 35.4789 33.4836 36.2478C35.0402 37.0167 36.3931 38.1424 37.4322 39.5331C37.6828 39.8672 38.0559 40.0881 38.4694 40.1472C38.8828 40.2063 39.3028 40.0987 39.6369 39.8481C39.971 39.5975 40.1919 39.2244 40.251 38.811C40.3101 38.3975 40.2025 37.9775 39.9519 37.6434C38.6318 35.8782 36.9181 34.4452 34.9471 33.4583C32.9761 32.4714 30.8021 31.9579 28.5978 31.9585Z"
                            fill="#F03D3D"
                          />
                        </svg>

                        <span
                          class="text-capitalize ml-2 font-size-4 font-size-md-5"
                        >
                          Ruim
                        </span>
                      </label>
                    </div>
                    <div class="d-inline-block mr-2 mr-md-4">
                      <input
                        id="fd-neutral"
                        type="radio"
                        name="feedback"
                        [disabled]="feedbackLoading"
                        [checked]="feedback === 'neutral'"
                        (click)="setFeedback('neutral')"
                        class="hidden-input"
                      />
                      <label
                        for="fd-neutral"
                        class="d-flex justify-content-center align-items-center cursor-pointer"
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 57 58"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          [ngClass]="{
                            'feedback-icon__disabled':
                              feedback && feedback !== 'neutral'
                          }"
                        >
                          <path
                            d="M37.9715 35.2026H19.0743C18.6566 35.2026 18.2561 35.3686 17.9608 35.6639C17.6654 35.9592 17.4995 36.3597 17.4995 36.7774C17.4995 37.1951 17.6654 37.5956 17.9608 37.8909C18.2561 38.1863 18.6566 38.3522 19.0743 38.3522H37.9715C38.3891 38.3522 38.7897 38.1863 39.085 37.8909C39.3803 37.5956 39.5462 37.1951 39.5462 36.7774C39.5462 36.3597 39.3803 35.9592 39.085 35.6639C38.7897 35.3686 38.3891 35.2026 37.9715 35.2026Z"
                            fill="#ECA317"
                          />
                          <path
                            d="M28.4446 3.61279C23.4612 3.61279 18.5898 5.09053 14.4463 7.85913C10.3028 10.6277 7.0733 14.5628 5.16625 19.1669C3.25921 23.7709 2.76024 28.837 3.73244 33.7246C4.70464 38.6122 7.10436 43.1017 10.6281 46.6255C14.1519 50.1493 18.6414 52.549 23.529 53.5212C28.4166 54.4934 33.4827 53.9944 38.0868 52.0874C42.6908 50.1803 46.6259 46.9509 49.3945 42.8074C52.1631 38.6638 53.6408 33.7924 53.6408 28.8091C53.6408 22.1266 50.9862 15.7178 46.261 10.9926C41.5358 6.26739 35.127 3.61279 28.4446 3.61279ZM28.4446 50.8558C24.0841 50.8558 19.8216 49.5628 16.1961 47.1402C12.5705 44.7177 9.74471 41.2745 8.07604 37.246C6.40738 33.2175 5.97078 28.7846 6.82146 24.508C7.67213 20.2313 9.77188 16.303 12.8552 13.2197C15.9385 10.1364 19.8668 8.03663 24.1435 7.18595C28.4201 6.33527 32.853 6.77187 36.8815 8.44053C40.91 10.1092 44.3532 12.935 46.7758 16.5605C49.1983 20.1861 50.4913 24.4486 50.4913 28.8091C50.4913 34.6562 48.1685 40.2639 44.034 44.3984C39.8994 48.533 34.2917 50.8558 28.4446 50.8558Z"
                            fill="#ECA317"
                          />
                          <path
                            d="M39.7196 25.7854C41.2851 25.7854 42.5542 24.5163 42.5542 22.9508C42.5542 21.3853 41.2851 20.1162 39.7196 20.1162C38.1541 20.1162 36.885 21.3853 36.885 22.9508C36.885 24.5163 38.1541 25.7854 39.7196 25.7854Z"
                            fill="#ECA317"
                          />
                          <path
                            d="M17.673 25.7854C19.2385 25.7854 20.5075 24.5163 20.5075 22.9508C20.5075 21.3853 19.2385 20.1162 17.673 20.1162C16.1075 20.1162 14.8384 21.3853 14.8384 22.9508C14.8384 24.5163 16.1075 25.7854 17.673 25.7854Z"
                            fill="#ECA317"
                          />
                        </svg>

                        <span
                          class="text-capitalize ml-2 font-size-4 font-size-md-5"
                        >
                          Média
                        </span>
                      </label>
                    </div>
                    <div class="d-inline-block">
                      <input
                        id="fd-positive"
                        type="radio"
                        name="feedback"
                        [disabled]="feedbackLoading"
                        [checked]="feedback === 'positive'"
                        (click)="setFeedback('positive')"
                        class="hidden-input"
                      />
                      <label
                        for="fd-positive"
                        class="d-flex justify-content-center align-items-center cursor-pointer"
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 58 58"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          [ngClass]="{
                            'feedback-icon__disabled':
                              feedback && feedback !== 'positive'
                          }"
                        >
                          <path
                            d="M17.3558 34.2368C19.3018 38.6628 24.1259 41.7924 29.1937 41.7924C34.24 41.7924 39.0548 38.6547 41.0176 34.2479"
                            stroke="#1EC02E"
                            stroke-width="3.14953"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <circle
                            cx="21.6364"
                            cy="21.6362"
                            r="2.5195"
                            fill="#1EC02E"
                          />
                          <circle
                            cx="36.7535"
                            cy="21.6362"
                            r="2.5195"
                            fill="#1EC02E"
                          />
                          <path
                            d="M29.195 54.39C43.1098 54.39 54.39 43.1098 54.39 29.195C54.39 15.2802 43.1098 4 29.195 4C15.2802 4 4 15.2802 4 29.195C4 43.1098 15.2802 54.39 29.195 54.39Z"
                            stroke="#1EC02E"
                            stroke-width="3.14953"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <span
                          class="text-capitalize ml-2 font-size-4 font-size-md-5"
                        >
                          Boa
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  class="w-100 d-flex flex-column align-items-end justify-content-start text-input-container mt-2"
                >
                  <div class="input-group w-100 m-0">
                    <textarea
                      id="notes"
                      name="notes"
                      rows="3"
                      placeholder="Deixe seu comentário"
                      [disabled]="feedbackLoading"
                      [(ngModel)]="feedbackNote"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-control w-100 font-size-4 font-size-md-5"
                    ></textarea>
                  </div>
                  <button
                    type="button"
                    title="Enviar"
                    [disabled]="feedbackLoading"
                    (click)="sendFeedback()"
                    class="btn btn-sm text-primary shadow-none mt-1"
                  >
                    {{ feedbackLoading ? "Enviando" : "Enviar" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
