<div class="card">
  <div class="card-header">
    <h4 class="card-title">{{ exercise && exercise.id ? nameToShow : (duplicate ? 'Duplicando Exercício' : 'Novo Exercício')}}</h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <form #exForm="ngForm" class="editForm" novalidate>
        <br/>
        <div class="form-row">
          <div class="col-12">
            <br/>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="categorias">selecione uma ou mais categorias para este exercício</label>
                  <ng-select [items]="categories" [notFoundText]="'Nenhuma categoria encontrada'"   class="testabc" bindLabel="name" name="categorias"  placeholder="Selecionar Categoria" id="categorias" [multiple]="true" [(ngModel)]="categoriaSelecionada" (ngModelChange)="onselectCategory()">

                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <span>{{item.name}}<small class="text-muted float-right">{{item.user ? 'personal' : 'plataforma'}}</small></span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <label for="name">Nome do Exercício</label>
              <input type="text" id="name" class="form-control" name="name" [(ngModel)]="exercise.name" #inputName="ngModel" required>
            </div>
            <div class="form-group mb-2">
              <p class="text-center">Atenção: para melhor experiência do seu aluno recomendamos utilizar vídeos ou gifs <b>no formato horizontal/paisagem</b></p>
              <p class="text-center text-bold-600">Caso seu vídeo seja muito longo, opte por fazer upload dele no YouTube</p>
            </div>
            <div class="form-row mb-2">
              <div class="col text-center">
                <div class="d-md-none">
                  <div class="btn-group btn-lg p-0" style="width: 50%" role="group">
                    <button type="button" class="btn btn-lg gradient-mint"(click)="toggleVideoOrGif(true, true)" style="width: 50%" placement="top" ngbTooltip="Você pode inserir um link de um vídeo do Youtube para seu exercício." ><i class="fa fa-youtube"></i></button>
                    <button type="button" style="width: 50%" class="btn btn-lg gradient-mint" (click)="toggleVideoOrGif(true, false)" placement="top" ngbTooltip="Você pode fazer upload do vídeo do seu exercício." ><i class="fa fa-upload"></i></button>
                  </div>
                </div>
                <div class="d-none d-md-block">
                    <div class="btn-group btn-lg p-0" style="width: 50%" role="group">
                      <button type="button" class="btn btn-lg gradient-mint"(click)="toggleVideoOrGif(true, true)" style="width: 50%" placement="top" ngbTooltip="Você pode inserir um link de um vídeo do Youtube para seu exercício." >Link do YouTube</button>
                      <button type="button" style="width: 50%" class="btn btn-lg gradient-mint" (click)="toggleVideoOrGif(true, false)" placement="top" ngbTooltip="Você pode fazer upload do vídeo do seu exercício." >Upload Video</button>
                    </div>
                </div>
                <div class="row" *ngIf="url_video">
                  <div class="col">
                    <label for="campo_inicio_youtube">Tempo inicial Vídeo Youtube</label>
                    <input *ngIf="url_video.startsWith('https://www.youtube.com/') || url_video.startsWith('http://www.youtube.com/')" id="campo_inicio_youtube" name="campo_inicio_youtube" [(ngModel)]="exercise.inicio_youtube" [dropSpecialCharacters]="false" mask="Hh:m0:s0" drop class="form-control text-center">
                  </div>
                  <div class="col">
                    <label for="campo_fim_youtube">Tempo final Vídeo Youtube</label>
                    <input *ngIf="url_video.startsWith('https://www.youtube.com/') || url_video.startsWith('http://www.youtube.com/')" id="campo_fim_youtube" name="campo_fim_youtube" [(ngModel)]="exercise.fim_youtube" [dropSpecialCharacters]="false" mask="Hh:m0:s0" class="form-control text-center">
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-2">
              <p class="text-center">Seu vídeo não está em formato MP4? <a href="https://convert-video-online.com/pt" target="_blank" rel="noopener noreferrer">Clique aqui para converter.</a></p>
            </div>
            <div class="form-row mb-2" *ngIf="url_video">
              <div class="col text-center">
                <ng-template [ngIf]="url_video.startsWith('https://www.youtube.com/') || url_video.startsWith('http://www.youtube.com/')">
                  <div id="video" #videoYtb class="auto-resizable-iframe">
                    <div>
                      <iframe frameborder="0" allowfullscreen="" [src]="safeURL"></iframe>
                    </div>
                  </div>
                </ng-template>
                <ng-template [ngIf]="!url_video.startsWith('https://www.youtube.com/') && !url_video.startsWith('http://www.youtube.com/')">
                  <video #video id="video" controls style="margin-left: auto; margin-right: auto; max-width: 500px" crossorigin="anonymous">
                    <source [src]="url_video" type="video/mp4">
                  </video>
                </ng-template>
              </div>
            </div>
            <hr/>
            <div class="form-row justify-content-center mb-2">
              <div class="col text-center">
                <h5 class="mb-0">OU/E</h5>
              </div>
            </div>
            <hr/>
            <div class="form-row mb-2">
              <div class="col text-center">
                <div class="d-md-none">
                  <div class="btn-group btn-lg p-0" style="width: 50%" role="group">
                    <button type="button" class="btn btn-lg gradient-ibiza-sunset"(click)="toggleVideoOrGif(false, true)" style="width: 50%" placement="top" ngbTooltip="Você pode inserir um link para o GIF do seu exercício." ><i class="fa fa-external-link"></i></button>
                    <button type="button" class="btn btn-lg gradient-ibiza-sunset"(click)="toggleVideoOrGif(false, false)" style="width: 50%" placement="top" ngbTooltip="Você pode fazer upload do GIF do seu exercício." ><i class="fa fa-upload"></i></button>
                  </div>
                </div>
                <div class="d-none d-md-block">
                  <div class="btn-group btn-lg p-0" style="width: 50%" role="group">
                    <button type="button" class="btn btn-lg gradient-ibiza-sunset"(click)="toggleVideoOrGif(false, true)" style="width: 50%" placement="top" ngbTooltip="Você pode inserir um link para o GIF do seu exercício." >Link do GIF</button>
                    <button type="button" class="btn btn-lg gradient-ibiza-sunset"(click)="toggleVideoOrGif(false, false)" style="width: 50%" placement="top" ngbTooltip="Você pode fazer upload do GIF do seu exercício." >Upload GIF</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="url_gif">
              <div class="col text-center">
                <img [src]="url_gif" #gif id="gif"
                     style="margin-left: auto; margin-right: auto; max-width: 500px;">
              </div>
            </div>

            <div class="form-row mt-2 mb-2" *ngIf="url_gif || url_video">
              <ng-template [ngIf]="url_gif || (!url_video.startsWith('https://www.youtube.com/') && !url_video.startsWith('http://www.youtube.com/'))">
                 <div class="col text-center">
                <hr/>
                <h5 class="mb-2">THUMBNAIL DO EXERCÍCIO</h5>
                <p class="mb-2">Você pode dar play no vídeo e gerar uma thumbnail nova clicando no botão abaixo</p>
                <img *ngIf="thumbnailImg" [src]="thumbnailImg"  style="margin-left: auto; margin-right: auto; max-width: 500px;">
                <canvas #canvas id=“canvas” class="vid mb-2" style="margin-left: auto; margin-right: auto; max-width: 500px;"></canvas>
                <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="capture()" >Capturar Thumbnail</button>
                <hr/>
              </div>
            </ng-template>
            </div>

          
            <div class="form-row mt-4">
              <div class="col-6 text-center">
                <button type="button" class="btn btn-lg btn-block" [ngClass]="exercise && exercise.id ? 'btn-danger' : 'btn-secondary'" (click)="activeModal.close('Close click')">{{exercise && exercise.id ? 'Fechar' : 'Cancelar'}}</button>
              </div>
              <div class="col-6 text-center">
                <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="inserirOuAtualizarExercicio()" [disabled]="!exForm.valid || (!url_video && !url_gif) || !categoriaSelecionada || categoriaSelecionada.length==0">{{exercise && exercise.id ? 'Atualizar' : 'Cadastrar'}}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
