import { Component, Input, OnInit } from '@angular/core';
import App from '../../helpers/app';
import * as moment from "moment";
@Component({
  selector: 'app-pix-payment',
  templateUrl: './pix-payment.component.html',
  styleUrls: ['./pix-payment.component.scss']
})
export class PixPaymentComponent implements OnInit {
  @Input() invoice: any;
  @Input() pix: any;
  pixKey: string;
  maskedCnpjCpf: string;
  maskedSellerDoc: string;
  constructor(private app: App) {}

  ngOnInit() {
    if (this.pix && this.invoice) {
      this.pixKey = this.pix.qrcode_text;
      this.maskedSellerDoc = this.maskCnpjCpf(this.invoice.sellerDoc);
      this.maskedCnpjCpf = this.maskCnpjCpf(this.invoice.payer_cpf_cnpj);
    }
  }

  copyPixKey() {
    navigator.clipboard.writeText(this.pixKey).then(() => {
      this.app.alert('Chave PIX copiada para a área de transferência.', 'success');
    }, (err) => {
      console.error('Erro ao copiar a chave PIX: ', err);
    });
  }

  maskCnpjCpf(cnpjCpf: string): string {
    if (!cnpjCpf) return '';
    const length = cnpjCpf.length;
    if (length === 11) {
      // CPF
      return `${cnpjCpf.substring(0, 3)}.***.***-${cnpjCpf.substring(9, 11)}`;
    } else if (length === 14) {
      // CNPJ
      return `${cnpjCpf.substring(0, 2)}.***.***/****-${cnpjCpf.substring(12, 14)}`;
    }
    return cnpjCpf;
  }
  formatDate(lastLogin) {
    return moment(lastLogin).format("DD/MM/YYYY");
  }
}


