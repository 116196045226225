import { Component, Input } from '@angular/core';
import App from "../../helpers/app"; 
import * as moment from "moment";
@Component({
  selector: 'app-payment-ticket-personal',
  templateUrl: './payment-ticket-personal.component.html',
  styleUrls: ['./payment-ticket-personal.component.scss']
})
export class PaymentTicketPersonalComponent{
  @Input() invoice: any;
  @Input() bankSlip: any;

  constructor(private app: App,) {}

  formatDate(date: string){
    return moment(date).format("DD/MM/YYYY");
  }

  maskCnpjCpf(cnpjCpf: string): string {
    return cnpjCpf.replace(/^(\d{3})\d+(\d{2})$/, '$1****$2');
  }

  copyBarcode() {
    navigator.clipboard.writeText(this.bankSlip.digitable_line).then(() => {
      this.app.alert('Código de barras copiado para a área de transferência.', '');
    }, (err) => {
      console.error('Erro ao copiar o código de barras: ', err);
    });
  }
}
