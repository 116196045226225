import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../shared/auth/auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import {SocialAuthService} from "angularx-social-login";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-admin-cupons',
  templateUrl: './admin-cupons.component.html',
  styleUrls: ['./admin-cupons.component.scss']
})
export class AdminCuponsComponent implements OnInit {
  apiCall = this.api.new().silent();
  cupons = []
  page = 1
  pageSize = 10
  cupom = {
    id : null,
    coupon : null,
    description : null,
    percentageFirstMonth : null,
    priceFirstMonth : null,
    status : null,
  }
  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private me: Me,
              private socialAuthService: SocialAuthService,
              private app: App,
              private api: Api) {
  }
  async ngOnInit() {
    const self = this

    self.resetCupom()
    self.cupons = []

    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const data = await self.apiCall.get('coupons').pipe(take(1)).toPromise()
    console.log(data)
    if(data.return) self.cupons = data.return
    await self.spinner.hide()

  }
  inserirOuAtualizarCupom(modal){

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    let chamada
    let body = {
      Coupons : this.cupom
    }
    if(this.cupom.id){
      chamada = this.apiCall.put('coupons', body)

    }else{
      chamada = this.apiCall.post('coupons', body)

    }
    chamada.subscribe(async data => {
      if(data.success){
        this.spinner.hide()
        await this.app.alert('Tudo certo!', this.cupom.id ? 'Cupom atualizado com sucesso' : 'Cupom cadastrado com sucesso', 'success')
        modal.close('Close click')
        this.ngOnInit()
      }else{
        await this.app.alert('Ops :(', data.message, 'error')
        this.spinner.hide()
      }
    });
  }

  onDeleteCupom(cupom){
    const self = this
    self.app.confirm('Tem certeza?', 'Você realmente deseja deletar este cupom?').then(d=>{
      if(d){
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        self.apiCall.delete('coupons/'+cupom.id).subscribe(async data => {
          self.spinner.hide()
          if(data.success){
            self.ngOnInit()
            await this.app.alert('Tudo certo!',  'Cupom deletado com sucesso', 'success')
          }else{
            await this.app.alert('Ops :(', data.message, 'error')
          }
        });
      }
    })
  }

  openCupom(cupom, modal){
    this.resetCupom()
    if(cupom){
      this.cupom = cupom
    }
    console.log(this.cupom)
    const modalRef = this.modalService.open(modal);
  }
  switchCupom(cupom){
    const self = this
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if(cupom && cupom.id){
      cupom.status = !cupom.status
      self.apiCall.put('coupons', {
        Coupons : cupom
      }).subscribe(async data => {
        await self.spinner.hide()
        await self.ngOnInit()
        if(data.success){
          await this.app.alert('Tudo certo!',  cupom.status? 'Cupom ativado com sucesso' : 'Cupom inativado com sucesso', 'success')
        }else{
          await this.app.alert('Ops :(', data.message, 'error')
        }
      });
    }
  }
  resetCupom(){
    this.cupom = {
      id : null,
      coupon : null,
      description : null,
      percentageFirstMonth : null,
      priceFirstMonth : null,
      status : null,
    }
  }

}
