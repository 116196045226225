<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Configurar na Hotmart</h5>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="dismiss()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="step">
        <div class="step-number" style="width: 80px">1</div>
        <div class="step-content">
          <h6>Leia as regras</h6>
          <p>
            <a
              href="https://opersonaldigital.com.br/politicas-para-afiliados/"
              target="_blank"
              >Clique aqui</a
            >

            e leia atentamente a forma correta de promover nossos produtos. Quem
            descumprir terá afiliação cancelada.
          </p>
        </div>
      </div>

      <div class="step">
        <div class="step-number" style="width: 40px">2</div>
        <div class="step-content">
          <h6>Solicite Afiliação</h6>
          <p>
            <a
              href="https://app-vlc.hotmart.com/affiliate-recruiting/view/9271N93762073"
              target="_blank"
              >Clique aqui</a
            >
            e solicite sua afiliação.
          </p>
        </div>
      </div>

      <div class="step">
        <div class="step-number" style="width: 60px">3</div>
        <div class="step-content">
          <h6>Aguarde</h6>
          <p>
            Após a solicitação da afiliação na Hotmart, o prazo para aprovação é
            de até <strong>10 dias úteis</strong>.
          </p>
        </div>
      </div>

      <div class="step">
        <div class="step-number">4</div>
        <div class="step-content">
          <h6>Adicione o link de afiliado</h6>
          <p>Após aprovado, copie o link de afiliado abaixo:</p>
          <div class="form-group">
            <label for="affiliateLink">Link de afiliado do produto:</label>
            <input
              type="text"
              class="form-control"
              id="affiliateLink"
              placeholder="https://hotmart.com/linkdeafiliadodoproduto"
              [(ngModel)]="affiliateLink"
            />
            <small
              class="form-text text-success"
              *ngIf="isAffiliateLinkValid() && affiliateLink"
              >Link válido</small
            >
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        (click)="dismiss()"
      >
        Cancelar
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="validateAndReplicateProduct()"
        [disabled]="!isAffiliateLinkValid()"
      >
        Salvar
      </button>
    </div>
  </div>
</div>
