import { Platform } from "@angular/cdk/platform";
import { Injectable, Injector } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { timer } from "rxjs";
import { take } from "rxjs/operators";
import { PromptComponent } from "../prompt/prompt.component";

@Injectable({
  providedIn: "root",
})
export class PwaService {
  private promptEvent: any;
  private bottomSheet: NgbModal;

  isMobile = null;
  isOnTour = false;
  isOnboarding = null;

  constructor(
    private injector: Injector,
    private platform: Platform,
    private route: ActivatedRoute
  ) {}

  public checkAccess() {
    const url = this.getCurrentURL();

    if (
      url.includes("first-onboarding") ||
      url.includes("checkout-marketplace") ||
      url.includes("login-mobile") ||
      url.includes("checkout")
    ) {
      this.isOnboarding = true;
    }

    this.route.queryParams.subscribe((params) => {
      if (params) {
        if (params["isMobile"]) {
          this.isMobile = true;
        }
        if (params["ontour"]) {
          this.isOnTour = true;
        }
      }
    });

    setTimeout(() => {
      if (!this.isOnboarding && !this.isMobile && !this.isOnTour) {
        // this.initPwaPrompt();
      }
    }, 1000);
  }

  public getCurrentURL() {
    return window.location.href;
  }

  public initPwaPrompt() {
    if (this.isOnboarding != true && this.isMobile != true) {
      if (this.platform.ANDROID) {
        window.addEventListener("beforeinstallprompt", (event: any) => {
          event.preventDefault();
          this.promptEvent = event;
          this.openPromptComponent("android");
        });
      }
      if (this.platform.IOS) {
        const isInStandaloneMode =
          "standalone" in window.navigator && window.navigator["standalone"];
        if (!isInStandaloneMode) {
          this.openPromptComponent("ios");
        }
      }
    }
  }

  private openPromptComponent(mobileType: "ios" | "android") {
    const self = this;
    if (self.isOnboarding != true && self.isMobile != true) {
      timer(3000)
        .pipe(take(1))
        .subscribe(() => {
          this.bottomSheet = this.injector.get(NgbModal);
          const modalRef = this.bottomSheet.open(PromptComponent, {
            size: "sm",
            backdrop: "static",
            keyboard: false,
          });
          modalRef.componentInstance.mobileType = mobileType;
          modalRef.componentInstance.promptEvent = this.promptEvent;
          modalRef.result.then(
            (data) => console.log(data),
            (reason) => console.log(reason)
          );
        });
    }
  }
}
