import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-prompt",
  templateUrl: "./prompt.component.html",
  styleUrls: ["./prompt.component.scss"],
})
export class PromptComponent {
  isMobile = null;
  isOnboarding = null;

  @Input() mobileType: "ios" | "android";
  @Input() promptEvent?: any;

  constructor(
    private activeModal: NgbActiveModal,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params["isMobile"]) {
        this.isMobile = true;
      }
    });
  }

  ngOnInit() {
    if (this.router.url == "/page/personal/first-onboarding") {
      this.isOnboarding = true;
    }
  }

  async installPwa(): Promise<void> {
    await this.promptEvent.prompt();
    const { outcome } = await this.promptEvent.userChoice;

    if (outcome) this.close("APP instalado com sucesso!");
    else this.close("O APP não foi instalado!");
  }

  close(reason: string) {
    this.activeModal.dismiss(reason);
  }
}
