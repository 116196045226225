import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../shared/auth/auth.service";
import {DragulaService} from "ng2-dragula";
import {NgxSpinnerService} from "ngx-spinner";
import {DomSanitizer} from "@angular/platform-browser";
import Me from "../../../helpers/me";
import {SocialAuthService} from "angularx-social-login";
import App from "../../../helpers/app";
import Api from "../../../helpers/api";
import {ExercicioComponent} from "../exercicios/pages/exercicio/exercicio.component";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.scss']
})
export class CategoriasComponent implements OnInit {
  apiCall = this.api.new().silent();
  pageTr = 1
  pageSize = 10
  categories = []
  originalCategories = []
  categoriaSelecionada = null

  categoria = {
    id : null,
    name : null
  }
  inputCategoria = null
  user = null
  urlToPlay = null
  safeURL = null
  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private dragulaService: DragulaService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private _sanitizer: DomSanitizer,
              private me: Me,
              private socialAuthService: SocialAuthService,
              private app: App,
              private api: Api) {
    this.me.get().pipe(take(1)).toPromise().then((user)=>{


      this.user = user

    })
  }

  async ngOnInit() {
    const self = this
    self.originalCategories = []
    self.categories = []

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    self.apiCall.get('training/exercises/categories').subscribe(async cat => {
      self.categories = cat.return
      self.originalCategories = cat.return
      self.spinner.hide()
    });

  }

  onSearchCategory() {
    if(this.categoriaSelecionada.length>0){
      this.categories = this.originalCategories.filter(e=>e.name.toString().toLowerCase().trim().includes(this.categoriaSelecionada.toString().toLowerCase().trim()))
    }else{
      this.onResetCategory()
    }
  }
  onResetCategory() {
    this.categories= this.originalCategories

  }
  inserirOuAtualizarCategoria(modal){
  if(!this.user || !this.user.id){
    return
  }
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    let chamada
    let body = {
      category: {
        id : this.categoria.id ? this.categoria.id : null,
        name: this.inputCategoria,
        user : this.user.id
      }
    }
    if(this.categoria.id){
      chamada = this.apiCall.put('training/exercises/categories', body)

    }else{
      chamada = this.apiCall.post('training/exercises/categories', body)

    }
    chamada.subscribe(async data => {
      if(data.success){
        this.spinner.hide()
        await this.app.alert('Tudo certo!', this.categoria.id ? 'Sua categoria foi atualizada com sucesso' : 'Sua categoria foi cadastrada com sucesso', 'success')
        modal.close('Close click')
        this.ngOnInit()
      }else{
        await this.app.alert('Ops :(', data.message, 'error')
        this.spinner.hide()
      }
    });
  }
  onDeleteCategory(category){
    const self = this
    if(!category || !category.id){
      return
    }
    self.app.confirm('Tem certeza?', 'Você realmente deseja deletar esta categoria?').then(d=>{
      if(d){
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        self.apiCall.delete('training/exercises/categories/'+category.id).subscribe(async data => {
          self.spinner.hide()
          if(data.success){
            self.ngOnInit()
            await this.app.alert('Tudo certo!',  'Categoria deletada com sucesso', 'success')
          }else{
            await this.app.alert('Ops :(', data.message, 'error')
          }
        });
      }
    })
  }
  openCategory(category, template) {
    if(category){
      this.categoria = category
      this.inputCategoria = this.categoria.name
    }else{
      this.categoria = {
        id : null,
        name : null
      }
      this.inputCategoria = null
    }
    const modalRef = this.modalService.open(template);

  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  viewMidia(urlToPlay, content){
    this.urlToPlay = urlToPlay
    if(urlToPlay.startsWith('https://www.youtube.com/watch?')){
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(urlToPlay.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));

    }else if(urlToPlay.startsWith('http://www.youtube.com/watch?')){
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(urlToPlay.replace('http://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));
    }
    this.modalService.open(content, { centered: true , size:'lg'});
  }
}

