
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(private router: Router) { }

  getCurrentUri(): string {
    // Obtém a URL completa
    const fullUrl = window.location.href;
    // Remove a parte inicial do domínio, ficando apenas com a URI
    const uri = fullUrl.replace(window.location.origin, '');
    return uri;
  }
}
