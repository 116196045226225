import { Pipe, PipeTransform } from "@angular/core";
import { speedSwitch } from "../../functions/speedSwitch";

@Pipe({
  name: "translate",
})
export class TranslatePipe implements PipeTransform {
  transform(value: string): string {
    return speedSwitch(value, "pt") || value;
  }
}
