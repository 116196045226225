import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as cep from "cep-promise";
import * as moment from "moment";
import { CookieService } from "ngx-cookie-service";
import { CountdownComponent } from "ngx-countdown";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Api from "../helpers/api";
import Me from "../helpers/me";
import App from "app/helpers/app";

declare var Iugu: any;
declare let fbq: Function;
declare let gtag: Function;

type HoveredBloonType = "course" | "ment" | "months" | "trainings" | null;

@Component({
  selector: "app-checkout-iago",
  templateUrl: "./checkout-iago.component.html",
  styleUrls: ["./checkout-iago.component.scss"],
})
export class CheckoutIagoComponent implements OnInit {
  apiCall = this.api.new().silent();
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  @ViewChild("cd", { static: false }) private countdown: CountdownComponent;

  planos = [
    {
      id: "9F1A6CA4075644F1BA398746FD4CF533",
      identifier: "plano_1_iago",
      name: "Mensal + I.A.GO",
      price: "R$ 79,90 por mês",
      value_cents: null,
      priceAlert: "R$ 79,90",
      parcelas: null,
      pfb: 39.9,
      pfb1: 19.95,
      pfb2: 27.93,
      pfb3: 33.91,
      pfb4: 1.0,
      order: 5,
      category: 4,
      paymentMethod: "credit_card",
      type: 'subscription',
      sideImage: null,
      features: ['Acesso a plataforma', 'Acesso ao I.A.GO']
    },
    {
      id: "A020EB6B8A5D49FA9A1E0BB71CA6BE32",
      identifier: "plano_2_iago",
      name: "Trimestral + I.A.GO",
      price: "R$ 59,90 por mês durante 3 meses",
      value_cents: null,
      priceAlert: "R$ 59,90",
      parcelas: null,
      pfb: 39.9,
      pfb1: 19.95,
      pfb2: 27.93,
      pfb3: 33.91,
      pfb4: 1.0,
      order: 5,
      category: 4,
      paymentMethod: "credit_card",
      type: 'subscription',
      sideImage: null,
      features: ['Acesso a plataforma', 'Acesso ao I.A.GO', ' Curso Imersão A Virada']
    },
    {
      id: "A8D09798FDE74CF9A7C6D279C17E921F",
      identifier: "plano_3_iago",
      name: "Anual + I.A.GO",
      price: "R$ 49,90 por mês durante 12 meses",
      value_cents: null,
      priceAlert: "R$ 49,90",
      parcelas: null,
      pfb: 39.9,
      pfb1: 19.95,
      pfb2: 27.93,
      pfb3: 33.91,
      pfb4: 1.0,
      order: 5,
      category: 4,
      paymentMethod: "credit_card",
      type: 'subscription',
      sideImage: null,
      features: ['Acesso a plataforma', 'Acesso ao I.A.GO', ' Curso Imersão A Virada', ' Curso Instagram Lucrativo', 'Curso 4 Passos Para Dobrar Seu Faturamento', 'Curso De Professor a Empresário', 'Como Montar a Sua Consultoria Online']
    },
    {
      id: "9F1A6CA4075644F1BA398746FD4CF533",
      identifier: "plano_1_iago",
      name: "Mensal + I.A.GO",
      price: "R$ 79,90 por mês",
      value_cents: null,
      priceAlert: "R$ 79,90",
      parcelas: null,
      pfb: 39.9,
      pfb1: 19.95,
      pfb2: 27.93,
      pfb3: 33.91,
      pfb4: 1.0,
      order: 5,
      category: 4,
      paymentMethod: "bank_slip",
      type: 'subscription',
      sideImage: null,
      features: ['Acesso a plataforma', 'Acesso ao I.A.GO']
    },
    {
      id: "7DB5AD12A2964B8097B08063890D3517",
      identifier: "plano_2_iago_boleto",
      name: "Trimestral + I.A.GO",
      price: "R$ 179,70 a cada 3 meses",
      value_cents: null,
      priceAlert: "R$ 179,70",
      parcelas: null,
      pfb: 39.9,
      pfb1: 19.95,
      pfb2: 27.93,
      pfb3: 33.91,
      pfb4: 1.0,
      order: 5,
      category: 4,
      paymentMethod: "bank_slip",
      type: 'subscription',
      sideImage: null,
      features: ['Acesso a plataforma', 'Acesso ao I.A.GO', ' Curso Imersão A Virada']
    },
    {
      id: "FDCF7F1902A44BE68DC8FA7D54E86CA1",
      identifier: "plano_3_iago_boleto",
      name: "Anual + I.A.GO",
      price: "R$ 598,80 a cada 12 meses",
      value_cents: null,
      priceAlert: "R$ 598,80",
      parcelas: null,
      pfb: 39.9,
      pfb1: 19.95,
      pfb2: 27.93,
      pfb3: 33.91,
      pfb4: 1.0,
      order: 5,
      category: 4,
      paymentMethod: "bank_slip",
      type: 'subscription',
      sideImage: null,
      features: ['Acesso a plataforma', 'Acesso ao I.A.GO', ' Curso Imersão A Virada', ' Curso Instagram Lucrativo', 'Curso 4 Passos Para Dobrar Seu Faturamento', 'Curso De Professor a Empresário', 'Como Montar a Sua Consultoria Online']
    },
    {
      id: "42F7FE6500764A388675CAC8141F62CC",
      identifier: "iago_mensal",
      name: "I.A.GO Mensal",
      price: "R$ 47 por mês",
      value_cents: null,
      priceAlert: "R$ 47,00",
      parcelas: null,
      pfb: 4,
      pfb1: 4,
      pfb2: 4,
      pfb3: 4,
      pfb4: 4,
      order: 1,
      paymentMethod: "credit_card",
      category: 2,
      type: 'subscription',
      sideImage: '../../../../assets/img/iago_checkout/iago_mensal.png'
    },
    {
      id: "14FF2A04845446ED94AF9BFD4E063D0E",
      identifier: "iago_anual",
      name: "I.A.GO Anual",
      price: "R$ 9,75 por mês durante 12 meses",
      value_cents: null,
      priceAlert: "R$ 9,75",
      parcelas: 12,
      pfb: 9,
      pfb1: 9,
      pfb2: 9,
      pfb3: 9,
      pfb4: 9,
      order: 2,
      paymentMethod: "credit_card",
      category: 2,
      type: 'subscription',
      sideImage: '../../../../assets/img/iago_checkout/iago_anual.png'
    },
    {
      id: 'IAGO_VITALICIO',
      identifier: "iago_vitalicio",
      name: "I.A.GO Vitalício",
      price: "R$ 16,41 por mês durante 12 meses",
      value_cents: 19700,
      priceAlert: "R$ 16,41",
      parcelas: 12,
      pfb: 15,
      pfb1: 15,
      pfb2: 15,
      pfb3: 15,
      pfb4: 15,
      order: 3,
      paymentMethod: "credit_card",
      category: 2,
      type: 'one_time',
      sideImage: '../../../../assets/img/iago_checkout/iago_vitalicio.png'
    },    
    {
      id: "740ADD3CF7E241EA872F1F64F4DC7843",
      identifier: "plano_3_parcelado_iago",
      name: "Anual + I.A.GO",
      price: "R$ 39,90 por mês durante 12 meses",
      value_cents: null,
      priceAlert: "R$ 39,90",
      parcelas: 12,
      pfb: 15,
      pfb1: 15,
      pfb2: 15,
      pfb3: 15,
      pfb4: 15,
      order: 3,
      paymentMethod: "credit_card",
      category: 3,
      type: 'subscription',
      sideImage: null, //'../../../../assets/img/iago_checkout/iago+plataforma_normal.png',
      features: ['Plano Anual Aplicativo Personal Digital', 'OPD Academy', 'I.A.GO', 'Curso Personal Digital', 'Curso Vendendo Grupos de Desafio'],
    },
    {
      id: "42F7FE6500764A388675CAC8141F62CC",
      identifier: "iago_mensal",
      name: "I.A.GO Mensal",
      price: "R$ 47 por mês",
      value_cents: null,
      priceAlert: "R$ 47,00",
      parcelas: 1,
      pfb: 4,
      pfb1: 4,
      pfb2: 4,
      pfb3: 4,
      pfb4: 4,
      order: 1,
      paymentMethod: "bank_slip",
      category: 2,
      type: 'subscription',
      sideImage: '../../../../assets/img/iago_checkout/iago_mensal.png'
    },
    {
      id: "14FF2A04845446ED94AF9BFD4E063D0E",
      identifier: "iago_anual",
      name: "I.A.GO Anual",
      price: "R$ 117 por ano",
      value_cents: null,
      priceAlert: "R$ 117,00",
      parcelas: 1,
      pfb: 9,
      pfb1: 9,
      pfb2: 9,
      pfb3: 9,
      pfb4: 9,
      order: 2,
      paymentMethod: "bank_slip",
      category: 2,
      type: 'subscription',
      sideImage: '../../../../assets/img/iago_checkout/iago_anual.png'
    },
    {
      id: null,
      identifier: "iago_vitalicio",
      name: "I.A.GO Vitalício",
      price: "R$ 197,00 pagamento único",
      value_cents: 19700,
      priceAlert: "R$ 197,00",
      parcelas: 1,
      pfb: 15,
      pfb1: 15,
      pfb2: 15,
      pfb3: 15,
      pfb4: 15,
      order: 3,
      paymentMethod: "bank_slip",
      category: 2,
      type: 'one_time',
      sideImage: '../../../../assets/img/iago_checkout/iago_vitalicio.png'
    },
    {
      id: "740ADD3CF7E241EA872F1F64F4DC7843",
      identifier: "plano_3_parcelado_iago",
      name: "Anual + I.A.GO",
      price: "R$ 478,80 por Ano",
      priceAlert: "R$ 39,90",
      parcelas: 1,
      pfb: 15,
      pfb1: 15,
      pfb2: 15,
      pfb3: 15,
      pfb4: 15,
      order: 3,
      paymentMethod: "bank_slip",
      category: 3,
      type: 'subscription',
      features: ['Plano Anual Aplicativo Personal Digital', 'OPD Academy', 'I.A.GO', 'Curso Personal Digital', 'Curso Vendendo Grupos de Desafio'],
      sideImage: null, //'../../../../assets/img/iago_checkout/iago+plataforma_normal.png'
    }   
  ];

  planosFiltered = [];
  formas = [
    {
      id: "credit_card",
      name: "Cartão de Crédito",
    },
    {
      id: "bank_slip",
      name: "Boleto",
    },
    {
      id: "pix",
      name: "PIX",
    },
  ];
  formasFiltered = [];
  novoCartao = {
    number: null,
    cvv: null,
    date: null,
    name: null,
  };
  usuario = {
    nome: null,
    email: null,
    phone: null,
    senha: null,
  };

  usuario_email;
  perdeuFocoEmail = false;

  endereco = {
    cpf_cnpj: null,
    zip_code: null,
    number: null,
    street: null,
    city: null,
    state: null,
    district: null,
  };
  userToLogin = {
    email: null,
    senha: null,
  };
  user = null;
  planoParam = null;
  planoEscolhido = null;
  logged = false;
  formaEscolhida = null;
  erroCartao = null;
  utmSource = null;
  utmMedium = null;
  utmCampaing = null;
  utmContent = null;
  ipAddress = null;
  fbpCookie = null;
  userAgent = null;
  productType = null;
  iagoSidebar = false;
  estados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  eventID = null;
  newUser = false;
  acceptTerms = false;
  venceuDiasGratuitos = false;
  hoveredBonusBloon: HoveredBloonType = null;
  countdownTimeUnits: Array<[string, number]> = [
    ["Y", 1000 * 60 * 60 * 24 * 365], // years
    ["M", 1000 * 60 * 60 * 24 * 30], // months
    ["D", 1000 * 60 * 60 * 24], // days
    ["H", 1000 * 60 * 60], // hours
    ["m", 1000 * 60], // minutes
    ["s", 1000], // seconds
    ["S", 1], // million seconds
  ];

  configTimer = {
    leftTime: 0,
    formatDate: ({ date, formatStr }) => {
      let duration = Number(date || 0);

      return this.countdownTimeUnits.reduce((current, [name, unit]) => {
        if (current.indexOf(name) !== -1) {
          const v = Math.floor(duration / unit);
          duration -= v * unit;
          return current.replace(
            new RegExp(`${name}+`, "g"),
            (match: string) => {
              return v.toString().padStart(match.length, "0");
            }
          );
        }
        return current;
      }, formatStr);
    },
  };

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private api: Api,
    private app: App,
    public cookieService: CookieService,
    private http: HttpClient
  ) {
    Iugu.setAccountID("B0C64ACE25DF962FF353AA716E9B82CE");

    this.route.queryParams.subscribe((params) => {
      if (params["plano"]) {
        this.planoParam = params["plano"];
      }

      if (params["utm_source"]) {
        this.utmSource = params["utm_source"];
      }

      if (params["utm_medium"]) {
        this.utmMedium = params["utm_medium"];
      }

      if (params["utm_campaign"]) {
        this.utmCampaing = params["utm_campaign"];
      }

      if (params["utm_campaing"]) {
        this.utmCampaing = params["utm_campaing"];
      }

      if (params["utm_content"]) {
        this.utmContent = params["utm_content"];
      }

      if (params['checkoutmode']) {
        this.productType = params['checkoutmode']
        if(this.productType==2 || this.productType==3){
          this.iagoSidebar = true
        }
        this.planos = this.planos.filter(x => x.category == this.productType)         
      }else{
        this.redirectToHome()
      }
    });
  }

  redirectToHome(){
    this.router.navigate(["/page"]);
  }

  iniciaCheckoutFb() {
    this.generateEventID();
    fbq("track", "PageView", {}, { eventID: this.eventID });
    this.sendFbEvent("PageView", null, this.user ? true : false);
    fbq("track", "InitiateCheckout", {}, { eventID: this.eventID });
    this.sendFbEvent("InitiateCheckout", null, this.user ? true : false);

    //Initiate Checkout Google ADS
    gtag("event", "conversion", {
      send_to: "AW-861390287/wcpzCIPH7-kCEM-L35oD",
    });

    if (this.planoParam && this.planoParam.startsWith("plano_3")) {
      fbq("track", "InitiateCheckoutA", {}, { eventID: this.eventID });
      this.sendFbEvent("InitiateCheckoutA", null, this.user ? true : false);
    }
  }

  async generateEventID() {
    const min = Math.ceil(1000000);
    const max = Math.floor(1000000000);
    this.eventID = "FBPL" + Math.floor(Math.random() * (max - min + 1)) + min;
  }

  generateRandomString() {
    let code = Math.random().toString(36).substr(2, 4).toUpperCase();

    return code;
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async ngOnInit() {
    const self = this
    this.fbpCookie = this.cookieService.get("_fbp");

    if (this.fbpCookie == "") {
      this.fbpCookie = null;
    }

    const user = await this.me.get().pipe(take(1)).toPromise();
    this.user = user;

    if (self.planoParam) {

      self.planoEscolhido = self.planos.find(
        (x) => x.identifier.toLowerCase() == this.planoParam.toLowerCase()
      );
    } else {
      self.planoEscolhido = self.planos[0];
    }

    this.formasFiltered = this.formas;
    this.formaEscolhida = this.formas.find((x) => x.id == "credit_card");
    this.planosFiltered = this.planos.filter(
      (x) => x.paymentMethod == "credit_card" 
    );

    this.planosFiltered = this.planosFiltered.sort((a, b) => a.order - b.order);
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (this.user) {
      this.usuario_email = this.user.email;
      await this.verificaEmail();
      this.perdeuFocoEmail = true;
      this.userToLogin.senha = JSON.parse(JSON.stringify(user)).password;
      await this.login();
    }

    this.iniciaCheckoutFb();

 
    this.planosFiltered = this.planosFiltered.sort((a, b) => a.order - b.order);

    this.spinner.hide();
  }

  ngAfterViewInit() {
    if (this.planoParam) {
      this.planoEscolhido = this.planos.find(
        (x) => x.identifier.toLowerCase() == this.planoParam.toLowerCase()
      );
    } else {
      this.planoEscolhido = this.planos.find(
        (x) => x.identifier.toLowerCase() == "plano_3"
      );
    }
    this.formaEscolhida = this.formas.find((x) => x.id == "credit_card");
  }

  isEmailInvalido = false;
  isEmailCadastrado = false;
  async verificaEmail() {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.reinicializaVariaveis();
    this.usuario.email = this.usuario_email;
    if (this.usuario_email) {
      const response = await this.apiCall
        .get("seller/me/verificacao-email/" + this.usuario_email)
        .pipe(take(1))
        .toPromise();
      if (response.return && response.return.existente === true) {
        this.isEmailCadastrado = true;
        this.userToLogin.email = this.usuario_email;
      } else {
        this.usuario.email = this.usuario_email;
      }
      if (
        !this.validateEmail(this.usuario_email) ||
        this.usuario_email.includes(".com.com")
      ) {
        this.isEmailInvalido = true;
      }
    }
    this.spinner.hide();
  }

  cupomfbprice = null;
  reinicializaVariaveis() {
    this.user = null;
    this.loginInvalido = false;
    this.userToLogin = {
      email: null,
      senha: null,
    };
    this.usuario = {
      nome: null,
      email: null,
      phone: null,
      senha: null,
    };
    this.isEmailCadastrado = false;
    this.isEmailInvalido = false;
  }

  formatPreco(plano) {
    return (plano.prices[0].value_cents / 100).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  loginInvalido = false;
  async login() {
    this.loginInvalido = false;
    if (this.userToLogin.email && this.userToLogin.senha) {
      this.usuario.senha = this.userToLogin.senha;
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const login = await this.me.login(
        this.userToLogin.email,
        this.userToLogin.senha
      );
      if (login) {
        this.user = await this.me.get().pipe(take(1)).toPromise();
        let response = null
        if(this.productType==2){
          response = await this.apiCall
          .get("seller/me/verify/subscription/checkoutiago")
          .pipe(take(1))
          .toPromise();
        }else if(this.productType==3){
          response = await this.apiCall
          .get("seller/me/verify/subscription/plataforma")
          .pipe(take(1))
          .toPromise();
        }         

        await this.apiCall
          .get("seller/me/initiatecheckout")
          .pipe(take(1))
          .toPromise();

      } else {
        this.spinner.hide();
        this.loginInvalido = true;
      }
    }
  }

  getCreditCard() {
    if (
      this.novoCartao.number &&
      Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)
    ) {
      return Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number);
    } else {
      return "";
    }
  }

  changePlano() {
    if (
      this.planoEscolhido &&
      this.formaEscolhida &&
      this.formaEscolhida.id == "credit_card"
    ) {
      if (this.planoEscolhido.paymentMethod != "credit_card") {
        this.planoEscolhido = this.planos.find(
          (x) => x.name == this.planoEscolhido.name && x.paymentMethod == 'credit_card'
        );
      }
    }
    if (
      this.planoEscolhido &&
      this.formaEscolhida &&
      (this.formaEscolhida.id == "bank_slip" || this.formaEscolhida.id == "pix")
    ) {
      if (this.planoEscolhido.paymentMethod == "credit_card") {
        this.planoEscolhido = this.planos.find(
          (x) => x.name == this.planoEscolhido.name && x.paymentMethod == 'bank_slip'
        );
      }
    }
  }

  changeForma() {
    const self = this;

    if (
      self.formaEscolhida &&
      (self.formaEscolhida.id == "bank_slip" || self.formaEscolhida.id == "pix")
    ) {
      self.planosFiltered = self.planos.filter((x) =>
        x.paymentMethod.toLowerCase().includes("bank_slip")
      );
    }else {
      self.planosFiltered = self.planos.filter(
        (x) => !x.paymentMethod.toLowerCase().includes("bank_slip")
      );
    }

    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "credit_card"
    ) {

      if (self.planoEscolhido.paymentMethod != 'credit_card') {
        self.planoEscolhido = self.planos.find(
          (x) => x.name == self.planoEscolhido.name && x.paymentMethod == 'credit_card'
        );
      }
    }

    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      (self.formaEscolhida.id == "bank_slip" || self.formaEscolhida.id == "pix")
    ) {

      if (self.planoEscolhido.paymentMethod != 'bank_slip') {
        self.planoEscolhido = self.planos.find(
          (x) => x.name == self.planoEscolhido.name && x.paymentMethod == 'bank_slip'
        );
      }
    }

    self.planosFiltered = self.planosFiltered.sort((a, b) => a.order - b.order);
    console.log("Plano escolhido", self.planoEscolhido);

  }

  consultaCep() {
    if (this.endereco.zip_code) {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      cep(this.endereco.zip_code)
        .then((local) => {
          this.spinner.hide();
          this.endereco.street = local.street;
          this.endereco.district = local.neighborhood;
          this.endereco.state = local.state;
          this.endereco.city = local.city;
        })
        .catch(() => {
          this.spinner.hide();
        });
    }
  }

  cartaoCadastrado = null

  async assinar() {
    const self = this
    if (
      !this.planoEscolhido ||
      !this.planoEscolhido.identifier ||
      !this.formaEscolhida ||
      !this.formaEscolhida.id
    ) {
      return;
    }
    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let data = null;

    let secret = this.generateRandomString();

    secret = btoa(secret);

    let response = null

    if(this.user){
      if(this.productType==2){
        response = await this.apiCall
        .get("seller/me/verify/subscription/checkoutiago")
        .pipe(take(1))
        .toPromise();
      }else if(this.productType==3){
        response = await this.apiCall
        .get("seller/me/verify/subscription/plataforma")
        .pipe(take(1))
        .toPromise();
      } 
    }

      let endPoint = null

      if(this.productType == 2){
        endPoint = 'seller/me/checkout-iago-subscriber'
      }else if(this.productType == 3 || this.productType == 4){
        endPoint = 'seller/me/checkout-offer'
      }

      console.log("Response", response)
      console.log("EndPoint", endPoint)

      if((this.productType == 2 && !this.user) || (this.productType == 2 && (response.return && response.return.plano_ativo!=true))){

       await Swal.fire({
          title: "Assinatura não encontrada!",
          text: "Parece que você não possui uma assinatura ativa. Para Assinar um plano do I.A.GO é necessário possuir uma assinatura ativa na plataforma",
          icon: "warning",
          showCancelButton: true,
          allowOutsideClick: false,
          allowEscapeKey: false,
          backdrop: false,
          confirmButtonColor: "#00b214",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tentar Novamente",
          cancelButtonText: "Não Possuo Assinatura Ainda!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            self.spinner.hide();
          } else if (result.isDismissed) {
            await self.app.alert(
              "Tudo bem",
              "Antes de assinar um plano de acesso ao I.A.GO, você precisa possuir uma assinatura ativa na plataforma. Clique no botão abaixo para assinar um plano.",
              "success"
            );
            window.location.href =
              "https://app.opersonaldigital.com.br/pages/checkout/";
          }
        });
                      
        this.spinner.hide();

        return

      }


    data = await this.apiCall
      .post(endPoint, {
        plan_identifier: this.planoEscolhido.identifier,
        plan_name: this.planoEscolhido.name,
        value_cents: this.planoEscolhido.value_cents,
        tipo_pagamento: this.formaEscolhida.id,
        endereco: this.formaEscolhida.id == "bank_slip" ? this.endereco : null,
        cartao: this.cartaoCadastrado ? this.cartaoCadastrado : null,
        name: this.usuario.nome,
        email: this.usuario.email,
        phone: this.usuario.phone && this.usuario.phone.e164Number ? this.usuario.phone.e164Number : null,
        password: this.usuario.senha,
        utmSource: this.utmSource ? this.utmSource : null,
        utmMedium: this.utmMedium ? this.utmMedium : null,
        utmCampaing: this.utmCampaing ? this.utmCampaing : null,
        utmContent: this.utmContent ? this.utmContent : null,
        utmSourceCheckout: this.utmSource ? this.utmSource : null,
        utmMediumCheckout: this.utmMedium ? this.utmMedium : null,
        utmCampaingCheckout: this.utmCampaing ? this.utmCampaing : null,
        utmContentCheckout: this.utmContent ? this.utmContent : null,
        issubscriber: response && response.return && response.return.plano_ativo ? response.return.plano_ativo : false,
        key: secret,
        user: this.user ? this.user : null,
        billingType: this.planoEscolhido.type,
        parcelas: this.planoEscolhido.parcelas,
        category: this.planoEscolhido.category,
      })
      .pipe(take(1))
      .toPromise();

    if (!this.user) {
      this.newUser = true;
    }

    if (data.success) {
      let valor = 0.0;

      if (valor > 0) {
        if (this.planoEscolhido.identifier.toLowerCase().includes("boleto")) {
          valor = valor - 1.98;
        } else {
          valor = valor - (valor * 2.51) / 100;
        }
      }
      if (valor < 0) {
        valor = 0.0;
      }
      fbq(
        "track",
        "Purchase",
        { value: valor.toFixed(2), currency: "BRL" },
        { eventID: this.eventID }
      );
      this.sendFbEvent("Purchase", valor.toFixed(2), this.user ? true : false);
      if (
        this.planoEscolhido.identifier == "plano_3" ||
        this.planoEscolhido.identifier == "plano_3_boleto"
      ) {
        fbq(
          "track",
          "PurchaseA",
          { value: valor.toFixed(2), currency: "BRL" },
          { eventID: this.eventID }
        );
        this.sendFbEvent(
          "PurchaseA",
          valor.toFixed(2),
          this.user ? true : false
        );
      }
      fbq("track", "ViewContent", {}, { eventID: this.eventID });
      this.sendFbEvent("ViewContent", null, this.user ? true : false);

      //Purchase Google
      gtag("event", "conversion", {
        send_to: "AW-861390287/hlPQCJe1t-UCEM-L35oD",
        value: valor.toFixed(2),
        currency: "BRL",
        transaction_id: "",
      });
    }
    setTimeout(async function () {
      console.log(data.return.secureUrl)

      if (data.success) {
        await self.app.alert(
          "Tudo certo!",
          "Plano assinado com sucesso!",
          "success"
        );

        if (!self.user)
          await self.me.login(data.return.userCreated.email, data.return.userCreated.password);

         
        if (self.newUser == true) {
          window.location.href =
            "https://app.opersonaldigital.com.br/page/personal/first-onboarding";
        } else {
          if(data.return.secureUrl){
            window.location.href =  data.return.secureUrl;
          }else{
            window.location.href = "https://app.opersonaldigital.com.br/";

          }
        }
      } else {
        if (data.message && JSON.parse(JSON.stringify(data))) {
          let retorno = null;

          try {
            retorno = JSON.parse(data.message);
          } catch (e) {
            retorno = JSON.parse(JSON.stringify(data));
          }

          console.log("Retorno", retorno);

          await self.spinner.hide();

          if (retorno.lr == "51") {
            Swal.fire({
              title: "Não foi possivel realizar a sua assinatura",
              text: "Parece que o cartão que você cadastrou está sem limite disponível para realizar a compra, você possui outro cartão com limite disponível?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#00b214",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim, Possuo!",
              cancelButtonText: "Não Possuo Outro Cartão",
              allowOutsideClick: false,
              allowEscapeKey: false,
              backdrop: false,
            }).then(async (result) => {
              if (result.isConfirmed) {
                self.ngOnInit();
                self.spinner.hide();
              } else if (result.isDismissed) {
                await self.app.alert(
                  "Que pena!",
                  "Não tem problema, você ainda pode assinar a plataforma de forma recorrente (com cobranças mensais), porém não receberá os bônus, para realizar uma assinatura recorrente, clique no botão abaixo",
                  "success"
                );
                window.location.href =
                  "https://app.opersonaldigital.com.br/pages/checkout/";
              }
            });
          } else {
            await self.app.alert("Ops :(", retorno.message, "error");
          }
        } else {
          await self.app.alert("Ops :(", data.message, "error");
        }
      }
    }, 3000);
  }

  disableAssinar() {
    if (
      !this.planoEscolhido ||
      !this.formaEscolhida ||
      ((!this.usuario.email || !this.usuario.senha || !this.usuario.phone) &&
        !this.user) ||
      (this.formaEscolhida.id == "credit_card" &&
        (!this.novoCartao.number ||
          !this.novoCartao.cvv ||
          !this.novoCartao.date ||
          !this.novoCartao.name)) ||
      (this.formaEscolhida.id == "bank_slip" &&
        (!this.endereco.number ||
          !this.endereco.zip_code ||
          !this.endereco.state ||
          !this.endereco.street ||
          !this.endereco.district ||
          !this.endereco.city ||
          !this.endereco.cpf_cnpj))
    ) {
      return true;
    } else {
      return false;
    }
  }

  async cadastrarCartaoEAssinar() {
    const self = this;
    self.erroCartao = "";
    let name = "";
    let cvv = "";
    let date = "";
    let number = "";

    if (self.novoCartao.cvv) {
      cvv = self.novoCartao.cvv.replace(/\s/g, "");
    }
    name = self.novoCartao.name;

    if (self.novoCartao.number) {
      number = self.novoCartao.number.replace(/\s/g, "");
    }
    if (self.novoCartao.date) {
      date = self.novoCartao.date.replace(/\s/g, "");
    }

    if (!Iugu.utils.validateCreditCardNumber(number)) {
      self.erroCartao = "Número de cartão inválido";
      return;
    }
    if (!Iugu.utils.validateExpirationString(date)) {
      self.erroCartao = "Data de validade inválida";
      return;
    }
    if (
      !Iugu.utils.getBrandByCreditCardNumber(number) ||
      !Iugu.utils.validateCVV(
        cvv,
        Iugu.utils.getBrandByCreditCardNumber(number)
      )
    ) {
      self.erroCartao = "Data de validade inválida";
      return;
    }

    let cc = Iugu.CreditCard(
      number,
      date.substring(0, 2),
      date.substring(3, 7),
      name.split(" ")[0],
      name.substring(name.indexOf(" ") + 1),
      cvv
    );
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    Iugu.createPaymentToken(cc, async function (response) {
      if (response.errors) {
        await self.spinner.hide();
        self.erroCartao = "Erro ao validar seu cartão.";
        return;
      } else {
        self.cartaoCadastrado = response.id;
        await self.spinner.hide();
        await self.assinar();
      }
    });
  }

  async sendFbEvent(event, value, logged) {
    this.ipAddress = await this.getIPAddress();

    this.getUserAgent();

    let body = {
      event: event,
      value: value ? value : null,
      fbpCookie: this.fbpCookie ? this.fbpCookie : null,
      ip: this.ipAddress ? this.ipAddress : null,
      userAgent: this.userAgent ? this.userAgent : null,
      eventID: this.eventID,
    };

    if (logged == true) {
      this.apiCall.post("send/conversionLogged", body).subscribe();
    } else {
      this.apiCall.post("send/conversion", body).subscribe();
    }
  }

  async getIPAddress() {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get("https://api.ipify.org/?format=json")
        .subscribe((res: any) => {
          resolve(res.ip);
        });
    });
  }

  getUserAgent() {
    this.userAgent = window.navigator.userAgent;
  }

  onMouseEnterBloon(bloon: HoveredBloonType) {
    if (!bloon) {
      return;
    } else if (this.hoveredBonusBloon == bloon) {
      return;
    }
    this.hoveredBonusBloon = bloon;
  }

  onMouseLeaveBloon() {
    this.hoveredBonusBloon = null;
  }

  doneTimer(event) {
    console.log(event)
  }
}
