<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem;">OPD Academy</h2>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-12 text-center">
              <h2 *ngIf="isTruePlan!=true">Tá sabendo da novidade?</h2>
             </div>
             <div class="col-12 text-center mb-2 d-none d-md-block">
              <a *ngIf="isTruePlan!=true && isTest==false" href="https://api.whatsapp.com/send?phone=558398576703&text=Olá, gostaria de migrar para o plano Diamante e ter acesso ao OPD Academy." target="_blank">
                <img src="../../assets/img/opd_academy_sem_acesso_desktop.png" style="width: 75%;">
              </a>
              <a *ngIf="isTruePlan!=true && isTest==true" href="https://api.whatsapp.com/send?phone=558398576703&text=Olá, gostaria de migrar para o plano Diamante e ter acesso ao OPD Academy." target="_blank">
                <img src="../../assets/img/teste_gratis_desktop.svg" style="width: 75%;">
              </a>
              <img *ngIf="isTruePlan==true" src="../../assets/img/opd_academy_liberado_destop.svg" style="width: 75%;">
            </div>
            
            <div class="col-12 text-center mb-2 d-md-none text-center p-0">
              <a *ngIf="isTruePlan!=true && isTest==false" href="https://api.whatsapp.com/send?phone=558398576703&text=Olá, gostaria de migrar para o plano Diamante e ter acesso ao OPD Academy." target="_blank">
                <img src="../../assets/img/opd_academy_sem_acesso_mobile.png" style="width: 100%;">
              </a>
              <a *ngIf="isTruePlan!=true && isTest==true" href="https://api.whatsapp.com/send?phone=558398576703&text=Olá, gostaria de migrar para o plano Diamante e ter acesso ao OPD Academy." target="_blank">
                <img src="../../assets/img/teste_gratis_mobile.png" style="width: 100%;">
              </a>
              <img *ngIf="isTruePlan==true" src="../../assets/img/opd_academy_liberado_mobile.svg" style="width: 100%;">
            </div>
            
            <div class="col-12 text-center mb-4" *ngIf="elegiblePlan==false && isTest==false">         
              <a class="btn btn-success btn-lg onboarding-btn-whatsapp" href="https://api.whatsapp.com/send?phone=558398576703&text=Olá, gostaria de migrar para o plano Diamante e ter acesso ao OPD Academy." target="_blank"><i class="fa fa-whatsapp"></i>
                Solicitar Migração</a>
            </div>
            <ngb-tabset #t="ngbTabset" [activeId]="activedTab" class="mb-2">
              <ngb-tab title="Desafio 30 Alunos em 30 Dias" icon="fa fa-graduation-cap" id="desafio">
                <ng-template ngbTabContent>
                  <div class="row mt-2">
                  <div class="col-12 col-md-6 col-xl-4 mb-2" *ngFor="let m of desafio30Alunos" style="padding: 10px">
                  <h4 class="text-center">{{m.title}}</h4>
                  <div class="{{generateMentoringClass(m)}}" (click)="viewMidia(contentLocked, contentVideo, m, false)">
                    <img src="{{m.thumbnailUrl}}" style="max-width: 100%;">
                  </div>
                  <p class="description text-center mt-2">{{m.description}}</p>
                  <p class="text-center mentoring-date">{{formatBrazilianDate(m.date)}}</p>
                </div>
                </div></ng-template></ngb-tab>
              <ngb-tab title="OPD Academy" icon="fa fa-graduation-cap" id="opd">
                <ng-template ngbTabContent>
                  <div class="row mt-2">
                  <div class="col-12 col-md-6 col-xl-4 mb-2" *ngFor="let m of mentorings" style="padding: 10px">
                  <h4 class="text-center">{{m.title}}</h4>
                  <div class="{{generateMentoringClass(m)}}" (click)="viewMidia(contentLocked, contentVideo, m, false)">
                    <img src="{{m.thumbnailUrl}}" style="max-width: 100%;">
                  </div>
                  <p class="description text-center mt-2">{{m.description}}</p>
                  <p class="text-center mentoring-date">{{formatBrazilianDate(m.date)}}</p>
                </div>
                </div></ng-template></ngb-tab>
                <ngb-tab title="Conteúdos Especiais" id="metas" *ngIf="this.userObjectives && this.userObjectives.length > 0">
                  <ng-template ngbTabContent>
                  <div class="row mt-2">
                    <div class="col-12 col-md-6 col-xl-4 mb-2" *ngFor="let m of freeContent" style="padding: 10px">
                    <h4 class="text-center">{{m.title}}</h4>
                    <div class="{{generateSpecialContentClass(m)}}" (click)="viewMidia(contentLockedSpecialContent, contentVideo, m, true)">
                      <img src="{{m.thumbnailUrl}}" style="max-width: 100%;">
                    </div>
                    <p class="description text-center mt-2">{{m.description}}</p>
                    <p class="text-center mentoring-date">{{formatBrazilianDate(m.date)}}</p>
                  </div>
                </div>
              </ng-template>
            </ngb-tab>
            </ngb-tabset>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #contentVideo>
  
  <div style="padding:62.5% 0 0 0;position:relative;"><iframe [src]="safeURL" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div><script src="https://player.vimeo.com/api/player.js"></script>

 
</ng-template>

<ng-template #contentLocked let-modal>
  <div class="card">
    <div class="card-header">
      <div class="text-right text-sm-right modal-lock-close">
        <button type="button" class="btn" (click)="modal.close('Close click')">X</button>
        </div>
       <div class="text-center modal-lock-title">
        <img class="padlock" src="../../assets/img/padlock.svg">
        <span class="card-title modal-lock-title">Conteúdo Bloqueado</span>
      </div>
     </div>
    <div class="card-body">
      <p class="text-modal">O OPD Academy é o serviço exclusivo para assinantes do plano Diamante.</p>
      <p class="text-modal">Para aproveitar o OPD Academy e todos os outros benefícios que só o plano Diamante oferece, clique no botão abaixo e faça a migração do seu plano:</p>
      <div class="text-center mt-4">
        <a class="btn btn-success btn-lg onboarding-btn-whatsapp" href="https://api.whatsapp.com/send?phone=558398576703&text=Olá, gostaria de migrar para o plano Diamante e ter acesso ao OPD Academy." target="_blank"><i class="fa fa-whatsapp"></i>
          Solicitar Migração</a>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentLockedSpecialContent let-modal>
  <div class="card">
    <div class="card-header">
      <div class="text-right text-sm-right modal-lock-close">
        <button type="button" class="btn" (click)="modal.close('Close click')">X</button>
        </div>
       <div class="text-center modal-lock-title">
        <img class="padlock" src="../../assets/img/padlock.svg">
        <span class="card-title modal-lock-title">Conteúdo Bloqueado</span>
      </div>
     </div>
    <div class="card-body">
      <p class="text-modal">Finalize todas os objetivos para desbloquear este conteúdo especial.</p>
      <p class="text-modal">Clique no botão abaixo e veja as metas que faltam:</p>
      <div class="text-center mt-4">
        <a class="btn btn-success btn-lg onboarding-btn-whatsapp" href="/page" target="_self">
          Ver meus objetivos <i class="fa ft-arrow-right"></i></a>
      </div>
    </div>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>