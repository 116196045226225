<div>
  <image-cropper
    [imageBase64]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio"
    format="jpeg, png"
    (imageCropped)="imageCropped($event)"
  >
  </image-cropper>

  <div class="image-preview-divider">
    <span>Prévia da imagem</span>
    <hr>
  </div>

  <div class="preview">
    <img *ngIf="croppedImage?.base64" [src]="croppedImage.base64" />
  </div>
</div>
