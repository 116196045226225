/**
 * Array of supported image content types.
 */
const SUPPORTED_IMAGE_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  // "image/gif",
  // "image/webp",
];

/**
 * Returns true if the passed in `File` object is a supported image type.
 * A supported image is able to be resized dynamically on the server.
 */
export function isImageFile(file: File): boolean {
  return SUPPORTED_IMAGE_TYPES.includes(file.type);
}

/**
 * Takes a `url` to an image and returns its size as a tuple `[width, height]`.
 *
 * It works by creating an `Image` object, assigning the `url` to its `src`
 * and waiting for it to load then finding its `naturalWidth` and `naturalHeight`.
 */
export async function getImageSize(
  url: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    const image = new Image();
    image.addEventListener("load", function () {
      resolve({
        width: this.naturalWidth,
        height: this.naturalHeight,
      });
    });
    image.src = url;
  });
}

type Params = {
  accept: string;
  multiple: boolean;
  callback: (arg: File[]) => void;
};

export function openFilePicker(params: Params) {
  const { accept, callback, multiple } = params;
  // this function must be called from a user
  // activation event (ie an onclick event)

  // Create an input element
  var inputElement = document.createElement("input");

  // Hide element and append to body (required to run on iOS safari)
  inputElement.style.display = "none";
  document.body.appendChild(inputElement);
  // Set its type to file
  inputElement.type = "file";

  // Set accept to the file types you want the user to select.
  // Include both the file extension and the mime type
  // if accept is "*" then dont set the accept attribute
  if (accept !== "*") inputElement.accept = accept;
  // Accept multiple files
  inputElement.multiple = multiple;
  // set onchange event to call callback when user has selected file
  //inputElement.addEventListener('change', callback);
  inputElement.addEventListener("change", (arg) => {
    const inputElement = arg.target as HTMLInputElement;
    const files = inputElement.files ? Array.from(inputElement.files) : [];
    callback(files);
    // remove element
    document.body.removeChild(inputElement);
  });

  // dispatch a click event to open the file dialog
  inputElement.dispatchEvent(new MouseEvent("click"));
}
