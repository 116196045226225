<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem;">Categorias</h1>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col" >
    <div class=" float-right d-none d-md-block" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openCategoria(null, categoriaDetail)" >Cadastrar</button>
    </div>
    <div class="btn-group btn-group-lg d-md-none" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openCategoria(null, categoriaDetail)" ><i class="fa fa-plus"></i></button>
    </div>
  </div>
</div>

<div class="row mb-2 mt-2" *ngIf="categorias.length > 0">
  <div class="col">
    <div class="card-content">

      <div class="card-body p-0">
        <div class="table text-center m-0">
          <table class="table-striped" style="width: 100%;">
            <thead>
            <tr>
              <th>Categoria</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let c of categorias| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
              <td>{{c.name}}</td>
              <td>{{c.status  ? 'Ativo' : 'Inativo'}}</td>
              <td>
                <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                  <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                    <i class="ft-more-vertical"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a  class="dropdown-item" (click)="openCategoria(c, categoriaDetail)" >Editar</a>
                    <a  class="dropdown-item" (click)="switchCategoria(c)">{{c.status  ? 'Inativar' : 'Ativar'}}</a>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
          <ngb-pagination style="margin-top: 10px" *ngIf="categorias.length>0"
                          [(page)]="page"
                          [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
                          [collectionSize]="categorias.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="categorias.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhuma categoria cadastrada.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>



<ng-template #categoriaDetail let-modal>
  <ngx-spinner></ngx-spinner>

  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Categoria</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="name">Categoria</label>
                <input type="text" id="name" class="form-control" name="name" (keydown.space)="$event.preventDefault();" [(ngModel)]="categoria.name" #inputName="ngModel" required>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block" [ngClass]="categoria && categoria.id ? 'btn-danger' : 'btn-secondary'" (click)="modal.close('Close click')">{{categoria && categoria.id ? 'Fechar' : 'Cancelar'}}</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="inserirOuAtualizarCategoria(modal)" [disabled]="!categoria || !categoria.name">{{categoria && categoria.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
