<div class="card m-0 modal-container position-relative">
  <button
    *ngIf="!isBannerVisible"
    type="button"
    (click)="closeModal()"
    class="btn btn-danger btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
  >
    <i class="ft-x font-medium-2 m-0"></i>
  </button>

  <!-- start banner content block -->
  <div *ngIf="isBannerVisible && !isLoading" class="row flex-grow-1">
    <div class="d-flex flex-column col-12 bg-white">
      <!-- start banner image block -->
      <div class="w-100 d-flex flex-grow-1 bg-dark-900">
        <img
          src="../../../../assets/img/personal-gpt/training-wizard/banner_desktop_view_2.png"
          class="img-fluid copy-image banner d-none d-lg-block"
        />
        <img
          src="../../../../assets/img/personal-gpt/training-wizard/banner_mobile_view_2.png"
          class="img-fluid copy-image banner d-lg-none"
        />
      </div>
      <!-- end banner image block -->

      <!-- start footer block -->
      <div
        class="w-100 d-flex justify-content-between banner-footer-content pb-3 pb-lg-4 px-4 px-lg-5"
      >
        <button
          type="button"
          class="btn btn-outline-light px-4 font-small-2 text-bold-600 mr-2"
          (click)="modalRef.close()"
        >
          Cancelar
        </button>
        <button
          type="button"
          class="btn btn-primary px-4 white font-small-2 text-bold-600"
          (click)="setBannerVisibility(false)"
        >
          Continuar
        </button>
      </div>
      <!-- end footer block -->
    </div>
  </div>
  <!-- end banner content block -->

  <!-- start main content block -->
  <div *ngIf="!isBannerVisible && !isLoading" class="row flex-grow-1">
    <!-- start sidebar block -->
    <div class="col-12 col-lg-5 p-0 bg-dark-900">
      <div class="w-100 h-100 d-flex steps-banner-container">
        <img
          *ngIf="currentStep === 1"
          src="../../../../assets/img/personal-gpt/training-wizard/step_1_banner_desktop.png"
          class="img-fluid copy-image step d-none d-lg-block"
        />
        <img
          *ngIf="currentStep === 1"
          src="../../../../assets/img/personal-gpt/training-wizard/step_1_banner_mobile.png"
          class="img-fluid copy-image step d-lg-none"
        />
        <img
          *ngIf="currentStep === 2"
          src="../../../../assets/img/personal-gpt/training-wizard/step_2_banner_desktop.png"
          class="img-fluid copy-image step d-none d-lg-block"
        />
        <img
          *ngIf="currentStep === 2"
          src="../../../../assets/img/personal-gpt/training-wizard/step_2_banner_mobile.png"
          class="img-fluid copy-image step d-lg-none"
        />
        <img
          *ngIf="currentStep === 3"
          src="../../../../assets/img/personal-gpt/training-wizard/step_3_banner_desktop.png"
          class="img-fluid copy-image step d-none d-lg-block"
        />
        <img
          *ngIf="currentStep === 3"
          src="../../../../assets/img/personal-gpt/training-wizard/step_3_banner_mobile.png"
          class="img-fluid copy-image step d-lg-none"
        />
        <img
          *ngIf="currentStep === 4"
          src="../../../../assets/img/personal-gpt/training-wizard/step_4_banner_desktop.png"
          class="img-fluid copy-image step d-none d-lg-block"
        />
        <img
          *ngIf="currentStep === 4"
          src="../../../../assets/img/personal-gpt/training-wizard/step_4_banner_mobile.png"
          class="img-fluid copy-image step d-lg-none"
        />
      </div>
    </div>
    <!-- end sidebar block -->

    <!-- start wizard block -->
    <div class="d-flex col-12 col-lg-7 bg-white">
      <div
        class="flex-grow-1 d-flex flex-column justify-content-start align-items-center p-3 pt-md-4 pr-md-4"
      >
        <!-- start header block -->
        <div
          class="w-100 d-flex flex-column align-items-start px-0 px-md-2 px-xl-4 mb-3"
        >
          <span class="font-small-2 text-secondary mb-1">{{
            currentStep + " de " + totalSteps + " completo"
          }}</span>
          <div class="w-100 rounded-pill bg-light overflow-hidden progress-bar">
            <span
              class="d-block h-100 rounded-pill bg-success current-progress"
              [style]="'width: ' + currentPercentage + ';'"
            ></span>
          </div>
        </div>
        <!-- end header block -->

        <!-- start form block -->
        <div
          class="w-100 flex-grow-1 d-flex flex-column justify-content-start align-items-start px-0 px-md-2 px-xl-4 mb-3"
        >
          <h3 class="black font-medium-4 text-bold-600 mb-3">
            {{
              currentStep < 3
                ? "Perfil do Aluno"
                : currentStep < 4
                ? "Detalhes do Treino"
                : "Parâmetros para cada exercício do treino"
            }}
          </h3>
          <form
            novalidate
            class="w-100 d-flex flex-grow-1 flex-column justify-content-start align-items-start"
            [formGroup]="trainingGeneratorForm"
            (submit)="onSubmit()"
          >
            <!-- start first step -->
            <div *ngIf="currentStep === 1" class="w-100 flex-grow-1">
              <div class="form-group mb-2 mb-md-3 mb-lg-4">
                <label for="gender-group" class="mb-2">SEXO</label>
                <div id="gender-group" class="input-group">
                  <div class="radio radio-sm d-inline-block mr-3 mb-1">
                    <input
                      id="fem-switch"
                      type="radio"
                      name="gender"
                      formControlName="gender"
                      value="female"
                    />
                    <label for="fem-switch" class="normal-case cursor-pointer"
                      >Feminino</label
                    >
                  </div>
                  <div class="radio radio-sm d-inline-block mb-1">
                    <input
                      id="mal-switch"
                      type="radio"
                      name="gender"
                      formControlName="gender"
                      value="male"
                    />
                    <label for="mal-switch" class="normal-case cursor-pointer"
                      >Masculino</label
                    >
                  </div>
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'O sexo é obrigatório',
                    control: trainingGeneratorForm.controls['gender']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'pattern',
                    message: 'O sexo deve ser masculino ou feminino',
                    control: trainingGeneratorForm.controls['gender']
                  }"
                ></ng-container>
              </div>

              <div class="w-100 form-group mb-2 mb-md-3 mb-lg-4">
                <label for="age" class="mb-2">IDADE</label>
                <div class="w-100 input-group mb-1">
                  <ng-select
                    id="age"
                    name="age"
                    [items]="ages"
                    bindValue="value"
                    bindLabel="label"
                    formControlName="age"
                    placeholder="Selecionar idade"
                    class="w-100"
                  >
                  </ng-select>
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'A idade é obrigatória',
                    control: trainingGeneratorForm.controls['age']
                  }"
                ></ng-container>
              </div>

              <div class="form-group mb-2 mb-md-3 mb-lg-4">
                <label for="goal-group" class="mb-2">OBJETIVO</label>
                <div for="goal-group" class="input-group">
                  <div class="radio radio-sm d-inline-block mr-3 mb-1">
                    <input
                      id="lose-switch"
                      type="radio"
                      name="goal"
                      formControlName="goal"
                      value="lose"
                    />
                    <label for="lose-switch" class="normal-case cursor-pointer"
                      >Emagrecimento</label
                    >
                  </div>
                  <div class="radio radio-sm d-inline-block mr-3 mb-1">
                    <input
                      id="maint-switch"
                      type="radio"
                      name="goal"
                      formControlName="goal"
                      value="maintain"
                    />
                    <label for="maint-switch" class="normal-case cursor-pointer"
                      >Manutenção</label
                    >
                  </div>
                  <div class="radio radio-sm d-inline-block mb-1">
                    <input
                      id="gain-switch"
                      type="radio"
                      name="goal"
                      formControlName="goal"
                      value="gain"
                    />
                    <label for="gain-switch" class="normal-case cursor-pointer"
                      >Hipertrofia</label
                    >
                  </div>
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'O objetivo é obrigatório',
                    control: trainingGeneratorForm.controls['goal']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'pattern',
                    message:
                      'O objetivo deve ser emagrecimento, manutenção ou hipertrofia',
                    control: trainingGeneratorForm.controls['goal']
                  }"
                ></ng-container>
              </div>
            </div>
            <!-- end first step -->

            <!-- start second step -->
            <div *ngIf="currentStep === 2" class="w-100 flex-grow-1">
              <div class="form-group mb-2 mb-md-3 mb-lg-4">
                <label for="level-group" class="mb-2">NÍVEL</label>
                <div id="level-group" class="input-group">
                  <div class="radio radio-sm d-inline-block mr-3 mb-1">
                    <input
                      id="beg-switch"
                      type="radio"
                      name="level"
                      formControlName="level"
                      value="beginner"
                    />
                    <label for="beg-switch" class="normal-case cursor-pointer"
                      >Iniciante</label
                    >
                  </div>
                  <div class="radio radio-sm d-inline-block mr-3 mb-1">
                    <input
                      id="inte-switch"
                      type="radio"
                      name="level"
                      formControlName="level"
                      value="intermediate"
                    />
                    <label for="inte-switch" class="normal-case cursor-pointer"
                      >Intermediário</label
                    >
                  </div>
                  <div class="radio radio-sm d-inline-block mb-1">
                    <input
                      id="adva-switch"
                      type="radio"
                      name="level"
                      formControlName="level"
                      value="advanced"
                    />
                    <label for="adva-switch" class="normal-case cursor-pointer"
                      >Avançado</label
                    >
                  </div>
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'O nível é obrigatório',
                    control: trainingGeneratorForm.controls['level']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'pattern',
                    message:
                      'O nível deve ser iniciante, intermediário ou avançado',
                    control: trainingGeneratorForm.controls['level']
                  }"
                ></ng-container>
              </div>

              <div class="form-group mb-2 mb-md-3 mb-lg-4">
                <label for="training-place-group" class="mb-2"
                  >LUGAR DE TREINO</label
                >
                <div id="training-place-group" class="input-group">
                  <div class="radio radio-sm d-inline-block mr-3 mb-1">
                    <input
                      id="gym-switch"
                      type="radio"
                      name="trainingPlace"
                      formControlName="trainingPlace"
                      value="gym"
                    />
                    <label for="gym-switch" class="normal-case cursor-pointer"
                      >Academia</label
                    >
                  </div>
                  <div
                    class="radio radio-sm d-inline-block mb-1 position-relative"
                  >
                    <input
                      id="home-switch"
                      type="radio"
                      name="trainingPlace"
                      formControlName="trainingPlace"
                      value="home"
                      disabled
                    />
                    <label for="home-switch" class="normal-case cursor-pointer"
                      >Casa</label
                    >
                    <span
                      class="badge badge-primary p-1 position-absolute badge-position font-small-1"
                      >Em breve</span
                    >
                  </div>
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'O lugar de treino é obrigatório',
                    control: trainingGeneratorForm.controls['trainingPlace']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'pattern',
                    message: 'O lugar de treino deve ser academia ou casa',
                    control: trainingGeneratorForm.controls['trainingPlace']
                  }"
                ></ng-container>
              </div>
            </div>
            <!-- end second step -->

            <!-- start third step -->
            <div
              *ngIf="currentStep === 3"
              class="w-100 flex-grow-1 d-flex flex-column justify-content-start"
            >
              <div class="w-100 form-group mb-1 mb-md-2">
                <label for="categories" class="mb-2">CATEGORIAS</label>
                <div
                  class="w-100 input-group mb-1"
                  aria-describedby="categories-warning"
                >
                  <ng-select
                    id="categories"
                    [multiple]="true"
                    [items]="categories"
                    bindValue="id"
                    bindLabel="name"
                    labelForId="categories"
                    formControlName="categoriesIds"
                    [closeOnSelect]="false"
                    [loading]="isCategoriesLoading"
                    (clear)="clearCategories()"
                    [loadingText]="'Carregando...'"
                    [notFoundText]="'Nenhuma categoria encontrada'"
                    placeholder="Selecionar Categoria"
                    class="w-100"
                  >
                    <ng-template ng-multi-label-tmp let-items="items">
                      <div
                        *ngIf="items.length > 0"
                        class="d-flex flex-wrap align-items-center w-100"
                      >
                        <div
                          class="ng-value bg-primary"
                          *ngFor="let item of items"
                        >
                          <span class="ng-value-label white">{{
                            item.name
                          }}</span>
                          <span
                            class="ng-value-icon right white"
                            (click)="deleteCategory(item.id)"
                            >×</span
                          >
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'As categorias são obrigatórias',
                    control: trainingGeneratorForm.controls['categoriesIds']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'minLength',
                    message: 'Selecione pelo menos uma categoria',
                    control: trainingGeneratorForm.controls['categoriesIds']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'minExercisesNumber',
                    message:
                      'O número de exercícios deve ser maior que 0 em todas as categorias',
                    control: trainingGeneratorForm.controls['categoriesIds']
                  }"
                ></ng-container>

                <div
                  id="categories-warning"
                  class="w-100 d-flex justify-content-start align-items-center my-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="24"
                    viewBox="0 0 23 24"
                    fill="none"
                  >
                    <path
                      d="M10.2234 2.36409L11.0333 2.83164L10.2234 2.36409L1.94916 16.6956C1.33851 17.7533 2.10183 19.0753 3.32313 19.0753H19.8717C21.093 19.0753 21.8563 17.7532 21.2457 16.6956L20.4358 17.1631L21.2457 16.6956L12.9714 2.36409L12.1616 2.83164L12.9714 2.36409C12.3607 1.30641 10.8341 1.30642 10.2234 2.36409Z"
                      stroke="#F77E17"
                      stroke-width="1.87018"
                    />
                    <path
                      d="M12.7071 7.73025L12.5522 13.425H11.0988L10.9399 7.73025H12.7071ZM11.8255 15.9665C11.5634 15.9665 11.3384 15.8739 11.1504 15.6885C10.9624 15.5006 10.8698 15.2755 10.8724 15.0134C10.8698 14.754 10.9624 14.5316 11.1504 14.3463C11.3384 14.161 11.5634 14.0683 11.8255 14.0683C12.077 14.0683 12.2981 14.161 12.4887 14.3463C12.6793 14.5316 12.7759 14.754 12.7786 15.0134C12.7759 15.1882 12.7296 15.3483 12.6396 15.494C12.5522 15.6369 12.4371 15.7521 12.2941 15.8395C12.1511 15.9242 11.9949 15.9665 11.8255 15.9665Z"
                      fill="#F77E17"
                    />
                  </svg>
                  <span class="text-secondary text-bold-400 font-small-3 ml-2"
                    >Selecionar na ordem de execução.</span
                  >
                </div>
              </div>

              <div class="w-100 d-flex flex-column mb-4 mb-md-3">
                <div
                  class="w-100 d-flex justify-content-between align-items-center pr-4 mb-2"
                >
                  <span class="text-secondary font-small-3"
                    >Selecionados:
                  </span>
                  <span class="text-secondary font-small-3"
                    >Quantidade de Exercícios</span
                  >
                </div>

                <div
                  class="w-100 d-flex flex-column justify-content-start exercises-container"
                >
                  <ng-container
                    *ngIf="
                      trainingGeneratorForm.get('categoriesIds').value.length >
                      0
                    "
                  >
                    <div
                      class="w-100 d-flex justify-content-between align-items-center p-2 border"
                      [ngClass]="{
                        'rounded-lg-top': i === 0,
                        'rounded-lg-bottom':
                          i ===
                          trainingGeneratorForm.get('categoriesIds').value
                            .length -
                            1
                      }"
                      *ngFor="
                        let categoryId of trainingGeneratorForm.get(
                          'categoriesIds'
                        ).value;
                        let i = index
                      "
                    >
                      <div class="d-flex align-items-center mr-1 mr-md-4">
                        <button
                          type="button"
                          (click)="deleteCategory(categoryId)"
                          class="exercise-delete__button rounded-circle btn btn-danger d-flex justify-content-center align-items-center p-0 mr-1 mr-md-2"
                        >
                          <i class="ft-minus font-small-1"></i>
                        </button>
                        <span class="black font-small-3">{{
                          getSelectedCategoryFromId(categoryId).name
                        }}</span>
                      </div>
                      <div
                        class="d-flex align-items-center"
                        *ngIf="
                          getSelectedCategoryFromId(categoryId) as category
                        "
                      >
                        <input
                          [id]="'category-' + i"
                          type="number"
                          autocomplete="off"
                          [name]="'categories.' + i + '.exercises'"
                          min="0"
                          placeholder="0"
                          [value]="category.exercises"
                          (input)="updateCategoryExercise(categoryId, $event)"
                          class="form-control mr-2 reduced-number__input"
                          [ngClass]="{
                            'is-invalid': category.exercises < 1,
                            'is-valid': category.exercises > 0
                          }"
                        />
                        <label
                          [htmlFor]="'category-' + i"
                          class="normal-case font-small-3"
                          >exercícios</label
                        >
                      </div>
                    </div>
                  </ng-container>
                  <span
                    class="black font-small-3"
                    *ngIf="
                      trainingGeneratorForm.get('categoriesIds').value
                        .length === 0
                    "
                    >Nenhuma categoria selecionada</span
                  >
                </div>
              </div>

              <div class="form-group mt-auto mb-0">
                <div class="input-group mb-1">
                  <div
                    class="checkbox checkbox-sm d-inline-block position-relative mr-3 mb-0"
                  >
                    <input
                      id="create-period-training"
                      type="checkbox"
                      disabled
                    />
                    <label
                      for="create-period-training"
                      class="normal-case cursor-pointer"
                      >Criar treino periodizado.</label
                    >
                    <span
                      class="badge badge-primary p-1 position-absolute badge-position font-small-1"
                      >Em breve</span
                    >
                  </div>
                </div>
                <div class="input-group mb-0">
                  <div
                    class="checkbox checkbox-sm d-inline-block position-relative mr-3"
                    [ngClass]="{
                      'mb-1': !!trainingGeneratorForm.get(
                        'showPreviousTrainnings'
                      ).value
                    }"
                  >
                    <input
                      id="past-tranning-usage"
                      type="checkbox"
                      formControlName="showPreviousTrainnings"
                    />
                    <label
                      for="past-tranning-usage"
                      class="normal-case cursor-pointer"
                      >Usar treinos prescritos como inteligência na I.A.</label
                    >
                  </div>
                  <div
                    *ngIf="
                      !!trainingGeneratorForm.get('showPreviousTrainnings')
                        .value
                    "
                    class="w-100 d-flex align-items-center justify-content-center position-relative mt-1 mt-md-2"
                  >
                    <ng-select
                      id="trainnings"
                      [multiple]="true"
                      [closeOnSelect]="false"
                      [items]="oldTrainnings"
                      [loading]="isOldTrainningsLoading"
                      [typeahead]="input$"
                      [notFoundText]="'Nenhum treino encontrado'"
                      [loadingText]="'Carregando...'"
                      [dropdownPosition]="'top'"
                      bindValue="id"
                      bindLabel="name"
                      placeholder="Selecione um treino"
                      class="ng-select-body-appended"
                      formControlName="previousTrainningsIds"
                      (clear)="clearOldTrainnings()"
                      (scrollToEnd)="
                        fetchMoreOldTrainnings(trainning.searchTerm)
                      "
                      #trainning
                    >
                      <ng-template ng-option-tmp let-item="item">
                        <div class="w-100">
                          <span
                            class="font-size-3 font-size-md-5 text-bold-400 whitespace-normal"
                          >
                            {{ item.name }}
                          </span>
                          <span
                            class="float-right grey font-size-2 font-size-md-4"
                          >
                            {{ getOldTrainningType(item.id) }}
                          </span>
                        </div>
                        <span class="grey font-size-2 font-size-md-4">
                          {{
                            "Criado em " +
                              getOldTrainningTransformedDate(item.id)
                          }}
                        </span>
                      </ng-template>
                      <ng-template ng-multi-label-tmp let-items="items">
                        <div
                          *ngIf="items.length > 0"
                          class="d-flex flex-wrap align-items-center w-100"
                        >
                          <div
                            class="ng-value bg-primary"
                            *ngFor="let item of items"
                          >
                            <span class="ng-value-label white">
                              {{ item.name }}
                            </span>
                            <span
                              class="ng-value-icon right white"
                              (click)="deleteOldTrainning(item.id)"
                            >
                              ×
                            </span>
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
              </div>
            </div>
            <!-- end third step -->

            <!-- start fourth step -->

            <div *ngIf="currentStep === 4" class="w-100 flex-grow-1">
              <div class="w-100 form-group mb-2 mb-md-3 mb-lg-4">
                <label for="series" class="mb-2"
                  >Quantidade de Séries POR EXERCÍCIOS</label
                >
                <div
                  class="input-group w-100 d-flex justify-content-start align-items-center mb-1"
                >
                  <input
                    id="series"
                    type="number"
                    autocomplete="off"
                    min="0"
                    class="w-auto form-control reduced-number__input rounded mr-2"
                    name="series"
                    formControlName="series"
                    [ngClass]="{
                      'is-invalid': validateField(
                        'series',
                        trainingGeneratorForm
                      ).invalid,
                      'is-valid': validateField('series', trainingGeneratorForm)
                        .valid
                    }"
                  />
                  <span class="d-block text-secondary font-small-3"
                    >séries</span
                  >
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'As séries são obrigatórias',
                    control: trainingGeneratorForm.controls['series']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'min',
                    message: 'As séries devem ser maiores que 0',
                    control: trainingGeneratorForm.controls['series']
                  }"
                ></ng-container>
              </div>

              <div class="w-100 form-group mb-2 mb-md-3 mb-lg-4">
                <label for="repetitions" class="mb-2"
                  >NÚMERO DE REPETIÇÕES</label
                >
                <div class="input-group mb-1">
                  <input
                    id="repetitions"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    name="repetitions"
                    formControlName="repetitions"
                    [ngClass]="{
                      'is-invalid': validateField(
                        'repetitions',
                        trainingGeneratorForm
                      ).invalid,
                      'is-valid': validateField(
                        'repetitions',
                        trainingGeneratorForm
                      ).valid
                    }"
                  />
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'As repetições são obrigatórias',
                    control: trainingGeneratorForm.controls['repetitions']
                  }"
                ></ng-container>
              </div>

              <div class="form-group mb-2 mb-md-3 mb-lg-4">
                <label for="break" class="mb-2">TEMPO DE DESCANSO</label>
                <div
                  id="break"
                  class="input-group w-100 d-flex justify-content-start align-items-center mb-1"
                  formGroupName="break"
                >
                  <div
                    class="d-flex justify-content-center align-items-center mr-2"
                  >
                    <input
                      id="breakMinute"
                      type="number"
                      autocomplete="off"
                      min="0"
                      class="w-auto form-control reduced-number__input rounded mr-1"
                      formControlName="minute"
                      [ngClass]="{
                        'is-invalid': validateField(
                          'break',
                          trainingGeneratorForm
                        ).invalid,
                        'is-valid': validateField(
                          'break',
                          trainingGeneratorForm
                        ).valid
                      }"
                    />
                    <span class="d-block text-secondary font-small-3 mr-2"
                      >min</span
                    >
                    <span
                      class="d-block text-secondary font-medium-1 text-bold-600"
                      >:</span
                    >
                  </div>
                  <div class="d-flex justify-content-center align-items-center">
                    <input
                      id="breakMinute"
                      type="number"
                      autocomplete="off"
                      min="0"
                      class="w-auto form-control reduced-number__input rounded mr-1"
                      formControlName="second"
                      [ngClass]="{
                        'is-invalid': validateField(
                          'break',
                          trainingGeneratorForm
                        ).invalid,
                        'is-valid': validateField(
                          'break',
                          trainingGeneratorForm
                        ).valid
                      }"
                    />
                    <span class="d-block text-secondary font-small-3">s</span>
                  </div>
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'Os minutos são obrigatórios',
                    control:
                      trainingGeneratorForm.controls['break'].controls['minute']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'min',
                    message: 'Os minutos devem ser maiores ou iguais a 0',
                    control:
                      trainingGeneratorForm.controls['break'].controls['minute']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'max',
                    message: 'Os minutos devem ser menores que 60',
                    control:
                      trainingGeneratorForm.controls['break'].controls['minute']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'Os segundos são obrigatórios',
                    control:
                      trainingGeneratorForm.controls['break'].controls['second']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'min',
                    message: 'Os segundos devem ser maiores que 0',
                    control:
                      trainingGeneratorForm.controls['break'].controls['second']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'max',
                    message:
                      'Os segundos devem ser menores que 60 (60 segundos devem ser definidos como 1 minuto)',
                    control:
                      trainingGeneratorForm.controls['break'].controls['second']
                  }"
                ></ng-container>
              </div>

              <div class="form-group mb-2 mb-md-3 mb-lg-4">
                <label for="speed-group" class="mb-2">MODO DE EXECUÇÃO</label>
                <div id="speed-group" class="input-group">
                  <div class="radio radio-sm d-inline-block mr-3 mb-1">
                    <input
                      id="isometric-switch"
                      type="radio"
                      formControlName="speed"
                      value="isometric"
                    />
                    <label
                      for="isometric-switch"
                      class="normal-case cursor-pointer"
                      >Isométrico</label
                    >
                  </div>
                  <div class="radio radio-sm d-inline-block mr-3 mb-1">
                    <input
                      id="slow-switch"
                      type="radio"
                      formControlName="speed"
                      value="slow"
                    />
                    <label for="slow-switch" class="normal-case cursor-pointer"
                      >Lento</label
                    >
                  </div>
                  <div class="radio radio-sm d-inline-block mr-3 mb-1">
                    <input
                      id="medium-switch"
                      type="radio"
                      formControlName="speed"
                      value="medium"
                    />
                    <label
                      for="medium-switch"
                      class="normal-case cursor-pointer"
                      >Moderado</label
                    >
                  </div>
                  <div class="radio radio-sm d-inline-block mb-1">
                    <input
                      id="fast-switch"
                      type="radio"
                      formControlName="speed"
                      value="fast"
                    />
                    <label for="fast-switch" class="normal-case cursor-pointer"
                      >Rápido</label
                    >
                  </div>
                </div>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'required',
                    message: 'O modo de execução é obrigatório',
                    control: trainingGeneratorForm.controls['speed']
                  }"
                ></ng-container>
                <ng-container
                  [ngTemplateOutlet]="formError"
                  [ngTemplateOutletContext]="{
                    validation: 'pattern',
                    message:
                      'O modo de execução deve ser isométrico, lento, moderado ou rápido',
                    control: trainingGeneratorForm.controls['speed']
                  }"
                ></ng-container>
              </div>
            </div>
            <!-- end fourth step -->
          </form>
        </div>
        <!-- end form block -->

        <!-- start footer block -->
        <div
          class="w-100 d-flex"
          [ngClass]="
            currentStep !== 1
              ? 'justify-content-between'
              : 'justify-content-end'
          "
        >
          <button
            *ngIf="currentStep !== 1"
            type="button"
            class="btn px-4 font-small-2 text-bold-600"
            (click)="prevStep()"
          >
            Voltar
          </button>
          <button
            type="button"
            class="btn btn-primary px-4 white font-small-2 text-bold-600"
            [disabled]="!canGoForward"
            (click)="currentStep === 4 ? onSubmit() : nextStep()"
          >
            {{ currentStep === 4 ? "Finalizar" : "Próximo" }}
          </button>
        </div>
        <!-- end footer block -->
      </div>
    </div>
    <!-- end wizard block -->
  </div>
  <!-- end main content block -->

  <!-- start loading block -->
  <div *ngIf="!isBannerVisible && isLoading" class="row flex-grow-1">
    <div
      class="d-flex col-12 bg-white justify-content-center align-items-center"
    >
      <div
        class="d-flex flex-column align-items-center justify-content-center mt-3 mb-2"
      >
        <div class="position-relative">
          <svg
            width="220"
            height="221"
            viewBox="0 0 220 221"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="spinner"
            *ngIf="loadingCurrentStep !== 4"
          >
            <circle cx="110.391" cy="111.458" r="68.3081" fill="#FF5E19" />
            <circle
              opacity="0.3"
              cx="110.392"
              cy="111.458"
              r="106.989"
              stroke="#FF5E19"
              stroke-width="4.93793"
            />
            <path
              d="M110.391 3.64551C139.421 3.64551 167.262 15.1776 187.79 35.7049"
              stroke="#FF5E19"
              stroke-width="6.58391"
              stroke-linecap="round"
            />
          </svg>

          <svg
            width="220"
            height="219"
            viewBox="0 0 220 219"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            *ngIf="loadingCurrentStep === 4"
            class="spinner"
          >
            <circle cx="110" cy="109.48" r="68.3221" fill="#FF5E19" />
            <circle
              cx="110"
              cy="109.48"
              r="105.58"
              stroke="#FF5E19"
              stroke-width="7.8"
            />
          </svg>

          <div
            class="position-absolute top-0 left-0 d-flex justify-content-center align-items-center icons-container"
          >
            <svg
              width="79"
              height="71"
              viewBox="0 0 79 71"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngIf="loadingCurrentStep === 1"
            >
              <path
                d="M70.9352 43.8236C62.6235 54.9671 52.0047 64.1854 39.804 70.849C27.6155 64.1776 17.0062 54.9603 8.69702 43.8236C-2.26771 29.3328 -1.71221 6.63047 13.962 1.72774C31.3027 -3.70633 39.804 22.3772 39.804 22.3772C39.804 22.3772 48.4502 -3.87539 65.6943 1.72774C81.2961 6.82368 81.8999 29.3328 70.9352 43.8236Z"
                fill="white"
              />
              <path
                d="M27.5834 59.4016L22.8739 39.7423L19.7825 46.6738H0.678772V44.4519H18.3334L23.5018 32.8592L27.1728 48.1953L32.3896 16.4121L36.9783 41.5537L40.6252 31.0478L46.3491 48.2436L47.6291 44.4519H78.9776V46.6496H49.2231L46.3491 55.2475L40.5769 37.9551L36.3262 50.2724L32.5103 29.4056L27.5834 59.4016Z"
                fill="#FF5E19"
              />
            </svg>

            <svg
              width="91"
              height="81"
              viewBox="0 0 91 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngIf="loadingCurrentStep === 2"
            >
              <path
                d="M75.7875 40.9532C86.3666 54.7283 80.9646 67.599 63.7231 69.6974C46.4817 71.7958 23.9302 62.3305 13.3511 48.5554C2.77207 34.7804 8.17409 21.9097 25.4155 19.8113C42.6569 17.7129 65.2085 27.1781 75.7875 40.9532Z"
                fill="#EB591A"
              />
              <path
                d="M32.9761 36.6369L35.245 35.9728L25.2703 35.0874L19.4044 35.4194L32.9761 36.6369Z"
                fill="#37474F"
              />
              <path
                d="M25.2703 21.7788V35.0877L19.4044 35.4197V18.9565L25.2703 21.7788Z"
                fill="#455A64"
              />
              <path
                d="M35.245 9.36929L30.7626 4.83154L19.4044 18.9566L25.2703 21.7789L35.245 9.36929Z"
                fill="#37474F"
              />
              <path
                d="M45.2197 10.2685L44.3481 6.03515L30.7626 4.83154L35.245 9.36929L45.2197 10.2685Z"
                fill="#263238"
              />
              <path
                d="M45.2197 23.5634V10.2684L35.2449 9.36914L25.2702 21.7788V35.0876L35.2449 35.973L45.2197 23.5634Z"
                fill="#455A64"
              />
              <path
                d="M19.4044 35.4198L11.5325 30.8683V14.4189L19.4044 18.9567V35.4198Z"
                fill="#455A64"
              />
              <path
                d="M30.7626 4.83138L19.4044 18.9565L11.5325 14.4187L22.8907 0.279785L30.7626 4.83138Z"
                fill="#37474F"
              />
              <path
                d="M44.3481 6.03498L36.4624 1.49724L22.8907 0.279785L30.7626 4.83138L44.3481 6.03498Z"
                fill="#263238"
              />
              <path
                opacity="0.2"
                d="M19.5981 27.2157C19.5981 24.4488 19.6811 21.7926 19.6811 19.0949L25.3256 12.1084L30.8594 5.12191C35.3418 5.46778 39.8242 5.79981 44.3066 6.0765C39.7966 5.53695 35.2865 5.05273 30.7626 4.59619H30.6242L30.5412 4.70687L24.8275 11.7487L19.1692 18.832V18.9012V18.915C19.1692 21.6819 19.1692 24.4488 19.266 27.1466C19.3629 29.8443 19.3905 32.6804 19.4459 35.4473C19.4597 32.7496 19.5704 29.9827 19.5981 27.2157Z"
                fill="black"
              />
              <path
                d="M22.8905 0.279785L19.584 0.930012L10.135 12.6894L11.5323 14.4187L22.8905 0.279785Z"
                fill="#37474F"
              />
              <path
                d="M10.1212 26.3995L11.5323 30.8681V14.4188L10.135 12.6895L10.1212 26.3995Z"
                fill="#455A64"
              />
              <g opacity="0.2">
                <path
                  d="M11.5317 30.8681C11.421 28.1011 11.3426 25.3619 11.2965 22.6503C11.2965 19.8834 11.2965 17.1165 11.2965 14.4187V14.3081V14.2251C13.1918 11.8732 15.101 9.52129 17.024 7.19708C18.947 4.87287 20.8977 2.56249 22.8899 0.279785C21.0453 2.71467 19.2007 5.12189 17.3561 7.50144C15.5115 9.88099 13.6299 12.2513 11.7116 14.6124V14.4187C11.7116 17.1857 11.7116 19.9526 11.7116 22.6503C11.7116 25.3481 11.6424 28.1288 11.5317 30.8681Z"
                  fill="black"
                />
              </g>
              <path
                opacity="0.1"
                d="M45.2197 10.2684L35.5078 10.0332L25.9619 22.0416L25.8236 34.7003L35.2449 35.973L25.2702 35.0876V21.7788L35.2449 9.36914L45.2197 10.2684Z"
                fill="white"
              />
              <path
                d="M16.6647 21.3085C16.7235 21.292 16.7857 21.292 16.8445 21.3085C16.9025 21.362 16.9536 21.4224 16.9967 21.4884L18.3802 23.8956L18.4908 24.1031C18.5135 24.1799 18.5135 24.2616 18.4908 24.3383V25.2514C18.501 25.2655 18.5064 25.2825 18.5064 25.2998C18.5064 25.3172 18.501 25.3341 18.4908 25.3482C18.467 25.3594 18.4411 25.3651 18.4148 25.3651C18.3885 25.3651 18.3625 25.3594 18.3387 25.3482L12.8048 22.1663C12.7474 22.1228 12.6962 22.0716 12.6527 22.0141C12.6326 21.9558 12.6326 21.8925 12.6527 21.8343V20.8658C12.6527 20.8658 12.6527 20.769 12.6527 20.7552C12.6765 20.744 12.7025 20.7383 12.7287 20.7383C12.755 20.7383 12.781 20.744 12.8048 20.7552L16.5402 22.9134L15.7793 21.5299C15.7627 21.4711 15.7627 21.4089 15.7793 21.35C15.7864 21.3288 15.7992 21.3098 15.8163 21.2952C15.8333 21.2806 15.854 21.2709 15.8761 21.267L16.6647 21.3085Z"
                fill="#263238"
              />
              <path
                d="M18.6712 28.5994C18.6916 28.892 18.6292 29.1844 18.4913 29.4433C18.3768 29.6306 18.1928 29.7649 17.9794 29.8168C17.7388 29.8777 17.4868 29.8777 17.2462 29.8168C16.9415 29.722 16.6453 29.6017 16.3608 29.4571L15.5861 29.0282L14.839 28.5717C14.5261 28.375 14.23 28.1529 13.9536 27.9076C13.6797 27.6594 13.4296 27.3862 13.2065 27.0914C13.0027 26.7944 12.8355 26.4739 12.7085 26.1368C12.5816 25.7959 12.516 25.4353 12.5148 25.0715C12.4978 24.7776 12.565 24.4848 12.7085 24.2276C12.8197 24.0432 12.9983 23.9093 13.2065 23.8541C13.4508 23.7856 13.7093 23.7856 13.9536 23.8541C14.2601 23.9377 14.5569 24.0536 14.839 24.2L15.5861 24.6288L16.3608 25.0854C16.6737 25.282 16.9698 25.5041 17.2462 25.7494C17.5194 25.9942 17.7652 26.2679 17.9794 26.5657C18.1927 26.8591 18.3649 27.1803 18.4913 27.5203C18.6154 27.8663 18.6763 28.2318 18.6712 28.5994ZM14.8805 27.2159L16.2639 28.046L16.6513 28.212C16.7567 28.2295 16.8642 28.2295 16.9695 28.212C17.0519 28.1981 17.126 28.1536 17.177 28.0875C17.241 27.9944 17.2703 27.8818 17.26 27.7693C17.262 27.6266 17.2337 27.4852 17.177 27.3543C17.1214 27.227 17.0519 27.1064 16.9695 26.9946C16.8705 26.8817 16.7642 26.7754 16.6513 26.6764C16.5304 26.5732 16.4008 26.4806 16.2639 26.3997C15.7936 26.1091 15.3094 25.8324 14.8805 25.5696L14.4931 25.3897C14.3885 25.365 14.2796 25.365 14.1749 25.3897C14.0926 25.4037 14.0185 25.4481 13.9674 25.5143C13.9035 25.6074 13.8741 25.72 13.8844 25.8325C13.8824 25.9751 13.9107 26.1166 13.9674 26.2475C14.023 26.3747 14.0926 26.4954 14.1749 26.6072C14.2667 26.7267 14.3736 26.8336 14.4931 26.9254C14.6135 27.0334 14.7431 27.1306 14.8805 27.2159Z"
                fill="#263238"
              />
              <path
                d="M60.9215 42.8763C61.2946 43.0486 61.707 43.1177 62.1158 43.0763C62.5246 43.0349 62.9148 42.8846 63.2458 42.6411C64.207 42.0199 65.0068 41.1791 65.5792 40.1881C66.1517 39.1971 66.4805 38.0842 66.5384 36.9412C66.5813 36.5335 66.5157 36.1216 66.348 35.7474C66.1803 35.3733 65.9167 35.0501 65.5838 34.8107L37.6379 18.6796C37.2625 18.5075 36.8479 18.4386 36.437 18.4799C36.0261 18.5213 35.6336 18.6714 35.2999 18.9148C34.3443 19.5408 33.5496 20.383 32.98 21.3732C32.4103 22.3635 32.0818 23.4739 32.0211 24.6146C31.9767 25.0225 32.0417 25.4349 32.2095 25.8093C32.3773 26.1838 32.6417 26.5068 32.9757 26.7452L60.9215 42.8763Z"
                fill="#F0F0F0"
              />
              <path
                d="M66.5384 36.8998C66.6197 36.4886 66.5701 36.0623 66.3966 35.6807C66.2231 35.2991 65.9345 34.9815 65.5712 34.7723C65.208 34.5632 64.7883 34.4732 64.3712 34.5148C63.9541 34.5564 63.5606 34.7277 63.2458 35.0045C62.2884 35.6298 61.4916 36.4715 60.9196 37.4616C60.3476 38.4518 60.0166 39.5626 59.9532 40.7043C59.8719 41.1155 59.9215 41.5419 60.095 41.9234C60.2684 42.305 60.5571 42.6227 60.9204 42.8318C61.2836 43.0409 61.7033 43.1309 62.1204 43.0893C62.5375 43.0477 62.931 42.8764 63.2458 42.5996C64.207 41.9784 65.0068 41.1377 65.5793 40.1467C66.1518 39.1557 66.4805 38.0428 66.5384 36.8998Z"
                fill="#FAFAFA"
              />
              <path
                d="M55.0835 39.5009L53.9629 38.8507C53.629 38.6155 53.3643 38.2951 53.1964 37.9228C53.0285 37.5505 52.9635 37.1399 53.0083 36.734C53.064 35.5905 53.3918 34.4767 53.9645 33.4854C54.5372 32.494 55.3382 31.6536 56.301 31.0341C56.631 30.7945 57.0187 30.6464 57.4245 30.6051C57.8303 30.5638 58.2398 30.6307 58.6113 30.7989L59.7319 31.4492C59.3601 31.2749 58.9484 31.2035 58.5396 31.2424C58.1308 31.2814 57.74 31.4292 57.4077 31.6705C56.4481 32.2934 55.6495 33.1345 55.0772 34.1252C54.5049 35.1158 54.1752 36.2279 54.1151 37.3704C54.0722 37.7795 54.1394 38.1928 54.3096 38.5673C54.4799 38.9419 54.747 39.2642 55.0835 39.5009Z"
                fill="#E0E0E0"
              />
              <path
                d="M39.7685 30.6605L38.6617 30.0241C38.3277 29.7857 38.0633 29.4627 37.8955 29.0882C37.7277 28.7138 37.6627 28.3014 37.7071 27.8935C37.7639 26.7503 38.0922 25.637 38.6648 24.6458C39.2373 23.6546 40.0378 22.814 40.9997 22.1937C41.3293 21.9522 41.7178 21.8042 42.1245 21.7652C42.5311 21.7263 42.9408 21.7978 43.3101 21.9723L44.4307 22.6087C44.0577 22.4364 43.6453 22.3674 43.2365 22.4087C42.8277 22.4501 42.4375 22.6004 42.1065 22.8439C41.1471 23.4637 40.3485 24.3023 39.7761 25.2907C39.2037 26.2792 38.8739 27.3893 38.8139 28.5299C38.7709 28.9377 38.8366 29.3496 39.0043 29.7237C39.1719 30.0979 39.4356 30.4211 39.7685 30.6605Z"
                fill="#E0E0E0"
              />
              <path
                d="M50.1861 36.6926L43.504 32.8328C43.1675 32.596 42.9003 32.2737 42.7301 31.8992C42.5598 31.5246 42.4927 31.1114 42.5356 30.7022C42.599 29.5605 42.9301 28.4497 43.502 27.4596C44.074 26.4694 44.8709 25.6277 45.8282 25.0024C46.1605 24.7611 46.5513 24.6132 46.9601 24.5743C47.3689 24.5354 47.7806 24.6068 48.1524 24.781L54.8346 28.6271C54.4605 28.459 54.0489 28.3923 53.641 28.4336C53.233 28.4748 52.8431 28.6227 52.5103 28.8622C51.553 29.4875 50.7561 30.3293 50.1841 31.3194C49.6122 32.3096 49.2811 33.4204 49.2177 34.5621C49.1748 34.9713 49.242 35.3845 49.4122 35.759C49.5825 36.1336 49.8496 36.4559 50.1861 36.6926Z"
                fill="#E0E0E0"
              />
              <path
                d="M78.381 62.84L80.636 62.1898L70.6613 61.2905L64.7954 61.6225L78.381 62.84Z"
                fill="#37474F"
              />
              <path
                d="M70.6613 47.9951V61.2902L64.7954 61.6222V45.1729L70.6613 47.9951Z"
                fill="#455A64"
              />
              <path
                d="M80.636 35.5858L76.1674 31.0342L64.7954 45.1731L70.6613 47.9954L80.636 35.5858Z"
                fill="#37474F"
              />
              <path
                d="M90.6108 36.4712L89.7392 32.2516L76.1675 31.0342L80.636 35.5858L90.6108 36.4712Z"
                fill="#263238"
              />
              <path
                d="M90.6106 49.7797V36.4709L80.6359 35.5854L70.6611 47.9951V61.2901L80.6359 62.1894L90.6106 49.7797Z"
                fill="#455A64"
              />
              <path
                d="M64.7955 61.6225L56.9236 57.0847V40.6216L64.7955 45.1732V61.6225Z"
                fill="#455A64"
              />
              <path
                d="M76.1675 31.0338L64.7955 45.1728L56.9236 40.6212L68.2818 26.4961L76.1675 31.0338Z"
                fill="#37474F"
              />
              <path
                d="M89.7392 32.2513L81.8673 27.6997L68.2817 26.4961L76.1674 31.0338L89.7392 32.2513Z"
                fill="#263238"
              />
              <path
                opacity="0.2"
                d="M64.989 53.4047C64.989 50.6378 65.0721 47.9816 65.0721 45.27L70.7166 38.3527L76.2504 31.4354C80.7328 31.7859 85.2198 32.0994 89.7115 32.3761C85.2014 31.8366 80.6775 31.3662 76.1536 30.9097H76.0152L75.9322 31.0203L70.2185 38.0483L64.5602 45.1316V45.2146V45.2008C64.5602 47.9677 64.5602 50.7346 64.6432 53.5016C64.7262 56.2685 64.7677 59.0354 64.823 61.7193C64.8645 58.8832 64.9614 56.1439 64.989 53.4047Z"
                fill="black"
              />
              <path
                d="M68.2816 26.4961L64.9751 27.1325L55.5261 38.9057L56.9233 40.6212L68.2816 26.4961Z"
                fill="#37474F"
              />
              <path
                d="M55.5261 52.602L56.9233 57.0844V40.6213L55.5261 38.9058V52.602Z"
                fill="#455A64"
              />
              <g opacity="0.2">
                <path
                  d="M56.9233 57.0844C56.8265 54.3174 56.7297 51.5505 56.702 48.7836C56.6743 46.0167 56.702 43.2498 56.619 40.552V40.4414V40.3584C58.5189 38.0065 60.4281 35.6638 62.3465 33.3304C64.2695 31.0062 66.234 28.6958 68.2123 26.4131C66.3677 28.8388 64.5231 31.246 62.6785 33.6347C60.8108 36.0005 58.9293 38.38 57.034 40.7319L57.1032 40.5382C57.1032 43.3051 57.1032 46.072 57.034 48.7698C56.9648 51.4675 57.1032 54.3451 56.9233 57.0844Z"
                  fill="black"
                />
              </g>
              <path
                opacity="0.1"
                d="M90.6106 36.4709L80.8987 36.2357L71.3529 48.2441L71.2284 60.9166L80.6359 62.1894L70.6611 61.2901V47.9951L80.6359 35.5854L90.6106 36.4709Z"
                fill="white"
              />
              <path
                d="M62.0697 47.511C62.1297 47.5207 62.1866 47.5444 62.2358 47.5802C62.3 47.6215 62.3524 47.6787 62.388 47.7462L63.7714 50.1534C63.7714 50.1534 63.7714 50.2918 63.8959 50.3748C63.9142 50.4521 63.9142 50.5326 63.8959 50.61V51.5092C63.9044 51.5239 63.9089 51.5406 63.9089 51.5576C63.9089 51.5746 63.9044 51.5913 63.8959 51.6061C63.8422 51.625 63.7836 51.625 63.7299 51.6061L58.1961 48.4103C58.1253 48.3795 58.0669 48.3259 58.0301 48.2581C58.0154 48.199 58.0154 48.1373 58.0301 48.0782V47.1098C58.0216 47.0951 58.0171 47.0784 58.0171 47.0614C58.0171 47.0444 58.0216 47.0277 58.0301 47.013C58.0839 46.995 58.1422 46.995 58.1961 47.013L61.9314 49.1712L61.1567 47.7877C61.1452 47.7615 61.1394 47.7333 61.1394 47.7047C61.1394 47.6761 61.1452 47.6479 61.1567 47.6217C61.1567 47.5997 61.1654 47.5786 61.181 47.563C61.1965 47.5474 61.2177 47.5387 61.2397 47.5387L62.0697 47.511Z"
                fill="#263238"
              />
              <path
                d="M64.0206 54.802C64.0353 55.0958 63.9683 55.3879 63.827 55.6459C63.7157 55.8303 63.5371 55.9643 63.3289 56.0195C63.0835 56.0798 62.8272 56.0798 62.5818 56.0195C62.2758 55.9283 61.9793 55.8078 61.6964 55.6597L60.9355 55.2309L60.1746 54.7882C59.8641 54.5881 59.5682 54.3662 59.2892 54.1241C59.0125 53.8777 58.7663 53.599 58.556 53.294C58.3468 53.0003 58.1792 52.6791 58.0579 52.3395C57.9256 52.0049 57.8598 51.6477 57.8642 51.288C57.8413 50.9891 57.9088 50.6903 58.0579 50.4303C58.1114 50.339 58.1833 50.2598 58.269 50.1979C58.3548 50.136 58.4525 50.0926 58.556 50.0706C58.7966 50.0097 59.0486 50.0097 59.2892 50.0706C59.5969 50.1569 59.8939 50.2775 60.1746 50.4303L60.9355 50.8453L61.6964 51.3019C62.0117 51.4951 62.3081 51.7174 62.5818 51.9659C62.8664 52.2084 63.1176 52.4875 63.3289 52.796C63.5381 53.0897 63.7057 53.4109 63.827 53.7506C63.9533 54.0868 64.0189 54.4428 64.0206 54.802ZM60.2161 53.4185C60.7142 53.7229 61.1845 53.9996 61.5996 54.2486C61.7518 54.2486 61.8763 54.387 62.0008 54.4285C62.1007 54.4536 62.2052 54.4536 62.3051 54.4285C62.3466 54.4314 62.3881 54.4225 62.4248 54.403C62.4615 54.3834 62.492 54.3539 62.5127 54.3178C62.5766 54.2195 62.6058 54.1026 62.5957 53.9858C62.595 53.8434 62.5668 53.7024 62.5127 53.5707C62.4675 53.4427 62.3969 53.325 62.3051 53.2249C62.2147 53.1088 62.1127 53.0022 62.0008 52.9067C61.875 52.8034 61.7408 52.7109 61.5996 52.63C61.1538 52.3285 60.6919 52.0514 60.2161 51.7999C60.0941 51.7257 59.9642 51.6653 59.8288 51.62C59.7234 51.6025 59.6159 51.6025 59.5106 51.62C59.4282 51.634 59.3541 51.6784 59.303 51.7446C59.2749 51.8534 59.2749 51.9677 59.303 52.0766C59.2822 52.2141 59.2822 52.3541 59.303 52.4916C59.3586 52.6189 59.4282 52.7395 59.5106 52.8513C59.6063 52.963 59.7129 53.065 59.8288 53.1557C59.9711 53.2435 60.119 53.322 60.2715 53.3909L60.2161 53.4185Z"
                fill="#263238"
              />
            </svg>

            <svg
              width="79"
              height="92"
              viewBox="0 0 79 92"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              *ngIf="loadingCurrentStep === 3"
            >
              <path
                d="M62.9321 53.5173C75.2253 62.5307 74.7698 76.4816 61.9149 84.674C49.0599 92.8663 28.6745 92.2008 16.3813 83.1873C4.08815 74.1739 4.54364 60.2229 17.3986 52.0306C30.2536 43.8382 50.6389 44.5038 62.9321 53.5173Z"
                fill="#EB591A"
              />
              <path
                d="M44.8207 2.8501H30.7671V10.7998H44.8207V2.8501Z"
                fill="#263238"
              />
              <path
                d="M46.8142 0.16748H28.7981V5.38527H46.8142V0.16748Z"
                fill="#455A64"
              />
              <path
                d="M13.4057 10.7157L9.5769 14.5444L15.6159 20.5835L19.4447 16.7547L13.4057 10.7157Z"
                fill="#263238"
              />
              <path
                d="M12.493 7.56925L6.41919 13.6431L10.3698 17.5937L16.4436 11.5199L12.493 7.56925Z"
                fill="#455A64"
              />
              <path
                d="M56.1527 16.7372L59.9814 20.5659L66.0204 14.5269L62.1917 10.6981L56.1527 16.7372Z"
                fill="#263238"
              />
              <path
                d="M59.1237 11.4853L65.1975 17.5591L69.1481 13.6085L63.0743 7.53466L59.1237 11.4853Z"
                fill="#455A64"
              />
              <path
                d="M73.1315 49.1715C76.1481 30.5066 63.4626 12.9303 44.7977 9.91373C26.1329 6.89718 8.55661 19.5827 5.54007 38.2476C2.52352 56.9125 15.209 74.4889 33.8739 77.5054C52.5387 80.522 70.115 67.8364 73.1315 49.1715Z"
                fill="#455A64"
              />
              <path
                d="M37.3878 75.9731C55.3849 75.9731 69.9744 61.3836 69.9744 43.3865C69.9744 25.3894 55.3849 10.7998 37.3878 10.7998C19.3908 10.7998 4.80127 25.3894 4.80127 43.3865C4.80127 61.3836 19.3908 75.9731 37.3878 75.9731Z"
                fill="#263238"
              />
              <path
                d="M66.1997 48.343C68.7347 32.6563 58.0733 17.8847 42.3867 15.3496C26.7 12.8145 11.9284 23.476 9.39336 39.1627C6.8583 54.8494 17.5198 69.621 33.2064 72.1561C48.893 74.6911 63.6646 64.0296 66.1997 48.343Z"
                fill="white"
              />
              <path
                d="M38.0768 17.1006H37.5107V21.457H38.0768V17.1006Z"
                fill="#C7C7C7"
              />
              <path
                d="M24.7251 20.5412L24.2349 20.8242L26.413 24.5969L26.9033 24.3139L24.7251 20.5412Z"
                fill="#C7C7C7"
              />
              <path
                d="M14.8321 30.1841L14.5491 30.6743L18.3218 32.8525L18.6048 32.3622L14.8321 30.1841Z"
                fill="#C7C7C7"
              />
              <path
                d="M15.4579 43.5093H11.1016V44.0753H15.4579V43.5093Z"
                fill="#C7C7C7"
              />
              <path
                d="M18.3206 54.7313L14.5471 56.9082L14.83 57.3985L18.6035 55.2217L18.3206 54.7313Z"
                fill="#C7C7C7"
              />
              <path
                d="M26.4053 62.9592L24.2297 66.7334L24.7202 67.0161L26.8957 63.2419L26.4053 62.9592Z"
                fill="#C7C7C7"
              />
              <path
                d="M38.0768 66.1284H37.5107V70.4848H38.0768V66.1284Z"
                fill="#C7C7C7"
              />
              <path
                d="M49.2063 62.9616L48.7161 63.2446L50.8942 67.0174L51.3845 66.7343L49.2063 62.9616Z"
                fill="#C7C7C7"
              />
              <path
                d="M57.2894 54.6763L57.0063 55.1665L60.7791 57.3447L61.0621 56.8544L57.2894 54.6763Z"
                fill="#C7C7C7"
              />
              <path
                d="M64.486 43.5093H60.1296V44.0753H64.486V43.5093Z"
                fill="#C7C7C7"
              />
              <path
                d="M60.7479 30.2243L56.9749 32.4019L57.2578 32.8922L61.0309 30.7146L60.7479 30.2243Z"
                fill="#C7C7C7"
              />
              <path
                d="M50.8897 20.5771L48.7129 24.3506L49.2033 24.6335L51.3801 20.86L50.8897 20.5771Z"
                fill="#C7C7C7"
              />
              <path
                d="M38.0028 22.9831L39.2827 45.5033L36.3784 45.4787L37.2152 22.9585L38.0028 22.9831Z"
                fill="#263238"
              />
              <path
                d="M38.7907 43.8048C38.7907 43.9995 38.733 44.1898 38.6248 44.3517C38.5167 44.5136 38.3629 44.6398 38.183 44.7143C38.0031 44.7889 37.8052 44.8084 37.6142 44.7704C37.4232 44.7324 37.2478 44.6386 37.1101 44.5009C36.9724 44.3632 36.8787 44.1878 36.8407 43.9968C36.8027 43.8059 36.8222 43.6079 36.8967 43.428C36.9712 43.2481 37.0974 43.0944 37.2593 42.9862C37.4212 42.878 37.6116 42.8203 37.8063 42.8203C38.0674 42.8203 38.3178 42.924 38.5024 43.1087C38.687 43.2933 38.7907 43.5437 38.7907 43.8048Z"
                fill="#FF5E19"
              />
            </svg>

            <i
              class="ft-check white font-large-5"
              *ngIf="loadingCurrentStep === 4"
            ></i>
          </div>
        </div>
        <div
          class="d-flex flex-column loading-text-container mt-5 px-2 px-md-0"
        >
          <h4 class="black font-size-8 font-size-md-10 text-center">
            {{ getLoadingContent(loadingCurrentStep).title }}
          </h4>
          <span
            class="text-secondary font-size-5 font-size-md-7 text-center mt-3"
            >{{ getLoadingContent(loadingCurrentStep).description }}</span
          >
        </div>
        <div
          class="d-flex justify-content-center align-items-center mt-5 pb-3 px-2 p-md-0"
        >
          <svg
            width="30"
            height="27"
            viewBox="0 0 30 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.1847 19.6162C14.2351 19.6162 13.4641 20.3864 13.4641 21.336C13.4641 22.2859 14.2351 23.0562 15.1847 23.0562C16.1342 23.0562 16.9044 22.2859 16.9044 21.336C16.9045 20.3864 16.1343 19.6162 15.1847 19.6162Z"
              fill="#FF5E19"
            />
            <path
              d="M29.2773 22.4453L17.7172 2.42229C17.1965 1.52024 16.226 0.959961 15.1838 0.959961C14.1424 0.959961 13.1729 1.52029 12.6522 2.42229L1.09119 22.4444C0.570515 23.3464 0.570515 24.4671 1.09119 25.3691C1.61192 26.2711 2.58232 26.8311 3.62372 26.8311H26.7449C27.7862 26.8311 28.7567 26.2711 29.2773 25.3691C29.7981 24.4671 29.7981 23.3464 29.2773 22.4453ZM26.9785 23.8759C26.8116 24.1646 26.5008 24.3436 26.1666 24.3436H4.20154C3.86819 24.3436 3.55691 24.1646 3.39086 23.875C3.22316 23.5859 3.22231 23.2275 3.39006 22.9388L14.3728 3.91552C14.5393 3.62681 14.8501 3.44741 15.1847 3.44741C15.5185 3.44741 15.8289 3.62681 15.9958 3.91597L26.9785 22.9385C27.1454 23.2275 27.1454 23.5868 26.9785 23.8759Z"
              fill="#FF5E19"
            />
            <path
              d="M15.1847 8.05176C14.2351 8.05176 13.4641 8.82193 13.4641 9.77197L14.1249 17.5505C14.1249 18.1358 14.5989 18.6098 15.1847 18.6098C15.7695 18.6098 16.2444 18.1358 16.2444 17.5505L16.9044 9.77197C16.9045 8.82188 16.1343 8.05176 15.1847 8.05176Z"
              fill="#FF5E19"
            />
          </svg>
          <span class="ml-2 font-size-4 font-size-md-5 text-secondary"
            >Não feche essa janela, ou perderá seu progresso!</span
          >
        </div>
      </div>
    </div>
  </div>
  <!-- end loading block -->
</div>

<ng-template #generatedTrainingRef let-modal>
  <div class="card m-0 rounded-lg">
    <div class="card-header py-3 bg-primary position-relative">
      <h4 class="card-title text-center white font-medium-4 m-0">Treino</h4>
      <button
        type="button"
        class="btn btn-danger btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
        (click)="modal.close('Close click')"
      >
        <i class="fa fa-times font-bold-400" aria-hidden="true"></i>
      </button>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                <th class="text-center p-2">#</th>
                <th class="text-center p-2">Exercício</th>
                <th class="text-center p-2">Categoria</th>
                <th class="text-center p-2">Repetições</th>
                <th class="text-center p-2">Séries</th>
                <th class="text-center p-2">Descanso</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let training of generatedTraining; let i = index">
                <th class="text-center p-2">{{ i + 1 }}</th>
                <th class="text-center p-2">{{ training.exercicio }}</th>
                <th class="text-center p-2">{{ training.category }}</th>
                <th class="text-center p-2">{{ training.repetitions }}</th>
                <th class="text-center p-2">{{ training.series }}</th>
                <th class="text-center p-2">
                  {{ training.break.minute * 60 + training.break.second }}
                </th>
              </tr>
              <tr *ngIf="!generatedTraining || generatedTraining.length === 0">
                <td colspan="9">
                  <p class="text-center">Não há nenhum treino</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #formError
  let-control="control"
  let-message="message"
  let-validation="validation"
>
  <ng-container
    *ngIf="
      control &&
      control.hasError(validation) &&
      (control.dirty || control.touched)
    "
  >
    <div>
      <span role="alert" class="font-small-3 text-danger text-bold-600">
        {{ message }}
      </span>
    </div>
  </ng-container>
</ng-template>
