export const secToTimeObj = (seconds: number, hideFields?: string[]) => {
  const hour = Math.floor(seconds / 3600);
  const minute = Math.floor((seconds - hour * 3600) / 60);
  const second = seconds - hour * 3600 - minute * 60;

  const timeObj = {
    hour,
    minute,
    second,
  };

  if (hideFields) {
    hideFields.forEach((field) => {
      delete timeObj[field];
    });
  }

  return timeObj;
};
