<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem">Buscar Usuário</h1>
    </div>
  </div>
</div>

<div class="row mb-2 mt-2">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-6">
            <fieldset>
              <div class="input-group">
                <input
                  type="text"
                  id="email"
                  class="form-control"
                  name="email"
                  [(ngModel)]="filterEmail"
                  placeholder="Buscar usuário"
                />
                <div class="input-group-append">
                  <button
                    class="btn btn-primary"
                    type="button"
                    (click)="searchUhser()"
                  >
                    <i class="fa fa-search"></i> Buscar
                  </button>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table text-center m-0">
              <table class="table-striped" style="width: 100%">
                <thead>
                  <tr>
                    <th></th>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Tipo</th>
                    <th>Dias Gratuitos Restantes</th>
                    <th>Data da exclusão</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="usuario != null">
                    <td>
                      <img
                        [src]="
                          usuario.photo_url
                            ? usuario.photo_url
                            : '../../assets/img/portrait/small/no-avatar.svg'
                        "
                        alt="product"
                        style="height: 75px"
                      />
                    </td>
                    <td>{{ usuario.name }}</td>
                    <td>{{ usuario.email }}</td>
                    <td>{{ usuario.type == "1" ? "Aluno" : "Personal" }}</td>
                    <td>{{ getDiasGratuitos(usuario.freeUntil) }}</td>
                    <td>
                      {{
                        usuario.deletedAt != null
                          ? formatDateWithoutTime(usuario.deletedAt)
                          : "-"
                      }}
                    </td>
                    <td>
                      <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                        <button
                          type="button"
                          class="btn btn-outline-warning btn-sm dropdown-toggle"
                          ngbDropdownToggle
                        >
                          <i class="ft-more-vertical"></i>
                        </button>
                        <div ngbDropdownMenu class="dropdown-menu" role="menu">
                          <a
                            class="dropdown-item"
                            (click)="openModal(dados, usuario, false)"
                            >Visualizar</a
                          >
                          <a
                            class="dropdown-item"
                            (click)="
                              gerarSenhaProvisoria(
                                userProvisoryPassword,
                                usuario
                              )
                            "
                            >Senha Provisória</a
                          >
                          <a
                            class="dropdown-item"
                            (click)="
                              liberaAtualizacaoDiasGratuitos(
                                updateFreeUntil,
                                usuario
                              )
                            "
                            >Dias Gratuitos</a
                          >
                          <a
                            class="dropdown-item"
                            (click)="toggleType(usuario)"
                            >{{
                              usuario.type == "1"
                                ? "Tornar Usuário Personal"
                                : "Tornar Usuário Aluno"
                            }}</a
                          >
                          <a
                            *ngIf="usuario.type == '1'"
                            class="dropdown-item"
                            (click)="openInfoUsuariosModal(usuario)"
                          >
                            Visualizar Relações
                          </a>
                          <a
                            class="dropdown-item"
                            (click)="toggleBanner(usuario)"
                            >{{
                              hideCustomerAppBanner == true
                                ? "Exibir Banner do App"
                                : "Ocultar Banner do App"
                            }}</a
                          >
                          <a
                            class="dropdown-item"
                            (click)="toggleStatus(usuario)"
                            >{{
                              usuario.status
                                ? "Inativar Usuário"
                                : "Reativar Usuário"
                            }}</a
                          >
                          <a
                            class="dropdown-item"
                            (click)="atualizaAssinatura(usuario)"
                            *ngIf="usuario.subscriptions.length > 0"
                            >{{
                              usuario.subscriptions[0].deletedAt
                                ? "Reativar Assinatura"
                                : "Inativar Assinatura"
                            }}</a
                          >
                          <a
                            class="dropdown-item"
                            *ngIf="usuario.deletedAt != null"
                            (click)="reactivateUser(usuario)"
                          >
                            Incluir Novamente Usuário
                          </a>
                          <a
                            class="dropdown-item"
                            *ngIf="usuario.blocks.length > 2"
                            (click)="unblockUser(usuario)"
                            >Desbloquear Usuário
                          </a>
                          <a
                            class="dropdown-item"
                            (click)="openManageSubscription(usuario)"
                          >
                            Gerênciar Assinatura
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="usuario == null">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum usuário encontrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #dados>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Informações</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item" *ngIf="userView && userView.createdAt">
            {{
              "Data de Cadastro : " + formatDateWithoutTime(userView.createdAt)
            }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.freeUntil">
            {{ "Gratuito até : " + formatDateWithoutTime(userView.freeUntil) }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.name">
            {{ userView && userView.name ? "Nome: " + userView.name : "" }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.email">
            {{ userView && userView.email ? "Email: " + userView.email : "" }}
          </li>
          <li class="list-group-item">
            {{
              userView && userView.emailValidated
                ? "status email: Confirmado"
                : "status email: Não Confirmado"
            }}
          </li>
          <li class="list-group-item">
            {{
              userView && userView.status
                ? "Status : Ativo"
                : "Status : Inativo"
            }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.gender">
            {{
              userView.gender == "2" ? "Sexo : Feminino" : "Sexo : Masculino"
            }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.phone">
            {{ "Telefone : " + userView.phone }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.lastLogin">
            {{ "Última acesso : " + formatDate(userView.lastLogin) }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.lastVersion">
            {{ "Última versão app : " + userView.lastVersion }}
          </li>
          <li
            class="list-group-item"
            *ngIf="userView && userView.hasIAGOAccess"
          >
            {{
              userView.hasIAGOAccess === "true"
                ? "Acesso IAGO : Ativo"
                : "Acesso IAGO : Inativo"
            }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.isBetaTester">
            {{
              userView.isBetaTester === "true"
                ? "Beta Teste : Ativo"
                : "Beta Teste : Inativo"
            }}
          </li>
        </ul>
        <button
          type="button"
          class="btn btn-primary mt-2"
          (click)="openModal(parametros, usuario, false)"
        >
          Parâmetros
        </button>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #userProvisoryPassword>
  <div class="card" *ngIf="userView && userView.provisoryPassword">
    <div class="card-header">
      <h4 class="card-title">Senha Provisória</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="form-row justify-content-center mb-2 mt-2">
          <div class="col text-center">
            <input
              type="text"
              id="aaaa"
              class="form-control text-center"
              disabled="true"
              [value]="userView.provisoryPassword"
              name="aaaa"
              required
              aria-describedby="basic-addon1"
            />
            <small class="form-text text-muted warning"
              >Senha provisória copiada!</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #updateFreeUntil>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <label for="diasGratuitosI">Dias Gratuitos</label>
              <div class="input-group">
                <input
                  type="text"
                  id="diasGratuitosI"
                  placeholder="00/00/0000"
                  class="form-control"
                  name="diasGratuitosI"
                  [(ngModel)]="diasGratuitosInput"
                  ngbDatepicker
                  #diasGratuitosIn="ngbDatepicker"
                  [container]="'body'"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-secondary input-group-text"
                    (click)="diasGratuitosIn.toggle()"
                  >
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-lg btn-warning btn-block mb-md-0"
                (click)="atualizaDiasGratuitos()"
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #parametros>
  <div class="card">
    <div class="card-header">
      <button
        type="button"
        class="close"
        (click)="closeModal()"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <h5 class="card-title" id="parametrosModalLabel">Parâmetros</h5>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="modal-body">
          <div class="form-group">
            <label for="usuario.isBetaTester">Beta Tester</label>
            <select
              id="usuario.isBetaTester"
              class="form-control"
              [(ngModel)]="usuario.isBetaTester"
            >
              <option [value]="true">Ativar</option>
              <option [value]="false">Inativar</option>
            </select>
          </div>
          <div class="form-group">
            <label for="usuario.hasIAGOAccess">Acesso IAGO</label>
            <select
              id="usuario.hasIAGOAccess"
              class="form-control"
              [(ngModel)]="usuario.hasIAGOAccess"
            >
              <option [value]="true">Ativar</option>
              <option [value]="false">Inativar</option>
            </select>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="closeModal()"
          >
            Fechar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="atualizarParametros(usuario)"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ngx-spinner></ngx-spinner>
