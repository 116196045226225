<div class="modal-header">
  <h4 class="modal-title">Adicionar Contato</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="contactForm" (ngSubmit)="submit()">
    <div class="form-group">
      <label for="contact">Contato</label>
      <input
        type="text"
        id="contact"
        class="form-control"
        formControlName="contact"
      />
    </div>
    <div class="form-group">
      <label for="contactTypeId">Tipo de Contato</label>
      <select
        id="contactTypeId"
        class="form-control"
        formControlName="contactTypeId"
      >
        <option *ngFor="let type of contactTypes" [value]="type.id">
          <i [class]="type.icon"></i> {{ type.name }}
        </option>
      </select>
    </div>
    <div
      class="form-group"
      *ngIf="contactForm.get('contactTypeId')?.value === 'outro'"
    >
      <label for="customContactType">Especifique o Tipo</label>
      <input
        type="text"
        id="customContactType"
        class="form-control"
        formControlName="customContactType"
      />
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="close()">
        Cancelar
      </button>
      <button type="submit" class="btn btn-primary">Adicionar</button>
    </div>
  </form>
</div>
