<ngx-spinner></ngx-spinner>
<div class="row ml-lg-2 mr-lg-2">
    <div class="col">
        <div class="card">
            <div class="card-content">
                <div class="card-body">
                    <div class="row" *ngIf="!avaliation">
                        <div class="col-12 text-center">
                            <h2>Avaliação Não Localizada :(</h2>
                        </div>
                    </div>
                    <form #workForm="ngForm" class="editForm" novalidate>
                        <div class="form-row" *ngIf="avaliation && avaliation.Itens && avaliation.Itens.length > 0" style=" display: flex;
                            align-items: baseline;">
                            <div class="col-12"><h4 class="head text-center mt-3">{{avaliation.name}}</h4></div>
                            <div class="col-12">
                                <div class="form-group mr-4">
                                    <label>{{'Criada em : '+formatDate(avaliation.createdAt)}}</label>
                                    <label class="ml-lg-4">{{'Personal : '+personalName}}</label>
                                    <label class="ml-lg-4">{{'Aluno : '+alunoName}}</label>
                                    <label class="ml-lg-4">{{'Respondida em : '+answeredAt}}</label>
                                </div>
                            </div>
                            <div  class="form-row mb-2" *ngIf="avaliation && avaliation.category!=9">
                                <div *ngFor="let avaliacao of avaliation.Itens" class="col-6 col-lg-3">
                                    <div class="form-group mb-2">
                                        <label for="pf_nome">{{avaliacao.question}}</label>
                                        <div class="input-group">
                                            <input type="text" *ngIf="avaliacao.question == 'Altura'" [mask]="'0.00'" id="{{avaliacao.question}}" class="form-control" name="{{avaliacao.question}}" [(ngModel)]="avaliacao.response"
                                            #inputNomeCompleto="ngModel" required (change)="getResult()" [disabled]="avaliation.answeredAt" [ngStyle]="{'border':avaliacao.question =='Meta de Gordura' ? '1px solid black' : 'default' }">
                                            <input type="text" *ngIf="avaliacao.question != 'Altura'" mask="separator.2" id="{{avaliacao.question}}" class="form-control" name="{{avaliacao.question}}" [(ngModel)]="avaliacao.response"
                                            #inputNomeCompleto="ngModel" required (change)="getResult()" [disabled]="avaliation.answeredAt" [ngStyle]="{'border':avaliacao.question =='Meta de Gordura' ? '1px solid black' : 'default' }">
                                            <div class="input-group-append" *ngIf="1==1">
                                                <span class="input-group-text" id="group-interval">{{avaliacao.measure}}</span>
                                            </div>
                                        </div>
                                        <!-- <small class="form-text text-muted danger" *ngIf="inputNomeCompleto.errors?.required">Preencha o campo</small> -->
                                    </div>
                                </div>
                            </div>
                            <div  class="form-row mb-2" *ngIf="avaliation && avaliation.category==9">
                                <small class="text-center mt-3">E = Esquerda / D = Direita</small>
                                <div class="col-12"><h4 class="head text-center mt-3">Membros Superiores(em cm)</h4></div>
                                <div *ngFor="let avaliacao of avaliation.Itens.slice(0,6)" class="col-6 col-lg-3">
                                    <div class="form-group mb-2">
                                        <label for="pf_nome">{{avaliacao.question}}</label>
                                        <div class="input-group">
                                            <input type="text" mask="separator.2" id="{{avaliacao.question+avaliacao.measure}}" class="form-control" placeholder="{{avaliacao.order % 2 === 0? 'Esquerda' : 'Direita'}}" name="{{avaliacao.question+avaliacao.measure}}" [(ngModel)]="avaliacao.response"
                                            #inputNomeCompleto="ngModel" required (change)="getResult()" [disabled]="avaliation.answeredAt">
                                            <div class="input-group-append" *ngIf="1==1">
                                                <span class="input-group-text" id="group-interval">{{avaliacao.measure}}</span>
                                            </div>
                                        </div>
                                        <small class="form-text text-muted danger" *ngIf="inputNomeCompleto.errors?.required">Preencha o campo</small>
                                    </div>
                                </div>
                                <div class="col-12"><h4 class="head text-center mt-3">Membros Inferiores(em cm)</h4></div>
                                <div *ngFor="let avaliacao of avaliation.Itens.slice(6,14)" class="col-6 col-lg-3">
                                    <div class="form-group mb-2">
                                        <label for="pf_nome">{{avaliacao.question}}</label>
                                        <div class="input-group">
                                            <input type="text" mask="separator.2" id="{{avaliacao.question+avaliacao.measure}}" class="form-control" placeholder="{{avaliacao.order % 2 === 0? 'Esquerda' : 'Direita'}}" name="{{avaliacao.question+avaliacao.measure}}" [(ngModel)]="avaliacao.response"
                                            #inputNomeCompleto="ngModel" required (change)="getResult()" [disabled]="avaliation.answeredAt">
                                            <div class="input-group-append" *ngIf="1==1">
                                                <span class="input-group-text" id="group-interval">{{avaliacao.measure}}</span>
                                            </div>
                                        </div>
                                        <small class="form-text text-muted danger" *ngIf="inputNomeCompleto.errors?.required">Preencha o campo</small>
                                    </div>
                                </div>
                                <div class="col-12"><h4 class="head text-center mt-3">Outros Membros(em cm)</h4></div>
                                <div *ngFor="let avaliacao of avaliation.Itens.slice(14,20)" class="col-6 col-lg-3">
                                    <div class="form-group mb-2">
                                        <label for="pf_nome">{{avaliacao.question}}</label>
                                        <div class="input-group">
                                            <input type="text" mask="separator.2" id="{{avaliacao.question}}" class="form-control" name="{{avaliacao.question}}" [(ngModel)]="avaliacao.response"
                                            #inputNomeCompleto="ngModel" required (change)="getResult()" [disabled]="avaliation.answeredAt">
                                            <div class="input-group-append" *ngIf="1==1">
                                                <span class="input-group-text" id="group-interval">{{avaliacao.measure}}</span>
                                            </div>
                                        </div>
                                        <small class="form-text text-muted danger" *ngIf="inputNomeCompleto.errors?.required">Preencha o campo</small>
                                    </div>
                                </div>
                                <div class="col-12"><h4 class="head text-center mt-3">Diâmetro Ósseo(em cm)</h4></div>
                                <div *ngFor="let avaliacao of avaliation.Itens.slice(20,23)" class="col-6 col-lg-3">
                                    <div class="form-group mb-2">
                                        <label for="pf_nome">{{avaliacao.question}}</label>
                                        <div class="input-group">
                                            <input type="text" mask="separator.2" id="{{avaliacao.question}}" class="form-control" name="{{avaliacao.question}}" [(ngModel)]="avaliacao.response"
                                            #inputNomeCompleto="ngModel" required (change)="getResult()" [disabled]="avaliation.answeredAt">
                                            <div class="input-group-append" *ngIf="1==1">
                                                <span class="input-group-text" id="group-interval">{{avaliacao.measure}}</span>
                                            </div>
                                        </div>
                                        <small class="form-text text-muted danger" *ngIf="inputNomeCompleto.errors?.required">Preencha o campo</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row" *ngIf="avaliation && avaliation.category !=1 && avaliation.category !=8 && avaliation.category !=9 && avaliationResults.length > 0">
                            <div class="col-12"><h4 class="head text-center mt-3">Resultado</h4></div>
                            <div *ngFor="let result of avaliationResults" class="col-6 col-lg-3">
                                <div class="form-group mb-2">
                                    <label for="pf_nome">{{result.name}}</label>
                                    <div class="input-group">
                                        <input type="text" *ngIf="result.name == 'Gordura Ideal' || result.name == 'IMC' || result.name == 'Gordura Atual'" id="{{result.name}}" class="form-control" name="{{result.name}}" [(ngModel)]="result.value"
                                        #inputNomeCompleto="ngModel" [disabled]="true" required>
                                        <input type="text" *ngIf="result.name != 'Gordura Ideal' && result.name != 'IMC' && result.name != 'Gordura Atual'" mask="separator.2" id="{{result.name}}" class="form-control" name="{{result.name}}" [(ngModel)]="result.value"
                                        #inputNomeCompleto="ngModel" [disabled]="true" required>
                                        <div class="input-group-append" *ngIf="1==1">
                                            <span class="input-group-text" id="group-interval">{{result.measure}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col-12 text-center">
                                <button *ngIf="avaliation && avaliation.answeredAt == null && avaliation.Itens && avaliation.Itens.length > 0" type="button" class="btn btn-lg btn-warning" [disabled]="!flagAvaliacaoDobrasCompleta" (click)="submitWebAvaliacao()">Finalizar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>