<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300"style="padding-bottom: 1.5rem;">Grupos de Alunos</h2>
    </div>
  </div>
  <div class="d-flex justify-content-between">
    <div class="col-sm-12">
      <button class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton" (click)="OpenModalXl(contentTutoriais)"><i
          class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
      </button>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col" >
    <div class=" float-right d-none d-md-block" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openGroup(null, grupoDetail)" >Adicionar Grupo</button>
    </div>
    <div class="btn-group btn-group-lg d-md-none" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openGroup(null, grupoDetail)" ><i class="fa fa-plus"></i></button>
    </div>
  </div>
</div>


<div class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <ul class="list-group">
          <li *ngFor="let group of groups | slice: (pageTr-1) * pageSize : (pageTr-1) * pageSize + pageSize" class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">{{group.group}}</h5>
              </div>
              <div class="btn-group btn-group-lg" role="group">
                <button type="button" class="btn btn-outline-warning" (click)="onDeleteGroup(group)"><i class="fa fa-trash"></i></button>
                <button type="button" class="btn btn-outline-warning" (click)="openGroup(group, grupoDetail)"><i class="fa fa-edit"></i></button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="groups.length>0">
  <div class="col">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        [(page)]="pageTr"
        [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
        [collectionSize]="groups.length"></ngb-pagination>
    </div>
  </div>
</div>
<div class="row" *ngIf="originalGroups.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <ul class="list-group p-0">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum grupo encontrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


<ng-template #grupoDetail let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Grupo</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br/>
              <div class="form-group mb-2">
                <label for="name">Nome do Grupo</label>
                <input type="text" id="name" class="form-control" name="name" [(ngModel)]="grupo.group" #inputName="ngModel" required>
              </div>
              <div class="form-group mb-2 scrollable-box-1">
                <label for="aluno">Alunos</label>
               <ng-select [appendTo]="'.scrollable-box-1'"  [notFoundText]="'Nenhum aluno encontrado'" [items]="relations"  bindLabel="Customer.name" name="model"  placeholder="Selecionar Alunos" id="aluno" [(ngModel)]="grupo.relations" multiple="true">
                <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                  <div *ngIf=" grupo.relations.length == 1" class="ng-values-list">
                    <div class="ng-value" *ngFor="let item of items | slice:0:1">
                      <span class="ng-value-label">{{item.Customer.name}}</span>
                      <span class="ng-value-icon right" (click)="clear(item)">×</span>
                    </div>
                  </div>
                  <div *ngIf="grupo.relations.length > 1" class="ng-summary-list">
                    <div class="ng-value">
                      <span class="ng-value-label">{{ grupo.relations.length }} Selecionados</span>
                    </div>
                  </div>
                </ng-template> 
                </ng-select>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block" [ngClass]="grupo && grupo.id ? 'btn-danger' : 'btn-secondary'" (click)="modal.close('Close click')">{{grupo && grupo.id ? 'Fechar' : 'Cancelar'}}</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="inserirOuAtualizarGrupo(modal)" [disabled]="!grupo.group || grupo.relations.length==0">{{grupo && grupo.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #contentVideo>
  <ng-template [ngIf]="urlToPlay.startsWith('https://www.youtube.com/') || urlToPlay.startsWith('http://www.youtube.com/')">
    <div class='embed-container'><iframe  [src]="safeURL" frameborder='0' allowfullscreen></iframe></div>
  </ng-template>
  <ng-template [ngIf]="!urlToPlay.startsWith('https://www.youtube.com/') && !urlToPlay.startsWith('http://www.youtube.com/')">
    <video controls style="width: 100%;">
      <source [src]="safeURL" type="video/mp4">
    </video>
  </ng-template>
</ng-template>

<ng-template #contentTutoriais let-modal>
  <!-- <div class="card"> -->
    <div class="card-header">
      <h4 class="card-title">Tutoriais</h4>
    </div>
    <button class="close-button" (click)="modal.close('Close click')"><i class="fa fa-times" aria-hidden="true"></i></button>
    <div class="form-row" style="margin: 5px">
      <ul class="list-group mb-2" style="width: 100%">
        <li class="list-group-item">
          <div class="media">
            <span class="dot align-middle mr-2">1</span>
            <div class="media-body">
              <h5 class="mt-0">Como criar grupos de alunos</h5>
              <span></span>
            </div>
            <div class="d-none d-md-block">
              <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=RXGrrkwXx-o', contentVideo )" >Vídeo</button>
            </div>
            <div class="d-md-none">
              <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=RXGrrkwXx-o', contentVideo )" >Vídeo</button>
            </div>
          </div>
        </li>
        <li class="list-group-item">
          <div class="media">
            <span class="dot align-middle mr-2">2</span>
            <div class="media-body">
              <h5 class="mt-0">Como gerenciar seus grupos</h5>
              <span></span>
            </div>
            <div class="d-none d-md-block">
              <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=Rmz-tbgRBaI', contentVideo )" >Vídeo</button>
            </div>
            <div class="d-md-none">
              <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=Rmz-tbgRBaI', contentVideo )" >Vídeo</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  <!-- </div> -->
</ng-template>
<ngx-spinner></ngx-spinner>
