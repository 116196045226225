<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem">
        {{ type && type == "2" ? "Desafios" : "Programas de Aula" }}
      </h2>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="d-flex justify-content-between">
    <div class="col-sm-12">
      <button
        class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton"
        (click)="OpenModalXl(contentTutoriais)"
      >
        <i class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
      </button>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col-5">
    <input
      type="text"
      id="volt"
      class="form-control"
      style="background-color: white; height: 100%"
      placeholder="Procurar programa"
      name="volt"
      [(ngModel)]="groupSearched"
      (keyup)="onSearchGroup()"
    />
  </div>
  <div class="col">
    <div class="float-right d-none d-md-block" role="group">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="openNewGroup(newGroupDetail)"
      >
        {{ type && type == "2" ? "Adicionar Desafio" : "Adicionar Programa" }}
      </button>
    </div>
    <div class="btn-group btn-group-lg d-md-none" role="group">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="openNewGroup(newGroupDetail)"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <ul class="list-group">
          <li
            *ngFor="
              let g of groups
                | slice
                  : (pageTr - 1) * pageSize
                  : (pageTr - 1) * pageSize + pageSize
            "
            class="list-group-item"
          >
            <div class="media">
              <img
                [src]="
                  g.photo
                    ? g.photo
                    : '../../assets/img/portrait/small/no-avatar.svg'
                "
                class="rounded-circle mr-2"
                alt="img-placeholder"
                height="50"
                width="50"
              />
              <div class="media-body">
                <h5>{{ g.title }}</h5>
              </div>
              <div class="btn-group btn-group-lg d-md-none" role="group">
                <button
                  type="button"
                  class="btn btn-outline-warning border-right-warning mb-2 mb-md-0"
                  (click)="goGroup(g)"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-warning border-right-warning mb-2 mb-md-0"
                  (click)="onDeletePrograma(g)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>

              <div
                class="btn-group btn-group-lg d-none d-md-block"
                role="group"
              >
                <button
                  type="button"
                  class="btn btn-outline-warning border-right-warning mb-2 mb-md-0"
                  (click)="goGroup(g)"
                >
                  Visualizar
                </button>
                <button
                  type="button"
                  class="btn btn-outline-warning border-right-warning mb-2 mb-md-0"
                  (click)="onDeletePrograma(g)"
                >
                  Deletar
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="groups.length > 0">
  <div class="col">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        [(page)]="pageTr"
        [pageSize]="pageSize"
        [maxSize]="2"
        [rotate]="true"
        [boundaryLinks]="true"
        size="sm"
        [collectionSize]="groups.length"
      ></ngb-pagination>
    </div>
  </div>
</div>
<div class="row" *ngIf="groups.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <ul class="list-group p-0">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">
                  {{
                    type && type == "2"
                      ? "Nenhum desafio cadastrado."
                      : "Nenhum programa cadastrado."
                  }}
                </h5>
                <span>{{
                  type && type == "2"
                    ? "Cadastre o seu primeiro desafio!"
                    : "Cadastre o seu primeiro programa de aula!"
                }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #contentVideo>
  <ng-template
    [ngIf]="
      urlToPlay.startsWith('https://www.youtube.com/') ||
      urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <div class="embed-container">
      <iframe [src]="safeURL" frameborder="0" allowfullscreen></iframe>
    </div>
  </ng-template>
  <ng-template
    [ngIf]="
      !urlToPlay.startsWith('https://www.youtube.com/') &&
      !urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <video controls style="width: 100%">
      <source [src]="safeURL" type="video/mp4" />
    </video>
  </ng-template>
</ng-template>

<ng-template #contentTutoriais let-modal>
  <div class="card-header">
    <h4 class="card-title">Tutoriais</h4>
  </div>
  <button class="close-button" (click)="modal.close('Close click')">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <div class="form-row" style="margin: 5px">
    <ul class="list-group mb-2" style="width: 100%">
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">1</span>
          <div class="media-body">
            <h5 class="mt-0">Como funcionam os desafios e programas</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=WGUL8i_EpFA',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=WGUL8i_EpFA',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">2</span>
          <div class="media-body">
            <h5 class="mt-0">Como criar um desafio ou programa</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=gcgJrP83s_g',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=gcgJrP83s_g',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">3</span>
          <div class="media-body">
            <h5 class="mt-0">Como inserir vídeos de abertura e encerramento</h5> 
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=I5LUM8cG-pI',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=I5LUM8cG',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">4</span>
          <div class="media-body">
            <h5 class="mt-0">Como convidar os alunos</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=Y1QcMKgc-hw',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=Y1QcMKgc',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">5</span>
          <div class="media-body">
            <h5 class="mt-0">Como subir um vídeo do youtube</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=rgLCtg22Vto',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=rgLCtg22Vto',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #newGroupDetail let-modal>
  <div class="card scrollable-box">
    <div class="card-header">
      <h4 class="card-title">
        {{ type && type == "2" ? "Novo Desafio" : "Novo Programa de Aulas" }}
      </h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-4">
                <label for="name">{{
                  type && type == "2" ? "Nome do desafio" : "Nome do programa"
                }}</label>
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  name="name"
                  [(ngModel)]="group.title"
                  #inputName="ngModel"
                  required
                />
              </div>
              <div class="form-group mb-2 scrollable-box-1">
                <label for="grupoSelecionado">Copiar Alunos de Grupo:</label>
                <ng-select
                  [appendTo]="'.scrollable-box-1'"
                  [notFoundText]="'Nenhum grupo encontrado'"
                  [items]="groupsAlunos"
                  bindLabel="group"
                  name="grupoSelecionado"
                  placeholder="Selecionar Grupo"
                  id="grupoSelecionado"
                  (change)="copiarAlunosGrupo()"
                  [(ngModel)]="grupoSelecionado"
                ></ng-select>
              </div>
              <div class="form-group mb-4 scrollable-box-2">
                <label for="aluno">Alunos</label>
                <ng-select
                  [appendTo]="'.scrollable-box-2'"
                  [notFoundText]="'Nenhum aluno encontrado'"
                  [items]="relations"
                  bindLabel="Customer.name"
                  name="model"
                  placeholder="Selecionar Alunos"
                  id="aluno"
                  [(ngModel)]="selectedRelations"
                  multiple="true"
                >
                  <ng-template
                    ng-multi-label-tmp
                    let-items="items"
                    let-clear="clear"
                  >
                    <div
                      *ngIf="selectedRelations.length == 1"
                      class="ng-values-list"
                    >
                      <div
                        class="ng-value"
                        *ngFor="let item of items | slice : 0 : 1"
                      >
                        <span class="ng-value-label">{{
                          item.Customer.name
                        }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                          >×</span
                        >
                      </div>
                    </div>
                    <div
                      *ngIf="selectedRelations.length > 1"
                      class="ng-summary-list"
                    >
                      <div class="ng-value">
                        <span class="ng-value-label"
                          >{{ selectedRelations.length }} Selecionados</span
                        >
                      </div>
                    </div>
                  </ng-template>

                  <ng-template ng-header-tmp>
                    <div>
                      <button class="btn btn-link" (click)="onSelectAll()">
                        Selecionar Todos
                      </button>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-row mb-4">
                <div class="col text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint"
                    (click)="selecionarCapa()"
                    style="width: 50%"
                    placement="top"
                    [ngbTooltip]="
                      type && type == '2'
                        ? 'Você pode inserir uma imagem de capa para seu desafio.'
                        : 'Você pode inserir uma imagem de capa para seu programa.'
                    "
                  >
                    Imagem de Capa
                  </button>
                </div>
              </div>
              <div class="form-row mb-4" *ngIf="capaSelecionada">
                <div class="col text-center">
                  <img
                    [src]="capaSelecionada"
                    style="
                      margin-left: auto;
                      margin-right: auto;
                      max-width: 500px;
                    "
                  />
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="form-group mb-2">
                  <label for="grupoSelecionado">Exigir aprovação manual?</label>
                  <ui-switch
                    checked
                    class="switchery"
                    placement="top"
                    ngbTooltip="Ative a exigência de aprovação manual."
                    (click)="verificaSwitch()"
                    name="needsApproval"
                    [(ngModel)]="group.needsApproval"
                  ></ui-switch>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-danger btn-block"
                    (click)="modal.close('Close click')"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="insertGroup(modal)"
                    [disabled]="!group || !group.title"
                  >
                    Cadastrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ngx-spinner></ngx-spinner>
