import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-warning-modal",
  templateUrl: "./warning-modal.component.html",
  styleUrls: ["./warning-modal.component.scss"],
})
export class WarningModalComponent {
  @Input() title: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() buttonLabel: string | undefined;
  @Input() callback: () => void = () => {};

  constructor(private activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.close();
  }

  submit() {
    this.callback();
    this.activeModal.close();
  }
}
