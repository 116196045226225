import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { CustomAdapter } from "app/shared/services/dateAdapter.service";
import { CustomDateParserFormatter } from "app/shared/services/dateParserFormatter.service";
import { saveAs } from "file-saver";
import * as moment from "moment";
import { DragulaService } from "ng2-dragula";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Api from "../../../helpers/api";
import App from "../../../helpers/app";
import Me from "../../../helpers/me";

@Component({
  selector: "app-mural",
  templateUrl: "./mural.component.html",
  styleUrls: ["./mural.component.scss"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class MuralComponent implements OnInit {
  apiCall = this.api.new().silent();
  pageTr = 1;
  pageSize = 10;
  murals = [];
  originalMurals = [];

  searchedMural = null;

  mural = {
    id: null,
    title: null,
    description: null,
    externalUrl: null,
    file: null,
    status: null,
    seller: null,
    agendamento: null,
    banco: false,
    vencimento: null,
    MuralSellerAddressee: [],
  };
  file = null;
  alunosSelecionadosParaMural = [];
  relations = [];
  programas = [];

  groups = [];

  grupoSelecionado;

  user = null;

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday());
  }

  constructor(
    private modalService: NgbModal,
    private dragulaService: DragulaService,
    private spinner: NgxSpinnerService,
    private _sanitizer: DomSanitizer,
    private me: Me,
    private app: App,
    private api: Api,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {
    this.me
      .get()
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
      });
  }

  lastFilterStatus = null;
  urlToPlay = null;
  safeURL = null;
  fileNameAndExt(str) {
    var file = str.split("/").pop();
    return [
      file.substr(0, file.lastIndexOf(".")),
      file.substr(file.lastIndexOf(".") + 1, file.length),
    ];
  }

  formatDate(date) {
    if (date) {
      return moment(date).format("DDMMYYYY");
    } else {
      return "";
    }
  }

  formatDateBrazil(date) {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }

  filterMuralsStatus(status) {
    this.searchedMural = null;

    if (status == null) {
      this.lastFilterStatus = null;

      this.onResetMurals();
    } else {
      this.lastFilterStatus = status;
      this.murals = this.originalMurals.filter((m) => m.status == status);
    }
  }
  resetAll() {
    const self = this;
    self.resetNewMural();
    self.murals = [];
    self.originalMurals = [];
    self.groups = [];
    self.searchedMural = null;
    self.grupoSelecionado = null;
    self.relations = [];
  }

  resetNewMural() {
    const self = this;
    self.file = null;
    self.mural = {
      id: null,
      title: null,
      description: null,
      externalUrl: null,
      file: null,
      status: null,
      seller: null,
      agendamento: null,
      banco: false,
      vencimento: null,
      MuralSellerAddressee: [],
    };
    self.alunosSelecionadosParaMural = [];
  }

  copiarAlunosGrupo() {
    if (!this.grupoSelecionado || !this.grupoSelecionado.CustomersGroupsUsers) {
      return;
    }
    for (let t of this.grupoSelecionado.CustomersGroupsUsers) {
      const found = this.relations.find((x) => x.id == t.relation);
      if (found) {
        let verify = null;
        if (
          this.alunosSelecionadosParaMural &&
          this.alunosSelecionadosParaMural.length > 0
        ) {
          this.alunosSelecionadosParaMural.find((x) => x.id == found.id);
        }
        if (!verify) {
          this.alunosSelecionadosParaMural.push(found);
        }
      }
    }
    this.alunosSelecionadosParaMural = [...this.alunosSelecionadosParaMural];
  }
  async ngOnInit() {
    const self = this;

    self.resetAll();
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.apiCall.get("sellercustomer").subscribe(async (data) => {
      if (data.return) this.relations = data.return.relations;
      if (this.relations.length > 0) {
        this.relations.unshift({
          Customer: {
            name: "Todos Alunos",
          },
        });
      }
    });
    const groups = await self.apiCall
      .get("groupcustomers")
      .pipe(take(1))
      .toPromise();
    if (groups.return) self.groups = groups.return;

    self.apiCall.get("mural").subscribe(async (cat) => {
      self.murals = cat.return;
      self.originalMurals = cat.return;
      self.spinner.hide();
    });
  }
  downloadFile(url) {
    saveAs(url);
  }
  onSearchMural() {
    if (this.searchedMural.length > 0) {
      this.murals = this.originalMurals.filter((e) =>
        e.title
          .toString()
          .toLowerCase()
          .trim()
          .includes(this.searchedMural.toString().toLowerCase().trim())
      );
    } else {
      this.onResetMurals();
    }
  }
  onResetMurals() {
    this.murals = this.originalMurals;
  }

  switchMural(event, mural) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (mural && mural.id) {
      mural.status = event;
      self.apiCall
        .put("mural", {
          mural: mural,
        })
        .subscribe(async (data) => {
          await self.spinner.hide();
          await self.ngOnInit();
          if (data.success) {
            await this.app.alert(
              "Tudo certo!",
              event
                ? "Mural ativado com sucesso"
                : "Mural arquivado com sucesso",
              "success"
            );
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }

  linkInvalido = false;
  async inserirOuAtualizarMural(modal) {
    if (!this.user || !this.user.id) {
      return;
    }
    if (
      this.mural &&
      this.mural.externalUrl &&
      !this.mural.externalUrl.startsWith("http://") &&
      !this.mural.externalUrl.startsWith("https://")
    ) {
      this.linkInvalido = true;
      return;
    } else {
      this.linkInvalido = false;
    }

    let listTosend = [];

    if (!this.mural.banco) {
      if (
        this.alunosSelecionadosParaMural &&
        this.alunosSelecionadosParaMural.find(
          (x) =>
            x.Customer &&
            x.Customer.name &&
            x.Customer.name.includes("Todos Alunos")
        )
      ) {
        listTosend = [];
      } else {
        if (
          this.alunosSelecionadosParaMural &&
          this.alunosSelecionadosParaMural.length > 0
        ) {
          for (let r of this.alunosSelecionadosParaMural) {
            if (r.id) {
              listTosend.push(r);
            }
          }
        }
      }
    } else {
      listTosend = [];
    }

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    let chamada;

    if (this.file && this.fileNameAndExt(this.file.name)[1]) {
      const urlToPostFile = await this.postFile(
        this.fileNameAndExt(this.file.name)[1],
        this.file.type
      );
      if (!urlToPostFile.return) {
        return;
      }
      var optionsFile = {
        headers: { "Content-Type": this.file.type, "x-amz-acl": "public-read" },
      };

      const uploadFile = await this.api.http
        .put(urlToPostFile.return, this.file, optionsFile)
        .toPromise();
      const locationFile = urlToPostFile.return.toString().split("?", 1)[0];
      this.mural.file = locationFile;
    }

    const body = {
      mural: {
        id: this.mural.id ? this.mural.id : null,
        title: this.mural.title,
        description: this.mural.description,
        externalUrl: this.mural.externalUrl,
        banco: this.mural.banco,
        file: this.mural.file,
        seller: this.user.id,
        agendamento: this.mural.agendamento
          ? moment(this.mural.agendamento, "DD/MM/YYYY").toDate()
          : this.mural.agendamento,
        vencimento: this.mural.vencimento
          ? moment(this.mural.vencimento, "DD/MM/YYYY").toDate()
          : this.mural.vencimento,
        MuralSellerAddressee: listTosend,
      },
    };

    if (this.mural.id) {
      chamada = this.apiCall.put("mural", body);
    } else {
      chamada = this.apiCall.post("mural", body);
    }

    chamada.subscribe(async (data) => {
      if (data.success) {
        this.spinner.hide();
        await this.app.alert(
          "Tudo certo!",
          this.mural.id
            ? "Seu mural foi atualizado com sucesso"
            : "Seu mural foi cadastrado com sucesso",
          "success"
        );
        await this.ngOnInit();

        modal.close("Close click");
      } else {
        await this.app.alert("Ops :(", data.message, "error");
        this.spinner.hide();
      }
    });
  }
  changeSwitchBanco(event) {
    if (event) {
      this.mural.banco = true;
    } else {
      this.mural.banco = false;
    }
  }
  isImage(file) {
    if (
      file.toString().includes("jpg") ||
      file.toString().includes("jpeg") ||
      file.toString().includes(".png")
    ) {
      return true;
    } else {
      return false;
    }
  }

  async attachFile() {
    let { value: file } = await Swal.fire({
      title: "Arquivo",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        "aria-label": "Anexe um arquivo.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher um arquivo!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos arquivos de até 30MB!";
        }

        this.file = file;
        const base64 = await this.fileToBase64(file);
        if (base64) this.mural.file = base64;
      },
    });
  }

  removeFile() {
    this.mural.file = null;
  }

  deletarMural(mural) {
    const self = this;
    if (!mural || !mural.id) {
      return;
    }
    self.app
      .confirm("Tem certeza?", "Você realmente deseja deletar este mural?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall.delete("mural/" + mural.id).subscribe(async (data) => {
            self.spinner.hide();
            if (data.success) {
              self.ngOnInit();
              await this.app.alert(
                "Tudo certo!",
                "Mural deletado com sucesso",
                "success"
              );
            } else {
              await this.app.alert("Ops :(", data.message, "error");
            }
          });
        }
      });
  }
  duplicarMural(mural) {
    const self = this;
    self.app
      .confirm("Confirmação", "Você deseja duplicar este mural?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .post("mural/duplicate", { mural: mural })
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Mural duplicado com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }

  openMural(mural, template) {
    this.resetNewMural();
    if (mural) {
      this.mural = {
        ...mural,
        vencimento: mural.vencimento
          ? moment(mural.vencimento).format("DD/MM/YYYY")
          : null,
        agendamento: mural.agendamento
          ? moment(mural.agendamento).format("DD/MM/YYYY")
          : null,
      };
      if (this.mural.agendamento) {
        const agendamentoSplited = this.mural.agendamento?.split("/");

        if (agendamentoSplited) {
          this.mural.agendamento = this.dateAdapter.toModel({
            day: Number(agendamentoSplited[0]),
            month: Number(agendamentoSplited[1]),
            year: Number(agendamentoSplited[2]),
          });
        }
      }

      if (this.mural.vencimento) {
        const vencimentoSplited = this.mural.vencimento?.split("/");

        if (vencimentoSplited) {
          this.mural.vencimento = this.dateAdapter.toModel({
            day: Number(vencimentoSplited[0]),
            month: Number(vencimentoSplited[1]),
            year: Number(vencimentoSplited[2]),
          });
        }
      }

      if (mural.MuralSellerAddressee && mural.MuralSellerAddressee.length > 0) {
        for (let m of mural.MuralSellerAddressee) {
          if (m.addressee) {
            let relationFound = this.relations.find((r) => r.id == m.addressee);
            if (relationFound)
              this.alunosSelecionadosParaMural.push(relationFound);
          }
        }
      } else {
        let relationFound = this.relations.find(
          (x) =>
            x.Customer &&
            x.Customer.name &&
            x.Customer.name.includes("Todos Alunos")
        );
        if (relationFound) this.alunosSelecionadosParaMural.push(relationFound);
      }
    } else {
      this.mural.agendamento = this.today;
    }
    this.modalService.open(template);
  }

  async postFile(extension, type) {
    return await new Promise<any>((res, rej) => {
      this.apiCall
        .post("auxiliar/file", { extension: extension, type: type })
        .subscribe(async (response) => {
          res(response);
        });
    });
  }

  async fileToBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  viewMidia(urlToPlay, content) {
    this.urlToPlay = urlToPlay;
    if (urlToPlay.startsWith("https://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "https://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    } else if (urlToPlay.startsWith("http://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "http://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    }
    this.modalService.open(content, { centered: true, size: "lg" });
  }
}
