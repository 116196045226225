<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem">
        Funil de Recuperação
      </h1>
    </div>
  </div>
</div>

<div
  class="row mb-2 mt-2"
  *ngIf="recoveryContacts && recoveryContacts.length > 0"
>
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <div class="table text-center m-0">
          <table class="table-striped table-responsive" style="width: 100%">
            <thead>
              <tr>
                <th>
                  <input
                    type="text"
                    id="email"
                    class="form-control"
                    style="background-color: white; height: 100%"
                    placeholder="Email"
                    name="email"
                    [(ngModel)]="filterEmail"
                    (keyup)="filter()"
                  />
                </th>
                <th><ng-select
                  [items]="funil"
                  name="funil"
                  bindLabel="name"
                  placeholder="Funil"
                  id="funil"
                  (change)="filter()"
                  [(ngModel)]="filterTipoFunil"
                ></ng-select></th>
                <th>Vencimento</th>
                <th>Mensagem Atual</th>
                <th>Último Envio</th>
                <th><ng-select
                  [items]="statusContato"
                  name="statusContato"
                  bindLabel="name"
                  placeholder="Status Contato"
                  id="statusContato"
                  (change)="filter()"
                  [(ngModel)]="filterStatusContato"
                ></ng-select></th>
                <th>Contato</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let s of recoveryContactsFiltered
                    | slice
                      : (page - 1) * pageSize
                      : (page - 1) * pageSize + pageSize
                "
              >
                <td>{{ s.User.email }}</td>
                <td>{{ s.RecoveryContactFunnel.name }}</td>
                <td>{{ formatDate(s.dueDate) }}</td>
                <td>{{ s.step }}</td>
                <td>{{ formatDate(s.lastContact) }}</td>
                <td [innerHTML]="getBadge(s.recoveryStatus)"></td>
                <td>
                  <button
                    type="button"
                    class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                    *ngIf="s.User.phone"
                    ngbTooltip="Whatsapp"
                    (click)="openWhatsapp(s.User.phone)"
                  >
                    <i class="fa fa-whatsapp"></i>
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                    *ngIf="s.User.phone"
                    ngbTooltip="Notificações Inteligentes"
                    (click)="openNotificacoesInteligentes(s.User.phone)"
                  >
                    <i class="fa fa-telegram"></i>
                  </button>
                </td>
                <td>
                  <div
                    class="dropdown btn-group-sm mr-1 mb-1"
                    ngbDropdown
                    container="body"
                  >
                    <button
                      type="button"
                      class="btn btn-outline-warning btn-sm dropdown-toggle"
                      ngbDropdownToggle
                      style="white-space: nowrap"
                    >
                      <i class="ft-more-vertical"></i>
                    </button>

                    <div
                      ngbDropdownMenu
                      class="dropdown-menu"
                      role="menu"
                      style="z-index: 10000000"
                    >
                      <a
                        class="dropdown-item"
                        (click)="openAtualizaContato(s, ContactDetails)"
                        >Atualizar Contato</a
                      >
                      <a
                        class="dropdown-item"
                        [href]="
                          'https://alia.iugu.com/receive/billing/' +
                          s.subscriptionId
                        "
                        target="_blank"
                        >Ver Assinatura</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-row" style="margin: 5px">
        <div
          class="d-flex justify-content-center"
          style="margin-left: auto; margin-right: auto"
        >
          <ngb-pagination
            style="margin-top: 10px"
            *ngIf="recoveryContactsFiltered.length > 0"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="2"
            [rotate]="true"
            [boundaryLinks]="true"
            size="sm"
            [collectionSize]="recoveryContactsFiltered.length"
          >
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="recoveryContactsFiltered.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum usuário no funil.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #ContactDetails>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <label for="diasGratuitosI">Status Contato</label>
              <ng-select
                [items]="statusContato"
                name="statusAtual"
                bindLabel="name"
                placeholder="Status do Contato"
                id="statusAtual"
                [(ngModel)]="statusAtual"
              ></ng-select>
            </div>
          </div>
        </div>
        <div class="form-row mb-2" *ngIf="statusAtual && statusAtual.id == 2">
          <div class="col-12">
            <div class="form-group">
              <label for="diasGratuitosI">Gerou Churn?</label>
              <ui-switch
                class="switchery switchery-warning"
                id="ttt"
                [checked]="isChurn"
                (change)="isChurn = !isChurn"
              ></ui-switch>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-12" *ngIf="isChurn">
            <div class="form-group">
              <label for="diasGratuitosI">Motivo do Churn</label>
              <ng-select
                [items]="churnReasonList"
                name="churnReason"
                placeholder="Motivo do Churn"
                id="statusAtual"
                [(ngModel)]="churnReason"
              ></ng-select>
            </div>
          </div>
        </div>
        <div class="form-row mb-2" *ngIf="statusAtual && statusAtual.id == 2">
          <div class="col-12">
            <div class="form-group">
              <label for="diasGratuitosI">Detalhe Contato</label>
              <textarea
                id="detalheContato"
                rows="2"
                class="form-control"
                name="detalheContato"
                maxlength="255"
                [(ngModel)]="detalheContato"
                #inputObse="ngModel"
                placeholder="Informe o retorno do cliente."
              ></textarea>
            </div>
          </div>
        </div>

        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-lg btn-warning btn-block mb-md-0"
                [disabled]="
                  (statusAtual && statusAtual.id == '2' && !detalheContato) ||
                  !statusAtual
                "
                (click)="updateRecovery()"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
