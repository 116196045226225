import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import Api from "app/helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";

type RouteType = "reports" | "subscriptions" | "account";
@Component({
  selector: "app-finance-main",
  templateUrl: "./finance-main.component.html",
  styleUrls: ["./finance-main.component.scss"],
})
export class FinanceMainComponent implements OnInit {
  route: string = "reports";
  activeRoute: RouteType = "reports";
  apiCall = this.api.new().silent();
  account: boolean = false;

  constructor(
    private router: Router,
    private api: Api,
    private spinner: NgxSpinnerService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let paths = event.url.split("/");
        let route = paths[paths.length - 1];

        if (route.includes("?")) {
          const newRoute = route.split("?")[0];
          route = newRoute;
        }

        this.activeRoute = route as RouteType;
      }
    });
  }

  async ngOnInit() {
    return;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const subaccount = await this.apiCall
      .get("seller/me/marketplaceaccountlocal")
      .pipe(take(1))
      .toPromise();
    if (!subaccount.return) {
      this.account = true;
      this.spinner.hide();
    } else {
      this.account = false;
    }
  }
}
