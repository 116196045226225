<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem">
        Histórico de Assinaturas
      </h1>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="float-right d-none d-md-block" role="group">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="uploadSimplificado()"
      >
        Importar
      </button>
    </div>
  </div>
</div>

<div class="row mb-2 mt-2" *ngIf="cupons.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <div class="table text-center m-0">
          <table class="table-striped" style="width: 100%">
            <thead>
              <tr>
                <th>Código da Assinatura</th>
                <th>Código do Cliente</th>
                <th>Cliente</th>
                <th>Identificador do Plano</th>
                <th>Situação</th>
                <th>Início</th>
                <th>Vencimento</th>
                <th>Valor do Plano</th>

                <!-- <th>Ações</th> -->
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let c of cupons
                    | slice
                      : (page - 1) * pageSize
                      : (page - 1) * pageSize + pageSize
                "
              >
                <td>{{ c.coupon }}</td>
                <td>{{ c.description }}</td>
                <!-- <td><textarea class="form-control" name="rrr" [disabled]="true" style="background-color: #e9ecef36" [(ngModel)]="c.description"></textarea></td> -->
                <td>{{ c.priceFirstMonth | currency : "BRL" }}</td>
                <td>{{ c.percentageFirstMonth }}</td>
                <td>{{ c.status ? "Ativo" : "Inativo" }}</td>
                <td>{{ c.status ? "Ativo" : "Inativo" }}</td>
                <td>{{ c.status ? "Ativo" : "Inativo" }}</td>
                <td>{{ c.status ? "Ativo" : "Inativo" }}</td>

                <!-- <td>
                <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                  <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                    <i class="ft-more-vertical"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a  class="dropdown-item" (click)="openCupom(c, cupomDetail)" >Editar</a>
                    <a  class="dropdown-item" (click)="switchCupom(c)">{{c.status  ? 'Inativar' : 'Ativar'}}</a>
                    <a  class="dropdown-item" (click)="onDeleteCupom(c)">Deletar</a>
                  </div>
                </div>
              </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-row" style="margin: 5px">
        <div
          class="d-flex justify-content-center"
          style="margin-left: auto; margin-right: auto"
        >
          <ngb-pagination
            style="margin-top: 10px"
            *ngIf="cupons.length > 0"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="2"
            [rotate]="true"
            [boundaryLinks]="true"
            size="sm"
            [collectionSize]="cupons.length"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="cupons.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum histórico disponível.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>

<ng-template #cupomDetail let-modal>
  <ngx-spinner></ngx-spinner>

  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Importação</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <input type="file" (change)="uploadSimplificado()" />
                <!-- <button (click)="uploadCSV()">Upload</button> -->
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block"
                    [ngClass]="'btn-danger'"
                    (click)="modal.close('Close click')"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="uploadCSV()"
                    [disabled]=""
                  >
                    Upload
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
