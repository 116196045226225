import { Injectable } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Injectable({
  providedIn: 'root'
})
export class ImageCropperService {

  constructor() { }

  handleImageCropped(event: ImageCroppedEvent): string {
    return event.base64;
  }
}

