import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../shared/auth/auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import {SocialAuthService} from "angularx-social-login";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-admin-opd-academy-categorias',
  templateUrl: './admin-opd-academy-categorias.component.html',
  styleUrls: ['./admin-opd-academy-categorias.component.scss']
})
export class AdminOpdAcademyCategoriasComponent implements OnInit {
  apiCall = this.api.new().silent();
  categorias = []
  page = 1
  pageSize = 10
  categoria = {
    id : null,
    name : null,
    status : null,
  }
  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private me: Me,
              private socialAuthService: SocialAuthService,
              private app: App,
              private api: Api) {
  }
  async ngOnInit() {
    const self = this

    self.resetCategoria()
    self.categorias = []

    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const data = await self.apiCall.get('me/opdacademycategory').pipe(take(1)).toPromise()
    if(data.return) self.categorias = data.return
    await self.spinner.hide()
  }
  inserirOuAtualizarCategoria(modal){

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    let chamada
    let body = {
      Categorias : this.categoria
    }
    if(this.categoria.id){
      chamada = this.apiCall.put('me/opdacademycategory', body)

    }else{
      chamada = this.apiCall.post('me/opdacademycategory', body)

    }
    chamada.subscribe(async data => {
      if(data.success){
        this.spinner.hide()
        await this.app.alert('Tudo certo!', this.categoria.id ? 'Categoria atualizada com sucesso' : 'Categoria cadastrada com sucesso', 'success')
        modal.close('Close click')
        this.ngOnInit()
      }else{
        await this.app.alert('Ops :(', data.message, 'error')
        this.spinner.hide()
      }
    });
  }

  onDeleteCategoria(categoria){
    const self = this
    self.app.confirm('Tem certeza?', 'Você realmente deseja deletar esta categoria?').then(d=>{
      if(d){
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        self.apiCall.delete('me/opdacademycategory/'+categoria.id).subscribe(async data => {
          self.spinner.hide()
          if(data.success){
            self.ngOnInit()
            await this.app.alert('Tudo certo!',  'Categoria deletada com sucesso', 'success')
          }else{
            await this.app.alert('Ops :(', data.message, 'error')
          }
        });
      }
    })
  }

  openCategoria(categoria, modal){
    this.resetCategoria()
    if(categoria){
      this.categoria = categoria
    }
    const modalRef = this.modalService.open(modal);
  }
  switchCategoria(categoria){
    const self = this
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if(categoria && categoria.id){
      categoria.status = !categoria.status
      self.apiCall.put('me/opdacademycategory', {
        Categorias : categoria
      }).subscribe(async data => {
        await self.spinner.hide()
        await self.ngOnInit()
        if(data.success){
          await this.app.alert('Tudo certo!',  categoria.status? 'Categoria ativada com sucesso' : 'Categoria inativada com sucesso', 'success')
        }else{
          await this.app.alert('Ops :(', data.message, 'error')
        }
      });
    }
  }
  resetCategoria(){
    this.categoria = {
      id : null,
      name : null,
      status : null,
    }
  }

}
