import { Injectable, OnDestroy } from "@angular/core";
import { TableExpansionServiceInterface } from "./table-expansio.types";

@Injectable()
export class TableExpansionService
  implements TableExpansionServiceInterface, OnDestroy
{
  public expanded = new Set<number | string>();

  constructor() {}

  public toggleRowExpansion(id: number | string) {
    if (this.expanded.has(id)) {
      this.expanded.delete(id);
    } else {
      this.expanded.add(id);
    }
  }

  public expandAndFocus(id: number, elementId: string) {
    if (!this.expanded.has(id)) {
      this.expanded.add(id);
    }
    const element = document.getElementById(elementId);

    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        setTimeout(function () {
          element.focus();
        }, 200);
      }, 300);
    }
  }

  public isRowExpanded(id: number | string) {
    return !!this.expanded.has(id);
  }

  public reset() {
    this.expanded.clear();
  }

  ngOnDestroy() {
    this.reset();
  }
}
