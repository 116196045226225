<div class="modal-header">
    <h4 class="modal-title">Escolha cartão de cobrança:</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <select class="form-control" [(ngModel)]="selectedCard">
      <option *ngFor="let card of cards" [value]="card.id">{{ card.display_number }}</option>
    </select>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss('cancel click')">Cancelar</button>
    <button type="button" class="btn btn-primary" (click)="confirmarCobranca()">Cobrar</button>
  </div>
  