<div class="row text-left">
    <div class="col-sm-12">
      <div class="d-flex justify-content-between mb-0">
        <h1 class="text-bold-300" style="padding: 1.5rem;">{{pageTitle}}</h1>
      </div>
    </div>
   
  </div>




 <!--Pills Starts-->
 <div class="col-md-12 col-lg-12">
    <div class="card">
  
     
      
      <div class="card-content">
        <div class="card-body">
          
          
  
          <ngb-tabset type="pills">
            <ngb-tab>
              <ng-template ngbTabTitle>
                <div (click)="updateTitle(1)"> <i class="ft-list font-medium-5"></i></div>
            </ng-template>
              <ng-template ngbTabContent>
                <div class="row mb-2 mt-2" *ngIf="listaSemAvaliacao.length > 0">
                  <div class="col-12 col-lg-6">
                  <p>{{listaSemAvaliacao.length}} Registros encontrados</p>  
                  </div>
                <div class="col-12 col-lg-6">
                  <div class="float-right d-none d-md-block mb-2">
                    <button type="button" class="btn btn-success" (click)="exportarListaUsuarios(1)"><i class="fa fa-file-archive-o"></i> Baixar Lista</button>
                  </div>
                </div></div>
                
  
                <div class="row mb-2 mt-2" *ngIf="listaSemAvaliacao.length > 0">
                  <div class="col">
                    <div class="card-content">
                      
                
                      <div class="card-body p-0">
                        <div class="table text-center m-0">
                          <table class="table-striped table-responsive" style="width: 100%;">
                            <thead>
                            <tr>                           
                              <th><input type="text" id="name"  class="form-control" style="background-color: white; height: 100%" placeholder="Nome" name="name" [(ngModel)]="filterName" (keyup)="filter()" ></th>
                              <th><input type="text" id="email"  class="form-control" style="background-color: white; height: 100%" placeholder="Email" name="email" [(ngModel)]="filterEmail" (keyup)="filter()" ></th>
                              <th>Data Cadastro</th>  
                              <th>
                                <ng-select [items]="personais"   name="personal" bindLabel="name"  placeholder="Personal" id="personal" (change)="filter()" [(ngModel)]="filterPersonal"></ng-select>
                              </th>
                                                            
                              <th>Fim Teste</th>
                              <th>Mensagem</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let lead of listaSemAvaliacaoFiltered| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                              <td>{{lead.Customer.name}}</td>
                              <td>{{lead.Customer.email}}</td>
                              <td>{{lead.Customer.createdAt ? formatDateWithoutTime(lead.Customer.createdAt) : '-----'}}</td>
                              <td>{{lead.Seller.name}}</td>
                              <td>{{lead.expirationDate ? formatDateWithoutTime(lead.expirationDate) : '-----' }}</td>
                              <td><button type="button" class="btn btn-outline-warning border-left-warning mb-2 mb-md-0" *ngIf="lead.Customer.phone" ngbTooltip="Whatsapp" (click)="sendNotification(lead.Customer, '26141')"><i class="fa fa-telegram"></i></button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                
                      </div>
                      <div class="form-row" style="margin: 5px">
                        <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
                          <ngb-pagination style="margin-top: 10px" *ngIf="listaSemAvaliacaoFiltered.length>0"
                                          [(page)]="page"
                                          [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
                                          [collectionSize]="listaSemAvaliacaoFiltered.length"></ngb-pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="listaSemAvaliacaoFiltered.length == 0">
                  <div class="col">
                    <div class="card-content">
                      <div class="card-body p-0 mt-2">
                        <ul class="list-group">
                          <li  class="list-group-item">
                            <div class="media">
                              <div class="media-body">
                                <h5 class="mt-0">Nenhum Lead Sem Avaliação Respondida </h5>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              
            </ng-template>
            </ngb-tab>


            <ngb-tab>
                <ng-template ngbTabTitle>
                  <div (click)="updateTitle(2)"> <i class="ft-clock font-medium-5"></i></div>
              </ng-template>
                <ng-template ngbTabContent>
                  <div class="row mb-2 mt-2" *ngIf="listaSemTreino.length > 0">
                    <div class="col-12 col-lg-6">
                    <p>{{listaSemTreino.length}} Registros encontrados</p>  
                    </div>
                  <div class="col-12 col-lg-6">
                    <div class="float-right d-none d-md-block mb-2">
                      <button type="button" class="btn btn-success" (click)="exportarListaUsuarios(2)"><i class="fa fa-file-archive-o"></i> Baixar Lista</button>
                    </div>
                  </div></div>
                  
    
                  <div class="row mb-2 mt-2" *ngIf="listaSemTreino.length > 0">
                    <div class="col">
                      <div class="card-content">
                        
                  
                        <div class="card-body p-0">
                          <div class="table text-center m-0">
                            <table class="table-striped table-responsive" style="width: 100%;">
                              <thead>
                              <tr>                           
                                <th><input type="text" id="name"  class="form-control" style="background-color: white; height: 100%" placeholder="Nome" name="name" [(ngModel)]="filterName" (keyup)="filter()" ></th>
                                <th><input type="text" id="email"  class="form-control" style="background-color: white; height: 100%" placeholder="Email" name="email" [(ngModel)]="filterEmail" (keyup)="filter()" ></th>
                                <th>Data Cadastro</th>  
                                <th>
                                  <ng-select [items]="personais"   name="personal" bindLabel="name"  placeholder="Personal" id="personal" (change)="filter()" [(ngModel)]="filterPersonal"></ng-select>
                                </th>
                                <th>Resp. Aval</th>              
                                <th>Fim Teste</th>
                                <th>Mensagem</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let lead of listaSemTreinoFiltered| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                <td>{{lead.Customer.name}}</td>
                                <td>{{lead.Customer.email}}</td>
                                <td>{{lead.Customer.createdAt ? formatDateWithoutTime(lead.Customer.createdAt) : '-----'}}</td>
                                <td>{{lead.Seller.name}}</td>
                                <td>{{lead.dataAvaliacao ? formatDateWithoutTime(lead.dataAvaliacao) : '-----'}}</td>
                                <td>{{lead.expirationDate ? formatDateWithoutTime(lead.expirationDate) : '-----' }}</td>
                                <td><button type="button" class="btn btn-outline-warning border-left-warning mb-2 mb-md-0" *ngIf="lead.Customer.phone" ngbTooltip="Whatsapp" (click)="sendNotification(lead.Customer, '26146')"><i class="fa fa-telegram"></i></button>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                  
                        </div>
                        <div class="form-row" style="margin: 5px">
                          <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
                            <ngb-pagination style="margin-top: 10px" *ngIf="listaSemAvaliacaoFiltered.length>0"
                                            [(page)]="page"
                                            [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
                                            [collectionSize]="listaSemAvaliacaoFiltered.length"></ngb-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="listaSemAvaliacaoFiltered.length == 0">
                    <div class="col">
                      <div class="card-content">
                        <div class="card-body p-0 mt-2">
                          <ul class="list-group">
                            <li  class="list-group-item">
                              <div class="media">
                                <div class="media-body">
                                  <h5 class="mt-0">Nenhum Lead Sem Treinos </h5>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </ng-template>
              </ngb-tab>
  

              <ngb-tab>
                <ng-template ngbTabTitle>
                  <div (click)="updateTitle(3)"> <i class="ft-check-circle font-medium-5"></i></div>
              </ng-template>
                <ng-template ngbTabContent>
                  <div class="row mb-2 mt-2" *ngIf="listaExecucaoTreino.length > 0">
                    <div class="col-12 col-lg-6">
                    <p>{{listaExecucaoTreino.length}} Registros encontrados</p>  
                    </div>
                  <div class="col-12 col-lg-6">
                    <div class="float-right d-none d-md-block mb-2">
                      <button type="button" class="btn btn-success" (click)="exportarListaUsuarios(3)"><i class="fa fa-file-archive-o"></i> Baixar Lista</button>
                    </div>
                  </div></div>
                  
    
                  <div class="row mb-2 mt-2" *ngIf="listaExecucaoTreino.length > 0">
                    <div class="col">
                      <div class="card-content">
                        
                  
                        <div class="card-body p-0">
                          <div class="table text-center m-0">
                            <table class="table-striped table-responsive" style="width: 100%;">
                              <thead>
                              <tr>                           
                                <th><input type="text" id="name"  class="form-control" style="background-color: white; height: 100%" placeholder="Nome" name="name" [(ngModel)]="filterName" (keyup)="filter()" ></th>
                                <th><input type="text" id="email"  class="form-control" style="background-color: white; height: 100%" placeholder="Email" name="email" [(ngModel)]="filterEmail" (keyup)="filter()" ></th>
                                <th>Data Cadastro</th>  
                                <th>
                                  <ng-select [items]="personais"   name="personal" bindLabel="name"  placeholder="Personal" id="personal" (change)="filter()" [(ngModel)]="filterPersonal"></ng-select>
                                </th>
                                <th>Primeiro Treino</th>              
                                <th>Fim Teste</th>
                                <th>Mensagem</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let lead of listaExecucaoTreinoFiltered| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                <td>{{lead.Customer.name}}</td>
                                <td>{{lead.Customer.email}}</td>
                                <td>{{lead.Customer.createdAt ? formatDateWithoutTime(lead.Customer.createdAt) : '-----'}}</td>
                                <td>{{lead.Seller.name}}</td>
                                <td>{{lead.dataTreino ? formatDateWithoutTime(lead.dataTreino) : '-----'}}</td>
                                <td>{{lead.expirationDate ? formatDateWithoutTime(lead.expirationDate) : '-----' }}</td>
                                <td><button type="button" class="btn btn-outline-warning border-left-warning mb-2 mb-md-0" *ngIf="lead.Customer.phone" ngbTooltip="Whatsapp" (click)="sendNotification(lead.Customer, '26147')"><i class="fa fa-telegram"></i></button>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                  
                        </div>
                        <div class="form-row" style="margin: 5px">
                          <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
                            <ngb-pagination style="margin-top: 10px" *ngIf="listaExecucaoTreinoFiltered.length>0"
                                            [(page)]="page"
                                            [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
                                            [collectionSize]="listaExecucaoTreinoFiltered.length"></ngb-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="listaExecucaoTreinoFiltered.length == 0">
                    <div class="col">
                      <div class="card-content">
                        <div class="card-body p-0 mt-2">
                          <ul class="list-group">
                            <li  class="list-group-item">
                              <div class="media">
                                <div class="media-body">
                                  <h5 class="mt-0">Nenhum Lead Com Execução do Primeiro Treino </h5>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </ng-template>
              </ngb-tab>



              <ngb-tab>
                <ng-template ngbTabTitle>
                  <div (click)="updateTitle(4)"> <i class="ft-stop-circle font-medium-5"></i></div>
              </ng-template>
                <ng-template ngbTabContent>
                  <div class="row mb-2 mt-2" *ngIf="listaFimTeste.length > 0">
                    <div class="col-12 col-lg-6">
                    <p>{{listaFimTeste.length}} Registros encontrados</p>  
                    </div>
                  <div class="col-12 col-lg-6">
                    <div class="float-right d-none d-md-block mb-2">
                      <button type="button" class="btn btn-success" (click)="exportarListaUsuarios(4)"><i class="fa fa-file-archive-o"></i> Baixar Lista</button>
                    </div>
                  </div></div>
                  
    
                  <div class="row mb-2 mt-2" *ngIf="listaFimTeste.length > 0">
                    <div class="col">
                      <div class="card-content">
                        
                  
                        <div class="card-body p-0">
                          <div class="table text-center m-0">
                            <table class="table-striped table-responsive" style="width: 100%;">
                              <thead>
                              <tr>                           
                                <th><input type="text" id="name"  class="form-control" style="background-color: white; height: 100%" placeholder="Nome" name="name" [(ngModel)]="filterName" (keyup)="filter()" ></th>
                                <th><input type="text" id="email"  class="form-control" style="background-color: white; height: 100%" placeholder="Email" name="email" [(ngModel)]="filterEmail" (keyup)="filter()" ></th>
                                <th>Data Cadastro</th>  
                                <th>
                                  <ng-select [items]="personais"   name="personal" bindLabel="name"  placeholder="Personal" id="personal" (change)="filter()" [(ngModel)]="filterPersonal"></ng-select>
                                </th>
                                <th>Fim Teste</th>
                                <th>Mensagem</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr *ngFor="let lead of listaFimTesteFiltered| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                <td>{{lead.Customer.name}}</td>
                                <td>{{lead.Customer.email}}</td>
                                <td>{{lead.Customer.createdAt ? formatDateWithoutTime(lead.Customer.createdAt) : '-----'}}</td>
                                <td>{{lead.Seller.name}}</td>
                                <td>{{lead.expirationDate ? formatDateWithoutTime(lead.expirationDate) : '-----' }}</td>
                                <td><button type="button" class="btn btn-outline-warning border-left-warning mb-2 mb-md-0" *ngIf="lead.Customer.phone" ngbTooltip="Whatsapp" (click)="sendNotification(lead.Customer, '26150')"><i class="fa fa-telegram"></i></button>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                  
                        </div>
                        <div class="form-row" style="margin: 5px">
                          <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
                            <ngb-pagination style="margin-top: 10px" *ngIf="listaFimTesteFiltered.length>0"
                                            [(page)]="page"
                                            [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
                                            [collectionSize]="listaFimTesteFiltered.length"></ngb-pagination>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="listaFimTesteFiltered.length == 0">
                    <div class="col">
                      <div class="card-content">
                        <div class="card-body p-0 mt-2">
                          <ul class="list-group">
                            <li  class="list-group-item">
                              <div class="media">
                                <div class="media-body">
                                  <h5 class="mt-0">Nenhum Lead No 8º Dia de Teste </h5>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                
              </ng-template>
              </ngb-tab>
  
  
           
          </ngb-tabset>
        </div>
      </div>
    </div>
  </div>
  
  <ngx-spinner></ngx-spinner>