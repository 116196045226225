import { Component, Input, OnInit } from "@angular/core";
import { ClipboardService } from "ngx-clipboard";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-copy-checkout-url-modal",
  templateUrl: "./copy-checkout-url-modal.component.html",
  styleUrls: ["./copy-checkout-url-modal.component.scss"],
})
export class CopyCheckoutUrlModalComponent implements OnInit {
  @Input("checkoutUrl") checkoutUrl: string = "";

  constructor(
    private clipboardApi: ClipboardService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  copyToClipboard() {
    const copied = this.clipboardApi.copyFromContent(this.checkoutUrl);

    if (copied) {
      this.toastr.success("Link copiado com sucesso!");
    }
  }
}
