<div class="card m-0">
  <div class="card-header">
    <div class="row mb-0">
      <div class="col">
        <div class="w-100 d-flex justify-content-center align-items-center">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="50" cy="50" r="48" stroke="#FF5E19" stroke-width="4" />
            <path d="M49.697 64L48 18H55L53.0909 64H49.697Z" fill="#FF5E19" />
            <circle cx="51.1108" cy="76.3492" r="3.65079" fill="#FF5E19" />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="card-content mt-2">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div
            class="w-100 d-flex flex-column justify-content-center align-items-center px-3 px-md-4"
          >
            <h2 class="text-center text-small-2 m-0">
              {{ title || "Tem certeza?" }}
            </h2>
            <span class="mt-3 text-secondary text-small-2 text-center">{{
              subtitle || "Esta ação não poderá ser desfeita."
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row mb-0">
      <div class="col">
        <div
          class="w-100 d-flex justify-content-between align-items-center px-0 px-md-3"
        >
          <button
            type="button"
            class="btn btn-outline-primary text-primary px-4"
            (click)="close()"
          >
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary px-4 white"
            (click)="submit()"
          >
            {{ buttonLabel || "Confirmar" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
