/**
 * Converts a string in the format "R$ X.XXX,XX" and "X,XXX.XX BRL" to a floating point number.
 * @param str The string to be converted.
 * @returns The converted floating point number, or null if the string is not in the expected format.
 */
export const realToFloat = (str: string): number | null => {
  const regex_01 = /R\$ (\d{1,3}(?:\.\d{3})*(?:,\d{2})?)/;
  const variant_01 = str.match(regex_01);

  const regex_02 = /(\d{1,3}(?:,\d{3})*(?:\.\d{2})) BRL/;
  const variant_02 = str.match(regex_02);

  switch (true) {
    case variant_01 !== null:
      return +variant_01[1].replace(/\./g, "").replace(",", ".");
    case variant_02 !== null:
      return +variant_02[1].replace(/\,/g, "");
    default:
      return null;
  }
};
