import { AbstractControl } from "@angular/forms";
import * as moment from "moment";

const notZero = (control: AbstractControl) => {
  if (control.value === 0 || control.value === "0") {
    return { notZero: { value: control.value } };
  }
  return null;
};

const minDate = (min: Date) => {
  const _min = moment(min);

  return (control: AbstractControl) => {
    if (control.value) {
      const val = moment(control.value, "DD-MM-YYYY");

      if (val.isBefore(_min)) {
        return { minDate: { value: control.value as string } };
      }
    }
    return null;
  };
};

const maxDate = (max: Date) => {
  const _max = moment(max);

  return (control: AbstractControl) => {
    if (control.value) {
      const val = moment(control.value, "DD-MM-YYYY");

      if (val.isAfter(_max)) {
        return { maxDate: { value: control.value as string } };
      }
    }
    return null;
  };
};

export const CustomValidators = {
  notZero,
  minDate,
  maxDate,
};
