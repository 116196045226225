import { Component, OnInit } from '@angular/core';
import Api from "../helpers/api";
import App from "../helpers/app";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from 'rxjs/operators';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-admin-listar-subcontas',
  templateUrl: './admin-listar-subcontas.component.html',
  styleUrls: ['./admin-listar-subcontas.component.scss']
})
export class AdminListarSubcontasComponent implements OnInit {

  apiCall = this.api.new()
  subContas = []
  subContasFiltered = []
  page = 1
  pageSize = 10
  subAccountSelected = null
  lastModal = null
  filterEmail
  

  constructor(private modalService: NgbModal,
    private api: Api,
    private app: App,
    private spinner: NgxSpinnerService,) { }

async ngOnInit() {
  const self = this

  self.spinner.show(undefined,
    {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });


    const subContas = await self.apiCall.get('admin/users/listar-subcontas').pipe(take(1)).toPromise()

    if(subContas) this.subContas = subContas.return

    this.subContasFiltered = this.subContas

    console.log(this.subContas)
    self.spinner.hide()
}

filter(){
  this.subContasFiltered = this.subContas
  if(this.filterEmail){
    this.subContasFiltered = this.subContasFiltered.filter(x=>x.User.email.toLowerCase().includes(this.filterEmail.toLowerCase()))
  }
}

async analisarSubconta(subConta, modal) {
  const self = this

  this.subAccountSelected = subConta
  this.lastModal = modal

  this.lastModal = this.modalService.open(modal, { centered: true, size: "lg" });

}

async openIugu(account_id: string) {
  const iuguUrl = `https://alia.iugu.com/marketplace/subaccounts/${account_id}`;
  window.open(iuguUrl, "_blank");
}

}
