import {
  AfterViewInit,
  Component,
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import { User } from "app/helpers/me";
import { TraningResponseType } from "app/personal/pages/treinos/treinos-edit/treinos-edit.types";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, of } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { TrainingWizardService } from "./training-wizard.service";
import {
  CategoryType,
  CreateDraftTrainingResponseType,
  CustomerDefaultDataType,
  FormType,
  FormatTrainingResponseType,
  GPTResponseType,
  RelationType,
  TrainingDataType,
} from "./training-wizard.types";

const bufferSize = 50;

@Component({
  selector: "app-training-wizard",
  templateUrl: "./training-wizard.component.html",
  styleUrls: ["./training-wizard.component.scss"],
})
export class TrainingWizardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  apiCall = this.api.new().silent();

  formStep1: FormGroup | null = null;
  formStep2: FormGroup | null = null;
  formStep3: FormGroup | null = null;
  formStep4: FormGroup | null = null;
  trainingGeneratorForm: FormGroup | null = null;

  initialData: Partial<FormType | { [key: string]: any }> = {
    // gender: "male",
    // age: "10 - 15",
    // goal: "lose",
    // level: "beginner",
    // trainingPlace: "gym",
    // categoriesIds: [],
    // showPreviousTrainnings: false,
    // previousTrainningsIds: [],
    // series: 3,
    // repetitions: 12,
    // break: {
    //   minute: 0,
    //   second: 30,
    // },
    // speed: "fast",
    gender: "",
    age: null,
    goal: "",
    level: "",
    trainingPlace: "gym",
    categoriesIds: [],
    showPreviousTrainnings: false,
    previousTrainningsIds: [],
    series: "",
    repetitions: "",
    break: {
      minute: 0,
      second: 30,
    },
    speed: "",
  };

  ages: { value: string; label: string }[] = [
    { value: "10 - 15", label: "Entre 10 e 15 anos" },
    { value: "15 - 20", label: "Entre 15 e 20 anos" },
    { value: "20 - 30", label: "Entre 20 e 30 anos" },
    { value: "30 - 40", label: "Entre 30 e 40 anos" },
    { value: "40 - 50", label: "Entre 40 e 50 anos" },
    { value: "50 - 60", label: "Entre 50 e 60 anos" },
    { value: "Mais de 60", label: "Mais de 60 anos" },
  ];
  categories: CategoryType[] = [];

  $currentStep = new BehaviorSubject<number>(1);

  currentStep: number = 1;
  currentPercentage: string = "25%";
  totalSteps: number = 4;

  canGoForward: boolean = false;

  isBannerVisible: boolean = true;
  isPreviewVisible: boolean = false;
  isLoading: boolean = false;
  isCategoriesLoading: boolean = true;
  isOldTrainningsLoading: boolean = false;

  loadingCurrentStep: number = 1;
  requestCurrentStep: number = 1;

  generatedTraining: TrainingDataType[] = [];

  oldTrainnings: TraningResponseType[] = [];
  input$ = new BehaviorSubject<string>("");

  @ViewChild("generatedTrainingRef", { static: true })
  generatedTrainingRef!: TemplateRef<any>;

  @Input() customer: User | null = null;
  @Input() oldTrainningsRaw: TraningResponseType[] | null = null;
  @Input() relation: RelationType | null = null;
  @Input() group: { id?: number } | null = null;
  @Input() isModel: boolean | null = null;
  @Input() modalRef: NgbModalRef | null = null;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService,
    private api: Api,
    private trainingWzdService: TrainingWizardService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initForm();

    if (this.customer && this.customer.gender !== null) {
      this.trainingGeneratorForm
        ?.get("gender")
        ?.patchValue(!!this.customer.gender ? "female" : "male");
    }

    this.$currentStep.subscribe((value) => {
      this.currentStep = value;
      this.currentPercentage = `${(value / this.totalSteps) * 100}%`;

      const stepForm = this[`formStep${value as 1 | 2 | 3 | 4}`] as FormGroup;

      if (stepForm.invalid) {
        this.canGoForward = false;
      } else if (stepForm.valid) {
        this.canGoForward = true;
      }
    });
  }

  ngAfterViewInit() {
    if (this.relation?.id) {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });

      this.apiCall
        .get<CustomerDefaultDataType>(
          "seller/validaterelation/" + this.relation?.id
        )
        .subscribe((response) => {
          if (response.return) {
            const defaultData = response.return;

            this.trainingGeneratorForm?.patchValue({
              gender:
                this.customer && this.customer.gender !== null
                  ? !!this.customer.gender
                    ? "female"
                    : "male"
                  : defaultData.gender
                  ? defaultData.gender === "f"
                    ? "female"
                    : "male"
                  : this.initialData.gender,
              age:
                defaultData.age?.replace(" à ", " - ") || this.initialData.age,
              goal: defaultData.goal
                ? defaultData.goal === "Emagrecimento"
                  ? "lose"
                  : defaultData.goal === "Hipertrofia"
                  ? "gain"
                  : "maintain"
                : this.initialData.goal,
              // level: defaultData.level || this.initialData.level,
              // trainingPlace:
              //   defaultData.trainingPlace || this.initialData.trainingPlace,
            });

            if (
              defaultData.gender !== null &&
              defaultData.age !== null &&
              defaultData.goal !== null
            ) {
              this.$currentStep.next(2);
            }
          }

          this.spinner.hide();
        });
    }

    this.apiCall.get("training/exercises/categories").subscribe((data) => {
      const categories = data.return as { id: number; name: string }[];

      if (categories) {
        this.categories = categories.map(
          (category: { id: number; name: string }) => ({
            id: category.id,
            name: category.name,
            exercises: null,
          })
        );
      }

      this.isCategoriesLoading = false;
    });

    this.trainingGeneratorForm?.valueChanges.subscribe((value: FormType) => {
      const step = this[
        `formStep${this.currentStep as 1 | 2 | 3 | 4}`
      ] as FormGroup;

      step.patchValue(value);
    });

    for (let i = 1; i <= this.totalSteps; i++) {
      const stepForm = this[`formStep${i as 1 | 2 | 3 | 4}`] as FormGroup;

      stepForm.valueChanges.subscribe(() => {
        if (stepForm.valid && !this.canGoForward) {
          this.canGoForward = true;
        } else if (stepForm.invalid && this.canGoForward) {
          this.canGoForward = false;
        }
      });
    }

    this.oldTrainningsRaw =
      this.oldTrainningsRaw?.sort((a, b) => {
        if (a.status == true && a.type != 4) {
          return -1;
        } else if (b.status == true && b.type != 4) {
          return 1;
        } else {
          return 0;
        }
      }) || [];

    this.oldTrainnings = this.oldTrainningsRaw?.slice(0, bufferSize);
  }

  fetchMoreOldTrainnings(searchTerm: string) {
    const length = this.oldTrainnings.length;
    let more: TraningResponseType[] | undefined = undefined;

    if (searchTerm === null || searchTerm === "" || searchTerm === undefined) {
      more = this.oldTrainningsRaw?.slice(length, bufferSize + length);
    } else {
      more = this.oldTrainningsRaw
        ?.filter((exer) => exer.name.includes(searchTerm))
        .slice(length, bufferSize + length);
    }

    this.isOldTrainningsLoading = true;

    setTimeout(() => {
      this.isOldTrainningsLoading = false;
      this.oldTrainnings = this.oldTrainnings.concat(more || []);
    }, 200);
  }

  onSearchOldTrainnings() {
    this.input$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          const values = this.oldTrainningsRaw?.filter((trainning) =>
            trainning.name
              .toLocaleLowerCase()
              .includes(searchTerm?.toLocaleLowerCase())
          );

          return of(values);
        })
      )
      .subscribe((trainnings) => {
        if (trainnings) {
          this.oldTrainnings = trainnings.slice(0, bufferSize);
        }
      });
  }

  closeModal() {
    this.modalRef?.dismiss();
  }

  initForm() {
    this.formStep1 = new FormGroup({
      gender: new FormControl(this.initialData.gender, [
        Validators.required,
        Validators.pattern(/^(female|male)$/),
      ]),
      age: new FormControl(this.initialData.age, [
        Validators.required,
        Validators.min(8),
      ]),
      goal: new FormControl(this.initialData.goal, [
        Validators.required,
        Validators.pattern(/^(lose|gain|maintain)$/),
      ]),
    });

    this.formStep2 = new FormGroup({
      level: new FormControl(this.initialData.level, [
        Validators.required,
        Validators.pattern(/^(beginner|intermediate|advanced)$/),
      ]),
      trainingPlace: new FormControl(this.initialData.trainingPlace, [
        Validators.required,
        Validators.pattern(/^(gym|home)$/),
      ]),
    });

    this.formStep3 = new FormGroup({
      categoriesIds: new FormControl(this.initialData.categoriesIds, [
        Validators.required,
        Validators.minLength(1),
        (control: FormControl) => {
          const value = control.value as number[];

          let isValid = true;

          if (Array.isArray(value)) {
            for (const id of value) {
              const category = this.getSelectedCategoryFromId(id);

              if (!category?.exercises || category?.exercises <= 0) {
                isValid = false;
                break;
              }
            }
          }

          if (!isValid) {
            return {
              minExercisesNumber: true,
            };
          }

          return null;
        },
      ]),
      showPreviousTrainnings: new FormControl(
        this.initialData.showPreviousTrainnings
      ),
      previousTrainningsIds: new FormControl(
        this.initialData.previousTrainningsIds,
        [
          (control: FormControl) => {
            const value = control.value as number[];

            let isValid = true;

            if (Array.isArray(value)) {
              for (const id of value) {
                const trainning = this.oldTrainningsRaw?.find(
                  (trainning) => trainning.id === id
                );

                if (!trainning) {
                  isValid = false;
                  break;
                }
              }
            }

            if (!isValid) {
              return {
                minExercisesNumber: true,
              };
            }

            return null;
          },
        ]
      ),
    });

    this.formStep4 = new FormGroup({
      series: new FormControl(this.initialData.series, [
        Validators.required,
        Validators.min(1),
      ]),
      repetitions: new FormControl(this.initialData.repetitions, [
        Validators.required,
      ]),
      break: new FormGroup({
        minute: new FormControl(this.initialData.break.minute, [
          Validators.required,
          Validators.min(0),
          Validators.max(59),
        ]),
        second: new FormControl(this.initialData.break.second, [
          Validators.required,
          Validators.min(0),
          Validators.max(59),
        ]),
      }),
      speed: new FormControl(this.initialData.speed, [
        Validators.required,
        Validators.pattern(/^(isometric|slow|medium|fast)$/),
      ]),
    });

    this.trainingGeneratorForm = new FormGroup({
      ...this.formStep1.controls,
      ...this.formStep2.controls,
      ...this.formStep3.controls,
      ...this.formStep4.controls,
    });
  }

  getCurrentRequestProp() {
    switch (this.requestCurrentStep) {
      case 1:
        return {
          createFile: true,
        };
      case 2:
        return {
          createThreads: true,
        };
      case 3:
        return {
          createMessages: true,
        };
      case 4:
        return {
          runThreads: true,
        };
      case 5:
        return {
          runCheck: true,
        };
    }
  }

  openModalXl(content: any) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  startLoading() {
    this.isLoading = true;

    let interval = setInterval(() => {
      if (this.loadingCurrentStep >= 3) {
        clearInterval(interval);
        return;
      }

      this.loadingCurrentStep++;
    }, 5000);
    // }, 15000);
  }

  onSubmit() {
    if (this.trainingGeneratorForm?.invalid) {
      return;
    }

    const formData = this.trainingGeneratorForm?.value as FormType;

    const categoriesReq = this.categories
      .map((cat) =>
        formData.categoriesIds.includes(cat.id)
          ? this.getSelectedCategoryFromId(cat.id)
          : null
      )
      .filter((cat) => cat !== null && cat !== undefined)
      .sort((a, b) => {
        if (a && b) {
          return (
            formData.categoriesIds.indexOf(a.id) -
            formData.categoriesIds.indexOf(b.id)
          );
        }

        return 0;
      });

    const req = (req?: Record<string, number | string>) => ({
      user: {
        age: formData.age.replace(" - ", " à "),
        genre: formData.gender === "female" ? "f" : "m",
        objective:
          formData.goal === "lose"
            ? "Emagrecimento"
            : formData.goal === "gain"
            ? "Hipertrofia"
            : "Manutenção",
        training: formData.trainingPlace === "gym" ? "Academia" : "Casa",
        level:
          formData.level === "beginner"
            ? "Iniciante"
            : formData.level === "intermediate"
            ? "Intermediário"
            : "Avançado",
      },
      training: {
        categories: categoriesReq,
      },
      prompts: [
        {
          role: "user",
          content: "Os treinos precisam ser coerente",
        },
        {
          role: "user",
          content: "Os treinos precisam fazer sentido",
        },
        {
          role: "user",
          content: "Os treinos precisam ser eficientes",
        },
      ],
      lasttrainings: formData.previousTrainningsIds,
      ...this.getCurrentRequestProp(),
      ...req,
    });

    let returnedPayload: {
      fileId?: number | string;
      threadId?: number | string;
      runId?: number | string;
    } | null = null;

    this.startLoading();

    const endpoint = "personalgpt/assistant";

    // create file endpoint
    // this.apiCall
    //   .post(endpoint, req())
    //   .pipe(
    //     // create thread endpoint
    //     switchMap((response) => {
    //       this.requestCurrentStep++;

    //       if (response.return && response.success) {
    //         returnedPayload = {
    //           ...returnedPayload,
    //           fileId: response.return.id,
    //         };
    //         return this.apiCall.post(endpoint, req(returnedPayload));
    //       } else {
    //         this.trainingWzdService.throwUnexpectedError();
    //       }
    //     }),
    //     // create message endpoint
    //     switchMap((response) => {
    //       this.requestCurrentStep++;

    //       if (response.return && response.success) {
    //         returnedPayload = {
    //           ...returnedPayload,
    //           threadId: response.return.id,
    //         };
    //         return this.apiCall.post(endpoint, req(returnedPayload));
    //       } else {
    //         this.trainingWzdService.throwCreationError();
    //       }
    //     }),
    //     // run thread endpoint
    //     switchMap((response) => {
    //       this.requestCurrentStep++;

    //       if (response.return && response.success) {
    //         return this.apiCall.post(endpoint, req(returnedPayload));
    //       } else {
    //         this.trainingWzdService.throwCreationError();
    //       }
    //     }),
    //     // run check endpoint
    //     switchMap((response) => {
    //       this.requestCurrentStep++;

    //       if (response.return && response.success) {
    //         returnedPayload = {
    //           ...returnedPayload,
    //           runId: response.return.id,
    //         };

    //         return this.apiCall.post(endpoint, req(returnedPayload)).pipe(
    //           expand((response) => {
    //             if (response.return && response.success) {
    //               if (response.return.status !== "in_progress") {
    //                 const status = response.return.status;

    //                 if (Array.isArray(response.return)) {
    //                   const GPTResponseData =
    //                     response.return as GPTResponseType[];

    //                   const training = GPTResponseData.map(
    //                     (data: GPTResponseType) => ({
    //                       id: data.id,
    //                       order: data.order,
    //                       category: data.category,
    //                       series: formData.series,
    //                       breakTime: formData.break,
    //                       exercicio: data.exercicio,
    //                       repetitions: formData.repetitions,
    //                       speed: formData.duration,
    //                     })
    //                   );

    //                   // format training
    //                   this.trainingWzdService
    //                     .formatTraining(training)
    //                     .subscribe((response) => {
    //                       if (response.return && response.success) {
    //                         const formattedTraining =
    //                           response.return as FormatTrainingResponseType[];

    //                         // create draft training
    //                         this.trainingWzdService
    //                           .createDraftTraining({
    //                             sellerCustomer: this.relation.id,
    //                             exercises: formattedTraining,
    //                           })
    //                           .subscribe((response) => {
    //                             if (response.return && response.success) {
    //                               const draftTraining: CreateDraftTrainingResponseType =
    //                                 response.return;

    //                               this.generatedTraining = training;

    //                               this.finalizeTrainingCreation(draftTraining);
    //                             } else {
    //                               this.resetLoading();
    //                               this.trainingWzdService.alertCreationError();
    //                             }
    //                           });
    //                       } else {
    //                         this.resetLoading();
    //                         this.trainingWzdService.alertCreationError();
    //                       }
    //                     });
    //                 } else if (!status) {
    //                   this.resetLoading();
    //                   this.trainingWzdService.alertCreationError();
    //                 }

    //                 return of(response);
    //               }

    //               return this.apiCall
    //                 .post(endpoint, req(returnedPayload))
    //                 .pipe(delay(10000));
    //             } else {
    //               this.resetLoading();
    //               this.trainingWzdService.alertCreationError();
    //             }
    //           }),
    //           takeWhile(
    //             (response) =>
    //               response.return && response.return.status === "in_progress"
    //           )
    //         );
    //       } else {
    //         this.trainingWzdService.throwCreationError();
    //       }
    //     }),
    //     catchError((error: string) => {
    //       this.resetLoading();
    //       this.app.alert("Erro", String(error).replace("Error: ", ""), "error");

    //       return of(null);
    //     })
    //   )
    //   .subscribe((response: null | Response) => {
    //     if (!response) return;

    //     try {
    //       if (response.success && response.return) {
    //         //
    //       } else {
    //         this.trainingWzdService.throwCreationError();
    //       }
    //     } catch (error) {
    //       this.resetLoading();
    //       this.toastr.error(String(error).replace("Error: ", ""));
    //     }
    //   });

    this.apiCall
      .post<GPTResponseType[]>(
        "training-generator",
        req(),
        "https://iagobackend.opersonaldigital.com.br/"
      )
      .subscribe((response) => {
        if (response.return && response.success) {
          let GPTResponseData: GPTResponseType[];

          if (Array.isArray(response.return)) {
            GPTResponseData = response.return;
          } else {
            this.resetLoading();
            this.trainingWzdService.alertCreationError();
            return;
          }

          const training = GPTResponseData.map((data: GPTResponseType) => ({
            id: data.id,
            order: data.order,
            category: data.category,
            series: formData.series,
            breakTime: formData.break,
            exercicio: data.exercicio,
            repetitions: formData.repetitions,
            speed: formData.speed,
          }));

          // format training
          this.trainingWzdService
            .formatTraining(training)
            .subscribe((response) => {
              if (response.return && response.success) {
                const formattedTraining =
                  response.return as FormatTrainingResponseType[];

                // create draft training
                this.trainingWzdService
                  .createDraftTraining({
                    sellerCustomer: this.relation?.id || null,
                    group: this.group?.id || null,
                    isModel: this.isModel,
                    exercises: formattedTraining,
                    lastTrainings: formData.previousTrainningsIds,
                  })
                  .subscribe((response) => {
                    if (response.return && response.success) {
                      const draftTraining: CreateDraftTrainingResponseType =
                        response.return;

                      this.generatedTraining = training;

                      if (this.relation?.id) {
                        this.apiCall
                          .put("sellercustomer/" + this.relation.id, {
                            gender: req().user.genre,
                            age: req().user.age,
                            goal: req().user.objective,
                          })
                          .subscribe(() =>
                            this.finalizeTrainingCreation(draftTraining)
                          );
                      } else {
                        this.finalizeTrainingCreation(draftTraining);
                      }
                    } else {
                      this.resetLoading();
                      this.trainingWzdService.alertCreationError();
                    }
                  });
              } else {
                this.resetLoading();
                this.trainingWzdService.alertCreationError();
              }
            });
        } else {
          this.resetLoading();
          this.trainingWzdService.alertCreationError();
        }
      });
  }

  finalizeTrainingCreation(draftTraining: CreateDraftTrainingResponseType) {
    this.toastr.success("Treino criado com sucesso! Redirecionando...");

    this.loadingCurrentStep = 4;

    const encodedData = btoa(
      JSON.stringify({
        training: String(draftTraining.id),
        relation: this.relation ? String(this.relation?.id) : "",
        group: this.group ? String(this.group?.id) : "",
        isModel: this.isModel || false,
      } as {
        training: string;
        relation: string;
        group: string;
        isModel: boolean;
      })
    ).replace(/=/g, "");

    setTimeout(() => {
      this.resetLoading();
      this.modalRef?.close();
      this.router.navigate([`/page/personal/treinos/${encodedData}/editar`]);
    }, 2000);
  }

  validateField(controlName: string, form: FormGroup) {
    return {
      valid:
        form.controls[controlName].valid &&
        (form.controls[controlName].touched ||
          form.controls[controlName].dirty),
      invalid:
        form.controls[controlName].invalid &&
        (form.controls[controlName].touched ||
          form.controls[controlName].dirty),
    };
  }

  validateStep(forced: boolean = false) {
    const step = this[
      `formStep${this.currentStep as 1 | 2 | 3 | 4}`
    ] as FormGroup;
    const controls = Object.keys(step.controls);

    let isValid = true;

    if (
      (this.trainingGeneratorForm?.dirty &&
        this.trainingGeneratorForm.touched) ||
      forced
    ) {
      controls.forEach((control) => step.controls[control].markAsDirty());

      for (const control of controls) {
        if (step.controls[control].invalid) {
          isValid = false;
          break;
        }
      }
    }

    return isValid;
  }

  getSelectedCategoryFromId(id: number): CategoryType | undefined {
    return this.categories.find((cat) => cat.id === id);
  }

  updateCategoryExercise(id: number, event: Event) {
    const categoriesIdsFormArray =
      this.trainingGeneratorForm?.get("categoriesIds");

    const value = (event.target as HTMLInputElement).value;

    const updatedCategory = this.categories.find((cat) => cat.id === id);
    if (updatedCategory) {
      if (value && !isNaN(+value) && +value >= 0) {
        updatedCategory.exercises = +value;
      } else {
        updatedCategory.exercises = null;
      }
    }

    categoriesIdsFormArray?.patchValue(categoriesIdsFormArray?.value);
  }

  deleteCategory(id: number) {
    const categoriesFormArray =
      this.trainingGeneratorForm?.get("categoriesIds");

    if (!id) return;

    this.categories = this.categories.map((cat) =>
      cat.id === id ? { ...cat, exercises: null } : cat
    );

    categoriesFormArray?.patchValue(
      categoriesFormArray.value.filter((catId: number) => catId !== id)
    );
  }

  clearCategories() {
    this.categories = this.categories.map((cat) => ({
      ...cat,
      exercises: null,
    }));
  }

  deleteOldTrainning(id: number) {
    const previousTrainningsFormArray = this.trainingGeneratorForm?.get(
      "previousTrainningsIds"
    );

    if (!id) return;

    previousTrainningsFormArray?.patchValue(
      previousTrainningsFormArray.value.filter(
        (trainningId: number) => trainningId !== id
      )
    );
  }

  clearOldTrainnings() {
    this.oldTrainnings = this.oldTrainningsRaw?.slice(0, bufferSize) || [];
  }

  getLoadingContent(index: number) {
    return loadingStepsContent[
      (index > loadingStepsContent.length
        ? loadingStepsContent.length
        : index) - 1
    ];
  }

  setBannerVisibility(value: boolean) {
    this.isBannerVisible = value;
  }

  getOldTrainningTransformedDate(id: number) {
    const trainning = this.oldTrainningsRaw?.find(
      (trainning) => trainning.id === id
    );

    if (!trainning) return "-";

    return moment(trainning.createdAt).format("DD/MM/YYYY");
  }

  getOldTrainningType(id: number) {
    const trainning = this.oldTrainningsRaw?.find(
      (trainning) => trainning.id === id
    );

    if (!trainning) return "-";

    if (trainning.status == true && trainning.type != 4) {
      return "Ativo";
    } else if (trainning.status == false && trainning.type != 4) {
      return "Arquivado";
    } else if (trainning.type == 4) {
      return "Avaliativo";
    }
    return "-";
  }

  // Wizard methods
  nextStep() {
    if (this.currentStep === this.totalSteps || !this.validateStep(true)) {
      return;
    }

    this.$currentStep.next(this.currentStep + 1);
  }

  prevStep() {
    if (this.currentStep === 1) {
      return;
    }

    this.$currentStep.next(this.currentStep - 1);
  }

  // End life cycle methods
  resetLoading() {
    this.isLoading = false;
    this.loadingCurrentStep = 1;
    this.requestCurrentStep = 1;
  }

  clearForm() {
    this.trainingGeneratorForm?.reset();
    this.trainingGeneratorForm?.markAsPristine();
    this.trainingGeneratorForm?.markAsUntouched();
    this.trainingGeneratorForm?.updateValueAndValidity();

    for (let i = 1; i <= this.totalSteps; i++) {
      const step = this[`formStep${i as 1 | 2 | 3 | 4}`] as FormGroup;

      step.reset();
      step.markAsPristine();
      step.markAsUntouched();
      step.updateValueAndValidity();
    }
  }

  ngOnDestroy() {
    this.$currentStep.unsubscribe();

    this.clearForm();
  }
}

@Directive({
  selector: 'input[type="number"]',
})
export class DisableNumberInputWheelDirective {
  constructor() {}

  @HostListener("wheel", ["$event"]) onWheel(event: WheelEvent) {
    event.preventDefault();
  }
}

const loadingStepsContent = [
  {
    title: "Analisando as informações fornecidas...",
    description:
      "Espere um momento, estou consultando nosso banco de dados para um criar um treino personalizado para você. Grandes coisas estão chegando!",
  },
  {
    title: "Criando seu treino...",
    description:
      "Seu treino exclusivo está sendo criado pela melhor inteligência artificial do mercado. O futuro da sua consultoria está a caminho.",
  },
  {
    title: "Está quase pronto!",
    description:
      "Só um segundo! Estou finalizando um treino personalizado para você. Prepare-se para elevar o padrão da sua forma de prescrever treinos.",
  },
  {
    title: "Treino finalizado!",
    description:
      "Parabéns! Seu treino personalizado está pronto e você está sendo redirecionado para acessá-lo.",
  },
];

// {
// 	"api_url": "https://pyn5u8h0qe.execute-api.sa-east-1.amazonaws.com/dev/",
// 	"auth_login": "contato@sorayaalmeida.com.br",
// 	"auht_pass": "2CFL"
// }
