import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import Api from "../../../helpers/api";
import App from "../../../helpers/app";
import { ExercicioComponent } from "./pages/exercicio/exercicio.component";

@Component({
  selector: "app-exercicios",
  templateUrl: "./exercicios.component.html",
  styleUrls: ["./exercicios.component.scss"],
})
export class ExerciciosComponent implements OnInit {
  apiCall = this.api.new().silent();
  pageTr = 1;
  pageSize = 10;
  originalExercicies = [];
  exercicies = [];
  categories = [];
  categoriaSelecionada = null;
  exercicioProcurado = null;
  urlToPlay = null;
  safeURL = null;

  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private _sanitizer: DomSanitizer,
    private app: App,
    private api: Api
  ) {}

  async ngOnInit() {
    const self = this;
    self.exercicies = [];

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.apiCall.get("training/exercises").subscribe(async (data) => {
      self.originalExercicies = data.return;

      if (
        this.categoriaSelecionada &&
        this.categoriaSelecionada.ExercisesCategories &&
        this.categoriaSelecionada.ExercisesCategories.length > 0
      ) {
        for (let e of this.categoriaSelecionada.ExercisesCategories) {
          this.exercicies.push(e.Exercise);
        }
      } else {
        self.exercicies = data.return;
      }

      self.apiCall
        .get("training/exercises/categories")
        .subscribe(async (cat) => {
          self.categories = cat.return;
          let auxExercicies = [];
          for (let e of self.originalExercicies) {
            auxExercicies.push({
              Exercise: e,
            });
          }

          self.categories.unshift({
            name: "Todos",
            ExercisesCategories: auxExercicies,
          });
          self.spinner.hide();
        });
    });
  }

  viewMidia(urlToPlay, content) {
    this.urlToPlay = urlToPlay;
    if (urlToPlay.startsWith("https://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "https://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    } else if (urlToPlay.startsWith("http://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "http://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    }
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  delete(exercise) {
    const self = this;
    if (!exercise || !exercise.id) {
      return;
    }
    self.app
      .confirm("Tem certeza?", "Você realmente deseja deletar este exercício?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("training/exercises/" + exercise.id)
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Exercício deletado com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }

  onSearchExercicie() {
    if (this.exercicioProcurado.length > 0) {
      this.exercicies = [];
      if (
        this.categoriaSelecionada &&
        this.categoriaSelecionada.ExercisesCategories &&
        this.categoriaSelecionada.ExercisesCategories.length > 0
      ) {
        let exercicies = [];
        if (
          this.categoriaSelecionada &&
          this.categoriaSelecionada.ExercisesCategories &&
          this.categoriaSelecionada.ExercisesCategories.length > 0
        ) {
          for (let e of this.categoriaSelecionada.ExercisesCategories) {
            exercicies.push(e.Exercise);
          }
        }
        this.exercicies = exercicies.filter((e) =>
          e.name
            .toString()
            .toLowerCase()
            .trim()
            .includes(this.exercicioProcurado.toString().toLowerCase().trim())
        );
      } else {
        this.exercicies = this.originalExercicies.filter((e) =>
          e.name
            .toString()
            .toLowerCase()
            .trim()
            .includes(this.exercicioProcurado.toString().toLowerCase().trim())
        );
      }
    } else {
      this.onResetExercicies();
    }
  }
  onResetExercicies() {
    this.exercicies = this.originalExercicies;
  }
  onselectCategory() {
    this.exercicies = [];
    if (
      this.categoriaSelecionada &&
      this.categoriaSelecionada.ExercisesCategories &&
      this.categoriaSelecionada.ExercisesCategories.length > 0
    ) {
      for (let e of this.categoriaSelecionada.ExercisesCategories) {
        this.exercicies.push(e.Exercise);
      }
    }
  }

  openExercise(exercise, duplicate) {
    const modalRef = this.modalService.open(ExercicioComponent, { size: "lg" });
    if (exercise) modalRef.componentInstance.exerciseToEdit = exercise;
    if (duplicate) modalRef.componentInstance.duplicate = duplicate;

    modalRef.result.then((data) => {
      // on close
      this.ngOnInit();
    });
  }

  isNewExercise(exercise) {
    return (
      moment().diff(moment(exercise.createdAt), "days") <= 20 && !exercise.user
    );
  }

  abrirFormularioSugestao() {
    window.open("https://forms.gle/A5ZWS2P8BcspveoM9", "_blank");
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }
}
