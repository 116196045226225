import * as moment from "moment";
import App from "../../helpers/app";
import Api from "../../helpers/api";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { distinctUntilChanged, filter, switchMap, take } from "rxjs/operators";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedService } from "../../shared/services/shared.service";
import { PaymentMethodService } from "../../shared/services/metodo-pagamento.service";
import { CobrancaFaturasComponent } from "./cobranca-faturas/cobranca-faturas.component";

@Component({
  selector: "app-faturas",
  templateUrl: "./faturas.component.html",
  styleUrls: ["./faturas.component.scss"],
})
export class FaturasComponent implements OnInit {
  user: any;
  cartoes: any;
  faturas: any[] = [];
  paginatedFaturas: any[] = [];
  page = 0;
  pageSize = 10;
  listaFaturas: any;
  subscriptions: any;
  userSubscription: Subscription;
  apiCall = this.api.new().silent();
  subscriptionsSubscription: Subscription;
  paymentMethodSubscription: Subscription;

  StatusTranslate = {
    canceled: "Cancelado",
    refunded: "Reembolsado",
    expired: "Expirado",
    paid: "Pago",
  };

  constructor(
    private app: App,
    private api: Api,
    private router: Router,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private paymentMethodService: PaymentMethodService
  ) {}

  ngOnInit(): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.userSubscription = this.sharedService
      .getUser()
      .pipe(
        filter((user) => !!user),
        distinctUntilChanged(
          (prevUser, currUser) => prevUser.id === currUser.id
        ),
        switchMap((user) => {
          this.user = user;
          return this.apiCall.get(
            `backoffice/${user.iugu_2}/financial/profile/invoices`
          );
        })
      )
      .subscribe(
        (data) => {
          this.faturas = data.return.invoices;
          this.listaFaturas = data.return;
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter faturas:", error);
        }
      );

    this.subscriptionsSubscription = this.sharedService
      .getSubscriptions()
      .pipe(filter((subscriptions) => !!subscriptions))
      .subscribe((subscriptions) => {
        this.subscriptions = subscriptions;
      });

    this.paymentMethodSubscription = this.paymentMethodService
      .getPaymentMethod()
      .pipe(filter((subscriptions) => !!subscriptions))
      .subscribe((cartoes) => {
        if (cartoes) {
          this.cartoes = cartoes;
        }
      });
  }

  invoicesList(user: any): void {
    this.spinner.show();

    this.apiCall
      .get(`backoffice/${user.iugu_2}/financial/profile/invoices`)
      .subscribe(
        (data) => {
          this.faturas = data.return.invoices;
          this.listaFaturas = data.return;
          this.spinner.hide();
        },
        (error) => {
          console.log("Erro ao obter faturas:", error);
          this.spinner.hide();
        }
      );
  }

  translateStatus(status: string): string {
    return this.StatusTranslate[status] || status;
  }

  cancelarFatura(invoiceId: string): void {
    const body = { invoiceId: invoiceId };
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    this.apiCall.put("/backoffice/financial/invoice/cancel", body).subscribe(
      (response) => {
        this.spinner.hide();
        if (response.error == true) {
          this.app.alert(response.message, "");
        } else {
          this.app.alert("Fatura Cancelada!", "", "success");
          this.faturas = this.faturas.filter(
            (invoice) => invoice.id !== invoiceId
          );
        }
      },
      (error) => {
        console.log("Erro ao cancelar fatura:", error);
      }
    );
  }

  cobrarNovamente(invoiceId: string): void {
    const modalRef = this.modalService.open(CobrancaFaturasComponent);
    modalRef.componentInstance.cards = this.cartoes;
    modalRef.componentInstance.confirm.subscribe((selectedCardId) => {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const body = {
        customerId: this.user.id,
        paymentMethodId: selectedCardId,
        invoiceId: invoiceId,
        months: null,
      };
      this.apiCall.post("backoffice/financial/direct-charge/", body).subscribe(
        (response) => {
          this.spinner.hide();
          if (response.error == true) {
            this.app.alert(response.message, "falha");
          } else {
            this.app.alert("Cobrança direta bem-sucedida!", "", "success");
            this.faturas = this.faturas.filter(
              (invoice) => invoice.id !== invoiceId
            );
          }
        },
        (error) => {
          console.log("Erro ao realizar cobrança direta:", error);
        }
      );
    });
  }

  redirectToAssinaturaPersonal(invoiceId: string): void {
    this.router.navigate([`/pages/assinatura-personal/${invoiceId}`]);
  }

  formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  updatePaginatedFaturas() {
    const startIndex = (this.page - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedFaturas = this.faturas.slice(startIndex, endIndex);
  }

  onPageChange(page: number) {
    this.page = page;
    this.updatePaginatedFaturas();
  }
}
