import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-cta-subscription",
  templateUrl: "./cta-subscription.component.html",
  styleUrls: ["./cta-subscription.component.scss"],
})
export class CtaSubscriptionComponent {
  variants = [
    "../../../../../../../assets/img/iago_popup_cta_banner.png",
    "../../../../../../../assets/img/iago_popup_cta_banner_2.png",
    "../../../../../../../assets/img/iago_popup_cta_banner_3.png",
  ];

  @Input() callback: () => void = () => {};

  constructor(private activeModal: NgbActiveModal, private router: Router) {}

  close() {
    this.activeModal.close();
  }

  closeButton() {
    this.close();
    this.callback();
  }

  goCheckoutIago() {
    window.open("https://opersonaldigital.com.br/app-chegou-o-IAGO", "_blank");
  }
}
