import { Component, OnInit, Input, Output, EventEmitter   } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cobranca-faturas',
  templateUrl: './cobranca-faturas.component.html',
  styleUrls: ['./cobranca-faturas.component.scss']
})
export class CobrancaFaturasComponent implements OnInit {

  @Input() cards: any[] = [];
  selectedCard: string;
  
  @Output() confirm = new EventEmitter<string>();

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
  
  confirmarCobranca() {
    this.confirm.emit(this.selectedCard);
    this.activeModal.close();
  }
  
}
