<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem">{{ pageTitle }}</h1>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-md-3 col-12">
    <input
      type="date"
      class="form-control ml-2"
      [(ngModel)]="datas.inicio"
      (change)="filter()"
    />
  </div>
  <div class="col-md-3 col-12">
    <input
      type="date"
      class="form-control ml-2"
      [(ngModel)]="datas.fim"
      (change)="filter()"
    />
  </div>
  <div class="col-md-3 col-12">
    <button
      type="button"
      class="btn btn-outline-warning border-left-warning mb-2 mb-md-0 ml-2"
      ngbTooltip="Limpar Datas"
      (click)="limparDatas()"
    >
      <i class="fa fa-eraser"></i>
    </button>
  </div>
</div>

<!--Pills Starts-->
<div class="col-md-12 col-lg-12">
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <ngb-tabset type="pills">
          <ngb-tab>
            <ng-template ngbTabTitle>
              <div (click)="updateTitle(1)">
                <i class="ft-user-x font-medium-5"></i>
              </div>
            </ng-template>
            <ng-template ngbTabContent>
              <div class="row mb-2 mt-2" *ngIf="listaChurn.length > 0">
                <div class="col-12 col-lg-3">
                  <p>{{ listaChurnFiltered.length }} Registros encontrados</p>
                </div>
                <div class="col-12 col-lg-9">
                  <div class="float-right d-none d-md-block mb-2">
                    <button
                      type="button"
                      class="btn btn-success"
                      (click)="exportarListaUsuarios(1)"
                    >
                      <i class="fa fa-file-archive-o"></i> Baixar Lista
                    </button>
                  </div>
                </div>
              </div>

              <div class="row mb-2 mt-2" *ngIf="listaChurn.length > 0">
                <div class="col">
                  <div class="card-content">
                    <div class="card-body p-0">
                      <div class="table text-center m-0">
                        <table
                          class="table-striped table-responsive"
                          style="width: 100%"
                        >
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="text"
                                  id="name"
                                  class="form-control"
                                  style="background-color: white; height: 100%"
                                  placeholder="Nome"
                                  name="name"
                                  [(ngModel)]="filterName"
                                  (keyup)="filter()"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  id="email"
                                  class="form-control"
                                  style="background-color: white; height: 100%"
                                  placeholder="Email"
                                  name="email"
                                  [(ngModel)]="filterEmail"
                                  (keyup)="filter()"
                                />
                              </th>
                              <th>Data Fatura</th>

                              <th>
                                <ng-select
                                  [items]="statusContato"
                                  name="statusContato"
                                  bindLabel="name"
                                  placeholder="Status Contato"
                                  id="statusContato"
                                  (change)="filter()"
                                  [(ngModel)]="filterStatusContato"
                                ></ng-select>
                              </th>
                              <th>
                                <ng-select
                                  [items]="tipoAssinatura"
                                  name="tipoAssinatura"
                                  bindLabel="name"
                                  placeholder="Tipo Assinatura"
                                  id="tipoAssinatura"
                                  (change)="filter()"
                                  [(ngModel)]="filterTipoAssinatura"
                                ></ng-select>
                              </th>
                              <th>Contato WhatsApp</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let usuario of listaChurnFiltered
                                  | slice
                                    : (page - 1) * pageSize
                                    : (page - 1) * pageSize + pageSize
                              "
                            >
                              <td>{{ usuario.name }}</td>
                              <td>{{ usuario.email }}</td>
                              <td>
                                {{
                                  formatDateWithoutTime(
                                    usuario.expirationDate
                                  )
                                }}
                              </td>
                              <td [innerHTML]="checkRecoveryStatus(usuario)"></td>
                              <td>
                                {{
                                  usuario.paymentMethod == "bank_slip"
                                    ? "Boleto"
                                    : "Cartão"
                                }}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                                  *ngIf="usuario.User.phone"
                                  ngbTooltip="Whatsapp"
                                  (click)="openWhatsapp(usuario.User.phone)"
                                >
                                  <i class="fa fa-whatsapp"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                                  *ngIf="usuario.User.phone"
                                  ngbTooltip="Notificações Inteligentes"
                                  (click)="openNotificacoesInteligentes(usuario.User.phone)"
                                >
                                  <i class="fa fa-telegram"></i>
                                </button>
                              </td>
                              <td>
                                <div
                                  class="dropdown btn-group-sm mr-1 mb-1"
                                  ngbDropdown
                                >
                                  <button
                                    type="button"
                                    class="btn btn-outline-warning btn-sm dropdown-toggle"
                                    ngbDropdownToggle
                                  >
                                    <i class="ft-more-vertical"></i>
                                  </button>
                                  <div
                                    ngbDropdownMenu
                                    class="dropdown-menu"
                                    role="menu"
                                  >
                                    <a
                                      class="dropdown-item"
                                      (click)="openModal(dados, usuario, false)"
                                      >Visualizar</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      (click)="
                                        openAtualizaContato(
                                          usuario,
                                          ContactDetails
                                        )
                                      "
                                      >Atualizar Contato</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      [href]="
                                        'https://alia.iugu.com/receive/billing/' +
                                        usuario.subscriptionId
                                      "
                                      target="_blank"
                                      >Ver Assinatura</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      (click)="
                                        manageRecoveryFunnel(
                                          usuario.recoveryFunnelStatus,
                                          usuario
                                        )
                                      "
                                      >{{
                                        usuario.recoveryFunnelStatus &&
                                        usuario.recoveryFunnelStatus == true
                                          ? "Remover Funil Serasa"
                                          : "Adicionar Funil Serasa"
                                      }}</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      (click)="
                                      openAddUserToFunnel(
                                          usuario,
                                          AddToFunnel
                                        )
                                      "
                                      >Adicionar ao Funil de Recuperação</a
                                    >
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="form-row" style="margin: 5px">
                      <div
                        class="d-flex justify-content-center"
                        style="margin-left: auto; margin-right: auto"
                      >
                        <ngb-pagination
                          style="margin-top: 10px"
                          *ngIf="listaChurnFiltered.length > 0"
                          [(page)]="page"
                          [pageSize]="pageSize"
                          [maxSize]="2"
                          [rotate]="true"
                          [boundaryLinks]="true"
                          size="sm"
                          [collectionSize]="listaChurnFiltered.length"
                        ></ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="listaChurn.length == 0">
                <div class="col">
                  <div class="card-content">
                    <div class="card-body p-0 mt-2">
                      <ul class="list-group">
                        <li class="list-group-item">
                          <div class="media">
                            <div class="media-body">
                              <h5 class="mt-0">Nenhum Churn :)</h5>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>

          <ngb-tab>
            <ng-template ngbTabTitle>
              <div (click)="updateTitle(2)">
                <i class="ft-user-plus font-medium-5"></i>
              </div>
            </ng-template>

            <ng-template ngbTabContent>
              <div
                class="row mb-2 mt-2"
                *ngIf="listaNovosAssinantes.length > 0"
              >
                <div class="col-12 col-lg-3">
                  <p>
                    {{ listaNovosAssinantesFiltered.length }} Registros
                    encontrados
                  </p>
                </div>
                <div class="col-12 col-lg-9">
                  <div class="float-right d-none d-md-block mb-2">
                    <button
                      type="button"
                      class="btn btn-success"
                      (click)="exportarListaUsuarios(2)"
                    >
                      <i class="fa fa-file-archive-o"></i> Baixar Lista
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="row mb-2 mt-2"
                *ngIf="listaNovosAssinantes.length > 0"
              >
                <div class="col">
                  <div class="card-content">
                    <div class="card-body p-0">
                      <div class="table text-center m-0">
                        <table
                          class="table-striped table-responsive"
                          style="width: 100%"
                        >
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="text"
                                  id="name"
                                  class="form-control"
                                  style="background-color: white; height: 100%"
                                  placeholder="Nome"
                                  name="name"
                                  [(ngModel)]="filterName"
                                  (keyup)="filter()"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  id="email"
                                  class="form-control"
                                  style="background-color: white; height: 100%"
                                  placeholder="Email"
                                  name="email"
                                  [(ngModel)]="filterEmail"
                                  (keyup)="filter()"
                                />
                              </th>
                              <th>Data Fatura</th>

                              <th>
                                <ng-select
                                  [items]="statusContato"
                                  name="statusContato"
                                  bindLabel="name"
                                  placeholder="Status Contato"
                                  id="statusContato"
                                  (change)="filter()"
                                  [(ngModel)]="filterStatusContato"
                                ></ng-select>
                              </th>
                              <th>
                                <ng-select
                                  [items]="tipoAssinatura"
                                  name="tipoAssinatura"
                                  bindLabel="name"
                                  placeholder="Tipo Assinatura"
                                  id="tipoAssinatura"
                                  (change)="filter()"
                                  [(ngModel)]="filterTipoAssinatura"
                                ></ng-select>
                              </th>
                              <th>Contato WhatsApp</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let usuario of listaNovosAssinantesFiltered
                                  | slice
                                    : (page - 1) * pageSize
                                    : (page - 1) * pageSize + pageSize
                              "
                            >
                              <td>{{ usuario.User.name }}</td>
                              <td>{{ usuario.User.email }}</td>
                              <td>
                                {{
                                  formatDateWithoutTime(
                                    usuario.expirationDate
                                  )
                                }}
                              </td>
                              <td [innerHTML]="checkRecoveryStatus(usuario)"></td>
                              <td>
                                {{
                                  usuario.paymentMethod == "bank_slip"
                                    ? "Boleto"
                                    : "Cartão"
                                }}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                                  *ngIf="usuario.User.phone"
                                  ngbTooltip="Whatsapp"
                                  (click)="openWhatsapp(usuario.User.phone)"
                                >
                                  <i class="fa fa-whatsapp"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                                  *ngIf="usuario.User.phone"
                                  ngbTooltip="Notificações Inteligentes"
                                  (click)="openNotificacoesInteligentes(usuario.User.phone)"
                                >
                                  <i class="fa fa-telegram"></i>
                                </button>
                              </td>
                              <td>
                                <div
                                  class="dropdown btn-group-sm mr-1 mb-1"
                                  ngbDropdown
                                >
                                  <button
                                    type="button"
                                    class="btn btn-outline-warning btn-sm dropdown-toggle"
                                    ngbDropdownToggle
                                  >
                                    <i class="ft-more-vertical"></i>
                                  </button>
                                  <div
                                    ngbDropdownMenu
                                    class="dropdown-menu"
                                    role="menu"
                                  >
                                    <a
                                      class="dropdown-item"
                                      (click)="openModal(dados, usuario, false)"
                                      >Visualizar</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      (click)="
                                        openAtualizaContato(
                                          usuario,
                                          ContactDetails
                                        )
                                      "
                                      >Atualizar Contato</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      [href]="
                                        'https://alia.iugu.com/receive/billing/' +
                                        usuario.subscriptionId
                                      "
                                      target="_blank"
                                      >Ver Assinatura</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      (click)="
                                        manageRecoveryFunnel(
                                          usuario.recoveryFunnelStatus,
                                          usuario
                                        )
                                      "
                                      >{{
                                        usuario.recoveryFunnelStatus &&
                                        usuario.recoveryFunnelStatus == true
                                          ? "Remover Funil Serasa"
                                          : "Adicionar Funil Serasa"
                                      }}</a
                                    >
                                    <a
                                    class="dropdown-item"
                                    (click)="
                                    openAddUserToFunnel(
                                        usuario,
                                        AddToFunnel
                                      )
                                    "
                                    >Adicionar ao Funil de Recuperação</a
                                  >
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="form-row" style="margin: 5px">
                      <div
                        class="d-flex justify-content-center"
                        style="margin-left: auto; margin-right: auto"
                      >
                        <ngb-pagination
                          style="margin-top: 10px"
                          *ngIf="listaNovosAssinantesFiltered.length > 0"
                          [(page)]="page"
                          [pageSize]="pageSize"
                          [maxSize]="2"
                          [rotate]="true"
                          [boundaryLinks]="true"
                          size="sm"
                          [collectionSize]="listaNovosAssinantesFiltered.length"
                        ></ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="listaNovosAssinantes.length == 0">
                <div class="col">
                  <div class="card-content">
                    <div class="card-body p-0 mt-2">
                      <ul class="list-group">
                        <li class="list-group-item">
                          <div class="media">
                            <div class="media-body">
                              <h5 class="mt-0">
                                Nenhuma assinatura nova com problemas :)
                              </h5>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>

          <ngb-tab>
            <ng-template ngbTabTitle>
              <div (click)="updateTitle(3)">
                <i class="ft-x-circle font-medium-5"></i>
              </div>
            </ng-template>
            <ng-template ngbTabContent>
              <div
                class="row mb-2 mt-2"
                *ngIf="listaCancelamentosIndiretos.length > 0"
              >
                <div class="col-12 col-lg-3">
                  <p>
                    {{ listaCancelamentosIndiretosFiltered.length }} Registros
                    encontrados
                  </p>
                </div>
                <div class="col-12 col-lg-9">
                  <div class="float-right d-none d-md-block mb-2">
                    <button
                      type="button"
                      class="btn btn-success"
                      (click)="exportarListaUsuarios(3)"
                    >
                      <i class="fa fa-file-archive-o"></i> Baixar Lista
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="row mb-2 mt-2"
                *ngIf="listaCancelamentosIndiretos.length > 0"
              >
                <div class="col">
                  <div class="card-content">
                    <div class="card-body p-0">
                      <div class="table text-center m-0">
                        <table
                          class="table-striped table-responsive"
                          style="width: 100%"
                        >
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="text"
                                  id="name"
                                  class="form-control"
                                  style="background-color: white; height: 100%"
                                  placeholder="Nome"
                                  name="name"
                                  [(ngModel)]="filterName"
                                  (keyup)="filter()"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  id="email"
                                  class="form-control"
                                  style="background-color: white; height: 100%"
                                  placeholder="Email"
                                  name="email"
                                  [(ngModel)]="filterEmail"
                                  (keyup)="filter()"
                                />
                              </th>
                              <th>Data Fatura</th>

                              <th>
                                <ng-select
                                  [items]="statusContato"
                                  name="statusContato"
                                  bindLabel="name"
                                  placeholder="Status Contato"
                                  id="statusContato"
                                  (change)="filter()"
                                  [(ngModel)]="filterStatusContato"
                                ></ng-select>
                              </th>
                              <th>
                                <ng-select
                                  [items]="tipoAssinatura"
                                  name="tipoAssinatura"
                                  bindLabel="name"
                                  placeholder="Tipo Assinatura"
                                  id="tipoAssinatura"
                                  (change)="filter()"
                                  [(ngModel)]="filterTipoAssinatura"
                                ></ng-select>
                              </th>
                              <th>Contato WhatsApp</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              *ngFor="
                                let usuario of listaCancelamentosIndiretosFiltered
                                  | slice
                                    : (page - 1) * pageSize
                                    : (page - 1) * pageSize + pageSize
                              "
                            >
                              <td>{{ usuario.User.name }}</td>
                              <td>{{ usuario.User.email }}</td>
                              <td>
                                {{
                                  formatDateWithoutTime(
                                    usuario.expirationDate
                                  )
                                }}
                              </td>
                              <td [innerHTML]="checkRecoveryStatus(usuario)"></td>
                              <td>
                                {{
                                  usuario.paymentMethod == "bank_slip"
                                    ? "Boleto"
                                    : "Cartão"
                                }}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                                  *ngIf="usuario.User.phone"
                                  ngbTooltip="Whatsapp"
                                  (click)="openWhatsapp(usuario.User.phone)"
                                >
                                  <i class="fa fa-whatsapp"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                                  *ngIf="usuario.User.phone"
                                  ngbTooltip="Notificações Inteligentes"
                                  (click)="openNotificacoesInteligentes(usuario.User.phone)"
                                >
                                  <i class="fa fa-telegram"></i>
                                </button>
                              </td>
                              <td>
                                <div
                                  class="dropdown btn-group-sm mr-1 mb-1"
                                  ngbDropdown
                                >
                                  <button
                                    type="button"
                                    class="btn btn-outline-warning btn-sm dropdown-toggle"
                                    ngbDropdownToggle
                                  >
                                    <i class="ft-more-vertical"></i>
                                  </button>
                                  <div
                                    ngbDropdownMenu
                                    class="dropdown-menu"
                                    role="menu"
                                  >
                                    <a
                                      class="dropdown-item"
                                      (click)="openModal(dados, usuario, false)"
                                      >Visualizar</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      (click)="
                                        openAtualizaContato(
                                          usuario,
                                          ContactDetails
                                        )
                                      "
                                      >Atualizar Contato</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      [href]="
                                        'https://alia.iugu.com/receive/billing/' +
                                        usuario.subscriptionId
                                      "
                                      target="_blank"
                                      >Ver Assinatura</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      (click)="
                                        manageRecoveryFunnel(
                                          usuario.recoveryFunnelStatus,
                                          usuario
                                        )
                                      "
                                      >{{
                                        usuario.recoveryFunnelStatus &&
                                        usuario.recoveryFunnelStatus == true
                                          ? "Remover Funil Serasa"
                                          : "Adicionar Funil Serasa"
                                      }}</a
                                    >
                                    <a
                                    class="dropdown-item"
                                    (click)="
                                    openAddUserToFunnel(
                                        usuario,
                                        AddToFunnel
                                      )
                                    "
                                    >Adicionar ao Funil de Recuperação</a
                                  >
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="form-row" style="margin: 5px">
                      <div
                        class="d-flex justify-content-center"
                        style="margin-left: auto; margin-right: auto"
                      >
                        <ngb-pagination
                          style="margin-top: 10px"
                          *ngIf="listaCancelamentosIndiretosFiltered.length > 0"
                          [(page)]="page"
                          [pageSize]="pageSize"
                          [maxSize]="2"
                          [rotate]="true"
                          [boundaryLinks]="true"
                          size="sm"
                          [collectionSize]="
                            listaCancelamentosIndiretosFiltered.length
                          "
                        ></ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="listaCancelamentosIndiretos.length == 0">
                <div class="col">
                  <div class="card-content">
                    <div class="card-body p-0 mt-2">
                      <ul class="list-group">
                        <li class="list-group-item">
                          <div class="media">
                            <div class="media-body">
                              <h5 class="mt-0">
                                Nenhuma assinatura nova com problemas :)
                              </h5>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab>
            <ng-template ngbTabTitle>
              <div (click)="updateTitle(4)">
                <i class="ft-user-check font-medium-5"></i>
              </div>
            </ng-template>
            <ng-template ngbTabContent>
              <div
                class="row mb-2 mt-2"
                *ngIf="listaAssinaturasAtivas.length > 0"
              >
                <div class="col-12 col-lg-3">
                  <p>
                    {{ listaAssinaturasAtivasFiltered.length }} Registros
                    encontrados
                  </p>
                </div>
                <div class="col-12 col-lg-9">
                  <div class="float-right d-none d-md-block mb-2">
                    <button
                      type="button"
                      class="btn btn-success"
                      (click)="exportarListaUsuarios(4)"
                    >
                      <i class="fa fa-file-archive-o"></i> Baixar Lista
                    </button>
                  </div>
                </div>
              </div>

              <div
                class="row mb-2 mt-2"
                *ngIf="listaAssinaturasAtivas.length > 0"
              >
                <div class="col">
                  <div class="card-content">
                    <div class="card-body p-0">
                      <div class="table text-center m-0">
                        <table
                          class="table-striped table-responsive"
                          style="width: 100%"
                        >
                          <thead>
                            <tr>
                              <th>
                                <input
                                  type="text"
                                  id="name"
                                  class="form-control"
                                  style="background-color: white; height: 100%"
                                  placeholder="Nome"
                                  name="name"
                                  [(ngModel)]="filterName"
                                  (keyup)="filter()"
                                />
                              </th>
                              <th>
                                <input
                                  type="text"
                                  id="email"
                                  class="form-control"
                                  style="background-color: white; height: 100%"
                                  placeholder="Email"
                                  name="email"
                                  [(ngModel)]="filterEmail"
                                  (keyup)="filter()"
                                />
                              </th>
                              <th>Data Fatura</th>

                              <th>
                                <ng-select
                                  [items]="statusContato"
                                  name="statusContato"
                                  bindLabel="name"
                                  placeholder="Status Contato"
                                  id="statusContato"
                                  (change)="filter()"
                                  [(ngModel)]="filterStatusContato"
                                ></ng-select>
                              </th>
                              <th>
                                <ng-select
                                  [items]="statusAssinatura"
                                  name="statusAssinatura"
                                  bindLabel="name"
                                  placeholder="Status Assinatura"
                                  id="statusAssinatura"
                                  (change)="filter()"
                                  [(ngModel)]="filterStatusAssinatura"
                                ></ng-select>
                              </th>
                              <th>
                                <ng-select
                                  [items]="tipoAssinatura"
                                  name="tipoAssinatura"
                                  bindLabel="name"
                                  placeholder="Tipo Assinatura"
                                  id="tipoAssinatura"
                                  (change)="filter()"
                                  [(ngModel)]="filterTipoAssinatura"
                                ></ng-select>
                              </th>
                              <th>Contatos</th>
                              <th>Ações</th>
                            </tr>
                          </thead>
                          <tbody style="width: 100%; overflow-y: auto">
                            <tr
                              *ngFor="
                                let usuario of listaAssinaturasAtivasFiltered
                                  | slice
                                    : (page - 1) * pageSize
                                    : (page - 1) * pageSize + pageSize
                              "
                            >
                              <td>{{ usuario.User.name }}</td>
                              <td>{{ usuario.User.email }}</td>
                              <td>
                                {{
                                  usuario.invoices.length > 0
                                    ? formatDateWithoutTime(
                                        usuario.expirationDate
                                      )
                                    : "N/D"
                                }}
                              </td>
                              <td [innerHTML]="checkRecoveryStatus(usuario)"></td>

                              <td>
                                {{
                                  usuario.statusAssinatura == "0"
                                    ? "Inativa"
                                    : "Ativa"
                                }}
                              </td>
                              <td>
                                {{
                                  usuario.paymentMethod == "bank_slip"
                                    ? "Boleto"
                                    : "Cartão"
                                }}
                              </td>
                              <td>
                                <button
                                  type="button"
                                  class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                                  *ngIf="usuario.User.phone"
                                  ngbTooltip="Whatsapp"
                                  (click)="openWhatsapp(usuario.User.phone)"
                                >
                                  <i class="fa fa-whatsapp"></i>
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-outline-warning border-left-warning mb-2 mb-md-0"
                                  *ngIf="usuario.User.phone"
                                  ngbTooltip="Notificações Inteligentes"
                                  (click)="openNotificacoesInteligentes(usuario.User.phone)"
                                >
                                  <i class="fa fa-telegram"></i>
                                </button>
                              </td>
                              <td>
                                <div
                                  class="dropdown btn-group-sm mr-1 mb-1"
                                  ngbDropdown
                                >
                                  <button
                                    type="button"
                                    class="btn btn-outline-warning btn-sm dropdown-toggle"
                                    ngbDropdownToggle
                                  >
                                    <i class="ft-more-vertical"></i>
                                  </button>
                                  <div
                                    ngbDropdownMenu
                                    class="dropdown-menu"
                                    role="menu"
                                  >
                                    <a
                                      class="dropdown-item"
                                      (click)="openModal(dados, usuario, false)"
                                      >Visualizar</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      (click)="
                                        openAtualizaContato(
                                          usuario,
                                          ContactDetails
                                        )
                                      "
                                      >Atualizar Contato</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      [href]="
                                        'https://alia.iugu.com/receive/billing/' +
                                        usuario.subscriptionId
                                      "
                                      target="_blank"
                                      >Ver Assinatura</a
                                    >
                                    <a
                                      class="dropdown-item"
                                      (click)="
                                        manageRecoveryFunnel(
                                          usuario.recoveryFunnelStatus,
                                          usuario
                                        )
                                      "
                                      >{{
                                        usuario.recoveryFunnelStatus &&
                                        usuario.recoveryFunnelStatus == true
                                          ? "Remover Funil Serasa"
                                          : "Adicionar Funil Serasa"
                                      }}</a
                                    >
                                    <a
                                    class="dropdown-item"
                                    (click)="
                                    openAddUserToFunnel(
                                        usuario,
                                        AddToFunnel
                                      )
                                    "
                                    >Adicionar ao Funil de Recuperação</a
                                  >
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="form-row" style="margin: 5px">
                      <div
                        class="d-flex justify-content-center"
                        style="margin-left: auto; margin-right: auto"
                      >
                        <ngb-pagination
                          style="margin-top: 10px"
                          *ngIf="listaAssinaturasAtivasFiltered.length > 0"
                          [(page)]="page"
                          [pageSize]="pageSize"
                          [maxSize]="2"
                          [rotate]="true"
                          [boundaryLinks]="true"
                          size="sm"
                          [collectionSize]="
                            listaAssinaturasAtivasFiltered.length
                          "
                        ></ngb-pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="listaAssinaturasAtivas.length == 0">
                <div class="col">
                  <div class="card-content">
                    <div class="card-body p-0 mt-2">
                      <ul class="list-group">
                        <li class="list-group-item">
                          <div class="media">
                            <div class="media-body">
                              <h5 class="mt-0">
                                Nenhuma assinatura encontrada :(
                              </h5>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div>
</div>

<ng-template #dados>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Informações</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li
            class="list-group-item"
            *ngIf="userView.User && userView.User.createdAt"
          >
            {{
              "Data de Cadastro : " +
                formatDateWithoutTime(userView.User.createdAt)
            }}
          </li>
          <li
            class="list-group-item"
            *ngIf="userView.User && userView.User.freeUntil"
          >
            {{
              "Gratuito até : " + formatDateWithoutTime(userView.User.freeUntil)
            }}
          </li>
          <li
            class="list-group-item"
            *ngIf="userView.User && userView.User.phone"
          >
            {{ "Telefone : " + userView.User.phone }}
          </li>
          <li
            class="list-group-item"
            *ngIf="userView.User && userView.User.lastLogin"
          >
            {{
              "Última acesso : " +
                formatDateWithoutTime(userView.User.lastLogin)
            }}
          </li>
          <li
            class="list-group-item"
            *ngIf="userView && userView.subscriptionId"
          >
            {{ "Assinatura Iugu : " + userView.subscriptionId }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #updateFreeUntil>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <label for="diasGratuitosI">Dias Gratuitos</label>
              <div class="input-group">
                <input
                  type="text"
                  id="diasGratuitosI"
                  placeholder="00/00/0000"
                  class="form-control"
                  name="diasGratuitosI"
                  [(ngModel)]="diasGratuitosInput"
                  ngbDatepicker
                  #diasGratuitosIn="ngbDatepicker"
                  [container]="'body'"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-secondary input-group-text"
                    (click)="diasGratuitosIn.toggle()"
                  >
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-lg btn-warning btn-block mb-md-0"
                (click)="atualizaDiasGratuitos()"
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #ContactDetails>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <label for="diasGratuitosI">Status Contato</label>
              <ng-select
                [items]="statusContato"
                name="statusAtual"
                bindLabel="name"
                placeholder="Status do Contato"
                id="statusAtual"
                [(ngModel)]="statusAtual"
              ></ng-select>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <label for="diasGratuitosI">Detalhe Contato</label>
              <textarea
                id="detalheContato"
                rows="2"
                class="form-control"
                name="detalheContato"
                maxlength="255"
                [(ngModel)]="detalheContato"
                #inputObse="ngModel"
                placeholder="Informe o retorno do cliente."
              ></textarea>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-lg btn-warning btn-block mb-md-0"
                [disabled]="
                  (statusAtual && statusAtual.id == '2' && !detalheContato) ||
                  !statusAtual
                "
                (click)="atualizaContato()"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #AddToFunnel>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <label for="diasGratuitosI">Adicionar ao Funil de Recuperação</label>
              <ng-select
                [items]="funnels"
                name="funnelToAdd"
                bindLabel="name"
                placeholder="Status do Contato"
                id="funnelToAdd"
                [(ngModel)]="funnelToAdd"
              ></ng-select>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-lg btn-warning btn-block mb-md-0"
                [disabled]="!funnelToAdd"
                (click)="addUserToRecoveryFunnel()"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ngx-spinner></ngx-spinner>
