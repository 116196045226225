<p class="paragrafo">Faturas</p>
<div *ngIf="faturas && faturas.length > 0" class="faturas">
  <div *ngFor="let invoice of paginatedFaturas" class="fatura">
    <div class="row">
      <div class="col-auto" style="display: flex; flex-direction: column">
        <span>Vencimento: {{ formatDate(invoice.due_date) }} </span>
        <span>{{ invoice.value }}</span>
      </div>
      <div class="col-auto">
        <span
          [ngClass]="{
            'tag-faturas': true,
            paid: invoice.status === 'paid',
            canceled: invoice.status === 'canceled',
            refunded: invoice.status === 'refunded',
            expired: invoice.status === 'expired'
          }"
        >
          {{ translateStatus(invoice.status) }}
        </span>
      </div>
    </div>
    <div class="row" style="justify-content: end">
      <button
        *ngIf="invoice.status === 'pending'"
        (click)="redirectToAssinaturaPersonal(invoice.id)"
        class="fatura-btn"
        title="Fatura"
      >
        Fatura
      </button>
      <button
        *ngIf="invoice.status === 'pending'"
        (click)="cobrarNovamente(invoice.id)"
        class="cobrar-btn"
        title="Cobrar novamente"
      >
        <i class="fa fa-rotate-left"></i>
      </button>
      <button
        *ngIf="invoice.status === 'pending'"
        (click)="cancelarFatura(invoice.id)"
        class="cancelar-btn"
        title="Cancelar Fatura"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="form-row" style="margin: 5px">
    <div
      class="d-flex justify-content-center"
      style="margin-left: auto; margin-right: auto"
    >
      <ngb-pagination
        style="margin-top: 10px"
        [(page)]="page"
        [pageSize]="pageSize"
        [maxSize]="2"
        [rotate]="true"
        [boundaryLinks]="true"
        size="sm"
        [collectionSize]="faturas.length"
        (pageChange)="onPageChange($event)"
      >
      </ngb-pagination>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
