import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from 'firebase';
import {BehaviorSubject, Observable} from 'rxjs';
import {AngularFireMessaging} from "@angular/fire/messaging";
import Me from "../../helpers/me";
import {take} from "rxjs/operators";
import App from "../../helpers/app";
import Api from "../../helpers/api";

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  currentMessage = new BehaviorSubject(null);
  apiCall = this.api.new().silent(true)

  userLogged = null
  constructor(public _firebaseAuth: AngularFireAuth, private me: Me, private app: App,
              private api: Api,
              public router: Router, private angularFireMessaging: AngularFireMessaging) {

    this.user = _firebaseAuth.authState;
    this.user.subscribe(
      (user) => {
        if (user) {
          this.userDetails = user;
        }
        else {
          this.userDetails = null;
        }
      }
    );

  }


  getPermission() {
    const self = this
    this.angularFireMessaging.getToken.subscribe(
      (token) => {
        self.me.get().pipe(take(1)).toPromise().then((user)=>{
          self.userLogged = user
          if((self.userLogged && !self.userLogged.UserFirebaseTokens) || (self.userLogged && !self.userLogged.UserFirebaseTokens.find(t=>t.token==token))){
            self.apiCall.post('web/firebase/token', {token:token} ).subscribe(async data => {
              if(data.success) await self.me.refresh().pipe(take(1)).toPromise();
            });
          }
        })
      },
      (err) => {
        this.requestPermission()
        //console.error('Unable to get permission to notify.', err);
      }
    );
  }
  requestPermission() {
    const self = this
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        self.me.get().pipe(take(1)).toPromise().then((user)=>{
          self.userLogged = user
          if((self.userLogged && !self.userLogged.UserFirebaseTokens) || !self.userLogged.UserFirebaseTokens.find(t=>t.token==token)){
            self.apiCall.post('web/firebase/token', {token:token} ).subscribe(async data => {
              if(data.success) await self.me.refresh().pipe(take(1)).toPromise();
            });
          }
        })
      },
      (err) => {

      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }
  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
    // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

    //uncomment above firebase auth code and remove this temp code
    return new Promise(function(resolve, reject) {
      setTimeout(function() {
        resolve(true);
      }, 1000);
    });

  }

  logout() {
    this._firebaseAuth.signOut();
    this.router.navigate(['YOUR_LOGOUT_URL']);
  }

  isAuthenticated() {
    return true;
  }
}
