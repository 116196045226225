<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem">Usuários</h1>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="float-right d-none d-md-block mb-2">
      <button
        type="button"
        class="btn btn-success"
        (click)="exportarListaUsuarios()"
      >
        <i class="fa fa-file-archive-o"></i> Baixar Lista
      </button>
    </div>
  </div>
</div>

<div class="row mb-2 mt-2" *ngIf="usuarios.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <div class="table text-center m-0">
          <table class="table-striped" style="width: 100%">
            <thead>
              <tr>
                <th></th>
                <th>
                  <input
                    type="text"
                    id="name"
                    class="form-control"
                    style="background-color: white; height: 100%"
                    placeholder="Nome"
                    name="name"
                    [(ngModel)]="filterName"
                    (keyup)="filter()"
                  />
                </th>
                <th>
                  <input
                    type="text"
                    id="email"
                    class="form-control"
                    style="background-color: white; height: 100%"
                    placeholder="Email"
                    name="email"
                    [(ngModel)]="filterEmail"
                    (keyup)="filter()"
                  />
                </th>
                <th>
                  <ng-select
                    [items]="tipos"
                    name="tipos"
                    bindLabel="name"
                    placeholder="Tipo"
                    id="tipos"
                    (change)="filter()"
                    [(ngModel)]="filterTipo"
                  ></ng-select>
                </th>
                <th>Dias Gratuitos Restantes</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let usuario of usuariosFiltered
                    | slice
                      : (page - 1) * pageSize
                      : (page - 1) * pageSize + pageSize
                "
              >
                <td>
                  <img
                    [src]="
                      usuario.photo_url
                        ? usuario.photo_url
                        : '../../assets/img/portrait/small/no-avatar.svg'
                    "
                    alt="product"
                    style="height: 75px"
                  />
                </td>
                <td>{{ usuario.name }}</td>
                <td>{{ usuario.email }}</td>
                <td>{{ usuario.type == "1" ? "Aluno" : "Personal" }}</td>
                <!--<td>{{usuario.status  ? 'Ativo' : 'Inativo'}}</td>-->
                <td>{{ getDiasGratuitos(usuario.freeUntil) }}</td>
                <td>
                  <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                    <button
                      type="button"
                      class="btn btn-outline-warning btn-sm dropdown-toggle"
                      ngbDropdownToggle
                    >
                      <i class="ft-more-vertical"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu" role="menu">
                      <a
                        class="dropdown-item"
                        (click)="openModal(dados, usuario, false)"
                        >Visualizar</a
                      >
                      <a
                        class="dropdown-item"
                        [href]="
                          'https://alia.iugu.com/contact/customers/' +
                          usuario.iugu
                        "
                        target="_blank"
                        >Acessar Pagamentos</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="
                          gerarSenhaProvisoria(userProvisoryPassword, usuario)
                        "
                        >Senha Provisória</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="
                          liberaAtualizacaoDiasGratuitos(
                            updateFreeUntil,
                            usuario
                          )
                        "
                        >Dias Gratuitos</a
                      >
                      <a
                        class="dropdown-item"
                        *ngIf="usuario.type == '1'"
                        (click)="toggleType(usuario)"
                        >Tornar Usuário Personal</a
                      >
                      <a
                        class="dropdown-item"
                        *ngIf="usuario.type == '0'"
                        (click)="deleteAssinatura(usuario)"
                        >Cancelar Assinatura</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="toggleStatus(usuario)"
                        >{{ usuario.status ? "Inativar" : "Ativar" }}</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-row" style="margin: 5px">
        <div
          class="d-flex justify-content-center"
          style="margin-left: auto; margin-right: auto"
        >
          <ngb-pagination
            style="margin-top: 10px"
            *ngIf="usuariosFiltered.length > 0"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="2"
            [rotate]="true"
            [boundaryLinks]="true"
            size="sm"
            [collectionSize]="usuariosFiltered.length"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="usuarios.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum usuário cadastrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #dados>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Informações</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item" *ngIf="userView && userView.createdAt">
            {{
              "Data de Cadastro : " + formatDateWithoutTime(userView.createdAt)
            }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.freeUntil">
            {{ "Gratuito até : " + formatDateWithoutTime(userView.freeUntil) }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.name">
            {{ userView && userView.name ? "Nome: " + userView.name : "" }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.email">
            {{ userView && userView.email ? "Email: " + userView.email : "" }}
          </li>
          <li class="list-group-item">
            {{
              userView && userView.emailValidated
                ? "status email: Confirmado"
                : "status email: Não Confirmado"
            }}
          </li>
          <li class="list-group-item">
            {{
              userView && userView.status
                ? "Status : Ativo"
                : "Status : Inativo"
            }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.gender">
            {{
              userView.gender == "2" ? "Sexo : Feminino" : "Sexo : Masculino"
            }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.phone">
            {{ "Telefone : " + userView.phone }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.lastLogin">
            {{ "Última acesso : " + formatDate(userView.lastLogin) }}
          </li>
          <li class="list-group-item" *ngIf="userView && userView.lastVersion">
            {{ "Última versão app : " + userView.lastVersion }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #userProvisoryPassword>
  <div class="card" *ngIf="userView && userView.provisoryPassword">
    <div class="card-header">
      <h4 class="card-title">Senha Provisória</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div class="form-row justify-content-center mb-2 mt-2">
          <div class="col text-center">
            <input
              type="text"
              id="aaaa"
              class="form-control text-center"
              disabled="true"
              [value]="userView.provisoryPassword"
              name="aaaa"
              required
              aria-describedby="basic-addon1"
            />
            <small class="form-text text-muted warning"
              >Senha provisória copiada!</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #updateFreeUntil>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <label for="diasGratuitosI">Dias Gratuitos</label>
              <div class="input-group">
                <input
                  type="text"
                  id="diasGratuitosI"
                  placeholder="00/00/0000"
                  class="form-control"
                  name="diasGratuitosI"
                  [(ngModel)]="diasGratuitosInput"
                  ngbDatepicker
                  #diasGratuitosIn="ngbDatepicker"
                  [container]="'body'"
                />
                <div class="input-group-append">
                  <button
                    type="button"
                    class="btn btn-secondary input-group-text"
                    (click)="diasGratuitosIn.toggle()"
                  >
                    <i class="fa fa-calendar"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-lg btn-warning btn-block mb-md-0"
                (click)="atualizaDiasGratuitos()"
              >
                Atualizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ngx-spinner></ngx-spinner>
