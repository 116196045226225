<div class="card m-0">
  <div class="card-header row justify-content-between">
    <h4 class="card-title">Tutoriais</h4>
    <button class="btn btn-sm close-button" (click)="closeModal()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
  </div>
  <div class="form-row" style="margin: 5px">
    <ul class="list-group mb-2" style="width: 100%">
      <li class="list-group-item" *ngFor="let tutorial of tutorials">
        <div class="media">
          <span class="dot align-middle mr-2">{{ tutorial.index }}</span>
          <div class="media-body">
            <h5 class="mt-0">{{ tutorial.title }}</h5>
            <span>{{ tutorial.description }}</span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="viewMidia(tutorial.url)"
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="viewMidia(tutorial.url)"
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<!-- Modal for playing the video -->
<ng-template #videoModal>
  <div class="modal-header">
    <h5 class="modal-title">Reproduzir Tutorial</h5>
    <button type="button" class="close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <video width="100%" controls>
      <source [src]="urlToPlay" type="video/mp4" />
      Seu navegador não suporta a tag de vídeo.
    </video>
  </div>
</ng-template>
