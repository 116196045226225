export const speedSwitch = (speed: string, returnAs: "pt" | "en") => {
  if (returnAs === "pt") {
    switch (speed) {
      case "slow":
        return "Lento";
      case "medium":
        return "Moderado";
      case "fast":
        return "Rápido";
      case "isometric":
        return "Isométrico";
      default:
        return "";
    }
  } else {
    switch (speed) {
      case "Lento":
        return "slow";
      case "Médio":
        return "medium";
      case "Moderado":
        return "medium";
      case "Rápido":
        return "fast";
      case "Isométrico":
        return "isometric";
      default:
        return "";
    }
  }
};
