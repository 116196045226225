<div class="card my-0">
  <div class="card-header">
    <h4 class="card-title">Copiar Link de Checkout</h4>
  </div>
  <div class="card-body">
    <div class="col text-center">
      <input
        type="text"
        disabled
        [value]="checkoutUrl"
        class="form-control text-center"
      />
      <button
        type="button"
        class="btn btn-warning border-right-warning mb-2 mt-2"
        (click)="copyToClipboard()"
      >
        Copiar Link de Checkout
      </button>
    </div>
  </div>
</div>
