<div class="container">
  <ng-container *ngIf="invoice$ | async as invoice">
    <!-- Exibição de Status -->
    <div *ngIf="!errorMessage">
      <div class="status">
        <div [ngClass]="getStatusClass(invoice.status)">
          <span *ngIf="invoice.status === 'pending'">Fatura Pendente</span>
          <span *ngIf="invoice.status === 'paid'">Fatura Paga</span>
          <span *ngIf="invoice.status === 'canceled'">Fatura Cancelada</span>
          <span *ngIf="invoice.status === 'refunded'">Fatura Reembolsada</span>
        </div>
      </div>
    </div>
  
    <!-- Exibição de Erro -->
    <div *ngIf="errorMessage" class="error-container">
      <label class="error-message">
        {{ errorMessage }}
      </label>
    </div>

    <!-- Seção Cinza com Detalhes -->
    <div class="gray-section">
      <div class="content">
        <div class="left-section">
          <div>
            <div>
              <span><b>Cliente:</b></span><br />
              <span>{{ invoice.payerName }}</span>
            </div>
          </div>
        </div>
        <div class="right-section">
          <div>
            <div>
              <b>Cedente: </b>
              <span>{{ invoice.accoutName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Detalhes da Fatura -->
    <div class="details-container">
      <div class="details-section">
        <div class="details-title"><b style="font-size:1.2em">Detalhes da Fatura</b></div>
        
        <div class="details-row">
          <div class="details-label">Vencimento</div>
          <div class="details-value">{{ formatDateWithoutTime(invoice.dueDate) }}</div>
        </div>
    
        <table class="details-table">
          <thead>
            <tr>
              <th class="details-label">Descrição</th>
              <th class="details-value">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of invoice.items">
              <td class="details-label">{{ item.description }}</td>
              <td class="details-value">{{ item.price }}</td>
            </tr>
          </tbody>
        </table>
    
        <div class="details-row">
          <div class="details-label">Subtotal</div>
          <div class="details-value">{{ invoice.total }}</div>
        </div>
        
        <div class="details-row total">
          <div class="details-label"><b>Total</b></div>
          <div class="details-value"><b>{{ invoice.total }}</b></div>
        </div>
      </div>
    </div>
    
    <!-- Componentes de Pagamento -->
    <div *ngIf="invoice.status === 'pending'">
      <app-payment-pix-personal
        *ngIf="invoice.payableWith === 'pix'"
        [invoice]="invoice"
      ></app-payment-pix-personal>
      <app-payment-ticket-personal
        *ngIf="invoice.payableWith === 'bank_slip'"
        [invoice]="invoice"
        [bankSlip]="invoice.bankSlip"
      ></app-payment-ticket-personal>
      <app-cred-card-payment-customer
        *ngIf="invoice.payableWith === 'credit_card'"
        [invoice]="invoice"
      ></app-cred-card-payment-customer>     
    </div>
  </ng-container>
</div>

