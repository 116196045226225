<p class="paragrafo">Assinaturas</p>
<div class="assinaturas">
  <div *ngFor="let assinatura of assinaturas" class="assinatura-item">
    <div class="assinatura-info">
      <input type="text" class="info-item" [(ngModel)]="assinatura.plano" readonly />
      <input type="text" class="info-item" [value]="(assinatura.priceCents / 100) | currency:'BRL'" readonly />
      <input type="text" class="info-item" [ngClass]="{ 'ativa': assinatura.planoAtivo, 'inativa': !assinatura.planoAtivo }"
             [value]="assinatura.planoAtivo ? 'Ativa' : 'Inativa'" readonly/>
    </div>    
    <div class="assinatura-acoes">
      <button *ngIf="assinatura.planoAtivo" (click)="reactivateSubscription(assinatura)">
        <i class="fas fa-ban"></i> Suspender Plano
      </button>
      <button *ngIf="!assinatura.planoAtivo">
        <i class="fa fa-rotate-left"></i> Reativar Assinatura
      </button>
    </div>
  </div>
  <button class="nova-assinatura">+ Nova Assinatura</button>
</div>

