<section class="page-user-profile">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="user-profile-images">
          <!-- user timeline image -->
          <img
            src="../../../../assets/img/banner/capa2.jpg"
            class="img-fluid rounded-top user-timeline-image"
            style="width: 100%"
            alt="User Timeline Image"
          />
          <!-- user profile image -->
          <img
            [src]="
              user && user.photo_url
                ? user.photo_url
                : '../../../../assets/img/portrait/small/no-avatar.svg'
            "
            class="user-profile-image rounded"
            alt="User Profile Image"
            height="140"
            width="140"
          />
        </div>
        <div class="user-profile-text">
          <h4 class="profile-text-color mb-0" style="font-weight: 600">
            {{ user ? user.name : "" }}
          </h4>
          <small>{{ user ? user.email : "" }}</small>
        </div>
        <!-- user profile body start -->
        <div class="card-content">
          <div class="card-body">
            <div
              class="user-profile-buttons d-flex justify-content-center justify-content-sm-start"
            >
              <ul ngbNav [(activeId)]="activeId" #nav="ngbNav" class="nav-tabs">
                <li ngbNavItem [ngbNavItem]="1">
                  <a ngbNavLink
                    ><button
                      type="button"
                      class="btn btn-block btn-warning mb-2 mb-md-0"
                    >
                      Informações de Pagamento
                    </button></a
                  >
                  <ng-template ngbNavContent>
                    <div class="row mt-2">
                      <div class="col-sm-12">
                        <h5 class="text-bold-300">
                          Está com dúvida sobre seu plano, veja o video abaixo
                        </h5>
                        <button
                          class="btn btn-warning border-right-warning mb-2 pulsingButton"
                          (click)="OpenModalXl(contentVideo)"
                        >
                          <i class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
                        </button>
                      </div>
                      <div class="col">
                        <h3 class="text-bold-300">
                          {{
                            !assinatura || assinatura.suspended
                              ? "Nenhum plano ativo"
                              : "Plano Atual"
                          }}
                        </h3>
                      </div>
                      <div
                        class="col"
                        *ngIf="!assinatura || assinatura.suspended"
                      >
                        <div class="float-right d-none d-md-block" role="group">
                          <!--<button type="button" class="btn btn-lg btn-warning  mb-md-0 "  (click)="openModal(assinaturaModal, true)">Selecionar Plano</button>-->
                          <button
                            type="button"
                            class="btn btn-lg btn-warning mb-md-0"
                            (click)="goCheckoutGeral()"
                          >
                            Assinar
                          </button>
                        </div>
                        <div
                          class="btn-group btn-group-lg d-md-none"
                          role="group"
                        >
                          <button
                            type="button"
                            class="btn btn-lg btn-warning mb-md-0"
                            (click)="goCheckoutGeral()"
                          >
                            <i class="fa fa-plus"></i>
                          </button>
                          <!--<button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openModal(assinaturaModal, true)" ><i class="fa fa-plus"></i></button>-->
                        </div>
                      </div>
                    </div>
                    <div
                      class="row"
                      *ngIf="assinatura && !assinatura.suspended"
                    >
                      <div class="col">
                        <div class="card-content">
                          <div class="card-body pr-0 pl-0">
                            <ul class="list-group p-0">
                              <li class="list-group-item pt-3 pb-3">
                                <form #planoForm="ngForm" novalidate>
                                  <div class="form-row">
                                    <div class="col-md-3 col-12">
                                      <div class="form-group">
                                        <div class="controls">
                                          <input
                                            type="text"
                                            id="planoativo"
                                            class="form-control"
                                            disabled="true"
                                            [(ngModel)]="assinatura.plan_name"
                                            name="planoativo"
                                            required
                                            aria-invalid="false"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-2 col-12">
                                      <div class="form-group">
                                        <div class="controls">
                                          <input
                                            type="text"
                                            id="valorFormatado"
                                            class="form-control"
                                            disabled="true"
                                            [(ngModel)]="
                                              assinatura.valorFormatado
                                            "
                                            name="valorFormatado"
                                            required
                                            aria-invalid="false"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-3 col-12">
                                      <div class="form-group">
                                        <div class="controls">
                                          <input
                                            type="text"
                                            id="active"
                                            class="form-control"
                                            disabled="true"
                                            [(ngModel)]="assinatura.status"
                                            name="active"
                                            required
                                            aria-invalid="false"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-4 col-12">
                                      <div class="form-group">
                                        <div class="controls">
                                          <div class="d-md-none" role="group">
                                            <div
                                              class="btn-group btn-group-lg float-right"
                                              role="group"
                                            >
                                              <button
                                                type="button"
                                                class="btn btn-lg btn-outline-warning mb-md-0"
                                                (click)="cancelarWhatsApp()"
                                              >
                                                <i class="fa fa-trash"></i>
                                              </button>
                                              <!--<button type="button" class="btn btn-lg btn-outline-warning  mb-md-0" *ngIf="planosUpgrade && planosUpgrade.length>0" (click)="openModal(upgradeModal, false)"><i class="fa fa-plus"></i></button>-->
                                            </div>
                                          </div>
                                          <div
                                            class="btn-group btn-group-lg d-none d-md-block"
                                            role="group"
                                          >
                                            <div
                                              class="btn-group btn-group-lg float-right"
                                              role="group"
                                            >
                                              <button
                                                type="button"
                                                class="btn btn-lg btn-outline-warning mb-md-0"
                                                (click)="cancelarWhatsApp()"
                                              >
                                                Cancelar Plano
                                              </button>
                                              <!--<button type="button" class="btn btn-lg btn-outline-warning  mb-md-0" *ngIf="planosUpgrade && planosUpgrade.length>0" (click)="openModal(upgradeModal, false)">Atualizar Plano</button>-->
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="!assinatura">
                      <div class="col">
                        <div class="card-content">
                          <div class="card-body pr-0 pl-0">
                            <ul class="list-group p-0">
                              <li class="list-group-item">
                                <div class="media">
                                  <div class="media-body">
                                    <h5 class="mt-0">
                                      Nenhuma assinatura ativa.
                                    </h5>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <h3 class="text-bold-300">Cartões Cadastrados</h3>
                      </div>
                      <div class="col">
                        <div class="float-right d-none d-md-block" role="group">
                          <button
                            type="button"
                            class="btn btn-lg btn-warning mb-md-0"
                            (click)="openModal(cadastroCartao, false)"
                          >
                            <i class="fa fa-credit-card mr-2"></i>Trocar Cartão
                          </button>
                        </div>
                        <div
                          class="btn-group btn-group-lg d-md-none"
                          role="group"
                        >
                          <button
                            type="button"
                            class="btn btn-lg btn-warning mb-md-0"
                            (click)="openModal(cadastroCartao, false)"
                          >
                            <i class="fa fa-credit-card"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="cartoes.length > 0">
                      <div class="col">
                        <div class="card-content">
                          <div class="card-body pr-0 pl-0">
                            <ul class="list-group">
                              <li
                                *ngFor="
                                  let cartao of cartoes
                                    | slice
                                      : (pageTr - 1) * pageSize
                                      : (pageTr - 1) * pageSize + pageSize
                                "
                                class="list-group-item"
                              >
                                <div class="media">
                                  <div
                                    [className]="
                                      cartoes &&
                                      cartoes.length > 1 &&
                                      (this.assinatura.plan_identifier ===
                                        'plano_1' ||
                                        this.assinatura.plan_identifier ===
                                          'plano_2' ||
                                        this.assinatura.plan_identifier ===
                                          'plano_3')
                                        ? 'media-body d-flex align-items-center justify-content-between'
                                        : 'media-body d-flex align-items-center justify-content-start'
                                    "
                                  >
                                    <div>
                                      <div
                                        class="d-flex justify-content-start align-items-center mb-2"
                                      >
                                        <h5 class="m-0">
                                          {{
                                            cartao.data ? cartao.data.brand : ""
                                          }}
                                        </h5>
                                        <span
                                          *ngIf="cartao.id === defaultCreditCardId"
                                          class="badge badge-warning mx-2"
                                          >Principal</span
                                        >
                                      </div>
                                      <span>
                                        {{
                                          cartao.data
                                            ? cartao.data.display_number
                                            : ""
                                        }}
                                      </span>
                                    </div>
                                    <div
                                      *ngIf="
                                        cartoes &&
                                        cartoes.length > 1 &&
                                        (this.assinatura.plan_identifier ===
                                          'plano_1' ||
                                          this.assinatura.plan_identifier ===
                                            'plano_2' ||
                                          this.assinatura.plan_identifier ===
                                            'plano_3')
                                      "
                                      class="d-flex justify-content-center align-items-end"
                                    >
                                      <button
                                        *ngIf="cartoes.indexOf(cartao) !== 0"
                                        type="button"
                                        (click)="changeMainCreditCard(cartao)"
                                        class="btn btn-primary text-white"
                                      >
                                        Tornar Principal
                                        <i class="fa fa-star-o ml-1"></i>
                                      </button>
                                      <button
                                        type="button"
                                        (click)="deleteCreditCard(cartao)"
                                        class="btn btn-danger ml-2"
                                        [disabled]="
                                          cartoes.indexOf(cartao) === 0
                                        "
                                        [ngClass]="
                                          cartoes.indexOf(cartao) === 0
                                            ? 'cursor-default'
                                            : ''
                                        "
                                      >
                                        Excluir <i class="fa fa-trash ml-1"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="cartoes.length > 0">
                      <div class="col">
                        <div class="d-flex justify-content-center">
                          <ngb-pagination
                            [(page)]="pageTr"
                            [pageSize]="pageSize"
                            [maxSize]="2"
                            [rotate]="true"
                            [boundaryLinks]="true"
                            size="sm"
                            [collectionSize]="cartoes.length"
                          ></ngb-pagination>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="cartoes.length == 0">
                      <div class="col">
                        <div class="card-content">
                          <div class="card-body pr-0 pl-0">
                            <ul class="list-group p-0">
                              <li class="list-group-item">
                                <div class="media">
                                  <div class="media-body">
                                    <h5>Nenhum cartão cadastrado.</h5>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col">
                        <h3 class="text-bold-300">Faturas</h3>
                      </div>
                    </div>
                    <div class="row" *ngIf="faturas.length > 0">
                      <div class="col">
                        <div class="card-content">
                          <div class="card-body pr-0 pl-0">
                            <ul class="list-group">
                              <li
                                *ngFor="
                                  let fatura of faturas
                                    | slice
                                      : (pageTrFatura - 1) * pageSizeFatura
                                      : (pageTrFatura - 1) * pageSizeFatura +
                                          pageSizeFatura
                                "
                                class="list-group-item"
                              >
                                <div class="media">
                                  <div class="media-body">
                                    <h5 class="mt-0">
                                      {{
                                        "Vencimento : " +
                                          getDataVencimentoFatura(fatura) +
                                          " - " +
                                          getStatusFatura(fatura)
                                      }}
                                    </h5>
                                    <span>{{ fatura.total }}</span>
                                  </div>
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary btn-icon mr-2"
                                    (click)="openFatura(fatura)"
                                  >
                                    <i class="fa fa-external-link"></i>
                                  </button>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="faturas.length > 0">
                      <div class="col">
                        <div class="d-flex justify-content-center">
                          <ngb-pagination
                            [(page)]="pageTrFatura"
                            [pageSize]="pageSizeFatura"
                            [maxSize]="2"
                            [rotate]="true"
                            [boundaryLinks]="true"
                            size="sm"
                            [collectionSize]="faturas.length"
                          ></ngb-pagination>
                        </div>
                      </div>
                    </div>
                    <div class="row" *ngIf="faturas.length == 0">
                      <div class="col">
                        <div class="card-content">
                          <div class="card-body pr-0 pl-0">
                            <ul class="list-group p-0">
                              <li class="list-group-item">
                                <div class="media">
                                  <div class="media-body">
                                    <h5>Nenhuma fatura existente.</h5>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
            </div>
          </div>
          <!-- user profile body ends -->
        </div>
      </div>
    </div>
  </div>
</section>

<div class="tab-content">
  <div [ngbNavOutlet]="nav"></div>
</div>
<ngx-spinner></ngx-spinner>

<ng-template #upgradeModal let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title text-center">Escolha seu Plano</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-4">
                <label for="plano">Planos</label>
                <ng-select
                  [notFoundText]="'Nenhum plano encontrado'"
                  [items]="planosUpgradeFiltered"
                  bindLabel="name"
                  name="model"
                  (change)="onChangePlanoUpgrade()"
                  placeholder="Selecionar Plano"
                  id="plano"
                  [(ngModel)]="planoUpgradeEscolhido"
                >
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                  >
                    <span>{{ item.name }}</span>
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group mb-4">
                    <label for="tipopagamentou">Forma de pagamento</label>
                    <ng-select
                      [items]="formasFiltered"
                      bindLabel="name"
                      name="tipopagamentou"
                      placeholder="Selecionar Tipo"
                      id="tipopagamentou"
                      (change)="onChangeFormaUpgrade()"
                      [(ngModel)]="formaEscolhida"
                    ></ng-select>
                  </div>
                </div>
              </div>
              <h6
                class="text-center mb-4"
                *ngIf="planoUpgradeEscolhido && formaEscolhida"
              >
                {{
                  planoUpgradeEscolhido
                    ? planoUpgradeEscolhido.name +
                      " - " +
                      formatPreco(planoUpgradeEscolhido)
                    : ""
                }}
              </h6>

              <form
                id="boleto-form"
                style="margin-left: auto; margin-right: auto"
                *ngIf="formaEscolhida && formaEscolhida.id == 'bank_slip'"
              >
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <div class="controls">
                        <label for="cpf_cnpj">CPF/CNPJ</label>
                        <input
                          type="text"
                          id="cpf_cnpj"
                          class="form-control"
                          [(ngModel)]="endereco.cpf_cnpj"
                          name="cpf_cnpj"
                          mask="000.000.000-00||00.000.000/0000-00"
                          placeholder="CPF/CNPJ"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <div class="controls">
                        <label for="cep">CEP</label>
                        <input
                          type="text"
                          id="cep"
                          class="form-control"
                          [(ngModel)]="endereco.zip_code"
                          name="nome"
                          mask="00000-000"
                          placeholder="Cep"
                          (change)="consultaCep()"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-8 col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label for="street">Rua/Avenida</label>
                        <input
                          type="text"
                          id="street"
                          class="form-control"
                          [(ngModel)]="endereco.street"
                          name="street"
                          placeholder="Rua/Avenida"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label for="number">Número</label>
                        <input
                          type="text"
                          id="number"
                          class="form-control"
                          [(ngModel)]="endereco.number"
                          name="number"
                          placeholder="Número"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col">
                    <div class="form-group">
                      <div class="controls">
                        <label for="district">Bairro</label>
                        <input
                          type="text"
                          id="district"
                          class="form-control"
                          [(ngModel)]="endereco.district"
                          name="district"
                          placeholder="Bairro"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-8 col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label for="city">Cidade</label>
                        <input
                          type="text"
                          id="city"
                          class="form-control"
                          [(ngModel)]="endereco.city"
                          name="city"
                          placeholder="Cidade"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-12">
                    <div class="form-group">
                      <div class="controls">
                        <label for="state">Estado</label>
                        <ng-select
                          [items]="estados"
                          name="state"
                          placeholder="Estado"
                          id="state"
                          [(ngModel)]="endereco.state"
                        ></ng-select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div
                class="form-row mb-4"
                *ngIf="
                  formaEscolhida &&
                  formaEscolhida.id == 'credit_card' &&
                  cartoes.length == 0
                "
              >
                <form
                  id="payment-form"
                  style="
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                  "
                  target="_blank"
                  [ngClass]="getCreditCard()"
                  method="POST"
                >
                  <div class="usable-creditcard-form">
                    <div class="specifcwrapper">
                      <div class="input-group nmb_a">
                        <div class="icon ccic-brand"></div>
                        <input
                          autocomplete="off"
                          class="credit_card_number"
                          data-iugu="number"
                          placeholder="Número do Cartão"
                          type="text"
                          [(ngModel)]="novoCartao.number"
                          name="ccNumber"
                          ccNumber
                        />
                      </div>
                      <div class="input-group nmb_b">
                        <div class="icon ccic-cvv"></div>
                        <input
                          autocomplete="off"
                          class="credit_card_cvv"
                          data-iugu="verification_value"
                          placeholder="CVV"
                          maxlength="4"
                          type="text"
                          [(ngModel)]="novoCartao.cvv"
                          name="cvv"
                          ccCVC
                        />
                      </div>
                      <div class="input-group nmb_c">
                        <div class="icon ccic-name"></div>
                        <input
                          class="credit_card_name"
                          data-iugu="full_name"
                          placeholder="Titular do Cartão"
                          type="text"
                          [(ngModel)]="novoCartao.name"
                          name="name"
                        />
                      </div>
                      <div class="input-group nmb_d">
                        <div class="icon ccic-exp"></div>
                        <input
                          autocomplete="off"
                          class="credit_card_expiration"
                          data-iugu="expiration"
                          placeholder="MM/AA"
                          mask="00/00"
                          type="text"
                          [(ngModel)]="novoCartao.date"
                          name="date"
                          ccExp
                        />
                      </div>
                    </div>
                    <p *ngIf="erroCartao" class="text-muted text-danger">
                      {{ erroCartao }}
                    </p>
                  </div>
                </form>
              </div>
              <div class="form-row mt-5">
                <div class="col text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="
                      formaEscolhida &&
                      formaEscolhida.id == 'credit_card' &&
                      cartoes.length == 0
                        ? cadastrarCartaoEAtualizar(modal)
                        : atualizarAssinatura(modal)
                    "
                    [disabled]="
                      !planoUpgradeEscolhido ||
                      !formaEscolhida ||
                      (formaEscolhida.id == 'bank_slip' &&
                        (!endereco.number ||
                          !endereco.zip_code ||
                          !endereco.state ||
                          !endereco.street ||
                          !endereco.district ||
                          !endereco.city ||
                          !endereco.cpf_cnpj)) ||
                      (formaEscolhida &&
                        formaEscolhida.id == 'credit_card' &&
                        cartoes.length == 0 &&
                        (!novoCartao.number ||
                          !novoCartao.cvv ||
                          !novoCartao.date ||
                          !novoCartao.name))
                    "
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #assinaturaModal let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title text-center">Escolha seu Plano</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col">
              <div class="form-group mb-4">
                <label for="aluno">Planos</label>
                <ng-select
                  [notFoundText]="'Nenhum plano encontrado'"
                  [items]="planosFiltered"
                  bindLabel="name"
                  name="planoEscolhido"
                  (change)="onChangePlano()"
                  placeholder="Selecionar Plano"
                  id="planoEscolhido"
                  [(ngModel)]="planoEscolhido"
                >
                  <ng-template
                    ng-option-tmp
                    let-item="item"
                    let-index="index"
                    let-search="searchTerm"
                  >
                    <span
                      >{{ item.name
                      }}<small class="text-muted float-right">{{
                        formatPreco(item)
                      }}</small></span
                    >
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col">
              <div class="form-group mb-4">
                <label for="tipopagamento">Forma de pagamento</label>
                <ng-select
                  [items]="formasFiltered"
                  bindLabel="name"
                  name="tipopagamento"
                  placeholder="Selecionar Tipo"
                  id="tipopagamento"
                  (change)="onChangeForma()"
                  [(ngModel)]="formaEscolhida"
                ></ng-select>
              </div>
            </div>
          </div>
          <h6 class="text-center mb-4" *ngIf="planoEscolhido && formaEscolhida">
            {{
              planoEscolhido
                ? planoEscolhido.name + " - " + formatPreco(planoEscolhido)
                : ""
            }}
          </h6>
          <form
            id="boleto-form"
            style="margin-left: auto; margin-right: auto"
            *ngIf="formaEscolhida && formaEscolhida.id == 'bank_slip'"
          >
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cpf_cnpj">CPF/CNPJ</label>
                    <input
                      type="text"
                      id="cpf_cnpj"
                      class="form-control"
                      [(ngModel)]="endereco.cpf_cnpj"
                      name="cpf_cnpj"
                      mask="000.000.000-00||00.000.000/0000-00"
                      placeholder="CPF/CNPJ"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="cep">CEP</label>
                    <input
                      type="text"
                      id="cep"
                      class="form-control"
                      [(ngModel)]="endereco.zip_code"
                      name="nome"
                      mask="00000-000"
                      placeholder="Cep"
                      (change)="consultaCep()"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-8 col-12">
                <div class="form-group">
                  <div class="controls">
                    <label for="street">Rua/Avenida</label>
                    <input
                      type="text"
                      id="street"
                      class="form-control"
                      [(ngModel)]="endereco.street"
                      name="street"
                      placeholder="Rua/Avenida"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-group">
                  <div class="controls">
                    <label for="number">Número</label>
                    <input
                      type="text"
                      id="number"
                      class="form-control"
                      [(ngModel)]="endereco.number"
                      name="number"
                      placeholder="Número"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="district">Bairro</label>
                    <input
                      type="text"
                      id="district"
                      class="form-control"
                      [(ngModel)]="endereco.district"
                      name="district"
                      placeholder="Bairro"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-8 col-12">
                <div class="form-group">
                  <div class="controls">
                    <label for="city">Cidade</label>
                    <input
                      type="text"
                      id="city"
                      class="form-control"
                      [(ngModel)]="endereco.city"
                      name="city"
                      placeholder="Cidade"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4 col-12">
                <div class="form-group">
                  <div class="controls">
                    <label for="state">Estado</label>
                    <ng-select
                      [items]="estados"
                      name="state"
                      placeholder="Estado"
                      id="state"
                      [(ngModel)]="endereco.state"
                    ></ng-select>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div
            class="form-row"
            *ngIf="
              formaEscolhida &&
              formaEscolhida.id == 'credit_card' &&
              cartoes.length == 0
            "
          >
            <form
              id="payment-form"
              style="text-align: center; margin-left: auto; margin-right: auto"
              target="_blank"
              [ngClass]="getCreditCard()"
              method="POST"
            >
              <div class="usable-creditcard-form">
                <div class="specifcwrapper">
                  <div class="input-group nmb_a">
                    <div class="icon ccic-brand"></div>
                    <input
                      autocomplete="off"
                      class="credit_card_number"
                      data-iugu="number"
                      placeholder="Número do Cartão"
                      type="text"
                      [(ngModel)]="novoCartao.number"
                      name="ccNumber"
                      ccNumber
                    />
                  </div>
                  <div class="input-group nmb_b">
                    <div class="icon ccic-cvv"></div>
                    <input
                      autocomplete="off"
                      class="credit_card_cvv"
                      data-iugu="verification_value"
                      placeholder="CVV"
                      maxlength="4"
                      type="text"
                      [(ngModel)]="novoCartao.cvv"
                      name="cvv"
                      ccCVC
                    />
                  </div>
                  <div class="input-group nmb_c">
                    <div class="icon ccic-name"></div>
                    <input
                      class="credit_card_name"
                      data-iugu="full_name"
                      placeholder="Titular do Cartão"
                      type="text"
                      [(ngModel)]="novoCartao.name"
                      name="name"
                    />
                  </div>
                  <div class="input-group nmb_d">
                    <div class="icon ccic-exp"></div>
                    <input
                      autocomplete="off"
                      class="credit_card_expiration"
                      data-iugu="expiration"
                      placeholder="MM/AA"
                      mask="00/00"
                      type="text"
                      [(ngModel)]="novoCartao.date"
                      name="date"
                      ccExp
                    />
                  </div>
                </div>
                <p *ngIf="erroCartao" class="text-muted text-danger">
                  {{ erroCartao }}
                </p>
              </div>
            </form>
          </div>
          <div class="form-row">
            <br />
            <div class="col">
              <div class="form-row mt-5">
                <div class="col text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-warning btn-block"
                    (click)="
                      formaEscolhida &&
                      formaEscolhida.id == 'credit_card' &&
                      cartoes.length == 0
                        ? cadastrarCartaoEAssinar(modal)
                        : assinar(modal)
                    "
                    [disabled]="
                      !planoEscolhido ||
                      !formaEscolhida ||
                      (formaEscolhida.id == 'credit_card' &&
                        cartoes.length == 0 &&
                        (!novoCartao.number ||
                          !novoCartao.cvv ||
                          !novoCartao.date ||
                          !novoCartao.name)) ||
                      (formaEscolhida.id == 'bank_slip' &&
                        (!endereco.number ||
                          !endereco.zip_code ||
                          !endereco.state ||
                          !endereco.street ||
                          !endereco.district ||
                          !endereco.city ||
                          !endereco.cpf_cnpj))
                    "
                  >
                    Assinar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #cadastroCartao let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title text-center">Cadastro Cartão de Crédito</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form
          id="payment-form"
          style="text-align: center"
          target="_blank"
          [ngClass]="getCreditCard()"
          method="POST"
        >
          <div class="usable-creditcard-form">
            <p class="text-muted text-danger">
              Este cartão de crédito passará a ser considerado como seu
              principal meio de pagamento
            </p>

            <div class="specifcwrapper">
              <div class="input-group nmb_a">
                <div class="icon ccic-brand"></div>
                <input
                  autocomplete="off"
                  class="credit_card_number"
                  data-iugu="number"
                  placeholder="Número do Cartão"
                  type="text"
                  [(ngModel)]="novoCartao.number"
                  name="ccNumber"
                  ccNumber
                />
              </div>
              <div class="input-group nmb_b">
                <div class="icon ccic-cvv"></div>
                <input
                  autocomplete="off"
                  class="credit_card_cvv"
                  data-iugu="verification_value"
                  placeholder="CVV"
                  maxlength="4"
                  type="text"
                  [(ngModel)]="novoCartao.cvv"
                  name="cvv"
                  ccCVC
                />
              </div>
              <div class="input-group nmb_c">
                <div class="icon ccic-name"></div>
                <input
                  class="credit_card_name"
                  data-iugu="full_name"
                  placeholder="Titular do Cartão"
                  type="text"
                  [(ngModel)]="novoCartao.name"
                  name="name"
                />
              </div>
              <div class="input-group nmb_d">
                <div class="icon ccic-exp"></div>
                <input
                  autocomplete="off"
                  class="credit_card_expiration"
                  data-iugu="expiration"
                  placeholder="MM/AA"
                  mask="00/00"
                  type="text"
                  [(ngModel)]="novoCartao.date"
                  name="date"
                  ccExp
                />
              </div>
            </div>
            <p *ngIf="erroCartao" class="text-muted text-danger">
              {{ erroCartao }}
            </p>
          </div>
          <button
            type="button"
            class="btn btn-lg btn-warning mt-2"
            (click)="cadastrarCartao(modal, false, false)"
            [disabled]="
              !novoCartao.number ||
              !novoCartao.cvv ||
              !novoCartao.date ||
              !novoCartao.name
            "
          >
            Cadastrar
          </button>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #contentVideo>
  <div class="embed-container">
    <iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
