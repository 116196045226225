<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem">
        Painel de Assinaturas
      </h2>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-sm-12">
    <p class="sub-texto">
      Tem alguma dúvida com as assinaturas, assista o video abaixo
      <a
        href="javascript:void(0)"
        class="text-warning pulsingButton"
        (click)="OpenModalXl(contentVideo)"
        style="text-decoration: underline; cursor: pointer"
      >
        Ver Vídeo Tutorial
      </a>
  </div>

  <div class="col-xl-4 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 warning">
                {{ assinaturasAtivas ? assinaturasAtivas : 0 }}
              </h3>
              <span
                >Assinaturas Ativas
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="Assinaturas que atualmente estão ativas"
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-check-circle warning font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 warning">
                {{ assinaturasPendentes ? assinaturasPendentes : 0 }}
              </h3>
              <span
                >Assinaturas Pendentes
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="Assinaturas que atualmente estão aguardando pagamento"
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-clock warning font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-lg-6 col-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="media">
            <div class="media-body text-left">
              <h3 class="mb-1 warning">
                {{ assinaturasSuspensas ? assinaturasSuspensas : 0 }}
              </h3>
              <span
                >Assinaturas Suspensas
                <i
                  class="fa fa-question-circle"
                  placement="top"
                  ngbTooltip="Assinaturas que estão suspensas/desativadas"
                ></i
              ></span>
            </div>
            <div class="media-right align-self-center">
              <i class="ft-x-circle warning font-large-2 float-right"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row text-left">
  <!--Pills Starts-->
  <div class="col-md-12 col-lg-12">
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div
            class="row mb-2 mt-2"
            *ngIf="subscriptions && subscriptions.length > 0"
          >
            <div class="col">
              <div class="card-content">
                <div class="card-body p-0">
                  <div class="table text-center m-0">
                    <table
                      class="table-striped table-responsive"
                      style="width: 100%"
                    >
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>
                            <input
                              type="text"
                              id="costumerName"
                              class="form-control"
                              style="background-color: white; height: 100%"
                              placeholder="Cliente"
                              name="customerName"
                              [(ngModel)]="filterCustomerName"
                              (keyup)="filter()"
                            />
                          </th>
                          <th>
                            <input
                              type="text"
                              id="nomePlano"
                              class="form-control"
                              style="background-color: white; height: 100%"
                              placeholder="Produto"
                              name="nomePlano"
                              [(ngModel)]="filterPlans"
                              (keyup)="filter()"
                            />
                          </th>
                          <th>Data da Assinatura</th>
                          <th>
                            <ng-select
                              [items]="tiposPagamento"
                              name="tiposPagamento"
                              bindLabel="name"
                              placeholder="Forma de Pagamento"
                              id="tiposPagamento"
                              (change)="filter()"
                              [(ngModel)]="filterPaymentMethod"
                            ></ng-select>
                          </th>
                          <th>
                            <ng-select
                              [items]="statusAssinatura"
                              name="statusAssinatura"
                              bindLabel="name"
                              placeholder="Status Assinatura"
                              id="statusAssinatura"
                              (change)="filter()"
                              [(ngModel)]="filterStatusAssinatura"
                            ></ng-select>
                          </th>
                          <th>Usuário Vinculado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="
                            let subscription of subscriptionsFiltered
                              | slice
                                : (page - 1) * pageSize
                                : (page - 1) * pageSize + pageSize
                          "
                        >
                          <td>
                            {{
                              formatSubscriptionId(subscription.subscriptionId)
                            }}<a
                              (click)="
                                copiarSubscriptionId(
                                  subscription.subscriptionId
                                )
                              "
                              ngbTooltip="Copiar ID da Assinatura."
                              ><i class="fa fa-clipboard"></i
                            ></a>
                          </td>
                          <td>{{ subscription.customer_ref }}</td>
                          <td>{{ subscription.plan_name }}</td>
                          <td>{{ formatDate(subscription.created_at) }}</td>
                          <td>
                            {{ formatLinguagem(subscription.payable_with) }}
                          </td>
                          <td>{{ subscription.status }}</td>
                          <td>
                            {{
                              subscription.SellerCustomer == null
                                ? "-"
                                : subscription.SellerCustomer.Customer.name
                            }}
                          </td>
                          <td>
                            <div
                              class="dropdown btn-group-sm mr-1 mb-1"
                              ngbDropdown
                            >
                              <button
                                type="button"
                                class="btn btn-outline-warning btn-sm dropdown-toggle"
                                ngbDropdownToggle
                              >
                                <i class="ft-more-vertical"></i>
                              </button>
                              <div
                                ngbDropdownMenu
                                class="dropdown-menu"
                                role="menu"
                              >
                                <a
                                  class="dropdown-item"
                                  (click)="suspenderAssinatura(subscription)"
                                  >Desativar Assinatura</a
                                >
                                <a
                                  class="dropdown-item"
                                  (click)="
                                    openVincularModal(
                                      vincularModal,
                                      subscription
                                    )
                                  "
                                  >Alterar Vinculação</a
                                >
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="form-row" style="margin: 5px">
                  <div
                    class="d-flex justify-content-center"
                    style="margin-left: auto; margin-right: auto"
                  >
                    <ngb-pagination
                      style="margin-top: 10px"
                      *ngIf="subscriptionsFiltered.length > 0"
                      [(page)]="page"
                      [pageSize]="pageSize"
                      [maxSize]="2"
                      [rotate]="true"
                      [boundaryLinks]="true"
                      size="sm"
                      [collectionSize]="subscriptionsFiltered.length"
                    ></ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2 mt-2" *ngIf="subscriptionsFiltered.length == 0">
            <div class="col">
              <div class="card-content">
                <div class="card-body p-0">
                  <p>Nenhuma assinatura localizada!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Pills Ends-->
</div>

<ng-template #withDrawBalance>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <div class="form-row mb-2">
          <div class="col">
            <h5>Solicitar Saque</h5>
            <p>
              <b>Saldo Disponível: </b
              >{{ balancetoWithDraw ? balancetoWithDraw : "R$ 0,00" }}
            </p>
            <div class="form-group">
              <div class="controls">
                <label for="value_cents">Valor a Sacar</label>
                <input
                  type="text"
                  id="inputWithdraw"
                  class="form-control"
                  prefix="R$"
                  mask="separator.2"
                  thousandSeparator="."
                  [(ngModel)]="inputWithdraw"
                  name="inputWithdraw"
                  placeholder="Valor do Saque"
                  #inputwithdraw="ngModel"
                  required
                />
                <small
                  class="form-text text-muted danger"
                  *ngIf="inputwithdraw.errors?.required"
                  >Informe o Valor do Saque</small
                >
                <small
                  class="form-text text-muted danger"
                  *ngIf="inputWithdraw > avaiableBalance"
                  >Valor solicitado é maior que o valor disponível</small
                >
                <small
                  class="form-text text-muted danger"
                  *ngIf="inputWithdraw && inputWithdraw < 5.0"
                  >O Valor mínimo para saque é de R$ 5</small
                >
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mb-2">
          <div class="col-12">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-lg btn-warning btn-block mb-md-0"
                [disabled]="!disableWithdraw || inputWithdraw > avaiableBalance"
                (click)="iuguWithdrawRequest(modal)"
              >
                Solicitar Saque
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #vincularModal let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Manutenção de Vínculo</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-2">
                <label for="aluno">Alunos</label>
                <ng-select
                  [appendTo]="'body'"
                  [notFoundText]="'Nenhum aluno encontrado'"
                  [items]="alunos"
                  bindLabel="Customer.name"
                  name="model"
                  placeholder="Selecionar Alunos"
                  id="aluno"
                  [(ngModel)]="alunoSelecionadoVincular"
                >
                </ng-select>
              </div>
              <div class="form-row mt-4">
                <div class="col-4 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-danger"
                    (click)="modal.close('Close click')"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-4 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="vincularAluno(false)"
                    [disabled]="subscriptionSelecionada.SellerCustomer == null"
                  >
                    Remover
                  </button>
                </div>
                <div class="col-4 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-warning mb-md-0"
                    (click)="vincularAluno(true)"
                    [disabled]="alunoSelecionadoVincular == null"
                  >
                    Vincular
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #contentVideo>
  <div class="embed-container">
    <iframe [src]="videoUrl" frameborder="0" allowfullscreen></iframe>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
