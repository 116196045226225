<ngx-spinner></ngx-spinner>

<!--Login Page Starts-->
<section id="login">
  <div class="row auth-height full-height-vh m-0 black">
    <div class="col-12 p-0 d-flex align-items-center justify-content-center">
      <div class="card bg-transparent m-0 overflow-hidden" style="max-width: 1300px">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row m-0 auth-img-bg">
              <div class="col-lg-6 d-flex flex-column justify-content-center align-items-center px-1 py-4 p-md-3" *ngIf="
                  planoEscolhido &&
                    planoEscolhido.name === 'Anual' &&
                    planoEscolhido.identifier === 'plano_3';
                  else placeholderImage
                ">
                <h3 class="text-center black font-medium-3" *ngIf="productType && productType==3">
                  Essa oportunidade encerra <b class="text-warning">às 18h</b>
                </h3>
                <h3 class="text-muted text-center font-small-3 mb-3">
                  Encerra em<br /><span class="black font-medium-3" *ngIf="configTimer.leftTime > 0; else timerDone">
                    <countdown (event)="doneTimer($event)" [config]="configTimer"></countdown>
                  </span>
                  <ng-template #timerDone>
                    <span class="black font-medium-3">00:30:00</span>
                  </ng-template>
                </h3>               
              </div>
              <ng-template #placeholderImage>
                <div class="col-lg-6 d-flex flex-column justify-content-center align-items-center auth-img-bg p-3"
                  *ngIf="!planoEscolhido || (planoEscolhido && !planoEscolhido.sideImage)">
                  <img src="../../../../assets/img/gallery/vector-web-laranja.png" alt="" class="img-fluid" width="400"
                    height="330" />

                </div>
                <div class="col-lg-6 d-flex flex-column justify-content-center align-items-center auth-img-bg p-0"
                  style="background-color: black;" *ngIf="planoEscolhido && planoEscolhido.sideImage">
                  <img [src]="planoEscolhido.sideImage" alt="" class="img-fluid"
                    width="100%" height="600"/>

                </div>
              </ng-template>

              <div class="col-lg-6 bg-white form-wrapper col-12 px-4 py-3">
                <div class="row mb-2" *ngIf="
                    planoEscolhido && planoEscolhido.identifier !== 'plano_3'
                  ">
                  <div class="col">
                    <h4 class="text-warning text-center" *ngIf="1==2">
                      {{
                      venceuDiasGratuitos
                      ? "Seus dias gratuitos acabaram. Garanta agora nossos planos em promoção!"
                      : "Garanta agora nossos planos em promoção!"
                      }}
                    </h4>  
                    <h3 class="text-center text-warning font-medium-3" *ngIf="productType && productType==3">
                      Essa oportunidade encerra <b class="text-warning">às 18h</b>
                    </h3>           
                    
                    <h3 class="text-center text-warning font-medium-3" *ngIf="productType && productType==4">
                      Garanta esta condição <b class="text-warning">Especial</b>
                    </h3>    
                    <h2 class="text-warning text-center"
                      *ngIf="planoEscolhido && productType != '4' && (planoEscolhido.name == 'Anual' || planoEscolhido.name == 'Semestral')">
                      Esta Oferta Encerra em<br /><span *ngIf="configTimer.leftTime > 0"><b>
                          <countdown (event)="doneTimer($event)" [config]="configTimer"></countdown>
                        </b></span>
                    </h2>             
                    <div class="col-12 p-0" *ngIf="planoEscolhido && planoEscolhido.features">
                      <ul class="fa-ul text-warning" style="font-size: 1.15em">
                        <li *ngFor="let f of planoEscolhido.features">
                          <i class="fa-li ft-check-circle"></i>
                          {{ f }}
                        </li>                        
                      </ul>
                    </div>
                  </div>
                </div>
                <h5 class="text-center font-medium-1 grey d-flex flex-column align-items-center"
                  [ngClass]="planoEscolhido ? 'mb-2' : 'mb-4'" *ngIf="planoEscolhido">
                  <span class="text-warning font-medium-2 text-bold-700">
                    {{ planoEscolhido.name }}
                  </span>

                  <span class="badge badge-pill px-2 mt-2">
                    <i class="fa fa-tags"></i>
                    <span class="ml-1">
                      <span *ngIf="
                        planoEscolhido && planoEscolhido.identifier">
                        {{ planoEscolhido.price }}
                      </span>

                    </span>
                  </span>
                </h5>
                <form #exForm="ngForm" class="editForm" novalidate>
                  <div class="form-row" *ngIf="!user">
                    <div class="col">
                      <div class="form-group">
                        <div class="controls">
                          <label for="email">Email</label>
                          <input type="text" id="email" class="form-control" autocomplete="off"
                            [(ngModel)]="usuario_email" (focus)="perdeuFocoEmail = false"
                            (blur)="perdeuFocoEmail = true" (change)="verificaEmail()" name="email"
                            placeholder="Email" />
                          <p *ngIf="isEmailInvalido" class="text-muted text-danger mt-1">
                            Digite um email válido.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="form-row" *ngIf="
                        isEmailCadastrado &&
                        validateEmail(usuario_email) &&
                        perdeuFocoEmail &&
                        !user
                      ">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="senha">Senha</label>
                            <input type="password" id="senha-cadastro" class="form-control" autocomplete="off"
                              [(ngModel)]="userToLogin.senha" (change)="login()" name="senha" placeholder="Senha" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p *ngIf="loginInvalido" class="text-muted text-danger mt-1">
                      Erro ao fazer Login. Email ou senha inválido. Tente
                      Novamente.
                    </p>
                  </div>
                  <div *ngIf="
                      !isEmailCadastrado &&
                      validateEmail(usuario_email) &&
                      perdeuFocoEmail
                    ">
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="senha">Senha</label>
                            <input type="text" id="senha" class="form-control" [(ngModel)]="usuario.senha" name="senha"
                              placeholder="Senha" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="nome">Nome</label>
                            <input type="text" id="nome" class="form-control" [(ngModel)]="usuario.nome" name="nome"
                              placeholder="Nome" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="phone">Telefone</label>
                            <ngx-intl-tel-input [preferredCountries]="preferredCountries"
                              [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="true"
                              [searchCountryField]="[
                                SearchCountryField.Iso2,
                                SearchCountryField.Name
                              ]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Brazil" [maxLength]="15"
                              [phoneValidation]="true" [separateDialCode]="separateDialCode"
                              [numberFormat]="PhoneNumberFormat.National" name="phone" class="d-block w-100"
                              searchCountryPlaceholder="Selecione o País" [(ngModel)]="usuario.phone">
                            </ngx-intl-tel-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="tipopagamento">Forma de pagamento</label>
                        <ng-select [items]="formasFiltered" bindLabel="name" name="tipopagamento"
                          (change)="changeForma()" placeholder="Selecionar Forma" id="tipopagamento" [clearable]="false"
                          [(ngModel)]="formaEscolhida"></ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="col">
                      <div class="form-group">
                        <label for="planoEscolhido">Planos</label>
                        <ng-select [items]="planosFiltered" bindLabel="name" name="planoEscolhido"
                          (change)="changePlano()" placeholder="Selecionar Plano" id="planoEscolhido"
                          [clearable]="false" [(ngModel)]="planoEscolhido"></ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-4" *ngIf="
                      formaEscolhida &&
                      formaEscolhida.id == 'credit_card' &&
                      planoEscolhido &&
                      planoEscolhido.name != 'Mensal' &&
                      planoEscolhido.name != 'Semestral'
                    ">
                    <div class="col">
                      <p class="text-center text-muted m-0">
                        Assim que você confirmar esta etapa, sua assinatura
                        {{ planoEscolhido.name }} será ativada, com a cobrança
                        de {{ planoEscolhido.parcelas }} mensalidades de
                        {{ planoEscolhido.priceAlert }}
                      </p>
                    </div>
                  </div>
                  <form id="boleto-form" style="margin-left: auto; margin-right: auto"
                    *ngIf="formaEscolhida && formaEscolhida.id == 'bank_slip'">
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cpf_cnpj">CPF/CNPJ</label>
                            <input type="text" id="cpf_cnpj" class="form-control" [(ngModel)]="endereco.cpf_cnpj"
                              name="cpf_cnpj" mask="000.000.000-00||00.000.000/0000-00" placeholder="CPF/CNPJ" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="cep">CEP</label>
                            <input type="text" id="cep" class="form-control" [(ngModel)]="endereco.zip_code" name="nome"
                              mask="00000-000" placeholder="Cep" (change)="consultaCep()" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-8 col-12">
                        <div class="form-group">
                          <div class="controls">
                            <label for="street">Rua/Avenida</label>
                            <input type="text" id="street" class="form-control" [(ngModel)]="endereco.street"
                              name="street" placeholder="Rua/Avenida" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="controls">
                            <label for="number">Número</label>
                            <input type="text" id="number" class="form-control" [(ngModel)]="endereco.number"
                              name="number" placeholder="Número" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col">
                        <div class="form-group">
                          <div class="controls">
                            <label for="district">Bairro</label>
                            <input type="text" id="district" class="form-control" [(ngModel)]="endereco.district"
                              name="district" placeholder="Bairro" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-md-8 col-12">
                        <div class="form-group">
                          <div class="controls">
                            <label for="city">Cidade</label>
                            <input type="text" id="city" class="form-control" [(ngModel)]="endereco.city" name="city"
                              placeholder="Cidade" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 col-12">
                        <div class="form-group">
                          <div class="controls">
                            <label for="state">Estado</label>
                            <ng-select [items]="estados" name="state" placeholder="Estado" id="state"
                              [(ngModel)]="endereco.state"></ng-select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div class="form-row mt-4" *ngIf="formaEscolhida && formaEscolhida.id == 'credit_card'">
                    <form id="payment-form" style="
                        text-align: center;
                        margin-left: auto;
                        margin-right: auto;
                      " target="_blank" [ngClass]="getCreditCard()" method="POST">
                      <div class="usable-creditcard-form">
                        <div class="specifcwrapper">
                          <div class="input-group nmb_a">
                            <div class="icon ccic-brand"></div>
                            <input autocomplete="off" class="credit_card_number" data-iugu="number"
                              placeholder="Número do Cartão" type="text" [(ngModel)]="novoCartao.number" name="ccNumber"
                              ccNumber />
                          </div>
                          <div class="input-group nmb_b">
                            <div class="icon ccic-cvv"></div>
                            <input autocomplete="off" class="credit_card_cvv" data-iugu="verification_value"
                              placeholder="CVV" maxlength="4" type="text" [(ngModel)]="novoCartao.cvv" name="cvv"
                              ccCVC />
                          </div>
                          <div class="input-group nmb_c">
                            <div class="icon ccic-name"></div>
                            <input class="credit_card_name" data-iugu="full_name" placeholder="Titular do Cartão"
                              type="text" [(ngModel)]="novoCartao.name" name="name" />
                          </div>
                          <div class="input-group nmb_d">
                            <div class="icon ccic-exp"></div>
                            <input autocomplete="off" class="credit_card_expiration" data-iugu="expiration"
                              placeholder="MM/AA" mask="00/00" type="text" [(ngModel)]="novoCartao.date" name="date"
                              ccExp />
                          </div>
                        </div>
                        <p *ngIf="erroCartao" class="text-muted text-danger">
                          {{ erroCartao }}
                        </p>
                      </div>
                    </form>
                  </div>
                  <div class="form-row mt-4">
                    <div class="col text-center">
                      <p class="m-0">
                        Ao clicar no botão Assinar você aceita os
                        <a href="https://ajuda.opersonaldigital.com.br/links-uteis/termos-de-servico"
                          src="www.google.com" target="_blank">Termos de serviço</a>
                        e
                        <a href="https://ajuda.opersonaldigital.com.br/links-uteis/termos-de-uso" src="www.google.com"
                          target="_blank">Termos de uso</a>
                      </p>
                    </div>
                  </div>
                  <div class="form-row mt-2">
                    <div class="col text-center">
                      <button type="button" class="btn btn-lg bg-success text-white btn-block" (click)="
                          formaEscolhida && formaEscolhida.id == 'credit_card'
                            ? cadastrarCartaoEAssinar()
                            : assinar()
                        " [disabled]="disableAssinar()">
                        Assinar
                      </button>
                    </div>
                  </div>
                  <div class="form-row mt-1">
                    <p class="text-center font-small-3 m-0" *ngIf="planoEscolhido">
                      {{
                      "*Renovação automática ativada. Preço a partir da renovação: " +
                      planoEscolhido.price
                      }}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-spinner></ngx-spinner>
<jjva-ngx-whatsapp-button [ngx_whatsapp_phone]="'+558398576703'"
  [ngx_whatsapp_text]="'Olá, estou no checkout e preciso de ajuda!'"></jjva-ngx-whatsapp-button>