import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "app-modal-hotmart",
  templateUrl: "./modal-hotmart.component.html",
  styleUrls: ["./modal-hotmart.component.scss"],
})
export class ModalHotmartComponent implements OnInit {
  affiliateLink: string = "";
  element: string = "htm-id";
  apiCall = this.api.new().silent();

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private api: Api
  ) {}

  ngOnInit(): void {}

  dismiss() {
    console.log("Modal dismissed");
    this.activeModal.dismiss();
  }

  isAffiliateLinkValid(): boolean {
    const urlPattern = /^(http:\/\/|https:\/\/)/;
    return urlPattern.test(this.affiliateLink);
  }

  validateAndReplicateProduct() {
    this.spinner.show();

    const scrapeUrl = `affiliate-product/scrape/product?url=${encodeURIComponent(
      this.affiliateLink
    )}&element=${this.element}`;

    this.apiCall.get(scrapeUrl).subscribe(
      (response: any) => {
        if (response.content) {
          this.replicateProduct(response.content);
        } else {
          this.toastr.error("Produto inválido ou não encontrado.");
          this.spinner.hide();
        }
      },
      (error: any) => {
        this.toastr.error("Erro ao validar o produto. Tente novamente.");
        console.error("Erro ao validar o link do produto:", error);
        this.spinner.hide();
      }
    );
  }

  replicateProduct(content: string) {
    const replicateUrl = `affiliate-product/replicate/${content}`;
    const body = { url: this.affiliateLink };

    this.apiCall.post(replicateUrl, body).subscribe(
      (response: any) => {
        this.toastr.success("Produto replicado com sucesso!");
        this.spinner.hide();
        this.activeModal.close();
        window.location.reload();
      },
      (error: any) => {
        this.toastr.error("Erro ao replicar o produto. Tente novamente.");
        console.error("Erro ao replicar o produto:", error);
        this.spinner.hide();
      }
    );
  }
}
