<div class="card m-0 position-relative">
  <button
    type="button"
    (click)="closeModal()"
    class="btn btn-danger btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
  >
    <i class="ft-x font-medium-2 m-0"></i>
  </button>

  <div class="card-header">
    <h4 class="card-title text-center m-0">Adicionar Produto</h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <div class="row">
        <div class="col-12 mb-2 mb-md-3">
          <div class="w-100 form-group m-0">
            <div class="mb-2">
              <span class="text-bold-500 font-size-5">
                Selecione os produtos que deseja tornar visíveis na sua loja:
              </span>
            </div>
            <div
              class="w-100 input-group"
              [ngClass]="{
                'select-container-open': selectOpen,
                'm-0': !selectOpen
              }"
            >
              <ng-select
                id="products"
                [multiple]="true"
                [items]="productsRaw"
                bindLabel="name"
                [closeOnSelect]="false"
                [(ngModel)]="products"
                [loading]="isLoading"
                (clear)="clearProducts()"
                (open)="toggleSelect()"
                (close)="toggleSelect()"
                [loadingText]="'Carregando...'"
                [notFoundText]="'Nenhum produto encontrado'"
                placeholder="Selecionar Produto"
                class="w-100"
              >
                <ng-template ng-multi-label-tmp let-items="items">
                  <div
                    *ngIf="items.length > 0"
                    class="d-flex flex-wrap align-items-center w-100"
                  >
                    <div class="ng-value bg-primary" *ngFor="let item of items">
                      <span class="ng-value-label white">{{ item.name }}</span>
                      <span
                        class="ng-value-icon right white"
                        (click)="deleteProduct(item.id)"
                      >
                        ×
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="col-12 mb-2 mb-md-3">
          <div class="w-100 d-flex justify-content-start">
            <span class="text-bold-500 font-size-5">
              Selecionados:
              <span class="grey">
                {{
                  products.length === 1
                    ? products.length + " produto"
                    : products.length + " produtos"
                }}
              </span>
            </span>
          </div>
        </div>
        <div class="col-12">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <button
              type="button"
              (click)="closeModal()"
              class="btn btn-outline-light px-4 px-lg-5 font-small-2 text-bold-600 mr-2"
            >
              Cancelar
            </button>
            <button
              type="button"
              (click)="submit()"
              class="btn btn-warning px-4 px-lg-5 font-small-2 text-bold-600"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
