<form
  novalidate
  [formGroup]="schedulingForm"
  (submit)="onSubmit()"
  class="card m-0"
>
  <div class="card-header py-3 bg-primary position-relative">
    <h4 class="card-title text-center white font-medium-4 m-0">Salvar</h4>
    <button
      type="button"
      class="btn btn-primary btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
      (click)="close()"
    >
      <i class="fa fa-times font-bold-400 white" aria-hidden="true"></i>
    </button>
  </div>
  <div class="card-content mt-2 px-1 px-md-2">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="name" class="mb-2"> Nome do Treino </label>
            <div class="input-group m-0">
              <input
                id="name"
                type="text"
                autocomplete="off"
                formControlName="name"
                class="form-control"
                [ngClass]="{
                  'is-invalid': validateField('name').invalid,
                  'is-valid': validateField('name').valid
                }"
              />
            </div>
            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'O nome do treino é obrigatório',
                control: schedulingForm.controls['name']
              }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'pattern',
                message:
                  'O nome do treino é obrigatório (altere o nome padrão)',
                control: schedulingForm.controls['name']
              }"
            ></ng-container>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="name" class="mb-2"> Observações </label>
            <div class="input-group m-0">
              <textarea
                id="note"
                type="text"
                rows="3"
                autocomplete="off"
                formControlName="note"
                placeholder="Adicione observações sobre o treino"
                class="form-control"
                [ngClass]="{
                  'is-invalid': validateField('note').invalid,
                  'is-valid': validateField('note').valid
                }"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="schedule" class="mb-2"> Agendamento </label>
            <div class="input-group m-0">
              <input
                id="schedule"
                type="text"
                autocomplete="off"
                placeholder="00/00/0000"
                formControlName="schedule"
                ngbDatepicker
                #scheduleRef="ngbDatepicker"
                [container]="'body'"
                class="form-control"
                [ngClass]="{
                  'is-invalid': validateField('schedule').invalid,
                  'is-valid': validateField('schedule').valid
                }"
              />
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary input-group-text"
                  (click)="scheduleRef.toggle()"
                >
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
            </div>
            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'required',
                message: 'A data de agendamento é obrigatória',
                control: schedulingForm.controls['schedule']
              }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'minDate',
                message:
                  'A data de agendamento não pode ser menor que a data atual',
                control: schedulingForm.controls['schedule']
              }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'maxDate',
                message:
                  'A data de agendamento não pode ser maior que a data de vencimento',
                control: schedulingForm.controls['schedule']
              }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'sameDate',
                message:
                  'A data de agendamento não pode ser igual a data de vencimento',
                control: schedulingForm.controls['schedule']
              }"
            ></ng-container>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="dueDate" class="mb-2"> Vencimento </label>
            <div class="input-group m-0">
              <input
                id="dueDate"
                type="text"
                autocomplete="off"
                placeholder="00/00/0000"
                formControlName="dueDate"
                ngbDatepicker
                #dueDateRef="ngbDatepicker"
                [container]="'body'"
                class="form-control"
                [ngClass]="{
                  'is-invalid': validateField('dueDate').invalid,
                  'is-valid': validateField('dueDate').valid
                }"
              />
              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary input-group-text"
                  (click)="dueDateRef.toggle()"
                >
                  <i class="fa fa-calendar"></i>
                </button>
              </div>
            </div>
            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'minDate',
                message:
                  'A data de vencimento não pode ser menor que a data de agendamento',
                control: schedulingForm.controls['dueDate']
              }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="formError"
              [ngTemplateOutletContext]="{
                validation: 'sameDate',
                message:
                  'A data de vencimento não pode ser igual a data de agendamento',
                control: schedulingForm.controls['dueDate']
              }"
            ></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row mb-0">
      <div class="col">
        <div
          class="w-100 d-flex justify-content-between align-items-center px-0 px-md-3"
        >
          <button
            type="button"
            title="Cancelar"
            class="btn btn-outline-primary text-primary px-4"
            (click)="close()"
          >
            Cancelar
          </button>
          <button
            type="submit"
            title="Salvar"
            class="btn btn-primary px-4 white"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template
  #formError
  let-control="control"
  let-message="message"
  let-validation="validation"
>
  <ng-container
    *ngIf="
      control &&
      control.hasError(validation) &&
      (control.dirty || control.touched)
    "
  >
    <div>
      <span role="alert" class="font-small-3 text-danger text-bold-600">
        {{ message }}
      </span>
    </div>
  </ng-container>
</ng-template>
