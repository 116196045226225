import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desafios',
  templateUrl: './desafios.component.html',
  styleUrls: ['./desafios.component.scss']
})
export class DesafiosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
