import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Api from "../helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import { AuthService } from "../shared/auth/auth.service";
import { DomSanitizer } from '@angular/platform-browser';
import Me from 'app/helpers/me';


@Component({
  selector: 'app-marketplace-posvenda',
  templateUrl: './marketplace-posvenda.component.html',
  styleUrls: ['./marketplace-posvenda.component.scss']
})
export class MarketplacePosvendaComponent implements OnInit {
  apiCall = this.api.new().silent();

  purchaseId = null
  productId = null
  product = null
  invoice: any;
  safeURL = null
  sellerView = null
  loadPreviewContent = null
  whatsAppContact = null
  pagamentoEscolhido: string = '';
  
  constructor(private api: Api,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    private _sanitizer: DomSanitizer,
    private me: Me,){ 

    this.route.params.subscribe((params) => {
      if(params['purchase-id']) {
        this.purchaseId = atob(params['purchase-id'])  
        console.log("Purchase",this.purchaseId)
       }

      if(params['product']) {
        this.productId = atob(params['product'])
        console.log("Purchase",this.productId)
       }
    })

    this.route.queryParams.subscribe((params)=>{

      if(params['sellerview']) {
        this.sellerView = true
      }


    })


  }

  async ngOnInit() {
    const self = this

    let user = null

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

      if(this.sellerView){
       user = await this.me.get().pipe(take(1)).toPromise()
      }

      
            
      const productoo = await self.apiCall.get('customer/me/getproduct/'+ self.productId).pipe(take(1)).toPromise()

      if(productoo.return){        
        this.product = productoo.return
        console.log("Product",this.product)

        if(user && user.id && this.product.user == user.id){
        this.loadPreviewContent = true
        console.log(this.loadPreviewContent)
        }

        if(this.product.delivery_video){
          self.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.product.delivery_video.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));
        }

        const purchase = await self.apiCall.get('customer/me/getinvoice/'+self.purchaseId+'/'+self.product.user).pipe(take(1)).toPromise()

        if (purchase.return) {
          this.invoice = purchase.return;
          console.log("Aqui aqui aqui", this.invoice);
    
          if (this.invoice.status === 'pending') {
            if (this.invoice.pix && this.invoice.pix.qrcode) {
              this.pagamentoEscolhido = 'pix';
            } else if (this.invoice.bank_slip) {
              this.pagamentoEscolhido = 'boleto';
            }
          }
        }

      }

      await self.spinner.hide()

  }

}
