import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { SharedService } from "../../shared/services/shared.service";
import { UserDataService } from "../../shared/services/user-data.service";
import { filter } from "rxjs/operators";
@Component({
  selector: "app-dados-pessoais",
  templateUrl: "./dados-pessoais.component.html",
  styleUrls: ["./dados-pessoais.component.scss"],
})
export class DadosPessoaisComponent implements OnInit {
  usuario: any;

  userSubscription: Subscription;

  constructor(private sharedService: SharedService) {}

  ngOnInit(): void {
    this.userSubscription = this.sharedService
      .getUser()
      .pipe(filter((user) => !!user))
      .subscribe((user) => {
        this.usuario = user;
      });
  }
}
