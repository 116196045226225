import Api from "../../helpers/api";
import App from "../../helpers/app";
import { Component, OnInit, Input } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
declare var Iugu: any;
@Component({
  selector: "app-cred-card-payment",
  templateUrl: "./cred-card-payment.component.html",
  styleUrls: ["./cred-card-payment.component.scss"],
})
export class CredCardPaymentComponent implements OnInit {
  @Input() invoice: any;
  apiCall = this.api.new().silent();
  novoCartao = {
    number: null,
    cvv: null,
    date: null,
    name: null,
  };
  isCheckboxDisabled: boolean = false;
  cupomDigitado;
  eventID = null;
  promo1 = false;
  promo2 = false;
  promo3 = false;
  promo4 = false;
  newUser = false;
  utmSource = null;
  utmMedium = null;
  planoParam = null;
  utmContent = null;
  erroCartao = null;
  hidecupom = false;
  parcelado = false;
  promoParc1 = false;
  promoParc2 = false;
  promoParc3 = false;
  promoParc4 = false;
  promoParc5 = false;
  promoParc6 = false;
  activePromo = null;
  utmCampaing = null;
  promoParc10 = false;
  isPromoParc = false;
  acceptTerms = false;
  cartaoCadastrado = null;
  venceuDiasGratuitos = false;
  constructor(
    private app: App,
    private api: Api,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    Iugu.setAccountID("B0C64ACE25DF962FF353AA716E9B82CE");
    this.route.queryParams.subscribe((params) => {
      if (params["plano"]) {
        this.planoParam = params["plano"];
      }

      if (params["cupom"] && !params["prom"]) {
        this.cupomDigitado = params["cupom"];
        if (this.cupomDigitado == "OPDA1REAL") {
          this.activePromo = 3;
        }
      }
      if (params["hidecupom"] && params["hidecupom"] == "true") {
        this.hidecupom = true;
      } else {
        this.hidecupom = false;
      }

      if (params["utm_source"]) {
        this.utmSource = params["utm_source"];
      }

      if (params["utm_medium"]) {
        this.utmMedium = params["utm_medium"];
      }

      if (params["utm_campaign"]) {
        this.utmCampaing = params["utm_campaign"];
      }

      if (params["utm_campaing"]) {
        this.utmCampaing = params["utm_campaing"];
      }

      if (params["utm_content"]) {
        this.utmContent = params["utm_content"];
      }

      if (params["pcode"]) {
        this.promoParc1 = false;
        this.promoParc2 = false;
        this.promoParc3 = false;
        this.promoParc4 = false;
        this.promoParc5 = false;
        this.promoParc6 = false;
        this.promoParc10 = false;

        if (params["pcode"] == "p1") {
          this.promo1 = true;
          this.promo2 = false;
          this.promo3 = false;
          this.promo4 = false;
        }
        if (params["pcode"] == "p2") {
          this.promo1 = false;
          this.promo2 = true;
          this.promo3 = false;
          this.promo4 = false;
        }
        if (params["pcode"] == "p3") {
          this.promo1 = false;
          this.promo2 = false;
          this.promo3 = true;
          this.promo4 = false;
        }
        if (params["pcode"] == "p4") {
          this.promo1 = false;
          this.promo2 = false;
          this.promo3 = false;
          this.promo4 = true;
        }
      }

      if (params["parc"] && params["parc"] == "1") this.parcelado = true;

      if (params["prom"]) {
        this.parcelado = true;

        this.promo1 = false;
        this.promo2 = false;
        this.promo3 = false;
        this.hidecupom = true;

        this.isPromoParc = true;

        switch (params["prom"]) {
          case "1":
            this.promoParc1 = true;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 5;
            break;
          case "2":
            this.promoParc1 = false;
            this.promoParc2 = true;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 6;
            break;
          case "3":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = true;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 7;
            break;
          case "4":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = true;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 8;
            break;
          case "5":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = true;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 9;
            break;
          case "6":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = true;
            this.promoParc10 = false;
            this.activePromo = 10;
            break;
          case "10":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = true;
            this.activePromo = 11;
            break;
        }
      }
    });
  }

  ngOnInit(): void {}
  
  onCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.isCheckboxDisabled = checkbox.checked;
  }
  getCreditCard() {
    if (
      this.novoCartao.number &&
      Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)
    ) {
      return Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number);
    } else {
      return "";
    }
  }

  async cadastrarCartaoEAssinar() {
    const self = this;
    self.erroCartao = "";
    let name = "";
    let cvv = "";
    let date = "";
    let number = "";

    if (self.novoCartao.cvv) {
      cvv = self.novoCartao.cvv.replace(/\s/g, "");
    }
    name = self.novoCartao.name;

    if (self.novoCartao.number) {
      number = self.novoCartao.number.replace(/\s/g, "");
    }
    if (self.novoCartao.date) {
      date = self.novoCartao.date.replace(/\s/g, "");
    }

    if (!Iugu.utils.validateCreditCardNumber(number)) {
      self.erroCartao = "Número de cartão inválido";
      return;
    }
    if (!Iugu.utils.validateExpirationString(date)) {
      self.erroCartao = "Data de validade inválida";
      return;
    }
    if (
      !Iugu.utils.getBrandByCreditCardNumber(number) ||
      !Iugu.utils.validateCVV(
        cvv,
        Iugu.utils.getBrandByCreditCardNumber(number)
      )
    ) {
      self.erroCartao = "Data de validade inválida";
      return;
    }

    let cc = Iugu.CreditCard(
      number,
      date.substring(0, 2),
      date.substring(3, 7),
      name.split(" ")[0],
      name.substring(name.indexOf(" ") + 1),
      cvv
    );
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    Iugu.createPaymentToken(cc, async function (response) {
      if (response.errors) {
        await self.spinner.hide();
        self.erroCartao = "Erro ao validar seu cartão.";
        return;
      } else {
        self.cartaoCadastrado = response.id;
        console.log("Cartão Cadastrado:", self.cartaoCadastrado);
        await self.spinner.hide();
        await self.assinar();
      }
    });
  }

  async assinar() {
    const self = this;
    const body = {
      invoiceId: self.invoice.id,
      token: self.cartaoCadastrado,
      setDefault: self.isCheckboxDisabled,
    };
    console.log("invoice", body);
    self.apiCall.post("finance/invoice/direct-charge", body).subscribe(
      (response) => {
        if (response.code == 200) {
          this.app.alert("Deu tudo certo", "");
        } else if (response.code == 400) {
          this.app.alert(response.message, "");
        }
        console.error("realizar cobrança direta:", response);
      },
      (error) => {
        console.error("Erro ao realizar cobrança direta:", error);
      }
    );
  }
}
