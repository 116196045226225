<div class="modal-header">
  <h4 class="modal-title">Recortar Imagem</h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-image-cropper
    [imageSource]="source"
    [aspectRatio]="aspectRatio"
    format="png"
    (croppedImageEvent)="onImageCropped($event)"
  >
  </app-image-cropper>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="dismiss()">
    Cancelar
  </button>
  <button type="button" class="btn btn-primary" (click)="confirmCrop()">
    Recortar
  </button>
</div>
