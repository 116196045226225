import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Api from "../../helpers/api";

@Component({
  selector: "app-admin-home",
  templateUrl: "./admin-home.component.html",
  styleUrls: ["./admin-home.component.scss"],
})
export class AdminHomeComponent implements OnInit {
  apiCall = this.api.new().silent();
  usuarios = [];
  usuariosFiltered = [];
  usuariosDetails = [];
  userView;
  training;
  avaliation;
  execution;
  programa;
  desafio;
  mural;
  pageAD = 1;
  pageSizeAD = 10;
  nomeLista;
  page = 1;
  pageSize = 10;
  tipos = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: "1",
      name: "Aluno",
    },
    {
      id: "0",
      name: "Personal",
    },
  ];
  status = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: true,
      name: "Ativo",
    },
    {
      id: false,
      name: "Inativo",
    },
  ];
  offset = 0;
  limit = 1000;

  constructor(private spinner: NgxSpinnerService, private api: Api) {}

  async ngOnInit() {
    const self = this;

    self.usuarios = [];
    self.usuariosFiltered = [];
    self.userView = null;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const dataTecnico = await self.apiCall
      .get("dashboard/tecnico")
      .pipe(take(1))
      .toPromise();

    if (dataTecnico && dataTecnico.return) {
      self.training = dataTecnico.return.training;
      self.avaliation = dataTecnico.return.avaliation;
      self.execution = dataTecnico.return.execution;
      self.programa = dataTecnico.return.programa;
      self.desafio = dataTecnico.return.desafio;
      self.mural = dataTecnico.return.mural;
    }

    let dataUsuarios = null; //await self.apiCall.get(`users/${self.offset}/${self.limit}`).pipe(take(1)).toPromise()
    let totalRows = [];
    let hasMoreRows = false;
    if (
      dataUsuarios?.return &&
      dataUsuarios.return.count &&
      dataUsuarios.return.count > self.offset + self.limit
    ) {
      hasMoreRows = true;
    }

    if (dataUsuarios?.return && dataUsuarios.return.rows) {
      for (let t of dataUsuarios.return.rows) {
        totalRows.push(t);
      }
    }

    while (hasMoreRows) {
      self.offset = self.offset + 1000;

      dataUsuarios = await self.apiCall
        .get(`users/${self.offset}/${self.limit}`)
        .pipe(take(1))
        .toPromise();

      if (
        dataUsuarios?.return &&
        dataUsuarios.return.count &&
        dataUsuarios.return.count > self.offset + self.limit
      ) {
        hasMoreRows = true;
      } else {
        hasMoreRows = false;
      }

      if (dataUsuarios?.return && dataUsuarios.return.rows) {
        for (let t of dataUsuarios.return.rows) {
          totalRows.push(t);
        }
      }
    }
    if (totalRows) {
      self.usuarios = JSON.parse(
        JSON.stringify(totalRows.filter((t) => t.type != "2"))
      );
      self.usuarios = self.usuarios.sort(
        (a, b) => a.name && a.name.localeCompare(b.name)
      );
      self.usuariosFiltered = self.usuarios;
    }
    self.spinner.hide();
  }

  getTotalType(personal) {
    if (personal) {
      return this.usuariosFiltered
        ? this.usuariosFiltered.filter((x) => x.type == "0").length
        : 0;
    } else {
      return this.usuariosFiltered
        ? this.usuariosFiltered.filter((x) => x.type == "1").length
        : 0;
    }
  }
}
