import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as cep from "cep-promise";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import Api from "../../../helpers/api";
import App from "../../../helpers/app";
import Me from "../../../helpers/me";

declare var Iugu: any;

@Component({
  selector: "app-personal-plano",
  templateUrl: "./personal-plano.component.html",
  styleUrls: ["./personal-plano.component.scss"],
})
export class PersonalPlanoComponent implements OnInit {
  apiCall = this.api.new().silent();
  user = null;
  faturas = [];
  cartoes = [];
  defaultCreditCardId: string | undefined;
  planos = [];
  planosFiltered = [];
  formas = [
    {
      id: "credit_card",
      name: "Cartão de Crédito",
    },
    {
      id: "bank_slip",
      name: "Boleto",
    },
  ];
  formasFiltered = [
    {
      id: "credit_card",
      name: "Cartão de Crédito",
    },
    {
      id: "bank_slip",
      name: "Boleto",
    },
  ];
  estados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];

  planosUpgrade = [];
  planosUpgradeFiltered = [];
  assinatura = null;
  pageTr = 1;
  pageSize = 10;
  pageTrFatura = 1;
  pageSizeFatura = 10;
  isIugu2 = null
  endereco = {
    cpf_cnpj: null,
    zip_code: null,
    number: null,
    street: null,
    city: null,
    state: null,
    district: null,
  };

  formUpdateSenha = {
    password: null,
    newPassword: null,
    confirmPassword: null,
  };
  phoneForm = new FormGroup({
    phone: new FormControl(undefined, [Validators.required]),
  });

  novoCartao = {
    number: null,
    cvv: null,
    date: null,
    name: null,
  };
  activeId = 1;
  videoUrl = null;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private toastr: ToastrService,
    private app: App,
    private _sanitizer: DomSanitizer,
    private api: Api
  ) {
    this.me
      .get()
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = this.formatTelUser(user);
      });
    this.route.params.subscribe((params) => {
      if (params["destId"]) {
        this.activeId = +params["destId"];
      }
    });
  }

  podeCancelar() {
    if (this.assinatura && this.assinatura.plan_name) {
      return this.assinatura.plan_name.toLowerCase().includes("mensal");
    } else {
      return false;
    }
  }

  cancelarWhatsApp() {
    let phoneFormated = "5583998576703";

    if (phoneFormated.length == 11) {
      phoneFormated = phoneFormated.padStart(13, "55");
    }
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneFormated}/&text=Olá, gostaria de cancelar o meu plano.`,
      "_blank"
    );
  }

  deleteAssinatura() {
    const self = this;
    if (!self.assinatura || !self.assinatura.id) {
      return;
    }
    self.app
      .confirm(
        "Tem certeza?",
        "Você realmente deseja cancelar esta assinatura?"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("seller/me/subscription")
            .subscribe(async (data) => {
              await self.ngOnInit();
              self.spinner.hide();
              if (data.success) {
                await this.app.alert(
                  "Tudo certo!",
                  "Assinatura cancelada com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }

  deleteCreditCard(creditCard) {
    const self = this;

    if (!creditCard || !creditCard.id) {
      self.toastr.error("Não foi possível remover o cartão", "Ops :(");
      return;
    }

    self.app
      .confirm("Tem certeza?", "Você realmente deseja remover este cartão?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("seller/me/paymentmethod/" + creditCard.id)
            .subscribe(async (data) => {
              if (data.success) {
                await self.ngOnInit();
                self.spinner.hide();

                this.toastr.success(
                  "Cartão removido com sucesso!",
                  "Tudo Certo!"
                );
              } else {
                this.toastr.error(data.message, "Ops :(");
              }
            });
        }
      });
  }

  async ngOnInit() {
    this.user - this.formatTelUser(this.user);
    this.phoneForm.patchValue({
      phone: this.user.phone,
    });

    const self = this;

    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/7BJw1gFBLso"
    );

    const dataFaturas = await self.apiCall
      .get("seller/me/faturas")
      .pipe(take(1))
      .toPromise();

    if (dataFaturas.return) {
      self.faturas = JSON.parse(JSON.stringify(dataFaturas.return.items));
    }

    const apiReturn = await self.apiCall
      .get("seller/me/subscription")
      .pipe(take(1))
      .toPromise();

    const data = apiReturn.return;

   

    if (data) {
      self.isIugu2 = data.iugu2;
      if (data.cartao) {
        self.defaultCreditCardId = data.metodopadrao;

        self.cartoes = (JSON.parse(JSON.stringify(data.cartao)) as any[]).sort(
          (a, b) => {
            if (!self.defaultCreditCardId) return 0;
            if (a.id === self.defaultCreditCardId) {
              return -1;
            }
            if (b.id === self.defaultCreditCardId) {
              return 1;
            }
            return 0;
          }
        );
      }

      if (data.assinatura) {
        self.assinatura = JSON.parse(JSON.stringify(data.assinatura));
    
        self.assinatura.status = self.assinatura.suspended
          ? "Suspensa"
          : self.assinatura.active
          ? "Ativa"
          : "Inativa";

        self.assinatura.valorFormatado = (
          data.assinatura.price_cents / 100
        ).toLocaleString("pt-br", { style: "currency", currency: "BRL" });
      }
    }

    if (!data || (data && data.iugu2)) {
      Iugu.setAccountID("B0C64ACE25DF962FF353AA716E9B82CE");
    } else {
      Iugu.setAccountID("DA2E12AE402FE52FB2321248579391AD");
    }

    await self.spinner.hide();
  }

  async changeMainCreditCard(cartao) {
    const self = this;

    if (
      !cartao ||
      !cartao.id ||
      (self.assinatura.plan_identifier !== "plano_1" &&
        self.assinatura.plan_identifier !== "plano_2" &&
        self.assinatura.plan_identifier !== "plano_3")
    ) {
      self.toastr.error(
        "Não foi possível alterar o cartão principal",
        "Ops :("
      );
      return;
    }

    const body = {
      customerId: self.assinatura.customer_id,
      paymentMethodId: cartao.id,
      iugu2: self.isIugu2
    };

    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    self.apiCall.put("me/changepaymentmethod", body).subscribe(async (data) => {
      if (data.success) {
        await self.ngOnInit();
        self.spinner.hide();

        this.toastr.success(
          "Cartão principal alterado com sucesso",
          "Tudo Certo!"
        );
      } else {
        this.toastr.error(data.message, "Ops :(");
      }
    });
  }

  openModal(template, isInitiate) {
    if (isInitiate) {
      this.apiCall.get("seller/me/initiatecheckout").subscribe((data) => {});
    }
    const modalRef = this.modalService.open(template, { centered: true });
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  async goCheckoutGeral() {
    await this.router.navigate(["/pages/checkout"], {
      queryParams: {
        logged: "true",
        utm_source: "PLATAFORMA",
        utm_medium: "CHECKOUT INTERNO",
        utm_campaing: "TELA MEU USUARIO",
        utm_content: "BOTAO ASSINAR",
      },
    });
  }

  openFatura(fatura) {
    if (fatura.secure_url) {
      window.open(fatura.secure_url, "_blank");
    }
  }

  consultaCep() {
    const self = this;
    if (self.endereco.zip_code) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      cep(self.endereco.zip_code)
        .then((local) => {
          self.spinner.hide();
          self.endereco.street = local.street;
          self.endereco.district = local.neighborhood;
          self.endereco.state = local.state;
          self.endereco.city = local.city;
        })
        .catch((erro) => {
          self.spinner.hide();
        });
    }
  }

  getDataVencimentoFatura(fatura) {
    if (!fatura.due_date) {
      return "";
    }
    return moment(fatura.due_date, "YYYY-MM-DD").format("DD/MM/YYYY");
  }

  getStatusFatura(fatura) {
    if (!fatura.status) {
      return "";
    }
    if (fatura.status == "pending") {
      return "pendente";
    }
    if (fatura.status == "paid") {
      return "paga";
    }
    if (fatura.status == "canceled") {
      return "cancelada";
    }
    if (fatura.status == "draft") {
      return "rascunho";
    }
    if (fatura.status == "partially_paid") {
      return "parcialmente paga";
    }
    if (fatura.status == "refunded") {
      return "reembolsada";
    }
    if (fatura.status == "expired") {
      return "expirada";
    }
    if (fatura.status == "in_protest") {
      return "em protesto";
    }
    if (fatura.status == "chargeback") {
      return "contestada";
    }
    if (fatura.status == "in_analysis") {
      return "em análise";
    }
    return fatura.status;
  }

  formatTelUser(user) {
    if (user) {
      if (user.phone && !user.phone.toString().startsWith("+")) {
        user.phone = "+" + user.phone;
      }
    }
    return user;
  }

  erroCartao;
  cadastrarCartao(modal, assina, atualiza) {
    const self = this;
    self.erroCartao = "";
    let name = "";
    let cvv = "";
    let date = "";
    let number = "";

    if (self.novoCartao.cvv) {
      cvv = self.novoCartao.cvv.replace(/\s/g, "");
    }
    name = self.novoCartao.name;

    if (self.novoCartao.number) {
      number = self.novoCartao.number.replace(/\s/g, "");
    }
    if (self.novoCartao.date) {
      date = self.novoCartao.date.replace(/\s/g, "");
    }

    if (!Iugu.utils.validateCreditCardNumber(number)) {
      self.erroCartao = "Número de cartão inválido";
      return;
    }
    if (!Iugu.utils.validateExpirationString(date)) {
      self.erroCartao = "Data de validade inválida";
      return;
    }
    if (
      !Iugu.utils.getBrandByCreditCardNumber(number) ||
      !Iugu.utils.validateCVV(
        cvv,
        Iugu.utils.getBrandByCreditCardNumber(number)
      )
    ) {
      self.erroCartao = "Data de validade inválida";
      return;
    }

    let cc = Iugu.CreditCard(
      number,
      date.substring(0, 2),
      date.substring(3, 7),
      name.split(" ")[0],
      name.substring(name.indexOf(" ") + 1),
      cvv
    );
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    Iugu.createPaymentToken(cc, function (response) {
      if (response.errors) {
        self.spinner.hide();
        self.erroCartao = "Erro ao validar seu cartão.";
        return;
      } else {
        self.apiCall
          .post("seller/me/paymentmethod", { token: response.id })
          .subscribe(async (data) => {
            await self.spinner.hide();
            if (data.success) {
              if (assina) {
                self.assinar(modal);
              } else {
                self.ngOnInit();
                await self.app.alert(
                  "Tudo certo!",
                  "Seu cartão foi cadastrado com sucesso!",
                  "success"
                );
                modal.close("Close click");
                self.novoCartao = {
                  number: null,
                  cvv: null,
                  date: null,
                  name: null,
                };
              }
            } else {
              await self.app.alert("Ops :(", data.message, "error");
            }
          });
      }
    });
  }

  getCreditCard() {
    if (
      this.novoCartao.number &&
      Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)
    ) {
      return Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number);
    } else {
      return "";
    }
  }

  planoUpgradeEscolhido;
  planoEscolhido;
  formaEscolhida;
  formatPreco(plano) {
    if (this.formaEscolhida) {
      let retorno = (plano.prices[0].value_cents / 100).toLocaleString(
        "pt-br",
        { style: "currency", currency: "BRL" }
      );
      retorno =
        plano.interval && plano.interval == 1
          ? retorno + " por mês"
          : plano.interval && plano.interval == 3
          ? retorno + " a cada 3 meses"
          : plano.interval && plano.interval == 12
          ? retorno + " a cada 12 meses"
          : retorno;
      return retorno;
    } else {
      return "";
    }
  }

  onChangePlano() {
    const self = this;
    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "credit_card"
    ) {
      if (self.planoEscolhido.identifier == "plano_2_boleto") {
        self.planoEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_2"
        );
      }
      if (self.planoEscolhido.identifier == "plano_3_boleto") {
        self.planoEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_3"
        );
      }
    }
    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "bank_slip"
    ) {
      if (self.planoEscolhido.identifier == "plano_2") {
        self.planoEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_2_boleto"
        );
      }
      if (self.planoEscolhido.identifier == "plano_3") {
        self.planoEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_3_boleto"
        );
      }
    }
  }

  onChangeForma() {
    const self = this;
    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "credit_card"
    ) {
      if (self.planoEscolhido.identifier == "plano_2_boleto") {
        self.planoEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_2"
        );
      }
      if (self.planoEscolhido.identifier == "plano_3_boleto") {
        self.planoEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_3"
        );
      }
    }
    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "bank_slip"
    ) {
      if (self.planoEscolhido.identifier == "plano_2") {
        self.planoEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_2_boleto"
        );
      }
      if (self.planoEscolhido.identifier == "plano_3") {
        self.planoEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_3_boleto"
        );
      }
    }
  }

  onChangePlanoUpgrade() {
    const self = this;
    if (
      self.planoUpgradeEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "credit_card"
    ) {
      if (self.planoUpgradeEscolhido.identifier == "plano_2_boleto") {
        self.planoUpgradeEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_2"
        );
      }
      if (self.planoUpgradeEscolhido.identifier == "plano_3_boleto") {
        self.planoUpgradeEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_3"
        );
      }
    }
    if (
      self.planoUpgradeEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "bank_slip"
    ) {
      if (self.planoUpgradeEscolhido.identifier == "plano_2") {
        self.planoUpgradeEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_2_boleto"
        );
      }
      if (self.planoUpgradeEscolhido.identifier == "plano_3") {
        self.planoUpgradeEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_3_boleto"
        );
      }
    }
  }

  onChangeFormaUpgrade() {
    const self = this;
    if (
      self.planoUpgradeEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "credit_card"
    ) {
      if (self.planoUpgradeEscolhido.identifier == "plano_2_boleto") {
        self.planoUpgradeEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_2"
        );
      }
      if (self.planoUpgradeEscolhido.identifier == "plano_3_boleto") {
        self.planoUpgradeEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_3"
        );
      }
    }
    if (
      self.planoUpgradeEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "bank_slip"
    ) {
      if (self.planoUpgradeEscolhido.identifier == "plano_2") {
        self.planoUpgradeEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_2_boleto"
        );
      }
      if (self.planoUpgradeEscolhido.identifier == "plano_3") {
        self.planoUpgradeEscolhido = self.planosUpgrade.find(
          (x) => x.identifier == "plano_3_boleto"
        );
      }
    }
  }

  cadastrarCartaoEAssinar(modal) {
    const self = this;

    if (
      !self.planoEscolhido ||
      !self.planoEscolhido.identifier ||
      !self.formaEscolhida ||
      !self.formaEscolhida.id
    ) {
      return;
    }

    if (
      self.formaEscolhida &&
      self.formaEscolhida.id == "credit_card" &&
      self.cartoes.length == 0
    ) {
      self.cadastrarCartao(modal, true, false);
    }
  }

  tokenCartao = null;
  assinar(modal) {
    const self = this;

    if (
      !self.planoEscolhido ||
      !self.planoEscolhido.identifier ||
      !self.formaEscolhida ||
      !self.formaEscolhida.id
    ) {
      return;
    }

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.apiCall
      .post("seller/me/subscription", {
        plan_identifier: self.planoEscolhido.identifier,
        tipo_pagamento: self.formaEscolhida.id,
        endereco: self.formaEscolhida.id == "bank_slip" ? self.endereco : null,
      })
      .subscribe(async (data) => {
        await self.spinner.hide();
        if (data.success) {
          self.planoEscolhido = null;
          self.ngOnInit();
          await self.app.alert(
            "Tudo certo!",
            "Plano assinado com sucesso!",
            "success"
          );
          modal.close("Close click");
          self.novoCartao = {
            number: null,
            cvv: null,
            date: null,
            name: null,
          };
        } else {
          await self.app.alert("Ops :(", data.message, "error");
        }
      });
  }
}
