<button
  class="btn btn-warning btn-sm"
  (click)="backPage()"
  style="margin-left: 10px; margin-top: 10px; margin-bottom: 10px"
>
  <i class="ft-arrow-left"></i>
</button>

<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem">
        Histórico de Cargas
      </h2>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3 class="text-center">{{ training ? training.training : "----" }}</h3>
      </div>
      <div class="card-content">
        <div class="card-body">
          <div class="row">
            <div
              class="col-12 d-flex justify-content-center mb-2 scrollable-box-3"
            >
              <div class="form-group mb-2">
                <ng-select
                  [clearable]="false"
                  [appendTo]="'.scrollable-box-3'"
                  [notFoundText]="'Nenhum período encontrado'"
                  [items]="filterPeriod"
                  bindLabel="description"
                  bindValue="days"
                  name="filterPeriod"
                  placeholder="Selecionar Grupo"
                  id="filterPeriod"
                  (change)="changePeriod()"
                  [(ngModel)]="filterPeriodSelected"
                >
                </ng-select>
              </div>
            </div>
            <div
              class="col-12 d-md-none justify-content-center mb-2 scrollable-box-4"
            >
              <div class="form-group mb-2">
                <ng-select
                  *ngIf="trainingExercises && trainingExercises.length > 0"
                  [clearable]="false"
                  [appendTo]="'.scrollable-box-4'"
                  [notFoundText]="'Nenhum exercício encontrado'"
                  [items]="trainingExercises"
                  bindLabel="name"
                  bindValue="id"
                  name="filteredExercise"
                  placeholder="Selecionar Exercício"
                  id="filterExercise"
                  (change)="changeExercise()"
                  [(ngModel)]="exerciseSelected"
                >
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col">
              <ngb-tabset class="text-center" style="justify-content: center">
                <ngb-tab title="Gráfico">
                  <ng-template ngbTabContent>
                    <div class="row d-md-none">
                      <div class="col" *ngIf="exerciseMobile">
                        <div class="card">
                          <div class="card-header">
                            <h4 class="card-title text-center mb-2">
                              {{
                                exerciseMobile ? exerciseMobile.name : "-----"
                              }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="card-body pl-0 pr-0">
                              <p
                                class="text-center"
                                *ngIf="
                                  !exerciseMobile ||
                                  (exerciseMobile &&
                                    exerciseMobile.historicalLoad &&
                                    exerciseMobile.historicalLoad.length == 0)
                                "
                              >
                                Sem dados de feedback para o período
                                selecionado!
                              </p>
                              <div
                                id="line-chart1"
                                class="height-400"
                                *ngIf="exerciseMobile.historicalLoad.length > 0"
                              >
                                <apx-chart
                                  [series]="[
                                    {
                                      name: 'Carga',
                                      data: exerciseMobile.historicalLoad
                                    }
                                  ]"
                                  [colors]="lineAreaChartOptions.colors"
                                  [chart]="lineAreaChartOptions.chart"
                                  [xaxis]="lineAreaChartOptions.xaxis"
                                  [yaxis]="lineAreaChartOptions.yaxis"
                                  [title]="lineAreaChartOptions.title"
                                  [dataLabels]="lineAreaChartOptions.dataLabels"
                                  [stroke]="lineAreaChartOptions.stroke"
                                  [grid]="lineAreaChartOptions.grid"
                                  [tooltip]="lineAreaChartOptions.tooltip"
                                  [plotOptions]="
                                    lineAreaChartOptions.plotOptions
                                  "
                                  [fill]="lineAreaChartOptions.fill"
                                  [legend]="lineAreaChartOptions.legend"
                                  [labels]="exerciseMobile.historicalDate"
                                  [markers]="lineAreaChartOptions.markers"
                                  [theme]="lineAreaChartOptions.theme"
                                  [responsive]="lineAreaChartOptions.responsive"
                                ></apx-chart>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row d-none d-md-flex">
                      <div class="col-6" *ngFor="let e of exercisesFeedbacks">
                        <div class="card">
                          <div class="card-header">
                            <h4 class="card-title text-center">{{ e.name }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="card-body">
                              <p
                                class="text-center"
                                *ngIf="e.historicalLoad.length == 0"
                              >
                                Sem dados de feedback para o período
                                selecionado!
                              </p>
                              <div
                                id="line-chart1"
                                class="height-400"
                                *ngIf="e.historicalLoad.length > 0"
                              >
                                <apx-chart
                                  [series]="[
                                    { name: 'Carga', data: e.historicalLoad }
                                  ]"
                                  [colors]="lineAreaChartOptions.colors"
                                  [chart]="lineAreaChartOptions.chart"
                                  [xaxis]="lineAreaChartOptions.xaxis"
                                  [yaxis]="lineAreaChartOptions.yaxis"
                                  [title]="lineAreaChartOptions.title"
                                  [dataLabels]="lineAreaChartOptions.dataLabels"
                                  [stroke]="lineAreaChartOptions.stroke"
                                  [grid]="lineAreaChartOptions.grid"
                                  [tooltip]="lineAreaChartOptions.tooltip"
                                  [plotOptions]="
                                    lineAreaChartOptions.plotOptions
                                  "
                                  [fill]="lineAreaChartOptions.fill"
                                  [legend]="lineAreaChartOptions.legend"
                                  [labels]="e.historicalDate"
                                  [markers]="lineAreaChartOptions.markers"
                                  [theme]="lineAreaChartOptions.theme"
                                  [responsive]="lineAreaChartOptions.responsive"
                                ></apx-chart>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>

                <ngb-tab title="Tabela">
                  <ng-template ngbTabContent>
                    <div class="row d-md-none">
                      <div class="col" *ngIf="exerciseMobile">
                        <div class="card">
                          <div class="card-header">
                            <h4 class="card-title text-center mb-2">
                              {{
                                exerciseMobile ? exerciseMobile.name : "-----"
                              }}
                            </h4>
                          </div>
                          <div class="card-content">
                            <div class="card-body p-0">
                              <p
                                class="text-center"
                                *ngIf="
                                  !exerciseMobile ||
                                  (exerciseMobile &&
                                    exerciseMobile.historicalDataforTable &&
                                    exerciseMobile.historicalDataforTable
                                      .length == 0)
                                "
                              >
                                Sem dados de feedback para o período
                                selecionado!
                              </p>

                              <div
                                class="table text-center m-0"
                                *ngIf="
                                  exerciseMobile.historicalDataforTable.length >
                                  0
                                "
                              >
                                <table class="table" style="width: 100%">
                                  <thead>
                                    <tr>
                                      <th>Data</th>
                                      <th>Carga</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      *ngFor="
                                        let f of exerciseMobile.historicalDataforTable
                                          | slice
                                            : (page - 1) * pageSize
                                            : (page - 1) * pageSize + pageSize
                                      "
                                    >
                                      <td>{{ f.date }}</td>
                                      <td>{{ f.feedback }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="form-row" style="margin: 5px">
                              <div
                                class="d-flex justify-content-center"
                                style="margin-left: auto; margin-right: auto"
                              >
                                <ngb-pagination
                                  style="margin-top: 10px"
                                  *ngIf="
                                    exerciseMobile.historicalDataforTable &&
                                    exerciseMobile.historicalDataforTable
                                      .length > 0
                                  "
                                  [(page)]="page"
                                  [pageSize]="pageSize"
                                  [maxSize]="2"
                                  [rotate]="true"
                                  [boundaryLinks]="true"
                                  size="sm"
                                  [collectionSize]="
                                    exerciseMobile.historicalDataforTable.length
                                  "
                                >
                                </ngb-pagination>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row d-none d-md-flex">
                      <div class="col-12" *ngFor="let e of exercisesFeedbacks">
                        <div class="card">
                          <div class="card-header">
                            <h4 class="card-title text-center">{{ e.name }}</h4>
                          </div>
                          <div class="card-content">
                            <div class="card-body">
                              <p
                                class="text-center"
                                *ngIf="e.historicalDataforTable.length == 0"
                              >
                                Sem dados de feedback para o período
                                selecionado!
                              </p>
                              <div
                                class="table text-center m-0"
                                *ngIf="e.historicalDataforTable.length > 0"
                              >
                                <table class="table" style="width: 100%">
                                  <thead>
                                    <tr>
                                      <th>Data</th>
                                      <th>Carga</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      *ngFor="
                                        let f of e.historicalDataforTable
                                          | slice
                                            : (e.page - 1) * pageSize
                                            : (e.page - 1) * pageSize + pageSize
                                      "
                                    >
                                      <td>{{ f.date }}</td>
                                      <td>{{ f.feedback }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="form-row" style="margin: 5px">
                              <div
                                class="d-flex justify-content-center"
                                style="margin-left: auto; margin-right: auto"
                              >
                                <ngb-pagination
                                  style="margin-top: 10px"
                                  *ngIf="e.historicalDataforTable.length > 0"
                                  [(page)]="e.page"
                                  [pageSize]="10"
                                  [maxSize]="2"
                                  [rotate]="true"
                                  [boundaryLinks]="true"
                                  size="sm"
                                  [collectionSize]="
                                    e.historicalDataforTable.length
                                  "
                                >
                                </ngb-pagination>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #grupoDetail let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Grupo</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-2">
                <label for="name">Nome do Grupo</label>
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  name="name"
                  [(ngModel)]="grupo.group"
                  #inputName="ngModel"
                  required
                />
              </div>
              <div class="form-group mb-2 scrollable-box-1">
                <label for="aluno">Alunos</label>
                <ng-select
                  [appendTo]="'.scrollable-box-1'"
                  [notFoundText]="'Nenhum aluno encontrado'"
                  [items]="relations"
                  bindLabel="Customer.name"
                  name="model"
                  placeholder="Selecionar Alunos"
                  id="aluno"
                  [(ngModel)]="grupo.relations"
                  multiple="true"
                >
                  <ng-template
                    ng-multi-label-tmp
                    let-items="items"
                    let-clear="clear"
                  >
                    <div
                      *ngIf="grupo.relations.length == 1"
                      class="ng-values-list"
                    >
                      <div
                        class="ng-value"
                        *ngFor="let item of items | slice : 0 : 1"
                      >
                        <span class="ng-value-label">{{
                          item.Customer.name
                        }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                          >×</span
                        >
                      </div>
                    </div>
                    <div
                      *ngIf="grupo.relations.length > 1"
                      class="ng-summary-list"
                    >
                      <div class="ng-value">
                        <span class="ng-value-label"
                          >{{ grupo.relations.length }} Selecionados</span
                        >
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block"
                    [ngClass]="
                      grupo && grupo.id ? 'btn-danger' : 'btn-secondary'
                    "
                    (click)="modal.close('Close click')"
                  >
                    {{ grupo && grupo.id ? "Fechar" : "Cancelar" }}
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="inserirOuAtualizarGrupo(modal)"
                    [disabled]="!grupo.group || grupo.relations.length == 0"
                  >
                    {{ grupo && grupo.id ? "Atualizar" : "Cadastrar" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #contentVideo>
  <ng-template
    [ngIf]="
      urlToPlay.startsWith('https://www.youtube.com/') ||
      urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <div class="embed-container">
      <iframe [src]="safeURL" frameborder="0" allowfullscreen></iframe>
    </div>
  </ng-template>
  <ng-template
    [ngIf]="
      !urlToPlay.startsWith('https://www.youtube.com/') &&
      !urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <video controls style="width: 100%">
      <source [src]="safeURL" type="video/mp4" />
    </video>
  </ng-template>
</ng-template>

<ng-template #contentTutoriais let-modal>
  <!-- <div class="card"> -->
  <div class="card-header">
    <h4 class="card-title">Tutoriais</h4>
  </div>
  <button class="close-button" (click)="modal.close('Close click')">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <div class="form-row" style="margin: 5px">
    <ul class="list-group mb-2" style="width: 100%">
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">1</span>
          <div class="media-body">
            <h5 class="mt-0">Como criar grupos de alunos</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=RXGrrkwXx-o',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=RXGrrkwXx-o',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">2</span>
          <div class="media-body">
            <h5 class="mt-0">Como gerenciar seus grupos</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=Rmz-tbgRBaI',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=Rmz-tbgRBaI',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <!-- </div> -->
</ng-template>
<ngx-spinner></ngx-spinner>
