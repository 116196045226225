import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { saveAs } from "file-saver";
import * as moment from "moment";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { take } from "rxjs/operators";
import Api from "../../../helpers/api";
import App from "../../../helpers/app";

@Component({
  selector: "app-indicacao",
  templateUrl: "./indicacao.component.html",
  styleUrls: ["./indicacao.component.scss"],
})
export class IndicacaoComponent implements OnInit {
  apiCall = this.api.new().silent();
  myCodeLink = null;
  indicacoes = [];
  comissoes = [];
  faturas = [];
  assinaturasMes = 0;
  saldoMes = 0;
  totalPago = 0;
  totalTaxas = 0;
  totalInvoices = 0;
  faturasMes = [];
  ganhosMensais = null;
  saldoDisponivel = null;
  saldoPendente = null;
  saldoLiberado = null;
  faixaGanhos = 0;
  totalLiquido = 0;
  page = 1;
  pageSize = 5;
  loading = false;
  explicacaoFaixa = null;
  mesSelecionado = null;
  anoSelecionado = null;
  mes = null;
  ano = null;
  faturasFiltered = [];
  faturasAgrupadas = [];
  isVisible1 = false;
  isVisible2 = false;
  isVisible3 = false;
  isVisible4 = false;
  showLinks = false;
  meses = [
    { mes: "00", name: "Janeiro" },
    { mes: "01", name: "Fevereiro" },
    { mes: "02", name: "Março" },
    { mes: "03", name: "Abril" },
    { mes: "04", name: "Maio" },
    { mes: "05", name: "Junho" },
    { mes: "06", name: "Julho" },
    { mes: "07", name: "Agosto" },
    { mes: "08", name: "Setembro" },
    { mes: "09", name: "Outubro" },
    { mes: "10", name: "Novembro" },
    { mes: "11", name: "Dezembro" },
  ];

  anos = [
    { ano: 2021, name: "2021" },
    { ano: 2022, name: "2022" },
    { ano: 2023, name: "2023" },
  ];

  UserBankAccount = [];
  videoUrl = null;

  bankAccount = {
    id: null,
    accountHolder: null,
    userCpf: null,
    bank: null,
    agency: null,
    account: null,
    accountType: null,
    accountPix: null,
    accountPixKey: null,
  };

  constructor(
    private api: Api,
    private clipboardApi: ClipboardService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private modal: NgbModal,
    private _sanitizer: DomSanitizer,
    private app: App
  ) {}

  async ngOnInit() {
    const self = this;

    this.faturas = [];
    this.saldoLiberado = 0;
    this.saldoPendente = 0;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/u5E1WALLHxU"
    );
    const indicacoes = await self.apiCall
      .get("seller/me/indicacoes")
      .pipe(take(1))
      .toPromise();

    self.indicacoes = indicacoes.return.indicacoes;
    self.comissoes = indicacoes.return.comissoes;

    const myCodeLink = await self.apiCall
      .get("seller/me/hasmycode")
      .pipe(take(1))
      .toPromise();

    const UserBankAccount = await self.apiCall
      .get("seller/me/userbankaccount")
      .pipe(take(1))
      .toPromise();

    if (UserBankAccount.return) self.bankAccount = UserBankAccount.return;

    if (myCodeLink.return) {
      self.myCodeLink = myCodeLink.return;
    } else {
      const linkconvite = await self.apiCall
        .put("users/generate/mycode")
        .pipe(take(1))
        .toPromise();

      if (linkconvite.return) {
        self.ngOnInit();
      } else {
        await self.app.alert("Ops :(", linkconvite.message, "error");
      }
    }

    for (let i of self.indicacoes) {
      if (i.invoices) {
        for (let v of i.invoices) {
          v.isLiberado =
            moment().diff(moment(v.paidAt), "days") >= 30 &&
            !v.pagamentoComissao
              ? true
              : false;
          v.mes = moment(v.paidAt).format("MM");
          v.ano = moment(v.paidAt).format("YYYY");
          this.faturas.push(v);
        }
      }
    }

    let penultimoMes = moment().subtract(2, "months").endOf("month");

    for (let i of self.comissoes) {
      if (
        moment(i.invoicePaymentDate).isSameOrBefore(penultimoMes) &&
        i.paymentId == null
      ) {
        //se entrar aqui, é um valor liberado
        this.saldoLiberado = this.saldoLiberado + i.commissionValue;
      } else if (moment(i.invoicePaymentDate).isAfter(penultimoMes)) {
        //se entrar aqui é saldo pendente
        this.saldoPendente = this.saldoPendente + i.commissionValue;
      }
    }

    const inicioMes = moment().startOf("month").format("YYYY-MM-DD HH:mm");
    const finalMes = moment().endOf("month").format("YYYY-MM-DD HH:mm");
    this.faturasMes = this.comissoes.filter(
      (f) =>
        f.invoicePaymentDate >= inicioMes && f.invoicePaymentDate <= finalMes
    );

    this.faturasFiltered = this.comissoes;
    await self.spinner.hide();
  }

  formatDate(date) {
    return moment(date).format("DD/MM/YYYY");
  }

  formatPreco(valor) {
    let retorno = (valor / 100).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    return retorno;
  }

  formatPlan(plano) {
    if (plano == "plano_1") {
      return "Mensal";
    } else if (
      plano == "plano_2" ||
      plano == "plano_2_parcelado" ||
      plano == "plano_2_boleto"
    ) {
      return "Semestral";
    } else if (
      plano == "plano_3" ||
      plano == "plano_3_parcelado" ||
      plano == "plano_3_boleto"
    ) {
      return "Anual";
    }
  }

  verificarComissaoLiberada(data) {
    if (moment().diff(moment(data), "days") >= 30) {
      return true;
    } else {
      return false;
    }
  }

  linkIndicacao = null;

  toggleDate() {
    if (
      this.mesSelecionado &&
      this.mesSelecionado.mes &&
      this.anoSelecionado &&
      this.anoSelecionado.ano
    ) {
      const mes = this.mesSelecionado.mes;
      const ano = this.anoSelecionado.ano;
      const primeirodia = moment(new Date(ano, mes, 1)).format(
        "YYYY-MM-DD HH:mm"
      );
      const ultimodia = moment(new Date(ano, mes, 1))
        .endOf("month")
        .format("YYYY-MM-DD HH:mm");
      this.faturasFiltered = this.comissoes.filter(
        (f) =>
          f.invoicePaymentDate >= primeirodia &&
          f.invoicePaymentDate <= ultimodia
      );
    } else {
      this.faturasFiltered = this.comissoes;
    }
  }

  inserirOuAtualizarConta() {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let chamada;
    let body = {
      bankAccount: {
        id: this.bankAccount.id ? this.bankAccount.id : null,
        accountHolder: this.bankAccount.accountHolder,
        userCpf: this.bankAccount.userCpf,
        bank: this.bankAccount.bank,
        agency: this.bankAccount.agency,
        account: this.bankAccount.account,
        accountType: this.bankAccount.accountType,
        accountPix: this.bankAccount.accountPix,
        accountPixKey: this.bankAccount.accountPixKey,
      },
    };

    if (this.bankAccount.id) {
      chamada = this.apiCall.put("seller/me/updatebankaccount", body);
    } else {
      chamada = this.apiCall.post("seller/me/createbankaccount", body);
    }
    chamada.subscribe(async (data) => {
      if (data.success) {
        this.spinner.hide();
        await this.app.alert(
          "Tudo certo!",
          this.bankAccount.id
            ? "Sua conta foi editada com sucesso"
            : "Sua conta foi cadastrada com sucesso",
          "success"
        );
        this.ngOnInit();
      } else {
        await this.app.alert("Ops :(", data.message, "error");
        this.spinner.hide();
      }
    });
  }

  downloadItem(item) {
    if (item == 1) {
      this.isVisible1 = true;
      saveAs(
        "https://files.wecodde.com/file/wecodde/E-book_Consultoria+Online+Versa%CC%83o+App.pdf",
        "Ebook Consultoria Lucrativa.pdf"
      );
    } else if (item == 2) {
      this.isVisible2 = true;
      saveAs(
        "https://files.wecodde.com/file/wecodde/Ebook-12-Passos-Instagram+Versa%CC%83o+App.pdf",
        "Ebook Instagram.pdf"
      );
    } else if (item == 3) {
      this.isVisible3 = true;
      saveAs(
        "https://files.wecodde.com/file/wecodde/GRUPO-DE-DESAFIO-LUCRATIVO+Versa%CC%83o+App.pdf",
        "Ebook Grupo de Desafio Lucrativo.pdf"
      );
    } else if (item == 4) {
      this.isVisible4 = true;
      saveAs(
        "https://files.wecodde.com/file/wecodde/Planilha-Financeira.xlsx",
        "Planilha Financeira.xlsx"
      );
    }
  }

  copyMyLink(myLink) {
    this.clipboardApi.copyFromContent(myLink);
    this.toastr.success("Link copiado com sucesso!");
  }

  OpenModalXl(content) {
    this.modal.open(content, { centered: true, size: "xl" });
  }

  toggleLinks() {
    this.showLinks = !this.showLinks;
  }

  scrollToId(target: string) {
    document.getElementById(target).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }
}
