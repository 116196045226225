import { AgmCoreModule } from "@agm/core";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import pt from "@angular/common/locales/pt";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { YouTubePlayerModule } from "@angular/youtube-player";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ArchwizardModule } from "angular-archwizard";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { CreditCardDirectivesModule } from "angular-cc-library";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "angularx-social-login";
import { NgApexchartsModule } from "ng-apexcharts";
import { ChartistModule } from "ng-chartist";
import { InternationalPhoneNumberModule } from "ng-phone-number";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { ChartsModule } from "ng2-charts";
import { DragulaModule, DragulaService } from "ng2-dragula";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ClipboardModule } from "ngx-clipboard";
import { ColorPickerModule } from "ngx-color-picker";
import { CookieService } from "ngx-cookie-service";
import { CountdownModule } from "ngx-countdown";
import { CustomFormsModule } from "ngx-custom-validators";
import { DeviceDetectorModule } from "ngx-device-detector";
import { ImageCropperModule } from "ngx-image-cropper";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { NgxIntlTelInputModule } from "ngx-intl-tel-input";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { FileUploadModule } from 'ng2-file-upload';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";
import { NgxPrintModule } from "ngx-print";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { UiSwitchModule } from "ngx-ui-switch";
import { WebcamModule } from "ngx-webcam";
import { NgxWhastappButtonModule } from "ngx-whatsapp-button";
import { environment } from "../environments/environment";
import { AdminAcompanharContestacaoComponent } from "./admin-acompanhar-contestacao/admin-acompanhar-contestacao.component";
import { AdminAssinaturasComponent } from "./admin-assinaturas/admin-assinaturas.component";
import { AdminBuscarUsuarioComponent } from "./admin-buscar-usuario/admin-buscar-usuario.component";
import { AdminCuponsComponent } from "./admin-cupons/admin-cupons.component";
import { AdminHistoricoAssinaturasComponent } from "./admin-historico-assinaturas/admin-historico-assinaturas.component";
import { AdminIndiqueEGanheComponent } from "./admin-indique-e-ganhe/admin-indique-e-ganhe.component";
import { AdminListarSubcontasComponent } from "./admin-listar-subcontas/admin-listar-subcontas.component";
import { AdminMeuPersonalDigitalComponent } from "./admin-meu-personal-digital/admin-meu-personal-digital.component";
import { AdminNovidadesComponent } from "./admin-novidades/admin-novidades.component";
import { AdminOffersComponent } from "./admin-offers/admin-offers.component";
import { AdminOpdAcademyAulasComponent } from "./admin-opd-academy-aulas/admin-opd-academy-aulas.component";
import { AdminOpdAcademyCategoriasComponent } from "./admin-opd-academy-categorias/admin-opd-academy-categorias.component";
import { AdminUsuariosComponent } from "./admin-usuarios/admin-usuarios.component";
import { AdminValidacaoSubcontaComponent } from "./admin-validacao-subconta/admin-validacao-subconta.component";
import { AdminHomeComponent } from "./admin/admin-home/admin-home.component";
import { AlunoComponent } from "./aluno/aluno.component";
import { AlunoAvaliacaoComponent } from "./aluno/pages/aluno-avaliacao/aluno-avaliacao.component";
import { AlunoTreinoComponent } from "./aluno/pages/aluno-treino/aluno-treino.component";
import { AvaliacaoWebComponent } from "./aluno/pages/avaliacao-web/avaliacao-web.component";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CheckoutIagoComponent } from "./checkout-iago/checkout-iago.component";
import { CheckoutMarketplaceComponent } from "./checkout-marketplace/checkout-marketplace.component";
import { CheckoutMpdComponent } from "./checkout-mpd/checkout-mpd.component";
import { CheckoutOfferComponent } from "./checkout-offer/checkout-offer.component";
import { CheckoutComponent } from "./checkout/checkout.component";
import Api from "./helpers/api";
import App from "./helpers/app";
import Me from "./helpers/me";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { MarketplacePosvendaComponent } from "./marketplace-posvenda/marketplace-posvenda.component";
import { DatatableComponentComponent } from "./modules/acompanhamento-execucoes/datatable-component/datatable-component.component";
import { AdminFunilRecuperacaoComponent } from "./admin-funil-recuperacao/admin-funil-recuperacao.component";

import {
  DisableNumberInputWheelDirective,
  TrainingWizardComponent,
} from "./modules/personal-gpt/training-wizard/training-wizard.component";
import { MpdCheckoutComponent } from "./mpd-checkout/mpd-checkout.component";
import { LoginMobileComponent } from "./pages/login-mobile/login-mobile.component";
import { AcompanhamentoExecucoesComponent } from "./personal/pages/acompanhamento-execucoes/acompanhamento-execucoes.component";
import { AvaliacoesComponent } from "./personal/pages/avaliacoes/avaliacoes.component";
import { PersonalAvaliacaoComponent } from "./personal/pages/avaliacoes/pages/personal-avaliacao/personal-avaliacao.component";
import { CategoriasComponent } from "./personal/pages/categorias/categorias.component";
import { DesafiosComponent } from "./personal/pages/desafios/desafios.component";
import { DesafioDetailComponent } from "./personal/pages/desafios/pages/desafio-detail/desafio-detail.component";
import { ExerciciosComponent } from "./personal/pages/exercicios/exercicios.component";
import { ExercicioComponent } from "./personal/pages/exercicios/pages/exercicio/exercicio.component";
import { FinanceAccountComponent } from "./personal/pages/finance-account/finance-account.component";
import { FinanceDashboardComponent } from "./personal/pages/finance-dashboard/finance-dashboard.component";
import { FinanceReportsComponent } from "./personal/pages/finance-reports/finance-reports.component";
import { FinanceSubscriptionsComponent } from "./personal/pages/finance-subscriptions/finance-subscriptions.component";
import { FirstOnboardingComponent } from "./personal/pages/first-onboarding/first-onboarding.component";
import { GruposComponent } from "./personal/pages/grupos/grupos.component";
import { IndicacaoComponent } from "./personal/pages/indicacao/indicacao.component";
import { InfosComponent } from "./personal/pages/infos/infos.component";
import { MuralComponent } from "./personal/pages/mural/mural.component";
import { OpdAcademyComponent } from "./personal/pages/opd-academy/opd-academy.component";
import { PersonalPlanoComponent } from "./personal/pages/personal-plano/personal-plano.component";
import { ProgramaDetailComponent } from "./personal/pages/programas/pages/programa-detail/programa-detail.component";
import { ProgramasComponent } from "./personal/pages/programas/programas.component";
import { TreinoDetailComponent } from "./personal/pages/treino-detail/treino-detail.component";
import { TreinoHistoricoCargaComponent } from "./personal/pages/treino-historico-carga/treino-historico-carga.component";
import { PersonalTreinoComponent } from "./personal/pages/treinos/pages/personal-treino/personal-treino.component";
import { ObjToSec } from "./personal/pages/treinos/treinos-edit/helpers/pipe/obj-to-sec-pipe/obj-to-sec.pipe";
import { TranslatePipe } from "./personal/pages/treinos/treinos-edit/helpers/pipe/translate-pipe/translate.pipe";
import { AddExerciseComponent } from "./personal/pages/treinos/treinos-edit/modals/add-exercise/add-exercise.component";
import { CtaSubscriptionComponent } from "./personal/pages/treinos/treinos-edit/modals/cta-subscription/cta-subscription.component";
import { FinalFeedbackComponent } from "./personal/pages/treinos/treinos-edit/modals/final-feedback/final-feedback.component";
import { ReplaceExerciseComponent } from "./personal/pages/treinos/treinos-edit/modals/replace-exercise/replace-exercise.component";
import { SchedulingComponent } from "./personal/pages/treinos/treinos-edit/modals/scheduling/scheduling.component";
import { ViewExerciseComponent } from "./personal/pages/treinos/treinos-edit/modals/view-exercise/view-exercise.component";
import { WarningModalComponent } from "./personal/pages/treinos/treinos-edit/modals/warning-modal/warning-modal.component";
import { TreinosEditComponent } from "./personal/pages/treinos/treinos-edit/treinos-edit.component";
import { TreinosListComponent } from "./personal/pages/treinos/treinos-list/treinos-list.component";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { AuthService } from "./shared/auth/auth.service";
import { PwaService } from "./shared/services/pwa.service";
import { WINDOW_PROVIDERS } from "./shared/services/window.service";
import { SharedModule } from "./shared/shared.module";
import { VerificacaoBoletoComponent } from "./verificacao-boleto/verificacao-boleto.component";
import { LoadEvolutionModalComponent } from "./modules/acompanhamento-execucoes/load-evolution-modal/load-evolution-modal.component";
import { RowDetailsComponent } from "./modules/acompanhamento-execucoes/row-details/row-details.component";
import { UsuarioInfosComponent } from "./admin-buscar-usuario/usuario-infos/usuario-infos.component";
import { ModalCropComponent } from "./modules/modal-crop/modal-crop.component";
import { ImageCropperComponent } from "./modules/crop-component/image-cropper.component";
import { AssinaturasUsuarioComponent } from "./admin-buscar-usuario/assinaturas-usuario/assinaturas-usuario.component";
import { DadosPessoaisComponent } from "./modules/dados-pessoais/dados-pessoais.component";
import { CartoesComponent } from "./modules/cartoes/cartoes.component";
import { AssinaturasComponent } from "./modules/assinaturas/assinaturas.component";
import { FaturasComponent } from "./modules/faturas/faturas.component";
import { CobrancaFaturasComponent } from "./modules/faturas/cobranca-faturas/cobranca-faturas.component";
import { PixPaymentComponent } from "./marketplace-posvenda/pix-payment/pix-payment.component";
import { TicketPaymentComponent } from "./marketplace-posvenda/ticket-payment/ticket-payment.component";
import { FreeTestModalComponent } from "./modules/personal-gpt/free-test-modal/free-test-modal.component";
import { GerenciarFaturasComponent } from "./pages/gerenciar-faturas/gerenciar-faturas.component";
import { CredCardPaymentComponent } from "./modules/cred-card-payment/cred-card-payment.component";
import { PaymentPixPersonalComponent } from "./modules/payment-pix-personal/payment-pix-personal.component";
import { PaymentTicketPersonalComponent } from "./modules/payment-ticket-personal/payment-ticket-personal.component";
import { FinanceStoreComponent } from "./personal/pages/finance-store/finance-store.component";
import { StoreComponent } from "./personal/pages/finance-store/store/store.component";
import { ServicesAndProductsComponent } from "./personal/pages/finance-store/services-and-products/services-and-products.component";
import { FinanceMainComponent } from "./personal/pages/finance-main/finance-main.component";
import { MidiaStoreComponent } from "./personal/pages/finance-store/store/midia-store/midia-store.component";
import { ShareSiteComponent } from "./modules/share-site/share-site.component";
import { CopyCheckoutUrlModalComponent } from "./modules/finance-store/copy-checkout-url-modal/copy-checkout-url-modal.component";
import { ActiveAndInactiveProductsComponent } from "./modules/finance-store/active-and-inactive-products/active-and-inactive-products.component";
import { OnStoreProductsComponent } from "./modules/finance-store/on-store-products/on-store-products.component";
import { ProductCreationAndEditionModalComponent } from "./modules/finance-store/product-creation-and-edition-modal/product-creation-and-edition-modal.component";
import { CoverUploadModalComponent } from './modules/finance-store/cover-upload-modal/cover-upload-modal.component';
import { ProductsAdditionToStoreModalComponent } from './modules/finance-store/products-addition-to-store-modal/products-addition-to-store-modal.component';
import { InvoicingChartComponent } from './modules/invoicing-chart/invoicing-chart.component';
import { SalesChartComponent } from './modules/sales-chart/sales-chart.component';
import { ProductsChartComponent } from './modules/products-chart/products-chart.component';
import { PersonalMarketplaceComponent } from './personal-marketplace/personal-marketplace.component';
import { PersonalMarketplaceProductsComponent } from './personal-marketplace/children/personal-marketplace-products/personal-marketplace-products.component';
import { PersonalMarketplaceSubscriptionsComponent } from './personal-marketplace/children/personal-marketplace-subscriptions/personal-marketplace-subscriptions.component';
import { PersonalMarketplaceLayoutComponent } from './modules/personal-marketplace/personal-marketplace-layout/personal-marketplace-layout.component';
import { SliderModule } from "./modules/slider/slider.module";
import { MarketplaceSubscriptionSlidesComponent } from './modules/personal-marketplace/marketplace-subscription-slides/marketplace-subscription-slides.component';
import { ModalHotmartComponent } from './modules/finance-store/modal-hotmart/modal-hotmart.component';
import { ImageUploadModalComponent } from './modules/finance-store/image-upload-modal/image-upload-modal.component';
import { GerenciarFaturasAlunoComponent } from "./pages/gerenciar-faturas-aluno/gerenciar-faturas-aluno.component";
import { CredCardPaymentCustomerComponent } from "./modules/cred-card-payment-customer/cred-card-payment-customer.component";
import { CheckoutV2Component } from "./checkoutv2/checkoutv2.component";
import { OrderBumpComponent } from './checkoutv2/order-bump/order-bump.component';
import { TutoriaisComponent } from './modules/finance-store/tutoriais/tutoriais.component';
import { NutriplanComponent } from './modules/finance-store/nutriplan/nutriplan.component';

var firebaseConfig = {
  apiKey: "AIzaSyDcCnJxd2L9JIlpAkYGg3EpAqiqzzs58sc",
  authDomain: "personaldigital-16884.firebaseapp.com",
  databaseURL: "https://personaldigital-16884.firebaseio.com",
  projectId: "personaldigital-16884",
  storageBucket: "personaldigital-16884.appspot.com",
  messagingSenderId: "917203014982",
  appId: "1:917203014982:web:9b18e0c43085a1590a0147",
};

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

const initializer = (pwaService: PwaService) => () => pwaService.checkAccess();

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

registerLocaleData(pt);

@NgModule({
  declarations: [
    AppComponent,
    FullLayoutComponent,
    ContentLayoutComponent,
    AlunoComponent,
    AlunoAvaliacaoComponent,
    InfosComponent,
    TreinosListComponent,
    TreinosEditComponent,
    AvaliacoesComponent,
    PersonalAvaliacaoComponent,
    AlunoTreinoComponent,
    PersonalTreinoComponent,
    ExerciciosComponent,
    ExercicioComponent,
    CategoriasComponent,
    MuralComponent,
    DesafiosComponent,
    DesafioDetailComponent,
    ProgramasComponent,
    ProgramaDetailComponent,
    AdminHomeComponent,
    CheckoutComponent,
    CheckoutV2Component,
    VerificacaoBoletoComponent,
    GruposComponent,
    AdminBuscarUsuarioComponent,
    AdminUsuariosComponent,
    AdminAssinaturasComponent,
    AdminHistoricoAssinaturasComponent,
    AdminCuponsComponent,
    AdminOffersComponent,
    AdminNovidadesComponent,
    AdminIndiqueEGanheComponent,
    AdminFunilRecuperacaoComponent,
    PersonalPlanoComponent,
    TreinoDetailComponent,
    TreinoHistoricoCargaComponent,
    IndicacaoComponent,
    FinanceAccountComponent,
    FinanceDashboardComponent,
    CheckoutMarketplaceComponent,
    MarketplacePosvendaComponent,
    FinanceReportsComponent,
    FinanceSubscriptionsComponent,
    AdminValidacaoSubcontaComponent,
    AdminListarSubcontasComponent,
    MpdCheckoutComponent,
    AdminAcompanharContestacaoComponent,
    AvaliacaoWebComponent,
    AdminMeuPersonalDigitalComponent,
    FirstOnboardingComponent,
    OpdAcademyComponent,
    LoginMobileComponent,
    CheckoutOfferComponent,
    CheckoutIagoComponent,
    TrainingWizardComponent,
    AdminOpdAcademyAulasComponent,
    AdminOpdAcademyCategoriasComponent,
    CheckoutMpdComponent,
    WarningModalComponent,
    AddExerciseComponent,
    ReplaceExerciseComponent,
    ViewExerciseComponent,
    FinalFeedbackComponent,
    TranslatePipe,
    ObjToSec,
    SchedulingComponent,
    DisableNumberInputWheelDirective,
    CtaSubscriptionComponent,
    AcompanhamentoExecucoesComponent,
    DatatableComponentComponent,
    LoadEvolutionModalComponent,
    RowDetailsComponent,
    UsuarioInfosComponent,
    ModalCropComponent,
    FaturasComponent,
    ImageCropperComponent,
    PixPaymentComponent,
    TicketPaymentComponent,
    FreeTestModalComponent,
    AssinaturasUsuarioComponent,
    CobrancaFaturasComponent,
    DadosPessoaisComponent,
    AssinaturasComponent,
    CartoesComponent,
    GerenciarFaturasComponent,
    GerenciarFaturasAlunoComponent,
    CredCardPaymentComponent,
    CredCardPaymentCustomerComponent,
    PaymentPixPersonalComponent,
    PaymentTicketPersonalComponent,
    FinanceStoreComponent,
    StoreComponent,
    ServicesAndProductsComponent,
    FinanceMainComponent,
    MidiaStoreComponent,
    ShareSiteComponent,
    CopyCheckoutUrlModalComponent,
    ActiveAndInactiveProductsComponent,
    OnStoreProductsComponent,
    ProductCreationAndEditionModalComponent,
    CoverUploadModalComponent,
    ProductsAdditionToStoreModalComponent,
    InvoicingChartComponent,
    SalesChartComponent,
    ProductsChartComponent,
    PersonalMarketplaceComponent,
    PersonalMarketplaceProductsComponent,
    PersonalMarketplaceSubscriptionsComponent,
    PersonalMarketplaceLayoutComponent,
    MarketplaceSubscriptionSlidesComponent,
    ModalHotmartComponent,
    ImageUploadModalComponent,
    OrderBumpComponent,
    TutoriaisComponent,
    NutriplanComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    ImageCropperModule,
    AppRoutingModule,
    InternationalPhoneNumberModule,
    SocialLoginModule, // Place SocialLoginModule in imports
    SharedModule,
    CreditCardDirectivesModule,
    HttpClientModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    YouTubePlayerModule,
    NgbModule,
    DragDropModule,
    FileUploadModule,
    ChartsModule,
    ScrollingModule,
    NgxIntlTelInputModule,
    CountdownModule,
    NgxSpinnerModule,
    NgApexchartsModule,
    ClipboardModule,
    NgxChartsModule,
    TooltipModule.forRoot(),
    ColorPickerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-right",
      progressBar: true,
    }),
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR_GOOGLE_MAP_API_KEY",
    }),
    PerfectScrollbarModule,
    DragulaModule,
    NgSelectModule,
    NgSelectModule,
    NgxMaskModule.forRoot(),
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    ChartistModule,
    ArchwizardModule,
    CustomFormsModule,
    NgxWhastappButtonModule,
    InfiniteScrollModule,
    UiSwitchModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FontAwesomeModule,
    NgxPrintModule,
    WebcamModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    RecaptchaV3Module,
    SliderModule,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
    { provide: LOCALE_ID, useValue: "pt-BR" },
    CookieService,
    AuthService,
    AuthGuard,
    App,
    Api,
    Me,
    DragulaService,
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              "917203014982-ug6b8id7r33s9bf98kpgab5ipm45gacc.apps.googleusercontent.com"
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider("2883333128433194"),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    WINDOW_PROVIDERS,
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [PwaService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
