import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import { ActiveTabType } from "./services-and-products.types";
import { ProductCreationAndEditionModalComponent } from "app/modules/finance-store/product-creation-and-edition-modal/product-creation-and-edition-modal.component";
import { ProductsAdditionToStoreModalComponent } from "app/modules/finance-store/products-addition-to-store-modal/products-addition-to-store-modal.component";
import { TutoriaisComponent } from "app/modules/finance-store/tutoriais/tutoriais.component";

const TUTORIAL_URL__SERVICES_AND_PRODUCTS =
  "https://www.youtube.com/embed/TaZeiVozd6M";

@Component({
  selector: "app-services-and-products",
  templateUrl: "./services-and-products.component.html",
  styleUrls: ["./services-and-products.component.scss"],
})
export class ServicesAndProductsComponent implements OnInit {
  apiCall = this.api.new().silent();

  showProducts = true;

  tutorialVideoUrl: SafeResourceUrl | null = null;

  activeTabId: ActiveTabType = null;

  seller: any = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private api: Api
  ) {
    this.route.queryParams.subscribe((queryParams) => {
      switch (queryParams["tab"] as ActiveTabType) {
        case "active":
          this.activeTabId = "active";
          break;
        case "inactive":
          this.activeTabId = "inactive";
          break;
        case "on-store":
          this.activeTabId = "on-store";
          break;
        default:
          this.router.navigate(
            ["/page/personal/finance-store/services-and-products"],
            {
              queryParams: { tab: "on-store" },
            }
          );
          break;
      }
    });
  }

  ngOnInit(): void {
    this.userInfo();

    this.tutorialVideoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      TUTORIAL_URL__SERVICES_AND_PRODUCTS
    );
  }

  userInfo(): void {
    this.apiCall.get("user/seller-store").subscribe(
      (data) => {
        this.seller = data.return;
      },
      (error) => {
        console.log("Erro ao obter informações da loja:", error);
      }
    );
  }

  openStore() {
    if (this.seller.slug) {
      const url = `/pages/loja/${this.seller.slug}`;
      window.open(url, "_blank");
    } else {
      this.router.navigate([`/pages/error`]);
    }
  }

  openAddProduct() {
    const instance = this.modalService.open(
      ProductCreationAndEditionModalComponent,
      {
        centered: true,
        size: "lg",
      }
    ).componentInstance as ProductCreationAndEditionModalComponent;

    instance.callback = (status: "success" | "error") => {
      if (status === "success") {
        this.showProducts = false;

        setTimeout(() => {
          this.showProducts = true;
        }, 500);
      }
    };
  }

  openAddProductsToStore() {
    const instance = this.modalService.open(
      ProductsAdditionToStoreModalComponent,
      {
        centered: true,
      }
    ).componentInstance as ProductsAdditionToStoreModalComponent;

    instance.callback = (status: "success" | "error") => {
      if (status === "success") {
        this.showProducts = false;

        setTimeout(() => {
          this.showProducts = true;
        }, 500);
      }
    };
  }

  openTutoriaisModal(size?: string) {
    const modalRef = this.modalService.open(TutoriaisComponent, {
      centered: true,
      size: size || "xl",
    });
  }
}
