<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem">
        {{
          lastFilterStatus == null
            ? "Mural do Personal"
            : lastFilterStatus == true
            ? "Murais Ativos"
            : "Murais Arquivados"
        }}
      </h2>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="d-flex justify-content-between">
    <div class="col-sm-12">
      <button
        class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton"
        (click)="OpenModalXl(contentTutoriais)"
      >
        <i class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
      </button>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col-5">
    <input
      type="text"
      id="volt"
      class="form-control"
      style="background-color: white; height: 100%"
      placeholder="Procurar mural"
      name="volt"
      [(ngModel)]="searchedMural"
      (keyup)="onSearchMural()"
    />
  </div>
  <div class="col">
    <div class="float-right d-none d-md-block" role="group">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="openMural(null, muralDetail)"
      >
        Adicionar Mural
      </button>
    </div>
    <div class="btn-group btn-group-lg d-md-none float-right" role="group">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="openMural(null, muralDetail)"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
    <div
      class="btn-group btn-group-lg d-none d-md-block float-right mr-2"
      role="group"
    >
      <button
        type="button"
        class="btn bg-light-secondary mb-md-0"
        (click)="filterMuralsStatus(null)"
      >
        Todos
      </button>
      <button
        type="button"
        class="btn bg-light-secondary mb-md-0"
        (click)="filterMuralsStatus(true)"
      >
        Ativos
      </button>
      <button
        type="button"
        class="btn bg-light-secondary mb-md-0"
        (click)="filterMuralsStatus(false)"
      >
        Arquivados
      </button>
    </div>
    <div class="btn-group btn-group-lg d-md-none float-right mr-2" role="group">
      <button
        type="button"
        class="btn bg-light-secondary mb-md-0"
        (click)="filterMuralsStatus(true)"
      >
        <i class="fa fa-check"></i>
      </button>
      <button
        type="button"
        class="btn bg-light-secondary mb-md-0"
        (click)="filterMuralsStatus(false)"
      >
        <i class="fa fa-archive"></i>
      </button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <ul class="list-group">
          <li
            *ngFor="
              let mural of murals
                | slice
                  : (pageTr - 1) * pageSize
                  : (pageTr - 1) * pageSize + pageSize
            "
            class="list-group-item"
          >
            <div class="media">
              <div class="media-body">
                <h5 style="word-break: break-all" class="mt-0">
                  {{ mural.title }}
                </h5>
              </div>

              <div class="switchery-warning mr-2">
                <ui-switch
                  checked
                  class="switchery"
                  placement="top"
                  [ngbTooltip]="
                    mural.status ? 'Mural Ativo' : 'Mural Arquivado'
                  "
                  [(ngModel)]="mural.status"
                  (change)="switchMural($event, mural)"
                ></ui-switch>
              </div>
              <div class="d-md-none" role="group">
                <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm dropdown-toggle"
                    ngbDropdownToggle
                  >
                    <i class="ft-more-vertical"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a class="dropdown-item" (click)="duplicarMural(mural)"
                      >Duplicar</a
                    >
                    <a
                      *ngIf="mural.file"
                      class="dropdown-item"
                      (click)="downloadFile(mural.file)"
                      >Baixar arquivo</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="openMural(mural, muralDetail)"
                      >Visualizar mural</a
                    >
                    <a class="dropdown-item" (click)="deletarMural(mural)"
                      >Deletar Mural</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="btn-group btn-group-lg d-none d-md-block"
                role="group"
              >
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  placement="top"
                  ngbTooltip="Duplicar Mural."
                  (click)="duplicarMural(mural)"
                >
                  <i class="fa fa-copy"></i>
                </button>
                <button
                  *ngIf="mural.file"
                  type="button"
                  placement="top"
                  ngbTooltip="Baixar arquivo."
                  class="btn btn-outline-warning"
                  (click)="downloadFile(mural.file)"
                >
                  <i class="fa fa-download"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  placement="top"
                  ngbTooltip="Visualizar mural."
                  (click)="openMural(mural, muralDetail)"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  placement="top"
                  ngbTooltip="Deletar Mural."
                  (click)="deletarMural(mural)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="murals.length > 0">
  <div class="col">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        [(page)]="pageTr"
        [pageSize]="pageSize"
        [maxSize]="2"
        [rotate]="true"
        [boundaryLinks]="true"
        size="sm"
        [collectionSize]="murals.length"
      ></ngb-pagination>
    </div>
  </div>
</div>
<div class="row" *ngIf="murals.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <ul class="list-group p-0">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">
                  Nenhuma informação cadastrada no seu mural.
                </h5>
                <span>Cadastre o seu primeiro mural!</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ng-template #contentVideo>
  <ng-template
    [ngIf]="
      urlToPlay.startsWith('https://www.youtube.com/') ||
      urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <div class="embed-container">
      <iframe [src]="safeURL" frameborder="0" allowfullscreen></iframe>
    </div>
  </ng-template>
  <ng-template
    [ngIf]="
      !urlToPlay.startsWith('https://www.youtube.com/') &&
      !urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <video controls style="width: 100%">
      <source [src]="safeURL" type="video/mp4" />
    </video>
  </ng-template>
</ng-template>

<ng-template #contentTutoriais let-modal>
  <div class="card-header">
    <h4 class="card-title">Tutoriais</h4>
  </div>
  <button class="close-button" (click)="modal.close('Close click')">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <div class="form-row" style="margin: 5px">
    <ul class="list-group mb-2" style="width: 100%">
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">1</span>
          <div class="media-body">
            <h5 class="mt-0">Como adicionar um mural</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=c6HtPn-brDs',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=c6HtPn-brDs',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">2</span>
          <div class="media-body">
            <h5 class="mt-0">Com enviar o mural para alunos</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=6exr7THR8vA',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=6exr7THR8vA',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">3</span>
          <div class="media-body">
            <h5 class="mt-0">Como gerenciar os arquivos do mural</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=tGBgFxdTSwM',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=tGBgFxdTSwM',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #muralDetail let-modal>
  <ngx-spinner></ngx-spinner>

  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Mural</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-2">
                <label for="ttt" class="mr-2">Banco de Murais</label>
                <ui-switch
                  class="switchery switchery-warning"
                  id="ttt"
                  [checked]="mural.banco"
                  (change)="changeSwitchBanco($event)"
                ></ui-switch>
              </div>
              <div
                *ngIf="!mural.banco"
                class="form-group mb-2 scrollable-box-1"
              >
                <label for="grupoSelecionado">Copiar Alunos de Grupo:</label>
                <ng-select
                  [appendTo]="'.scrollable-box-1'"
                  [notFoundText]="'Nenhum grupo encontrado'"
                  [items]="groups"
                  bindLabel="group"
                  name="grupoSelecionado"
                  placeholder="Selecionar Grupo"
                  id="grupoSelecionado"
                  (change)="copiarAlunosGrupo()"
                  [(ngModel)]="grupoSelecionado"
                ></ng-select>
              </div>
              <div
                *ngIf="!mural.banco"
                class="form-group mb-2 scrollable-box-2"
              >
                <label for="aluno">Alunos</label>
                <ng-select
                  [appendTo]="'.scrollable-box-2'"
                  [notFoundText]="'Nenhum aluno encontrado'"
                  [items]="relations"
                  bindLabel="Customer.name"
                  name="model"
                  placeholder="Selecionar Alunos"
                  id="aluno"
                  [(ngModel)]="alunosSelecionadosParaMural"
                  multiple="true"
                >
                  <ng-template
                    ng-multi-label-tmp
                    let-items="items"
                    let-clear="clear"
                  >
                    <div
                      *ngIf="alunosSelecionadosParaMural.length == 1"
                      class="ng-values-list"
                    >
                      <div
                        class="ng-value"
                        *ngFor="let item of items | slice : 0 : 1"
                      >
                        <span class="ng-value-label">{{
                          item.Customer.name
                        }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                          >×</span
                        >
                      </div>
                    </div>
                    <div
                      *ngIf="alunosSelecionadosParaMural.length > 1"
                      class="ng-summary-list"
                    >
                      <div class="ng-value">
                        <span class="ng-value-label"
                          >{{
                            alunosSelecionadosParaMural.length
                          }}
                          Selecionados</span
                        >
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-group mb-2">
                <label for="name">Título do Mural</label>
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  name="name"
                  [(ngModel)]="mural.title"
                  #inputName="ngModel"
                  required
                />
              </div>
              <div class="form-group mb-2">
                <label for="name">Descrição do Mural</label>
                <textarea
                  id="obse"
                  rows="2"
                  class="form-control"
                  name="obse"
                  #muralDescription
                  maxlength="255"
                  [(ngModel)]="mural.description"
                  #inputObse="ngModel"
                  placeholder="Você pode informar uma descrição mais detalhada para este mural."
                ></textarea>
              </div>
              <div class="form-row__description">
                {{ 255 - muralDescription.value.length }} caracteres restantes.
              </div>
              <div class="form-group mb-2">
                <label for="externalUrl">Link Externo</label>
                <input
                  type="text"
                  id="externalUrl"
                  class="form-control"
                  name="externalUrl"
                  [(ngModel)]="mural.externalUrl"
                  #externalUrl="ngModel"
                  required
                />
                <span class="text-danger text-mute" *ngIf="linkInvalido"
                  >Insira um link válido. O Link deve começar com 'http://' ou
                  'https://'</span
                >
              </div>
              <div class="form-row mb-2">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label for="agend">Agendamento</label>
                    <div class="input-group">
                      <input
                        type="text"
                        id="agend"
                        placeholder="00/00/0000"
                        class="form-control"
                        name="agend"
                        [(ngModel)]="mural.agendamento"
                        ngbDatepicker
                        #agend="ngbDatepicker"
                        [container]="'body'"
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          class="btn btn-secondary input-group-text"
                          (click)="agend.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label for="agend">Vencimento</label>
                    <div class="input-group">
                      <input
                        type="text"
                        id="vencimento"
                        placeholder="00/00/0000"
                        class="form-control"
                        name="vencimento"
                        [(ngModel)]="mural.vencimento"
                        ngbDatepicker
                        #venc="ngbDatepicker"
                        [container]="'body'"
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          class="btn btn-secondary input-group-text"
                          (click)="venc.toggle()"
                        >
                          <i class="fa fa-calendar"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-row mb-2">
                <div class="col text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint"
                    (click)="attachFile()"
                    style="width: 50%"
                    placement="top"
                    ngbTooltip="Você pode inserir um arquivo para seu mural."
                  >
                    <i class="fa fa-file-archive-o"></i> Inserir Arquivo
                  </button>
                </div>
              </div>
              <ng-template [ngIf]="mural.file">
                <div class="form-row mb-2">
                  <div class="col text-center">
                    <img
                      [src]="
                        isImage(mural.file)
                          ? mural.file
                          : '../../../assets/img/portrait/small/download-circular-button.png'
                      "
                      class="rounded-circle"
                      style="cursor: pointer"
                      (click)="mural.file ? downloadFile(mural.file) : null"
                      height="100"
                      width="100"
                    />
                  </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col text-center">
                    <button
                      type="button"
                      class="btn btn-lg btn-danger"
                      (click)="removeFile()"
                      style="width: 50%"
                      placement="top"
                      ngbTooltip="Remover anexo"
                    >
                      <i class="fa fa-eraser"></i> Remover Arquivo
                    </button>
                  </div>
                </div>
              </ng-template>

              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block"
                    [ngClass]="
                      mural && mural.id ? 'btn-danger' : 'btn-secondary'
                    "
                    (click)="modal.close('Close click')"
                  >
                    {{ mural && mural.id ? "Fechar" : "Cancelar" }}
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="inserirOuAtualizarMural(modal)"
                    [disabled]="
                      !mural ||
                      !mural.title ||
                      (!mural.banco &&
                        (!alunosSelecionadosParaMural ||
                          alunosSelecionadosParaMural.length == 0))
                    "
                  >
                    {{ mural && mural.id ? "Atualizar" : "Cadastrar" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
