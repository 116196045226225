import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import * as moment from "moment";
import { CookieService } from "ngx-cookie-service";
import { take } from "rxjs/operators";
import Api from "../../helpers/api";
import Me from "../../helpers/me";

@Injectable()
export class AuthGuard implements CanActivate {
  rotasAdmin = [
    "/page/admin",
    "/page/admin/buscar-usuario",
    "/page/assinatura-personal/:invoiceId",
    "/page/usuarios",
    "/page/cupons",
    "/page/admin/offers",
    "/page/admin/atualizacoes",
    "/page/admin/indique-e-ganhe",
    "/page/admin/assinaturas",
    "/page/admin/meu-personal-digital",
    "/page/admin/validacao-subcontas",
    "/page/admin/listar-subcontas",
    "/page/admin/acompanhar-contestacao",
    "/page/admin/opd-academy-aulas",
    "/page/admin/opd-academy-categorias",
    "/page/admin/recovery-funnel",
    "/page/admin/buscar-usuario/*"
  ];
  apiCall = this.api.new();
  eventID = null;

  constructor(
    private me: Me,
    private api: Api,
    private router: Router,
    public cookieService: CookieService,
    private http: HttpClient
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const self = this;
    let user = await self.me.get().pipe(take(1)).toPromise();
    const args = next.data || {};

    if (!user || !("id" in user)) {
      self.router.navigate([args.redirect || "/pages/login"]);
      return false;
    }

    if ("permission" in args) {
      if (!self.me.hasPermission(args.permission)) {
        return self.router.navigate(["/page"]);
      }
    }

    if ("permissions" in args) {
      for (const permission of args.permissions) {
        if (!self.me.hasPermission(permission)) {
          return self.router.navigate(["/page"]);
        }
      }
    }

    const jUser = JSON.parse(JSON.stringify(user));
    if (jUser.type == "2") {
      if (
        !self.rotasAdmin.find((x) =>
          state.url.includes("/page/admin/buscar-usuario/")
            ? true
            : x === state.url
        )
      ) {
        self.router.navigate([args.redirect || "/page/admin"]);
        return false;
      }
    } else {
      if (state.url != "/page/personal" && state.url != "/page") {
        let plano_ativo = false;
        let plano_em_dia = false;
        if (jUser.freeUntil) {
          var today = moment();
          let diferenca = moment(jUser.freeUntil).diff(today, "days");
          if (diferenca > 0) {
            plano_ativo = true;
            plano_em_dia = true;
          } else {
            const datapagamentos = await self.apiCall
              .get("seller/me/verify/subscription/plataforma")
              .pipe(take(1))
              .toPromise();
            if (datapagamentos.return) {
              plano_ativo = datapagamentos.return.plano_ativo;
              plano_em_dia = datapagamentos.return.plano_em_dia;
            }
          }
        } else {
          const datapagamentos = await self.apiCall
            .get("seller/me/verify/subscription/plataforma")
            .pipe(take(1))
            .toPromise();
          if (datapagamentos.return) {
            plano_ativo = datapagamentos.return.plano_ativo;
            plano_em_dia = datapagamentos.return.plano_em_dia;
          }
        }
        if (plano_ativo && !plano_em_dia) {
          self.router.navigate(["/page/personal"]);
          return false;
        }

        if (!plano_ativo) {
          self.router.navigate(["/page"]);
          return false;
        }
      }
      if (self.rotasAdmin.find((x) => x == state.url)) {
        self.router.navigate(["/page"]);
        return false;
      }
    }
    return true;
  }

  async sendFbEvent(event, value, logged) {
    const self = this;

    let ipAddress = null;
    let userAgent = null;
    let fbpCookie = this.cookieService.get("_fbp");

    if (fbpCookie == "") {
      fbpCookie = null;
    }

    ipAddress = await this.getIPAddress();

    userAgent = this.getUserAgent();

    let body = {
      event: event,
      value: value ? value : null,
      fbpCookie: fbpCookie ? fbpCookie : null,
      ip: ipAddress ? ipAddress : null,
      userAgent: userAgent ? userAgent : null,
      eventID: this.eventID,
    };

    if (logged == true) {
      self.apiCall.post("send/conversionLogged", body).subscribe();
    } else {
      self.apiCall.post("send/conversion", body).subscribe();
    }
  }

  async getIPAddress() {
    const self = this;
    return new Promise<void>((resolve, reject) => {
      self.http
        .get("https://api.ipify.org/?format=json")
        .subscribe((res: any) => {
          resolve(res.ip);
        });
    });
  }

  getUserAgent() {
    return window.navigator.userAgent;
  }

  generateEventID() {
    const min = Math.ceil(1000000);
    const max = Math.floor(1000000000);
    this.eventID = "FBPL" + Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
