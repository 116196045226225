import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../shared/auth/auth.service";
import {DragulaService} from "ng2-dragula";
import {NgxSpinnerService} from "ngx-spinner";
import {DomSanitizer} from "@angular/platform-browser";
import Me from "../../../helpers/me";
import {SocialAuthService} from "angularx-social-login";
import App from "../../../helpers/app";
import Api from "../../../helpers/api";
import {take} from "rxjs/operators";

@Component({
  selector: 'app-grupos',
  templateUrl: './grupos.component.html',
  styleUrls: ['./grupos.component.scss']
})
export class GruposComponent implements OnInit {
  apiCall = this.api.new().silent();
  pageTr = 1
  pageSize = 10
  groups = []
  originalGroups = []
  grupo = {
    id : null,
    group : null,
    relations : []
  }
  relations = []
  user = null
  urlToPlay = null
  safeURL = null
  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private dragulaService: DragulaService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private _sanitizer: DomSanitizer,
              private me: Me,
              private socialAuthService: SocialAuthService,
              private app: App,
              private api: Api) {
    this.me.get().pipe(take(1)).toPromise().then((user)=>{


      this.user = user

    })
  }
  async ngOnInit() {
    const self = this
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const relations = await self.apiCall.get('sellercustomer' ).pipe(take(1)).toPromise()
    if(relations.return) self.relations = relations.return.relations

    const groups = await self.apiCall.get('groupcustomers' ).pipe(take(1)).toPromise()
    if(groups.return) self.groups = groups.return
    if(groups.return) self.originalGroups = groups.return
    self.spinner.hide()

  }

  onDeleteGroup(grupo){
    const self = this
    self.app.confirm('Tem certeza?', 'Você realmente deseja deletar este grupo?').then(d=>{
      if(d){
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        self.apiCall.delete('groupcustomers/'+grupo.id).subscribe(async data => {
          self.spinner.hide()
          if(data.success){
            self.ngOnInit()
            await this.app.alert('Tudo certo!',  'Grupo deletado com sucesso', 'success')
          }else{
            await this.app.alert('Ops :(', data.message, 'error')
          }
        });
      }
    })
  }

  openGroup(grupo, modal){
    this.grupo = {
      id : null,
      group : null,
      relations : []
    }
    if(grupo){
      let relations = []
      for(let t of grupo.CustomersGroupsUsers){
        const found = this.relations.find(x=>x.id==t.relation)
        if(found) relations.push(found)
      }
      this.grupo = {
        id : grupo.id,
        group : grupo.group,
        relations : relations
      }
    }
    const modalRef = this.modalService.open(modal, { centered: true, size: 'xl' });
  }

  inserirOuAtualizarGrupo(modal){

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    let chamada
    let body = {
      CustomersGroups: {
        id : this.grupo.id ? this.grupo.id : null,
        group: this.grupo.group,
        CustomersGroupsUsers : this.grupo.relations
      }
    }
    if(this.grupo.id){
      chamada = this.apiCall.put('groupcustomers', body)

    }else{
      chamada = this.apiCall.post('groupcustomers', body)

    }
    chamada.subscribe(async data => {
      if(data.success){
        this.spinner.hide()
        await this.app.alert('Tudo certo!', this.grupo.id ? 'Seu grupo foi atualizado com sucesso' : 'Seu grupo foi cadastrado com sucesso', 'success')
        modal.close('Close click')
        this.ngOnInit()
      }else{
        await this.app.alert('Ops :(', data.message, 'error')
        this.spinner.hide()
      }
    });
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  viewMidia(urlToPlay, content){
    this.urlToPlay = urlToPlay
    if(urlToPlay.startsWith('https://www.youtube.com/watch?')){
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(urlToPlay.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));

    }else if(urlToPlay.startsWith('http://www.youtube.com/watch?')){
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(urlToPlay.replace('http://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));
    }
    this.modalService.open(content, { centered: true , size:'lg'});
  }

}
