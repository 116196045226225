<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300"style="padding: 1.5rem;">Banco de Avaliações</h2>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="d-flex justify-content-between">
    <div class="col-sm-12">
      <button class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton" (click)="OpenModalXl(contentTutoriais)"><i
          class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
      </button>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <div class="row" style="padding-left: 1.5rem">
            <div class="col" >
              <div class=" d-md-none" role="group">
                <div class="btn-group btn-group-lg float-right" role="group">
                  <button type="button" class="btn btn-lg btn-outline-warning mb-2 mb-md-0" (click)="openAvaliacaoComponent(null,  1)"><i class="fa fa-plus"></i></button>
                  <button type="button" class="btn btn-lg btn-outline-warning mb-2 mb-md-0" (click)="openAvaliacaoComponent(null,  2)"><i class="fa fa-folder-open"></i></button>
                </div>
              </div>
              <div class="btn-group btn-group-lg d-none d-md-block" role="group">
                <div class="btn-group btn-group-lg float-right" role="group">
                  <button type="button" class="btn btn-lg btn-outline-warning mb-2 mb-md-0" (click)="openAvaliacaoComponent(null,  1)">Nova Avaliação</button>
                  <button type="button" class="btn btn-lg btn-outline-warning mb-2 mb-md-0" (click)="openAvaliacaoComponent(null,  2)">Avaliação Padrão</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="card-content">
                <div class="card-body pr-0 pl-0">
                  <ul class="list-group">
                    <li *ngFor="let avaliation of avaliations" class="list-group-item">
                      <div class="media">
                        <img src="../../assets/img/icons/file-medical-alt-solid.png" class="rounded-circle bg-warning mr-2" style="padding: 8px;"
                             height="50" width="50" >
                        <div class="media-body">
                          <h5 class="mt-0">{{avaliation.name}}</h5>
                          <span>{{avaliation.type==1 ? 'Avaliação Modelo' : 'Avaliação Padrão'}}</span>
                        </div>
                        <div class=" d-md-none" role="group">
                          <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                            <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                              <i class="ft-more-vertical"></i>
                            </button>
                            <div ngbDropdownMenu class="dropdown-menu" role="menu">
                              <a *ngIf="avaliation.type != 2" class="dropdown-item" (click)="tornarAvaliacaoPadrao(avaliation)">Tornar avaliação padrão</a>
                              <a class="dropdown-item" (click)="exportarAvaliacaoAluno(avaliation, exportAvaliationDetail)">Exportar para alunos</a>
                              <a class="dropdown-item" (click)="duplicarAvaliacao(avaliation)">Duplicar esta avaliação</a>
                              <a class="dropdown-item"(click)="openAvaliacaoComponent(avaliation, avaliation.type)">Editar esta avaliação</a>
                              <a class="dropdown-item" (click)="deleteAvaliation(avaliation)" >Deletar esta avaliação</a>
                            </div>
                          </div>
                        </div>
                        <div class="btn-group btn-group-lg d-none d-md-block" role="group">
                          <button *ngIf="avaliation.type != 2" type="button" placement="top" (click)="tornarAvaliacaoPadrao(avaliation)" ngbTooltip="Tornar esta avaliação padrão." class="btn btn-sm btn-outline-warning btn-icon"><i class="fa fa-check-square-o"></i></button>
                          <button type="button" placement="top" (click)="exportarAvaliacaoAluno(avaliation, exportAvaliationDetail)" ngbTooltip="Exportar esta avaliação para outros alunos." class="btn btn-sm btn-outline-warning btn-icon"><i class="fa fa-users"></i></button>
                          <button type="button" placement="top" (click)="duplicarAvaliacao(avaliation)" ngbTooltip="Duplicar esta avaliação." class="btn btn-sm btn-outline-warning btn-icon"><i class="fa fa-copy"></i></button>
                          <button type="button" class="btn btn-lg btn-outline-warning mb-2 mb-md-0" (click)="openAvaliacaoComponent(avaliation, avaliation.type)" placement="top" ngbTooltip="Editar esta avaliação" >  <i class="fa fa-edit" ></i></button>
                          <button type="button" class="btn btn-outline-warning" placement="top" ngbTooltip="Deletar esta avaliação." (click)="deleteAvaliation(avaliation)"><i class="fa fa-trash"></i></button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="avaliations.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Você ainda não tem avaliações cadastradas no seu banco.</h5>
                <span>Cadastre sua primeira avaliação modelo!</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #exportAvaliationDetail let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Exportar Avaliação para Alunos</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br/>
              <div class="form-group mb-2 scrollable-box-1">
                <label for="grupoSelecionado">Copiar Alunos de Grupo:</label>
                <ng-select [appendTo]="'.scrollable-box-1'" [notFoundText]="'Nenhum grupo encontrado'" [items]="groups"  bindLabel="group" name="grupoSelecionado"  placeholder="Selecionar Grupo" id="grupoSelecionado" (change)="copiarAlunosGrupoExpAvaliacao()" [(ngModel)]="grupoSelecionado"></ng-select>
              </div>
              <div class="form-group mb-2 scrollable-box-2">
                <label for="aluno">Alunos</label>
                <ng-select [appendTo]="'.scrollable-box-2'" [notFoundText]="'Nenhum aluno encontrado'" [items]="relationsFiltered"  bindLabel="Customer.name" name="model"  placeholder="Selecionar Alunos" id="aluno" [(ngModel)]="alunosSelecionadosParaExportarAvaliacao" multiple="true">
                  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                    <div *ngIf=" alunosSelecionadosParaExportarAvaliacao.length == 1" class="ng-values-list">
                      <div class="ng-value" *ngFor="let item of items | slice:0:1">
                        <span class="ng-value-label">{{item.Customer.name}}</span>
                        <span class="ng-value-icon right" (click)="clear(item)">×</span>
                      </div>
                    </div>
                    <div *ngIf="alunosSelecionadosParaExportarAvaliacao.length > 1" class="ng-summary-list">
                      <div class="ng-value">
                        <span class="ng-value-label">{{ alunosSelecionadosParaExportarAvaliacao.length }} Selecionados</span>
                    </div>
                    </div>
                  </ng-template>  
                  <ng-template ng-header-tmp>

                    <div>
                      <button class="btn btn-link"
                              (click)="onSelectAll()">Selecionar Todos</button>
                    </div>
  
                  </ng-template>  

                </ng-select>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block btn-danger" (click)="cancelaExportacaoAvaliacaoAlunos(modal)">Cancelar</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="exportarAvaliacaoParaAlunos(modal)" [disabled]="!alunosSelecionadosParaExportarAvaliacao || alunosSelecionadosParaExportarAvaliacao.length==0">Exportar</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #contentVideo>
  <ng-template [ngIf]="urlToPlay.startsWith('https://www.youtube.com/') || urlToPlay.startsWith('http://www.youtube.com/')">
    <div class='embed-container'><iframe  [src]="safeURL" frameborder='0' allowfullscreen></iframe></div>
  </ng-template>
  <ng-template [ngIf]="!urlToPlay.startsWith('https://www.youtube.com/') && !urlToPlay.startsWith('http://www.youtube.com/')">
    <video controls style="width: 100%;">
      <source [src]="safeURL" type="video/mp4">
    </video>
  </ng-template>
</ng-template>

<ng-template #contentTutoriais let-modal>
  <div class="card-header">
    <h4 class="card-title">Tutoriais</h4>
  </div>
  <button class="close-button" (click)="modal.close('Close click')"><i class="fa fa-times" aria-hidden="true"></i></button>
  <div class="form-row" style="margin: 5px">
    <ul class="list-group mb-2" style="width: 100%">
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">1</span>
          <div class="media-body">
            <h5 class="mt-0">Como realizar uma avaliação padrão</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=a7Zmb1RXVz0', contentVideo )" >Vídeo</button>
          </div>
          <div class="d-md-none">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=a7Zmb1RXVz0', contentVideo )" >Vídeo</button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">2</span>
          <div class="media-body">
            <h5 class="mt-0">Como realizar uma avaliação personalizada</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=rtJidv3uX50', contentVideo )" >Vídeo</button>
          </div>
          <div class="d-md-none">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=rtJidv3uX50', contentVideo )" >Vídeo</button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">3</span>
          <div class="media-body">
            <h5 class="mt-0">Avaliações: %G, perimetria, bioimpedância</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=omWE1ro28K0', contentVideo )" >Vídeo</button>
          </div>
          <div class="d-md-none">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=omWE1ro28K0', contentVideo )" >Vídeo</button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">4</span>
          <div class="media-body">
            <h5 class="mt-0">Como visalizar as avaliações dos seus alunos</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=zXvZ6vpj1dQ', contentVideo )" >Vídeo</button>
          </div>
          <div class="d-md-none">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=zXvZ6vpj1dQ', contentVideo )" >Vídeo</button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
