import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CloudStorageService } from "app/shared/services/cloud-storage/cloud-storage.service";
import { isImageFile, openFilePicker } from "app/util/upload/file-picker";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import Api from "../helpers/api";
import App from "../helpers/app";

type FormType = {
  productId: number;
  offerImage: string;
  freeMonths: number;
  features: string[];
};

type OfferType = {
  id: number | null;
  name: string | null;
  expirationDate: string | null;
  code: number | null;
  paymentMethods: string | any[] | null;
  active?: boolean | null;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  ProductOffer?: ProductOfferType[] | null;
};

type ProductOfferType = {
  id: number;
  image: string | null;
  freeMonths: number | null;
  features: string | null;
  active: boolean | null;
  productId: number | null;
  offerId: number | null;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

@Component({
  selector: "app-admin-offers",
  templateUrl: "./admin-offers.component.html",
  styleUrls: ["./admin-offers.component.scss"],
  providers: [CloudStorageService],
})
export class AdminOffersComponent implements OnInit {
  apiCall = this.api.new().silent();

  offers = [];
  products = [];
  page = 1;
  pageSize = 10;
  offer: OfferType = {
    id: null,
    name: null,
    expirationDate: null,
    code: null,
    paymentMethods: [] as any[],
  };
  productOffer = {
    productId: null,
    offerId: null,
    image: "",
    freeMonths: null,
    features: "",
  };
  paymentMethodsOptions = [
    { id: "credit_card", label: "Cartão de Crédito" },
    { id: "pix", label: "PIX" },
    { id: "boleto", label: "Boleto" },
  ];
  productOffers: ProductOfferType[] = [];

  productOfferLinkForm: FormGroup | null = null;

  get productOfferLinkFeatures() {
    return this.productOfferLinkForm?.get("features") as FormArray;
  }

  initialData: Partial<FormType | { [key: string]: any }> = {
    productId: null,
    offerImage: "",
    freeMonths: null,
    features: [],
  };

  isUploadingImage: boolean = false;

  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private cloudStorage: CloudStorageService,
    private app: App,
    private toastr: ToastrService,
    private api: Api
  ) {}

  ngOnInit() {
    this.resetOffer();
    this.offers = [];

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall.get("offers").subscribe((response) => {
      this.spinner.hide();

      if (response.return) {
        this.offers = response.return;
      }
    });

    this.apiCall.get("products").subscribe((response) => {
      this.spinner.hide();

      if (response.return) {
        this.products = response.return;
      }
    });
  }

  initProductOfferLinkForm() {
    this.productOfferLinkForm = new FormGroup({
      productId: new FormControl(this.initialData.productId, [
        Validators.required,
      ]),
      offerImage: new FormControl(this.initialData.offerImage),
      freeMonths: new FormControl(this.initialData.freeMonths, [
        Validators.required,
        Validators.min(1),
      ]),
      features: new FormArray([new FormControl("", [Validators.required])]),
    });
  }

  inserirOuAtualizarOffer(modal: any) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let request: Observable<any> | null = null;
    let body = {
      Offer: {
        ...this.offer,
        paymentMethods: JSON.stringify(this.offer.paymentMethods),
      },
    };

    if (this.offer.id) {
      request = this.apiCall.put(`offers/${this.offer.id}`, body);
    } else {
      request = this.apiCall.post("offers", body);
    }

    request.subscribe((response) => {
      this.spinner.hide();

      if (response.success) {
        this.toastr.success(
          this.offer.id
            ? "Oferta atualizada com sucesso"
            : "Oferta cadastrada com sucesso",
          "Tudo Certo!"
        );

        modal.close("Close click");
        this.ngOnInit();
      } else {
        this.toastr.error(response.message, "Ops :(");
      }
    });
  }

  onDeleteOffer(offer: any) {
    this.app
      .confirm("Tem certeza?", "Você realmente deseja deletar esta oferta?")
      .then((d) => {
        if (d) {
          this.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });

          this.apiCall.delete("offers/" + offer.id).subscribe((response) => {
            this.spinner.hide();

            if (response.success) {
              this.toastr.success("Oferta deletada com sucesso", "Tudo Certo!");

              this.ngOnInit();
            } else {
              this.toastr.error(response.message, "Ops :(");
            }
          });
        }
      });
  }

  openOffer(offer: any, modal: any) {
    this.resetOffer();

    if (offer) {
      const formattedExpirationDate = offer.expirationDate
        ? this.formatDate(offer.expirationDate)
        : "";
      this.offer = {
        ...offer,
        expirationDate: formattedExpirationDate,
        paymentMethods: offer.paymentMethods
          ? JSON.parse(offer.paymentMethods)
          : [],
      };
    }
    this.modalService.open(modal, { centered: true, size: "lg" });
  }

  onOfferImageChange() {
    if (this.productOfferLinkForm?.get("offerImage")?.value) {
      this.productOfferLinkForm?.get("offerImage")?.patchValue("");
    }

    openFilePicker({
      accept: "image/*",
      multiple: false,
      callback: (files) => {
        if (files.length < 0) return;

        const file = files[0];
        if (!file) return;

        if (!isImageFile(file)) return;

        this.isUploadingImage = true;
        this.cloudStorage
          .upload(file, {
            fileType: file.type,
            fileExt: file.name.split(".").pop() || "",
          })
          .subscribe((url) => {
            if (url) {
              // preloading image
              const img = new Image();
              img.src = url;
              img.onload = () => {
                this.productOfferLinkForm?.get("offerImage")?.patchValue(url);
                this.isUploadingImage = false;
              };
              img.onerror = () => (this.isUploadingImage = false);
            } else {
              this.isUploadingImage = false;
            }
          });
      },
    });
  }

  removeOfferImage() {
    this.productOfferLinkForm?.get("offerImage")?.patchValue("");
  }

  addProductOfferLinkFeature() {
    this.productOfferLinkFeatures.push(
      new FormControl("", [Validators.required])
    );
  }

  removeProductOfferLinkFeature(index: number) {
    this.productOfferLinkFeatures.removeAt(index);
  }

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  resetOffer() {
    this.offer = {
      id: null,
      name: null,
      expirationDate: null,
      code: null,
      paymentMethods: [],
    };
  }

  getPaymentMethodsLabels(paymentMethods: any): string {
    if (typeof paymentMethods === "string") {
      try {
        paymentMethods = JSON.parse(paymentMethods);
      } catch (e) {
        console.error("Failed to parse paymentMethods:", e);
        return "";
      }
    }
    if (Array.isArray(paymentMethods)) {
      return paymentMethods.map((method) => method.label).join("; ");
    }
    return "";
  }

  onPaymentMethodChange(event: Event, option: any) {
    if ((event.target as HTMLInputElement | undefined)?.checked) {
      if (
        this.offer.paymentMethods &&
        Array.isArray(this.offer.paymentMethods) &&
        !this.offer.paymentMethods.some((pm) => pm.id === option.id)
      ) {
        this.offer.paymentMethods?.push(option);
      }
    } else {
      this.offer.paymentMethods =
        this.offer.paymentMethods && Array.isArray(this.offer.paymentMethods)
          ? this.offer.paymentMethods.filter(
              (method) => method.id !== option.id
            )
          : [];
    }
  }

  isChecked(option: any): boolean {
    return this.offer.paymentMethods && Array.isArray(this.offer.paymentMethods)
      ? this.offer.paymentMethods.some((pm) => pm.id === option.id)
      : false;
  }

  openProductOfferModal(offer: any, productOfferModal: any) {
    this.productOffer.offerId = offer.id;

    this.initProductOfferLinkForm();
    this.modalService.open(productOfferModal, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      size: "lg",
    });
  }

  validateField(controlName: string, form: FormGroup, index?: number) {
    if (index !== undefined) {
      const control = (form.controls[controlName] as FormArray)?.controls[
        index
      ];

      return {
        valid: control?.valid && (control?.touched || control?.dirty),
        invalid: control?.invalid && (control?.touched || control?.dirty),
      };
    }

    const control = form?.controls[controlName];

    return {
      valid: control?.valid && (control?.touched || control?.dirty),
      invalid: control?.invalid && (control?.touched || control?.dirty),
    };
  }

  onSubmitProductOfferLink(modal: NgbModalRef) {
    if (!this.productOfferLinkForm?.valid) {
      this.productOfferLinkForm?.markAllAsTouched();
      return;
    }

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const formData: FormType = this.productOfferLinkForm?.value;

    const body = {
      ProductOffer: {
        productId: formData.productId,
        offerId: this.productOffer.offerId,
        image: formData.offerImage,
        freeMonths: formData.freeMonths,
        features: formData.features,
      },
    };

    this.apiCall.post("productOffers", body).subscribe((response) => {
      this.spinner.hide();

      if (response.success) {
        this.toastr.success(
          "Oferta vinculada ao produto com sucesso!",
          "Tudo Certo!"
        );
        modal.close("Close click");
        this.ngOnInit();
      } else {
        this.toastr.error(response.message, "Ops :(");
      }
    });
  }

  visualizarOfertaProduto(offerId: any, modal: NgbModalRef) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall
      .get<OfferType | null>(`offers/${offerId}`)
      .subscribe((response) => {
        this.spinner.hide();

        if (response.success && response.return) {
          this.offer = response.return;

          if (response.return.ProductOffer) {
            this.productOffers = response.return.ProductOffer.map((po) => ({
              ...po,
              features: po.features ? JSON.parse(po.features) : [],
            }));
            this.modalService.open(modal, { centered: true, size: "xl" });
          }
        } else {
          this.toastr.error(response.message, "Ops :(");
        }
      });
  }

  async removerVinculoProduto(productOfferId: number) {
    this.app
      .confirm(
        "Tem certeza?",
        "Você realmente deseja remover este produto da oferta?"
      )
      .then((confirmation) => {
        if (confirmation) {
          this.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });

          this.apiCall
            .delete(`productOffers/${productOfferId}`)
            .subscribe((response) => {
              this.spinner.hide();

              if (response.success) {
                this.productOffers = this.productOffers.filter(
                  (po) => po.id !== productOfferId
                );

                this.toastr.success(
                  "Produto desvinculado com sucesso!",
                  "Tudo Certo!"
                );
              } else {
                this.toastr.error(response.message, "Ops :(");
              }
            });
        }
      });
  }
}
