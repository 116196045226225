import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SocialAuthService } from "angularx-social-login";
import { DragulaService } from "ng2-dragula";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Api from "../../../helpers/api";
import App from "../../../helpers/app";
import Me from "../../../helpers/me";
import { AuthService } from "../../../shared/auth/auth.service";

@Component({
  selector: "app-programas",
  templateUrl: "./programas.component.html",
  styleUrls: ["./programas.component.scss"],
})
export class ProgramasComponent implements OnInit {
  apiCall = this.api.new().silent();
  pageTr = 1;
  pageSize = 10;
  groups = [];
  originalGroups = [];
  groupSearched = null;
  relations = [];
  selectedRelations = [];
  capaSelecionada;
  capaSelecionadaFile;
  group = {
    title: null,
    photo: null,
    type: 1,
    needsApproval: null,
    relations: [],
  };
  type;
  user = null;
  groupsAlunos = [];
  grupoSelecionado;
  urlToPlay = null;
  safeURL = null;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private dragulaService: DragulaService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private me: Me,
    private socialAuthService: SocialAuthService,
    private app: App,
    private api: Api
  ) {
    this.me
      .get()
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
      });
    this.route.params.subscribe((params) => {
      if (params["type"]) {
        this.type = +params["type"];
      }
    });
  }

  onSelectAll() {
    this.selectedRelations = this.relations;
  }

  async ngOnInit() {
    const self = this;
    self.originalGroups = [];
    self.groups = [];
    self.groupsAlunos = [];
    self.relations = [];
    if (self.type) {
      self.group.type = self.type;
    }
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.apiCall.get("sellercustomer").subscribe(async (data) => {
      if (data.return) this.relations = data.return.relations;
    });
    const groups = await self.apiCall
      .get("groupcustomers")
      .pipe(take(1))
      .toPromise();
    if (groups.return) self.groupsAlunos = groups.return;
    let type = "1";
    if (self.type) type = self.type.toString();
    self.apiCall.get("grouptraining/" + type).subscribe(async (cat) => {
      if (cat.return) self.groups = cat.return;
      if (cat.return) self.originalGroups = cat.return;
      self.spinner.hide();
    });
  }
  async selecionarCapa() {
    let { value: file } = await Swal.fire({
      title: "Imagem de Capa",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma imagem.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher uma imagem!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos imagens de até 30MB!";
        }
        if (
          file.type != "image/png" &&
          file.type != "image/jpg" &&
          file.type != "image/jpeg"
        ) {
          return "São permitidos apenas imagens!";
        }
        this.capaSelecionadaFile = file;
        const base64 = await this.fileToBase64(file);
        if (base64) this.capaSelecionada = base64;
      },
    });
  }
  async postFile(extension, type) {
    return await new Promise<any>((res, rej) => {
      this.apiCall
        .post("auxiliar/file", { extension: extension, type: type })
        .subscribe(async (response) => {
          res(response);
        });
    });
  }
  async fileToBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  onSearchGroup() {
    if (this.groupSearched.length > 0) {
      this.groups = this.originalGroups.filter((e) =>
        e.title
          .toString()
          .toLowerCase()
          .trim()
          .includes(this.groupSearched.toString().toLowerCase().trim())
      );
    } else {
      this.onResetGroup();
    }
  }
  onResetGroup() {
    this.groups = this.originalGroups;
  }
  fileNameAndExt(str) {
    var file = str.split("/").pop();
    return [
      file.substr(0, file.lastIndexOf(".")),
      file.substr(file.lastIndexOf(".") + 1, file.length),
    ];
  }

  async insertGroup(modal) {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    if (
      self.capaSelecionadaFile &&
      self.fileNameAndExt(self.capaSelecionadaFile.name)[1]
    ) {
      const urlToPost = await self.postFile(
        self.fileNameAndExt(self.capaSelecionadaFile.name)[1],
        self.capaSelecionadaFile.type
      );
      if (!urlToPost.return) {
        self.spinner.hide();
        return;
      }
      var options = {
        headers: {
          "Content-Type": self.capaSelecionadaFile.type,
          "x-amz-acl": "public-read",
        },
      };

      const uploadedFile = await self.api.http
        .put(urlToPost.return, self.capaSelecionadaFile, options)
        .toPromise();
      const locationFile = urlToPost.return.toString().split("?", 1)[0];
      self.group.photo = locationFile;
    }
    if (self.selectedRelations) {
      self.group.relations = self.selectedRelations;
    }

    console.log("Grupo", self.group);
    let body = {
      group: self.group,
    };
    self.apiCall.post("grouptraining", body).subscribe(async (data) => {
      if (data.success) {
        self.spinner.hide();
        await self.app.alert(
          "Tudo certo!",
          self.type && self.type == "2"
            ? "seu desafio foi cadastrado com sucesso!"
            : "seu programa de aulas foi cadastrado com sucesso!",
          "success"
        );
        modal.close("Close click");
        self.ngOnInit();
      } else {
        await this.app.alert("Ops :(", data.message, "error");
        self.spinner.hide();
      }
    });
  }
  goGroup(group) {
    // @ts-ignore
    this.router.navigate(["page/personal/programas/details/" + group.id]);
  }
  onDeletePrograma(programa) {
    const self = this;
    if (!programa || !programa.id) {
      return;
    }
    self.app
      .confirm(
        "Tem certeza?",
        "" + this.type && this.type == "2"
          ? "Você realmente deseja deletar este desafio e as aulas inclusas nele? Esta ação é irreversível."
          : "Você realmente deseja deletar este programa e as aulas inclusas nele? Esta ação é irreversível."
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("grouptraining/" + programa.id)
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Programa deletado com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  openNewGroup(template) {
    this.grupoSelecionado = null;
    this.group = {
      title: null,
      photo: null,
      type: 1,
      needsApproval: false,
      relations: [],
    };
    if (this.type) {
      this.group.type = this.type;
    }
    const modalRef = this.modalService.open(template, {
      size: "lg",
      windowClass: "my-class",
    });
  }

  verificaSwitch() {
    console.log(this.group.needsApproval);
  }

  copiarAlunosGrupo() {
    if (!this.grupoSelecionado || !this.grupoSelecionado.CustomersGroupsUsers) {
      return;
    }
    for (let t of this.grupoSelecionado.CustomersGroupsUsers) {
      const found = this.relations.find((x) => x.id == t.relation);
      if (found) {
        let verify = null;
        if (this.selectedRelations && this.selectedRelations.length > 0) {
          this.selectedRelations.find((x) => x.id == found.id);
        }
        if (!verify) {
          this.selectedRelations.push(found);
        }
      }
    }
    this.selectedRelations = [...this.selectedRelations];
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  viewMidia(urlToPlay, content) {
    this.urlToPlay = urlToPlay;
    if (urlToPlay.startsWith("https://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "https://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    } else if (urlToPlay.startsWith("http://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "http://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    }
    this.modalService.open(content, { centered: true, size: "lg" });
  }
}
