<div class="card m-0 rounded-lg">
  <div class="card-header py-3 bg-primary position-relative">
    <h1 class="card-title text-center white font-medium-4 m-0">Surpresa!</h1>
    <button
      type="button"
      title="Fechar"
      class="btn btn-primary btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
      (click)="modalRef?.close('Close click')"
    >
      <i class="fa fa-times font-bold-400 white" aria-hidden="true"></i>
    </button>
  </div>
  <div class="card-content">
    <div class="card-body">
      <div
        class="w-100 flex-grow-1 d-flex flex-column justify-content-center align-items-center pt-1"
      >
        <h2 class="black font-medium-3 font-bold-500 mb-3">
          Você ganhou <b>7 dias</b> para <b>testar o I.A.GO</b> 🥳
        </h2>
        <div class="w-100 d-flex justify-content-center align-items-end mb-2">
          <h3 class="text-secondary text-center font-medium-1 font-bold-500">
            Para continuar usando após este período, será necessário atualizar
            seu plano.
          </h3>
        </div>
        <div class="w-100 d-flex justify-content-center align-items-end mb-2">
          <span class="text-secondary text-center font-medium-1 font-bold-400">
            Clique no botão abaixo para começar o teste:
          </span>
        </div>
        <div
          class="d-flex w-100 flex-column justify-content-center align-items-center"
        >
          <button
            type="button"
            class="btn btn-primary font-small-2 text-bold-600 d-flex justify-content-center align-items-center mb-2"
            (click)="submit()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="#ffffff"
            >
              <path
                d="M6.73315 13.5469L7.51761 15.1158C7.7831 15.6468 7.91584 15.9123 8.09318 16.1423C8.25054 16.3465 8.43356 16.5295 8.6377 16.6869C8.86777 16.8642 9.13325 16.997 9.66423 17.2624L11.2332 18.0469L9.66423 18.8314C9.13325 19.0968 8.86777 19.2296 8.6377 19.4069C8.43356 19.5643 8.25054 19.7473 8.09318 19.9515C7.91584 20.1815 7.7831 20.447 7.51761 20.978L6.73315 22.5469L5.94869 20.978C5.6832 20.447 5.55046 20.1815 5.37312 19.9515C5.21576 19.7473 5.03274 19.5643 4.8286 19.4069C4.59853 19.2296 4.33305 19.0968 3.80207 18.8314L2.23315 18.0469L3.80207 17.2624C4.33305 16.997 4.59853 16.8642 4.8286 16.6869C5.03274 16.5295 5.21576 16.3465 5.37312 16.1423C5.55046 15.9123 5.6832 15.6468 5.94869 15.1158L6.73315 13.5469Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M15.2332 2.54688L16.4118 5.6113C16.6938 6.34453 16.8348 6.71114 17.0541 7.01952C17.2485 7.29283 17.4872 7.53163 17.7606 7.72597C18.0689 7.94524 18.4356 8.08625 19.1688 8.36826L22.2332 9.54688L19.1688 10.7255C18.4356 11.0075 18.0689 11.1485 17.7606 11.3678C17.4872 11.5622 17.2485 11.8009 17.0541 12.0743C16.8348 12.3826 16.6938 12.7493 16.4118 13.4825L15.2332 16.5469L14.0546 13.4825C13.7726 12.7493 13.6316 12.3826 13.4123 12.0743C13.2179 11.8009 12.9792 11.5622 12.7058 11.3678C12.3975 11.1485 12.0308 11.0075 11.2976 10.7255L8.23315 9.54688L11.2976 8.36826C12.0308 8.08625 12.3975 7.94524 12.7058 7.72597C12.9792 7.53163 13.2179 7.29283 13.4123 7.01952C13.6316 6.71114 13.7726 6.34453 14.0546 5.6113L15.2332 2.54688Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span class="ml-2 white"> Testar I.A.GO </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
