<div class="w-100 card m-0 modal-container position-relative">
  <button
    type="button"
    (click)="closeButton()"
    class="btn btn-danger btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
  >
    <i class="ft-x font-medium-2 m-0"></i>
  </button>
  <div class="w-100 flex-grow-1 position-relative banner-body-content">
    <img
      [src]="variants[2]"
      alt="Imagem informando o termino do teste gratuito e convidando a assinar o plano"
      width="800"
      height="600"
      (click)="goCheckoutIago()"
      style="cursor: pointer"
      class="img-fluid hero-image"
    />
  </div>
  <div
    class="w-100 d-flex justify-content-between banner-footer-content pb-3 pb-lg-4 px-4 px-lg-5"
  >
    <button
      type="button"
      class="btn btn-outline-light px-4 font-small-2 text-bold-600 mr-2"
      (click)="closeButton()"
    >
      Cancelar
    </button>
    <a
      title="Substituir exercício"
      target="_blank"
      href="https://opersonaldigital.com.br/app-chegou-o-IAGO"
      class="btn btn-primary px-4 font-small-2 text-bold-600 white"
    >
      Ver
    </a>
  </div>
</div>
