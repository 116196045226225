<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300"style="padding-bottom: 1.5rem;">Banco de Categorias de Exercícios</h2>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="d-flex justify-content-between">
    <div class="col-sm-12">
      <button class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton" (click)="OpenModalXl(contentTutoriais)"><i
          class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
      </button>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col-5">
    <input type="text" id="volt"  class="form-control" style="background-color: white; height: 100%" placeholder="Procurar categoria" name="volt" [(ngModel)]="categoriaSelecionada" (keyup)="onSearchCategory()" >
  </div>
  <div class="col" >
    <div class=" float-right d-none d-md-block" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openCategory(null, categoryDetail)" >Adicionar Categoria</button>
    </div>
    <div class="btn-group btn-group-lg d-md-none" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openCategory(null, categoryDetail)" ><i class="fa fa-plus"></i></button>
    </div>
  </div>
</div>


<div class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <ul class="list-group">
          <li *ngFor="let category of categories | slice: (pageTr-1) * pageSize : (pageTr-1) * pageSize + pageSize" class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">{{category.name}}</h5>
                <span>{{category.user ? 'personal' : 'plataforma'}}</span>
              </div>
              <div class="btn-group btn-group-lg" *ngIf="category.user" role="group">
                <button type="button" class="btn btn-outline-warning" (click)="onDeleteCategory(category)"><i class="fa fa-trash"></i></button>
                <button type="button" class="btn btn-outline-warning" (click)="openCategory(category, categoryDetail)"><i class="fa fa-edit"></i></button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="categories.length>0">
  <div class="col">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        [(page)]="pageTr"
        [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
        [collectionSize]="categories.length"></ngb-pagination>
    </div>
  </div>
</div>
<div class="row" *ngIf="categories.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <ul class="list-group p-0">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhuma categoria cadastrada.</h5>
                <span>Cadastre a sua primeira categoria!</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>


<ng-template #categoryDetail let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Categoria</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br/>
              <div class="form-group mb-2">
                <label for="name">Nome da Categoria</label>
                <input type="text" id="name" class="form-control" name="name" [(ngModel)]="inputCategoria" #inputName="ngModel" required>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block" [ngClass]="categoria && categoria.id ? 'btn-danger' : 'btn-secondary'" (click)="modal.close('Close click')">{{categoria && categoria.id ? 'Fechar' : 'Cancelar'}}</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="inserirOuAtualizarCategoria(modal)" [disabled]="!categoria || !inputCategoria">{{categoria && categoria.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #contentVideo>
  <ng-template [ngIf]="urlToPlay.startsWith('https://www.youtube.com/') || urlToPlay.startsWith('http://www.youtube.com/')">
    <div class='embed-container'><iframe  [src]="safeURL" frameborder='0' allowfullscreen></iframe></div>
  </ng-template>
  <ng-template [ngIf]="!urlToPlay.startsWith('https://www.youtube.com/') && !urlToPlay.startsWith('http://www.youtube.com/')">
    <video controls style="width: 100%;">
      <source [src]="urlToPlay" type="video/mp4">
    </video>
  </ng-template>
</ng-template>
<ng-template #contentGif>
  <IMG [src]="urlToPlay" style="width: 100%;">
</ng-template>

<ng-template #contentTutoriais let-modal>
  <div class="card-header">
    <h4 class="card-title">Tutoriais</h4>
  </div>
  <button class="close-button" (click)="modal.close('Close click')"><i class="fa fa-times" aria-hidden="true"></i></button>
  <div class="form-row" style="margin: 5px">
    <ul class="list-group mb-2" style="width: 100%">
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">1</span>
          <div class="media-body">
            <h5 class="mt-0">Banco de categorias da plataforma</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=FWO-GCNemOY', contentVideo )" >Vídeo</button>
          </div>
          <div class="d-md-none">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=FWO-GCNemOY', contentVideo )" >Vídeo</button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">2</span>
          <div class="media-body">
            <h5 class="mt-0">Como gerenciar o banco de categorias</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=16RB4-AM3RU', contentVideo )" >Vídeo</button>
          </div>
          <div class="d-md-none">
            <button type="button" class="btn btn-outline-warning mb-2 mb-md-0" (click)="viewMidia( 'https://www.youtube.com/watch?v=16RB4-AM3RU', contentVideo )" >Vídeo</button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
