import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import {
  ExecutionType,
  ExerciseType,
  TrainingHistory,
} from "app/personal/pages/acompanhamento-execucoes/acompanhamento-execucoes.types";
import { DeviceDetectorService } from "ngx-device-detector";
import { BehaviorSubject } from "rxjs";
import { LoadEvolutionModalComponent } from "../load-evolution-modal/load-evolution-modal.component";

@Component({
  selector: "app-row-details",
  templateUrl: "./row-details.component.html",
  styleUrls: ["./row-details.component.scss"],
})
export class RowDetailsComponent implements OnInit {
  apiCall = this.api.new().silent();

  @Input("execution") execution: ExecutionType | null = null;

  loadEvolutionModal: LoadEvolutionModalComponent | null = null;
  trainingHistory: TrainingHistory | null = null;

  clientDevice: "desktop" | "mobile" | null = null;

  $sLoading = new BehaviorSubject<boolean>(false);

  constructor(
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
    private api: Api
  ) {}

  ngOnInit(): void {
    if (this.execution?.trainingId) {
      this.setDeviceType();

      this.$sLoading.next(true);

      this.apiCall
        .get<TrainingHistory>("training/historico/" + this.execution.trainingId)
        .subscribe((response) => {
          this.$sLoading.next(false);

          if (response.error) return;

          this.trainingHistory = response.return;

          if (this.loadEvolutionModal) {
            this.loadEvolutionModal.trainingHistory = this.trainingHistory;
            this.loadEvolutionModal.calculateGraphData();
          }
        });
    }

    this.$sLoading.subscribe((loading) => {
      if (this.loadEvolutionModal) {
        this.loadEvolutionModal.isLoading = loading;
      }
    });
  }

  hasFeedback(execution: ExecutionType) {
    return !!execution.hasExerciseComment || !!execution.observation;
  }

  hasLoadEvolution(execution: ExecutionType) {
    return !!execution.loadNotification;
  }

  hasExerciseLoadEvolution(exercise: ExerciseType) {
    return exercise.loadVariation === "up";
  }

  hasExerciseLoadVariation(exerciseId: number) {
    const exercise = this.trainingHistory?.historicalExercicesFeedback?.find(
      (exercise) => exercise.id === exerciseId
    );

    return (
      exercise &&
      exercise.feedbackData &&
      exercise.feedbackData.length &&
      exercise.feedbackData.length > 0
    );
  }

  openLoadEvolutionModal(execution: ExecutionType, exerciseId?: number) {
    this.loadEvolutionModal = this.modalService.open(
      LoadEvolutionModalComponent,
      {
        centered: true,
        size: "lg",
      }
    ).componentInstance as LoadEvolutionModalComponent;

    this.loadEvolutionModal.trainingHistory = this.trainingHistory;
    this.loadEvolutionModal.exercises = execution.exercises;
    this.loadEvolutionModal.exerciseSelected =
      execution.exercises.find((exercise) => exercise.id === exerciseId)?.id ||
      null;
  }

  setDeviceType() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktop = this.deviceService.isDesktop();

    this.clientDevice =
      isMobile || isTablet ? "mobile" : isDesktop ? "desktop" : null;
  }
}
