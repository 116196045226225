<div class="row match-height" *ngIf="mobileType === 'ios'">
  <div class="col-md-6 col-12 text-center">
      <div class="card-content">
        <div class="card-body">
          <div class="row d-flex">
            <div class="col align-self-center">
              <p class="card-text">Adicione esse app à sua lista de aplicativos: Clique em  <img src="../../../assets/img/ios.png" width="15px" alt=""> e depois <b>Adicionar à Tela de Início</b></p>
              <button type="button" class="btn btn-outline-info"  (click)="close('O usuário cancelou a operação')">Talvez mais Tarde</button>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>

<div class="row match-height" *ngIf="mobileType === 'android'">
  <div class="col-md-6 col-12 text-center">
    <div class="card-content">
      <div class="card-body">
        <div class="row d-flex">
          <div class="col align-self-center">
            <p class="card-text">Adicione esse app à sua lista de aplicativos: Clique em Adicionar!</p>
            <button type="button" class="btn btn-outline-success" style="margin-right: 5px" (click)="installPwa()">Adicionar</button>
            <button type="button" class="btn btn-outline-danger" style="margin-left: 5px" (click)="close('O usuário cancelou a operação')">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
