<div class="card m-0 position-relative">
  <button
    type="button"
    (click)="closeModal()"
    class="btn btn-danger btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
  >
    <i class="ft-x font-medium-2 m-0"></i>
  </button>

  <div class="card-content">
    <div class="card-body p-2 p-md-3">
      <div class="row">
        <div class="col-12 mb-3 mb-md-4">
          <div class="w-100 d-flex justify-content-center">
            <span class="font-size-8 font-size-md-10 text-bold-500">
              Imagem do Produto
            </span>
          </div>
        </div>
        <div *ngIf="isUploadingImage && !coverImage" class="col-12 mb-3">
          <div class="w-100 d-flex flex-column">
            <span class="font-size-4 font-size-md-5 text-bold-500 mb-2">
              Salvando imagem...
            </span>
            <div class="w-100 d-flex justify-content-start">
              <div class="d-flex bg-grey bg-lighten-4 rounded-sm">
                <div class="d-flex overflow-hidden my-2 mx-4">
                  <div
                    class="d-flex justify-content-center align-items-center load-spinner-content"
                  >
                    <div class="load-spinner">
                      <i class="ft-loader text-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-3 d-none d-md-block">
          <div class="w-100 d-flex flex-column">
            <span class="font-size-4 font-size-md-5 text-bold-500 mb-2">
              Faça o upload da imagem de capa do seu produto
            </span>
            <div
              ng2FileDrop
              [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
              (fileOver)="!isUploadingImage && onfileOver($event)"
              (onFileDrop)="!isUploadingImage && onFileDrop($event)"
              [uploader]="uploader"
              class="py-4 py-md-5 text-center font-size-4 font-size-md-6 text-bold-500 text-uppercase grey my-drop-zone"
            >
              Arraste uma imagem para cá
            </div>
          </div>
        </div>
        <div class="col-12 mb-3">
          <div>
            <span
              class="d-block d-md-none font-size-4 font-size-md-5 text-bold-500 mb-2"
            >
              Ou fazer o upload da imagem:
            </span>
            <div class="w-100 d-flex justify-content-between">
              <div class="custom-file-upload">
                <button type="button" (click)="fileInput.click()">
                  Escolher arquivo
                </button>
              </div>

              <input
                type="file"
                id="cover"
                #fileInput
                class="hidden"
                (change)="onImageChange($event)"
                accept="image/*"
                formControlName="banner"
              />
              <div class="d-none d-md-flex">
                <div
                  class="d-flex flex-column font-size-2 font-size-md-3 text-bold-500 text-uppercase mr-2"
                >
                  <span class="mb-1">
                    <img
                      src="../../../../assets/img/svg/file-type.svg"
                      alt="Icone de tipo de arquivo"
                      class="img-fluid mr-1"
                    />
                    tipo de arquivo
                  </span>
                  <span>
                    <svg
                      width="12"
                      height="11"
                      viewBox="0 0 9 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mr-1"
                    >
                      <path
                        d="M7.52682 0.606445C8.15976 0.606445 8.67715 1.10123 8.7133 1.72511L8.71532 1.79494V6.54894C8.71532 7.18189 8.22054 7.69927 7.59665 7.73542L7.52682 7.73744H1.98049C1.34754 7.73744 0.830158 7.24266 0.79401 6.61877L0.791992 6.54894V1.79494C0.791992 1.162 1.28677 0.644612 1.91066 0.608463L1.98049 0.606445H7.52682ZM7.52682 1.39878H1.98049C1.77732 1.39878 1.60987 1.55171 1.58699 1.74874L1.58432 1.79494V6.54894C1.58432 6.75211 1.73726 6.91955 1.93429 6.94244L1.98049 6.9451H7.52682C7.72999 6.9451 7.89743 6.79217 7.92032 6.59514L7.92298 6.54894V1.79494C7.92298 1.59178 7.77005 1.42433 7.57302 1.40144L7.52682 1.39878ZM6.73449 4.17194C6.93765 4.17194 7.1051 4.32488 7.12799 4.52191L7.13065 4.56811V5.75661C7.13065 5.95977 6.97772 6.12722 6.78069 6.15011L6.73449 6.15277H5.54599C5.32719 6.15277 5.14982 5.9754 5.14982 5.75661C5.14982 5.55344 5.30276 5.38599 5.49979 5.36311L5.54599 5.36044H6.33832V4.56811C6.33832 4.36494 6.49126 4.19749 6.68828 4.17461L6.73449 4.17194ZM3.96132 2.19111C4.18012 2.19111 4.35749 2.36848 4.35749 2.58728C4.35749 2.79044 4.20455 2.95789 4.00752 2.98078L3.96132 2.98344H3.16899V3.77577C3.16899 3.97894 3.01605 4.14639 2.81902 4.16928L2.77282 4.17194C2.56965 4.17194 2.40221 4.01901 2.37932 3.82198L2.37666 3.77577V2.58728C2.37666 2.38411 2.52959 2.21666 2.72662 2.19378L2.77282 2.19111H3.96132Z"
                        fill="black"
                      />
                    </svg>
                    resolução sugerida
                  </span>
                </div>
                <div
                  class="d-flex flex-column font-size-2 font-size-md-3 text-uppercase"
                >
                  <span class="grey mb-1">jpeg, jpg, png</span>
                  <span class="grey">16:9</span>
                </div>
              </div>
            </div>
            <span
              *ngIf="!coverImage && !isUploadingImage"
              class="d-block font-size-4 font-size-md-5 mt-2"
            >
              Nenhum arquivo selecionado
            </span>
            <div class="d-flex d-md-none mt-2">
              <div
                class="d-flex flex-column font-size-2 font-size-md-3 text-bold-500 text-uppercase mr-2"
              >
                <span class="mb-1">
                  <img
                    src="../../../../assets/img/svg/file-type.svg"
                    alt="Icone de tipo de arquivo"
                    class="img-fluid mr-1"
                  />
                  tipo de arquivo
                </span>
                <span>
                  <svg
                    width="12"
                    height="11"
                    viewBox="0 0 9 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-1"
                  >
                    <path
                      d="M7.52682 0.606445C8.15976 0.606445 8.67715 1.10123 8.7133 1.72511L8.71532 1.79494V6.54894C8.71532 7.18189 8.22054 7.69927 7.59665 7.73542L7.52682 7.73744H1.98049C1.34754 7.73744 0.830158 7.24266 0.79401 6.61877L0.791992 6.54894V1.79494C0.791992 1.162 1.28677 0.644612 1.91066 0.608463L1.98049 0.606445H7.52682ZM7.52682 1.39878H1.98049C1.77732 1.39878 1.60987 1.55171 1.58699 1.74874L1.58432 1.79494V6.54894C1.58432 6.75211 1.73726 6.91955 1.93429 6.94244L1.98049 6.9451H7.52682C7.72999 6.9451 7.89743 6.79217 7.92032 6.59514L7.92298 6.54894V1.79494C7.92298 1.59178 7.77005 1.42433 7.57302 1.40144L7.52682 1.39878ZM6.73449 4.17194C6.93765 4.17194 7.1051 4.32488 7.12799 4.52191L7.13065 4.56811V5.75661C7.13065 5.95977 6.97772 6.12722 6.78069 6.15011L6.73449 6.15277H5.54599C5.32719 6.15277 5.14982 5.9754 5.14982 5.75661C5.14982 5.55344 5.30276 5.38599 5.49979 5.36311L5.54599 5.36044H6.33832V4.56811C6.33832 4.36494 6.49126 4.19749 6.68828 4.17461L6.73449 4.17194ZM3.96132 2.19111C4.18012 2.19111 4.35749 2.36848 4.35749 2.58728C4.35749 2.79044 4.20455 2.95789 4.00752 2.98078L3.96132 2.98344H3.16899V3.77577C3.16899 3.97894 3.01605 4.14639 2.81902 4.16928L2.77282 4.17194C2.56965 4.17194 2.40221 4.01901 2.37932 3.82198L2.37666 3.77577V2.58728C2.37666 2.38411 2.52959 2.21666 2.72662 2.19378L2.77282 2.19111H3.96132Z"
                      fill="black"
                    />
                  </svg>
                  resolução sugerida
                </span>
              </div>
              <div
                class="d-flex flex-column font-size-2 font-size-md-3 text-uppercase"
              >
                <span class="grey mb-1">jpeg, jpg, png</span>
                <span class="grey">16:9</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <button
              type="button"
              (click)="closeModal()"
              class="btn btn-outline-light px-4 px-lg-5 font-size-4 text-bold-600 mr-2"
            >
              Cancelar
            </button>
            <button
              type="button"
              [disabled]="isLoading"
              (click)="!isLoading && salvarImagem()"
              class="btn btn-warning px-4 px-lg-5 font-size-4 text-bold-600"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
