<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem;">Aulas</h1>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col" >
    <div class=" float-right d-none d-md-block" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openAula(null, aulaDetail)" >Cadastrar</button>
    </div>
    <div class="btn-group btn-group-lg d-md-none" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openAula(null, aulaDetail)" ><i class="fa fa-plus"></i></button>
    </div>
  </div>
</div>

<div class="row mb-2 mt-2" *ngIf="aulas.length > 0">
  <div class="col">
    <div class="card-content">

      <div class="card-body p-0">
        <div class="table text-center m-0">
          <table class="table-striped" style="width: 100%;">
            <thead>
            <tr>
              <th>Titulo</th>
              <th>Descrição</th>
              <th>Categoria</th>
              <th>Data</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let c of aulas| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
              <td>{{c.title}}</td>
              <td><textarea class="form-control" name="rrr" [disabled]="true" style="background-color: #e9ecef36" [(ngModel)]="c.description"></textarea></td>
              <td>{{c.OpdAcademyCategory.name}}</td>
              <td>{{formatDateWithoutTime(c.date)}}</td>
              <td>{{c.status  ? 'Ativo' : 'Inativo'}}</td>
              <td>
                <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                  <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                    <i class="ft-more-vertical"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a  class="dropdown-item" (click)="openAula(c, aulaDetail)" >Editar</a>
                    <a  class="dropdown-item" (click)="switchAula(c)">{{c.status  ? 'Inativar' : 'Ativar'}}</a>
                    <a  class="dropdown-item" (click)="onDeleteAula(c)">Deletar</a>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
          <ngb-pagination style="margin-top: 10px" *ngIf="aulas.length>0"
                          [(page)]="page"
                          [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
                          [collectionSize]="aulas.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="aulas.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhuma aula cadastrada.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>



<ng-template #aulaDetail let-modal>
  <ngx-spinner></ngx-spinner>

  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Aula</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="category">Categoria</label>
                <ng-select [appendTo]="'body'" [items]="categorias" bindLabel="name" name="fname2"  placeholder="Selecionar tipo" id="basic-form-1" [(ngModel)]="aula.category"></ng-select>
                <!-- <input type="text" id="category" class="form-control" name="category" (keydown.space)="$event.preventDefault();" [(ngModel)]="aula.category" #inputName="ngModel" required> -->
              </div>
              <div class="form-group mb-2">
                <label for="title">Título</label>
                <input type="text" id="title" class="form-control" name="title" [(ngModel)]="aula.title" #inputName="ngModel" required>
              </div>
              <div class="form-group mb-2">
                <label for="description">Descrição</label>
                <textarea id="description" rows="2" class="form-control" name="description"  #muralDescription maxlength="255"  [(ngModel)]="aula.description" #inputObse="ngModel" placeholder="Você pode informar uma descrição para este aula."></textarea>
              </div>
              <div class="form-row__description">
                {{ 255 - muralDescription.value.length }} caracteres restantes.
              </div>
              <div class="form-group mb-2">
                <label for="date">Data</label>
                <div class="form-group mb-2">
                                  
                  <input type="text" autocomplete="off" id="ed" mask="00/00/0000" placeholder="00/00/0000" class="form-control" name="ed" [(ngModel)]="aula.date" #agend="ngModel">

                 
                </div>
                <!-- <input type="text" id="date" class="form-control" name="date" (keydown.space)="$event.preventDefault();" [(ngModel)]="aula.date" #inputName="ngModel" required> -->
              </div>
              <div class="form-group mb-2">
                <div class="form-row mb-4">
                  <div class="col-12 text-center mb-2">
                    <img class="mt-2" *ngIf="thumbnail_url" [src]="thumbnail_url"
                        style="margin-left: auto; margin-right: auto; max-width: 350px;">
                        <button type="button" class="btn btn-lg gradient-mint mt-2"(click)="selecionarImagemProduto()" style="width: 100%" placement="top">Thumbnail</button>
                  </div>
              </div>
              </div>
              <div class="form-group mb-2">
                <label for="videoUrl">Url do Vídeo</label>
                <input type="text" id="videoUrl" class="form-control" name="videoUrl" (keydown.space)="$event.preventDefault();" [(ngModel)]="aula.videoUrl" #inputName="ngModel" required>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block" [ngClass]="aula && aula.id ? 'btn-danger' : 'btn-secondary'" (click)="modal.close('Close click')">{{aula && aula.id ? 'Fechar' : 'Cancelar'}}</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="inserirOuAtualizarAula(modal)" [disabled]="!aula || !aula.category || !aula.title || !aula.description || !aula.date || !thumbnail_url || !aula.videoUrl">{{aula && aula.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
