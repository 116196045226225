import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SocialAuthService } from "angularx-social-login";
import * as CryptoJS from 'crypto-js';
import { DragulaService } from "ng2-dragula";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Api from "../../../../../helpers/api";
import App from "../../../../../helpers/app";
import Me from "../../../../../helpers/me";
import { AuthService } from "../../../../../shared/auth/auth.service";


@Component({
  selector: 'app-exercicio',
  templateUrl: './exercicio.component.html',
  styleUrls: ['./exercicio.component.scss']
})
export class ExercicioComponent implements OnInit {

  // @ts-ignore
  @ViewChild("video") videoElement: ElementRef;
  @ViewChild("gif") gifElement: ElementRef;
  @ViewChild("videoYtb") youtubeElement: ElementRef;
  // @ts-ignore
  @ViewChild("canvas") canvas: ElementRef;
  apiCall = this.api.new().silent();


  categoriaSelecionada = null
  categoriasOriginais = null
  exercise = {
    id: null,
    name: null,
    url_video: null,
    url_gif: null,
    url_thumbnail: null,
    status: true,
    inicio_youtube: null,
    fim_youtube: null
  }
  duplicate = false
  exerciseToEdit = null
  url_video = null
  sha1_video = null
  safeURL = null
  video_file = null
  url_gif = null
  sha1_gif = null
  gif_file = null
  thumbnail_file = null
  url_thumbnail = null
  sha1_thumbnail = null
  categories = []
  nameToShow = null
  canvasVisible = false
  thumbnailImg = null
  constructor(private modalService: NgbModal,
    private router: Router,
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private dragulaService: DragulaService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private socialAuthService: SocialAuthService,
    private _sanitizer: DomSanitizer,
    private app: App,
    private api: Api,
    private renderer: Renderer2

  ) {
  }

  parseExerciseToEdit(exercise) {
    const self = this
    if (!self.duplicate && exercise.id) {
      self.nameToShow = exercise.name
      self.exercise.id = exercise.id
      self.exercise.name = exercise.name
      self.exercise.url_video = exercise.url_video
      self.exercise.url_gif = exercise.url_gif
      self.exercise.status = exercise.status
      self.url_video = exercise.url_video
      self.url_thumbnail = exercise.url_thumbnail
      self.thumbnailImg = exercise.url_thumbnail
      if (self.exerciseToEdit.inicio_youtube) self.exercise.inicio_youtube = this.convertSecondsToStringTime(self.exerciseToEdit.inicio_youtube)
      if (self.exerciseToEdit.fim_youtube) self.exercise.fim_youtube = this.convertSecondsToStringTime(self.exerciseToEdit.fim_youtube)
      if (self.url_video && self.url_video.startsWith('https://www.youtube.com/watch?')) {
        self.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(self.url_video.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));

      } else if (self.url_video && self.url_video.startsWith('http://www.youtube.com/watch?')) {
        self.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(self.url_video.replace('http://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));
      }
      self.url_gif = exercise.url_gif



      if (exercise.ExercisesCategories) {
        self.categoriaSelecionada = []
        for (let e of exercise.ExercisesCategories) {
          const categoryFound = self.categories.find(c => c.id == e.category)
          if (categoryFound) self.categoriaSelecionada.push(categoryFound)
        }
        self.categoriasOriginais = self.categoriaSelecionada
      }

    } else {
      self.exercise.name = exercise.name
      if (exercise.ExercisesCategories) {
        self.categoriaSelecionada = []
        for (let e of exercise.ExercisesCategories) {
          const categoryFound = self.categories.find(c => c.id == e.category)
          if (categoryFound) self.categoriaSelecionada.push(categoryFound)
        }
        self.categoriasOriginais = self.categoriaSelecionada
      }
    }
  }

  ngOnInit(): void {
    const self = this
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    self.apiCall.get('training/exercises/categories').subscribe(async data => {
      self.categories = data.return
      self.resetExercise()
      if (self.exerciseToEdit) self.parseExerciseToEdit(self.exerciseToEdit)
      self.spinner.hide()
    });
  }



  resetExercise() {
    this.exercise = {
      id: null,
      name: null,
      url_video: null,
      url_gif: null,
      url_thumbnail: null,
      status: true,
      inicio_youtube: null,
      fim_youtube: null
    }
  }

  onselectCategory() {
  }

  convertStringTimeToSeconds(string) {
    let listTime = string.split(':')
    let hours = listTime[0]
    let minutes = listTime[1]
    let seconds = listTime[2]

    return (parseInt(hours) * (60 * 60)) + (parseInt(minutes) * 60) + parseInt(seconds)
  }

  convertSecondsToStringTime(time) {
    let hours = ((time / 3600) - ((time % 3600) / 3600)).toString()
    let minutes = (((time % 3600) / 60) - ((time % 60) / 60)).toString()
    let seconds = (time % 60).toString()
    let string = [hours.padStart(2, '0'), minutes.padStart(2, '0'), seconds.padStart(2, '0')]
    return string.join(':')
  }
  async toggleVideoOrGif(isVideo, isUrl) {
    const self = this
    if (isVideo) {
      if (isUrl) {
        let { value: file } = await Swal.fire({
          title: 'Url do Vídeo do YouTube',
          input: 'text',
          showCancelButton: true,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Salvar`,
          inputValidator: async (value) => {
            if (value && value.startsWith('https://youtu.be/')) {
              value = value.replace('https://youtu.be/', 'https://www.youtube.com/watch?v=')
            }
            if (value && value.startsWith('https://www.youtube.com/shorts/')) {
              value = value.replace('https://www.youtube.com/shorts/', 'https://www.youtube.com/watch?v=')
            }
            if (value && value.startsWith('https://youtube.com/shorts/')) {
              value = value.replace('https://youtube.com/shorts/', 'https://www.youtube.com/watch?v=')
            }
            if (value && value.startsWith('https://www.youtube.com/live/')) {
              value = value.replace('https://www.youtube.com/live/', 'https://www.youtube.com/watch?v=')
            }
            if (value && value.startsWith('https://youtube.com/live/')) {
              value = value.replace('https://youtube.com/live/', 'https://www.youtube.com/watch?v=')
            }
            if (!value) {
              return 'Você precisa informar a url do YouTube!'
            }
            if (!value.startsWith('https://www.youtube.com/watch?') && !value.startsWith('http://www.youtube.com/watch?')) {
              return 'Você precisa informar uma url válida do Youtube! esta url deve começar com "https://www.youtube.com/watch?"'
            }
            self.url_video = value
            if (value.startsWith('https://www.youtube.com/watch?')) {
              self.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(self.url_video.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));

            } else {
              self.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(self.url_video.replace('http://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));
            }
            self.video_file = null
          }
        })
      } else {
        let { value: file } = await Swal.fire({
          title: 'Vídeo do Exercício',
          html:
            '<p>Antes de fazer o upload do vídeo do seu exercício você precisa garantir que o codec do áudio do vídeo esteja em formato AAC, o codec de vídeo H.264 / AVC e a resolução máxima do vídeo seja de 1280x720px.</p>' +
            '<p style="margin-bottom: 5px">para fazer isso é bem simples:</p> ' +
            '<ul style="text-align: left">' +
            '<li style="margin-bottom: 5px">1 - acesse essa ferramenta gratuita <a target="_blank"  href="https://convert-video-online.com/pt">https://convert-video-online.com/pt</a></li>' +
            '<li style="margin-bottom: 5px">2 - faça o upload do seu vídeo através do botão "Abrir Arquivo"</li>' +
            '<li style="margin-bottom: 5px">3 - selecione vídeo mp4</li>' +
            '<li style="margin-bottom: 5px">4 - em resolução selecione "HD 720p (1280x720)"</li>' +
            '<li style="margin-bottom: 5px">5 - clique em configurações e selecione o codec de vídeo "H.264 / AVC"</li>' +
            '<li style="margin-bottom: 5px">6 - clique em configurações e selecione o codec de áudio "AAC"</li>' +
            '<li style="margin-bottom: 5px">7 - clique em converter</li>' +
            '</ul>' +
            '<p>Com o vídeo convertido é só fazer o upload abaixo.</p>' +
            '<p>Se você ainda tiver dúvidas de como realizar isso, <a target="_blank" href="https://ajuda.opersonaldigital.com.br/personaltrainer/exercicios#em-que-formato-o-audio-do-meu-video-precisa-estar">clique aqui para ver um tutorial mais completo.</a></p>',
          input: 'file',
          showCancelButton: true,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Salvar`,
          inputAttributes: {
            'accept': 'video/mp4',
            'aria-label': 'Faça upload de um vídeo de até 30 segundos.'
          },
          inputValidator: async (value) => {
            if (!value) {
              return 'Você precisa escolher um vídeo!'
            }
            // @ts-ignore
            let file: File = value

            if (file.size > 30000000) {
              return 'São permitidos vídeos de até 30MB!'
            }

            const isValid = await self.verifyDuration(file)
            if (!isValid) {
              return 'São permitidos vídeos de até 30 segundos!'
            }
            self.video_file = file
            const base64 = await self.fileToBase64(file)
            if (base64) self.url_video = base64
            var reader = new FileReader(); //define a Reader
            reader.onload = function (f) {
              var file_result = this.result; // this == reader, get the loaded file "result"
              var file_wordArr = CryptoJS.lib.WordArray.create(file_result as ArrayBuffer); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
              var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
              self.sha1_video = sha1_hash.toString()

              setTimeout(() => {
                self.capture()
              }, 1000);


            };
            reader.readAsArrayBuffer(file); //read file as ArrayBuffer



          }
        })
      }
    } else {
      if (isUrl) {
        let { value: file } = await Swal.fire({
          title: 'Url do GIF do Exercício',
          input: 'text',
          showCancelButton: true,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Salvar`,
          inputValidator: async (value) => {
            if (!value) {
              return 'Você precisa informar a url do GIF!'
            }
            if (!value.startsWith('http://') && !value.startsWith('https://') && !value.endsWith('.gif')) {
              return 'Você precisa informar uma url válida para o gif!'
            }
            self.url_gif = value
            self.gif_file = null
          }
        })
      } else {
        let { value: file } = await Swal.fire({
          title: 'GIF do Exercício',
          input: 'file',
          showCancelButton: true,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Salvar`,
          inputAttributes: {
            'accept': 'image/gif',
            'aria-label': 'Faça upload de um GIF.'
          },
          inputValidator: async (value) => {
            if (!value) {
              return 'Você precisa escolher um GIF!'
            }
            // @ts-ignore
            let file: File = value

            if (file.size > 30000000) {
              return 'São permitidos gif de até 30MB!'
            }
            if (file.type != 'image/gif') {
              return 'São permitidos apenas gifs!'
            }
            self.gif_file = file
            const base64 = await self.fileToBase64(file)
            if (base64) self.url_gif = base64

            var reader = new FileReader(); //define a Reader
            reader.onload = function (f) {
              var file_result = this.result; // this == reader, get the loaded file "result"
              var file_wordArr = CryptoJS.lib.WordArray.create(file_result as ArrayBuffer); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
              var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
              self.sha1_gif = sha1_hash.toString()

              setTimeout(() => {
                self.capture()
              }, 1000);

            };
            reader.readAsArrayBuffer(file); //read file as ArrayBuffer
          }
        })
      }
    }
  }

  getIdFromUrlYouTube(url) {
    if (url.startsWith('https://www.youtube.com/watch')) {
      return url.replace('https://www.youtube.com/watch?v=', '')
    } else {
      return url.replace('http://www.youtube.com/watch?v=', '')
    }
  }


  async inserirOuAtualizarExercicio() {
    if (this.exercise.inicio_youtube && this.exercise.fim_youtube) {
      if (this.convertStringTimeToSeconds(this.exercise.fim_youtube) <= this.convertStringTimeToSeconds(this.exercise.inicio_youtube)) {
        this.app.alert('Houve um erro!', 'O tempo final do vídeo do youtube não pode ser menor que o tempo inicial!', 'warning')
        return
      }
    }
    const self = this
    let categoriesToSend = []
    if (self.categoriaSelecionada && self.categoriaSelecionada.length > 0) {
      for (let c of self.categoriaSelecionada) {
        categoriesToSend.push({
          id: c.id
        })
      }
      self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      if (self.video_file) {
        const dataUrl = await self.apiCall.get('blackbaze/urltoupload').pipe(take(1)).toPromise()
        if (!dataUrl.return) {
          self.spinner.hide()
          return
        }
        if (dataUrl.return && dataUrl.return.authorizationToken && dataUrl.return.uploadUrl && dataUrl.return.uniqid) {
          var optionsB2 = { headers: { 'Authorization': dataUrl.return.authorizationToken, 'Content-Type': self.video_file.type, 'X-Bz-File-Name': dataUrl.return.uniqid.toString() + '.mp4', 'X-Bz-Content-Sha1': self.sha1_video } };
          const returnedUpload = await self.api.http.post(dataUrl.return.uploadUrl, self.video_file, optionsB2).toPromise()
          if (returnedUpload) self.url_video = 'https://files.wecodde.com/file/wecodde/' + JSON.parse(JSON.stringify(returnedUpload)).fileName
        }
      }
      if (self.gif_file) {

        const dataUrl = await self.apiCall.get('blackbaze/urltoupload').pipe(take(1)).toPromise()
        if (!dataUrl.return) {
          self.spinner.hide()
          return
        }
        if (dataUrl.return && dataUrl.return.authorizationToken && dataUrl.return.uploadUrl && dataUrl.return.uniqid) {
          var optionsB2 = { headers: { 'Authorization': dataUrl.return.authorizationToken, 'Content-Type': self.gif_file.type, 'X-Bz-File-Name': dataUrl.return.uniqid.toString() + '.gif', 'X-Bz-Content-Sha1': self.sha1_gif } };
          const returnedUpload = await self.api.http.post(dataUrl.return.uploadUrl, self.gif_file, optionsB2).toPromise()
          if (returnedUpload) self.url_gif = 'https://files.wecodde.com/file/wecodde/' + JSON.parse(JSON.stringify(returnedUpload)).fileName
        }
      }
      if (self.thumbnail_file) {

        const dataUrl2 = await self.apiCall.get('blackbaze/urltoupload').pipe(take(1)).toPromise()
        if (!dataUrl2.return) {
          self.spinner.hide()
          return
        }
        if (dataUrl2.return && dataUrl2.return.authorizationToken && dataUrl2.return.uploadUrl && dataUrl2.return.uniqid) {
          var optionsB2 = { headers: { 'Authorization': dataUrl2.return.authorizationToken, 'Content-Type': self.thumbnail_file.type, 'X-Bz-File-Name': dataUrl2.return.uniqid.toString() + '.jpeg', 'X-Bz-Content-Sha1': self.sha1_thumbnail } };
          const returnedUpload2 = await self.api.http.post(dataUrl2.return.uploadUrl, self.thumbnail_file, optionsB2).toPromise()
          if (returnedUpload2) self.url_thumbnail = 'https://files.wecodde.com/file/wecodde/' + JSON.parse(JSON.stringify(returnedUpload2)).fileName
          console.log(self.url_thumbnail)
        }
      }
      let body = {
        exercise: {
          id: self.exercise.id ? self.exercise.id : null,
          name: self.exercise.name,
          url_video: self.url_video ? self.url_video : null,
          url_gif: self.url_gif ? self.url_gif : null,
          url_thumbnail: self.url_thumbnail ? self.url_thumbnail : null,
          inicio_youtube: self.exercise.inicio_youtube ? this.convertStringTimeToSeconds(self.exercise.inicio_youtube) : null,
          fim_youtube: self.exercise.fim_youtube ? this.convertStringTimeToSeconds(self.exercise.fim_youtube) : null,
          categories: categoriesToSend
        }
      }
      let chamada
      if (this.exercise.id) {
        if (self.categoriasOriginais) {
          for (let c of self.categoriasOriginais) {
            const found = self.categoriaSelecionada.find(e => e.id == c.id);
            if (!found) {
              categoriesToSend.push({
                id: c.id,
                deletedAt: true
              })
            }
          }
          body.exercise.categories = categoriesToSend
        }
        console.log(body)
        chamada = this.apiCall.put('training/exercises', body)
      } else {
        chamada = this.apiCall.post('training/exercises', body)
      }
      chamada.subscribe(async data => {
        if (data.success) {
          self.spinner.hide()
          await this.app.alert('Tudo certo!', self.exercise.id ? 'Seu exercício foi atualizado com sucesso' : 'Seu exercício foi cadastrado com sucesso', 'success')
          this.activeModal.close('Close click')
        } else {
          await this.app.alert('Ops :(', data.message, 'error')
          self.spinner.hide()
        }
      });
    }
  }

  async postFile(extension, type) {
    return await new Promise<any>((res, rej) => {
      this.apiCall.post('auxiliar/file', { extension: extension, type: type }).subscribe(async response => {
        res(response)
      });
    })
  }

  async fileToBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  async verifyDuration(file) {
    return await new Promise<boolean>((res, rej) => {
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        if (duration > 31) {
          res(false);
        } else {
          res(true);
        }
      }
    })
  }
  async capture() {

    const self = this

    self.thumbnailImg = null

    if (self.url_video != null && !self.url_video?.startsWith('https://www.youtube.com/')) {
      let videoHeight = this.videoElement.nativeElement.videoHeight;
      let videoWidth = this.videoElement.nativeElement.videoWidth;
      this.renderer.setProperty(this.canvas.nativeElement, "width", videoWidth);
      this.renderer.setProperty(this.canvas.nativeElement, "height", videoHeight);
      this.canvas.nativeElement.getContext("2d").drawImage(this.videoElement.nativeElement, 0, 0)
    } else if (self.url_gif) {
      let img = new Image();
      img.src = this.gifElement.nativeElement.src;
      let gifHeight = this.gifElement.nativeElement.height;
      let gifWidth = this.gifElement.nativeElement.width;
      this.renderer.setProperty(this.canvas.nativeElement, "width", gifWidth);
      this.renderer.setProperty(this.canvas.nativeElement, "height", gifHeight);
      this.canvas.nativeElement.getContext("2d").drawImage(img, 0, 0, img.width, img.height, 0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    }

    self.url_thumbnail = this.canvas.nativeElement.toDataURL('image/jpeg', 0.8)
    self.thumbnail_file = this.dataURLtoFile(self.url_thumbnail, 'thumbnail.jpeg')

    var reader = new FileReader(); //define a Reader
    reader.onload = function (f) {
      var file_result = this.result; // this == reader, get the loaded file "result"
      var file_wordArr = CryptoJS.lib.WordArray.create(file_result as ArrayBuffer); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
      var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
      self.sha1_thumbnail = sha1_hash.toString()
    }
    reader.readAsArrayBuffer(self.thumbnail_file); //read file as ArrayBuffer
  }






  dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }




}
