<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem">Dashboard</h1>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-xl-4 col-lg-4 col-md-4 col-12">
    <div class="card bg-info bg-lighten-3 pt-2 pb-2">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body secondary text-left">
              <h3 class="font-large-1 text-bold-700 secondary mb-0">
                {{ usuariosFiltered ? usuariosFiltered.length : 0 }}
              </h3>
              <span class="text-bold-700">Usuários Totais</span>
            </div>
            <div class="media-right secondary text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-lg-4 col-md-4 col-12">
    <div class="card bg-info bg-lighten-3 pt-2 pb-2">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body secondary text-left">
              <h3 class="font-large-1 text-bold-700 secondary mb-0">
                {{ getTotalType(true) }}
              </h3>
              <span class="text-bold-700">Personais</span>
            </div>
            <div class="media-right secondary text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-lg-4 col-md-4 col-12">
    <div class="card bg-info bg-lighten-3 pt-2 pb-2">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body secondary text-left">
              <h3 class="font-large-1 text-bold-700 secondary mb-0">
                {{ getTotalType(false) }}
              </h3>
              <span class="text-bold-700">Alunos</span>
            </div>
            <div class="media-right secondary text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xl-4 col-lg-4 col-md-4 col-12">
    <div class="card bg-info bg-lighten-3 pt-2 pb-2">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body secondary text-left">
              <h3 class="font-large-1 text-bold-700 secondary mb-0">
                {{ training ? training : 0 }}
              </h3>
              <span class="text-bold-700">Treinos</span>
            </div>
            <div class="media-right secondary text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-lg-4 col-md-4 col-12">
    <div class="card bg-info bg-lighten-3 pt-2 pb-2">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body secondary text-left">
              <h3 class="font-large-1 text-bold-700 secondary mb-0">
                {{ execution ? execution : 0 }}
              </h3>
              <span class="text-bold-700">Execuções</span>
            </div>
            <div class="media-right secondary text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-lg-4 col-md-4 col-12">
    <div class="card bg-info bg-lighten-3 pt-2 pb-2">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body secondary text-left">
              <h3 class="font-large-1 text-bold-700 secondary mb-0">
                {{ avaliation ? avaliation : 0 }}
              </h3>
              <span class="text-bold-700">Avaliações</span>
            </div>
            <div class="media-right secondary text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-xl-4 col-lg-4 col-md-4 col-12">
    <div class="card bg-info bg-lighten-3 pt-2 pb-2">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body secondary text-left">
              <h3 class="font-large-1 text-bold-700 secondary mb-0">
                {{ programa ? programa : 0 }}
              </h3>
              <span class="text-bold-700">Programas</span>
            </div>
            <div class="media-right secondary text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-4 col-lg-4 col-md-4 col-12">
    <div class="card bg-info bg-lighten-3 pt-2 pb-2">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body secondary text-left">
              <h3 class="font-large-1 text-bold-700 secondary mb-0">
                {{ desafio ? desafio : 0 }}
              </h3>
              <span class="text-bold-700">Desafios</span>
            </div>
            <div class="media-right secondary text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-xl-4 col-lg-4 col-md-4 col-12">
    <div class="card bg-info bg-lighten-3 pt-2 pb-2">
      <div class="card-content">
        <div class="card-body py-0">
          <div class="media pb-1">
            <div class="media-body secondary text-left">
              <h3 class="font-large-1 text-bold-700 secondary mb-0">
                {{ mural ? mural : 0 }}
              </h3>
              <span class="text-bold-700">Murais</span>
            </div>
            <div class="media-right secondary text-right">
              <i class="ft-users font-large-1"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
