import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import App from "app/helpers/app";
import { BehaviorSubject, of } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { ExerciseCategoryType } from "../../treinos-edit.types";

const bufferSize = 50;

@Component({
  selector: "app-add-exercise",
  templateUrl: "./add-exercise.component.html",
  styleUrls: ["./add-exercise.component.scss"],
})
export class AddExerciseComponent implements OnInit {
  type?: "video" | "gif";

  exercisesList: ExerciseCategoryType[] = [];

  selectedNewExerciseValue?:
    | ExerciseCategoryType & {
        urlVideo?: string | null;
        urlGif?: string | null;
        safeURL?: SafeResourceUrl | null;
      };

  get selectedNewExercise() {
    return this.selectedNewExerciseValue;
  }

  set selectedNewExercise(value) {
    let _type = this.type;
    this.type = undefined;

    if (!value) {
      this.selectedNewExerciseValue = undefined;
      return;
    }

    const { urlVideo, urlGif, safeURL } = this.getMediaUrl({
      url_video: value.url_video,
      url_gif: value.url_gif,
    });

    this.selectedNewExerciseValue = {
      ...value,
      urlVideo,
      urlGif,
      safeURL,
    };

    setTimeout(() => {
      if (urlVideo && urlGif) {
        if (_type) {
          this.type = _type;
          return;
        }
        this.type = "gif";
      } else if (urlGif && !urlVideo) {
        this.type = "gif";
      } else if (!urlGif && urlVideo) {
        this.type = "video";
      }
    }, 200);
  }

  exercisesLoading = false;
  input$ = new BehaviorSubject<string>("");

  editMode = false;
  selectOpen = false;

  @Input() exerciseInput: ExerciseCategoryType | null = null;
  @Input() existentExercisesIds: number[] = [];
  @Input() exercisesRawList: ExerciseCategoryType[] = [];
  @Input() callback: (args: { id: number; type: "video" | "gif" }) => void =
    () => {};

  constructor(
    private app: App,
    private activeModal: NgbActiveModal,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.exercisesRawList.length > 0) {
      this.exercisesList = this.exercisesRawList.slice(0, bufferSize);
    }

    if (this.exerciseInput) {
      const { urlVideo, urlGif, safeURL } = this.getMediaUrl({
        url_video: this.exerciseInput.url_video,
        url_gif: this.exerciseInput.url_gif,
      });

      this.selectedNewExercise = {
        ...this.exerciseInput,
        urlVideo,
        urlGif,
        safeURL,
      };

      if (
        this.selectedNewExercise.urlVideo &&
        this.selectedNewExercise.urlGif
      ) {
        this.type = "video";
      } else if (
        this.selectedNewExercise.urlGif &&
        !this.selectedNewExercise.urlVideo
      ) {
        this.type = "gif";
      } else if (
        !this.selectedNewExercise.urlGif &&
        this.selectedNewExercise.urlVideo
      ) {
        this.type = "video";
      }
    }

    this.onSearch();
  }

  close() {
    this.activeModal.close();
  }

  setMediaType(type: "video" | "gif") {
    this.type = type;
  }

  save() {
    if (!this.selectedNewExercise) return;

    if (!this.type) {
      this.app.alert("Atenção", "Selecione um tipo de mídia");
      return;
    }

    if (this.existentExercisesIds.includes(this.selectedNewExercise.id)) {
      this.app
        .confirm(
          "Atenção",
          "Este exercicio ja existe no treino, deseja realmente adicionar?",
          "Confirmar"
        )
        .then((res) => {
          if (!res) return;

          if (this.selectedNewExercise?.id && this.type) {
            this.callback({
              id: this.selectedNewExercise?.id,
              type: this.type,
            });
          }

          this.activeModal.close();
        });
    } else {
      this.callback({
        id: this.selectedNewExercise.id,
        type: this.type,
      });

      this.activeModal.close();
    }
  }

  fetchMore(searchTerm: string) {
    const length = this.exercisesList.length;
    let more = null;

    if (searchTerm === null || searchTerm === "" || searchTerm === undefined) {
      more = this.exercisesRawList.slice(length, bufferSize + length);
    } else {
      more = this.exercisesRawList
        .filter((exer) => exer.name.includes(searchTerm))
        .slice(length, bufferSize + length);
    }

    this.exercisesLoading = true;

    setTimeout(() => {
      this.exercisesLoading = false;
      this.exercisesList = this.exercisesList.concat(more);
    }, 200);
  }

  onSearch() {
    this.input$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          const values = this.exercisesRawList.filter((exer) =>
            exer.name
              .toLocaleLowerCase()
              .includes(searchTerm?.toLocaleLowerCase())
          );

          return of(values);
        })
      )
      .subscribe((exercises) => {
        this.exercisesList = exercises.slice(0, bufferSize);
      });
  }

  getMediaUrl({
    url_video,
    url_gif,
  }: {
    url_video?: string | null;
    url_gif?: string | null;
  }) {
    let urlVideo: string | null = null;
    let urlGif: string | null = null;
    let safeURL: SafeResourceUrl | null = null;

    if (url_video) {
      urlVideo = url_video;

      if (urlVideo.startsWith("https://www.youtube.com/watch?")) {
        safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          urlVideo.replace(
            "https://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          )
        );
      } else if (urlVideo.startsWith("http://www.youtube.com/watch?")) {
        safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          urlVideo.replace(
            "http://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          )
        );
      } else {
        safeURL = null;
      }
    }
    if (url_gif) {
      urlGif = url_gif;
    }

    return {
      urlVideo,
      urlGif,
      safeURL,
    };
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
  }

  toggleSelect() {
    this.selectOpen = !this.selectOpen;
  }
}
