import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ExercicioComponent } from "app/personal/pages/exercicios/pages/exercicio/exercicio.component";
import { CustomAdapter } from "app/shared/services/dateAdapter.service";
import { CustomDateParserFormatter } from "app/shared/services/dateParserFormatter.service";
import * as moment from "moment";
import { DragulaService } from "ng2-dragula";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
import Api from "../../../helpers/api";
import App from "../../../helpers/app";

@Component({
  selector: "app-aluno-treino",
  templateUrl: "./aluno-treino.component.html",
  styleUrls: ["./aluno-treino.component.scss"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class AlunoTreinoComponent implements OnInit {
  apiCall = this.api.new().silent();

  BAG = "bag-one";
  subs = new Subscription();
  listaExercicios = [];
  listaExerciciosFiltrados = [];
  listaExerciciosDeletadas = [];
  @Input() public relation;
  @Input() public group;
  @Input() public training;
  @Input() public canEdit;
  @Input() public isModel = false;
  @Input() public isExperimental = false;
  trainingModels = [];
  categoriesAndExercicies = [];
  exercicies = [];
  trainingModelselecionada = null;
  categoriaSelecionada = null;
  exercicioSelecionado = null;
  listaRequisicoes = [];
  listaRequisicoesDeletadas = [];
  tipos = [
    { id: 1, name: "Personalizado" },
    { id: 2, name: "Circuito" },
    { id: 3, name: "Intervalado" },
    { id: 4, name: "Avaliação" },
  ];
  velocidade = ["Lento", "Moderado", "Rápido", "Isométrico"];

  switchRepetitionAndDuration = true;
  switchExecutionAndDate = true;
  switchAquecimentoMode = false;
  switchHideVariables = false;
  isVideo = null;
  isEditingExercise = false;
  exercicie = {
    tempId: null,
    id: null,
    name: null,
    url_video: null,
    url_gif: null,
    series: null,
    repetitions: null,
    duration: null,
    load: null,
    speed: null,
    breakTime: null,
    note: null,
    order: null,
    loadFeedback: null,
    repetitionFeedback: null,
    timeFeedback: null,
    category: null,
    progressions: null,
    timeDuration: null,
    showLoadFeedback: null,
    showRepetitionFeedback: null,
    showTimeFeedback: null,
    isAquecimento: null,
    conjugado: null,
    hideVariables: null,
    corporalLoad: null,
    inicio_youtube: null,
    fim_youtube: null,
    url_thumbnail: null,
  };
  showFeedback = false;

  idTreino = null;
  idExercicio = 0;
  tipoSelecionado = null;
  intervaloTreino = null;
  voltasTreino = null;
  observacoesTreino = null;
  isAddingProgression = false;
  progressions = [];
  progressionsDeletadas = [];
  isEditingProgression = false;
  progressionInEdit = null;
  newProgression = {
    id: null,
    execution: null,
    progressionDate: !this.switchExecutionAndDate
      ? moment(new Date()).format("YYYY-MM-DD")
      : null,
    series: null,
    repetitions: null,
    duration: null,
    timeDuration: null,
    load: null,
    speed: null,
    breakTime: null,
    status: false,
  };
  newTraining = {
    id: null,
    name: null,
    note: null,
    agendamento: null,
    vencimento: null,
    type: null,
    laps: null,
    breakTime: null,
    isExperimental: false,
    isModel: false,
    status: true,
    sellerCustomer: null,
    seller: null,
    previousTraining: null,
    group: null,
    exercises: [],
  };

  nomeTreino = null;
  agendamento = null;
  vencimento = null;
  ultimoTipoSelecionado = null;

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday());
  }

  constructor(
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private dragulaService: DragulaService,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private app: App,
    private api: Api,
    private ngbCalendar: NgbCalendar,
    private dateAdapter: NgbDateAdapter<string>
  ) {
    this.subs.add(
      dragulaService.drag(this.BAG).subscribe(({ el }) => {
        //this.removeClass(el, 'ex-moved');
      })
    );
    this.subs.add(
      dragulaService.drop(this.BAG).subscribe(({ el }) => {
        //this.addClass(el, 'ex-moved');
      })
    );
    this.subs.add(
      dragulaService.over(this.BAG).subscribe(({ el, container }) => {
        //this.addClass(container, 'ex-over');
      })
    );
    this.subs.add(
      dragulaService.out(this.BAG).subscribe(({ el, container }) => {
        //this.removeClass(container, 'ex-over');
      })
    );
  }

  liberaCadastroProgressao() {
    this.resetNewProgression();
    this.isAddingProgression = true;
  }

  cancelaCadastroProgressao() {
    this.isAddingProgression = false;
    this.isEditingProgression = false;
    this.progressionInEdit = null;
    this.resetNewProgression();
  }

  switchAquecimento($event) {
    this.exercicie.isAquecimento = $event;
    this.switchAquecimentoMode = $event;
  }

  cadastraProgressao() {
    if (this.newProgression.timeDuration) {
      let totalSeconds = 0;
      if (this.newProgression.timeDuration.hour)
        totalSeconds =
          totalSeconds + this.newProgression.timeDuration.hour * 60 * 60;
      if (this.newProgression.timeDuration.minute)
        totalSeconds =
          totalSeconds + this.newProgression.timeDuration.minute * 60;
      if (this.newProgression.timeDuration.second)
        totalSeconds = totalSeconds + this.newProgression.timeDuration.second;
      this.newProgression.duration = totalSeconds;
    }
    this.progressions.push(this.newProgression);
    this.resetNewProgression();
    this.isAddingProgression = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.listaExercicios,
      event.previousIndex,
      event.currentIndex
    );
  }

  atualizaProgressao() {
    for (let p of this.progressions) {
      if (this.progressions.indexOf(p) == this.progressionInEdit) {
        p.execution = this.newProgression.execution;
        p.progressionDate = this.newProgression.progressionDate;
        p.series = this.newProgression.series;
        p.repetitions = this.newProgression.repetitions;

        if (this.newProgression.timeDuration) {
          let totalSeconds = 0;
          if (this.newProgression.timeDuration.hour)
            totalSeconds =
              totalSeconds + this.newProgression.timeDuration.hour * 60 * 60;
          if (this.newProgression.timeDuration.minute)
            totalSeconds =
              totalSeconds + this.newProgression.timeDuration.minute * 60;
          if (this.newProgression.timeDuration.second)
            totalSeconds =
              totalSeconds + this.newProgression.timeDuration.second;
          p.duration = totalSeconds;
        }
        p.load = this.newProgression.load;
        p.speed = this.newProgression.speed;
        p.breakTime = this.newProgression.breakTime;
        p.status = this.newProgression.status;
      }
    }
    this.resetNewProgression();
    this.isEditingProgression = false;
  }

  verifyNewProgression() {
    if (
      ((this.newProgression.execution && this.newProgression.execution > 1) ||
        this.newProgression.progressionDate) &&
      (this.newProgression.series ||
        this.newProgression.repetitions ||
        !(
          !this.newProgression.timeDuration ||
          (this.newProgression.timeDuration.hour == 0 &&
            this.newProgression.timeDuration.minute == 0 &&
            this.newProgression.timeDuration.second == 0)
        ) ||
        this.newProgression.load ||
        this.newProgression.speed ||
        this.newProgression.breakTime) &&
      (!this.progressions ||
        !this.progressions.find(
          (p) =>
            ((this.newProgression.execution &&
              p.execution == this.newProgression.execution) ||
              (this.newProgression.progressionDate &&
                p.progressionDate &&
                moment(p.progressionDate).isSame(
                  moment(this.newProgression.progressionDate)
                ))) &&
            this.progressions.indexOf(p) != this.progressionInEdit
        ))
    ) {
      return true;
    } else {
      return false;
    }
  }

  resetAllProgression() {
    this.progressionsDeletadas = [];
    this.progressions = [];
    this.isAddingProgression = false;
    this.isEditingProgression = false;
    this.progressionInEdit = null;
    this.resetNewProgression();
  }

  editProgression(progression) {
    this.resetNewProgression();
    this.isEditingProgression = true;
    this.progressionInEdit = this.progressions.indexOf(progression);
    let timeDuration = null;
    if (progression.duration) {
      progression.duration = Number(progression.duration);
      var h = Math.floor(progression.duration / 3600);
      var m = Math.floor((progression.duration % 3600) / 60);
      var s = Math.floor((progression.duration % 3600) % 60);
      timeDuration = { hour: h, minute: m, second: s };
    }
    if (progression.execution) {
      this.switchExecutionAndDate = true;
    } else {
      this.switchExecutionAndDate = false;
    }
    this.newProgression = {
      id: progression.id ? progression.id : null,
      execution: progression.execution ? progression.execution : null,
      progressionDate: progression.progressionDate
        ? moment(progression.progressionDate).format("YYYY-MM-DD")
        : null,
      series: progression.series ? progression.series : null,
      repetitions: progression.repetitions ? progression.repetitions : null,
      duration: progression.duration ? progression.duration : null,
      load: progression.load ? progression.load : null,
      timeDuration: timeDuration,
      speed: progression.speed ? progression.speed : null,
      breakTime: progression.breakTime ? progression.breakTime : null,
      status: progression.status ? progression.status : false,
    };
  }
  deleteProgression(progression) {
    if (progression.id) {
      progression.deletedAt = true;
      this.progressionsDeletadas.push(progression);
    }
    this.cancelaCadastroProgressao();
    const found = this.progressions.indexOf(progression);
    if (found > -1) this.progressions.splice(found, 1);
  }

  resetNewProgression() {
    this.newProgression = {
      id: null,
      execution: null,
      progressionDate: !this.switchExecutionAndDate
        ? moment(new Date()).format("YYYY-MM-DD")
        : null,
      series: null,
      repetitions: null,
      duration: null,
      load: null,
      speed: null,
      breakTime: null,
      timeDuration: null,
      status: false,
    };
  }
  switchMidia(isVideo) {
    if (isVideo) {
      this.isVideo = true;
      if (!this.exercicie.url_video) {
        this.exercicie.url_video = this.exercicioSelecionado.Exercise
          .url_video_blackbaze
          ? this.exercicioSelecionado.Exercise.url_video_blackbaze
          : this.exercicioSelecionado.Exercise.url_video;
      }
      this.exercicie.url_gif = null;
    } else {
      this.isVideo = false;
      if (!this.exercicie.url_gif) {
        this.exercicie.url_gif = this.exercicioSelecionado.Exercise
          .url_gif_blackbaze
          ? this.exercicioSelecionado.Exercise.url_gif_blackbaze
          : this.exercicioSelecionado.Exercise.url_gif;
      }
      this.exercicie.url_video = null;
    }
  }
  urlToPlay = null;
  safeURL = null;

  viewMidia(isVideo, contentVideo, contentGif) {
    if (isVideo) {
      this.isVideo = true;
      if (this.exercicie.url_video) {
        this.urlToPlay = this.exercicie.url_video;
      } else {
        this.urlToPlay = this.exercicioSelecionado.Exercise.url_video_blackbaze
          ? this.exercicioSelecionado.Exercise.url_video_blackbaze
          : this.exercicioSelecionado.Exercise.url_video;
        this.exercicie.url_video = this.exercicioSelecionado.Exercise
          .url_video_blackbaze
          ? this.exercicioSelecionado.Exercise.url_video_blackbaze
          : this.exercicioSelecionado.Exercise.url_video;
      }
      this.exercicie.url_gif = null;
      if (this.urlToPlay.startsWith("https://www.youtube.com/watch?")) {
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          this.urlToPlay.replace(
            "https://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          )
        );
      } else if (this.urlToPlay.startsWith("http://www.youtube.com/watch?")) {
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          this.urlToPlay.replace(
            "http://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          )
        );
      } else {
        this.safeURL = null;
      }
      this.modalService.open(contentVideo, { centered: true });
    } else {
      this.isVideo = false;
      if (this.exercicie.url_gif) {
        this.urlToPlay = this.exercicie.url_gif;
      } else {
        this.urlToPlay = this.exercicioSelecionado.Exercise.url_gif_blackbaze
          ? this.exercicioSelecionado.Exercise.url_gif_blackbaze
          : this.exercicioSelecionado.Exercise.url_gif;
        this.exercicie.url_gif = this.exercicioSelecionado.Exercise
          .url_gif_blackbaze
          ? this.exercicioSelecionado.Exercise.url_gif_blackbaze
          : this.exercicioSelecionado.Exercise.url_gif;
      }
      this.exercicie.url_video = null;
      this.modalService.open(contentGif, { centered: true });
    }
  }

  switchRepAndDur(event) {
    this.switchRepetitionAndDuration = event;
    this.exercicie.duration = null;
    this.exercicie.repetitions = null;
  }

  switchExeAndDate(event) {
    this.switchExecutionAndDate = event;
    this.newProgression.execution = null;
    this.newProgression.progressionDate = !this.switchExecutionAndDate
      ? moment(new Date()).format("YYYY-MM-DD")
      : null;
  }

  changeSwitchVariables(event) {
    this.switchHideVariables = event;
    if (this.switchHideVariables) {
      this.exercicie.hideVariables = true;
    } else {
      this.exercicie.hideVariables = null;
    }
  }
  toggleTipoTreino() {
    this.resetaTreinoEExercicios();
    this.classificaLabelTipo(this.tipoSelecionado.id);
  }

  classificaLabelTipo(id) {
    switch (id) {
      case 1:
        this.ultimoTipoSelecionado = "Treino Personalizado";
        break;
      case 2:
        this.ultimoTipoSelecionado = "Treino Circuito";
        break;
      case 3:
        this.ultimoTipoSelecionado = "Treino Intervalado";
        break;
      case 4:
        this.ultimoTipoSelecionado = "Treino de Avaliação";
        break;
      default:
        this.ultimoTipoSelecionado = "";
        break;
    }
  }
  formatToDate(date) {
    if (date) {
      return moment(date, "DDMMYYYY").toDate();
    } else {
      return "";
    }
  }
  formatDate(date) {
    if (date) {
      return moment(date).format("DDMMYYYY");
    } else {
      return "";
    }
  }
  formatDateBrazil(date) {
    if (date) {
      return moment(date).format("DD/MM/YYYY");
    } else {
      return "";
    }
  }
  checkValidDate(date) {
    if (date) {
      const data = moment(date, "DDMMYYYY");
      return data.isValid();
    } else {
      return false;
    }
  }

  resetaTreinoEExercicios() {
    if (!this.idTreino) {
      this.agendamento = this.today;
    }
    this.newTraining = {
      id: null,
      name: null,
      note: null,
      agendamento: this.agendamento?.replace("/", "")?.replace("-", ""),
      vencimento: null,
      type: null,
      laps: null,
      breakTime: null,
      isExperimental: false,
      isModel: false,
      status: true,
      sellerCustomer: null,
      seller: null,
      previousTraining: null,
      group: null,
      exercises: [],
    };

    this.vencimento = null;
    this.listaExercicios = [];

    this.categoriaSelecionada = null;
    this.exercicioSelecionado = null;
    this.showFeedback = false;

    this.resetaExercicio();
  }

  resetaExercicio() {
    this.exercicie = {
      tempId: null,
      id: null,
      name: null,
      url_video: null,
      url_gif: null,
      series: null,
      repetitions: null,
      duration: null,
      load: null,
      speed: null,
      breakTime: null,
      note: null,
      order: null,
      loadFeedback: null,
      repetitionFeedback: null,
      timeFeedback: null,
      category: null,
      progressions: null,
      timeDuration: null,
      showLoadFeedback: null,
      showRepetitionFeedback: null,
      showTimeFeedback: null,
      isAquecimento: null,
      conjugado: null,
      hideVariables: null,
      corporalLoad: null,
      inicio_youtube: null,
      fim_youtube: null,
      url_thumbnail: null,
    };
  }

  preencheInputsTreino(training) {
    this.newTraining = { ...training };
    let vencimentoFormatado = this.formatDate(this.newTraining.vencimento);
    let agendamentoFormatado = this.formatDate(this.newTraining.agendamento);
    this.newTraining.vencimento = vencimentoFormatado;
    this.newTraining.agendamento = agendamentoFormatado;
    this.newTraining.breakTime = null;

    const agendamentoSplited = agendamentoFormatado
      ? moment(agendamentoFormatado, "DDMMYYYY").format("DD/MM/YYYY").split("/")
      : null;
    if (agendamentoSplited) {
      this.agendamento = this.dateAdapter.toModel({
        day: Number(agendamentoSplited[0]),
        month: Number(agendamentoSplited[1]),
        year: Number(agendamentoSplited[2]),
      });
    }

    const vencimentoSplited = vencimentoFormatado
      ? moment(vencimentoFormatado, "DDMMYYYY").format("DD/MM/YYYY").split("/")
      : null;
    if (vencimentoSplited) {
      this.vencimento = this.dateAdapter.toModel({
        day: Number(vencimentoSplited[0]),
        month: Number(vencimentoSplited[1]),
        year: Number(vencimentoSplited[2]),
      });
    }

    if (!this.idTreino) {
      this.agendamento = this.today;
    }

    this.tipoSelecionado = this.tipos.find((t) => t.id == training.type);
    this.classificaLabelTipo(this.tipoSelecionado.id);
    this.nomeTreino = training.name;
    training.TrainingExercises.sort(function (a, b) {
      return a.order - b.order;
      ("");
    });

    training.duration = Number(training.breakTime);
    var h = Math.floor(training.duration / 3600);
    var m = Math.floor((training.duration % 3600) / 60);
    var s = Math.floor((training.duration % 3600) % 60);
    this.newTraining.breakTime = { hour: h, minute: m, second: s };

    this.listaExercicios = [];
    this.listaExerciciosFiltrados = [];
    for (let i of training.TrainingExercises) {
      i.tempId = this.idExercicio++;
      i.disabled = false;
      if (i.repetitionsSt) {
        i.repetitions = i.repetitionsSt;
      }

      let prog = [];
      for (let p of i.TrainingExercisesProgressions) {
        prog.push({
          id: p.id,
          breakTime: p.breakTime,
          duration: p.duration,
          execution: p.execution,
          progressionDate: p.progressionDate,
          load: p.load,
          repetitions: p.repetitions,
          series: p.series,
          speed: p.speed,
          status: false,
        });
      }
      i.progressions = [];
      for (let p of prog) {
        i.progressions.push(p);
      }
      this.listaExercicios.push(i);
    }
    for (let f of this.listaExercicios) {
      if (f.conjugado != null) {
        let found = this.listaExercicios.find((x) => f.conjugado == x.id);
        if (found) {
          f.conjugado = found.tempId;
        }
      }
    }

    this.listaExerciciosFiltrados = [
      ...this.listaExercicios.filter((x) => !x.hideVariables),
    ];
    for (let e of this.listaExerciciosFiltrados) {
      e.disabled = false;
    }
    for (let e of this.listaExerciciosFiltrados) {
      if (
        this.listaExerciciosFiltrados.find(
          (x) => x.conjugado != null && x.conjugado == e.tempId
        )
      ) {
        e.disabled = true;
      }
    }
  }

  ngOnInit() {
    if (!this.relation && !this.isModel && !this.group) {
      this.activeModal.close("Close click");
    }
    this.apiCall.get("training/personal/0").subscribe(async (data) => {
      if (data.return) {
        this.trainingModels = data.return;
        if (this.group) {
          this.trainingModels = this.trainingModels.filter((t) => t.type !== 4);
          this.tipos = this.tipos.filter((t) => t.id !== 4);
        }
      }
    });

    /*
    POR QUE USAR setTimeout?
    O Ciclo de vida do Angular é bastante organizado, executando de forma consistente cada função.
    Em uma dessas funções, há a verificação dos dados do componente pai e dos seus filhos.
    Como esse componente em questão trata-se de um modal window (uma janela filha que desabilita a janela pai), podemos
    concluir que é uma filha do componente aluno!
    Esse trecho de código abaixo estava gerando a excessão ExpressionChangedAfterItHasBeenCheckedError, que ocorre porque
    há a alteração dos dados após serem checados.
    Acontece que as verificações ocorrem de forma síncrona, bastando executar o código de forma assíncrona para escapar da
    excessão.
    */
    setTimeout(() => {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
    });
    this.apiCall
      .get("training/exercises/categories")
      .subscribe(async (data) => {
        this.spinner.hide();
        this.categoriesAndExercicies = data.return;
      });

    if (this.training) {
      this.idTreino = this.training.id;
      this.preencheInputsTreino(this.training);
    } else {
      if (!this.agendamento) {
        this.agendamento = moment().format("DDMMYYYY");
      }
    }
  }

  openExercise() {
    const modalRef = this.modalService.open(ExercicioComponent, { size: "lg" });
    modalRef.result.then(
      (data) => {
        // on close
        this.apiCall
          .get("training/exercises/categories")
          .subscribe(async (data) => {
            this.spinner.hide();
            this.categoriesAndExercicies = data.return;
          });
        this.ngOnInit();
      },
      (reason) => {
        // on dismiss
      }
    );
  }

  onselectExercicio() {
    this.exercicie.name = null;
    this.exercicie.url_video = null;
    this.exercicie.url_gif = null;
    this.exercicie.category = null;
    this.isVideo = null;
    if (this.exercicioSelecionado) {
      this.exercicie.name = this.exercicioSelecionado.Exercise.name;
      this.exercicie.url_video = this.exercicioSelecionado.Exercise
        .url_video_blackbaze
        ? this.exercicioSelecionado.Exercise.url_video_blackbaze
        : this.exercicioSelecionado.Exercise.url_video;
      this.isVideo = true;
      this.exercicie.url_gif = null;
      this.exercicie.category = this.exercicioSelecionado.category;
      this.exercicie.inicio_youtube = this.exercicioSelecionado.Exercise
        .inicio_youtube
        ? this.exercicioSelecionado.Exercise.inicio_youtube
        : null;
      this.exercicie.fim_youtube = this.exercicioSelecionado.Exercise
        .fim_youtube
        ? this.exercicioSelecionado.Exercise.fim_youtube
        : null;
      this.exercicie.url_thumbnail = this.exercicioSelecionado.url_thumbnail
        ? this.exercicioSelecionado.url_thumbnail
        : null;
    }
  }

  onselectCategory() {
    this.exercicioSelecionado = null;
    this.exercicies = [];
    if (
      this.categoriaSelecionada &&
      this.categoriaSelecionada.ExercisesCategories &&
      this.categoriaSelecionada.ExercisesCategories.length > 0
    ) {
      this.categoriaSelecionada.ExercisesCategories =
        this.categoriaSelecionada.ExercisesCategories.sort((a, b) =>
          a.Exercise.name.localeCompare(b.Exercise.name)
        );
      this.exercicies = this.categoriaSelecionada.ExercisesCategories;
    }
  }

  onselectModel() {
    if (this.trainingModelselecionada) {
      this.preencheInputsTreino(this.trainingModelselecionada);
    } else {
      if (!this.idTreino) {
        this.nomeTreino = null;
        this.listaExercicios = [];
        this.listaRequisicoes = [];
      }
    }
  }

  getLabelCategory(category) {
    if (category) {
      let cat = this.categoriesAndExercicies.find((c) => c.id == category);
      if (cat) {
        return cat.name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  getNameExercise(exercise) {
    if (exercise && exercise.conjugado != null) {
      let found = this.listaExercicios.find(
        (c) => c.tempId == exercise.conjugado
      );
      if (found) {
        return found.name;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  verificaPreenchimentoExercicio() {
    if (!this.exercicioSelecionado) {
      return false;
    }
    if (!this.exercicie.url_video && !this.exercicie.url_gif) {
      return false;
    }
    if (this.exercicie.hideVariables) {
      return true;
    }
    if (
      this.tipoSelecionado.id == 1 &&
      (!this.exercicie.series ||
        this.exercicie.series == 0 ||
        ((!this.exercicie.breakTime || this.exercicie.breakTime == 0) &&
          this.exercicie.conjugado == null) ||
        ((!this.switchRepetitionAndDuration ||
          !this.exercicie.timeDuration ||
          (this.exercicie.timeDuration.hour == 0 &&
            this.exercicie.timeDuration.minute == 0 &&
            this.exercicie.timeDuration.second == 0)) &&
          (this.switchRepetitionAndDuration ||
            !this.exercicie.repetitions ||
            this.exercicie.repetitions == 0)))
    ) {
      return false;
    }

    if (
      this.tipoSelecionado.id == 2 &&
      (!this.switchRepetitionAndDuration ||
        !this.exercicie.timeDuration ||
        (this.exercicie.timeDuration.hour == 0 &&
          this.exercicie.timeDuration.minute == 0 &&
          this.exercicie.timeDuration.second == 0)) &&
      (this.switchRepetitionAndDuration ||
        !this.exercicie.repetitions ||
        this.exercicie.repetitions == 0)
    ) {
      return false;
    }
    if (
      this.tipoSelecionado.id == 3 &&
      (((!this.switchRepetitionAndDuration ||
        !this.exercicie.timeDuration ||
        (this.exercicie.timeDuration.hour == 0 &&
          this.exercicie.timeDuration.minute == 0 &&
          this.exercicie.timeDuration.second == 0)) &&
        (this.switchRepetitionAndDuration ||
          !this.exercicie.repetitions ||
          this.exercicie.repetitions == 0)) ||
        !this.exercicie.breakTime ||
        this.exercicie.breakTime == 0)
    ) {
      return false;
    }

    if (this.switchRepetitionAndDuration) {
      this.exercicie.repetitions = null;
    } else {
      this.exercicie.duration = null;
      this.exercicie.timeDuration = null;
    }

    if (
      this.tipoSelecionado.id == 4 &&
      !this.exercicie.showRepetitionFeedback &&
      !this.exercicie.showTimeFeedback &&
      !this.exercicie.showLoadFeedback
    ) {
      return false;
    }
    return true;
  }

  verificaPreenchimentoTreino() {
    if (!this.tipoSelecionado) {
      return false;
    }

    if (!this.newTraining.name) {
      return false;
    }

    if (
      (this.tipoSelecionado.id == 2 || this.tipoSelecionado.id == 3) &&
      (!this.newTraining.laps || this.newTraining.laps == 0)
    ) {
      return false;
    }

    return true;
  }

  verificaTreinoDeAvaliacaoPreenchido() {
    if (
      this.training &&
      this.training.type == 4 &&
      this.training.TrainingExecution &&
      this.training.TrainingExecution.length > 0
    ) {
      return true;
    }
    return false;
  }

  atualizarExercicio() {
    if (this.exercicie) {
      this.listaExerciciosFiltrados = [];
      this.listaExerciciosFiltrados = [
        ...this.listaExercicios.filter((x) => !x.hideVariables),
      ];
      for (let e of this.listaExercicios) {
        if (e.tempId == this.exercicie.tempId) {
          if (this.exercicie.url_gif) {
            this.exercicie.url_video = null;
          } else {
            this.exercicie.url_gif = null;
          }
          e.tempId = this.exercicie.tempId;
          e.id = this.exercicie.id;
          e.name = this.exercicie.name;
          e.url_video = this.exercicie.url_video;
          e.url_gif = this.exercicie.url_gif;
          e.series = this.exercicie.series;
          e.showLoadFeedback = this.exercicie.showLoadFeedback;
          e.showTimeFeedback = this.exercicie.showTimeFeedback;
          e.isAquecimento = this.exercicie.isAquecimento;
          e.showRepetitionFeedback = this.exercicie.showRepetitionFeedback;
          e.conjugado = this.exercicie.conjugado;
          e.corporalLoad = this.exercicie.corporalLoad;
          e.inicio_youtube = this.exercicie.inicio_youtube;
          e.fim_youtube = this.exercicie.fim_youtube;
          e.url_thumbnail = this.exercicie.url_thumbnail;

          if (this.switchRepetitionAndDuration) {
            this.exercicie.repetitions = null;
          } else {
            this.exercicie.duration = null;
            this.exercicie.timeDuration = null;
          }

          e.duration = this.exercicie.duration;
          e.repetitions = this.exercicie.repetitions;

          if (this.exercicie.timeDuration) {
            let totalSeconds = 0;
            if (this.exercicie.timeDuration.hour)
              totalSeconds =
                totalSeconds + this.exercicie.timeDuration.hour * 60 * 60;
            if (this.exercicie.timeDuration.minute)
              totalSeconds =
                totalSeconds + this.exercicie.timeDuration.minute * 60;
            if (this.exercicie.timeDuration.second)
              totalSeconds = totalSeconds + this.exercicie.timeDuration.second;
            e.duration = totalSeconds;
          }

          e.load = this.exercicie.load;
          e.speed = this.exercicie.speed;
          e.breakTime = this.exercicie.breakTime;
          e.note = this.exercicie.note;
          e.order = this.exercicie.order;
          e.loadFeedback = this.exercicie.loadFeedback;
          e.repetitionFeedback = this.exercicie.repetitionFeedback;
          e.timeFeedback = this.exercicie.timeFeedback;
          e.category = this.exercicie.category;
          let progressionsToSend = [];
          if (this.progressions) {
            for (let p of this.progressions) {
              progressionsToSend.push(p);
            }
          }

          if (this.progressionsDeletadas) {
            for (let p of this.progressionsDeletadas) {
              progressionsToSend.push(p);
            }
          }

          e.progressions = progressionsToSend;
          if (this.exercicie.conjugado != null) {
            e.breakTime = 0;
          }
        }
      }

      if (this.exercicie.conjugado != null) {
        if (this.listaExercicios.find((x) => x.conjugado == null)) {
          let searchFront = { ...this.exercicie };
          while (searchFront.conjugado != null) {
            for (let z of this.listaExercicios) {
              if (z.tempId == searchFront.conjugado) {
                z.series = searchFront.series;
                z.isAquecimento = searchFront.isAquecimento;
                searchFront = { ...z };
              }
            }
          }
          let searchBack = { ...this.exercicie };
          while (
            this.listaExercicios.find((x) => x.conjugado == searchBack.tempId)
          ) {
            for (let z of this.listaExercicios) {
              if (z.conjugado == searchBack.tempId) {
                z.series = searchBack.series;
                z.isAquecimento = searchBack.isAquecimento;
                searchBack = { ...z };
              }
            }
          }
        }
      }

      this.cancelarAtualizacaoExercicio();
    }
    if (this.idTreino && this.exercicie.id) {
      this.cadastrarTreino();
    }
  }

  cancelarAtualizacaoExercicio() {
    this.listaExerciciosFiltrados = [];
    this.listaExerciciosFiltrados = [];
    this.listaExerciciosFiltrados = [
      ...this.listaExercicios.filter((x) => !x.hideVariables),
    ];
    for (let e of this.listaExerciciosFiltrados) {
      e.disabled = false;
    }
    for (let e of this.listaExerciciosFiltrados) {
      if (
        this.listaExerciciosFiltrados.find(
          (x) => x.conjugado != null && x.conjugado == e.tempId
        )
      ) {
        e.disabled = true;
      }
    }
    this.isEditingExercise = false;
    this.resetaExercicio();
    this.resetAllProgression();
    this.switchRepetitionAndDuration = true;
    this.switchHideVariables = false;
    this.categoriaSelecionada = null;
    this.exercicioSelecionado = null;
    this.showFeedback = false;
    this.exercicies = [];
  }

  adicionarExercicio() {
    if (this.isVideo) {
      this.exercicie.url_gif = null;
    } else {
      this.exercicie.url_video = null;
      this.exercicie.inicio_youtube = null;
      this.exercicie.fim_youtube = null;
    }
    this.exercicie.tempId = this.idExercicio++;
    let progressionsToSend = [];
    for (let p of this.progressions) {
      progressionsToSend.push(p);
    }
    for (let p of this.progressionsDeletadas) {
      progressionsToSend.push(p);
    }
    this.exercicie.progressions = progressionsToSend;
    if (this.switchRepetitionAndDuration) {
      this.exercicie.repetitions = null;
    } else {
      this.exercicie.duration = null;
      this.exercicie.timeDuration = null;
    }

    if (this.exercicie.timeDuration) {
      let totalSeconds = 0;
      if (this.exercicie.timeDuration.hour)
        totalSeconds =
          totalSeconds + this.exercicie.timeDuration.hour * 60 * 60;
      if (this.exercicie.timeDuration.minute)
        totalSeconds = totalSeconds + this.exercicie.timeDuration.minute * 60;
      if (this.exercicie.timeDuration.second)
        totalSeconds = totalSeconds + this.exercicie.timeDuration.second;
      this.exercicie.duration = totalSeconds;
    }

    if (this.exercicie.conjugado != null) {
      this.exercicie.breakTime = 0;

      let searchFront = { ...this.exercicie };
      while (searchFront.conjugado != null) {
        for (let z of this.listaExercicios) {
          if (z.tempId == searchFront.conjugado) {
            z.series = searchFront.series;
            z.isAquecimento = searchFront.isAquecimento;
            searchFront = { ...z };
          }
        }
      }
    }

    this.listaExercicios.push(this.exercicie);
    this.listaExerciciosFiltrados = [];
    this.listaExerciciosFiltrados = [
      ...this.listaExercicios.filter((x) => !x.hideVariables),
    ];
    this.exercicioSelecionado = null;
    this.categoriaSelecionada = null;
    this.showFeedback = false;
    this.switchHideVariables = false;
    this.switchAquecimentoMode = false;
    this.resetaExercicio();
    this.resetAllProgression();
  }

  zeraCarga() {
    this.exercicie.load = null;
  }

  verificaExercicio(exercicio) {
    if (exercicio) {
      if (
        this.idTreino &&
        this.training &&
        this.training.type == 4 &&
        (exercicio.loadFeedback ||
          exercicio.timeFeedback ||
          exercicio.repetitionFeedback)
      ) {
        return false;
      } else {
        return true;
      }
    } else if (this.exercicie) {
      if (
        this.idTreino &&
        this.training &&
        this.training.type == 4 &&
        (this.exercicie.loadFeedback ||
          this.exercicie.timeFeedback ||
          this.exercicie.repetitionFeedback)
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  clearing() {
    this.exercicie.conjugado = null;
  }

  duplicarExercicio(exercicio) {
    let newExercicie = { ...exercicio };
    newExercicie.tempId = this.idExercicio++;
    newExercicie.id = null;
    this.listaExercicios.push(newExercicie);
  }

  editarExercicio(exercicio) {
    if (this.idTreino && this.training && this.training.type == 4) {
      this.showFeedback = true;
    }

    this.listaExerciciosFiltrados = [];
    this.listaExerciciosFiltrados = [
      ...this.listaExercicios.filter((x) => !x.hideVariables),
    ];
    for (let e of this.listaExerciciosFiltrados) {
      e.disabled = false;
    }
    if (exercicio) {
      if (exercicio.tempId != null) {
        const foundE = this.listaExerciciosFiltrados.findIndex(
          (x) => x.tempId == exercicio.tempId
        );
        if (foundE > -1) {
          this.listaExerciciosFiltrados.splice(foundE, 1);
        }
      }
      for (let e of this.listaExerciciosFiltrados) {
        if (
          this.listaExerciciosFiltrados.find(
            (x) => x.conjugado != null && x.conjugado == e.tempId
          )
        ) {
          e.disabled = true;
        }
        if (e.conjugado == exercicio.tempId) {
          e.disabled = true;
        }
      }

      if (
        this.listaExerciciosFiltrados.length == 1 ||
        this.listaExerciciosFiltrados.filter((x) => !x.disabled).length == 1
      ) {
        let exToVerify =
          this.listaExerciciosFiltrados.length == 1
            ? this.listaExerciciosFiltrados[0]
            : this.listaExerciciosFiltrados.filter((x) => !x.disabled)[0];
        if (exToVerify) {
          let searchFront = { ...exToVerify };
          while (searchFront.conjugado != null) {
            for (let z of this.listaExercicios) {
              if (z.tempId == searchFront.conjugado) {
                searchFront = { ...z };
              }
            }
          }
          if (
            exercicio.tempId != null &&
            searchFront.tempId != null &&
            exercicio.tempId == searchFront.tempId
          ) {
            if (this.listaExerciciosFiltrados.length == 1) {
              this.listaExerciciosFiltrados[0].disabled == true;
            } else if (
              this.listaExerciciosFiltrados.filter((x) => !x.disabled).length ==
              1
            ) {
              for (let i of this.listaExerciciosFiltrados) {
                if (!i.disabled) i.disabled = true;
              }
            }
          }
        }
      }

      this.categoriaSelecionada = this.categoriesAndExercicies.find(
        (c) => c.id == exercicio.category
      );
      this.onselectCategory();
      this.exercicioSelecionado = this.exercicies.find(
        (e) => e.Exercise.name == exercicio.name
      );
      if (!this.exercicioSelecionado) {
        let exercicieTemp = {
          Exercise: {
            id: "x",
            name: exercicio.name,
            url_gif: exercicio.url_gif ? exercicio.url_gif : null,
            url_video: exercicio.url_video ? exercicio.url_video : null,
          },
        };
        this.exercicioSelecionado = exercicieTemp;
      }
      this.resetaExercicio();
      this.resetAllProgression();

      this.isEditingExercise = true;
      if (exercicio.duration) {
        this.switchRepetitionAndDuration = true;
      } else if (exercicio.repetitions) {
        this.switchRepetitionAndDuration = false;
      }

      if (exercicio.isAquecimento) {
        this.switchAquecimentoMode = true;
      } else {
        this.switchAquecimentoMode = false;
      }
      if (exercicio.url_video) {
        this.isVideo = true;
      } else {
        this.isVideo = false;
      }

      this.progressions = this.idTreino
        ? exercicio.TrainingExercisesProgressions
        : exercicio.progressions;

      let timeDuration = null;
      if (exercicio.duration) {
        exercicio.duration = Number(exercicio.duration);
        var h = Math.floor(exercicio.duration / 3600);
        var m = Math.floor((exercicio.duration % 3600) / 60);
        var s = Math.floor((exercicio.duration % 3600) % 60);
        timeDuration = { hour: h, minute: m, second: s };
      }

      this.exercicie = {
        tempId: exercicio.tempId,
        id: exercicio.id,
        name: exercicio.name,
        url_video: exercicio.url_video,
        url_gif: exercicio.url_gif,
        series: exercicio.series,
        repetitions: exercicio.repetitions,
        duration: exercicio.duration,
        load: exercicio.load,
        speed: exercicio.speed,
        breakTime: exercicio.breakTime,
        note: exercicio.note,
        order: exercicio.order,
        loadFeedback: exercicio.loadFeedback,
        repetitionFeedback: exercicio.repetitionFeedback,
        timeFeedback: exercicio.timeFeedback,
        category: exercicio.category,
        progressions: exercicio.TrainingExercisesProgressions,
        timeDuration: timeDuration,
        showLoadFeedback: exercicio.showLoadFeedback,
        showRepetitionFeedback: exercicio.showRepetitionFeedback,
        showTimeFeedback: exercicio.showTimeFeedback,
        isAquecimento: exercicio.isAquecimento,
        conjugado: exercicio.conjugado,
        hideVariables: exercicio.hideVariables,
        corporalLoad: exercicio.corporalLoad,
        inicio_youtube: exercicio.inicio_youtube,
        fim_youtube: exercicio.fim_youtube,
        url_thumbnail: exercicio.url_thumbnail,
      };
      if (this.exercicie.hideVariables) {
        this.switchHideVariables = true;
      } else {
        this.switchHideVariables = false;
      }
    }
  }

  removerExercicio(exercicio) {
    const self = this;
    if (self.idTreino) {
      exercicio.deletedAt = true;
      self.listaExerciciosDeletadas.push(exercicio);
    }
    const index = self.listaExercicios.indexOf(exercicio);
    if (index > -1) {
      self.listaExercicios.splice(index, 1);
    }
    this.listaExerciciosFiltrados = [];
    this.listaExerciciosFiltrados = [
      ...this.listaExercicios.filter((x) => !x.hideVariables),
    ];
    this.resetAllProgression();
  }

  cadastrarTreino() {
    if (this.listaExercicios) {
      let listaParaEnviar = [];
      for (let e of this.listaExercicios) {
        e.order = this.listaExercicios.indexOf(e) + 1;
        if (e.progressions) {
          for (let p of e.progressions) {
            if (p.progressionDate)
              p.progressionDate = moment(
                p.progressionDate,
                "YYYY-MM-DD"
              ).toDate();
          }
        }
        listaParaEnviar.push(e);
      }
      for (let e of this.listaExerciciosDeletadas) {
        listaParaEnviar.push(e);
      }
      this.newTraining.exercises = listaParaEnviar;
      this.newTraining.type = this.tipoSelecionado.id;

      if (this.group) {
        this.newTraining.sellerCustomer = null;
        this.newTraining.isModel = false;
        this.newTraining.isExperimental = false;
        this.newTraining.group = this.group.id;
      } else if (this.isModel) {
        this.newTraining.isModel = true;
        this.newTraining.isExperimental = false;
        this.newTraining.sellerCustomer = null;
        this.newTraining.group = null;
      } else {
        this.newTraining.sellerCustomer = this.relation.id;
        this.newTraining.group = null;
        this.newTraining.isModel = false;
        this.newTraining.isExperimental = false;
      }

      if (this.newTraining.breakTime) {
        let totalSeconds = 0;
        if (this.newTraining.breakTime.hour)
          totalSeconds =
            totalSeconds + this.newTraining.breakTime.hour * 60 * 60;
        if (this.newTraining.breakTime.minute)
          totalSeconds = totalSeconds + this.newTraining.breakTime.minute * 60;
        if (this.newTraining.breakTime.second)
          totalSeconds = totalSeconds + this.newTraining.breakTime.second;
        this.newTraining.breakTime = totalSeconds;
      }

      if (this.agendamento) {
        this.newTraining.agendamento = this.formatToDate(
          this.formatDate(this.agendamento)
        );
      }
      if (this.vencimento) {
        this.newTraining.vencimento = this.formatToDate(this.vencimento);
      }
      let chamada;
      let urlChamada = null;
      if (!this.isModel && !this.group) {
        urlChamada = "training/personal/" + this.relation.id;
      } else {
        urlChamada = "training/personal/0";
      }
      if (!urlChamada) {
        return;
      }

      if (this.idTreino) {
        chamada = this.apiCall.put(urlChamada, { training: this.newTraining });
      } else {
        chamada = this.apiCall.post(urlChamada, { training: this.newTraining });
      }
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      chamada.subscribe(async (data) => {
        if (data.success) {
          this.spinner.hide();
          await this.app.alert(
            "Tudo certo!",
            this.idTreino
              ? "Seu treino foi atualizado com sucesso"
              : "Seu treino foi cadastrado com sucesso",
            "success"
          );
          this.activeModal.close("Close click");
        } else {
          await this.app.alert("Ops :(", data.message, "error");
          this.spinner.hide();
        }
      });
    }
  }
}
