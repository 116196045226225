import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { retry, timeout } from "rxjs/operators";

import { Observable, Observer, Subject } from "rxjs";
import config from "../config";
import App from "./app";

export type Callback = (response: Response) => void;

export interface ResponseInterface {
  readonly api: any;
  readonly code: number;
  readonly message: string;
  readonly return: any;
  readonly error: boolean;
  readonly error_stack: any;
}

const LOGIN_ERROR = 401;
const LOG_TITLE = "app/helpers/api.ts";

function getValidLink(link: string) {
  return link.substr(link.length - 1, 1) === "/" ? link : link + "/";
}

@Injectable()
export default class Api {
  public url: string;
  public development: boolean;
  public app: App = new App();

  constructor(readonly http: HttpClient) {
    const self = this;
    const condition = document.URL.indexOf("localhost") > -1;

    self.url = condition
      ? getValidLink(config.api.development_link)
      : getValidLink(config.api.production_link);
    self.development = condition;
  }

  public new() {
    return new Io(this);
  }
}

export class Io {
  public retry = 5;
  public timeout = 90 * 1000;

  public success = false;
  public log = false;
  public error = true;
  public exception = true;

  public loader = new Subject<boolean>();
  public loading: boolean = false;

  private headers: HttpHeaders;

  constructor(private base: Api) {
    this.loader.subscribe((bool) => (this.loading = bool));
  }

  public set(field: "log" | "error" | "exception" | "success", value: boolean) {
    this[field] = value;
    return this;
  }
  public silent(bool = true) {
    this.set("error", !bool);
    this.set("exception", !bool);
    return this;
  }

  public get<T extends unknown = any, Q extends unknown = { [a: string]: any }>(
    url?: string,
    args?: Q,
    alternativeBaseUrl?: string
  ): Observable<Response<T>> {
    const self = this;

    return new Observable((observer) => {
      self.setHeaders();
      self.loader.next(true);

      self.base.http
        .get((alternativeBaseUrl || self.base.url) + url, {
          headers: self.headers,
          params: args || {},
        })
        .pipe(retry(self.retry))
        .pipe(timeout(self.timeout))
        .subscribe(
          (data) => {
            self.loader.next(false);
            self.responseHttpOk(data, observer);
          },
          (error) => {
            self.loader.next(false);
            self.responseHttpError(error, observer);
          }
        );
    });
  }

  public post<
    T extends unknown = any,
    Q extends unknown = { [a: string]: any }
  >(
    url?: string,
    args?: Q,
    alternativeBaseUrl?: string
  ): Observable<Response<T>> {
    const self = this;

    return new Observable((observer) => {
      self.setHeaders();

      self.loader.next(true);

      self.base.http
        .post((alternativeBaseUrl || self.base.url) + url, args || {}, {
          headers: self.headers,
        })
        .pipe(retry(self.retry))
        .pipe(timeout(self.timeout))
        .subscribe(
          (data) => {
            self.loader.next(false);
            self.responseHttpOk(data, observer);
          },
          (error) => {
            self.loader.next(false);
            self.responseHttpError(error, observer);
          }
        );
    });
  }

  public put<T extends unknown = any, Q extends unknown = { [a: string]: any }>(
    url?: string,
    args?: Q,
    alternativeBaseUrl?: string
  ): Observable<Response<T>> {
    const self = this;

    return new Observable((observer) => {
      self.setHeaders();

      self.loader.next(true);

      self.base.http
        .put((alternativeBaseUrl || self.base.url) + url, args || {}, {
          headers: self.headers,
        })
        .pipe(retry(self.retry))
        .pipe(timeout(self.timeout))
        .subscribe(
          (data) => {
            self.loader.next(false);
            self.responseHttpOk(data, observer);
          },
          (error) => {
            self.loader.next(false);
            self.responseHttpError(error, observer);
          }
        );
    });
  }

  public delete<
    T extends unknown = any,
    Q extends unknown = { [a: string]: any }
  >(
    url?: string,
    args?: Q,
    alternativeBaseUrl?: string
  ): Observable<Response<T>> {
    const self = this;

    return new Observable((observer) => {
      self.setHeaders();

      self.loader.next(true);

      self.base.http
        .delete((alternativeBaseUrl || self.base.url) + url, {
          headers: self.headers,
          params: args || {},
        })
        .pipe(retry(self.retry))
        .pipe(timeout(self.timeout))
        .subscribe(
          (data) => {
            self.loader.next(false);
            self.responseHttpOk(data, observer);
          },
          (error) => {
            self.loader.next(false);
            self.responseHttpError(error, observer);
          }
        );
    });
  }

  private responseHttpOk<T extends unknown = any>(
    data: T,
    observer: Observer<Response<T>>
  ) {
    const self = this;
    const response = new Response<T>().build(data);

    if (self.log) {
      console.log(LOG_TITLE, response, this);
    }

    if (response.error === false) {
      if (self.success) {
        self.alertSuccess(response);
      }
      return observer.next(response);
    }

    if (self.error) {
      self.alertError(response);
    }

    if (self.exception) {
      observer.error(response);
    } else {
      observer.next(response);
    }
  }

  private responseHttpError<T extends unknown = any>(
    error: any,
    observer: Observer<Response<T>>
  ) {
    const self = this;

    console.error(LOG_TITLE, error, this);

    const response = new Response<T>();

    if (error.error) {
      response.build(error.error);
    }

    if (+error.status === LOGIN_ERROR) {
      localStorage.removeItem(config.me.cache_name + "_token");
      localStorage.removeItem(config.me.cache_name);
      return self.alertUnauthorized();
    }

    if (self.error) {
      self.alertError(response);
    }

    if (self.exception) {
      observer.error(response);
    } else {
      observer.next(response);
    }
  }

  private async alertSuccess(data: Response) {
    return await this.base.app.alert(
      "Sucesso",
      data.message || "Atividade realizada com sucesso!",
      "success"
    );
  }
  private async alertError(data: Response) {
    if (data.code == 403) {
      window.location.href = "pages/login";
    }

    const message = data.message;

    return await this.base.app.alert(
      "Erro",
      message && typeof message == "string"
        ? message
        : "Erro de comunicação com o servidor - " + JSON.stringify(data || {}),
      "error"
    );
  }
  private async alertUnauthorized() {
    return await this.base.app
      .alert(
        "Erro",
        "Você não tem permissões para visualizar essa página",
        "error"
      )
      .then(() => (window.location.href = "login"));
  }

  private setHeaders() {
    const self = this;
    self.headers = new HttpHeaders({ "Content-Type": "application/json" });

    try {
      const user = JSON.parse(
        localStorage.getItem(config.me.cache_name) || "{}"
      );
      if (user) {
        self.headers = new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Basic " + btoa(user.email + ":" + user.password),
        });
      }
    } catch (e) {
      console.error(LOG_TITLE, `setHeaders()`, e);
    }
  }
}

export class Response<T extends unknown = any> implements ResponseInterface {
  readonly api: any;
  readonly code: number;
  readonly message: string;
  readonly return: T;
  readonly error: boolean;
  readonly error_stack: any;

  public build(data: any) {
    for (const item in data) {
      this[item] = data[item];
    }
    return this;
  }

  public get success() {
    return this.error === false;
  }
}
