import { Component, Input, OnInit } from '@angular/core';
import App from "../../helpers/app"; 
import * as moment from "moment";
@Component({
  selector: 'app-ticket-payment',
  templateUrl: './ticket-payment.component.html',
  styleUrls: ['./ticket-payment.component.scss']
})
export class TicketPaymentComponent implements OnInit {
  @Input() invoice: any;
  @Input() bankSlip: any;
  maskedCnpjCpf: string;
  maskedSellerDoc: string
  constructor(private app: App,) {}

  ngOnInit() {
    if (this.invoice) {
      this.maskedCnpjCpf = this.maskCnpjCpf(this.invoice.payer_cpf_cnpj);
      this.maskedSellerDoc = this.maskCnpjCpf(this.invoice.sellerDoc);
    }
  }

  maskCnpjCpf(cnpjCpf: string): string {
    if (!cnpjCpf) return '';
    const length = cnpjCpf.length;
    return length === 11 
      ? `${cnpjCpf.slice(0, 3)}*******${cnpjCpf.slice(-2)}` 
      : `${cnpjCpf.slice(0, 3)}********${cnpjCpf.slice(-2)}`;
  }

  copyBarcodeData() {
    navigator.clipboard.writeText(this.bankSlip.barcode_data).then(() => {
      this.app.alert('Código de barras copiado para a área de transferência.', 'success');
    }, (err) => {
      console.error('Erro ao copiar o código de barras: ', err);
    });
  }
  formatDate(lastLogin) {
    return moment(lastLogin).format("DD/MM/YYYY");
  }
}

