<div class="share-modal">
  <button type="button" class="close-button" (click)="closeModal()">
    &times;
  </button>
  <p style="color: #424242; font-weight: 500; font-size: 23px">
    Compartilhe seu site
  </p>
  <div class="status-wrapper">
    <span class="status-indicator"></span>
    <span class="site-online">Seu site está online</span>
  </div>

  <div class="url-copy-wrapper">
    <input class="share-url" [value]="siteUrl" readonly />
    <i class="fa-regular ft-copy copy-button" (click)="copyUrl()"></i>
  </div>

  <p style="font-size: 13px; font-family: Montserrat; font-weight: 400">
    Compartilhe seu site pelo WhatsApp:
  </p>

  <a class="whatsapp-link" [href]="whatsappUrl" target="_blank">
    <i class="fa fa-whatsapp"></i> Enviar pelo WhatsApp
  </a>
</div>
