<div class="constainer-form">
  <form
    id="payment-form"
    target="_blank"
    [ngClass]="getCreditCard()"
    method="POST"
  >
    <div class="usable-creditcard-form">
      <div class="specifcwrapper">
        <div class="input-group nmb_a">
          <div class="icon ccic-brand"></div>
          <input
            autocomplete="off"
            data-iugu="number"
            placeholder="Número do Cartão"
            type="text"
            [(ngModel)]="novoCartao.number"
            name="ccNumber"
            ccNumber
          />
        </div>
        <div class="input-group nmb_a">
          <div class="icon ccic-cvv"></div>
          <input
            autocomplete="off"
            data-iugu="verification_value"
            placeholder="CVV"
            maxlength="4"
            type="text"
            [(ngModel)]="novoCartao.cvv"
            name="cvv"
            ccCVC
          />
        </div>
        <div class="input-group nmb_a">
          <div class="icon ccic-name"></div>
          <input
            data-iugu="full_name"
            placeholder="Titular do Cartão"
            type="text"
            [(ngModel)]="novoCartao.name"
            name="name"
          />
        </div>
        <div class="input-group nmb_a">
          <div class="icon ccic-exp"></div>
          <input
            autocomplete="off"
            data-iugu="expiration"
            placeholder="MM/AA"
            mask="00/00"
            type="text"
            [(ngModel)]="novoCartao.date"
            name="date"
            ccExp
          />
        </div>
      </div>
    </div>
  </form>
  <div class="form-row mt-4">
    <div
      class="checkbox checkbox-sm d-inline-block position-relative mr-3 mb-0"
    >
      <input
        id="create-period-training"
        type="checkbox"
        (change)="onCheckboxChange($event)"
        [disabled]="isCheckboxDisabled"
      />
      <label for="create-period-training" class="normal-case cursor-pointer"
        >Tornar esse cartão principal para pagamentos.</label
      >
    </div>
  </div>

  <div class="form-row mt-2">
    <div class="col text-center">
      <button
        type="button"
        class="btn btn-sm bg-success text-white"
        style="width: 200px"
        (click)="cadastrarCartaoEAssinar()"
      >
        Pagar
      </button>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</div>
