import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Api from 'app/helpers/api';
import App from 'app/helpers/app';
import Me from 'app/helpers/me';
import { NgxSpinnerService } from 'ngx-spinner';
declare let fbq:Function;
declare let gtag:Function;

@Component({
  selector: 'app-login-mobile',
  templateUrl: './login-mobile.component.html',
  styleUrls: ['./login-mobile.component.scss']
})
export class LoginMobileComponent implements OnInit {
  apiCall = this.api.new().silent();

  constructor(private api: Api,
    private spinner: NgxSpinnerService,
    private app: App,
    private route: ActivatedRoute,
    private me: Me,
    private router: Router,
    ) { }

  async ngOnInit() {
    const self = this
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.route.queryParams.subscribe(async (params) => {
      if (params && params['token']) {
      try {
          let aux = atob(params['token'])
          console.log("aux",aux)
          let userAux = JSON.parse(aux)
          console.log("user",userAux)
          if (userAux && userAux.email && userAux.password) {
            const login = await this.me.login(userAux.email, userAux.password, self.apiCall);
            if (login) {
              console.log("login",JSON.stringify(login))
              let pixel = null
              if (params['option']) {
                if (params['option'].toLowerCase() == '7d') {
                  pixel = 1
                } else if (params['option'].toLowerCase() == '30d') {
                  pixel = 2
                }
              }
              if (pixel) {
                if (pixel == 1) {
                  gtag('event', 'conversion', { 'send_to': 'AW-861390287/-3QRCPfk-swCEM-L35oD' });
                } else if (pixel == 2) {
                  gtag('event', 'conversion', { 'send_to': 'AW-861390287/CggkCO3j2dACEM-L35oD' });
                }
              }
              this.spinner.hide();
              
              //@ts-ignore
              if(params['redirect']){
                let redirect = atob(params['redirect'])
                if(params['isMobile']){
                  this.router.navigate([redirect], {queryParams: { isMobile: true }});
                }else{
                  this.router.navigate([redirect]);
                }
              }else{
                window.location.href="https://app.opersonaldigital.com.br/page/personal/first-onboarding"
              }
              return
            }else{
              this.spinner.hide();
            }
          }else{
            this.spinner.hide();
          }
        } catch (e) {
          this.spinner.hide();
          return self.router.navigate(["/page"]);
        }
      }else if(params && params['access']){
        try {
          let aux = atob(params['access'])
          const [first, ...rest] = aux.toString().split(":");
					let email = first;
					let password = rest.join(":");        
        
          if (aux && email && password) {            
            const login = await this.me.login(email, password, self.apiCall);
            if (login) {
            
              this.spinner.hide();
              
              window.location.href="https://app.opersonaldigital.com.br/page/?showmodalebook=1"
             
              return
            }else{
              this.spinner.hide();
            }
          }else{
            this.spinner.hide();
          }
        } catch (e) {
          this.spinner.hide();
          return self.router.navigate(["/page"]);
        }
        
      }else{
        this.spinner.hide();
      }

    })
  }

}
