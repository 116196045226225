import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Location } from "@angular/common";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import { Number } from "core-js";
import * as moment from "moment";
import { DeviceDetectorService } from "ngx-device-detector";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, of } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from "rxjs/operators";
import { CustomValidators } from "./helpers/functions/customValidators";
import { secToTimeObj } from "./helpers/functions/secToTimeObj";
import { speedSwitch } from "./helpers/functions/speedSwitch";
import { timeObjToSec } from "./helpers/functions/timeObjToSec";
import { AddExerciseComponent } from "./modals/add-exercise/add-exercise.component";
import { CtaSubscriptionComponent } from "./modals/cta-subscription/cta-subscription.component";
import {
  FeedbackType,
  FinalFeedbackComponent,
} from "./modals/final-feedback/final-feedback.component";
import { ReplaceExerciseComponent } from "./modals/replace-exercise/replace-exercise.component";
import { SchedulingComponent } from "./modals/scheduling/scheduling.component";
import { ViewExerciseComponent } from "./modals/view-exercise/view-exercise.component";
import { WarningModalComponent } from "./modals/warning-modal/warning-modal.component";
import {
  CheckboxSelectionService,
  InputsModeService,
  ProcessExercisesArrayService,
  TableExpansionService,
} from "./services";
import {
  CategoryResponseType,
  DecodedDataType,
  ExerciseCategoryType,
  FormExerciseGroupType,
  FormExerciseType,
  FormExerciseUnifiedType,
  FormType,
  PlanStatusType,
  TrainingExerciseRequestDataType,
  TrainingExerciseType,
  TrainingRequestDataType,
  TraningResponseType,
} from "./treinos-edit.types";

const bufferSize = 50;

@Component({
  selector: "app-treinos-edit",
  templateUrl: "./treinos-edit.component.html",
  styleUrls: ["./treinos-edit.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [TableExpansionService],
})
export class TreinosEditComponent implements OnInit {
  apiCall = this.api.new().silent();

  decodedData: DecodedDataType | null = null;
  planStatus: PlanStatusType | null = null;

  training: TraningResponseType | null = null;
  trainingRaw: TraningResponseType | null = null;

  initialData: FormType = {
    name: "",
    note: "",
    exercises: [
      {
        id: 0,
        name: "",
        series: "",
        repetitions: "",
        breakTime: {
          minute: 0,
          second: 0,
        },
        duration: {
          hour: 0,
          minute: 0,
          second: 0,
        },
        load: "",
        speed: "",
        note: "",
        viewType: undefined,
      },
    ],
  };

  initialTrainingForm: FormGroup = new FormGroup({
    name: new FormControl(
      {
        value: this.initialData.name,
        disabled: true,
      },
      [Validators.required]
    ),
    note: new FormControl(this.initialData.note),
    exercises: new FormArray([]),
  });

  trainingForm: FormGroup = this.initialTrainingForm;

  get exercises() {
    return this.trainingForm?.get("exercises") as FormArray;
  }

  categories: CategoryResponseType[] = [];
  exercisesRawList: ExerciseCategoryType[] = [];
  exercisesList: ExerciseCategoryType[] = [];

  selectedNewExercise: ExerciseCategoryType | null = null;

  exercisesLoading = false;
  input$ = new BehaviorSubject<string>("");

  initialFeedback: FeedbackType | null = null;
  initialFeedbackNotes: string | null = null;
  feedbackResgistered: string[] = [];
  isLoading: boolean = false;

  clientDevice: "desktop" | "mobile" | null = null;

  promotionDaysLeft: number = 0;

  // dropdown
  isOpen = false;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private deviceService: DeviceDetectorService,
    private _location: Location,
    private api: Api,
    private tableExpansionService: TableExpansionService,
    private checkboxSelectionService: CheckboxSelectionService,
    private inputsModeService: InputsModeService,
    private processExercises: ProcessExercisesArrayService
  ) {
    this.route.params.subscribe((params) => {
      if (params["encodedData"]) {
        let decodedData: DecodedDataType | null = null;

        try {
          decodedData = JSON.parse(atob(params["encodedData"]));
        } catch (error) {
          //
        }

        if (
          typeof decodedData === "object" &&
          decodedData !== null &&
          !Array.isArray(decodedData)
        ) {
          this.decodedData = decodedData;
        }
      }
    });
  }

  ngOnInit(): void {
    this.setDeviceType();

    this.isLoading = true;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall
      .get<PlanStatusType>("seller/me/verify/subscription/iago")
      .subscribe((response) => {
        if (response.success) {
          this.planStatus = response.return;
        }
      });

    const getRoute = () => {
      if (this.decodedData?.relation) {
        return (
          "training/edit/sellercustomer/" +
          this.decodedData.training +
          "/" +
          this.decodedData.relation
        );
      } else if (this.decodedData?.group) {
        return (
          "training/edit/group/" +
          this.decodedData.training +
          "/" +
          this.decodedData.group
        );
      } else if (this.decodedData?.isModel) {
        return "training/edit/model/" + this.decodedData.training;
      }
    };

    if (this.decodedData?.training) {
      this.exercisesLoading = true;

      this.apiCall
        .get<CategoryResponseType[] | null>("training/exercises/categories")
        .subscribe((response) => {
          this.exercisesLoading = false;

          if (response.error && response.message) {
            this.toastr.error(
              "Ocorreu um erro ao buscar as categorias. Tente novamente mais tarde."
            );
            return;
          }

          const categories = response.return;

          if (categories) {
            const exercises = categories.reduce(
              (acc, category) => [
                ...acc,
                ...category.ExercisesCategories.map((exercise) => ({
                  ...exercise.Exercise,
                  categoryId: category.id,
                  categoryName: category.name,
                })),
              ],
              [] as ExerciseCategoryType[]
            );

            this.categories = categories;

            this.exercisesRawList = exercises;
            this.exercisesList = exercises.slice(0, bufferSize);

            this.apiCall
              .get<TraningResponseType | null>(getRoute())
              .subscribe((response) => {
                this.isLoading = false;
                this.spinner.hide();

                if (response.error && response.message) {
                  this.toastr.error(
                    "Ocorreu um erro ao buscar os dados do treino. Tente novamente mais tarde."
                  );

                  return;
                }

                if (response.return) {
                  if (response.return.editorVersion !== "iago_v1") {
                    this.navigateBack();
                  }

                  this.feedbackResgistered =
                    response.return?.TrainingGeneratorFeedback?.map(
                      (fb) => fb?.step
                    ) || [...this.feedbackResgistered];

                  this.setTrainigData(response.return);
                }
              });
          }
        });
    } else {
      this.isLoading = false;
      this.spinner.hide();
      this.toastr.error("Ocorreu um erro ao buscar os dados do treino.");
    }

    this.onSearch();

    this.promotionDaysLeft = moment("2024-05-06").diff(moment(), "days");
  }

  fulfillExerciseControls(
    exercise: TrainingExerciseType,
    setToForm: (exer: FormExerciseType) => void,
    getControl: (controlName: string) => AbstractControl | null
  ) {
    const duration = exercise.duration
      ? secToTimeObj(exercise.duration)
      : {
          hour: undefined,
          minute: undefined,
          second: undefined,
        };
    const repetitions = exercise.repetitions || exercise.repetitionsSt;
    const load =
      typeof exercise.load === "number" && Number.isInteger(exercise.load)
        ? exercise.load
        : "";
    const breakTime = exercise.breakTime
      ? secToTimeObj(exercise.breakTime, ["hour"])
      : {
          minute: undefined,
          second: undefined,
        };

    const categoryExerciseByName = this.exercisesRawList.find(
      (item) => item?.name === exercise.name
    );

    const viewType = categoryExerciseByName
      ? (categoryExerciseByName.url_gif && categoryExerciseByName.url_video) ||
        (categoryExerciseByName.url_gif && !categoryExerciseByName.url_video)
        ? "gif"
        : categoryExerciseByName.url_video && !categoryExerciseByName.url_gif
        ? "video"
        : ""
      : (exercise.url_gif && exercise.url_video) ||
        (exercise.url_gif && !exercise.url_video)
      ? "gif"
      : exercise.url_video && !exercise.url_gif
      ? "video"
      : "";

    const currExercise: FormExerciseType = {
      id: exercise.id,
      name: exercise.name,
      series: exercise.series,
      repetitions,
      duration,
      load,
      speed: speedSwitch(exercise.speed, "en") || exercise.speed || "",
      note: exercise.note,
      breakTime,
      viewType,
    };

    setToForm(currExercise);

    if (exercise.duration) {
      this.inputsModeService.setRepDurMode(exercise.id, "dur");
    } else {
      this.inputsModeService.setRepDurMode(exercise.id, "rep");
    }

    const repControl = getControl("repetitions");
    const durControl = getControl("duration");

    if (repControl && durControl) {
      this.inputsModeService.setRepDurModeControl(
        exercise.id,
        repControl,
        durControl
      );
    }

    if (
      !exercise.corporalLoad &&
      (exercise.load === "" ||
        (exercise.load !== null && Number.isInteger(+exercise.load)))
    ) {
      this.inputsModeService.setLoadMode(exercise.id, "load");
    } else if (exercise.corporalLoad) {
      this.inputsModeService.setLoadMode(exercise.id, "corporalLoad");
    } else {
      this.inputsModeService.setLoadMode(exercise.id, "load");
    }
  }

  setTrainigData(training: TraningResponseType) {
    const sortedTraining: TraningResponseType = {
      ...training,
      TrainingExercises: training.TrainingExercises.sort(
        (a, b) => a.order - b.order
      ),
    };

    this.training = sortedTraining;
    this.trainingRaw = sortedTraining;

    this.trainingForm?.get("name")?.patchValue(this.trainingRaw.name);
    this.trainingForm?.get("note")?.patchValue(this.trainingRaw.note);

    const unifiedExercises = this.processExercises.joinChildsToParent(
      this.trainingRaw.TrainingExercises
    );

    let groupingCount = 1;

    unifiedExercises.forEach((exercise, index) => {
      if (exercise.childs) {
        const exercises = [exercise, ...exercise.childs];

        // Troca o primeiro e o último elemento se houver mais de um
        if (exercises.length > 1) {
          const temp = exercises[0];
          exercises[0] = exercises[exercises.length - 1];
          exercises[exercises.length - 1] = temp;
        }

        const grouping: FormExerciseGroupType = {
          id: exercises.map((exer) => exer.id).join("__"),
          name: `Agrupamento ${groupingCount}`,
          childs: [],
        };

        this.exercises.push(this.makeExerciseGroupingFormGroup(grouping));

        const childsControl = this.exercises.controls[index].get(
          "childs"
        ) as FormArray;

        exercises.forEach((exer) => {
          this.fulfillExerciseControls(
            exer,
            (exer) => {
              childsControl?.push(this.makeExerciseFormGroup(exer));
            },
            (controlName) =>
              childsControl?.controls[exercises.indexOf(exer)]?.get(controlName)
          );
        });

        groupingCount++;
      } else {
        this.fulfillExerciseControls(
          exercise,
          (exer) => {
            this.exercises.push(this.makeExerciseFormGroup(exer));
          },
          (controlName) => this.exercises.controls[index].get(controlName)
        );
      }
    });
  }

  navigateBack() {
    this._location.back();
  }

  setDeviceType() {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktop = this.deviceService.isDesktop();

    this.clientDevice =
      isMobile || isTablet ? "mobile" : isDesktop ? "desktop" : null;
  }

  initForm() {
    this.trainingForm = new FormGroup({
      name: new FormControl(
        {
          value: this.initialData.name,
          disabled: true,
        },
        [Validators.required]
      ),
      note: new FormControl(this.initialData.note),
      exercises: new FormArray([]),
    });
  }

  markControlsAsTouched() {
    this.exercises.controls.forEach((control) => {
      if (control.get("childs") && control.get("childs") instanceof FormArray) {
        (control.get("childs") as FormArray).controls.forEach(
          (childControl) => {
            Object.keys(childControl.value).forEach((key) => {
              childControl.get(key)?.markAsTouched();
            });
          }
        );
      } else {
        Object.keys(control.value).forEach((key) => {
          control.get(key)?.markAsTouched();
        });
      }
    });
  }

  save({
    returnAsObservable = false,
    redirect,
    message,
    actionType,
    propertiesToOvewrite,
  }: {
    returnAsObservable?: boolean;
    redirect: boolean;
    message?: string;
    actionType: "draft" | "save";
    propertiesToOvewrite?: Partial<
      Omit<TraningResponseType, "TrainingExercises">
    >;
  }) {
    this.markControlsAsTouched();

    if (this.trainingForm?.invalid) {
      this.toastr.error("Preencha todos os campos obrigatórios", "Atenção!");
      return;
    }

    this.tableExpansionService.reset();
    this.checkboxSelectionService.reset();

    const relation = this.decodedData?.relation || null;
    const group = this.decodedData?.group || null;
    const isModel = this.decodedData?.isModel || null;

    let endpoint: string | null = null;

    if (!isModel && !group && relation) {
      endpoint = "training/personal/" + relation;
    } else {
      endpoint = "training/personal/0";
    }
    if (!endpoint) {
      this.toastr.error("Ocorreu um erro ao salvar o treino");
      return;
    }

    const splitedExercises = this.processExercises.splitChildsFromParent(
      this.trainingForm?.get("exercises")?.value
    );
    const exercises: TrainingExerciseRequestDataType[] = splitedExercises.map(
      (exercise, index: number) => {
        const isDuplicate =
          typeof exercise.id === "string" &&
          Number.isInteger(+exercise.id.split("_")?.[0]);
        const currentId = +(typeof exercise.id === "number" &&
        Number.isInteger(exercise.id)
          ? exercise.id
          : (() => {
              const id = String(exercise.id);
              return isDuplicate ? +id?.split("_")?.[1] : id;
            })());
        console.log("currentId", currentId);
        const trainingExercise = this.trainingRaw?.TrainingExercises.find(
          (item) => item?.id === currentId
        );
        console.log("trainingExercise", trainingExercise);
        const categoryExercise = this.exercisesRawList.find(
          (item) => item?.id === currentId
        );
        const categoryExerciseByName = this.exercisesRawList.find(
          (item) => item?.name === exercise.name
        );
        const category = this.categories.find(
          (item) => item?.id === categoryExercise?.categoryId
        );

        const url_video =
          exercise.viewType === "video"
            ? categoryExerciseByName?.url_video || null
            : null;
        const url_gif =
          exercise.viewType === "gif"
            ? categoryExerciseByName?.url_gif || null
            : null;

        const url_thumbnail = categoryExerciseByName?.url_thumbnail
          ? categoryExerciseByName.url_thumbnail
          : null;

        const currentExercise = (trainingExercise || categoryExercise) as
          | TrainingExerciseType
          | ExerciseCategoryType
          | undefined;

        const isRep = this.inputsModeService.getRepDurMode(currentId) === "rep";
        const isDur = this.inputsModeService.getRepDurMode(currentId) === "dur";

        const isLoad = this.inputsModeService.getLoadMode(currentId) === "load";
        const isCorporalLoad =
          this.inputsModeService.getLoadMode(currentId) === "corporalLoad";

        return {
          id: isDuplicate || !!categoryExercise ? null : currentId,
          name: exercise.name,
          url_video,
          url_gif,
          series: String(exercise.series),
          repetitions: isRep ? exercise.repetitions : null,
          duration:
            isDur && exercise.duration ? timeObjToSec(exercise.duration) : null,
          timeDuration: exercise.duration,
          load: isLoad ? exercise.load : null,
          corporalLoad: isCorporalLoad ? true : null,
          speed: speedSwitch(exercise.speed, "pt"),
          breakTime: exercise.breakTime
            ? String(timeObjToSec(exercise.breakTime))
            : "",
          note: exercise.note || null,
          order: index + 1,
          inicio_youtube: currentExercise?.inicio_youtube ?? null,
          fim_youtube: currentExercise?.fim_youtube ?? null,
          url_thumbnail: url_thumbnail ?? null,
          exerciseId: null,
          conjugado:
            exercise.conjugado === undefined
              ? (currentExercise as TrainingExerciseType)?.conjugado ?? null
              : exercise.conjugado,
          tempId: exercise.tempId ?? null,
          category: (currentExercise as TrainingExerciseType)?.category
            ? (currentExercise as TrainingExerciseType).category
            : (currentExercise as ExerciseCategoryType)?.categoryId
            ? (currentExercise as ExerciseCategoryType).categoryId
            : null,
          Category:
            categoryExercise && category
              ? {
                  id: category.id,
                  name: category.name,
                  image: category.image,
                  status: category.status,
                  deletedAt: category.deletedAt,
                  createdAt: category.createdAt,
                  updatedAt: category.updatedAt,
                  user: category.user,
                }
              : (currentExercise as TrainingExerciseType).Category ?? null,
          progressions:
            (currentExercise as TrainingExerciseType)
              .TrainingExercisesProgressions ?? [],
          createdAt: currentExercise?.createdAt || null,
          updatedAt: currentExercise?.updatedAt || null,
          deletedAt: currentExercise?.deletedAt ?? null,
          hideVariables: false,
          isAquecimento: null,
          loadFeedback: null,
          repetitionFeedback: null,
          showLoadFeedback: null,
          showRepetitionFeedback: null,
          showTimeFeedback: null,
          timeFeedback: null,
        };
      }
    );

    let TrainingExercises: TrainingExerciseType[] = [];

    if (exercises.length === this.trainingRaw?.TrainingExercises.length) {
      this.trainingRaw?.TrainingExercises.forEach((item) => {
        const index = exercises.findIndex((exer) => exer.id === item.id);

        if (index !== -1) {
          TrainingExercises[index] = { ...item, order: index + 1 };
        }
      });
    }

    const newTraining: TrainingRequestDataType = {
      ...this.trainingRaw,
      ...propertiesToOvewrite,
      name: this.trainingForm?.get("name")?.value,
      note: this.trainingForm?.get("note")?.value || null,
      TrainingExercises,
      exercises,
    };

    newTraining.type = 1;

    if (group) {
      newTraining.sellerCustomer = null;
      newTraining.isModel = false;
      newTraining.isExperimental = false;
      newTraining.group = group;
    } else if (isModel) {
      newTraining.isModel = true;
      newTraining.isExperimental = false;
      newTraining.sellerCustomer = null;
      newTraining.group = null;
    } else if (relation) {
      newTraining.sellerCustomer = relation;
      newTraining.group = null;
      newTraining.isModel = false;
      newTraining.isExperimental = false;
    }

    if (actionType !== "draft") {
      newTraining.isDraft = false;

      if (
        propertiesToOvewrite?.agendamento &&
        moment(propertiesToOvewrite.agendamento).isSameOrBefore(moment())
      ) {
        newTraining.status = true;
      }
    }

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const observable = of(null).pipe(
      switchMap(() =>
        this.apiCall.put<TraningResponseType>(endpoint, {
          training: newTraining,
        })
      ),
      tap({
        next: (response) => {
          this.spinner.hide();

          if (response.success) {
            this.initForm();

            this.setTrainigData(response.return);
            this.exercisesRawList = this.exercisesRawList.map((item) => {
              if (item.deletedAt) {
                return {
                  ...item,
                  deletedAt: null,
                };
              }
              return item;
            });

            this.toastr.success(
              message || "Seu treino foi atualizado com sucesso",
              "Tudo Certo!"
            );
          }
        },
      }),
      tap({
        next: (response) => {
          if (response.success) {
            const redirectFn = (action: "feedback" | "close") => {
              if (action === "close") {
                if (this.decodedData?.relation) {
                  this.router.navigate([
                    `/page/customer/${this.decodedData.relation}/2`,
                  ]);
                } else {
                  this.router.navigate(["/page"]);
                }
              } else {
                this._location.back();
              }
            };

            if (redirect && !this.feedbackResgistered.includes("last")) {
              this.openFeedbackModal((action) =>
                this.planStatus?.plano_ativo
                  ? redirectFn(action)
                  : this.openCTASubscriptionModal(() => redirectFn(action))
              );
            } else if (redirect) {
              this.planStatus?.plano_ativo
                ? redirectFn("feedback")
                : this.openCTASubscriptionModal(() => redirectFn("feedback"));
            }
          }
        },
      }),
      tap({
        next: (response) => {
          if (!response.success) {
            this.exercisesRawList = this.exercisesRawList.map((item) => {
              if (item.deletedAt) {
                return {
                  ...item,
                  deletedAt: null,
                };
              }
              return item;
            });

            this.toastr.error(response.message, "Ops :(");
          }
        },
      })
    );

    if (returnAsObservable) {
      return observable;
    } else {
      observable.subscribe();
    }
  }

  duplicateExercise(id: number, rowIndex: number, groupRowIndex?: number) {
    this.markControlsAsTouched();

    const exerciseControl = this.exercises.controls[rowIndex];
    const exerciseValue = exerciseControl.value as FormExerciseType;

    if (!exerciseControl.valid) {
      this.toastr.error("Não é possível duplicar um exercício inválido!");
      return;
    }

    if (groupRowIndex !== undefined && Number.isInteger(groupRowIndex)) {
      const targetExerciseControl = (exerciseControl.get("childs") as FormArray)
        ?.controls[groupRowIndex];
      const targetExercise = (exerciseControl.get("childs") as FormArray)
        ?.controls[groupRowIndex].value as FormExerciseType;

      if (!targetExerciseControl.valid) {
        this.toastr.error("Não é possível duplicar um exercício inválido!");
        return;
      }

      let duplicatedCount = 0;

      (exerciseControl.get("childs") as FormArray)?.controls.forEach(
        (control) => {
          const value = control.get("id")?.value as string | number | undefined;
          if (
            value?.toString().split("_")?.[0] &&
            Number.isInteger(
              value?.toString().split("_")?.[0] as unknown as number
            )
          ) {
            duplicatedCount++;
          }
        }
      );

      const groupingIdArray = (
        exerciseControl.get("id")?.value as string
      ).split("__");

      const newIdPos = groupingIdArray.findIndex(
        (groupingId) => groupingId === String(id)
      );

      groupingIdArray.splice(newIdPos + 1, 0, `${duplicatedCount}_${id}`);

      exerciseControl.get("id")?.patchValue(groupingIdArray.join("__"));

      (exerciseControl.get("childs") as FormArray)?.insert(
        groupRowIndex + 1,
        this.makeExerciseFormGroup({
          ...targetExercise,
          id: `${duplicatedCount}_${id}`,
        })
      );
    } else {
      const targetExercise = exerciseValue;

      let duplicatedCount = 0;

      this.exercises.controls.forEach((control) => {
        const value = control.get("id")?.value as string | number | undefined;
        if (
          value?.toString().split("_")?.[0] &&
          Number.isInteger(
            value?.toString().split("_")?.[0] as unknown as number
          )
        ) {
          duplicatedCount++;
        }
      });

      this.exercises.insert(
        rowIndex + 1,
        this.makeExerciseFormGroup({
          ...targetExercise,
          id: `${duplicatedCount}_${id}`,
        })
      );

      const prevRepDurMode = this.inputsModeService.getRepDurMode(id);
      const prevLoadMode = this.inputsModeService.getLoadMode(id);
      this.inputsModeService.setRepDurMode(
        `${duplicatedCount}_${id}`,
        prevRepDurMode || "rep"
      );
      this.inputsModeService.setLoadMode(
        `${duplicatedCount}_${id}`,
        prevLoadMode || "load"
      );
    }

    this.save({
      redirect: false,
      actionType: "save",
    });
  }

  onSubmit() {
    const instance = this.modalService.open(SchedulingComponent, {
      centered: true,
      windowClass: "transparent-modal",
    }).componentInstance as SchedulingComponent;

    const schedule = this.trainingRaw?.agendamento;
    const dueDate = this.trainingRaw?.vencimento;

    instance.trainingInput = {
      name: this.trainingForm?.get("name")?.value || "",
      note: this.trainingForm?.get("note")?.value || "",
      schedule: schedule
        ? moment(schedule).format("DD-MM-YYYY")
        : moment().format("DD-MM-YYYY"),
      dueDate: dueDate ? moment(dueDate).format("DD-MM-YYYY") : undefined,
    };

    instance.callback = (args) => {
      this.trainingForm?.get("name")?.patchValue(args.name);
      this.trainingForm?.get("note")?.patchValue(args.note);

      this.save({
        redirect: true,
        actionType: "save",
        propertiesToOvewrite: {
          agendamento: moment(args.schedule, "DD-MM-YYYY").toISOString(),
          vencimento: args.dueDate
            ? moment(args.dueDate, "DD-MM-YYYY").toISOString()
            : null,
        },
      });
    };
  }

  sendInitialFeedback() {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall
      .post("training/generatorFeedback", {
        feedback: {
          training: this.training?.id,
          feedback: (() => {
            switch (this.initialFeedback) {
              case "positive":
                return 3;
              case "negative":
                return 1;
              case "neutral":
                return 2;
            }
          })(),
          notes: this.initialFeedbackNotes,
          step: "first",
        },
      })
      .subscribe((response) => {
        this.spinner.hide();

        if (!response.success) {
          this.toastr.error("Erro ao enviar feedback!");
          return;
        }

        this.feedbackResgistered.push("first");

        this.toastr.success("Feedback enviado com sucesso!");
      });
  }

  makeExerciseFormGroup(exercise: FormExerciseType) {
    return new FormGroup({
      id: new FormControl(exercise.id),
      name: new FormControl(exercise.name, [Validators.required]),
      series: new FormControl(exercise.series, [
        Validators.required,
        CustomValidators.notZero,
      ]),
      repetitions: new FormControl(exercise.repetitions, [
        CustomValidators.notZero,
      ]),
      duration: new FormGroup({
        second: new FormControl(exercise.duration?.second ?? null, [
          Validators.min(0),
          Validators.max(59),
        ]),
        minute: new FormControl(exercise.duration?.minute ?? null, [
          Validators.min(0),
          Validators.max(59),
        ]),
        hour: new FormControl(exercise.duration?.hour ?? null, [
          Validators.min(0),
          Validators.max(24),
        ]),
      }),
      load: new FormControl(exercise.load, [CustomValidators.notZero]),
      speed: new FormControl(exercise.speed),
      note: new FormControl(exercise.note),
      breakTime: new FormGroup({
        second: new FormControl(exercise.breakTime?.second ?? null, [
          Validators.required,
          Validators.min(0),
          Validators.max(59),
        ]),
        minute: new FormControl(exercise.breakTime?.minute ?? null, [
          Validators.required,
          Validators.min(0),
          Validators.max(59),
        ]),
      }),
      viewType: new FormControl(exercise.viewType, [Validators.required]),
    });
  }

  makeExerciseGroupingFormGroup(grouping: FormExerciseGroupType) {
    return new FormGroup({
      id: new FormControl(grouping.id),
      name: new FormControl(grouping.name, [Validators.required]),
      childs: new FormArray([]),
    });
  }

  toggleNameFieldVisibility(action?: "blur") {
    const control = this.trainingForm?.get("name");

    if (control) {
      if (control.disabled && !action) {
        control.enable();
      } else {
        control.disable();

        this.save({
          redirect: false,
          actionType: "save",
          message: "Nome do treino atualizado com sucesso!",
        });
      }
    }
  }

  validateField(
    controlName: string,
    rowIndex?: number,
    groupRowIndex?: number
  ) {
    if (rowIndex !== undefined && groupRowIndex !== undefined) {
      const exerciseControl = (
        this.exercises.controls[rowIndex].get("childs") as FormArray
      )?.controls[groupRowIndex].get(controlName);

      return {
        valid:
          exerciseControl?.valid &&
          (exerciseControl?.touched || exerciseControl?.dirty),
        invalid:
          exerciseControl?.invalid &&
          (exerciseControl?.touched || exerciseControl?.dirty),
      };
    } else if (rowIndex !== undefined) {
      const exerciseControl =
        this.exercises.controls[rowIndex].get(controlName);

      return {
        valid:
          exerciseControl?.valid &&
          (exerciseControl?.touched || exerciseControl?.dirty),
        invalid:
          exerciseControl?.invalid &&
          (exerciseControl?.touched || exerciseControl?.dirty),
      };
    }

    const control = this.trainingForm?.controls[controlName];

    return {
      valid: control?.valid && (control?.touched || control?.dirty),
      invalid: control?.invalid && (control?.touched || control?.dirty),
    };
  }

  autoFillTimeFields(
    fieldGroup: "breakTime" | "duration",
    field: "hour" | "minute" | "second",
    index: number,
    groupRowIndex?: number
  ) {
    const fields =
      fieldGroup === "duration"
        ? ["hour", "minute", "second"]
        : ["minute", "second"];
    const control =
      groupRowIndex !== undefined && Number.isInteger(groupRowIndex)
        ? (this.exercises.controls[index].get("childs") as FormArray)?.controls[
            groupRowIndex
          ]?.get(fieldGroup)
        : this.exercises.controls[index].get(fieldGroup);

    fields.forEach((item) => {
      if (item !== field) {
        const fieldControl = control?.get(item);

        if (
          fieldControl &&
          (fieldControl.value === null ||
            fieldControl.value === undefined ||
            fieldControl.value === "")
        ) {
          fieldControl.patchValue(0);
        }
      }
    });
  }

  preventDefault(event: Event, callback?: Function) {
    event.stopPropagation();
    callback;
  }

  fetchMore(searchTerm: string) {
    const length = this.exercisesList.length;
    let more = null;

    if (searchTerm === null || searchTerm === "" || searchTerm === undefined) {
      more = this.exercisesRawList.slice(length, bufferSize + length);
    } else {
      more = this.exercisesRawList
        .filter((exer) => exer.name.includes(searchTerm))
        .slice(length, bufferSize + length);
    }

    this.exercisesLoading = true;

    setTimeout(() => {
      this.exercisesLoading = false;
      this.exercisesList = this.exercisesList.concat(more);
    }, 200);
  }

  onSearch() {
    this.input$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap((searchTerm) => {
          const values = this.exercisesRawList.filter((exer) =>
            exer.name
              .toLocaleLowerCase()
              .includes(searchTerm?.toLocaleLowerCase())
          );

          return of(values);
        })
      )
      .subscribe((exercises) => {
        this.exercisesList = exercises.slice(0, bufferSize);
      });
  }

  openFeedbackModal(callback: (action: "feedback" | "close") => void) {
    const instance = this.modalService.open(FinalFeedbackComponent, {
      centered: true,
      windowClass: "transparent-modal",
      keyboard: false,
      beforeDismiss: () => false,
    }).componentInstance as FinalFeedbackComponent;

    instance.trainingId = this.trainingRaw?.id || null;
    instance.callback = callback;
  }

  openCTASubscriptionModal(callback: () => void) {
    const instance = this.modalService.open(CtaSubscriptionComponent, {
      centered: true,
      keyboard: false,
      size: "xl",
      beforeDismiss: () => false,
    }).componentInstance as CtaSubscriptionComponent;

    instance.callback = callback;
  }

  setInitialFeedback(feedback: FeedbackType) {
    this.initialFeedback = feedback;
  }

  getMediaUrl({
    url_video,
    url_gif,
  }: {
    url_video?: string | null;
    url_gif?: string | null;
  }) {
    let urlVideo: string | null = null;
    let urlGif: string | null = null;
    let safeURL: SafeResourceUrl | null = null;

    if (url_video) {
      urlVideo = url_video;

      if (urlVideo.startsWith("https://www.youtube.com/watch?")) {
        safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          urlVideo.replace(
            "https://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          )
        );
      } else if (urlVideo.startsWith("http://www.youtube.com/watch?")) {
        safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          urlVideo.replace(
            "http://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          )
        );
      } else {
        safeURL = null;
      }
    }
    if (url_gif) {
      urlGif = url_gif;
    }

    return {
      urlVideo,
      urlGif,
      safeURL,
    };
  }

  addExerciseModal() {
    const instance = this.modalService.open(AddExerciseComponent, {
      centered: true,
    }).componentInstance as AddExerciseComponent;

    instance.exercisesRawList = this.exercisesRawList;
    instance.exerciseInput = this.selectedNewExercise;
    instance.existentExercisesIds = this.exercises.controls.map(
      (item) => item.get("id")?.value as number
    );

    instance.callback = ({ id, type }) => {
      const exercise = this.exercisesRawList.find((item) => item.id === id);

      if (exercise) {
        const formExerciseValue = this.exercises.controls[
          this.exercises.controls.length - 1
        ]?.value as FormExerciseType | undefined;

        const prevRepDurMode = formExerciseValue?.id
          ? this.inputsModeService.getRepDurMode(formExerciseValue.id)
          : undefined;

        this.inputsModeService.setRepDurMode(
          exercise.id,
          prevRepDurMode || "rep"
        );

        const prevLoadMode = formExerciseValue?.id
          ? this.inputsModeService.getLoadMode(formExerciseValue.id)
          : undefined;

        this.inputsModeService.setLoadMode(exercise.id, prevLoadMode || "load");

        this.exercises.push(
          this.makeExerciseFormGroup({
            id: exercise.id,
            name: exercise.name,
            series: formExerciseValue?.series || "4",
            repetitions: formExerciseValue?.repetitions || "10 a 12",
            breakTime: formExerciseValue?.breakTime || {
              minute: 1,
              second: 0,
            },
            duration: formExerciseValue?.duration || {
              hour: undefined,
              minute: undefined,
              second: undefined,
            },
            load: formExerciseValue?.load || "",
            speed: formExerciseValue?.speed || "medium",
            note: "",
            viewType: type,
          })
        );

        this.selectedNewExercise = null;

        this.save({
          redirect: false,
          actionType: this.trainingRaw?.isDraft ? "draft" : "save",
          message: "Exercício adicionado com sucesso!",
        });
      }
    };
  }

  replaceExerciseModal(rowIndex: number, groupRowIndex?: number) {
    const formExercise =
      groupRowIndex !== undefined && Number.isInteger(groupRowIndex)
        ? (this.exercises.controls[rowIndex].get("childs") as FormArray)
            ?.controls[groupRowIndex]
        : this.exercises.controls[rowIndex];
    const formExerciseValue = formExercise.value as FormExerciseType;

    const categoryExercise = this.exercisesRawList.find(
      (item) => item.name === formExercise.value.name
    );

    const instance = this.modalService.open(ReplaceExerciseComponent, {
      centered: true,
    }).componentInstance as ReplaceExerciseComponent;

    instance.exercisesRawList = this.exercisesRawList;
    instance.exerciseInput = {
      id: categoryExercise?.id || 0,
      name: categoryExercise?.name || "",
      type: formExerciseValue.viewType || null,
    };
    instance.existentExercisesNames = this.exercises.controls.map(
      (item) => item.get("name")?.value as string
    );

    instance.callback = ({ name, type }) => {
      if (name) {
        formExercise.patchValue({
          id: formExerciseValue.id,
          name: name,
          series: formExerciseValue?.series || "",
          repetitions: formExerciseValue?.repetitions || "",
          breakTime: formExerciseValue?.breakTime || {
            minute: 1,
            second: 0,
          },
          duration: formExerciseValue?.duration || {
            hour: 0,
            minute: 0,
            second: 0,
          },
          load: formExerciseValue?.load || "",
          speed: formExerciseValue?.speed || "medium",
          note: formExerciseValue?.note || "",
          viewType: type,
        });

        this.save({
          redirect: false,
          actionType: this.trainingRaw?.isDraft ? "draft" : "save",
          message: "Exercício substituído com sucesso!",
        });
      }
    };
  }

  viewExerciseMediaModal(rowIndex: number, groupRowIndex?: number) {
    const formExercise =
      groupRowIndex !== undefined && Number.isInteger(groupRowIndex)
        ? (this.exercises.controls[rowIndex].get("childs") as FormArray)
            ?.controls[groupRowIndex]
        : this.exercises.controls[rowIndex];
    const formExerciseValue = formExercise.value as FormExerciseType;

    const categoryExercise = this.exercisesRawList.find(
      (item) => item.name === formExerciseValue.name
    );

    const instance = this.modalService.open(ViewExerciseComponent, {
      centered: true,
    }).componentInstance as ViewExerciseComponent;

    const { urlVideo, urlGif, safeURL } = this.getMediaUrl({
      url_video: categoryExercise?.url_video,
      url_gif: categoryExercise?.url_gif,
    });

    instance.exercisePreviewData = {
      name: formExerciseValue.name,
      inititalType: formExerciseValue.viewType || null,
      urlVideo,
      urlGif,
      safeURL,
    };
    instance.callback = (type) => {
      formExercise.get("viewType")?.patchValue(type);

      this.save({
        redirect: false,
        actionType: this.trainingRaw?.isDraft ? "draft" : "save",
        message: "Tipo de visualização do exercício alterado com sucesso!",
      });
    };
  }

  removeExerciseModal(id: number | string) {
    const instance = this.modalService.open(WarningModalComponent, {
      centered: true,
    }).componentInstance as WarningModalComponent;

    instance.callback = () => {
      if (typeof id === "string") {
        const ids = id.split("__");

        if (ids.length > 0) {
          ids.forEach((id) => {
            this.removeExercise(+id);
          });
        }
      } else if (typeof id === "number") {
        this.removeExercise(id);
      }

      this.save({
        redirect: false,
        actionType: "save",
        message: "Exercício removido com sucesso!",
      });
    };
  }

  removeExercise(id: number | string) {
    const trainingExerciseIndex = this.trainingRaw?.TrainingExercises.findIndex(
      (item) => item?.id === id
    );

    const categoryExerciseIndex = this.exercisesRawList.findIndex(
      (item) => item?.id === id
    );

    if (
      trainingExerciseIndex !== undefined &&
      trainingExerciseIndex !== -1 &&
      this.trainingRaw?.TrainingExercises
    ) {
      this.trainingRaw.TrainingExercises[trainingExerciseIndex]["deletedAt"] =
        true;
    } else if (categoryExerciseIndex !== -1 && this.exercisesRawList) {
      this.exercisesRawList[categoryExerciseIndex]["deletedAt"] = true;
    }

    this.checkboxSelectionService.removeRowSelection(id);
  }

  removeSelectedExercisesModal() {
    const instance = this.modalService.open(WarningModalComponent, {
      centered: true,
    }).componentInstance as WarningModalComponent;

    instance.title = "Remover exercícios selecionados";

    instance.callback = () => {
      this.checkboxSelectionService.getSelectedRows().forEach((id) => {
        this.removeExercise(id);
      });
      this.save({
        redirect: false,
        actionType: "save",
        message: "Exercícios removidos com sucesso!",
      });
    };
  }

  shouldRemoveBorder(rowIndex: number) {
    return (
      !this.exercises.controls[rowIndex]?.get("childs") &&
      !!this.exercises.controls[rowIndex + 1]?.get("childs")
    );
  }

  groupExercises() {
    if (this.checkboxSelectionService.getSelectedRows().length < 2) {
      return;
    }

    const selectedIds = this.checkboxSelectionService.getSelectedRows();

    const selectedIndex = selectedIds.map((id) =>
      this.exercises.controls.findIndex(
        (control) => control.get("id")?.value === id
      )
    );
    const smallerIndex = Math.min(...selectedIndex);

    const exercisesToBeGrouped: (TrainingExerciseType | undefined)[] =
      selectedIds
        .join("__")
        .split("__")
        .filter((item, index, arr) => arr.indexOf(item) === index)
        .map((id) =>
          this.trainingRaw?.TrainingExercises.find((item) => item.id === +id)
        );

    const controls = [...this.exercises.controls];
    this.exercises.clear();

    const groupingCount = controls.filter((control) => {
      const value = String(control.get("id")?.value);
      return (
        value?.includes("__") &&
        value?.split("__")?.length > 1 &&
        Number.isInteger(+value?.split("__")?.[0])
      );
    }).length;

    controls.splice(
      smallerIndex,
      0,
      this.makeExerciseGroupingFormGroup({
        id: `${selectedIds.join("__")}`,
        name: `Agrupamento ${groupingCount + 1}`,
        childs: [],
      })
    );

    controls.forEach((control) => {
      if (!selectedIds.includes(control.value?.id)) {
        this.exercises.push(control);
      }
    });

    const childsControl = this.exercises.controls[smallerIndex].get(
      "childs"
    ) as FormArray;

    exercisesToBeGrouped.forEach((exer) => {
      if (exer) {
        this.fulfillExerciseControls(
          exer,
          (exer) => {
            childsControl?.push(this.makeExerciseFormGroup(exer));
          },
          (controlName) =>
            childsControl?.controls[exercisesToBeGrouped.indexOf(exer)]?.get(
              controlName
            )
        );
      }
    });

    this.checkboxSelectionService.reset();

    this.save({
      redirect: false,
      actionType: this.trainingRaw?.isDraft ? "draft" : "save",
      message: "Exercícios agrupados com sucesso!",
    });
  }

  groupExercisesModal() {
    if (this.checkboxSelectionService.getSelectedRows().length < 2) {
      this.toastr.error("Selecione pelo menos dois exercícios para agrupar!");
      return;
    }

    const instance = this.modalService.open(WarningModalComponent, {
      centered: true,
    }).componentInstance as WarningModalComponent;
    instance.title = "Agrupar exercícios selecionados";
    instance.subtitle = "Por enquanto esta ação não poderá ser desfeita!";
    instance.buttonLabel = "Agrupar";
    instance.callback = () => {
      this.groupExercises();
    };
  }

  onDrop(
    event: CdkDragDrop<
      {
        [key: string]: AbstractControl;
      }[]
    >
  ) {
    const controls = [...this.exercises.controls];

    moveItemInArray(controls, event.previousIndex, event.currentIndex);

    this.exercises.clear();

    controls.forEach((control) => {
      this.exercises.push(control);
    });

    this.save({
      redirect: false,
      actionType: this.trainingRaw?.isDraft ? "draft" : "save",
      message: "Exercícios reordenados com sucesso!",
    });
  }

  onDropGroup(
    event: CdkDragDrop<
      {
        [key: string]: AbstractControl;
      }[]
    >,
    rowIndex: number
  ) {
    const controls = [
      ...(this.exercises.controls[rowIndex]?.get("childs") as FormArray)
        ?.controls,
    ];

    moveItemInArray(controls, event.previousIndex, event.currentIndex);

    const childs = this.exercises.controls[rowIndex].get("childs") as FormArray;

    childs.clear();

    controls.forEach((control) => {
      childs.push(control);
    });

    this.save({
      redirect: false,
      actionType: this.trainingRaw?.isDraft ? "draft" : "save",
      message: "Exercícios do agrupamento reordenados com sucesso!",
    });
  }

  // inputs mode services methods
  setRepDurModeControl(id: number, rowIndex: number, groupRowIndex?: number) {
    this.inputsModeService.toggleRepDurMode(id);

    const repDurMode = groupRowIndex
      ? (
          this.exercises.controls[rowIndex].get("childs") as FormArray
        )?.controls[groupRowIndex].get("repetitions")
      : this.exercises.controls[rowIndex]?.get("repetitions");
    const durControl = groupRowIndex
      ? (
          this.exercises.controls[rowIndex].get("childs") as FormArray
        )?.controls[groupRowIndex].get("duration")
      : this.exercises.controls[rowIndex]?.get("duration");

    if (repDurMode && durControl) {
      this.inputsModeService.setRepDurModeControl(id, repDurMode, durControl);
    }
  }

  toggleLoadMode(id: number) {
    this.inputsModeService.toggleLoadMode(id);
  }

  getRepDurMode(id: number) {
    return this.inputsModeService.getRepDurMode(id);
  }

  getLoadMode(id: number) {
    return this.inputsModeService.getLoadMode(id);
  }

  // checkbox selection services methods
  toggleRowSelection(id: number | string) {
    this.checkboxSelectionService.toggleRowSelection(id);
  }

  isRowSelected(id: number | string) {
    return this.checkboxSelectionService.isRowSelected(id);
  }

  getSelectedRows() {
    return this.checkboxSelectionService.getSelectedRows();
  }

  toggleAllRows(event: Event) {
    const exercises: FormExerciseUnifiedType[] = this.exercises.value.filter(
      (item: FormExerciseUnifiedType) => typeof item.id === "number"
    );
    this.checkboxSelectionService.toggleAllRows(event, exercises);
  }

  isAllRowsSelected() {
    return this.checkboxSelectionService.isAllRowsSelected(
      this.exercises.value
    );
  }

  isSelectDisabled(id: number | string, isChild?: boolean) {
    if (this.getSelectedRows().length === 0) return false;

    if (isChild) {
      let disabled = false;
      let childParent: string | number | null = null;
      let onlyChildsSelected = true;

      this.exercises.controls.forEach((control) => {
        if (!disabled) {
          disabled = this.isRowSelected(control.get("id")?.value as number);
        }

        if (control.get("childs")?.value.length === 0) {
          control.get("childs")?.value.forEach((child: FormExerciseType) => {
            if (child.id === id) {
              childParent = control.get("id")?.value;
            }
          });
        }
      });

      this.exercises.controls.forEach((control) => {
        if (control.get("childs")?.value.length === 0) {
          control.get("childs")?.value.forEach((child: FormExerciseType) => {
            if (
              childParent &&
              childParent !== control.get("id")?.value &&
              this.isRowSelected(child.id)
            ) {
              onlyChildsSelected = false;
            }
          });
        }
      });

      return disabled || !onlyChildsSelected;
    } else {
      let childSelected = false;

      this.exercises.controls.forEach((control) => {
        if (control.get("childs")?.value.length === 0) {
          control.get("childs")?.value.forEach((child: FormExerciseType) => {
            if (this.isRowSelected(child.id)) {
              childSelected = true;
            }
          });
        }
      });

      return childSelected;
    }
  }

  // row expansion services methods
  toggleRowExpansion(id: number | string) {
    this.tableExpansionService.toggleRowExpansion(id);
  }

  expandAndFocus(id: number, elementId: string) {
    this.tableExpansionService.expandAndFocus(id, elementId);
  }

  isRowExpanded(id: number | string) {
    return this.tableExpansionService.isRowExpanded(id);
  }
}
