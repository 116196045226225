<p class="paragrafo">Cartões</p>
<div class="cards">
  <div *ngFor="let method of cartoes" class="card">
    <div class="card-details">
      <div class="row">
        <i [class]="getCardIcon(method.brand)"></i>
        <span
          *ngIf="method.id === listaCartoes.default_payment_method_id"
          class="tag-principal"
          >Principal</span
        >
      </div>
      <span class="card-number">{{ method.display_number }}</span>
    </div>
    <div class="card-actions">
      <button
        *ngIf="method.id !== listaCartoes.default_payment_method_id"
        class="primary-btn btn-sm"
        (click)="markAsPrimary(method.id)"
      >
        <i class="fa fa-star"></i> Marcar como Principal
      </button>
      <button
        *ngIf="method.id === listaCartoes.default_payment_method_id"
        class="remove-primary-btn btn-sm-outline"
        (click)="removePrimary(method.id)"
      >
        <i class="fa fa-star"></i> Remover de Principal
      </button>
      <button class="delete-btn mb-2" (click)="deleteCard(method)">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
