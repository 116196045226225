import * as CryptoJS from "crypto-js";
import { Observable } from "rxjs";
import Swal from "sweetalert2";

interface UploadOptions {
  accept: string;
  ariaLabel: string;
}

export async function uploadImage(
  options: UploadOptions
): Promise<string | undefined> {
  const { accept, ariaLabel } = options;

  const { value: fileInput } = await Swal.fire({
    title: "Novo Banner",
    input: "file",
    text: "É obrigatório utilizar a proporção de 414px x 150px",
    showCancelButton: true,
    cancelButtonText: "Cancelar",
    confirmButtonText: "Salvar",
    inputAttributes: {
      accept,
      "aria-label": ariaLabel,
    },
    inputValidator: async (value: string | File) => {
      if (!value) {
        return "Você precisa escolher um banner!";
      }

      if (!(value instanceof File)) {
        return "Você precisa escolher um arquivo válido!";
      }

      const file = value as File;

      if (file.size > 1000000) {
        return "São permitidos banners de até 1MB!";
      }

      return null;
    },
  });

  if (!fileInput) {
    return;
  }

  const file = fileInput as File;
  const base64 = await fileToBase64(file);
  const sha1 = calculateSHA1(base64 as unknown as ArrayBuffer);

  const dataUrl = await getUploadUrl();
  if (
    !dataUrl ||
    !dataUrl.return ||
    !dataUrl.return.authorizationToken ||
    !dataUrl.return.uploadUrl ||
    !dataUrl.return.uniqid
  ) {
    // await hideSpinner();
    return; // Trate como necessário se não houver URL de upload
  }

  const fileName = generateFileName(file, dataUrl);
  const uploadResult = await performUpload(file, dataUrl, fileName, sha1);

  // await hideSpinner();

  if (uploadResult) {
    return uploadResult; // Retorne a URL do banner após o upload
  }

  return;
}

export function fileToBase64(file: File): Observable<string> {
  return new Observable((observer) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      observer.next(base64String);
      observer.complete();
    };
    reader.onerror = observer.error;
    reader.readAsDataURL(file);
  });
}

export function fileToArrayBuffer(file: File): Observable<ArrayBuffer> {
  return new Observable((observer) => {
    const reader = new FileReader();
    reader.onload = () => {
      const buffer = reader.result as ArrayBuffer;
      observer.next(buffer);
      observer.complete();
    };
    reader.onerror = observer.error;
    reader.readAsArrayBuffer(file);
  });
}

export function calculateSHA1(file: ArrayBuffer): string {
  const wordArray = CryptoJS.lib.WordArray.create(file);
  const sha1Hash = CryptoJS.SHA1(wordArray);
  return sha1Hash.toString();
}

async function getUploadUrl(): Promise<any> {
  // Lógica para obter a URL de upload, por exemplo, usando uma chamada HTTP
  // Substitua isso com sua lógica real de obtenção de URL de upload
  return {
    return: {
      authorizationToken: "token",
      uploadUrl: "https://example.com/upload",
      uniqid: "unique_id",
    },
  };
}

async function performUpload(
  file: File,
  dataUrl: any,
  fileName: string,
  sha1: string
): Promise<string | undefined> {
  const options = {
    headers: {
      Authorization: dataUrl.return.authorizationToken,
      "Content-Type": file.type,
      "X-Bz-File-Name": fileName,
      "X-Bz-Content-Sha1": sha1,
    },
  };

  try {
    const response = await fetch(dataUrl.return.uploadUrl, {
      method: "POST",
      headers: options.headers,
      body: file,
    });

    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse.fileName; // Retornar o nome do arquivo ou URL completa, dependendo da resposta da API
    } else {
      throw new Error("Erro durante o upload");
    }
  } catch (error) {
    console.error("Erro durante o upload:", error);
    return;
  }
}

function generateFileName(file: File, dataUrl: any): string {
  // Lógica para gerar o nome do arquivo conforme necessário
  // Aqui, estamos usando um exemplo simples
  return `${dataUrl.return.uniqid}.${file.name.split("?")[0].split(".").pop()}`;
}
