import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private userSubject = new BehaviorSubject<any>(null);
  private subscriptionsSubject = new BehaviorSubject<any[]>([]);

  setUser(user: any): void {
    this.userSubject.next(user);
  }

  getUser() {
    return this.userSubject.asObservable();
  }

  setSubscriptions(subscriptions: any[]): void {
    this.subscriptionsSubject.next(subscriptions);
  }

  getSubscriptions() {
    return this.subscriptionsSubject.asObservable();
  }

  clearUserCache() {
    this.userSubject.next(null);
    this.subscriptionsSubject.next([]);
  }
}
