<nav
  class="navbar navbar-expand-lg navbar-light header-navbar {{
    transparentBGClass
  }}"
  [ngClass]="{
    'navbar-brand-center': menuPosition === 'Top',
    'navbar-static':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Static',
    'navbar-sticky':
      menuPosition === 'Side' && config?.layout.navbar.type === 'Fixed',
    'fixed-top': isSmallScreen && menuPosition === 'Top'
  }"
>
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div
        class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
        data-toggle="collapse"
        (click)="toggleSidebar()"
      >
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <!--<li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
            (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>-->
        <!--        <li class="nav-item nav-search">
          <a class="nav-link nav-link-search" id="navbar-search" href="javascript:" (click)="toggleSearchOpenClass(true)"><i
              class="ft-search font-medium-3"></i></a>
          <div class="search-input {{searchOpenClass}}">
            <div class="search-input-icon">
              <i class="ft-search font-medium-3"></i>
            </div>
            <input [formControl]="control" #search class="input" type="text" placeholder="Pesquisar..." tabindex="0"
              autofocus data-search="template-search" [appAutocomplete]="autocomplete" (keyup.enter)="onEnter()" (keydown.esc)="onEscEvent()" (keyup)="onSearchKey($event)" />
            <div class="search-input-close" (click)="toggleSearchOpenClass(false)">
              <i class="ft-x font-medium-3"></i>
            </div>

            <app-autocomplete #autocomplete="appAutocomplete">
              <ng-template appAutocompleteContent >
                <ng-container *ngIf="(listItems | filter: control.value) as result">
                  <app-option #searchResults *ngFor="let option of result" [url]="option.url" [value]="option.name" (click)="redirectTo(option.url);" (mouseenter)="removeActiveClass()"  >
                    <div class="d-flex align-items-center justify-content-between w-100">
                      <div class="d-flex align-items-center justify-content-start">
                        <i class="{{option.icon}} mr-2"></i>
                        <span>{{ option.name }}</span>
                      </div>
                    </div>
                  </app-option>
                  <app-option class="no-result" *ngIf="!result.length">Nenhum Resultado :(</app-option>
                </ng-container>
              </ng-template>
            </app-autocomplete>
          </div>
        </li>-->
      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo">
                <a
                  class="logo-text"
                  [routerLink]="['/']"
                  *ngIf="config?.layout.menuPosition === 'Top'"
                >
                  <div class="logo-img">
                    <img class="logo-img" alt="Apex logo" [src]="logoUrl" />
                  </div>
                  <span class="text">APEX</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <!--<li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown >
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img class="langimg selected-flag"
                [src]="selectedLanguageFlag" alt="flag" /><span
                class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')"><img
                  class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" /><span
                  class="font-small-3">English</span></a><a class="dropdown-item" href="javascript:;" data-language="es"
                (click)="ChangeLanguage('es')"><img class="langimg mr-2" src="./assets/img/flags/es.png"
                  alt="flag" /><span class="font-small-3">Spanish</span></a><a class="dropdown-item" href="javascript:;"
                data-language="pt" (click)="ChangeLanguage('pt')"><img class="langimg mr-2"
                  src="./assets/img/flags/pt.png" alt="flag" /><span class="font-small-3">Portuguese</span></a>
              <a class="dropdown-item" href="javascript:;" data-language="de" (click)="ChangeLanguage('de')"><img
                  class="langimg mr-2" src="./assets/img/flags/de.png" alt="flag" /><span
                  class="font-small-3">German</span></a>
            </div>
          </li>-->
          <!-- <li *ngIf="isAssinante" class="dropdown nav-item" display="static" placement="bottom" (click)="goTelegram()" ngbTooltip="Está com alguma dúvida ou dificuldade? Clique aqui e fale com a gente no nosso grupo exclusivo do telegram">
            <a  class="nav-link p-0 mt-2 mr-2"id="telegram" href="javascript:;"
            >
              <i class="fa fa-telegram font-medium-3 text-info"></i>
            </a>

          </li> -->
          <li
            class="dropdown nav-item"
            placement="bottom-left"
            display="static"
            (click)="viewStoreLink(contentGoogle)"
            *ngIf="user?.type != '2'"
          >
            <a class="nav-link p-0 mt-2 mr-2" id="android" href="javascript:;">
              <i class="fa fa-android font-medium-3"></i>
            </a>
          </li>
          <li
            class="dropdown nav-item"
            placement="bottom-left"
            display="static"
            (click)="viewStoreLink(contentApple)"
            *ngIf="user?.type != '2'"
          >
            <a class="nav-link p-0 mt-2 mr-2" id="apple" href="javascript:;">
              <i class="fa fa-apple font-medium-3"></i>
            </a>
          </li>

          <li
            class="dropdown nav-item"
            placement="bottom-left"
            display="static"
            ngbDropdown
            (openChange)="clickOnNotifications($event)"
            *ngIf="user?.type != '2'"
          >
            <a
              class="nav-link dropdown-toggle dropdown-notification p-0 mt-2"
              style="margin-top: 0.75rem !important"
              [ngClass]="countNotifications == 0 ? 'mr-2' : ''"
              id="drp-notification"
              href="javascript:;"
              data-toggle="dropdown"
              ngbDropdownToggle
              ><i class="ft-bell font-medium-3"></i>
              <span class="notification badge badge-pill badge-danger">{{
                countNotifications > 0 ? countNotifications : null
              }}</span>
            </a>
            <ul
              ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden"
            >
              <li class="dropdown-menu-header">
                <div
                  class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary"
                >
                  <div class="d-flex">
                    <i
                      class="ft-bell font-medium-3 d-flex align-items-center mr-2"
                    ></i
                    ><span class="noti-title"
                      >Olá Personal! Confira suas notificações!</span
                    >
                    <a (click)="openModalNormal(configNotification)"
                      ><i
                        class="ft-settings font-medium-3 d-flex align-items-center ml-2"
                      ></i
                    ></a>
                  </div>
                </div>
              </li>
              <li
                class="scrollable-container"
                infinite-scroll
                #perfectScroll
                [infiniteScrollDistance]="scrollDistance"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onScrollDown($event)"
                (scrolledUp)="onUp($event)"
                [scrollWindow]="false"
                [perfectScrollbar]="configS"
              >
                <a
                  *ngFor="let notification of notifications"
                  class="d-flex justify-content-between"
                  [href]="getNotificationLink(notification)"
                  [ngStyle]="{
                    'pointer-events':
                      notification.sellerCustomer == null ? 'none' : 'default'
                  }"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-left">
                      <div class="mr-3">
                        <img
                          class="avatar"
                          [src]="
                            notification.FromUser &&
                            notification.FromUser.photo_url
                              ? notification.FromUser.photo_url
                              : 'assets/img/portrait/small/no-avatar.svg'
                          "
                          alt="avatar"
                          height="45"
                          width="45"
                        />
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="m-0" *ngIf="notification.FromUser">
                        <span>{{ notification.FromUser.name }}</span
                        ><small
                          class="grey lighten-1 font-italic float-right"
                          >{{ formatDate(notification.createdAt) }}</small
                        >
                      </h6>
                      <small class="noti-text">{{
                        notification.message
                      }}</small>
                    </div>
                  </div>
                </a>
                <a
                  *ngIf="notifications.length == 0"
                  class="d-flex justify-content-between"
                  href="javascript:void(0)"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-body text-center">
                      <small class="noti-text"
                        >Você ainda não tem notificações.</small
                      >
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>

          <!--Novidades-->
          <li
            class="dropdown nav-item"
            placement="bottom-left"
            display="static"
            ngbDropdown
            (openChange)="clickOnAtualizacoes($event)"
            *ngIf="user?.type != '2'"
          >
            <a
              class="nav-link dropdown-toggle dropdown-notification p-0 mt-2"
              style="margin-top: 0.75rem !important"
              [ngClass]="countNotifications == 0 ? 'mr-2' : ''"
              id="drp-notification"
              href="javascript:;"
              data-toggle="dropdown"
              ngbDropdownToggle
              ><i class="ft-star font-medium-3 color-primary"></i>
              <span class="notification badge badge-pill badge-danger">{{
                countAtualizacoes > 0 ? countAtualizacoes : null
              }}</span>
            </a>
            <ul
              ngbDropdownMenu
              class="notification-dropdown dropdown-menu dropdown-menu-media dropdown-menu-right m-0 overflow-hidden"
            >
              <li class="dropdown-menu-header">
                <div
                  class="dropdown-header d-flex justify-content-between m-0 px-3 py-2 white bg-primary"
                >
                  <div class="d-flex">
                    <i
                      class="ft-star font-medium-3 d-flex align-items-center mr-2"
                    ></i
                    ><span class="noti-title"
                      >Fique por dentro das novidades!</span
                    >
                  </div>
                </div>
              </li>
              <li
                class="scrollable-container"
                infinite-scroll
                #perfectScroll
                [infiniteScrollDistance]="scrollDistance"
                [infiniteScrollUpDistance]="scrollUpDistance"
                [infiniteScrollThrottle]="throttle"
                (scrolled)="onScrollDown($event)"
                (scrolledUp)="onUp($event)"
                [scrollWindow]="false"
                [perfectScrollbar]="configS"
              >
                <a
                  *ngFor="let atualizacao of atualizacoes"
                  class="d-flex justify-content-between"
                  href="javascript:void(0)"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-body">
                      <h6 class="m-0" *ngIf="atualizacao.title">
                        <span>{{ atualizacao.title }}</span
                        ><small
                          class="grey lighten-1 font-italic float-right"
                          >{{ formatDate(atualizacao.createdAt) }}</small
                        >
                      </h6>
                      <small class="noti-text">{{
                        atualizacao.description
                      }}</small>
                    </div>
                  </div>
                </a>
                <a
                  *ngIf="atualizacoes.length == 0"
                  class="d-flex justify-content-between"
                  href="javascript:void(0)"
                >
                  <div class="media d-flex align-items-center">
                    <div class="media-body text-center">
                      <small class="noti-text"
                        >Você ainda não tem notificações.</small
                      >
                    </div>
                  </div>
                </a>
              </li>
            </ul>
          </li>
          <li
            class="dropdown nav-item mr-1"
            placement="bottom-left"
            display="static"
            ngbDropdown
          >
            <a
              class="nav-link dropdown-toggle user-dropdown d-flex"
              id="dropdownBasic2"
              href="javascript:;"
              data-toggle="dropdown"
              ngbDropdownToggle
              [ngClass]="
                user?.type != '2' ? 'align-items-end' : 'align-items-start'
              "
            >
              <div style="margin-top: 2px" class="user d-md-flex d-none mr-2">
                <span class="text-right">
                  {{ user?.name || "" }}
                </span>
                <span
                  class="text-right text-muted font-small-3"
                  *ngIf="user?.type != '2'"
                >
                  Personal Trainer
                </span>
              </div>
              <img
                class="avatar"
                [src]="
                  user?.photo_url
                    ? user.photo_url
                    : 'assets/img/portrait/small/no-avatar.svg'
                "
                alt="avatar"
                height="35"
                width="35"
              />
            </a>
            <div
              class="dropdown-menu text-left dropdown-menu-right m-0 pb-0"
              aria-labelledby="dropdownBasic2"
              ngbDropdownMenu
            >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>Sair</span>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<ng-template #contentApple>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="bbbb"
                      >Seu aluno tem iPhone? Envie para ele o seu link exclusivo
                      do nosso aplicativo na App Store!</label
                    >
                    <div class="form-row justify-content-center mb-2 mt-2">
                      <div class="col text-center">
                        <button
                          type="button"
                          class="btn btn-lg btn-warning mb-md-0"
                          (click)="gerarLinkConvite()"
                          placement="bottom"
                          ngbTooltip="Você pode gerar um link ilimitado onde o usuário vai poder baixar o aplicativo e virar seu aluno automaticamente. Este link não tem expiração."
                        >
                          Gerar Link
                        </button>
                      </div>
                    </div>
                    <div
                      *ngIf="linkConviteGerado"
                      class="form-row justify-content-center mb-2 mt-2"
                    >
                      <div class="col text-center">
                        <input
                          type="text"
                          id="aaaa"
                          class="form-control text-center"
                          disabled="true"
                          [value]="linkConviteGerado"
                          name="aaaa"
                          required
                          aria-describedby="basic-addon1"
                        />
                        <small class="form-text text-muted warning"
                          >Link copiado! Agora é só enviar aos seus alunos!
                          <b>Acesso ao link somente pelo celular.</b></small
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #contentGoogle>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div class="controls">
                    <label for="aaaa"
                      >Seu aluno usa Android? Envie para ele o seu link
                      exclusivo do nosso aplicativo na Google Play!</label
                    >
                    <div class="form-row justify-content-center mb-2 mt-2">
                      <div class="col text-center">
                        <button
                          type="button"
                          class="btn btn-lg btn-warning mb-md-0"
                          (click)="gerarLinkConvite()"
                          placement="bottom"
                          ngbTooltip="Você pode gerar um link ilimitado onde o usuário vai poder baixar o aplicativo e virar seu aluno automaticamente. Este link não tem expiração."
                        >
                          Gerar Link
                        </button>
                      </div>
                    </div>
                    <div
                      *ngIf="linkConviteGerado"
                      class="form-row justify-content-center mb-2 mt-2"
                    >
                      <div class="col text-center">
                        <input
                          type="text"
                          id="aaaa"
                          class="form-control text-center"
                          disabled="true"
                          [value]="linkConviteGerado"
                          name="aaaa"
                          required
                          aria-describedby="basic-addon1"
                        />
                        <small class="form-text text-muted warning"
                          >Link copiado! Agora é só enviar aos seus alunos!
                          <b>Acesso ao link somente pelo celular.</b></small
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #configNotification let-modal>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-content">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <p>Configuração de Feedback de Carga</p>
                <form #exForm="ngForm" class="editForm" novalidate>
                  <div class="form-row mt-4">
                    <div class="form-group mb-2">
                      <label for="notificationAAA"
                        >Notificação de Feedback de Carga:
                      </label>
                      <ui-switch
                        checked
                        class="switchery"
                        placement="top"
                        ngbTooltip="Ative ou desative as notificações de feedback de carga."
                        (click)="updateNotificationPreferences(modal)"
                        name="feedbackNotification"
                        [(ngModel)]="feedbackNotification"
                        ngDefaultControl
                      ></ui-switch>
                    </div>
                  </div>
                </form>
                <!-- <div class="form-group custom-control-inline switchery-warning mt-1">
                    <ui-switch checked class="switchery" placement="top"
                      ngbTooltip="Ative/Inative a relação com seu aluno."
                      [(ngModel)]="user.loadFeedbackNotification"></ui-switch>
                  </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
