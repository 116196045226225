import Api from "../../helpers/api";
import App from "../../helpers/app";
import { Component, OnInit, Input } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoiceService } from "app/shared/services/invoiceService";
declare var Iugu: any;
@Component({
  selector: "app-cred-card-payment-customer",
  templateUrl: "./cred-card-payment-customer.component.html",
  styleUrls: ["./cred-card-payment-customer.component.scss"],
})
export class CredCardPaymentCustomerComponent implements OnInit {
  @Input() invoice: any;
  
  apiCall = this.api.new().silent();
  novoCartao = {
    number: null,
    cvv: null,
    date: null,
    name: null,
  };
  isCheckboxDisabled: boolean = false;
  cupomDigitado;
  eventID = null;
  promo1 = false;
  promo2 = false;
  promo3 = false;
  promo4 = false;
  newUser = false;
  utmSource = null;
  utmMedium = null;
  planoParam = null;
  utmContent = null;
  erroCartao = null;
  hidecupom = false;
  parcelado = false;
  promoParc1 = false;
  promoParc2 = false;
  promoParc3 = false;
  promoParc4 = false;
  promoParc5 = false;
  promoParc6 = false;
  activePromo = null;
  utmCampaing = null;
  promoParc10 = false;
  isPromoParc = false;
  acceptTerms = false;
  cartaoCadastrado = null;
  venceuDiasGratuitos = false;

  constructor(
    private app: App,
    private api: Api,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private invoiceService: InvoiceService // Injetando o serviço
  ) {  

    const invoiceFromService = this.invoiceService.getInvoice();
    if (invoiceFromService && invoiceFromService.accountId) {
      Iugu.setAccountID(invoiceFromService.accountId);
      console.log("Iugu Account ID set to:", invoiceFromService.accountId);
    } else {
      console.warn("Invoice não está definido no serviço.");
    }
    this.route.queryParams.subscribe((params) => {    
     
    });
  }

  ngOnInit(): void {

    const self = this

    console.log(self.invoice)

        //Iugu.setAccountID("B104DCBA3E4E4207B1B17CA79D4290B7");


  /*  if (self.invoice && self.invoice.accountId) {
      Iugu.setAccountID(self.invoice.accountId);
    }*/

  }
  
  onCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.isCheckboxDisabled = checkbox.checked;
  }
  getCreditCard() {
    if (
      this.novoCartao.number &&
      Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)
    ) {
      return Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number);
    } else {
      return "";
    }
  }

  async cadastrarCartaoEAssinar() {
    const self = this;
    self.erroCartao = "";
    let name = "";
    let cvv = "";
    let date = "";
    let number = "";

    console.log("chamu aqui");

    if (self.novoCartao.cvv) {
      cvv = self.novoCartao.cvv.replace(/\s/g, "");
    }
    name = self.novoCartao.name;

    if (self.novoCartao.number) {
      number = self.novoCartao.number.replace(/\s/g, "");
    }
    if (self.novoCartao.date) {
      date = self.novoCartao.date.replace(/\s/g, "");
    }

    if (!Iugu.utils.validateCreditCardNumber(number)) {
      console.log("Número de cartão inválido");
      self.erroCartao = "Número de cartão inválido";
      return;
    }
    if (!Iugu.utils.validateExpirationString(date)) {
      console.log("Data de validade inválida");
      self.erroCartao = "Data de validade inválida";
      return;
    }
    if (
      !Iugu.utils.getBrandByCreditCardNumber(number) ||
      !Iugu.utils.validateCVV(
        cvv,
        Iugu.utils.getBrandByCreditCardNumber(number)
      )
    ) {
      console.log("Data de validade inválida");
      self.erroCartao = "Data de validade inválida";
      return;
    }

    let cc = Iugu.CreditCard(
      number,
      date.substring(0, 2),
      date.substring(3, 7),
      name.split(" ")[0],
      name.substring(name.indexOf(" ") + 1),
      cvv
    );
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    Iugu.createPaymentToken(cc, async function (response) {
      if (response.errors) {
        await self.spinner.hide();
        self.erroCartao = "Erro ao validar seu cartão.";
        return;
      } else {
        self.cartaoCadastrado = response.id;
        console.log("Cartão Cadastrado:", self.cartaoCadastrado);
        await self.spinner.hide();
        await self.assinar();
      }
    });
  }

  async assinar() {
    const self = this;
    const body = {
      invoiceId: self.invoice.id,
      token: self.cartaoCadastrado,
      setDefault: self.isCheckboxDisabled,
    };
    console.log("invoice", body);
    self.apiCall.post("finance/invoice/marketplace/direct-charge", body).subscribe(
      (response) => {
        if (response.code == 200) {
          this.app.alert("Deu tudo certo", "");
        } else if (response.code == 400) {
          this.app.alert(response.message, "");
        }
        console.error("realizar cobrança direta:", response);
      },
      (error) => {
        console.error("Erro ao realizar cobrança direta:", error);
      }
    );
  }
}
