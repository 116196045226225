<div id="chart">
  <apx-chart
    [series]="chartOptions.series"
    [chart]="chartOptions.chart"
    [xaxis]="chartOptions.xaxis"
    [plotOptions]="chartOptions.plotOptions"
    [title]="chartOptions.title"
    [colors]="chartOptions.colors"
  ></apx-chart>
</div>
