<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding-bottom: 1.5rem">
        Banco de Exercícios
      </h2>
    </div>
    <div class="d-flex justify-content-between">
      <div class="col-sm-12">
        <button
          class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton"
          (click)="OpenModalXl(contentTutoriais)"
        >
          <i class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
        </button>
      </div>
    </div>
    <div class="col-sm-12 mt-2 mb-4">
      <ngb-alert
        style="cursor: pointer"
        type="warning"
        class="text-center"
        (click)="abrirFormularioSugestao()"
        [dismissible]="false"
      >
        <h5 class="text-center text-white mb-0">
          <i class="fa fa-info-circle"></i> Sentiu falta de algum exercício?
          Clique aqui e sugira.
        </h5>
      </ngb-alert>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col-4">
    <ng-select
      [items]="categories"
      [notFoundText]="'Nenhuma categoria encontrada'"
      class="testabc"
      bindLabel="name"
      name="catselect"
      placeholder="Selecionar Categoria"
      id="catselect"
      [(ngModel)]="categoriaSelecionada"
      (clear)="onResetExercicies()"
      (ngModelChange)="onselectCategory()"
    >
      <ng-template ng-label-tmp let-item="item">
        <span>{{ item.name }}</span>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm"
      >
        <span
          >{{ item.name
          }}<small class="text-muted float-right">{{
            item.user ? "personal" : "plataforma"
          }}</small></span
        >
      </ng-template>
    </ng-select>
  </div>
  <div class="col-5">
    <input
      type="text"
      id="volt"
      class="form-control"
      style="background-color: white; height: 100%"
      placeholder="Procurar exercício"
      name="volt"
      [(ngModel)]="exercicioProcurado"
      (keyup)="onSearchExercicie()"
    />
  </div>
  <div class="col">
    <div class="float-right d-none d-md-block" role="group">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="openExercise(null, false)"
      >
        Adicionar Exercício
      </button>
    </div>
    <div class="btn-group btn-group-lg d-md-none" role="group">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="openExercise(null, false)"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <ul class="list-group">
          <li
            *ngFor="
              let exercicie of exercicies
                | slice
                  : (pageTr - 1) * pageSize
                  : (pageTr - 1) * pageSize + pageSize
            "
            class="list-group-item"
          >
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">
                  {{ exercicie.name }}
                  <span
                    *ngIf="isNewExercise(exercicie)"
                    class="badge badge-pill px-2 font-size-4"
                    >Novo</span
                  >
                </h5>
                <span>{{ exercicie.user ? "personal" : "plataforma" }}</span>
              </div>
              <button
                type="button"
                *ngIf="exercicie.user"
                class="btn btn-outline-warning btn-icon round mr-2"
                (click)="delete(exercicie)"
              >
                <i class="fa fa-trash"></i>
              </button>
              <button
                type="button"
                *ngIf="exercicie.user"
                class="btn btn-outline-warning btn-icon round mr-2"
                (click)="openExercise(exercicie, false)"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button
                type="button"
                *ngIf="!exercicie.user"
                class="btn btn-outline-warning btn-icon round mr-2"
                (click)="openExercise(exercicie, true)"
              >
                <i class="fa fa-copy"></i>
              </button>
              <div class="btn-group btn-group-sm d-md-none" role="group">
                <button
                  type="button"
                  class="btn btn-lg btn-outline-warning btn-icon round mr-2"
                  [disabled]="!exercicie.url_video"
                  (click)="
                    viewMidia(
                      exercicie.url_video_blackbaze
                        ? exercicie.url_video_blackbaze
                        : exercicie.url_video,
                      contentVideo
                    )
                  "
                >
                  <i class="fa fa-video-camera"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-lg btn-outline-warning btn-icon round"
                  [disabled]="!exercicie.url_gif"
                  (click)="
                    viewMidia(
                      exercicie.url_gif_blackbaze
                        ? exercicie.url_gif_blackbaze
                        : exercicie.url_gif,
                      contentGif
                    )
                  "
                >
                  <i class="fa fa-image"></i>
                </button>
              </div>
              <div
                class="btn-group btn-group-lg d-none d-md-block"
                role="group"
              >
                <button
                  type="button"
                  class="btn btn-outline-warning mb-2 mb-md-0"
                  [disabled]="!exercicie.url_video"
                  (click)="
                    viewMidia(
                      exercicie.url_video_blackbaze
                        ? exercicie.url_video_blackbaze
                        : exercicie.url_video,
                      contentVideo
                    )
                  "
                >
                  Vídeo
                </button>
                <button
                  type="button"
                  class="btn btn-outline-warning mb-2 mb-md-0"
                  [disabled]="!exercicie.url_gif"
                  (click)="
                    viewMidia(
                      exercicie.url_gif_blackbaze
                        ? exercicie.url_gif_blackbaze
                        : exercicie.url_gif,
                      contentGif
                    )
                  "
                >
                  GIF
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="exercicies.length > 0">
  <div class="col">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        [(page)]="pageTr"
        [pageSize]="pageSize"
        [maxSize]="2"
        [rotate]="true"
        [boundaryLinks]="true"
        size="sm"
        [collectionSize]="exercicies.length"
      ></ngb-pagination>
    </div>
  </div>
</div>
<div class="row" *ngIf="exercicies.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <ul class="list-group p-0">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum exercício na base de dados.</h5>
                <span>Cadastre o seu primeiro exercício!</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #contentVideo>
  <ng-template
    [ngIf]="
      urlToPlay.startsWith('https://www.youtube.com/') ||
      urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <div class="embed-container">
      <iframe [src]="safeURL" frameborder="0" allowfullscreen></iframe>
    </div>
  </ng-template>
  <ng-template
    [ngIf]="
      !urlToPlay.startsWith('https://www.youtube.com/') &&
      !urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <video controls style="width: 100%">
      <source [src]="urlToPlay" type="video/mp4" />
    </video>
  </ng-template>
</ng-template>
<ng-template #contentGif>
  <IMG [src]="urlToPlay" style="width: 100%" />
</ng-template>

<ng-template #contentTutoriais let-modal>
  <div class="card-header">
    <h4 class="card-title">Tutoriais</h4>
  </div>
  <button class="close-button" (click)="modal.close('Close click')">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <div class="form-row" style="margin: 5px">
    <ul class="list-group mb-2" style="width: 100%">
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">1</span>
          <div class="media-body">
            <h5 class="mt-0">Banco de exercícios da plataforma</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=37o7E2vyZ90',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=37o7E2vyZ90',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">2</span>
          <div class="media-body">
            <h5 class="mt-0">Como adicionar um novo exercício</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=TuuIq2pURik',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=TuuIq2pURik',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dot align-middle mr-2">3</span>
          <div class="media-body">
            <h5 class="mt-0">
              Como adicionar um vídeo e/ou gif próprio de um exercício já
              cadastrado na plataforma
            </h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=B4lnp2b5JU8',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=B4lnp2b5JU8',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>
<ngx-spinner></ngx-spinner>
