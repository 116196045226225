import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
@Component({
  selector: 'app-usuario-infos',
  templateUrl: './usuario-infos.component.html',
  styleUrls: ['./usuario-infos.component.scss']
})
export class UsuarioInfosComponent implements OnInit {

  public modalService: NgbModal;

  constructor(modalService: NgbModal) {
    this.modalService = modalService;
  }

  ngOnInit(): void {
  }
  @Input() usuario!: any; 

  formatDate(lastLogin) {
    return moment(lastLogin).format("DD/MM/YYYY HH:mm");
  }
}
