import {
  NgbCalendar,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import Api from "../helpers/api";
import App from "../helpers/app";
import { Router } from "@angular/router";
import { mergeMap, take } from "rxjs/operators";
import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { UserDataService } from "../shared/services/user-data.service";
import { CustomAdapter } from "app/shared/services/dateAdapter.service";
import { UsuarioInfosComponent } from "./usuario-infos/usuario-infos.component";
import { CustomDateParserFormatter } from "app/shared/services/dateParserFormatter.service";
@Component({
  selector: "app-admin-buscar-usuario",
  templateUrl: "./admin-buscar-usuario.component.html",
  styleUrls: ["./admin-buscar-usuario.component.scss"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class AdminBuscarUsuarioComponent implements OnInit {
  usuario = null;
  filterEmail;
  diasGratuitosInput: string = null;
  tipos = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: "1",
      name: "Aluno",
    },
    {
      id: "0",
      name: "Personal",
    },
  ];
  userView;
  lastModal = null;
  offset = 0;
  limit = 1000;
  page = 1;
  pageSize = 5;
  apiCall = this.api.new().silent();
  hideCustomerAppBanner = null;
  hasIAGOAccess = null;
  isBetaTester = null;

  get today() {
    return this.dateAdapter.toModel(this.ngbCalendar.getToday());
  }

  constructor(
    private app: App,
    private api: Api,
    private router: Router,
    private modalService: NgbModal,
    private ngbCalendar: NgbCalendar,
    private spinner: NgxSpinnerService,
    private userDataService: UserDataService,
    private dateAdapter: NgbDateAdapter<string>
  ) {}

  async ngOnInit() {
    const self = this;
    self.userView = null;
    self.diasGratuitosInput = null;
    self.filterEmail = null;
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    await self.spinner.hide();
  }

  closeModal() {
    if (this.lastModal) {
      this.lastModal.close();
    }
  }

  async searchUhser() {
    const self = this;

    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let dataUsuarios = await self.apiCall
      .get(`search-user/${self.filterEmail}`)
      .pipe(take(1))
      .toPromise();

    if (dataUsuarios.return) {
      self.usuario = dataUsuarios.return;
      self.userDataService.changeUser(this.usuario);
      let appBanner = self.usuario.userParams.find(
        (p) => p.param == "hideCustomerAppBanner"
      )?.value;

      if (!appBanner) {
        this.hideCustomerAppBanner = false;
      } else {
        this.hideCustomerAppBanner = appBanner == "true" ? true : false;
      }

      let hasIAGOAccess = self.usuario.userParams.find(
        (p) => p.param == "hasIAGOAccess"
      )?.value;
      if (!hasIAGOAccess) {
        self.usuario.hasIAGOAccess = "false";
      } else {
        self.usuario.hasIAGOAccess = hasIAGOAccess;
      }

      let isBetaTester = self.usuario.userParams.find(
        (p) => p.param == "isBetaTester"
      )?.value;
      if (!isBetaTester) {
        self.usuario.isBetaTester = "false";
      } else {
        self.usuario.isBetaTester = isBetaTester;
      }
    }

    await self.spinner.hide();
  }

  getDiasGratuitos(freeUntil) {
    if (!freeUntil) {
      return 0;
    }
    var today = moment();
    let diferenca = moment(freeUntil).diff(today, "days");
    if (diferenca > 0) {
      return diferenca;
    } else {
      return 0;
    }
  }

  openModal(content, lastUser, refresh) {
    this.userView = lastUser;
    this.lastModal = this.modalService.open(content, { centered: true });
    this.lastModal.result.then(
      (result) => {
        if (refresh) this.ngOnInit();
      },
      (reason) => {
        if (refresh) this.ngOnInit();
      }
    );
  }
  openManageSubscription(usuario: any) {
    this.router.navigate([`/page/admin/buscar-usuario/${usuario.id}`]);
  }
  openInfoUsuariosModal(usuario: any) {
    const modalRef = this.modalService.open(UsuarioInfosComponent, {
      size: "lg",
    });
    modalRef.componentInstance.usuario = usuario;
  }
  async gerarSenhaProvisoria(content, lastUser) {
    if (lastUser.provisoryPassword) {
      this.openModal(content, lastUser, true);
    } else {
      this.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const data = await this.apiCall
        .put("users/generate/pass", lastUser)
        .pipe(take(1))
        .toPromise();
      this.spinner.hide();
      if (data.return) {
        this.openModal(content, data.return, true);
      }
    }
  }
  liberaAtualizacaoDiasGratuitos(content, lastUser) {
    if (lastUser.freeUntil) {
      const freeDaysSplited = lastUser.freeUntil
        ? moment(lastUser.freeUntil).format("DD/MM/YYYY").split("/")
        : null;

      if (freeDaysSplited) {
        this.diasGratuitosInput = this.dateAdapter.toModel({
          day: Number(freeDaysSplited[0]),
          month: Number(freeDaysSplited[1]),
          year: Number(freeDaysSplited[2]),
        });
      }
    }
    this.openModal(content, lastUser, false);
  }
  async toggleStatus(user) {
    user.status = !user.status;
    await this.atualizaUsuario(user);
  }
  formatDate(lastLogin) {
    return moment(lastLogin).format("DD/MM/YYYY HH:mm");
  }
  formatDateWithoutTime(lastLogin) {
    return moment(lastLogin).format("DD/MM/YYYY");
  }
  async atualizaDiasGratuitos() {
    this.userView.freeUntil = moment(
      this.diasGratuitosInput,
      "DD-MM-YYYY"
    ).toDate();

    await this.atualizaUsuario(this.userView);
  }
  async toggleType(user) {
    if (user.type == 1) {
      user.type = 0;
    } else if (user.type == 0) {
      user.type = 1;
    }
    await this.atualizaUsuario(user);
  }
  async toggleBanner(user) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let value = this.convertBooleanToString(!this.hideCustomerAppBanner);

    this.apiCall
      .put("me/userparams/hideCustomerAppBanner", { value, user })
      .subscribe(async (data) => {
        if (data.success) {
          this.spinner.hide();
          await this.app.alert(
            "Tudo certo!",
            "Preferência salva com sucesso!",
            "success"
          );
          this.ngOnInit();
        } else {
          this.spinner.hide();

          await this.app.alert("Ops :(", data.message, "error");
        }
      });
  }
  convertBooleanToString(bool: Boolean) {
    if (bool == true) {
      return "true";
    } else {
      return "false";
    }
  }
  async atualizaUsuario(user) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const dataUsuarios = await this.apiCall
      .put("users/update", user)
      .pipe(take(1))
      .toPromise();
    this.spinner.hide();
    if (dataUsuarios.success) {
      await this.app.alert(
        "Tudo certo!",
        "Usuário atualizado com sucesso!",
        "success"
      );
      if (this.lastModal) {
        this.lastModal.close();
      }
      this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", dataUsuarios.message, "error");
    }
  }
  async atualizaAssinatura(user) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    let action = this.usuario.subscriptions[0].deletedAt
      ? "reativar"
      : "inativar";
    const dataUsuarios = await this.apiCall
      .put("me/update/subscription/" + user.id + "/" + action)
      .pipe(take(1))
      .toPromise();
    this.spinner.hide();
    if (dataUsuarios.success) {
      await this.app.alert(
        "Tudo certo!",
        "Assinatura atualizada com sucesso!",
        "success"
      );
      if (this.lastModal) {
        this.lastModal.close();
      }
      this.usuario.subscriptions[0] = dataUsuarios.return;
    } else {
      await this.app.alert("Ops :(", dataUsuarios.message, "error");
    }
  }
  async atualizarParametros(user) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    let hasIAGOAccess = this.usuario.hasIAGOAccess;
    let isBetaTester = this.usuario.isBetaTester;
    this.apiCall
      .put("me/userparams/isBetaTester", { value: isBetaTester, user })
      .pipe(
        mergeMap((data) => {
          if (data.success) {
            return this.apiCall.put("me/userparams/hasIAGOAccess", {
              value: hasIAGOAccess,
              user,
            });
          } else {
            throw new Error(data.message);
          }
        })
      )
      .subscribe(
        async (data) => {
          this.spinner.hide();
          if (data.success) {
            await this.app.alert(
              "Tudo certo!",
              "Preferência salva com sucesso!",
              "success"
            );
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
          if (this.lastModal) {
            this.lastModal.close();
          }
        },
        async (error) => {
          this.spinner.hide();
          await this.app.alert("Ops :(", error.message, "error");
        }
      );
  }
  async reactivateUser(user) {
    await this.app
      .confirm(
        "Atenção",
        "Tem certeza que deseja incluir novamente este usuário?"
      )
      .then((resultado) => {
        if (resultado == true) {
          const dataUsuarios = this.apiCall
            .put(`user/restore/${user.id}`)
            .subscribe(async (data) => {
              if (data.success) {
                this.ngOnInit();
                await this.app.alert(
                  "Usuário incluído novamente com sucesso!",
                  "Tudo certo!",
                  "success"
                );
              } else {
                await this.app.alert(
                  "Ops :(",
                  "Ocorreu um erro ao incluir novamente o usuário.",
                  "error"
                );
              }
            });
        }
      });
  }
  async unblockUser(user) {
    await this.app
      .confirm("Atenção", "Tem certeza que deseja desbloquear este usuário?")
      .then((resultado) => {
        if (resultado == true) {
          this.apiCall
            .delete(`user-unblock/${user.email}`)
            .subscribe(async (data) => {
              if (data.success) {
                this.ngOnInit();
                await this.app.alert(
                  "Usuário desbloqueado com sucesso!",
                  "Tudo certo!",
                  "success"
                );
              } else {
                await this.app.alert(
                  "Ops :(",
                  "Ocorreu um erro ao desbloquear o usuário.",
                  "error"
                );
              }
            });
        }
      });
  }
}
