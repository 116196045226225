import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import { ProductType } from "app/personal/pages/finance-store/services-and-products/services-and-products.types";
import { CloudStorageService } from "app/shared/services/cloud-storage/cloud-storage.service";
import { isImageFile, openFilePicker } from "app/util/upload/file-picker";
import { FileUploader } from "ng2-file-upload";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-cover-upload-modal",
  templateUrl: "./cover-upload-modal.component.html",
  styleUrls: ["./cover-upload-modal.component.scss"],
  providers: [CloudStorageService],
})
export class CoverUploadModalComponent implements OnInit {
  apiCall = this.api.new().silent();

  uploader: FileUploader = new FileUploader({
    isHTML5: true,
    allowedFileType: ["JPEG", "JPG", "PNG"],
    maxFileSize: 2000,
  });

  defaultProduct: ProductType | null = null;
  coverImage: string | null = null;

  useImgAsStoreBg = false;

  isLoading: boolean = false;
  isUploadingImage: boolean = false;

  hasBaseDropZoneOver = false;

  @Input("callback") callback: ((status: "success" | "error") => void) | null =
    null;

  constructor(
    private activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private cloudStorage: CloudStorageService,
    private api: Api
  ) {}

  ngOnInit(): void {
    if (this.defaultProduct?.cover) {
      this.coverImage = this.defaultProduct?.cover;
    }
  }

  addOrChangeImage() {
    if (!this.defaultProduct?.id || !this.coverImage) return;

    this.isLoading = true;
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    const body: { cover: string } = { cover: this.coverImage };

    this.apiCall
      .put(`marketplace/product/cover/${this.defaultProduct.id}`, body)
      .subscribe((response) => {
        this.isLoading = false;
        this.spinner.hide();

        if (response.success) {
          this.toastr.success(
            this.defaultProduct?.cover
              ? "Capa do produto atualizada com sucesso"
              : "Capa do produto adicionada com sucesso",
            "Tudo Certo!"
          );

          this.callback?.("success");
          this.closeModal();
        } else {
          this.callback?.("error");
          this.toastr.error(response.message, "Ops :(");
        }
      });
  }

  openFilePicker() {
    openFilePicker({
      accept: "image/png, image/jpeg, image/jpg",
      multiple: false,
      callback: (files) => {
        if (files.length < 0) return;

        const file = files[0];
        if (!file) return;

        if (!isImageFile(file)) return;

        this.isUploadingImage = true;
        this.cloudStorage
          .upload(file, {
            fileType: file.type,
            fileExt: file.name.split(".").pop() || "",
          })
          .subscribe((url) => {
            if (url) {
              // preloading image
              const img = new Image();
              img.src = url;
              img.onload = () => {
                this.coverImage = url;
                this.isUploadingImage = false;
              };
              img.onerror = () => (this.isUploadingImage = false);
            } else {
              this.isUploadingImage = false;
            }
          });
      },
    });
  }

  onfileOver(e: boolean): void {
    if (e !== this.hasBaseDropZoneOver) {
      this.hasBaseDropZoneOver = e;
    }
  }

  onFileDrop(files: FileList) {
    if (files.length < 0) return;

    const file = files[0];
    if (!file) return;

    if (!isImageFile(file)) return;

    this.isUploadingImage = true;
    this.cloudStorage
      .upload(file, {
        fileType: file.type,
        fileExt: file.name.split(".").pop() || "",
      })
      .subscribe((url) => {
        if (url) {
          // preloading image
          const img = new Image();
          img.src = url;
          img.onload = () => {
            this.coverImage = url;
            this.isUploadingImage = false;
          };
          img.onerror = () => (this.isUploadingImage = false);
        } else {
          this.isUploadingImage = false;
        }
      });
  }

  closeModal() {
    this.activeModal.dismiss("closed-by-user");
  }
}
