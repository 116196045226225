<div class="container" style="background: #ffffff; padding: 0px">
  <div class="card fixed-sidebar">
    <img [src]="seller?.banner" alt="logo personal" />
    <div class="card-body">
      <div class="profile-container">
        <img
          [src]="
            userUrl && userUrl.photo_url
              ? userUrl.photo_url
              : '../../../../assets/img/portrait/small/no-avatar.svg'
          "
          class="user-profile-image"
          alt="User Profile Image"
          height="140"
          width="140"
        />
        <div class="profile-info">
          <p class="card-text">{{ seller?.name }}</p>
          <p class="card-subtext" *ngIf="isMobile">{{ displayText }}</p>
          <p *ngIf="!isMobile" class="card-subtext">
            {{ seller?.description }}
          </p>
          <button
            *ngIf="seller?.description.length > maxChars && isMobile"
            (click)="toggleExpand()"
            class="toggle-button"
          >
            {{ isExpanded ? "Mostrar menos" : "Mostrar mais" }}
            <span>{{ isExpanded ? "▲" : "▼" }}</span>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="seller && seller.SellerStoreContact">
      <div
        class="contact-icons row"
        style="justify-content: center; align-items: center"
      >
        <div *ngFor="let contact of seller.SellerStoreContact">
          <button
            type="button"
            *ngIf="contact.contactTypeId === 1"
            (click)="openInstagram(contact.contact)"
          >
            <i
              class="fa fa-instagram"
              style="justify-content: center; display: flex"
              [style.color]="selectedColor"
            ></i>
          </button>
          <button
            type="button"
            *ngIf="contact.contactTypeId === 2"
            (click)="openWhatsApp(contact.contact)"
          >
            <i
              class="fa fa-whatsapp"
              style="justify-content: center; display: flex"
              [style.color]="selectedColor"
            ></i>
          </button>
          <button
            type="button"
            *ngIf="contact.contactTypeId === 3"
            (click)="openEmail(contact.contact)"
          >
            <i
              class="fa fa-envelope-o"
              style="justify-content: center; display: flex"
              [style.color]="selectedColor"
            ></i>
          </button>

          <button
            type="button"
            *ngIf="contact.contactTypeId === 4"
            (click)="openEmail(contact.contact)"
          >
            <i
              class="fa fa-globe"
              style="justify-content: center; display: flex"
              [style.color]="selectedColor"
            ></i>
          </button>

          <button
            type="button"
            *ngIf="contact.contactTypeId === 5"
            (click)="openEmail(contact.contact)"
          >
            <i
              class="fa fa-heart"
              style="align-self: center"
              [style.color]="selectedColor"
            ></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</div>
