import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {
  private paymentMethodSubject = new BehaviorSubject<any>(null);

  setPaymentMethod(cards: any): void {
    this.paymentMethodSubject.next(cards);
  }

  getPaymentMethod() {
    return this.paymentMethodSubject.asObservable();
  }
}