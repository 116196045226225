import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  NgbActiveModal,
  NgbDateAdapter,
  NgbDateParserFormatter,
} from "@ng-bootstrap/ng-bootstrap";
import { CustomAdapter } from "app/shared/services/dateAdapter.service";
import { CustomDateParserFormatter } from "app/shared/services/dateParserFormatter.service";
import * as moment from "moment";
import { ToastrService } from "ngx-toastr";
import { CustomValidators } from "../../helpers/functions/customValidators";

type TrainingInputType = {
  name: string;
  note: string;
  dueDate?: string;
  schedule?: string;
};

type SchedulingFormType = TrainingInputType;

@Component({
  selector: "app-scheduling",
  templateUrl: "./scheduling.component.html",
  styleUrls: ["./scheduling.component.scss"],
  providers: [
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class SchedulingComponent implements OnInit {
  schedulingForm?: FormGroup;

  @Input() trainingInput?: TrainingInputType;
  @Input() callback: (args: TrainingInputType) => void = () => {};

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    let initialData: SchedulingFormType = {
      name: "",
      note: "",
      dueDate: undefined,
      schedule: undefined,
    };

    if (this.trainingInput) {
      initialData = {
        name: this.trainingInput.name,
        note: this.trainingInput.note,
        dueDate: this.trainingInput.dueDate,
        schedule: this.trainingInput.schedule,
      };
    }

    this.initiForm(initialData);
    this.subscribeToFormChanges();
  }

  close() {
    this.activeModal.close();
  }

  initiForm(initialData: SchedulingFormType) {
    this.schedulingForm = new FormGroup({
      name: new FormControl(initialData.name, [
        Validators.required,
        Validators.pattern(/^(?!Nome Treino$)/),
      ]),
      note: new FormControl(initialData.note),
      schedule: new FormControl(initialData.schedule, [Validators.required]),
      dueDate: new FormControl(initialData.dueDate),
    });
  }

  subscribeToFormChanges() {
    const scheduleControl = this.schedulingForm?.get("schedule");

    scheduleControl?.valueChanges.subscribe((value) => {
      const dueDate = this.schedulingForm?.get("dueDate")?.value;

      if (
        CustomValidators.maxDate(moment(dueDate, "DD-MM-YYYY").toDate())(
          scheduleControl
        ) !== null
      ) {
        scheduleControl.setErrors({
          maxDate: { value },
        });
      } else if (
        moment(dueDate, "DD-MM-YYYY").isSame(moment(value, "DD-MM-YYYY"))
      ) {
        scheduleControl.setErrors({
          sameDate: { value },
        });
      } else {
        scheduleControl.setErrors(null);
      }
    });

    const dueDateControl = this.schedulingForm?.get("dueDate");

    dueDateControl?.valueChanges.subscribe((value) => {
      const schedule = this.schedulingForm?.get("schedule")?.value;

      if (
        CustomValidators.minDate(moment(schedule, "DD-MM-YYYY").toDate())(
          dueDateControl
        ) !== null
      ) {
        dueDateControl.setErrors({
          minDate: { value },
        });
      } else if (
        moment(schedule, "DD-MM-YYYY").isSame(moment(value, "DD-MM-YYYY"))
      ) {
        dueDateControl.setErrors({
          sameDate: { value },
        });
      } else {
        dueDateControl.setErrors(null);
      }
    });
  }

  onSubmit() {
    this.schedulingForm?.markAllAsTouched();

    if (this.schedulingForm?.invalid) {
      this.toastr.error("Preencha os campos corretamente");
      return;
    }

    this.callback(this.schedulingForm?.value as SchedulingFormType);
    this.activeModal.close();
  }

  validateField(controlName: string) {
    const control = this.schedulingForm?.controls[controlName];

    return {
      valid: control?.valid && (control?.touched || control?.dirty),
      invalid: control?.invalid && (control?.touched || control?.dirty),
    };
  }
}
