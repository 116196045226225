import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-nutriplan",
  templateUrl: "./nutriplan.component.html",
  styleUrls: ["./nutriplan.component.scss"],
})
export class NutriplanComponent implements OnInit {
  constructor(private modal: NgbModal) {}

  ngOnInit(): void {}
  closeModal() {
    this.modal.dismissAll();
  }
}
