import { Component, OnInit } from '@angular/core';
import Api from "../helpers/api";
import App from "../helpers/app";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from 'rxjs/operators';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-admin-validacao-subconta',
  templateUrl: './admin-validacao-subconta.component.html',
  styleUrls: ['./admin-validacao-subconta.component.scss']
})
export class AdminValidacaoSubcontaComponent implements OnInit {

  apiCall = this.api.new()
  subContas = []
  page = 1
  pageSize = 10
  subAccountSelected = null
  situacaoValidacao = [
   {
      id: '2',
      name: 'Não Validado',
    },
    {
      id: '3',
      name: 'Validado',
    },
    ]
  statusValidacao = null
  comentarioValidacao = null
  lastModal = null
  

  constructor(private modalService: NgbModal,
    private api: Api,
    private app: App,
    private spinner: NgxSpinnerService,) { }

async ngOnInit() {
  const self = this

  self.spinner.show(undefined,
    {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });

    const subContas = await self.apiCall.get('admin/users/subcontas').pipe(take(1)).toPromise()

    if(subContas) this.subContas = subContas.return

    console.log(this.subContas)
    self.spinner.hide()
}

async analisarSubconta(subConta, modal) {
  const self = this

  this.subAccountSelected = subConta
  this.lastModal = modal

  this.lastModal = this.modalService.open(modal, { centered: true, size: "lg" });

}


async openAtualizaContato(modal) {
  const self = this
  this.statusValidacao = null
  this.comentarioValidacao = null
  this.modalService.open(modal, { centered: true });
  
}

async saveAccountValidation(modal, lastModal){
  const self = this

  let chamada = null

  self.spinner.show(undefined,
    {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });

    let body = {
      user: this.subAccountSelected.user,
      identity_validation: this.statusValidacao.id,
      identity_validation_message: this.comentarioValidacao ? this.comentarioValidacao : null
    } 

    chamada = this.apiCall.post('admin/users/validatesubaccount', body)

    chamada.subscribe(async data => {
      if (data.success) {
        this.spinner.hide()
        await this.app.alert('Tudo certo!', 'Validação aplicada com sucesso', 'success')
        console.log("LM",this.lastModal)
        modal.close('Close click')
        this.lastModal.close('Close click')
        this.ngOnInit()
      } else {
        await this.app.alert('Ops :(', data.message, 'error')
        this.spinner.hide()
      }
    });

}

}
