<div class="row text-left">
    <div class="col-sm-12">
      <div class="d-flex justify-content-between mb-0">
        <h1 class="text-bold-300" style="padding: 1.5rem;">Validação de Subcontas</h1>
      </div>
    </div>
  </div>
  <div class="row mb-2 mt-2" *ngIf="subContas.length > 0">
    <div class="col">
      <div class="card-content">
        <div class="card-body p-0">
          <div class="table text-center m-0">
            <table class="table-striped" style="width: 100%;">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>CPF/CNPJ</th>
                  <th>Situação Iugu</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of subContas| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                  <td>{{s.person_name}}</td>
                  <td>{{s.cpf ? s.cpf : s.cnpj}}</td>
                  <td>{{s.can_receive ? 'Liberada' : 'Pendente'}}</td>
                                   <td>
                    <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                      <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                        <i class="ft-more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu class="dropdown-menu" role="menu">
                        <a class="dropdown-item" (click)="analisarSubconta(s, subAccountData)">Analisar Dados</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="form-row" style="margin: 5px">
          <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
            <ngb-pagination style="margin-top: 10px" *ngIf="subContas.length>0" [(page)]="page" [pageSize]="pageSize"
              [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm" [collectionSize]="subContas.length">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="subContas.length == 0">
    <div class="col">
      <div class="card-content">
        <div class="card-body p-0 mt-2">
          <ul class="list-group">
            <li class="list-group-item">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Nenhuma validação pendente.</h5>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
  
  
  
  <ng-template #subAccountData let-modal>
    
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Dados da Conta</h2>
      </div>
      <div class="card-content">
        <div class="card-body">
         <h6>Informações Pessoais</h6><br>
          <div class="form-row">                      
              <div class="col-8">
                <b>Nome:</b> {{subAccountSelected.person_name ? subAccountSelected.person_name : subAccountSelected.resp_name}}                
              </div>
              <div class="col-4">
                <b>CPF:</b> {{subAccountSelected.cpf ? subAccountSelected.cpf : subAccountSelected.resp_cpf}}                
              </div>
            </div><br>
            <hr>
            <h6>Dados para Contato</h6><br>
          <div class="form-row"> 
              <div class="col-6">
                <b>Endereço:</b> {{subAccountSelected.address ? subAccountSelected.address : "Endereço não encontrado"}}                
              </div>
              <div class="col-3">
                <b>CEP:</b> {{subAccountSelected.cep ? subAccountSelected.cep : "CEP não cadastrado"}}                
              </div>
              <div class="col-3">
                <b>Cidade:</b> {{subAccountSelected.city ? subAccountSelected.city : "Cidade Não Cadastrada"}}                
              </div>
            </div>
            <div class="form-row">
              <div class="col-2">
                <b>Estado:</b> {{subAccountSelected.state ? subAccountSelected.state : "Estado Não Cadastrado"}}                
              </div>
              <div class="col-4">
                <b>Telefone:</b> {{subAccountSelected.telephone ? subAccountSelected.telephone : "Sem telefone para contato"}}                
              </div>
            </div><br>
            <hr>
          <h6>Dados Bancarios</h6><br>
          <div class="form-row">                      
              <div class="col-8">
                <b>Banco:</b> {{subAccountSelected.bank_name ? subAccountSelected.bank_name : "Banco Não Cadastrado"}}                
              </div>
              <div class="col-4">
                <b>Tipo de Conta:</b> {{subAccountSelected.bank_account_type ? subAccountSelected.bank_account_type : "Tipo de Conta Não Cadastrada"}}                
              </div>
              </div>
              <div class="form-row">
              <div class="col-8">
                <b>Número da Conta:</b> {{subAccountSelected.bank_account ? subAccountSelected.bank_account : "Sem Número da Conta"}}                
              </div>
              <div class="col-4">
                <b>Agência:</b> {{subAccountSelected.bank_agency ? subAccountSelected.bank_agency : "Agência não cadastrada"}}                
              </div>
            </div>
            <hr>
            <div class="form-row">
                <div class="col-12"><h4>Imagens Enviadas</h4></div>
                
              <div class="col-md-4 col-12">
                <img [src]="subAccountSelected.document_front" style="width:100%">
              </div>
              <div class="col-md-4 col-12">
                <img [src]="subAccountSelected.document_back" style="width:100%">
              </div>
              <div class="col-md-4 col-12">
                <img [src]="subAccountSelected.selfie_image" style="width:100%">
              </div>
            </div>
          
            <div class="form-row mt-4">
              <div class="col-6 text-center">
                <button type="button" class="btn btn-lg btn-block btn-danger"
                  (click)="modal.close('Close click')">Cancelar</button>
              </div>
              <div class="col-6 text-center">
                <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="openAtualizaContato(contactDetails)"
                  >Atualizar Validação</button>
              </div>
            </div>
       
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #contactDetails let-modal>
    <div class="card">
      <div class="card-content">
        <div class="card-body">
          <div class="form-row mb-2">
            <div class="col-12">
              <div class="form-group">
                <label for="diasGratuitosI">Status Validação</label>
                <ng-select [items]="situacaoValidacao"   name="statusValidacao" bindLabel="name"  placeholder="Status da Validação" id="statusValidacao" [(ngModel)]="statusValidacao"></ng-select>
              </div>
            </div>
          </div>
          <div class="form-row mb-2">
            <div class="col-12">
              <div class="form-group">
                <label for="diasGratuitosI">Comentário não validação</label>
                <textarea id="comentarioValidacao" rows="2" class="form-control" name="comentarioValidacao"  maxlength="255"  [(ngModel)]="comentarioValidacao" #inputObse="ngModel" placeholder="Em caso de não validação, informe o motivo."></textarea>
              </div>
            </div>
          </div>
          <div class="form-row mb-2">
            <div class="col-12">
              <div class="form-group">
                <button type="button" class="btn btn-lg btn-warning  btn-block mb-md-0" [disabled]="!statusValidacao || (statusValidacao && statusValidacao.id==1 && !comentarioValidacao)" (click)="saveAccountValidation(modal, subAccountData)">Salvar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
  </ng-template>
  <ngx-spinner></ngx-spinner>