import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";
import { timeObjToSec } from "../../functions/timeObjToSec";

@Pipe({
  name: "objToSec",
})
export class ObjToSec implements PipeTransform {
  transform(
    value:
      | {
          second: number;
          minute: number;
          hour: number;
        }
      | string
      | number,
    hideFields?: string[]
  ): string | number {
    let format: string = "HH:mm:ss";

    if (hideFields) {
      if (hideFields.includes("hour")) {
        format = "mm:ss";
      } else if (hideFields.includes("minute")) {
        format = "HH:ss";
      } else if (hideFields.includes("second")) {
        format = "HH:mm";
      }
    }

    if (typeof value === "object") {
      const seconds = timeObjToSec(value);

      return moment.utc(seconds * 1000).format(format);
    } else if (String(value).match(/^[0-9]+$/)) {
      return moment.utc(parseInt(String(value)) * 1000).format(format);
    }

    return moment.utc(0).format(format);
  }
}
