import { Component, OnInit } from '@angular/core';
import * as cep from "cep-promise";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AuthService } from "../shared/auth/auth.service";
import { NgxSpinnerService } from "ngx-spinner";
import Me from "../helpers/me";
import { SocialAuthService } from "angularx-social-login";
import App from "../helpers/app";
import Api from "../helpers/api";
import { take } from "rxjs/operators";
import * as moment from "moment";
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ReCaptchaV3Service } from 'ng-recaptcha';
declare var Iugu: any;
declare let fbq: Function;
declare let gtag: Function;

@Component({
  selector: 'app-mpd-checkout',
  templateUrl: './mpd-checkout.component.html',
  styleUrls: ['./mpd-checkout.component.scss']
})
export class MpdCheckoutComponent implements OnInit {
  apiCall = this.api.new().silent();
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
  productId = null
  sellerCustomerId = null
  usuario = {
    nome: null,
    email: null,
    phone: null,
    senha: null,
  }
  user = []
  product = null
  plans = []
  usuario_email = null
  formaEscolhida = null
  formasdePagamento = [
    {
      "id": "credit_card",
      "label": "Cartão de Crédito"
    },
    {
      "id": "bank_slip",
      "label": "Boleto"
    },
    {
      "id": "pix",
      "label": "PIX"
    }
  ]

  //desabilitar depois
  promo1 = false
  promo2 = false
  promo3 = false
  cupomRetornado = null

  //Verificar se é preciso
  planoEscolhido = null

  novoCartao = {
    number: null,
    cvv: null,
    date: null,
    name: null
  }
  endereco = {
    cpf_cnpj: null,
    zip_code: null,
    number: null,
    street: null,
    city: null,
    state: null,
    district: null,
  }
  erroCartao = null
  cartaoCadastrado = null
  utmSource = null
  utmMedium = null
  utmCampaign = null
  utmContent = null
  userAgent = null
  eventID = null
  ipAddress = null
  fbpCookie = null


  

  constructor(private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api,
    public cookieService: CookieService,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service) {
    Iugu.setAccountID("B0C64ACE25DF962FF353AA716E9B82CE")


    this.route.params.subscribe((params) => {

      if (params['data']) {
        let checkParams = JSON.parse(atob(params['data']))



        if (checkParams && checkParams.sellerCustomer && checkParams.productId) {
          this.productId = checkParams.productId
          this.sellerCustomerId = checkParams.sellerCustomer
        }

      }
    })

  }

  generateRandomString(){
    let code = Math.random()
       .toString(36)
       .substr(2,4)
       .toUpperCase()
 
       return code
   }



  async ngOnInit() {

    const self = this

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    if (this.sellerCustomerId == null || this.productId == null) {
      await this.app.alert("Erro!", "Ocorreu um erro ao carregar os dados, verifique a URL digitada", 'error')
      window.open(`https://meu.opersonaldigital.com.br`, "_self");
    } else {

      const user = await self.apiCall.post('customer/mvp/checkuser/', { sellercustomerId: this.sellerCustomerId }).pipe(take(1)).toPromise()

      if (user.return) {

        console.log(user.return)

        this.usuario.email = user.return.Customer.email
        this.usuario.nome = user.return.Customer.name
        this.usuario.phone = user.return.Customer.phone

        this.user = user.return.Customer

        console.log("User",this.user)

        const product = await self.apiCall.get('customer/me/getproduct/' + this.productId).pipe(take(1)).toPromise()

        if (product.return) {
          console.log(product.return)
          this.product = product.return
          this.plans = product.return.Plans
          console.log(this.plans)

          if(this.plans){
            this.planoEscolhido = this.plans.find(x=>x.name == 'Trimestral')
            self.formaEscolhida = self.formasdePagamento.find(x=>x.id=='credit_card')
          }
        }

        
        const test = await self.apiCall.post('initiatecheckout/mvp', {user: this.user}).pipe(take(1)).toPromise()
        console.log("Teste", test)
        this.iniciaCheckoutFb()

      } else {
        await this.app.alert("Erro!", user.message, 'error')
      }
    }
    self.spinner.hide();

  }

  disableAssinar() {

    if (!this.planoEscolhido
      || !this.formaEscolhida
      || (!this.usuario.email
        || !this.usuario.nome
      )
      || (this.formaEscolhida.id == 'credit_card' && (!this.novoCartao.number || !this.novoCartao.cvv || !this.novoCartao.date || !this.novoCartao.name))
      || (this.formaEscolhida.id == 'bank_slip' && (!this.endereco.number || !this.endereco.zip_code || !this.endereco.state || !this.endereco.street || !this.endereco.district || !this.endereco.city || !this.endereco.cpf_cnpj))) {
      return true
    } else {
      console.log('this.usuario', this.usuario)
      console.log("Plano", this.planoEscolhido)
      console.log("Forma", this.formaEscolhida)
      return false
    }
  }

  getCreditCard() {
    if (this.novoCartao.number && Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)) {
      return Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)
    } else {
      return ""
    }
  }

  consultaCep() {
    const self = this;
    if (self.endereco.zip_code) {
      self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      cep(self.endereco.zip_code).then(local => {
        self.spinner.hide()
        self.endereco.street = local.street
        self.endereco.district = local.neighborhood
        self.endereco.state = local.state
        self.endereco.city = local.city
      }).catch(erro => {
        self.spinner.hide()
      });
    }

  }

  verificaPlano() {
    const self = this

    if(this.planoEscolhido.interval == 1){
      return " Por mês"

    } else if(this.planoEscolhido.interval == 3){
      if(this.formaEscolhida.id!="credit_card"){
        return " a cada " + this.planoEscolhido.interval + " meses"
      }else{
        return " por mês durante " + this.planoEscolhido.interval + " meses"
      }

    } 
  }

  formatPrice(price) {


    if(this.formaEscolhida.id!="credit_card" && this.planoEscolhido.interval == 3){
      price = price * 3
    }

    return (price / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
  }


 async assinar(){

    const self = this

    if(!self.planoEscolhido || !self.planoEscolhido.identifier || !self.formaEscolhida || !self.formaEscolhida.id){
      return
    }

    console.log('vai acionar spinner')

    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      })

      let data = null

      let captchaToken = null

      let secret = this.generateRandomString()

      captchaToken = await this.generateCaptchaToken(secret)

      secret = btoa(secret)

      data = await self.apiCall.post('me/mpdsubscription', {user: this.user, sellerCustomer: this.sellerCustomerId, productId: this.productId,plan_identifier: self.planoEscolhido.identifier, parcelas: self.planoEscolhido.interval, tipo_pagamento : self.formaEscolhida.id , endereco : self.formaEscolhida.id=='bank_slip' ? self.endereco : null, cartao : self.cartaoCadastrado ? self.cartaoCadastrado : null, cupom: self.cupomRetornado ? self.cupomRetornado : null, utmSourceCheckout: this.utmSource ? this.utmSource : null, utmMediumCheckout: this.utmMedium ? this.utmMedium : null, utmCampaingCheckout: this.utmCampaign ? this.utmCampaign : null, utmContentCheckout: this.utmContent ? this.utmContent : null, captchaToken: captchaToken, key: secret}).pipe(take(1)).toPromise()

      if (data.success) {

        let valor = self.planoEscolhido.value_cents / 100

        if(self.planoEscolhido.name=='Trimestral'){
          valor = valor * 3
        }
      
          this.generateEventID()

          fbq('trackSingle', '544083090982331', 'Purchase', {value: valor.toFixed(2), currency: 'BRL'}, {eventID: this.eventID});
          this.sendFbEventMVP('Purchase', valor.toFixed(2));

          if(self.planoEscolhido.name=='Mensal'){
            fbq('trackSingle', '544083090982331',  'PurchaseM', {value: valor.toFixed(2), currency: 'BRL'}, {eventID: this.eventID});
            this.sendFbEventMVP('PurchaseM', valor.toFixed(2));
            }else if(self.planoEscolhido.name=='Trimestral'){
            fbq('trackSingle', '544083090982331',  'PurchaseT', {value: valor.toFixed(2), currency: 'BRL'}, {eventID: this.eventID});
            this.sendFbEventMVP('PurchaseT', valor.toFixed(2));
          } 
              
          
      }

      setTimeout(async function () {
  
        if (data.success) {

          console.log("data",data)
           
          await self.app.alert('Tudo certo!', 'Plano assinado com sucesso!', 'success')
          
          if(data.message && data.message.startsWith('https://faturas.iugu.com/')){
            window.open(data.message, "_self")
          }else{
            window.open('https://meu.opersonaldigital.com.br/pagina-de-obrigado-pos-compra/', "_self")
          }
        
        } else {
          console.log('vai esconder spinner')
  
          await self.spinner.hide()
          await self.app.alert('Ops :(', data.message, 'error')
        }
      }, 3000)
  }

  async sendFbEventMVP(event, value) {
    const self = this

    this.ipAddress = await this.getIPAddress()

    this.getUserAgent()

    let body = {
      event: event,
      value: value ? value : null,
      fbpCookie: this.fbpCookie ? this.fbpCookie : null,
      user: this.user,
      ip: this.ipAddress ? this.ipAddress : null,
      userAgent: this.userAgent ? this.userAgent : null,
      eventID: this.eventID
    }

    console.log("Body", this.user)
  
      self.apiCall.post('send/conversionMVPLogged', body).subscribe();
  
  }

  async getIPAddress() {
    const self = this
    return new Promise<void>((resolve, reject) => {

      self.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
        resolve(res.ip)

      })
    })

  }

  getUserAgent() {
    this.userAgent = window.navigator.userAgent;
  }

  async generateEventID() {
    const min = Math.ceil(1000000);
    const max = Math.floor(1000000000);
    this.eventID = "FBPL"+Math.floor(Math.random() * (max - min + 1)) + min;
  
  }

  async cadastrarCartaoEAssinar(){

    console.log("Cadastra cartão")
    const self = this
    self.erroCartao = ""
    let name = ""
    let cvv = ""
    let date = ""
    let number = ""

    if(self.novoCartao.cvv){
      cvv = self.novoCartao.cvv.replace(/\s/g, '')
    }
    name = self.novoCartao.name

    if(self.novoCartao.number){
      number = self.novoCartao.number.replace(/\s/g, '')
    }
    if(self.novoCartao.date){
      date = self.novoCartao.date.replace(/\s/g, '')
    }

    if(!Iugu.utils.validateCreditCardNumber(number)){
      self.erroCartao = "Número de cartão inválido"
      return
    }
    if(!Iugu.utils.validateExpirationString(date)){
      self.erroCartao = "Data de validade inválida"
      return
    }
    if(!Iugu.utils.getBrandByCreditCardNumber(number) || !Iugu.utils.validateCVV(cvv, Iugu.utils.getBrandByCreditCardNumber(number))){
      self.erroCartao = "Data de validade inválida"
      return
    }

    let cc = Iugu.CreditCard(number,
      date.substring(0,2), date.substring(3,7),name.split(' ')[0],
      name.substring(name.indexOf(" ") + 1), cvv);
    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    Iugu.createPaymentToken(cc, async function(response) {
      if (response.errors) {
        await self.spinner.hide()
        self.erroCartao = "Erro ao validar seu cartão."
        return
      } else {
        self.cartaoCadastrado = response.id
        await self.spinner.hide()
        await self.assinar()
      }
    });

  }



  iniciaCheckoutFb(){
    fbq('init', '544083090982331')
    console.log("mandou começar checkout")
    fbq('trackSingle', '544083090982331', 'PageView', {}, {eventID: this.eventID});
    this.sendFbEventMVP('PageView', null);
    fbq('trackSingle', '544083090982331', 'InitiateCheckout', {}, {eventID: this.eventID});
    this.sendFbEventMVP('InitiateCheckout', null);
  }


  async generateCaptchaToken(secret) {
    try {
      const token = await this.recaptchaV3Service.execute('purchaseMPD'+secret).pipe(take(1)).toPromise();
     return token
    } catch (error) {
      return null     
    }
}

}
