import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as cep from "cep-promise";
import * as moment from "moment";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { CookieService } from "ngx-cookie-service";
import { CountdownComponent } from "ngx-countdown";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Api from "../helpers/api";
import App from "../helpers/app";
import Me from "../helpers/me";
import { AuthService } from "../shared/auth/auth.service";
declare var Iugu: any;
declare let fbq: Function;
declare let gtag: Function;
import { UrlService } from "../shared/services/url.service";

type HoveredBloonType = "course" | "ment" | "months" | "trainings" | null;

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  apiCall = this.api.new().silent();
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  hidecupom = false;
  @ViewChild("cd", { static: false }) private countdown: CountdownComponent;
  planos = [
    {
      id: "36CE3CD024874D238A1752068A96BC91",
      identifier: "plano_1",
      name: "Mensal",
      price: "R$ 69,90  por mês",
      priceAlert: "R$ 69,90",
      price_p1: "R$ 34,95 no primeiro mês",
      price_p2: "R$ 48,93 no primeiro mês",
      price_p3: "R$ 59,41 no primeiro mês",
      price_p4: "R$ 1 no primeiro mês",
      parcelas: 1,
      pfb: 69.9,
      pfb1: 34.95,
      pfb2: 48.93,
      pfb3: 59.41,
      pfb4: 1.0,
      order: 0,
    },
    {
      id: "3E820B5BF76E46638A15A43EC7D4A599",
      identifier: "plano_2",
      name: "Trimestral",
      price: "R$ 49,90  por mês durante 3 meses",
      priceAlert: "R$ 49,90",
      price_p1: "R$ 24,95 no primeiro mês",
      price_p2: "R$ 34,93 no primeiro mês",
      price_p3: "R$ 42,41 no primeiro mês",
      price_p4: "R$ 1 no primeiro mês",
      parcelas: 3,
      pfb: 49.9,
      pfb1: 24.95,
      pfb2: 34.93,
      pfb3: 42.41,
      pfb4: 1.0,
      order: 1,
    },
    {
      id: "BE0720EB85994E469D9253847BDD16A6",
      identifier: "plano_3",
      name: "Anual",
      price: "R$ 39,90  por mês durante 12 meses",
      priceAlert: "R$ 39,90",
      price_p1: "R$ 19,95 no primeiro mês",
      price_p2: "R$ 27,93 no primeiro mês",
      price_p3: "R$ 33,91 no primeiro mês",
      price_p4: "R$ 1 no primeiro mês",
      parcelas: 12,
      pfb: 39.9,
      pfb1: 19.95,
      pfb2: 27.93,
      pfb3: 33.91,
      pfb4: 1.0,
      order: 5,
    },
    {
      id: "A3A490189BAC49B792BDD7B7228FEE4F",
      identifier: "plano_2_boleto",
      name: "Trimestral",
      price: "R$ 149,70 a cada 3 meses",
      price_p1: "R$ 124,75 pelos primeiros 3 meses",
      price_p2: "R$ 134,73 pelos primeiros 3 meses",
      price_p3: "R$ 142,21 pelos primeiros 3 meses",
      price_p4: "R$ 100,80 pelos primeiros 3 meses",
      pfbaux: 49.9,
      labelaux: " pelos primeiros 3 meses",
      pfb: 149.7,
      pfb1: 124.75,
      pfb2: 134.73,
      pfb3: 142.21,
      pfb4: 100.8,
      order: 3,
    },
    {
      id: "02C8B0B4EDE64019AFF315A2A078AD32",
      identifier: "plano_3_boleto",
      name: "Anual",
      price: "R$ 478,80 a cada 12 meses",
      price_p1: "R$ 458,85 pelos primeiros 12 meses",
      price_p2: "R$ 466,83 pelos primeiros 12 meses",
      price_p3: "R$ 472,81 pelos primeiros 12 meses",
      price_p4: "R$ 439,90 pelos primeiros 12 meses",
      pfbaux: 39.9,
      labelaux: " pelos primeiros 12 meses",
      pfb: 478.8,
      pfb1: 458.85,
      pfb2: 466.83,
      pfb3: 472.81,
      pfb4: 439.9,
      order: 5,
    },
  ];
  planosFiltered = [];
  formas = [
    {
      id: "credit_card",
      name: "Cartão de Crédito",
    },
    {
      id: "bank_slip",
      name: "Boleto",
    },
    {
      id: "pix",
      name: "PIX",
    },
  ];
  formasFiltered = [];
  novoCartao = {
    number: null,
    cvv: null,
    date: null,
    name: null,
  };
  usuario = {
    nome: null,
    email: null,
    phone: null,
    senha: null,
  };

  usuario_email;
  perdeuFocoEmail = false;

  cupomDigitado;
  cupomRetornado;

  endereco = {
    cpf_cnpj: null,
    zip_code: null,
    number: null,
    street: null,
    city: null,
    state: null,
    district: null,
  };
  userToLogin = {
    email: null,
    senha: null,
  };
  user = null;
  planoParam = null;
  planoEscolhido = null;
  logged = false;
  formaEscolhida = null;
  erroCartao = null;
  utmSource = null;
  utmMedium = null;
  utmCampaing = null;
  utmContent = null;
  ipAddress = null;
  fbpCookie = null;
  userAgent = null;
  referrer = null;
  estados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  eventID = null;
  promo1 = false;
  promo2 = false;
  promo3 = false;
  promo4 = false;
  promoParc1 = false;
  promoParc2 = false;
  promoParc3 = false;
  promoParc4 = false;
  promoParc5 = false;
  promoParc6 = false;
  promoParc10 = false;
  isPromoParc = false;
  newUser = false;
  acceptTerms = false;
  venceuDiasGratuitos = false;
  parcelado = false;
  activePromo = null;
  hoveredBonusBloon: HoveredBloonType = null;
  countdownTimeUnits: Array<[string, number]> = [
    ["Y", 1000 * 60 * 60 * 24 * 365], // years
    ["M", 1000 * 60 * 60 * 24 * 30], // months
    ["D", 1000 * 60 * 60 * 24], // days
    ["H", 1000 * 60 * 60], // hours
    ["m", 1000 * 60], // minutes
    ["s", 1000], // seconds
    ["S", 1], // million seconds
  ];
  configTimer = {
    leftTime: 0,
    formatDate: ({ date, formatStr }) => {
      let duration = Number(date || 0);

      return this.countdownTimeUnits.reduce((current, [name, unit]) => {
        if (current.indexOf(name) !== -1) {
          const v = Math.floor(duration / unit);
          duration -= v * unit;
          return current.replace(
            new RegExp(`${name}+`, "g"),
            (match: string) => {
              return v.toString().padStart(match.length, "0");
            }
          );
        }
        return current;
      }, formatStr);
    },
  };
  currentUri: any;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api,
    public cookieService: CookieService,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private urlService: UrlService
  ) {
    Iugu.setAccountID("B0C64ACE25DF962FF353AA716E9B82CE");
    //Iugu.setTestMode(true);
    this.route.queryParams.subscribe((params) => {
      if (params["plano"]) {
        this.planoParam = params["plano"];
      }

    if(params["referrer"]){
      this.referrer = params["referrer"];
      console.log("Referrer", this.referrer)
    }

      if (params["cupom"] && !params["prom"]) {
        this.cupomDigitado = params["cupom"];
        if (this.cupomDigitado == "OPDA1REAL") {
          this.activePromo = 4;
        }
      }
      if (params["hidecupom"] && params["hidecupom"] == "true") {
        this.hidecupom = true;
      } else {
        this.hidecupom = false;
      }

      if (params["utm_source"]) {
        this.utmSource = params["utm_source"];
      }

      if (params["utm_medium"]) {
        this.utmMedium = params["utm_medium"];
      }

      if (params["utm_campaign"]) {
        this.utmCampaing = params["utm_campaign"];
      }

      if (params["utm_campaing"]) {
        this.utmCampaing = params["utm_campaing"];
      }

      if (params["utm_content"]) {
        this.utmContent = params["utm_content"];
      }

      if (params["pcode"]) {
        this.promoParc1 = false;
        this.promoParc2 = false;
        this.promoParc3 = false;
        this.promoParc4 = false;
        this.promoParc5 = false;
        this.promoParc6 = false;
        this.promoParc10 = false;

        if (params["pcode"] == "p1") {
          this.promo1 = true;
          this.promo2 = false;
          this.promo3 = false;
          this.promo4 = false;
        }
        if (params["pcode"] == "p2") {
          this.promo1 = false;
          this.promo2 = true;
          this.promo3 = false;
          this.promo4 = false;
        }
        if (params["pcode"] == "p3") {
          this.promo1 = false;
          this.promo2 = false;
          this.promo3 = true;
          this.promo4 = false;
        }
        if (params["pcode"] == "p4") {
          this.promo1 = false;
          this.promo2 = false;
          this.promo3 = false;
          this.promo4 = true;
        }
      }

      if (params["parc"] && params["parc"] == "1") this.parcelado = true;
      if(params["parcelado"]) this.parcelado = true;

      if (params["prom"]) {
        this.parcelado = true;

        this.promo1 = false;
        this.promo2 = false;
        this.promo3 = false;
        this.hidecupom = true;

        this.isPromoParc = true;

        switch (params["prom"]) {
          case "1":
            this.promoParc1 = true;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 5;
            break;
          case "2":
            this.promoParc1 = false;
            this.promoParc2 = true;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 6;
            break;
          case "3":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = true;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 7;
            break;
          case "4":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = true;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 8;
            break;
          case "5":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = true;
            this.promoParc6 = false;
            this.promoParc10 = false;
            this.activePromo = 9;
            break;
          case "6":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = true;
            this.promoParc10 = false;
            this.activePromo = 10;
            break;
          case "10":
            this.promoParc1 = false;
            this.promoParc2 = false;
            this.promoParc3 = false;
            this.promoParc4 = false;
            this.promoParc5 = false;
            this.promoParc6 = false;
            this.promoParc10 = true;
            this.activePromo = 11;
            break;
        }
      }
    });
  }

  iniciaCheckoutFb() {
    this.generateEventID();
    fbq("track", "PageView", {}, { eventID: this.eventID });
    this.sendFbEvent("PageView", null, this.user ? true : false);
    fbq("track", "InitiateCheckout", {}, { eventID: this.eventID });
    this.sendFbEvent("InitiateCheckout", null, this.user ? true : false);
    //Initiate Checkout Google ADS
    gtag("event", "conversion", {
      send_to: "AW-861390287/wcpzCIPH7-kCEM-L35oD",
    });

    if (this.planoParam == "plano_1") {
      this.sendFbEvent("InitiateCheckoutM", null, this.user ? true : false);
      fbq("track", "InitiateCheckoutM", {}, { eventID: this.eventID });
    } else if (this.planoParam == "plano_2") {
      fbq("track", "InitiateCheckoutT", {}, { eventID: this.eventID });
      this.sendFbEvent("InitiateCheckoutT", null, this.user ? true : false);
    } else if (this.planoParam == "plano_3") {
      fbq("track", "InitiateCheckoutA", {}, { eventID: this.eventID });
      this.sendFbEvent("InitiateCheckoutA", null, this.user ? true : false);
    }
  }

  async generateEventID() {
    const min = Math.ceil(1000000);
    const max = Math.floor(1000000000);
    this.eventID = "FBPL" + Math.floor(Math.random() * (max - min + 1)) + min;
  }

  generateRandomString() {
    let code = Math.random().toString(36).substr(2, 4).toUpperCase();

    return code;
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  async ngOnInit() {
    const self = this;
    self.cupomRetornado = null;

    console.log("cupom", self.hidecupom);

    this.fbpCookie = this.cookieService.get("_fbp");

    this.currentUri = this.urlService.getCurrentUri();

    console.log("Parcelado1", this.parcelado);

    if (this.fbpCookie == "") {
      this.fbpCookie = null;
    }

    const user = await self.me.get().pipe(take(1)).toPromise();
    self.user = user;

    console.log("User", self.user);

    if (
      self.user &&
      self.user.promotionFunnel &&
      (self.user.promotionFunnel == 2 || self.user.promotionFunnel == 3)
    )
      this.parcelado = true;

    if (this.parcelado) {
      this.hidecupom = true;
      this.planos.push(
        {
          id: "B51FBAAA5F9A4FBAA8EC633320C291FF",
          identifier: "plano_4",
          name: "Semestral",
          price: "R$ 39,90  por mês durante 7 meses",
          priceAlert: "R$ 39,90",
          price_p1: "R$ 27,93 no primeiro mês",
          price_p2: "R$ 33,91 no primeiro mês",
          price_p3: "R$ 1 no primeiro mês",
          price_p4: "R$ 1 no primeiro mês",
          parcelas: 7,
          pfb: 39.9,
          pfb1: 27.93,
          pfb2: 33.91,
          pfb3: 1.0,
          pfb4: 1.0,
          order: 4,
        },
        {
          id: "C1B202CD90BE4F16ACF04EFEC4212A0A",
          identifier: "plano_4_boleto",
          name: "Semestral",
          price: "R$ 239,40 a cada 6 meses",
          priceAlert: "R$ 39,90",
          price_p1: "R$ 233,40 pelos primeiros 6 meses",
          price_p2: "R$ 223,70 pelos primeiros 6 meses",
          price_p3: "R$ 214,90 pelos primeiros 6 meses",
          price_p4: "R$ 214,90 pelos primeiros 6 meses",
          parcelas: 7,
          pfb: 239.4,
          pfb1: 233.4,
          pfb2: 223.7,
          pfb3: 214.9,
          pfb4: 214.9,
          order: 4,
        }
      );
    }

    if (self.planoParam) {
      self.planoEscolhido = self.planos.find(
        (x) => x.identifier.toLowerCase() == self.planoParam.toLowerCase()
      );
    } else {
      self.planoEscolhido = self.planos.find(
        (x) => x.identifier.toLowerCase() == "plano_3"
      );
    }
    self.formasFiltered = self.formas;
    self.formaEscolhida = self.formas.find((x) => x.id == "credit_card");
    self.planosFiltered = self.planos.filter(
      (x) => !x.identifier.toLowerCase().includes("boleto")
    );
    self.planosFiltered = self.planosFiltered.sort((a, b) => a.order - b.order);
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    await this.verificaCupom();

    if (self.user) {
      if (self.user.installmentSubscription == true) {
        this.parcelado = true;
      }

      console.log("Parcelado2", this.parcelado);

      self.usuario_email = self.user.email;
      await self.verificaEmail();
      self.perdeuFocoEmail = true;
      self.userToLogin.senha = JSON.parse(JSON.stringify(user)).password;
      await self.login();
    }
    const datapagamentos = await self.apiCall
      .get("seller/me/verify/subscription/checkoutpadrao")
      .pipe(take(1))
      .toPromise();
    await self.spinner.hide();
    if (datapagamentos.return && datapagamentos.return.plano_ativo) {
      await self.router.navigate(["/page"]);
    } else {
      self.iniciaCheckoutFb();
    }
    if (
      !self.cupomDigitado ||
      (self.cupomDigitado && self.isCupomInvalido) ||
      !self.isPromoParc
    ) {
      console.log("caiu no valida", self.isPromoParc);
      self.validaPromo();
    }

    /*    if(this.activePromo && this.activePromo==5 && moment().isAfter('2022-12-30 23:59:59')){
      console.log("passou da data")
      this.activePromo = null
    }*/

    if (this.activePromo == 5 || this.activePromo === 11) {
      self.configTimer.leftTime = moment()
        .add(30, "minutes")
        .diff(moment(), "seconds");
      setTimeout(() => {
        if (this.countdown) {
          console.log("tinha countdown");
          this.countdown.begin();
        }
      }, 500);
    }
    this.planosFiltered = this.planosFiltered.sort((a, b) => a.order - b.order);
  }
  validaPromo() {
    const self = this;
    if (self.user && self.user.freeUntil && !self.isPromoParc) {
      let diferenca = moment(this.user.freeUntil).diff(
        moment(this.user.createdAt),
        "days"
      );
      if (diferenca == 7) {
        let verificaPromo = moment().diff(moment(this.user.createdAt), "days");
        if (this.user.promotionFunnel) {
          if (
            this.user.promotionFunnel == 1 ||
            this.user.promotionFunnel == 6
          ) {
            if (verificaPromo == 0) {
              this.activePromo = 1;
              this.promo1 = true;
              this.promo2 = false;
              this.promo3 = false;
              this.promo4 = false;
            }
            if (verificaPromo > 0 && verificaPromo <= 4) {
              this.activePromo = 2;
              this.promo1 = false;
              this.promo2 = true;
              this.promo3 = false;
              this.promo4 = false;
            }
            if (verificaPromo > 4 && verificaPromo <= 7) {
              this.activePromo = 3;
              this.promo1 = false;
              this.promo2 = false;
              this.promo3 = true;
              this.promo4 = false;
            }
            if (verificaPromo > 7 && verificaPromo <= 10) {
              this.activePromo = 4;
              this.promo1 = false;
              this.promo2 = false;
              this.promo3 = false;
              this.promo4 = true;
            }
          }
          if (
            this.user.promotionFunnel == 2 ||
            this.user.promotionFunnel == 3
          ) {
            if (verificaPromo == 0) {
              this.isPromoParc = true;
              this.promoParc1 = true;
              this.activePromo = 5;
            }
            if (verificaPromo > 0 && verificaPromo <= 2) {
              this.isPromoParc = true;
              this.promoParc2 = true;
              this.activePromo = 6;
            }
            if (verificaPromo > 2 && verificaPromo <= 7) {
              this.isPromoParc = true;
              this.promoParc3 = true;
              this.activePromo = 7;
            }
            if (verificaPromo > 7 && verificaPromo <= 10) {
              if (this.user.promotionFunnul == 2) {
                this.activePromo = 4;
              } else if (this.user.promotionFunnul == 3) {
                this.isPromoParc = true;
                this.promoParc4 = true;
                this.activePromo = 8;
              }
            }
          }
        } else {
          if (verificaPromo == 0) {
            this.activePromo = 1;
            this.promo1 = true;
            this.promo2 = false;
            this.promo3 = false;
            this.promo4 = false;
          }
          if (verificaPromo > 0 && verificaPromo <= 4) {
            this.activePromo = 2;
            this.promo1 = false;
            this.promo2 = true;
            this.promo3 = false;
            this.promo4 = false;
          }
          if (verificaPromo > 4 && verificaPromo <= 7) {
            this.activePromo = 3;
            this.promo1 = false;
            this.promo2 = false;
            this.promo3 = true;
            this.promo4 = false;
          }
          if (verificaPromo > 7 && verificaPromo <= 10) {
            this.activePromo = 4;
            this.promo1 = false;
            this.promo2 = false;
            this.promo3 = false;
            this.promo4 = true;
          }
        }
      }
      if (diferenca == 30) {
        let verificaPromo = moment().diff(moment(this.user.createdAt), "days");
        if (verificaPromo == 0) {
          this.promo1 = true;
          this.promo2 = false;
          this.promo3 = false;
        }
        if (verificaPromo == 28) {
          this.promo1 = false;
          this.promo2 = true;
          this.promo3 = false;
        }
        if (verificaPromo == 31 || verificaPromo == 32 || verificaPromo == 33) {
          this.promo1 = false;
          this.promo2 = false;
          this.promo3 = true;
        }
      }
    } else {
      this.promo1 = false;
      this.promo2 = false;
      this.promo3 = false;
    }
    console.log("Promo", this.activePromo);
  }
  ngAfterViewInit() {
    const self = this;
    console.log("planoParam", self.planoParam);
    if (self.planoParam) {
      self.planoEscolhido = self.planos.find(
        (x) => x.identifier.toLowerCase() == self.planoParam.toLowerCase()
      );
    } else {
      self.planoEscolhido = self.planos.find(
        (x) => x.identifier.toLowerCase() == "plano_3"
      );
    }
    console.log("planoEscolhido", self.planoEscolhido);
    self.formaEscolhida = self.formas.find((x) => x.id == "credit_card");
  }
  isEmailInvalido = false;
  isEmailCadastrado = false;
  async verificaEmail() {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.reinicializaVariaveis();
    self.usuario.email = self.usuario_email;
    if (self.usuario_email) {
      const verificacao = await self.apiCall
        .get("seller/me/verificacao-email/" + self.usuario_email)
        .pipe(take(1))
        .toPromise();
      if (verificacao.return && verificacao.return.existente == true) {
        self.isEmailCadastrado = true;
        self.userToLogin.email = self.usuario_email;
      } else {
        self.usuario.email = self.usuario_email;
      }
      if (
        !self.validateEmail(self.usuario_email) ||
        self.usuario_email.includes(".com.com")
      ) {
        self.isEmailInvalido = true;
      }
    }
    self.spinner.hide();
  }

  cupomfbprice = null;

  reinicializaVariaveis() {
    const self = this;
    self.user = null;
    self.loginInvalido = false;
    self.userToLogin = {
      email: null,
      senha: null,
    };
    self.usuario = {
      nome: null,
      email: null,
      phone: null,
      senha: null,
    };
    self.isEmailCadastrado = false;
    self.isEmailInvalido = false;
  }
  priceToShow() {
    const self = this;

    if (self.cupomRetornado) {
      let retorno = "";
      if (self.planoEscolhido.identifier.toLowerCase().includes("boleto")) {
        if (self.cupomRetornado.percentageFirstMonth != null) {
          let aux =
            self.planoEscolhido.pfb -
            (self.planoEscolhido.pfbaux *
              self.cupomRetornado.percentageFirstMonth) /
              100;
          aux = Math.round((aux + Number.EPSILON) * 100) / 100;
          self.cupomfbprice = aux;
          if (aux != null) {
            retorno =
              "R$ " +
              aux.toFixed(2).toString().replace(".", ",") +
              self.planoEscolhido.labelaux;
          }
        } else if (self.cupomRetornado.priceFirstMonth != null) {
          let aux =
            self.planoEscolhido.pfb -
            self.planoEscolhido.pfbaux +
            self.cupomRetornado.priceFirstMonth;
          aux = Math.round((aux + Number.EPSILON) * 100) / 100;
          self.cupomfbprice = aux;
          if (aux != null) {
            retorno =
              "R$ " +
              aux.toFixed(2).toString().replace(".", ",") +
              self.planoEscolhido.labelaux;
          }
        }
      } else {
        if (self.cupomRetornado.percentageFirstMonth != null) {
          let aux =
            self.planoEscolhido.pfb -
            (self.planoEscolhido.pfb *
              self.cupomRetornado.percentageFirstMonth) /
              100;
          aux = Math.round((aux + Number.EPSILON) * 100) / 100;
          self.cupomfbprice = aux;
          if (aux != null) {
            retorno =
              "R$ " +
              aux.toFixed(2).toString().replace(".", ",") +
              " no primeiro mês";
          }
        } else if (self.cupomRetornado.priceFirstMonth != null) {
          self.cupomfbprice = self.cupomRetornado.priceFirstMonth;
          retorno =
            "R$ " +
            self.cupomRetornado.priceFirstMonth
              .toFixed(2)
              .toString()
              .replace(".", ",") +
            " no primeiro mês";
        }
      }
      return retorno;
    } else if (
      self.planoEscolhido &&
      self.activePromo &&
      self.activePromo == 1
    ) {
      return self.planoEscolhido.price_p1;
    } else if (
      self.planoEscolhido &&
      self.activePromo &&
      self.activePromo == 2
    ) {
      return self.planoEscolhido.price_p2;
    } else if (
      self.planoEscolhido &&
      self.activePromo &&
      self.activePromo == 3
    ) {
      return self.planoEscolhido.price_p3;
    } else if (
      self.planoEscolhido &&
      self.activePromo &&
      self.activePromo == 4
    ) {
      return self.planoEscolhido.price_p4;
    } else {
      return "";
    }
  }
  isCupomInvalido = false;
  async verificaCupom() {
    const self = this;
    self.cupomRetornado = null;
    self.isCupomInvalido = false;
    if (self.cupomDigitado) {
      await self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const verificacao = await self.apiCall
        .get("coupon/verify/" + self.cupomDigitado)
        .pipe(take(1))
        .toPromise();
      if (verificacao && verificacao.return) {
        self.cupomRetornado = verificacao.return;
      } else {
        self.isCupomInvalido = true;
        self.hidecupom = false;
      }
      await self.spinner.hide();
    }
  }

  formatPreco(plano) {
    return (plano.prices[0].value_cents / 100).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  loginInvalido = false;
  async login() {
    const self = this;
    self.loginInvalido = false;
    if (self.userToLogin.email && self.userToLogin.senha) {
      self.usuario.senha = self.userToLogin.senha;
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      const login = await self.me.login(
        self.userToLogin.email,
        self.userToLogin.senha
      );
      if (login) {
        this.user = await this.me.get().pipe(take(1)).toPromise();
        if (this.user && this.user.installmentSubscription == true) {
          this.parcelado = true;
        }
        console.log("Parcelado 3", this.parcelado);
        this.validaPromo();
        const datapagamentos = await self.apiCall
          .get("seller/me/verify/subscription/checkoutpadrao")
          .pipe(take(1))
          .toPromise();
        self.spinner.hide();
        if (datapagamentos.return && datapagamentos.return.plano_ativo) {
          await self.router.navigate(["/page"]);
        } else {
          await self.apiCall
            .get("seller/me/initiatecheckout")
            .pipe(take(1))
            .toPromise();
        }
      } else {
        self.spinner.hide();
        self.loginInvalido = true;
      }
    }
  }
  getCreditCard() {
    if (
      this.novoCartao.number &&
      Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)
    ) {
      return Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number);
    } else {
      return "";
    }
  }
  changePlano() {
    const self = this;
    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "credit_card"
    ) {
      if (self.planoEscolhido.identifier == "plano_2_boleto") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_2"
        );
      }
      if (self.planoEscolhido.identifier == "plano_3_boleto") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_3"
        );
      }
    }
    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      (self.formaEscolhida.id == "bank_slip" || self.formaEscolhida.id == "pix")
    ) {
      if (self.planoEscolhido.identifier == "plano_2") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_2_boleto"
        );
      }
      if (self.planoEscolhido.identifier == "plano_3") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_3_boleto"
        );
      }
    }
  }

  changeForma() {
    const self = this;
    if (
      self.formaEscolhida &&
      (self.formaEscolhida.id == "bank_slip" || self.formaEscolhida.id == "pix")
    ) {
      self.planosFiltered = self.planos.filter((x) =>
        x.identifier.toLowerCase().includes("boleto")
      );
    } else {
      self.planosFiltered = self.planos.filter(
        (x) => !x.identifier.toLowerCase().includes("boleto")
      );
    }
    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "credit_card"
    ) {
      if (self.planoEscolhido.identifier == "plano_2_boleto") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_2"
        );
      }
      if (self.planoEscolhido.identifier == "plano_3_boleto") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_3"
        );
      }
      if (self.planoEscolhido.identifier == "plano_4_boleto") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_4"
        );
      }
    }
    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      (self.formaEscolhida.id == "bank_slip" || self.formaEscolhida.id == "pix")
    ) {
      if (self.planoEscolhido.identifier == "plano_1") {
        self.planoEscolhido = null;
      }
      if (self.planoEscolhido.identifier == "plano_2") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_2_boleto"
        );
      }
      if (self.planoEscolhido.identifier == "plano_3") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_3_boleto"
        );
      }
      if (self.planoEscolhido.identifier == "plano_4") {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == "plano_4_boleto"
        );
      }
    }
    self.planosFiltered = self.planosFiltered.sort((a, b) => a.order - b.order);
    console.log("Plano escolhido", self.planoEscolhido);
    console.log("is promo", this.isPromoParc);
  }

  consultaCep() {
    const self = this;
    if (self.endereco.zip_code) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      cep(self.endereco.zip_code)
        .then((local) => {
          self.spinner.hide();
          self.endereco.street = local.street;
          self.endereco.district = local.neighborhood;
          self.endereco.state = local.state;
          self.endereco.city = local.city;
        })
        .catch((erro) => {
          self.spinner.hide();
        });
    }
  }

  cartaoCadastrado = null;
  async assinar() {
    const self = this;
    if (
      !self.planoEscolhido ||
      !self.planoEscolhido.identifier ||
      !self.formaEscolhida ||
      !self.formaEscolhida.id
    ) {
      return;
    }
    console.log("vai acionar spinner");
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    let data = null;

    let captchaToken = null;

    let secret = this.generateRandomString();

    captchaToken = await this.generateCaptchaToken(secret);

    secret = btoa(secret);

    if (this.user) {
      data = await self.apiCall
        .post("seller/me/subscription2", {
          plan_identifier: self.planoEscolhido.identifier,
          tipo_pagamento: self.formaEscolhida.id,
          promocode: this.promo1
            ? "1"
            : this.promo2
            ? "2"
            : this.promo3
            ? "3"
            : this.promo4
            ? "4"
            : null,
          endereco:
            self.formaEscolhida.id == "bank_slip" ? self.endereco : null,
          cartao: self.cartaoCadastrado ? self.cartaoCadastrado : null,
          cupom: self.cupomRetornado ? self.cupomRetornado : null,
          utmSourceCheckout: this.utmSource ? this.utmSource : null,
          utmMediumCheckout: this.utmMedium ? this.utmMedium : null,
          utmCampaingCheckout: this.utmCampaing ? this.utmCampaing : null,
          utmContentCheckout: this.utmContent ? this.utmContent : null,
          parcelado: self.parcelado,
          promoParc: this.promoParc1
            ? "1"
            : this.promoParc2
            ? "2"
            : this.promoParc3
            ? "3"
            : this.promoParc4
            ? "4"
            : this.promoParc5
            ? "5"
            : this.promoParc10
            ? "11"
            : null,
          captchaToken: captchaToken,
          key: secret,
          origin: this.currentUri,
          referrer: this.referrer,
        })
        .pipe(take(1))
        .toPromise();
    } else {
      //Cadastrar
      data = await self.apiCall
        .post("seller/me/assinatura", {
          name: self.usuario.nome,
          email: self.usuario.email,
          phone: self.usuario.phone.e164Number,
          password: self.usuario.senha,
          utmSource: this.utmSource ? this.utmSource : null,
          utmMedium: this.utmMedium ? this.utmMedium : null,
          utmCampaing: this.utmCampaing ? this.utmCampaing : null,
          utmContent: this.utmContent ? this.utmContent : null,
          utmSourceCheckout: this.utmSource ? this.utmSource : null,
          utmMediumCheckout: this.utmMedium ? this.utmMedium : null,
          utmCampaingCheckout: this.utmCampaing ? this.utmCampaing : null,
          utmContentCheckout: this.utmContent ? this.utmContent : null,
          plan_identifier: self.planoEscolhido.identifier,
          tipo_pagamento: self.formaEscolhida.id,
          endereco:
            self.formaEscolhida.id == "bank_slip" ? self.endereco : null,
          cartao: self.cartaoCadastrado ? self.cartaoCadastrado : null,
          cupom: self.cupomRetornado ? self.cupomRetornado : null,
          parcelado: self.parcelado,
          promoParc: this.promoParc1
            ? "1"
            : this.promoParc2
            ? "2"
            : this.promoParc3
            ? "3"
            : this.promoParc4
            ? "4"
            : this.promoParc5
            ? "5"
            : null,
          captchaToken: captchaToken,
          origin: this.currentUri,
          referrer: this.referrer,
        })
        .pipe(take(1))
        .toPromise();
      this.newUser = true;
    }
    if (data.success) {
      let valor = 0.0;
      const found = self.planos.find(
        (x) => x.identifier == self.planoEscolhido.identifier
      );
      if (found) {
        if (this.cupomRetornado && this.cupomfbprice != null) {
          valor = this.cupomfbprice;
        } else if (this.promo1) {
          valor = found.pfb1;
        } else if (this.promo2) {
          valor = found.pfb2;
        } else if (this.promo3) {
          valor = found.pfb3;
        } else {
          valor = found.pfb;
        }
      }

      if (valor > 0) {
        if (self.planoEscolhido.identifier.toLowerCase().includes("boleto")) {
          valor = valor - 1.98;
        } else {
          valor = valor - (valor * 2.51) / 100;
        }
      }
      if (valor < 0) {
        valor = 0.0;
      }
      fbq(
        "track",
        "Purchase",
        { value: valor.toFixed(2), currency: "BRL" },
        { eventID: this.eventID }
      );
      this.sendFbEvent("Purchase", valor.toFixed(2), this.user ? true : false);
      if (self.planoEscolhido.identifier == "plano_1") {
        fbq(
          "track",
          "PurchaseM",
          { value: valor.toFixed(2), currency: "BRL" },
          { eventID: this.eventID }
        );
        this.sendFbEvent(
          "Purchase",
          valor.toFixed(2),
          this.user ? true : false
        );
      } else if (
        self.planoEscolhido.identifier == "plano_2" ||
        self.planoEscolhido.identifier == "plano_2_boleto"
      ) {
        fbq(
          "track",
          "PurchaseT",
          { value: valor.toFixed(2), currency: "BRL" },
          { eventID: this.eventID }
        );
        this.sendFbEvent(
          "PurchaseT",
          valor.toFixed(2),
          this.user ? true : false
        );
      } else if (
        self.planoEscolhido.identifier == "plano_3" ||
        self.planoEscolhido.identifier == "plano_3_boleto"
      ) {
        fbq(
          "track",
          "PurchaseA",
          { value: valor.toFixed(2), currency: "BRL" },
          { eventID: this.eventID }
        );
        this.sendFbEvent(
          "PurchaseA",
          valor.toFixed(2),
          this.user ? true : false
        );
      }
      fbq("track", "ViewContent", {}, { eventID: this.eventID });
      this.sendFbEvent("ViewContent", null, this.user ? true : false);

      //Purchase Google
      gtag("event", "conversion", {
        send_to: "AW-861390287/hlPQCJe1t-UCEM-L35oD",
        value: valor.toFixed(2),
        currency: "BRL",
        transaction_id: "",
      });
    }
    setTimeout(async function () {
      if (data.success) {
        await self.app.alert(
          "Tudo certo!",
          "Plano assinado com sucesso!",
          "success"
        );
        if (!self.user)
          await self.me.login(data.return.email, data.return.password);

        if (self.newUser == true) {
          window.location.href =
            "https://app.opersonaldigital.com.br/page/personal/first-onboarding";
        } else {
          window.location.href = "https://app.opersonaldigital.com.br/";
        }
      } else {
        console.log("data", data)
        if (data.error_stack && JSON.parse(JSON.stringify(data.error_stack))) {
          let retorno = null;

          console.log("data error_stack", data.error_stack)

          try {
            retorno = JSON.parse(data.error_stack);
          } catch (e) {
            retorno = JSON.parse(JSON.stringify(data.error_stack));
          }

          console.log("Retorno", retorno);

          await self.spinner.hide();

          if (retorno.lrCode == "51") {
            Swal.fire({
              title: "Não foi possivel realizar a sua assinatura",
              text: "Parece que o cartão que você cadastrou está sem limite disponível para realizar a compra, você possui outro cartão com limite disponível?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#00b214",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim, Possuo!",
              cancelButtonText: "Não Possuo Outro Cartão",
            }).then(async (result) => {
              if (result.isConfirmed) {
                self.ngOnInit();
              } else if (result.isDismissed) {
                await self.app.alert(
                  "Que pena!",
                  "Não tem problema, você ainda pode assinar a plataforma de forma recorrente (com cobranças mensais), porém não receberá os bônus, para realizar uma assinatura recorrente, clique no botão abaixo",
                  "success"
                );
                window.location.href =
                  "https://app.opersonaldigital.com.br/pages/checkout/";
              }
            });
          } else {
            await self.app.alert("Ops :(", retorno.message, "error");
          }
        } else {
          await self.app.alert("Ops :(", data.message, "error");
        }
      }
    }, 3000);
  }
  disableAssinar() {
    if (
      !this.planoEscolhido ||
      !this.formaEscolhida ||
      ((!this.usuario.email ||
        // || !this.usuario.nome
        !this.usuario.senha ||
        !this.usuario.phone) &&
        !this.user) ||
      (this.formaEscolhida.id == "credit_card" &&
        (!this.novoCartao.number ||
          !this.novoCartao.cvv ||
          !this.novoCartao.date ||
          !this.novoCartao.name)) ||
      (this.formaEscolhida.id == "bank_slip" &&
        (!this.endereco.number ||
          !this.endereco.zip_code ||
          !this.endereco.state ||
          !this.endereco.street ||
          !this.endereco.district ||
          !this.endereco.city ||
          !this.endereco.cpf_cnpj))
    ) {
      return true;
    } else {
      console.log("this.usuario", this.usuario);
      console.log("this.user", this.user);
      return false;
    }
  }

  checkAssinar() {
    console.log(this.acceptTerms);
  }

  async cadastrarCartaoEAssinar() {
    const self = this;
    self.erroCartao = "";
    let name = "";
    let cvv = "";
    let date = "";
    let number = "";

    if (self.novoCartao.cvv) {
      cvv = self.novoCartao.cvv.replace(/\s/g, "");
    }
    name = self.novoCartao.name;

    if (self.novoCartao.number) {
      number = self.novoCartao.number.replace(/\s/g, "");
    }
    if (self.novoCartao.date) {
      date = self.novoCartao.date.replace(/\s/g, "");
    }

    if (!Iugu.utils.validateCreditCardNumber(number)) {
      self.erroCartao = "Número de cartão inválido";
      return;
    }
    if (!Iugu.utils.validateExpirationString(date)) {
      self.erroCartao = "Data de validade inválida";
      return;
    }
    if (
      !Iugu.utils.getBrandByCreditCardNumber(number) ||
      !Iugu.utils.validateCVV(
        cvv,
        Iugu.utils.getBrandByCreditCardNumber(number)
      )
    ) {
      self.erroCartao = "Data de validade inválida";
      return;
    }

    let cc = Iugu.CreditCard(
      number,
      date.substring(0, 2),
      date.substring(3, 7),
      name.split(" ")[0],
      name.substring(name.indexOf(" ") + 1),
      cvv
    );
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    Iugu.createPaymentToken(cc, async function (response) {
      if (response.errors) {
        await self.spinner.hide();
        self.erroCartao = "Erro ao validar seu cartão.";
        return;
      } else {
        self.cartaoCadastrado = response.id;
        await self.spinner.hide();
        await self.assinar();
      }
    });
  }

  async sendFbEvent(event, value, logged) {
    const self = this;

    this.ipAddress = await this.getIPAddress();

    this.getUserAgent();

    let body = {
      event: event,
      value: value ? value : null,
      fbpCookie: this.fbpCookie ? this.fbpCookie : null,
      ip: this.ipAddress ? this.ipAddress : null,
      userAgent: this.userAgent ? this.userAgent : null,
      eventID: this.eventID,
    };

    if (logged == true) {
      self.apiCall.post("send/conversionLogged", body).subscribe();
    } else {
      self.apiCall.post("send/conversion", body).subscribe();
    }
  }

  async getIPAddress() {
    const self = this;
    return new Promise<void>((resolve, reject) => {
      self.http
        .get("https://api.ipify.org/?format=json")
        .subscribe((res: any) => {
          resolve(res.ip);
        });
    });
  }

  getUserAgent() {
    this.userAgent = window.navigator.userAgent;
  }

  async generateCaptchaToken(secret) {
    try {
      const token = await this.recaptchaV3Service
        .execute("purchasePersonal" + secret)
        .pipe(take(1))
        .toPromise();
      return token;
    } catch (error) {
      return null;
    }
  }

  onMouseEnterBloon(bloon: HoveredBloonType) {
    if (!bloon) {
      return;
    } else if (this.hoveredBonusBloon == bloon) {
      return;
    }
    this.hoveredBonusBloon = bloon;
  }

  onMouseLeaveBloon() {
    this.hoveredBonusBloon = null;
  }
}
