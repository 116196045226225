<div class="card">
  <div class="card-header">
    <h4 class="card-title">{{ !idTreino && training ? nomeTreino : (idTreino && training ? 'Edição Treino' : 'Novo Treino')}}</h4>
  </div>
  <div class="card-content">
    <div class="card-body">
      <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
        <aw-wizard-step *ngIf="!(!idTreino && training)" [stepTitle]="'Treino'" [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }">
          <form #firstForm="ngForm" class="editForm" novalidate>
            <br/>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="tipotreino">{{ultimoTipoSelecionado ? ultimoTipoSelecionado : 'Tipo de Treino'}}</label>
                  <ng-select [notFoundText]="'Nenhum tipo encontrado'" [items]="tipos" [disabled]="verificaTreinoDeAvaliacaoPreenchido()"  bindLabel="name" name="model"  placeholder="Selecionar Tipo" id="tipotreino" [(ngModel)]="tipoSelecionado" (ngModelChange)="toggleTipoTreino()"></ng-select>
                </div>
              </div>
            </div>
            <div class="form-row" *ngIf="tipoSelecionado && (tipoSelecionado.id==2 || tipoSelecionado.id==3)">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="volt">Voltas <span style="color: red">*</span></label>
                  <div class="input-group">
                    <input type="text" id="volt" mask="0*" class="form-control" name="volt" [(ngModel)]="newTraining.laps" #volt="ngModel" gt="0" required>
                  </div>
                  <small class="form-text text-muted danger" *ngIf="volt.errors?.required || volt.errors?.gt">Você deve informar a quantidade de voltas</small>
                </div>
              </div>

            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="inputAvaliacao">Nome* <span style="color: red">*</span></label>
                  <input type="text" id="inputAvaliacao" class="form-control" name="inputAvaliacao" [disabled]="verificaTreinoDeAvaliacaoPreenchido()"  [(ngModel)]="newTraining.name" #inputAvaliacao="ngModel" required>
                  <small class="form-text text-muted danger" *ngIf="inputAvaliacao.errors?.required">Informe um nome para o treino</small>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="obst">Observações*</label>
                  <textarea id="obst" rows="3" class="form-control" name="obst" [disabled]="verificaTreinoDeAvaliacaoPreenchido()" [(ngModel)]="newTraining.note" #inputObs="ngModel" placeholder="Você pode escrever observações sobre este treino"></textarea>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-lg  mr-2" [ngClass]="idTreino ? 'btn-danger' : 'btn-secondary'" (click)="activeModal.close('Close click')">{{idTreino ? 'Cancelar' : 'Voltar'}}</button>
                <button type="button" class="btn btn-lg gradient-mint" [disabled]="!verificaPreenchimentoTreino()" awNextStep>{{idTreino ? 'Próximo Passo' : 'Avançar'}}</button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step  [stepTitle]="'Exercícios'" [navigationSymbol]="{ symbol: '&#xf059;', fontFamily: 'FontAwesome' }">
          <form #workForm="ngForm" class="editForm" novalidate>
            <h4 class="head text-center mt-3">{{!(!idTreino && training) ? ultimoTipoSelecionado + ' - '+ (isEditingExercise ? 'Atualização Exercício' : 'Cadastro Exercícios') : ultimoTipoSelecionado+ ' - '+ (isEditingExercise ? 'Atualização Exercício' : 'Cadastro Exercícios')}}</h4>
            <br/>
            <div class="form-row">
              <div [ngClass]="exercicioSelecionado ? 'col-md-4 col-12' : 'col-md-4 col-12'">
                <div class="form-group mb-2">
                  <label for="catselect">Selecione uma categoria</label>
                  <ng-select [items]="categoriesAndExercicies" [disabled]="verificaTreinoDeAvaliacaoPreenchido()" [notFoundText]="'Nenhuma categoria encontrada'"  bindLabel="name" name="catselect"  placeholder="Selecionar Categoria" id="catselect" [(ngModel)]="categoriaSelecionada" (ngModelChange)="onselectCategory()">
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{item.name}}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <span>{{item.name}}<small class="text-muted float-right">{{item.user ? 'personal' : 'plataforma'}}</small></span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div [ngClass]="exercicioSelecionado ? 'col-md-4 col-12' : 'col-md-4 col-12'">
                <div class="form-group mb-2">
                  <label for="exercselect">Selecione um exercício</label>
                  <ng-select
                    [items]="exercicies"
                    [disabled]="exercicies.length==0 || verificaTreinoDeAvaliacaoPreenchido()"
                    [notFoundText]="'Nenhum exercício encontrado'"
                    bindLabel="Exercise.name"
                    name="blable"
                    placeholder="Selecionar Exercício"
                    id="blable" [(ngModel)]="exercicioSelecionado" (ngModelChange)="onselectExercicio()" required>
                    <ng-template ng-label-tmp let-item="item">
                      <span>{{item.Exercise.name}}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                      <span>{{item.Exercise.name}}<small class="text-muted float-right">{{item.Exercise.user ? 'personal' : 'plataforma'}}</small></span>
                    </ng-template>
                  </ng-select>
                </div>
              </div>
              <div class="col-md-2 col-12" *ngIf="exercicioSelecionado">
                <div class="form-group mb-2">
                  <label for="exercselect">Mídia</label>
                  <ul class="list-unstyled mb-0">
                    <li class="d-inline-block mr-2" style="vertical-align: middle" *ngIf="exercicioSelecionado.Exercise && exercicioSelecionado.Exercise.url_video">
                      <div class="radio radio-sm">
                        <input type="radio" name="size-radio-1" [value]="true" id="size-sm" (click)="switchMidia(true)" [(ngModel)]="isVideo">
                        <label for="size-sm"><a class="text-primary" (click)="viewMidia(true, contentVideo, contentGif)">Vídeo</a></label>
                      </div>
                    </li>
                    <li class="d-inline-block" style="vertical-align: middle" *ngIf="exercicioSelecionado.Exercise && exercicioSelecionado.Exercise.url_gif">
                      <div class="radio radio-sm">
                        <input type="radio" name="size-radio-1" [value]="false" id="size-default" (click)="switchMidia(false)" [(ngModel)]="isVideo">
                        <label for="size-default"><a class="text-primary" (click)="viewMidia(false, contentVideo, contentGif)">GIF</a></label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            
                <div class="col-md-2 col-12" role="group">
                  <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openExercise(null, false)" >Adicionar Exercício</button>
                </div>

            </div>
            <ng-template [ngIf]="tipoSelecionado && tipoSelecionado.id==1">
              <div class="form-row">
                <div class="col-12">
                  <div class="form-group mb-2">
                    <label for="series">Séries <span style="color: red">*</span></label>
                    <div class="input-group">
                      <input type="text" id="series" mask="0*" class="form-control" name="series" [(ngModel)]="exercicie.series" #series="ngModel" gt="0" required>
                    </div>
                    <small class="form-text text-muted danger" *ngIf="series.errors?.required || series.errors?.gt">Você deve informar a quantidade de séries</small>
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-2 col-12">
                  <div class="form-group mb-2">
                    <label for="rep">Tipo</label>
                    <div class="input-group" >
                      <div class="d-md-none" role="group">
                        <ui-switch class="switchery switchery-warning" size="small" id="bla" [checked]="switchRepetitionAndDuration" (change)="switchRepAndDur($event)"></ui-switch>
                      </div>
                      <div class="d-none d-md-block">
                        <ui-switch class="switchery switchery-warning" size="small" id="bla" [checkedLabel]="'Duração'" [uncheckedLabel]="'Repetições'" [checked]="switchRepetitionAndDuration" (change)="switchRepAndDur($event)"></ui-switch>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-12">
                  <div class="form-group mb-2">
                    <label for="rep">{{switchRepetitionAndDuration ? 'Duração' : 'Repetições'}}<span style="color: red">*</span></label>
                    <ng-template [ngIf]="!switchRepetitionAndDuration">
                      <div class="input-group" >
                        <input type="text" id="rep" mask="0*" class="form-control" name="rep" [(ngModel)]="exercicie.repetitions" #repetitions="ngModel" required gt="0" aria-describedby="group-repetition">
                        <div class="input-group-append">
                          <span class="input-group-text" id="group-repetition">x</span>
                        </div>
                      </div>
                      <small class="form-text text-muted danger" *ngIf="repetitions.errors?.required || repetitions.errors?.gt">Você deve informar a quantidade de repetições</small>
                    </ng-template>
                    <ng-template [ngIf]="switchRepetitionAndDuration">
                      <div class="input-group">
                        <ngb-timepicker name="timerDuration" [spinners]="false" [seconds]="true" [(ngModel)]="exercicie.timeDuration" required #duration1="ngModel"></ngb-timepicker>
                        <small class="form-text text-muted danger" *ngIf="duration1.errors?.required || duration1.errors?.gt">Você deve informar a duração</small>
                      </div>
                    </ng-template>
                  </div>
                </div>
                <div class="col-md-5 col-12">
                  <div class="form-group mb-2">
                    <div class="d-md-none" role="group">
                      <label for="carga">Carga Total</label>
                    </div>
                    <div class="d-none d-md-block">
                      <label for="carga">Carga sugerida total</label>
                    </div>
                    <div class="input-group">
                      <input type="text" id="carga" mask="9*"  class="form-control" name="carga" [(ngModel)]="exercicie.load" #carga="ngModel" gt="0" aria-describedby="group-carga">
                      <div class="input-group-append">
                        <span class="input-group-text" id="group-carga">KG</span>
                      </div>
                    </div>
                    <small class="form-text text-muted danger" *ngIf="carga.errors?.required || carga.errors?.gt">Você deve informar a carga total</small>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="tipoSelecionado && (tipoSelecionado.id==2 || tipoSelecionado.id==3)">
              <div class="form-row">
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <label for="duration2">Duração <span style="color: red">*</span></label>
                    <ngb-timepicker  id="timerDuration2" name="timerDuration2" [spinners]="false" [seconds]="true" [(ngModel)]="exercicie.timeDuration" required #duration2="ngModel"></ngb-timepicker>
                    <small class="form-text text-muted danger" *ngIf="duration2.errors?.required || duration2.errors?.gt">Você deve informar a duração</small>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <div class="d-md-none" role="group">
                      <label for="carga">Carga Total</label>
                    </div>
                    <div class="d-none d-md-block">
                      <label for="carga">Carga sugerida total</label>
                    </div>
                    <div class="input-group">
                      <input type="text" id="carga" mask="9*" class="form-control" name="carga" [(ngModel)]="exercicie.load" #carga="ngModel" gt="0" aria-describedby="group-carga">
                      <div class="input-group-append">
                        <span class="input-group-text" id="group-carga">KG</span>
                      </div>
                    </div>
                    <small class="form-text text-muted danger" *ngIf="carga.errors?.required || carga.errors?.gt">Você deve informar a carga total</small>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="tipoSelecionado && tipoSelecionado.id!==4">
              <div class="form-row">
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <label for="carga">Velocidade</label>
                    <ng-select [items]="velocidade"  [notFoundText]="'Nenhuma velocidade encontrada'" name="velocidade"  placeholder="Selecionar Velocidade" id="exercselect" [(ngModel)]="exercicie.speed"></ng-select>
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group mb-2">
                    <label for="interval">Descanso<span *ngIf="tipoSelecionado.id!=2" style="color: red">*</span></label>
                    <div class="input-group">
                      <input type="text" id="interval"  mask="9*" class="form-control" name="interval" [(ngModel)]="exercicie.breakTime" #interval="ngModel" gt="0" aria-describedby="group-interval" [required]="tipoSelecionado.id==3 || tipoSelecionado.id==1">
                      <div class="input-group-append">
                        <span class="input-group-text" id="group-interval">segundos</span>
                      </div>
                    </div>
                    <small class="form-text text-muted danger" *ngIf="interval.errors?.required || interval.errors?.gt">Você deve informar o intervalo</small>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="tipoSelecionado && tipoSelecionado.id==4 && (!exercicie.loadFeedback && !exercicie.repetitionFeedback && !exercicie.timeFeedback)">
              <div class="form-row mb-2 mt-2">
                <div class="col-md-4 col-12">
                  <div class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0" placement="top" ngbTooltip="Informe se deseja solicitar o total de repetições.">
                    <input type="checkbox" class="custom-control-input" id="color-switch-1"  [(ngModel)]="exercicie.showRepetitionFeedback" name="showRepetitionFeedback">
                    <label class="custom-control-label mr-1" for="color-switch-1">
                      <span>Total de Repetições</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4 col-12" placement="top" ngbTooltip="Informe se deseja solicitar o tempo total de execução.">
                  <div class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0">
                    <input type="checkbox" class="custom-control-input" id="color-switch-2"    [(ngModel)]="exercicie.showTimeFeedback" name="showTimeFeedback">
                    <label class="custom-control-label mr-1" for="color-switch-2">
                      <span>Tempo Total de Execução</span>
                    </label>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="custom-switch custom-switch-primary custom-control-inline mb-1 mb-xl-0" placement="top" ngbTooltip="Informe se deseja solicitar a carga total utilizada.">
                    <input type="checkbox" class="custom-control-input" id="color-switch-3"   [(ngModel)]="exercicie.showLoadFeedback" name="showLoadFeedback">
                    <label class="custom-control-label mr-1" for="color-switch-3">
                      <span>Total de Carga</span>
                    </label>
                  </div>
                </div>
              </div>
            </ng-template>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="obse">Observações</label>
                  <textarea id="obse" rows="2" class="form-control" name="obse" [disabled]="verificaTreinoDeAvaliacaoPreenchido()" [(ngModel)]="exercicie.note" #inputObse="ngModel" placeholder="Você pode escrever observações sobre este exercício"></textarea>
                </div>
              </div>
            </div>
            <ng-template [ngIf]="tipoSelecionado && tipoSelecionado.id!==4">
              <div class="row">
                <div class="col-md-8 col-12">
                  <ng-template #tipTitleProg>
                    <p>
                      Aqui você pode configurar periodizações automáticas de qualquer variável deste exercício e a partir de qualquer execução deste treino.
                    </p>
                    <p>Caso não queira alterar alguma variável na periodização basta não preencher a mesma.</p>
                  </ng-template>
                  <p>Periodizações Automáticas <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipTitleProg"></i></p>
                </div>
                <div class="col-md-4 col-12" *ngIf="!isAddingProgression || !isEditingProgression">
                  <button type="button" class="btn btn-sm btn-outline-secondary btn-icon float-right" (click)="liberaCadastroProgressao()"><i class="fa fa-plus-circle"></i><span class="d-none d-md-inline-block ml-2">adicionar periodização</span></button>
                </div>
              </div>
              <div class="row mb-2" *ngIf="isAddingProgression|| isEditingProgression">
                <div class="col-sm" style="align-self: center;">
                  <button type="button" class="btn btn-block btn-outline-danger btn-icon" (click)="cancelaCadastroProgressao()" style="margin-top: 7px"><i class="fa fa-close"></i></button>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label for="pe">Execução <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipExecProg"></i></label>
                    <input type="text" id="pe" class="form-control" mask="9*" name="pe" [(ngModel)]="newProgression.execution" #ipe="ngModel" required>
                  </div>
                </div>
                <div class="col-sm" *ngIf="tipoSelecionado && tipoSelecionado.id==1">
                  <div class="form-group">
                    <label for="ps">Séries <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipSerProg"></i></label>
                    <input type="text" id="ps" class="form-control" mask="9*" name="ps" [(ngModel)]="newProgression.series" #ips="ngModel">
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label for="pr">{{switchRepetitionAndDuration ? 'duração' : 'repetições'}} <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipDurProg"></i></label>
                    <ng-template [ngIf]="switchRepetitionAndDuration">
                      <ngb-timepicker name="timerDuration" [spinners]="false" [seconds]="true" [(ngModel)]="newProgression.timeDuration" required #duration1="ngModel"></ngb-timepicker>
                    </ng-template>
                    <ng-template [ngIf]="!switchRepetitionAndDuration">
                      <input type="text" id="pra" mask="9*" class="form-control" name="pra" [(ngModel)]="newProgression.repetitions" #ipra="ngModel">
                    </ng-template>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label for="pc">Carga <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipCarProg"></i></label>
                    <input type="text" id="pc" class="form-control" mask="9*" name="pc" [(ngModel)]="newProgression.load" #ipc="ngModel">
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label for="pv">Velocidade <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipVelProg"></i></label>
                    <ng-select [items]="velocidade"  [notFoundText]="'Nenhuma velocidade encontrada'" name="pv"  id="pv" [(ngModel)]="newProgression.speed"></ng-select>
                  </div>
                </div>
                <div class="col-sm">
                  <div class="form-group">
                    <label for="pd">Descanso <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipDesProg"></i></label>
                    <input type="text" id="pd" class="form-control" mask="9*" name="pd" [(ngModel)]="newProgression.breakTime" #ipd="ngModel">
                  </div>
                </div>
                <div class="col-sm" style="align-self: center;">
                  <button type="button" class="btn btn-block gradient-mint btn-icon" style="margin-top: 7px" [disabled]="!verifyNewProgression()" (click)="isEditingProgression ? atualizaProgressao() : cadastraProgressao()"><i class="fa fa-save"></i></button>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="thead-dark">
                      <tr>
                        <ng-template #tipExecProg>Execução inicial que vai começar esta periodização. Informe obrigatoriamente uma execução maior que 1.</ng-template>
                        <ng-template #tipDurProg>{{switchRepetitionAndDuration ? 'Novo tempo de duração em segundos' : 'Nova quantidade de repetições'}}</ng-template>
                        <ng-template #tipCarProg>Novo valor de carga total.</ng-template>
                        <ng-template #tipVelProg>Novo valor de velocidade.</ng-template>
                        <ng-template #tipDesProg>Novo tempo de descanso.</ng-template>
                        <ng-template #tipSerProg>Nova quantidade de séries.</ng-template>
                        <ng-template #tipStatProg>Status indicando se a periodização já foi realizada ou não.</ng-template>
                        <th class="text-center p-2">execução inicial <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipExecProg"></i></th>
                        <th class="text-center p-2"  *ngIf="tipoSelecionado && tipoSelecionado.id==1">série <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipSerProg"></i></th>
                        <th class="text-center p-2">{{switchRepetitionAndDuration ? 'duração' : 'repetições'}} <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipDurProg"></i></th>
                        <th class="text-center p-2">carga total <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipCarProg"></i></th>
                        <th class="text-center p-2">velocidade <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipVelProg"></i></th>
                        <th class="text-center p-2">descanso <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipDesProg"></i></th>
                        <th class="text-center p-2">status <i class="fa fa-question-circle" style="cursor: pointer" [ngbTooltip]="tipStatProg"></i></th>
                        <th class="text-center p-2">#</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let progression of progressions">
                        <td class="text-center p-2">{{progression.execution ? progression.execution : '--'}}</td>
                        <td class="text-center p-2"  *ngIf="tipoSelecionado && tipoSelecionado.id==1">{{progression.series ? progression.series : '--'}}</td>
                        <td class="text-center p-2">{{switchRepetitionAndDuration ? (progression.duration ? progression.duration : '--') : (progression.repetitions ? progression.repetitions : '--')}}</td>
                        <td class="text-center p-2">{{progression.load ? progression.load : '--'}}</td>
                        <td class="text-center p-2">{{progression.speed ? progression.speed : '--'}}</td>
                        <td class="text-center p-2">{{progression.breakTime ? progression.breakTime : '--'}}</td>
                        <td class="text-center p-2">{{progression.status ? 'Realizada' : 'Pendente'}}</td>
                        <td class="text-center p-2">
                          <div class="btn-group btn-group-sm pl-0 pr-0" role="group">
                            <button type="button" [disabled]="progression.status" (click)="editProgression(progression)" class="btn btm-sm gradient-mint btn-icon round" ><i class="fa fa-edit"></i></button>
                            <button type="button" [disabled]="progression.status" (click)="deleteProgression(progression)" class="btn btm-sm btn-danger btn-icon round"  ><i class="fa fa-trash"></i></button>
                          </div>
                        </td>
                      </tr>
                      <tr *ngIf="progressions.length==0">
                        <td colspan="8"><p class="text-center">Nenhuma periodização cadastrada</p></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="showFeedback">
              <div class="form-row">
                <div class="col-md-4 col-12">
                  <div class="form-group mb-2">
                    <label for="loadFeedback">Feedback Carga Total</label>
                    <div class="input-group">
                      <input type="text" id="loadFeedback"  class="form-control" name="loadFeedback" [disabled]="true" [value]="exercicie.loadFeedback!=null ? exercicie.loadFeedback : 'N/A'"   aria-describedby="group-loadFeedback" >
                      <div class="input-group-append" *ngIf="exercicie.loadFeedback">
                        <span class="input-group-text" id="group-loadFeedback">KG</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group mb-2">
                    <label for="repetitionFeedback">Feedback Repetições</label>
                    <div class="input-group">
                      <input type="text" id="repetitionFeedback"  class="form-control" name="repetitionFeedback" [disabled]="true" [value]="exercicie.repetitionFeedback!=null ? exercicie.repetitionFeedback : 'N/A'"   aria-describedby="group-repetitionFeedback" >
                      <div class="input-group-append" *ngIf="exercicie.repetitionFeedback">
                        <span class="input-group-text" id="group-repetitionFeedback">X</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 col-12">
                  <div class="form-group mb-2">
                    <label for="timeFeedback">Feedback Execução</label>
                    <div class="input-group">
                      <input type="text" id="timeFeedback"  class="form-control" name="timeFeedback" [disabled]="true" [value]="exercicie.timeFeedback!=null ? exercicie.timeFeedback : 'N/A'"   aria-describedby="group-timeFeedback" >
                      <div class="input-group-append" *ngIf="exercicie.timeFeedback">
                        <span class="input-group-text" id="group-timeFeedback">Seg</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="isEditingExercise">
              <div class="form-row mb-2">
                <div class="col-md-6 col-12">
                  <button type="button" class="btn btn-lg btn-light float-left"  (click)="cancelarAtualizacaoExercicio()" >Cancelar</button>
                </div>
                <div class="col-md-6 col-12">
                  <button type="button" *ngIf="verificaExercicio(null)" class="btn btn-lg btn-warning float-right" [disabled]="!verificaPreenchimentoExercicio()" (click)="atualizarExercicio()" >Editar</button>
                </div>
              </div>
            </ng-template>
            <ng-template [ngIf]="!isEditingExercise && !verificaTreinoDeAvaliacaoPreenchido()">
              <div class="form-row mb-2">
                <div class="col-12">
                  <button type="button" class="btn btn-lg btn-warning float-right" [disabled]="!verificaPreenchimentoExercicio()" (click)="adicionarExercicio()" >Adicionar</button>
                </div>
              </div>
            </ng-template>
            <div class="form-row">
              <ul class="list-group mb-2" [dragula]='!verificaTreinoDeAvaliacaoPreenchido() ? "bag-one" : null' [(dragulaModel)]="listaExercicios" style="width: 100%">
                <li  *ngFor="let exercicio of listaExercicios" class="list-group-item draggable">
                  <div class="media">
                    <span class="dot align-middle mr-2">{{listaExercicios.indexOf(exercicio)+1}}</span>
                    <div class="media-body">
                      <h5 class="mt-0">{{exercicio.name}}</h5>
                      <span>{{getLabelCategory(exercicio.category)}}</span>
                    </div>
                    <button  type="button" class="btn btn-outline-secondary btn-icon round mr-1 mb-1" (click)="duplicarExercicio(exercicio)"><i class="fa fa-copy"></i></button>
                    <button  type="button" class="btn btn-outline-primary btn-icon round mr-1 mb-1" (click)="editarExercicio(exercicio)"><i class="fa fa-edit"></i></button>
                    <button  type="button" [disabled]="listaExercicios.length<=1" class="btn btn-outline-danger btn-icon round mr-1 mb-1" (click)="removerExercicio(exercicio)" *ngIf="verificaExercicio(exercicio)"><i class="fa fa-trash"></i></button>
                  </div>
                </li>
              </ul>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-lg btn-secondary mr-2" (click)="!idTreino && training ? activeModal.close('Close click') : null" awPreviousStep>Voltar</button>
                <button type="button" class="btn btn-lg gradient-mint" [disabled]="listaExercicios.length==0 || !verificaPreenchimentoTreino() || verificaTreinoDeAvaliacaoPreenchido()" (click)="cadastrarTreino()">{{idTreino ? 'Salvar' : 'Finalizar'}}</button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
</div>
<ng-template #contentVideo>
  <ng-template [ngIf]="urlToPlay.startsWith('https://www.youtube.com/') || urlToPlay.startsWith('http://www.youtube.com/')">
    <div class='embed-container'><iframe  [src]="safeURL" frameborder='0' allowfullscreen></iframe></div>
  </ng-template>
  <ng-template [ngIf]="!urlToPlay.startsWith('https://www.youtube.com/') && !urlToPlay.startsWith('http://www.youtube.com/')">
    <video controls style="width: 100%;">
      <source [src]="urlToPlay" type="video/mp4">
    </video>
  </ng-template>
</ng-template>
<ng-template #contentGif>
  <IMG style="width: 100%;" [src]="urlToPlay">
</ng-template>
<ngx-spinner></ngx-spinner>
