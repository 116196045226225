import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import Api from 'app/helpers/api';
import App from 'app/helpers/app';
import { ExcelService } from 'app/helpers/excel.service';
import Me from 'app/helpers/me';
import { AuthService } from 'app/shared/auth/auth.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-admin-meu-personal-digital',
  templateUrl: './admin-meu-personal-digital.component.html',
  styleUrls: ['./admin-meu-personal-digital.component.scss']
})
export class AdminMeuPersonalDigitalComponent implements OnInit {

  pageTitle = "Sem Responder Avaliação"
  listaSemAvaliacao = []
  listaSemTreino = []
  listaExecucaoTreino = []
  listaFimTeste = []

  listaSemAvaliacaoFiltered = []
  listaSemTreinoFiltered = []
  listaExecucaoTreinoFiltered = []
  listaFimTesteFiltered = []

  filterName = null
  filterEmail = null
  filterPersonal = null

  page = 1
  pageSize = 10

  personais = [
    {
      id: null,
      name: 'Todos',
    },
    {
      id: '128923',
      name: 'Marco Paixão',
    }, {
      id: '130698',
      name: 'Alex Paz',
    },
    {
      id: '130701',
      name: 'Isaac Holanda',
    },
    {
      id: '130703',
      name: 'Marcus Dias',
    },
    {
      id: '132257',
      name: 'Monalyse Dantas',
    },
    {
      id: '132290',
      name: 'Eli Medeiros',
    },
    {
      id: '132397',
      name: 'Mayara Vitória',
    }
  ]

  apiCall = this.api.new().silent();
  constructor( private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private socialAuthService: SocialAuthService,
    private app: App,
    private excelService: ExcelService,
    private api: Api) { }

 async ngOnInit(){
  const self = this

  self.spinner.show(undefined,
    {
      type: 'ball-triangle-path',
      size: 'medium',
      bdColor: 'rgba(0, 0, 0, 0.8)',
      color: '#fff',
      fullScreen: true
    });


  let listasUsuarios = await self.apiCall.get('admin/leadsmvp').pipe(take(1)).toPromise()

  if(listasUsuarios && listasUsuarios.return){
    this.listaSemAvaliacao = listasUsuarios.return.listaSemAvaliacao
    this.listaSemTreino = listasUsuarios.return.listaSemTreino
    this.listaExecucaoTreino = listasUsuarios.return.listaExecucaoTreino
    this.listaFimTeste = listasUsuarios.return.listaFimTeste

    this.listaSemAvaliacaoFiltered = listasUsuarios.return.listaSemAvaliacao
    this.listaSemTreinoFiltered = listasUsuarios.return.listaSemTreino
    this.listaExecucaoTreinoFiltered = listasUsuarios.return.listaExecucaoTreino
    this.listaFimTesteFiltered = listasUsuarios.return.listaFimTeste

  
  }
  console.log(listasUsuarios.return)

  self.spinner.hide()

 }


 updateTitle(lista){
  console.log(lista)
    switch (lista) {
      case 1:
        this.pageTitle = "Sem Responder Avaliação"
        break
      case 2:
        this.pageTitle = "Sem Execução de Treino"
        break
      case 3:
        this.pageTitle = "Primeiro Treino Executado"
        break
        case 4:
        this.pageTitle = "Fim Período de Testes"
        break  
    }
  }


  exportarListaUsuarios(listagem) {

    let dadosexport = []

    switch (listagem) {
      case 1:
        for(let l of this.listaSemAvaliacao){
          dadosexport.push(l.Customer)
        }
        break
      case 2:
        for(let l of this.listaSemTreino){
          dadosexport.push(l.Customer)
        }
        break
      case 3:
        for(let l of this.listaExecucaoTreino){
          dadosexport.push(l.Customer)
        }
     
        break
      case 4:
        for(let l of this.listaFimTeste){
          dadosexport.push(l.Customer)
        }
    }


    this.excelService.exportAsExcelFile(JSON.parse(JSON.stringify(dadosexport)), 'listausuariosMPD')
  }


  filter() {
    this.listaSemAvaliacaoFiltered = this.listaSemAvaliacao
    this.listaSemTreinoFiltered = this.listaSemTreino
    this.listaExecucaoTreinoFiltered = this.listaExecucaoTreino
    this.listaFimTesteFiltered = this.listaFimTeste

    if (this.filterName) {
      this.listaSemAvaliacaoFiltered = this.listaSemAvaliacaoFiltered.filter(x => x.Customer.name && x.Customer.name.toLowerCase().includes(this.filterName.toLowerCase()))
      this.listaSemTreinoFiltered = this.listaSemTreinoFiltered.filter(x => x.Customer.name && x.Customer.name.toLowerCase().includes(this.filterName.toLowerCase()))
      this.listaExecucaoTreinoFiltered = this.listaExecucaoTreinoFiltered.filter(x => x.Customer.name && x.Customer.name.toLowerCase().includes(this.filterName.toLowerCase()))
      this.listaFimTesteFiltered = this.listaFimTesteFiltered.filter(x => x.Customer.name && x.Customer.toLowerCase().includes(this.filterName.toLowerCase()))
    }
    if (this.filterEmail) {
      this.listaSemAvaliacaoFiltered = this.listaSemAvaliacaoFiltered.filter(x => x.Customer.email.toLowerCase().includes(this.filterEmail.toLowerCase()))
      this.listaSemTreinoFiltered = this.listaSemTreinoFiltered.filter(x => x.Customer.email.toLowerCase().includes(this.filterEmail.toLowerCase()))
      this.listaExecucaoTreinoFiltered = this.listaExecucaoTreinoFiltered.filter(x => x.Customer.email.toLowerCase().includes(this.filterEmail.toLowerCase()))
      this.listaFimTesteFiltered = this.listaFimTesteFiltered.filter(x => x.Customer.email.toLowerCase().includes(this.filterEmail.toLowerCase()))

    }
    if (this.filterPersonal && this.filterPersonal.id) {
      this.listaSemAvaliacaoFiltered = this.listaSemAvaliacaoFiltered.filter(x => x.seller == this.filterPersonal.id)
      this.listaSemTreinoFiltered = this.listaSemTreinoFiltered.filter(x => x.seller == this.filterPersonal.id)
      this.listaExecucaoTreinoFiltered = this.listaExecucaoTreinoFiltered.filter(x => x.seller == this.filterPersonal.id)
      this.listaFimTesteFiltered = this.listaFimTesteFiltered.filter(x => x.seller == this.filterPersonal.id)

    }



  }


  openWhatsapp(phoneCustomer) {
    let phoneFormated = phoneCustomer.replace('+', '');
    if (phoneFormated.length == 11) {
      phoneFormated = phoneFormated.padStart(13, '55');
    }
    window.open(`https://api.whatsapp.com/send?phone=${phoneFormated}`, "_blank");
  }


  formatDateWithoutTime(date) {
    return moment(date).format('DD/MM/YYYY')
  }


  sendNotification(user, store) {

    const self = this

   self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

      let body = {
        name: user.name,
        email: user.email,
        phone: user.phone,
        store: store
      }

      console.log(body)


    self.apiCall.post('mpd/sendnotification', body).subscribe(async data => {
      await self.spinner.hide()
      if (data.success) {
        await self.app.alert('Tudo Certo!', 'Notificação Enviada Com Sucesso', 'success')
      } else {
        self.app.alert('Ops :(', data.message, 'error')
      }
    });

  }


}
