<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem;">Cupons de Desconto</h1>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col" >
    <div class=" float-right d-none d-md-block" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openCupom(null, cupomDetail)" >Cadastrar</button>
    </div>
    <div class="btn-group btn-group-lg d-md-none" role="group">
      <button type="button" class="btn btn-lg btn-warning  mb-md-0 " (click)="openCupom(null, cupomDetail)" ><i class="fa fa-plus"></i></button>
    </div>
  </div>
</div>

<div class="row mb-2 mt-2" *ngIf="cupons.length > 0">
  <div class="col">
    <div class="card-content">

      <div class="card-body p-0">
        <div class="table text-center m-0">
          <table class="table-striped" style="width: 100%;">
            <thead>
            <tr>
              <th>Cupom</th>
              <th>Descrição</th>
              <th>Preço Mensalidade 1</th>
              <th>% Mensalidade 1</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let c of cupons| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
              <td>{{c.coupon}}</td>
              <td><textarea class="form-control" name="rrr" [disabled]="true" style="background-color: #e9ecef36" [(ngModel)]="c.description"></textarea></td>
              <td>{{c.priceFirstMonth | currency:'BRL'}}</td>
              <td>{{c.percentageFirstMonth}}</td>
              <td>{{c.status  ? 'Ativo' : 'Inativo'}}</td>
              <td>
                <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                  <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                    <i class="ft-more-vertical"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a  class="dropdown-item" (click)="openCupom(c, cupomDetail)" >Editar</a>
                    <a  class="dropdown-item" (click)="switchCupom(c)">{{c.status  ? 'Inativar' : 'Ativar'}}</a>
                    <a  class="dropdown-item" (click)="onDeleteCupom(c)">Deletar</a>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="form-row" style="margin: 5px">
        <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
          <ngb-pagination style="margin-top: 10px" *ngIf="cupons.length>0"
                          [(page)]="page"
                          [pageSize]="pageSize"  [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm"
                          [collectionSize]="cupons.length"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="cupons.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li  class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhum cupom cadastrado.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>



<ng-template #cupomDetail let-modal>
  <ngx-spinner></ngx-spinner>

  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Cupom</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="coupon">Cupom</label>
                <input type="text" id="coupon" class="form-control" name="coupon" (keydown.space)="$event.preventDefault();" [(ngModel)]="cupom.coupon" #inputName="ngModel" required>
              </div>
              <div class="form-group mb-2">
                <label for="obse">Descrição</label>
                <textarea id="obse" rows="2" class="form-control" name="obse"  #muralDescription maxlength="255"  [(ngModel)]="cupom.description" #inputObse="ngModel" placeholder="Você pode informar uma descrição para este cupom."></textarea>
              </div>
              <div class="form-row__description">
                {{ 255 - muralDescription.value.length }} caracteres restantes.
              </div>
              <div class="form-group mb-2">
                <label for="priceFirstMonth">Preço Mensalidade 1</label>
                <input type="text" id="priceFirstMonth" class="form-control" name="priceFirstMonth" [(ngModel)]="cupom.priceFirstMonth" #priceFirstMonthInput="ngModel" prefix="R$" mask="separator.2" thousandSeparator="." decimalMarker=",">
              </div>
              <p class="text-center">OU</p>
              <div class="form-group mb-2">
                <label for="percentageFirstMonth">Porcentagem Mensalidade 1</label>
                <input type="text" id="percentageFirstMonth" class="form-control" name="percentageFirstMonth" [(ngModel)]="cupom.percentageFirstMonth" #percentageFirstMonthInput="ngModel" suffix="%" mask="separator.2" thousandSeparator="." decimalMarker=",">
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg btn-block" [ngClass]="cupom && cupom.id ? 'btn-danger' : 'btn-secondary'" (click)="modal.close('Close click')">{{cupom && cupom.id ? 'Fechar' : 'Cancelar'}}</button>
                </div>
                <div class="col-6 text-center">
                  <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="inserirOuAtualizarCupom(modal)" [disabled]="!cupom || !cupom.coupon || !cupom.description ||(!cupom.priceFirstMonth && !cupom.percentageFirstMonth)">{{cupom && cupom.id ? 'Atualizar' : 'Cadastrar'}}</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
