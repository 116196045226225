import { Injectable } from "@angular/core";
import {
  FormExerciseUnifiedType,
  TrainingExerciseType,
} from "../../treinos-edit.types";
import {
  GroupedExerciseType,
  ProcessExercisesArrayServiceInterface,
} from "./process-exercises-array.types";

@Injectable({
  providedIn: "root",
})
export class ProcessExercisesArrayService
  implements ProcessExercisesArrayServiceInterface
{
  constructor() {}

  private findChilds(
    item: TrainingExerciseType,
    array: TrainingExerciseType[]
  ): TrainingExerciseType[] {
    let childs = array.filter((i) => i.conjugado === item.id);
    childs.forEach((foreign) => {
      const subForeigns = this.findChilds(foreign, array);
      if (subForeigns) {
        childs = childs.concat(subForeigns);
      }
    });
    return childs.length > 0 ? childs : null;
  }

  joinChildsToParent(
    exercises: TrainingExerciseType[]
  ): TrainingExerciseType[] {
    return exercises
      .filter((item) => item.conjugado === null)
      .map((item) => {
        const childs = this.findChilds(item, exercises);
        return childs ? { ...item, childs } : item;
      });
  }

  splitChildsFromParent(
    exercises: FormExerciseUnifiedType[]
  ): GroupedExerciseType[] {
    const childs: GroupedExerciseType[] = [];

    let tempId = 0;

    exercises.forEach((item: FormExerciseUnifiedType) => {
      console.log("Processando item:", item);

      if ("childs" in item) {
        item.childs.forEach((child, index) => {
          const conjugado =
            index === item.childs.length - 1 ? null : tempId + 1;
          childs.push({
            ...child,
            conjugado,
            tempId,
          });
          tempId++;
        });
      } else {
        childs.push({ ...item, tempId });
        tempId++;
      }
    });

    return childs;
  }
}
