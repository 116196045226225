import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private invoice: any = null;

  constructor() {}

  // Método para definir o invoice
  setInvoice(invoice: any): void {
    this.invoice = invoice;
  }

  // Método para obter o invoice
  getInvoice(): any {
    return this.invoice;
  }

  // Opcional: Método para limpar o invoice
  clearInvoice(): void {
    this.invoice = null;
  }
}