<div class="boleto-container">
  <div class="boleto-header">
    <div class="boleto-logo">
      <img src="assets/logo.png" alt="Banco Logo">
    </div>
    <div class="boleto-info">
      <div class="linha-digitavel">{{ bankSlip.digitable_line }}</div>
      <div class="dados-boleto">
        <span> <strong>Vencimento:</strong>  {{ formatDate(invoice.due_date) }}</span>
        <span> <strong>Valor:</strong> R$ {{ invoice.items[0].price }}</span>
      </div>
    </div>
  </div>
  <div class="boleto-body">
    <div class="boleto-section">
      <div class="boleto-cedente">
        <span><strong>Cedente:</strong></span>
        <span>{{ invoice.sellerName }}</span>
      </div>
      <div class="boleto-cedente">
        <span><strong>CPF/CNPJ:</strong></span>
        <span>{{ maskedSellerDoc }}</span>
      </div>
      <div class="boleto-cedente-endereco">
        <span><strong>Endereço:</strong></span>
        <span>{{ invoice.sellerAddress }}</span>
      </div>
    </div>
    <div class="boleto-section">
      <div class="boleto-sacado">
        <span><strong>Sacado:</strong></span>
        <span>{{ invoice.payer_name }}</span>
      </div>
      <div class="boleto-sacado">
        <span><strong>CPF/CNPJ:</strong></span>
        <span>{{ maskedCnpjCpf }}</span>
      </div>
      <div class="boleto-sacado">
        <span><strong>Endereço:</strong></span>
        <span>{{ invoice.payer_address_street }}, {{ invoice.payer_address_number }}, {{ invoice.payer_address_city }} - {{ invoice.payer_address_state }}</span>
      </div>
    </div>
    <div class="boleto-section">
      <div class="boleto-descricao">
        <span><strong>Descrição:</strong></span>
        <span>{{ invoice.items[0].description }}</span>
      </div>
    </div>
    <div class="boleto-section">
      <div class="boleto-total-nosso-numero">
        <div class="boleto-nosso-numero">
          <span><strong>Nosso Número:</strong></span>
          <span>{{ invoice.transaction_number }}</span>
        </div>
        <div class="boleto-total">
          <span><strong> Total: </strong></span>
          <span>R$ {{ invoice.total }}</span>
        </div>
      </div>
    </div>
    <div class="boleto-codigo-barras">
      <img [src]="bankSlip.barcode" alt="Código de Barras">
      <button (click)="copyBarcodeData()" class="btn-warning">Copiar Código de Barras</button>
    </div>
  </div>
</div>




