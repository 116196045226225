<div class="row text-left">
  <div class="col-sm-12">
    <div class="d-flex justify-content-between mb-0">
      <h1 class="text-bold-300" style="padding: 1.5rem">Ofertas</h1>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <div class="float-right d-none d-md-block" role="group">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="openOffer(null, offerDetail)"
      >
        Cadastrar
      </button>
    </div>
    <div class="btn-group btn-group-lg d-md-none" role="group">
      <button
        type="button"
        class="btn btn-lg btn-warning mb-md-0"
        (click)="openOffer(null, offerDetail)"
      >
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</div>

<div class="row mb-2 mt-2" *ngIf="offers.length > 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0">
        <div class="table text-center m-0">
          <table class="table-striped" style="width: 100%">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nome</th>
                <th>Data de Expiração</th>
                <th>Código</th>
                <th>Métodos de Pagamento</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="
                  let offer of offers
                    | slice
                      : (page - 1) * pageSize
                      : (page - 1) * pageSize + pageSize
                "
              >
                <td>{{ offer.id }}</td>
                <!-- <td><textarea class="form-control" name="name" [disabled]="true" style="background-color: #e9ecef36" [(ngModel)]="offer.name"></textarea></td> -->
                <td>{{ offer.name }}</td>
                <td>{{ offer.expirationDate | date : "dd/MM/yyyy" }}</td>
                <td>{{ offer.code }}</td>
                <td>
                  <!-- <textarea class="form-control" name="paymentMethods" [disabled]="true" style="background-color: #e9ecef36"> -->
                  {{ getPaymentMethodsLabels(offer.paymentMethods) }}
                  <!-- </textarea> -->
                </td>
                <td>
                  <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                    <button
                      type="button"
                      class="btn btn-outline-warning btn-sm dropdown-toggle"
                      ngbDropdownToggle
                    >
                      <i class="ft-more-vertical"></i>
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu" role="menu">
                      <a
                        class="dropdown-item"
                        (click)="openOffer(offer, offerDetail)"
                        >Editar</a
                      >
                      <a class="dropdown-item" (click)="onDeleteOffer(offer)"
                        >Deletar</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="
                          openProductOfferModal(offer, productOfferModal)
                        "
                        >Vincular Oferta</a
                      >
                      <a
                        class="dropdown-item"
                        (click)="
                          visualizarOfertaProduto(
                            offer.id,
                            viewOfferProductsModal
                          )
                        "
                        >Visualizar Oferta e Produtos</a
                      >
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="form-row" style="margin: 5px">
        <div
          class="d-flex justify-content-center"
          style="margin-left: auto; margin-right: auto"
        >
          <ngb-pagination
            style="margin-top: 10px"
            *ngIf="offers.length > 0"
            [(page)]="page"
            [pageSize]="pageSize"
            [maxSize]="2"
            [rotate]="true"
            [boundaryLinks]="true"
            size="sm"
            [collectionSize]="offers.length"
          ></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="offers.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body p-0 mt-2">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">Nenhuma oferta cadastrada.</h5>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<ngx-spinner></ngx-spinner>

<ng-template #offerDetail let-modal>
  <ngx-spinner></ngx-spinner>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Oferta</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <div class="form-group mb-2">
                <label for="name">Nome</label>
                <input
                  type="text"
                  id="name"
                  class="form-control"
                  name="name"
                  [(ngModel)]="offer.name"
                  #inputName="ngModel"
                  required
                />
              </div>
              <div class="form-group mb-2">
                <label for="expirationDate">Data de Expiração</label>
                <input
                  type="date"
                  id="expirationDate"
                  class="form-control"
                  name="expirationDate"
                  [(ngModel)]="offer.expirationDate"
                  #inputExpirationDate="ngModel"
                  required
                />
              </div>
              <div class="form-group mb-2">
                <label for="code">Código</label>
                <input
                  type="number"
                  id="code"
                  class="form-control"
                  name="code"
                  [(ngModel)]="offer.code"
                  #inputCode="ngModel"
                  required
                />
              </div>
              <div class="form-group mb-2">
                <label for="paymentMethods">Métodos de Pagamento</label>
                <div *ngFor="let option of paymentMethodsOptions">
                  <input
                    type="checkbox"
                    [id]="option.id"
                    [value]="option.id"
                    (change)="onPaymentMethodChange($event, option)"
                    [checked]="isChecked(option)"
                  />
                  <label [for]="option.id">{{ option.label }}</label>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block"
                    [ngClass]="
                      offer && offer.id ? 'btn-danger' : 'btn-secondary'
                    "
                    (click)="modal.close('Close click')"
                  >
                    {{ offer && offer.id ? "Fechar" : "Cancelar" }}
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="inserirOuAtualizarOffer(modal)"
                    [disabled]="
                      !offer ||
                      !offer.name ||
                      !offer.expirationDate ||
                      !offer.code ||
                      !offer.paymentMethods.length
                    "
                  >
                    {{ offer && offer.id ? "Atualizar" : "Cadastrar" }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #productOfferModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Vincular Oferta ao Produto
    </h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form
      novalidate
      [formGroup]="productOfferLinkForm"
      (submit)="onSubmitProductOfferLink(modal)"
    >
      <div class="form-group">
        <label for="product">Produto</label>
        <div class="input-group">
          <select
            id="product"
            formControlName="productId"
            class="form-control"
            [ngClass]="{
              'is-invalid': validateField('productId', productOfferLinkForm)
                .invalid,
              'is-valid': validateField('productId', productOfferLinkForm).valid
            }"
          >
            <option *ngFor="let product of products" [value]="product.id">
              {{ product.name }}
            </option>
          </select>
        </div>
        <ng-container
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'O produto é obrigatório',
            control: productOfferLinkForm.controls['productId']
          }"
        ></ng-container>
      </div>
      <div class="form-group">
        <label for="image">Imagem</label>
        <div
          (click)="
            !productOfferLinkForm.get('offerImage')?.value &&
              onOfferImageChange()
          "
          class="w-100 px-2 rounded-lg border border-grey bg-grey bg-lighten-4 border-lighten-2 d-flex justify-content-start align-items-center"
          [ngClass]="
            !productOfferLinkForm.get('offerImage')?.value
              ? 'cursor-pointer'
              : ''
          "
        >
          <!-- placeholder -->
          <div
            *ngIf="
              !isUploadingImage &&
              !productOfferLinkForm.get('offerImage')?.value
            "
            class="py-4"
          >
            <input
              type="file"
              accept="image/*"
              hidden
              autocomplete="off"
              tabindex="-1"
              class="d-none"
            />
            <span> Clique para selecionar uma imagem </span>
          </div>
          <!-- uploading -->
          <div
            *ngIf="
              isUploadingImage && !productOfferLinkForm.get('offerImage')?.value
            "
            class="ml-2 py-4"
          >
            <div class="d-flex justify-content-center align-items-center">
              <div class="load-spinner">
                <i class="ft-loader text-primary"></i>
              </div>
              <span class="ml-2 font-size-4 font-size-md-5">Carregando...</span>
            </div>
          </div>
          <!-- preview -->
          <div
            *ngIf="
              !isUploadingImage && productOfferLinkForm.get('offerImage')?.value
            "
            class="row py-2"
          >
            <div class="col-12">
              <div class="img-container d-flex overflow-hidden">
                <img
                  [src]="productOfferLinkForm.get('offerImage')?.value"
                  alt="Imagem do Produto"
                  height="100"
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-12 mt-2">
              <button
                type="button"
                (click)="removeOfferImage(); $event.stopPropagation()"
                class="btn btn-sm btn-danger"
              >
                <i class="fa fa-trash"></i>
                Remover Imagem
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="freeMonths">Meses Gratuitos</label>
        <div class="input-group">
          <input
            id="freeMonths"
            type="number"
            formControlName="freeMonths"
            class="form-control"
            [ngClass]="{
              'is-invalid': validateField('freeMonths', productOfferLinkForm)
                .invalid,
              'is-valid': validateField('freeMonths', productOfferLinkForm)
                .valid
            }"
          />
        </div>
        <ng-container
          [ngTemplateOutlet]="formError"
          [ngTemplateOutletContext]="{
            validation: 'required',
            message: 'Os meses gratuitos são obrigatórios',
            control: productOfferLinkForm.controls['freeMonths']
          }"
        ></ng-container>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-start align-items-center">
          <label for="features" class="m-0">Benefícios</label>
          <button
            type="button"
            (click)="addProductOfferLinkFeature()"
            class="ml-2 btn btn-sm btn-primary white"
          >
            <i class="fa fa-plus"></i>
          </button>
        </div>
        <div class="mt-1" formArrayName="features">
          <div
            *ngFor="
              let feature of productOfferLinkForm.get('features').controls;
              let i = index
            "
            class="d-flex justify-content-between align-items-center"
            [ngClass]="{ 'mt-2': i > 0 }"
          >
            <span class="feature-index d-block mr-1 text-center">{{
              i + 1
            }}</span>
            <input
              type="text"
              [formControlName]="i"
              placeholder="Benefício"
              class="form-control"
              [ngClass]="{
                'is-invalid': validateField('features', productOfferLinkForm, i)
                  .invalid,
                'is-valid': validateField('features', productOfferLinkForm, i)
                  .valid
              }"
            />
            <button
              type="button"
              (click)="i !== 0 && removeProductOfferLinkFeature(i)"
              [disabled]="i === 0"
              class="btn btn-sm btn-danger ml-2"
            >
              <i class="fa fa-trash"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismiss('cancel click')"
    >
      Cancelar
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onSubmitProductOfferLink(modal)"
    >
      Vincular
    </button>
  </div>
</ng-template>

<!-- Modal for viewing offer and linked products -->
<ng-template #viewOfferProductsModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Detalhes da Oferta e Produtos Vinculados</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="card">
      <div class="card-header">
        <h4 class="card-title">Detalhes da Oferta</h4>
      </div>
      <div class="card-content">
        <div class="card-body">
          <form>
            <div class="form-group">
              <label for="name">Nome</label>
              <input
                type="text"
                id="name"
                class="form-control"
                [(ngModel)]="offer.name"
                name="name"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="expirationDate">Data de Expiração</label>
              <input
                type="text"
                id="expirationDate"
                class="form-control"
                [value]="offer.expirationDate | date : 'dd/MM/yyyy'"
                name="expirationDate"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="code">Código</label>
              <input
                type="text"
                id="code"
                class="form-control"
                [(ngModel)]="offer.code"
                name="code"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="paymentMethods">Métodos de Pagamento</label>
              <textarea
                id="paymentMethods"
                class="form-control"
                [value]="getPaymentMethodsLabels(offer.paymentMethods)"
                name="paymentMethods"
                disabled
              ></textarea>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="productOffers.length > 0; else noProductsLinked">
      <div class="card mt-3">
        <div class="card-header">
          <h4 class="card-title">Produtos Vinculados</h4>
        </div>
        <div class="card-content">
          <div class="card-body p-0">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Nome do Produto</th>
                    <th>Imagem</th>
                    <th>Meses Gratuitos</th>
                    <th>Benefícios</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let productOffer of productOffers">
                    <td>Teste</td>
                    <td>
                      <img
                        [src]="productOffer.image"
                        alt="Imagem do Produto"
                        height="50"
                      />
                    </td>
                    <td>{{ productOffer.freeMonths }}</td>
                    <td>
                      <ng-container *ngFor="let item of productOffer.features">
                        <span>- {{ item }}</span>
                        <br />
                      </ng-container>
                    </td>
                    <td>
                      <button
                        class="btn btn-danger btn-sm"
                        (click)="removerVinculoProduto(productOffer.id)"
                      >
                        Remover
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #noProductsLinked>
      <div class="alert alert-info mt-3" role="alert">
        Nenhum produto vinculado a esta oferta.
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      (click)="modal.dismiss('Close click')"
    >
      Fechar
    </button>
  </div>
</ng-template>

<ng-template
  #formError
  let-control="control"
  let-message="message"
  let-validation="validation"
>
  <ng-container
    *ngIf="
      control &&
      control.hasError(validation) &&
      (control.dirty || control.touched)
    "
  >
    <div>
      <span role="alert" class="font-small-3 text-danger text-bold-600">
        {{ message }}
      </span>
    </div>
  </ng-container>
</ng-template>
