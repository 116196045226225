<div class="row">
  <div class="col">
    <swiper [config]="swiperConfig" #swiperGallery class="swiper-gallery">
      <div class="swiper-wrapper">
        <div class="swiper-slide" *ngFor="let banner of bannersList">
          <img
            [src]="banner.imgDesktop"
            class="img-fluid d-none d-md-block"
            (click)="onClickElement(banner.url)"
            style="cursor: pointer"
          />
          <img
            [src]="banner.imgMobile"
            class="img-fluid d-md-none"
            (click)="onClickElement(banner.url)"
            style="cursor: pointer"
          />
        </div>
      </div>
      <div
        role="button"
        title="Anterior"
        class="swiper-button-prev"
        (click)="goPrev()"
      ></div>
      <div
        role="button"
        title="Próximo"
        class="swiper-button-next"
        (click)="goNext()"
      ></div>
    </swiper>
  </div>
</div>
