import { UserDataService } from "../../shared/services/user-data.service";
import { SharedService } from "../../shared/services/shared.service";
import { ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import Api from "../../helpers/api";

@Component({
  selector: "app-assinaturas",
  templateUrl: "./assinaturas.component.html",
  styleUrls: ["./assinaturas.component.scss"],
})
export class AssinaturasComponent implements OnInit {
  usuario: any;
  apiCall = this.api.new().silent();
  assinaturas: any[];
  loading: boolean = true;
  error: any;
  user: object;
  userId: string;

  PlanIdentifiers = {
    plano_1: "Mensal",
    plano_2: "Trimenstral",
    plano_3: "Anual",
    plano_2_boleto: "Trimenstral",
    plano_2_parcelado: "Trimenstral Parcelado",
    plano_3_boleto: "Anual Boleto Parcelado",
    plano_3_hotmart: "Anual Hotmart Parcelado",
    plano_3_parcelado: "Anual Parcelado",
    plano_4_boleto: "Semestral",
    plano_4_hotmart: "Semestral",
    plano_4_parcelado: "Semestral",
  };

  constructor(
    private api: Api,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private userDataService: UserDataService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.userId = params.get("userId");
    });
  }

  ngOnInit(): void {
    this.loadSubscriptions(this.userId);
  }

  loadSubscriptions(userId: string): void {
    this.apiCall.get(`backoffice/${userId}/financial/profile/`).subscribe(
      (data) => {
        this.assinaturas = data.return.subscriptions;
        this.user = data.return.user;
        this.assinaturas.forEach((sub) => {
          if (sub && sub.planIdentifier) {
            const planIdentifier = sub.planIdentifier;
            const plan = this.PlanIdentifiers[planIdentifier];
            sub.plano = plan ? plan : "Desconhecido";
          }
        });
        this.sharedService.setUser(this.user);
        this.sharedService.setSubscriptions({ ...this.assinaturas });
        this.loading = false;
      },
      (error) => {
        this.error = error;
        this.loading = false;
      }
    );
  }

  cancelSubscription(assinatura: any): void {
    this.apiCall
      .delete(`backoffice/financial/subscription/${assinatura.subscriptionId}`)
      .subscribe(
        () => {
          this.assinaturas = this.assinaturas.filter(
            (sub) => sub.id !== assinatura.id
          );
        },
        (error) => {
          console.log("Erro ao cancelar a assinatura:", error);
        }
      );
  }
}
