import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { Vendas } from "app/personal/pages/finance-reports/graphics.types";

@Component({
  selector: "app-sales-chart",
  templateUrl: "./sales-chart.component.html",
  styleUrls: ["./sales-chart.component.scss"],
})
export class SalesChartComponent implements OnChanges {
  @Input() data: Vendas | undefined;
  public chartOptions: any = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.updateChart();
    }
  }

  private updateChart(): void {
    if (this.data && this.data.recorrentes && this.data.unica) {
      const recurringData = this.data.recorrentes.map((r) => r.amount);
      const uniqueData = this.data.unica.map((u) => u.amount);
      const categories = this.data.recorrentes.map((r) => r.date);

      this.chartOptions = {
        series: [
          {
            name: "Recorrente",
            data: recurringData,
          },
          {
            name: "Compra Única",
            data: uniqueData,
          },
        ],
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        colors: ["#FF7F0E", "#C0C0C0"],
        xaxis: {
          categories: categories,
        },
        title: {
          text: "Número de Vendas",
          align: "left",
        },
      };
    }
  }
}
