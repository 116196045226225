import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Api from "../../../helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import { AuthService } from 'app/shared/auth/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import Me from 'app/helpers/me';
import App from "../../../helpers/app";
import * as moment from 'moment';


@Component({
  selector: 'app-avaliacao-web',
  templateUrl: './avaliacao-web.component.html',
  styleUrls: ['./avaliacao-web.component.scss']
})
export class AvaliacaoWebComponent implements OnInit {
  apiCall = this.api.new().silent();

  @Input() public relation;
  avaliationId = null
  avaliation = null
  avaliationItens = null
  avaliationFiles = null
  avaliationResults = null
  resultId = null
  sellerCustomer = null
  personalName = null
  alunoName = null

  purchaseId = null
  productId = null
  product = null
  invoice = null
  safeURL = null
  sellerView = null
  loadPreviewContent = null
  flagAvaliacaoDobras = false
  flagAvaliacaoDobrasCompleta = false
  answeredAt = null

  
  constructor(private api: Api,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    private _sanitizer: DomSanitizer,
    private me: Me,
    private app: App,
    ){ 

    this.route.params.subscribe((params) => {
      if(params['id']) {
        this.avaliationId = atob(params['id'])  
        console.log("avaliationId",this.avaliationId)
       }

    })

    
  }

  async ngOnInit() {
    const self = this
   
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

   
      //rota avaliation get receber id avaliacao
      const getAvaliation = await self.apiCall.get('avaliation-web/'+ self.avaliationId).pipe(take(1)).toPromise()
      
      if(getAvaliation.return && getAvaliation.return.avaliation){  
        console.log(getAvaliation.return.avaliation)
        self.avaliation = getAvaliation.return.avaliation
        self.sellerCustomer = getAvaliation.return.sellerCustomer
        self.personalName = self.sellerCustomer.Seller.name
        self.alunoName = self.sellerCustomer.Customer.name
        self.answeredAt = self.avaliation.answeredAt? this.formatDate(self.avaliation.answeredAt) : 'Não respondida'
        self.avaliationResults = self.avaliation.Results[0]? JSON.parse(self.avaliation.Results[0].result) : null
        self.resultId = self.avaliationResults? self.avaliation.Results[0].id : null
        // console.log("o que tem: ", self.sellerCustomer)
        // this.product = productoo.return
        // console.log("Product",this.product)

        // if(user && user.id && this.product.user == user.id){
        // this.loadPreviewContent = true
        // console.log(this.loadPreviewContent)
        // }

        // if(this.product.delivery_video){
        //   self.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.product.delivery_video.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));
        // }

        // const purchase = await self.apiCall.get('customer/me/getinvoice/'+self.purchaseId+'/'+self.product.user).pipe(take(1)).toPromise()

        // if(purchase.return){
        //   this.invoice = purchase.return
        //   console.log("Product", this.invoice)
        // }

      }else{
        await this.app.alert('Avaliação não encontrada!', 'Nenhuma avaliação encontrada', 'error')

      }

      await self.spinner.hide()

  }

  getResult(){
    let flag = true
    this.flagAvaliacaoDobrasCompleta = true
    if(this.avaliation.category != 9) {
      for(let p of this.avaliation.Itens){
        if(p.question != 'Meta de Gordura'){
          if(p.response == null || p.response == ""){
            flag = false
            this.flagAvaliacaoDobras = false
          }
        }
        if(p.response == null || p.response == ""){
          this.flagAvaliacaoDobrasCompleta = false
        }
      }
    }
    let soma07Dobras = null
    let soma03Dobras = null
    let abdomen = null
    let pescoco = null
    let gluteo = null
    let alturaPol = null
    let idade = null
    let peso = null
    let altura = null
    let meta = null
    let imc = null
    if(flag){
      this.flagAvaliacaoDobras = true
      if(this.avaliation.category == 2 || this.avaliation.category == 3){
        for(let p of this.avaliation.Itens){
          if(p.question != "Idade" && p.question != "Peso" && p.question != "Altura" && p.question != "Meta de Gordura"){
            soma07Dobras += parseFloat(p.response)
          }

          if(p.question == "Idade"){
            idade = p.response
          }
          if(p.question == "Peso"){
            peso = p.response
          }
          if(p.question == "Altura"){
            altura = p.response
          }
          if(p.question == "Meta de Gordura"){
            meta = p.response
          }
        }
      }else if(this.avaliation.category == 4 || this.avaliation.category == 5){
        for(let p of this.avaliation.Itens){
          if(p.question != "Idade" && p.question != "Peso" && p.question != "Altura" && p.question != "Meta de Gordura"){
            soma03Dobras += parseFloat(p.response)
          }

          if(p.question == "Idade"){
            idade = p.response
          }
          if(p.question == "Peso"){
            peso = p.response
          }
          if(p.question == "Altura"){
            altura = p.response
          }
          if(p.question == "Meta de Gordura"){
            meta = p.response
          }
        }
      }else if(this.avaliation.category == 6 || this.avaliation.category == 7){
        for(let p of this.avaliation.Itens){
          if(p.question == "Idade"){
            idade = p.response
          }
          if(p.question == "Peso"){
            peso = p.response
          }
          if(p.question == "Altura"){
            altura = p.response
          }
          if(p.question == 'Circunferência de abdomen'){
            abdomen = p.response
          }
          if(p.question == 'Circunferência de pescoço'){
            pescoco = p.response
          }if(this.avaliation.category == 7 && p.question == 'Circunferência de glúteo'){
            gluteo = p.response
          }
          if(p.question == "Meta de Gordura"){
            meta = p.response
          }
        }
      }
      //result recebe a formula aqui
      let dc = null
      let gPorcent = null
      let gPorcentClass = null
      let category = this.avaliation.category
      altura = parseFloat(altura).toFixed(2)
      altura = altura/100
      imc = peso/(altura*altura)
      imc = parseFloat(imc).toFixed(2)
      imc = imc + this.getClassIMC(imc)
      let alturaConvert = altura
      switch (category) {
        case 2:
          //polock07DobrasH
          dc = 1.112 - 0.00043499 * (soma07Dobras) + 0.00000055 * (soma07Dobras*soma07Dobras) - 0.00028826 * (idade)
          gPorcent = (4.95/dc - 4.50) * 100
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'h', idade)
          for(let p of this.avaliationResults){
            let pesoGordo = peso * (gPorcent/100)
            let pesoMeta = peso * (meta/100)
            if(p.name == "Gordura Ideal"){
              p.value = this.getGorduraIdeal(idade)
            }
            if(p.name == "Gordura Atual"){
              p.value = gPorcentClass
            }
            if(p.name == "Peso Magro"){
              p.value = peso - pesoGordo
            }
            if(p.name == "Peso Gordo"){
              p.value = pesoGordo
            }
            if(p.name == "Peso Meta"){
              p.value = peso - pesoMeta
            }
            if(p.name == "IMC"){
              p.value = imc
            }
          }
        break;
        case 3:
          //polock07DobrasM
          dc = 1.0970 - 0.00046971 * (soma07Dobras) + 0.00000056 * (soma07Dobras*soma07Dobras) - 0.00012828 * (idade)
          gPorcent = (4.95/dc - 4.50) * 100
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'm', idade)
          for(let p of this.avaliationResults){
            let pesoGordo = peso * (gPorcent/100)
            let pesoMeta = peso * (meta/100)
            if(p.name == "Gordura Ideal"){
              p.value = this.getGorduraIdeal(idade)
            }
            if(p.name == "Gordura Atual"){
              p.value = gPorcentClass
            }
            if(p.name == "Peso Magro"){
              p.value = peso - pesoGordo
            }
            if(p.name == "Peso Gordo"){
              p.value = pesoGordo
            }
            if(p.name == "Peso Meta"){
              p.value = peso - pesoMeta
            }
            if(p.name == "IMC"){
              p.value = imc
            }
          }
        break;
        case 4:
          //polock03DobrasH
          dc = 1.1093800 - 0.0008267 * (soma03Dobras) + 0.0000016 * (soma03Dobras*soma03Dobras) -  0.0002574 * (idade)
          gPorcent = (4.95/dc - 4.50) * 100
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'h', idade)
          for(let p of this.avaliationResults){
            let pesoGordo = peso * (gPorcent/100)
            let pesoMeta = peso * (meta/100)
            if(p.name == "Gordura Ideal"){
              p.value = this.getGorduraIdeal(idade)
            }
            if(p.name == "Gordura Atual"){
              p.value = gPorcentClass
            }
            if(p.name == "Peso Magro"){
              p.value = peso - pesoGordo
            }
            if(p.name == "Peso Gordo"){
              p.value = pesoGordo
            }
            if(p.name == "Peso Meta"){
              p.value = peso - pesoMeta
            }
            if(p.name == "IMC"){
              p.value = imc
            }
          }
        break;
        case 5:
          //polock03DobrasM
          dc = 1.099492 - 0.0009929 * (soma03Dobras) + 0.0000023 * (soma03Dobras*soma03Dobras) - 0.0001392 * (idade)
          gPorcent = (4.95/dc - 4.50) * 100
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'm', idade)
          for(let p of this.avaliationResults){
            let pesoGordo = peso * (gPorcent/100)
            let pesoMeta = peso * (meta/100)
            if(p.name == "Gordura Ideal"){
              p.value = this.getGorduraIdeal(idade)
            }
            if(p.name == "Gordura Atual"){
              p.value = gPorcentClass
            }
            if(p.name == "Peso Magro"){
              p.value = peso - pesoGordo
            }
            if(p.name == "Peso Gordo"){
              p.value = pesoGordo
            }
            if(p.name == "Peso Meta"){
              p.value = peso - pesoMeta
            }
            if(p.name == "IMC"){
              p.value = imc
            }
          }
        break;
        case 6:
          //dotsonEDavisH
          alturaConvert = altura*100
          alturaConvert =  parseFloat(alturaConvert).toFixed(2)
          alturaPol = (alturaConvert/2.54).toFixed(2)
          gPorcent = +(85.20969 * Math.log(abdomen - pescoco)) - (69.73016 * Math.log(alturaPol)) + 37.26673
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'h', idade)
          for(let p of this.avaliationResults){
            let pesoGordo = peso * (gPorcent/100)
            let pesoMeta = peso * (meta/100)
            if(p.name == "Gordura Ideal"){
              p.value = this.getGorduraIdeal(idade)
            }
            if(p.name == "Gordura Atual"){
              p.value = gPorcentClass
            }
            if(p.name == "Peso Magro"){
              p.value = peso - pesoGordo
            }
            if(p.name == "Peso Gordo"){
              p.value = pesoGordo
            }
            if(p.name == "Peso Meta"){
              p.value = peso - pesoMeta
            }
            if(p.name == "IMC"){
              p.value = imc
            }
          }
        break;
        case 7:
          //dotsonEDavisM
          alturaConvert = altura*100
          alturaConvert =  parseFloat(alturaConvert).toFixed(2)
          alturaPol = (alturaConvert/2.54).toFixed(2)
          gPorcent = +(161.27327 * Math.log(abdomen + gluteo + pescoco)) - (100.81032 * Math.log(alturaPol)) + 69.55016
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'm', idade)
          for(let p of this.avaliationResults){
            let pesoGordo = peso * (gPorcent/100)
            let pesoMeta = peso * (meta/100)
            if(p.name == "Gordura Ideal"){
              p.value = this.getGorduraIdeal(idade)
            }
            if(p.name == "Gordura Atual"){
              p.value = gPorcentClass
            }
            if(p.name == "Peso Magro"){
              p.value = peso - pesoGordo
            }
            if(p.name == "Peso Gordo"){
              p.value = pesoGordo
            }
            if(p.name == "Peso Meta"){
              p.value = peso - pesoMeta
            }
            if(p.name == "IMC"){
              p.value = imc
            }
          }
        break;
        case 8:
          //bioimpedancia
        break;
        case 9:
          //perimetros
        break;
      }
    }
  }

  getGorduraIdeal(idade){
    //this.avaliation.category
    const self = this
    let gorduraIdeal = null
    //Pollock 7 e 3 dobras
    if(this.avaliation.category == 2 || this.avaliation.category == 3 || this.avaliation.category == 4 || this.avaliation.category == 5){
      if(this.avaliation.category == 2){
        if(idade >= 20 && idade <= 29){
          gorduraIdeal = "14 a 20"
        }else if(idade >= 30 && idade <= 39){
          gorduraIdeal = "15 a 21"
        }else if(idade >= 40 && idade <= 49){
          gorduraIdeal = "17 a 23"
        }else if(idade >= 50 && idade <= 59){
          gorduraIdeal = "18 a 24"
        }else if(idade >= 60){
          gorduraIdeal = "19 a 25"
        }
      }else{
        if(idade >= 20 && idade <= 29){
          gorduraIdeal = "20 a 28"
        }else if(idade >= 30 && idade <= 39){
          gorduraIdeal = "21 a 29"
        }else if(idade >= 40 && idade <= 49){
          gorduraIdeal = "22 a 30"
        }else if(idade >= 50 && idade <= 59){
          gorduraIdeal = "23 a 31"
        }else if(idade >= 60){
          gorduraIdeal = "24 a 32"
        }
      }//DotsonEDavis
    }else if(this.avaliation.category == 6 || this.avaliation.category == 7){
      if(this.avaliation.category == 6){
        if(idade >= 6 && idade <= 17){
          gorduraIdeal = "11 a 25"
        }else if(idade >= 18 && idade <= 34){
          gorduraIdeal = "13 a 21"
        }else if(idade >= 35 && idade <= 55){
          gorduraIdeal = "18 a 24"
        }else if(idade >= 55){
          gorduraIdeal = "16 a 22"
        }
      }else{
        if(idade >= 6 && idade <= 17){
          gorduraIdeal = "16 a 30"
        }else if(idade >= 18 && idade <= 34){
          gorduraIdeal = "28 a 34"
        }else if(idade >= 35 && idade <= 55){
          gorduraIdeal = "32 a 37"
        }else if(idade >= 55){
          gorduraIdeal = "30 a 34"
        }
      }
    }
    return gorduraIdeal
  }

  getClassIMC(imc){
    let classIMC = null
    if(imc < 18.5){
      classIMC = " - Baixo Peso"
    }else if(imc >= 18.5 && imc <= 24.9){
      classIMC = " - Adequado"
    }else if(imc >= 25 && imc <= 29.9){
      classIMC = " - Sobrepeso"
    }else if(imc >= 30){
      classIMC = " - Obesidade"
    }
    return classIMC
  }

  getClassGorduraAtual(gPorcent, sexo, idade){
    let classGorduraAtual = null
    if(sexo == 'h'){
      if(idade >= 20 && idade <= 29){
        if(gPorcent < 11){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 11 && gPorcent <= 13){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 14 && gPorcent <= 20){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 21 && gPorcent <= 23){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 23){
          classGorduraAtual = " - Insuficiente"
        }
      }else if(idade >= 30 && idade <= 39){
        if(gPorcent < 12){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 12 && gPorcent <= 14){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 15 && gPorcent <= 21){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 22 && gPorcent <= 24){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 24){
          classGorduraAtual = " - Insuficiente"
        }
      }else if(idade >= 40 && idade <= 49){
        if(gPorcent < 14){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 14 && gPorcent <= 16){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 17 && gPorcent <= 23){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 24 && gPorcent <= 26){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 26){
          classGorduraAtual = " - Insuficiente"
        }
      }else if(idade >= 50 && idade <= 59){
        if(gPorcent < 15){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 15 && gPorcent <= 17){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 18 && gPorcent <= 24){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 25 && gPorcent <= 27){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 27){
          classGorduraAtual = " - Insuficiente"
        }
      }else if(idade >= 60){
        if(gPorcent < 16){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 16 && gPorcent <= 18){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 19 && gPorcent <= 25){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 26 && gPorcent <= 28){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 28){
          classGorduraAtual = " - Insuficiente"
        }
      }
    }else{
      if(idade >= 20 && idade <= 29){
        if(gPorcent < 16){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 16 && gPorcent <= 19){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 20 && gPorcent <= 28){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 29 && gPorcent <= 31){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 31){
          classGorduraAtual = " - Insuficiente"
        }
      }else if(idade >= 30 && idade <= 39){
        if(gPorcent < 17){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 17 && gPorcent <= 20){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 21 && gPorcent <= 29){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 30 && gPorcent <= 32){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 32){
          classGorduraAtual = " - Insuficiente"
        }
      }else if(idade >= 40 && idade <= 49){
        if(gPorcent < 18){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 18 && gPorcent <= 21){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 22 && gPorcent <= 30){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 31 && gPorcent <= 33){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 33){
          classGorduraAtual = " - Insuficiente"
        }
      }else if(idade >= 50 && idade <= 59){
        if(gPorcent < 19){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 19 && gPorcent <= 22){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 23 && gPorcent <= 31){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 32 && gPorcent <= 34){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 34){
          classGorduraAtual = " - Insuficiente"
        }
      }else if(idade >= 60){
        if(gPorcent < 20){
          classGorduraAtual = " - Excelente"
        }else if(gPorcent >= 20 && gPorcent <= 23){
          classGorduraAtual = " - Bom"
        }else if(gPorcent >= 24 && gPorcent <= 32){
          classGorduraAtual = " - Média"
        }else if(gPorcent >= 33 && gPorcent <= 35){
          classGorduraAtual = " - Regular"
        }else if(gPorcent > 35){
          classGorduraAtual = " - Insuficiente"
        }
      }
    }
    return classGorduraAtual
  }

  submitWebAvaliacao(){
    const self = this

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    let chamada

    for(let p of self.avaliation.Itens){
      if(p.question == "Altura"){
        p.response =  parseFloat(p.response).toFixed(2)
        p.response = (p.response/100).toFixed(2)
      }
    }
    
    const body = {
      avaliation : {
        id: self.avaliation.id,
        sellerCustomer : self.avaliation.sellerCustomer,
        resultId : self.resultId,
        type : '0',
        name : self.avaliation.name,
        itens: self.avaliation.Itens,
        results: self.avaliationResults && self.avaliationResults.length > 0 ? self.avaliationResults : null
      }
    }
    chamada = self.apiCall.put('response/avaliation-web/'+self.avaliation.sellerCustomer, body)

    chamada.subscribe(async data => {
      if(data.success){
        self.spinner.hide()
        await this.app.alert('Tudo certo!', self.avaliation.id ? 'Sua avaliação foi respondida com sucesso' : 'Sua avaliação foi criada com sucesso', 'success')
        window.open(`https://opersonaldigital.com.br/avaliacao-respondida/`, "_self");
      }else{
        await this.app.alert('Ops :(', data.message, 'error')
        self.spinner.hide()
      }
    });
  }

  formatDate(data){
    return moment(data).format('DD/MM/YYYY HH:mm')
  }
  formatDateWithoutTime(data){
    return moment(data).format('DD/MM/YYYY')
  }

  checkAvaliationAnswered(){

    const self = this

    if(self.avaliation && self.avaliation.answeredAt == null && self.avaliation.Itens && self.avaliation.Itens.length > 0){
      console.log("true")
      return true
    }else{
      console.log("false")
      return false
    }
  }

}
