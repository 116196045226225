<div class="pix-container">
  <div class="payment-section">
    <div class="payment-title"><b>Pagar Fatura</b></div>
    <div class="pix-info">
      <div class="form-group">
        <label for="pixQRCode"><b>QR Code</b></label>
        <div class="qr-code-container">
          <img
            [src]="invoice.pix.qrcode"
            alt="QR Code PIX"
            class="pix-qrcode"
          />
        </div>
      </div>
      <div class="form-group">
        <div class="input-group-append">
          <button class="btn btn-primary btn-lg" (click)="copyPixKey()">
            Copiar Chave Pix
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
