import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import {
  MarketplaceProductsRequestType,
  MarketplaceProductsReturnType,
  ProductType,
} from "app/personal/pages/finance-store/services-and-products/services-and-products.types";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { asyncScheduler, scheduled } from "rxjs";
import { map } from "rxjs/operators";
import { CoverUploadModalComponent } from "../cover-upload-modal/cover-upload-modal.component";
import { ProductCreationAndEditionModalComponent } from "../product-creation-and-edition-modal/product-creation-and-edition-modal.component";
import { ModalHotmartComponent } from "../modal-hotmart/modal-hotmart.component";
import { NutriplanComponent } from "../nutriplan/nutriplan.component";

const GET_PRODUCTS_LIMIT = 100;

("/addtostore");

@Component({
  selector: "app-on-store-products",
  templateUrl: "./on-store-products.component.html",
  styleUrls: ["./on-store-products.component.scss"],
})
export class OnStoreProductsComponent implements OnInit {
  tooltipContent: string = `
  Seu aluno responde questionário e recebe dieta diária personalizada.
  Para saber mais sobre comissões, prazos de recebimento e outros detalhes, 
  <a href="https://link-exemplo.com" target="_blank">clique aqui</a>.
`;

  apiCall = this.api.new().silent();
  hotmartProducts = [];
  products: ProductType[] = [];
  oneTimeProducts: ProductType[] = [];
  recurringProducts: ProductType[] = [];

  hasProductWihoutBanner = false;

  isLoading = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private app: App,
    private api: Api
  ) {}

  ngOnInit(): void {
    this.getProducts({
      limit: GET_PRODUCTS_LIMIT,
      offset: 0,
      onStore: true,
    }).subscribe(() => this.cdr.detectChanges());
    this.getHotmartProducts();
  }

  getProducts(query: MarketplaceProductsRequestType) {
    this.isLoading = true;

    return this.apiCall
      .get<MarketplaceProductsReturnType | null>(
        `/marketplace/products?${new URLSearchParams(
          Object.entries(query) as unknown as string[][]
        ).toString()}`
      )
      .pipe(
        map((response) => {
          this.isLoading = false;

          if (response.success && response.return) {
            this.products = response.return.rows;
            this.oneTimeProducts = response.return.rows.filter(
              (item) => item.plan_type === 0
            );
            this.recurringProducts = response.return.rows.filter(
              (item) => item.plan_type === 1
            );

            this.hasProductWihoutBanner = !!this.products.find(
              (item) => !item.cover
            );
          } else {
            this.toastr.error(response.message, "Ops :(");
          }

          return scheduled([response], asyncScheduler);
        })
      );
  }

  getHotmartProducts() {
    return this.apiCall
      .get("seller-store/affiliate/products")
      .subscribe((data) => {
        this.hotmartProducts = data.return;
        console.log("hotmart", this.hotmartProducts);
      });
  }
  trackByProductId(index: number, product: ProductType): number {
    return product.id;
  }
  onDrop(
    event: CdkDragDrop<
      {
        [key: string]: ProductType;
      }[]
    >,
    productType: "one-time" | "recurring"
  ) {
    const products = [
      ...(productType === "one-time"
        ? this.oneTimeProducts
        : this.recurringProducts),
    ];

    moveItemInArray(products, event.previousIndex, event.currentIndex);

    // Atualize a referência do array de produtos correspondente
    if (productType === "one-time") {
      this.oneTimeProducts = [...products];
    } else {
      this.recurringProducts = [...products];
    }

    const body: { products: { id: number; order: number }[] } = {
      products: [],
    };

    products.forEach((product, i) => {
      body.products.push({ id: product.id, order: i + 1 });
    });

    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall
      .put("marketplace/products/reorder", body)
      .subscribe((response) => {
        this.spinner.hide();
        if (response.success) {
          this.toastr.success("Produto reordenado com Sucesso!", "Tudo certo!");

          // Força a detecção de mudanças
          this.cdr.detectChanges();
        } else {
          this.toastr.error(response.message, "Ops :(");
        }
      });
  }

  addOrChangeImage(productId: number) {
    const instance = this.modalService.open(CoverUploadModalComponent, {
      centered: true,
      size: "lg",
    }).componentInstance as CoverUploadModalComponent;

    const product = this.products.find((item) => productId === item.id);
    instance.defaultProduct = product || null;
    instance.callback = (status: "success" | "error") => {
      if (status === "success") {
        this.getProducts({
          limit: GET_PRODUCTS_LIMIT,
          offset: 0,
          onStore: true,
        }).subscribe(() => this.cdr.detectChanges());
      }
    };
  }

  toggleHightlight(productId: number, isFeatured: boolean) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.apiCall
      .put(`marketplace/product/featured/${productId}`)
      .subscribe((response) => {
        this.spinner.hide();

        if (response.success) {
          this.toastr.success(
            isFeatured
              ? "Destaque do produto removido com Sucesso!"
              : "Destaque do produto adicionado com Sucesso!",
            "Tudo certo!"
          );

          this.getProducts({
            limit: GET_PRODUCTS_LIMIT,
            offset: 0,
            onStore: true,
          }).subscribe(() => this.cdr.detectChanges());
        } else {
          this.toastr.error(response.message, "Ops :(");
        }
      });
  }

  removeFromStore(productId: number) {
    this.app
      .confirm(
        "Tem certeza?",
        "Tem certeza que deseja remover este produto da vitrine?"
      )
      .then((result) => {
        if (result) {
          this.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });

          this.apiCall
            .put(`marketplace/product/removefromstore/${productId}`)
            .subscribe((response) => {
              this.spinner.hide();

              if (response.success) {
                this.toastr.success(
                  "Produto removido da vitrine com Sucesso!",
                  "Tudo certo!"
                );

                this.getProducts({
                  limit: 50,
                  offset: 0,
                  onStore: true,
                }).subscribe(() => this.cdr.detectChanges());
              } else {
                this.toastr.error(response.message, "Ops :(");
              }
            });
        }
      });
  }

  editProduct(product: ProductType) {
    const instance = this.modalService.open(
      ProductCreationAndEditionModalComponent,
      {
        centered: true,
        size: "lg",
      }
    ).componentInstance as ProductCreationAndEditionModalComponent;

    instance.defaultProduct = product;
    instance.callback = (status: "success" | "error") => {
      if (status === "success") {
        this.getProducts({
          limit: GET_PRODUCTS_LIMIT,
          offset: 0,
          onStore: true,
        }).subscribe(() => this.cdr.detectChanges());
      }
    };
  }

  integerToReal(val: number) {
    return Intl.NumberFormat("pt-Br", {
      style: "currency",
      currency: "BRL",
    }).format(val / 100);
  }

  planInterval(val: number, type: string) {
    switch (type) {
      case "days":
        return `${val} ${val === 1 ? "dia" : "dias"}`;
      case "weeks":
        return `${val} ${val === 1 ? "semana" : "semanas"}`;
      case "months":
        return `${val} ${val === 1 ? "mês" : "meses"}`;
      case "years":
        return `${val} ${val === 1 ? "ano" : "anos"}`;
      default:
        return `${val} -`;
    }
  }

  preventDefault(event: Event, execute?: { func: Function; args: any[] }) {
    event.preventDefault();
    event.stopPropagation();

    if (execute) {
      const { func, args } = execute;

      func(...args);
    }
  }

  modalHotmart() {
    this.modalService.open(ModalHotmartComponent);
  }
  modalNutriplan() {
    this.modalService.open(NutriplanComponent);
  }
}
