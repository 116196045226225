import { Injectable } from "@angular/core";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import { CreateDraftTrainingPaylaodType, CreateDraftTrainingType, TrainingDataType } from "./training-wizard.types";

@Injectable({
  providedIn: "root",
})
export class TrainingWizardService {
  apiCall = this.api.new().silent();

  constructor(private app: App, private api: Api) {}

  formatTraining(exercises: TrainingDataType[]) {
    const orderedExercises = exercises.sort((a, b) => a.order - b.order);

    return this.apiCall.post("training/gpt/formatTraining", {
      exercises: orderedExercises,
    });
  }

  createDraftTraining(training: CreateDraftTrainingType) {
    const payload: CreateDraftTrainingPaylaodType = {
      name: "Nome Treino",
      type: 1,
      agendamento: null,
      vencimento: null,
      isModel: training.isModel || false,
      sellerCustomer: training.sellerCustomer || null,
      group: training.group || null,
      status: false,
      editorVersion: "iago_v1",
      isDraft: true,
      exercises: training.exercises,
      lastTrainings:
        Array.isArray(training.lastTrainings) &&
        training.lastTrainings.length > 0
          ? training.lastTrainings
          : null,
    };

    return this.apiCall.post("training/gpt/createdraft", { training: payload });
  }

  throwCreationError() {
    throw new Error(
      "Ocorreu um erro inesperado ao criar o treino. Tente novamente mais tarde."
    );
  }

  throwUnexpectedError() {
    throw new Error("Ocorreu um erro inesperado. Tente novamente mais tarde.");
  }

  alertCreationError() {
    this.app.alert(
      "Erro",
      "Ocorreu um erro inesperado ao criar o treino, tente novamente mais tarde!",
      "error"
    );
  }
}
