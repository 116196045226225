import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import * as moment from "moment";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";

@Component({
  selector: "app-finance-subscriptions",
  templateUrl: "./finance-subscriptions.component.html",
  styleUrls: ["./finance-subscriptions.component.scss"],
})
export class FinanceSubscriptionsComponent implements OnInit {
  apiCall = this.api.new().silent();
  account = null;
  subscriptions = [];
  subscriptionSelecionada = null;
  subscriptionsFiltered = [];
  alunoSelecionadoVincular = null;
  page = 1;
  pageSize = 10;
  filterStatusAssinatura = null;
  filterCustomerName = null;
  filterPlans = null;
  filterPaymentMethod = null;

  statusAssinatura = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: "Ativo",
      name: "Ativas",
    },
    {
      id: "Pendente",
      name: "Pendentes",
    },
    {
      id: "Suspenso",
      name: "Suspensas",
    },
  ];

  tiposPagamento = [
    {
      id: null,
      name: "Todos",
    },
    {
      id: "credit_card",
      name: "Cartão de Crédito",
    },
    {
      id: "bank_slip",
      name: "Boleto",
    },
    {
      id: "pix",
      name: "Pix",
    },
  ];

  assinaturasAtivas = null;
  assinaturasPendentes = null;
  assinaturasSuspensas = null;
  alunos = [];
  activeModal = null;
  videoUrl = null;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private api: Api,
    private app: App,
    private modal: NgbModal,
    private clipboardApi: ClipboardService,
    private _sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    const self = this;

    let localAccount = null;

    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/b5xNMkCKLwE"
    );
    const subaccount = await this.apiCall
      .get("seller/me/marketplaceaccountlocal")
      .pipe(take(1))
      .toPromise();

    if (!subaccount.return) {
      this.router.navigate(["/page/personal/finance/account"]);
    } else {
      localAccount = subaccount.return;
    }

    if (
      (localAccount &&
        localAccount.identity_validation &&
        localAccount.identity_validation != 3) ||
      (localAccount && !localAccount.identity_validation)
    ) {
      this.router.navigate(["/page/personal/finance/account"]);
    }

    const accountInfos = await this.apiCall
      .get("seller/me/marketplaceaccount")
      .pipe(take(1))
      .toPromise();

    if (!accountInfos.return) {
      this.router.navigate(["/page/personal/finance/account"]);
    }

    if (accountInfos.return) {
      this.account = accountInfos.return;
    }

    const assinaturas = await this.apiCall
      .get("seller/me/subaccountsubscriptions")
      .pipe(take(1))
      .toPromise();

    if (assinaturas && assinaturas.return) {
      this.subscriptions = assinaturas.return;
      this.subscriptionsFiltered = this.subscriptions;
      this.assinaturasAtivas = this.subscriptions.filter(
        (x) => x.status == "Ativo"
      ).length;
      this.assinaturasPendentes = this.subscriptions.filter(
        (x) => x.status == "Pendente"
      ).length;
      this.assinaturasSuspensas = this.subscriptions.filter(
        (x) => x.status == "Suspenso"
      ).length;
    }

    await this.spinner.hide();
  }

  formatDate(date) {
    moment.locale("pt-br");
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  }

  formatPreco(valor) {
    let retorno = (valor / 100).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    return retorno;
  }

  subscriptionIdGerado = null;
  formatSubscriptionId(subscriptionId) {
    let retorno =
      subscriptionId.substr(0, 4) + " - " + subscriptionId.substr(-4);
    this.subscriptionIdGerado = retorno;
    return retorno;
  }

  copiarSubscriptionId(subscriptionId) {
    this.clipboardApi.copyFromContent(subscriptionId);
  }

  formatLinguagem(tiposPagamento) {
    const self = this;

    if (tiposPagamento == "credit_card") {
      return "Cartão de Crédito";
    } else if (tiposPagamento == "bank_slip") {
      return "Boleto";
    } else if (tiposPagamento == "pix") {
      return "Pix";
    }
  }

  OpenModalSm(content) {
    this.modal.open(content, { centered: true, size: "sm" });
  }

  OpenModalXl(content) {
    this.modal.open(content, { centered: true, size: "xl" });
  }

  filter() {
    const self = this;
    this.subscriptionsFiltered = this.subscriptions;

    if (this.filterStatusAssinatura) {
      this.subscriptionsFiltered = this.subscriptionsFiltered.filter(
        (x) => x.status == this.filterStatusAssinatura.id
      );
    }
    if (this.filterCustomerName) {
      this.subscriptionsFiltered = this.subscriptionsFiltered.filter(
        (x) =>
          x.customer_ref &&
          x.customer_ref
            .toLowerCase()
            .includes(this.filterCustomerName.toLowerCase())
      );
    }
    if (this.filterPaymentMethod) {
      this.subscriptionsFiltered = this.subscriptionsFiltered.filter(
        (x) => x.payable_with == this.filterPaymentMethod.id
      );
    }
  }

  async suspenderAssinatura(subscription) {
    const self = this;

    if (subscription.status == "Ativo" || subscription.status == "Pendente") {
      self.app
        .confirm(
          "Tem certeza?",
          "Ao inativar a assinatura cobranças futuras serão canceladas automaticamente"
        )
        .then((d) => {
          if (d) {
            self.spinner.show(undefined, {
              type: "ball-triangle-path",
              size: "medium",
              bdColor: "rgba(0, 0, 0, 0.8)",
              color: "#fff",
              fullScreen: true,
            });

            let chamada = this.apiCall.delete(
              "seller/me/subscriptionmarketplace/" + subscription.subscriptionId
            );

            chamada.subscribe(async (data) => {
              if (data.success) {
                this.spinner.hide();
                await this.app.alert(
                  "Tudo certo!",
                  "Assinatura suspensa com sucesso!",
                  "success"
                );
                this.ngOnInit();
              } else {
                this.spinner.hide();
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
          }
        });
    } else {
      await this.app.alert(
        "Ops :(",
        "Só é possível suspender uma assinatura ativa!",
        "error"
      );
    }
  }

  async openVincularModal(modal, subscription) {
    const sellercustomer = await this.apiCall
      .get("sellercustomerplataforma")
      .pipe(take(1))
      .toPromise();
    if (sellercustomer.return) {
      let testUsers = sellercustomer.return.relations.filter(
        (x) => x.Customer.isTest == true
      );
      let realUsers = sellercustomer.return.relations.filter(
        (x) => x.Customer.isTest == null || !x.Customer.isTest
      );
      if (realUsers) {
        realUsers = realUsers.sort(
          (a, b) =>
            a.Customer.name && a.Customer.name.localeCompare(b.Customer.name)
        );
      }
      let allUsers = [];
      if (testUsers) {
        for (let t of testUsers) {
          allUsers.push(t);
        }
      }
      if (realUsers) {
        for (let t of realUsers) {
          allUsers.push(t);
        }
      }
      this.alunos = allUsers;
    }
    this.subscriptionSelecionada = subscription;
    this.alunoSelecionadoVincular = subscription.SellerCustomer
      ? subscription.SellerCustomer
      : null;

    this.activeModal = this.modal.open(modal, { centered: true });
  }

  async vincularAluno(vincular, modal) {
    const self = this;
    if (!vincular || self.alunoSelecionadoVincular) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      self.apiCall
        .post("seller/me/vincularAluno", {
          subscriptionId: self.subscriptionSelecionada.id,
          sellerCustomer: self.alunoSelecionadoVincular,
          vincular: vincular,
        })
        .subscribe(async (data) => {
          self.spinner.hide();
          if (data.success) {
            self.ngOnInit();
            self.activeModal.close("Close click");
            await this.app.alert(
              "Tudo certo!",
              "Aluno vinculado com sucesso",
              "success"
            );
          } else {
            await this.app.alert("Ops :(", data.message, "error");
          }
        });
    }
  }
}
