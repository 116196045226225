import { Injectable } from "@angular/core";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from "angularx-social-login";
import { Observable, Observer, Subject } from "rxjs";
import { take } from "rxjs/operators";
import config from "../config";
import Api, { Io, Response } from "./api";

export type UserPermission = {
  userId: number;
  permission: string;
  field: string | null;
  key: string | null;
};

export type UserVariable = {
  userId: number;
  variable: string;
  field: string | null;
};
export type UserFirebaseTokens = {
  user: number | null;
  token: string | null;
  platform: string | null;
  status: string | null;
};
export type User = {
  createdAt: Date;
  firstOnboardingProgress: number;
  utmContent: string;
  utmCampaing: string;
  utmMedium: string;
  utmSource: string;
  username: string;
  master: boolean;
  type: number;
  gender: number;
  firebaseToken: string | null;
  name: string | null;
  picture: string | null;
  password: string;
  provisoryPassword: string | null;
  email: string | null;
  emailValidated: boolean;
  phone: string | null;
  phoneValidated: boolean;
  Permissions: UserPermission[];
  Variables: UserVariable[];
  UserFirebaseTokens: UserFirebaseTokens[];
  id: number;
  facebook_id: any;
  google_id: any;
  apple_id: any;
  photo_url: string;
  status: boolean;
  isTest: any;
  iugu: string;
  iugu_2: string;
  lastVersion: any;
  lastLogin: string;
  freeUntil: string;
  telegram: any;
  instagram: any;
  site: any;
  suporte: any;
  notes: any;
  banner: any;
  whatsapp: any;
  myCode: any;
  myCodeLink: any;
  utmSourceCheckout: any;
  utmMediumCheckout: any;
  utmCampaingCheckout: any;
  utmContentCheckout: any;
  isVipUser: boolean;
  npsScore: any;
  npsComment: any;
  installmentSubscription: any;
  acceptPromoMessages: boolean;
  loadFeedbackNotification: any;
  promotionFunnel: any;
  acceptPromoMessagesCustomers: any;
  getTrainingsModels: any;
  capaPlataforma: any;
  onboardingProgress: any;
  journeyFirstAccess: any;
  inviteLink: any;
  initiateCheckoutOnboarding: any;
  customersAmount: any;
  onboardingInviteCustomer: any;
  hotmartCustomerCode: any;
  updatedAt: string;
  deletedAt: string;
};

@Injectable()
export default class Me {
  private load = new Subject();

  constructor(public api: Api, private socialAuthService: SocialAuthService) {}

  public getCacheData(): User | false {
    try {
      return JSON.parse(localStorage.getItem(config.me.cache_name));
    } catch (e) {
      return false;
    }
  }

  public setUser(user) {
    localStorage.setItem(config.me.cache_name, JSON.stringify(user));
  }

  get(cache = true, io?: Io): Observable<User | false> {
    const self = this;
    return Observable.create((observer: Observer<User | false>) => {
      self.load.subscribe((data: User | false) => observer.next(data));

      if (cache) {
        const cached = self.getCacheData();
        if (cached) {
          observer.next(cached);
        } else {
          self.refresh(io).subscribe((data) => observer.next(data));
        }
      } else {
        self.refresh(io).subscribe((data) => observer.next(data));
      }
    });
  }

  refresh(io?: Io): Observable<User | false> {
    const self = this;

    return Observable.create((observer: Observer<User | false>) => {
      (io ? io : self.api.new())
        .silent()
        .get("seller/me")
        .subscribe((response) => {
          localStorage.setItem(
            config.me.cache_name,
            JSON.stringify(response.return)
          );
          self.load.next(response.return);
          observer.next(response.return);
        });
    });
  }

  async onboardingUpdate(args: any, io?: Io): Promise<Response> {
    const self = this;
    const api: Io = io ? io : self.api.new();
    const data = await self.get().pipe(take(1)).toPromise();
    for (let item in args) {
      data[item] = args[item];
    }
    const update = await api.put("seller/me", args).pipe(take(1)).toPromise();
    return update;
  }

  async update(args: any, io?: Io): Promise<Response> {
    const self = this;
    const api: Io = io ? io : self.api.new();
    const data = await self.get().pipe(take(1)).toPromise();
    for (let item in args) {
      data[item] = args[item];
    }
    const update = await api.put("seller/me", args).pipe(take(1)).toPromise();
    if (update.success) {
      await self.refresh().pipe(take(1)).toPromise();
    }
    return update;
  }
  loginGoogle(io?: Io): Promise<User | false> {
    const self = this;
    const call = io ? io : self.api.new().silent();
    return new Promise(async (resolve, reject) => {
      self.socialAuthService
        .signIn(GoogleLoginProvider.PROVIDER_ID)
        .then((user) => {
          call
            .get("seller/me/google/" + user.idToken)
            .subscribe(async (data) => {
              if (data.success) {
                resolve(
                  await self.login(data.return.email, data.return.password)
                );
              } else {
                reject(data.message);
              }
            });
        });
    });
  }

  registerUser(
    email: string,
    name: string,
    phone: string,
    password: string,
    free: any,
    utmSource: string,
    utmMedium: string,
    utmCampaing: string,
    utmContent: string,
    io?: Io
  ): Promise<User | false> {
    const self = this;
    const call = io ? io : self.api.new().silent();
    return new Promise(async (resolve, reject) => {
      call
        .post("seller/me", {
          email: email,
          name: name,
          phone: phone,
          freeUntil: free,
          password: password,
          utmSource: utmSource,
          utmMedium: utmMedium,
          utmCampaing: utmCampaing,
          utmContent: utmContent,
        })
        .subscribe(async (data) => {
          if (data.success) {
            resolve(await self.login(data.return.email, data.return.password));
          } else {
            reject(data.message);
          }
        });
    });
  }

  async registerUserProgress(
    id: number | null,
    meta: string,
    progress: number,
    maxProgress: number,
    io?: Io
  ): Promise<Response> {
    const self = this;
    const api: Io = io ? io : self.api.new();
    const args = {
      id: id,
      meta: meta,
      progress: progress,
      maxProgress: maxProgress,
    };
    const update = await api
      .put("me/userprogress", args)
      .pipe(take(1))
      .toPromise();
    return update;
  }

  loginFacebook(io?: Io): Promise<User | false> {
    const self = this;
    const call = io ? io : self.api.new().silent();
    return new Promise(async (resolve, reject) => {
      self.socialAuthService
        .signIn(FacebookLoginProvider.PROVIDER_ID)
        .then((user) => {
          call
            .get("seller/me/facebook/" + user.authToken)
            .subscribe(async (data) => {
              if (data.success) {
                resolve(
                  await self.login(data.return.email, data.return.password)
                );
              } else {
                reject(data.message);
              }
            });
        });
    });
  }

  async login(email: string, password: string, io?: Io): Promise<User | false> {
    const self = this;
    await localStorage.setItem(
      config.me.cache_name,
      JSON.stringify({ email: email, password: password })
    );
    return await self.get(false, io).pipe(take(1)).toPromise();
  }

  async logout() {
    return await localStorage.removeItem(config.me.cache_name);
  }

  recoverPassword(email: string, io?: Io) {
    const self = this;
    const api: Io = io ? io : self.api.new().set("success", true);
    return api.get("seller/me/recover-password/" + email);
  }

  updatePassword(
    form: { password: string; newPassword: string; confirmPassword: string },
    io?: Io
  ) {
    const self = this;
    const api: Io = io ? io : self.api.new().set("success", true);
    const post = api.put("seller/me/change-password", form);

    post.subscribe(async (data) => {
      if (data.success) {
        await localStorage.setItem(
          config.me.cache_name,
          JSON.stringify(data.return)
        );
      }
    });

    return post;
  }

  hasPermission(permission: string, field?: string, key?: string) {
    const self = this;
    return Observable.create((observer: Observer<boolean>) => {
      self.get().subscribe((data) => {
        if (!data) {
          observer.next(false);
        } else {
          let returned = false;

          if (data.master) {
            returned = true;
            observer.next(true);
          }

          for (let item of data.Permissions) {
            let _return = false;

            if (item.permission == permission || item.permission == `MASTER`) {
              _return = true;
            }

            if (field) {
              _return = item.field == field;
            }

            if (key) {
              _return = item.key == key;
            }

            if (_return) {
              returned = true;
              observer.next(true);
            }
          }

          if (!returned) {
            observer.next(false);
          }
        }
      });
    });
  }
}
