<ngx-spinner></ngx-spinner>
<div class="row">
    <div class="col">
        <div class="card">
            <div class="card-content">
                <div class="card-body">
                    <div *ngIf="product && product.delivery_logo" class="row mb-2">
                        <div class="col text-center">
                          <img style="width: 150px" [src]="product.delivery_logo">
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col">
                            <h2 class="text-bold-500 text-center">{{(invoice && invoice.status=='paid' || loadPreviewContent) ? product && product.isInternalProduct ? 'Parabéns! Você finalizou sua inscrição!' : 'Parabéns, compra concluída' : 'Detalhes da Sua Compra'}}</h2>
                        </div>
                    </div>
                    
                    <div class="row mb-2">
                        <div class="col">
                            <h4 *ngIf="(invoice && invoice.status=='paid') || loadPreviewContent" class="text-bold-400 text-center mb-2">{{product && product.isInternalProduct ? 'Seja bem-vindo(a)' : 'Veja abaixo os detalhes de como acessar o seu produto.'}}</h4>
                            <h4 *ngIf="invoice && invoice.status=='pending'" class="text-bold-400 text-center">Sua compra está aguardando pagamento.</h4>
                                <p *ngIf="(invoice && invoice.status=='paid') || loadPreviewContent" class="text-bold-400 text-center" style="font-size: 18px;">{{product.delivery_instructions ? product.delivery_instructions : ''}}</p>
                                <p *ngIf="(invoice && invoice.status=='paid') && product && product.isInternalProduct || loadPreviewContent && product && product.isInternalProduct" class="text-bold-400 text-center" style="font-size: 18px;">Agora, você só precisa seguir estes dois passos:</p>

                            </div>
                    </div>


                    <div class="row mb-2" *ngIf="((invoice && invoice.status=='paid') || loadPreviewContent) && product && product.delivery_video">
                        <div class="col text-center">
                            <h4 *ngIf="(invoice && invoice.status=='paid') && product && product.isInternalProduct || loadPreviewContent && product && product.isInternalProduct" class="text-bold-600 text-center mb-2">1. Assistir ao vídeo abaixo para receber os treinos:</h4>

                            <ng-template
                                [ngIf]="product.delivery_video.startsWith('https://www.youtube.com/') || product.delivery_video.startsWith('http://www.youtube.com/')">
                                <div id="video" #videoYtb class="auto-resizable-iframe">
                                    <div>
                                        <iframe class="d-none d-md-inline" frameborder="0" allowfullscreen="" [src]="safeURL" style="aspect-ratio: 16/9; width: 40%;"></iframe>
                                        <iframe class="d-md-none" frameborder="0" allowfullscreen="" [src]="safeURL" style="aspect-ratio: 16/9; width: 90%;"></iframe>

                                    </div>
                                </div>
                            </ng-template>
                            <ng-template
                                [ngIf]="!product.delivery_video.startsWith('https://www.youtube.com/') && !product.delivery_video.startsWith('http://www.youtube.com/')">
                                <video #video id="video" controls
                                    style="margin-left: auto; margin-right: auto; max-width: 550px;">
                                    <source [src]="product.delivery_video" type="video/mp4">
                                </video>
                            </ng-template>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="(invoice && invoice.status=='paid') && product && product.isInternalProduct || loadPreviewContent && product && product.isInternalProduct">
                        <div class="col text-center">
                            <h4 class="text-bold-600 text-center mb-2 mt-2">2. Baixar o App O Personal Digital:</h4>
                            <a [href]="product.delivery_link" class="btn btn-lg gradient-mint"  target="_blank">Baixar App Agora</a>
                        </div>
                    </div>
                    <div class="row mb-2" *ngIf="((invoice && invoice.status=='paid') || loadPreviewContent) && product && product.delivery_image">
                        <div class="col text-center">
                            <img style="width: 50%" [src]="product.delivery_image">
                        </div>
                    </div>
                
                <div class="row mb-2" *ngIf="(invoice && invoice.status=='paid') && product && product.isInternalProduct || loadPreviewContent && product && product.isInternalProduct">
                    <div class="col text-center">
                        <p class="text-bold-400 text-center mt-2" style="font-size: 18px;">É muito importante baixar o App O Personal Digital. 
                            É através dele que você vai receber a avaliação inicial para preencher e receber todos os treinos.</p>
                            <p class="text-bold-400 text-center mt-2" style="font-size: 18px;">Você também receberá todas as instruções por e-mail. 
                                Caso o e-mail não apareça na sua Caixa de Entrada, verifique nas abas de Spam e Promoção.</p>
                                <p class="text-bold-400 text-center mt-2" style="font-size: 18px;">Se tiver alguma dúvida, fale com a nossa equipe, clicando no botão abaixo:</p>

                    </div>
                </div>
                <div class="row mb-4">
                    <div class="col text-center">
                        <a *ngIf="(invoice && invoice.status=='paid' && product && !product.isInternalProduct) || (loadPreviewContent  && product && !product.isInternalProduct)" class="btn btn-lg gradient-mint" [href]="product.delivery_link" target="_blank" >Acessar Agora</a>
                        <a *ngIf="(invoice && invoice.status=='paid' && product && product.isInternalProduct) || (product && product.isInternalProduct && loadPreviewContent) " class="btn btn-lg gradient-mint" href="https://wa.me/5583998576703?text=Preciso de ajuda para baixar o App MPD" target="_blank" >Fale conosco no WhatsApp</a>
                        
                        <div *ngIf="invoice && invoice.status == 'pending'">
                            <div *ngIf="pagamentoEscolhido === 'pix'">
                                <app-pix-payment [invoice]="invoice" [pix]="invoice.pix"></app-pix-payment>
                              </div>
                            <div *ngIf="pagamentoEscolhido === 'boleto'">
                              <app-ticket-payment  
                              [invoice]="invoice"
                              [bankSlip]="invoice.bank_slip"></app-ticket-payment>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
            </div>
        </div>
    </div>
