import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ImageCroppedEvent } from "ngx-image-cropper";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-modal-crop",
  templateUrl: "./modal-crop.component.html",
  styleUrls: ["./modal-crop.component.scss"],
})
export class ModalCropComponent implements OnInit {
  @Input() source: string | ArrayBuffer | null = null;
  @Input() aspectRatio: number = 16 / 9;
  @Output() croppedImageEvent = new EventEmitter<string>();

  croppedImage: string | null = null;
  $croppedImage = new BehaviorSubject<string>("");

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  onImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  confirmCrop() {
    try {
      if (this.croppedImage) {
        this.croppedImageEvent.emit(this.croppedImage);
        this.activeModal.dismiss();
      } else {
        console.error("No cropped image available");
      }
    } catch (e) {
      console.log(e);
    }
  }

  dismiss() {
    console.log("Modal dismissed");
    this.activeModal.dismiss();
  }
}
