<div class="card m-0">
  <div class="card-header py-3 bg-primary position-relative">
    <h4 class="card-title text-center white font-medium-4 m-0">Treino</h4>
    <button
      type="button"
      title="Fechar"
      class="btn btn-primary btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
      (click)="close()"
    >
      <i class="fa fa-times font-bold-400 white" aria-hidden="true"></i>
    </button>
  </div>
  <div class="card-content mt-2 px-1 px-md-2">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div
            class="w-100 d-flex flex-column justify-content-center align-items-start"
          >
            <span class="text-small-2 text-bold-500">Exercício Atual:</span>
            <div
              class="w-100 d-flex justify-content-center align-items-center mt-2"
            >
              <span class="w-100 text-secondary text-small-2">{{
                exerciseInput.name
              }}</span>
            </div>
          </div>
          <div
            class="mt-4 w-100 d-flex flex-column justify-content-center align-items-start"
          >
            <span class="text-small-2 text-bold-500">Substituir por:</span>
            <div
              class="w-100 d-flex justify-content-center align-items-center mt-2"
            >
              <div
                class="w-100 position-relative"
                [ngClass]="{
                  'select-open': selectOpen
                }"
              >
                <ng-select
                  id="add-exercises"
                  [items]="exercisesList"
                  [loading]="exercisesLoading"
                  [typeahead]="input$"
                  [notFoundText]="'Nenhum exercício encontrado'"
                  [loadingText]="'Carregando...'"
                  bindLabel="name"
                  placeholder="Selecione um exercício"
                  class="ng-custom-select"
                  [(ngModel)]="selectedNewExercise"
                  (open)="toggleSelect()"
                  (close)="toggleSelect()"
                  (scrollToEnd)="fetchMore(exercise.searchTerm)"
                  #exercise
                >
                  <ng-template ng-option-tmp let-item="item">
                    <span class="font-size-3 font-size-md-5 text-bold-400"
                      >{{ item.name
                      }}<span
                        class="text-muted float-right font-size-3 font-size-md-5 text-bold-400"
                        >{{ item.categoryName }}</span
                      ></span
                    >
                  </ng-template>
                </ng-select>
                <i
                  class="ft-search grey lighten-1 text-bold-600 search-icon__input"
                ></i>
              </div>
            </div>
          </div>
          <div
            *ngIf="!!selectedNewExerciseValue"
            class="mt-4 w-100 d-flex flex-column justify-content-center align-items-start"
          >
            <span class="text-small-2 text-bold-500"
              >Selecione a forma de exibição do exercício:</span
            >
            <div class="preview-container mt-2">
              <ng-template
                [ngIf]="
                  type === 'video' &&
                  (selectedNewExerciseValue.urlVideo ||
                    selectedNewExerciseValue.safeURL)
                "
              >
                <ng-template
                  [ngIf]="
                    selectedNewExerciseValue.urlVideo.startsWith(
                      'https://www.youtube.com/'
                    ) ||
                    selectedNewExerciseValue.urlVideo.startsWith(
                      'http://www.youtube.com/'
                    )
                  "
                >
                  <div class="embed-container">
                    <iframe
                      [src]="selectedNewExerciseValue.safeURL"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </ng-template>
                <ng-template
                  [ngIf]="
                    !selectedNewExerciseValue.urlVideo.startsWith(
                      'https://www.youtube.com/'
                    ) &&
                    !selectedNewExerciseValue.urlVideo.startsWith(
                      'http://www.youtube.com/'
                    )
                  "
                >
                  <video controls style="width: 100%">
                    <source
                      [src]="selectedNewExerciseValue.urlVideo"
                      type="video/mp4"
                    />
                  </video>
                </ng-template>
              </ng-template>
              <ng-template
                [ngIf]="type === 'gif' && selectedNewExerciseValue.urlGif"
              >
                <img
                  style="width: 100%"
                  [src]="selectedNewExerciseValue.urlGif"
                />
              </ng-template>
            </div>
            <div
              id="media-type"
              class="input-group w-100 d-flex justify-content-center align-items-center mt-2"
            >
              <div
                class="radio radio-sm d-inline-block mr-3"
                [ngbTooltip]="
                  !selectedNewExerciseValue.urlGif
                    ? 'Não há gif para este exercício'
                    : ''
                "
              >
                <input
                  id="gif-switch"
                  type="radio"
                  name="media-type"
                  [disabled]="!selectedNewExerciseValue.urlGif"
                  [checked]="type === 'gif'"
                  (click)="setMediaType('gif')"
                />
                <label for="gif-switch" class="normal-case cursor-pointer"
                  >Gif</label
                >
              </div>
              <div
                class="radio radio-sm d-inline-block"
                [ngbTooltip]="
                  !selectedNewExerciseValue.urlVideo
                    ? 'Não há vídeo para este exercício'
                    : ''
                "
              >
                <input
                  id="video-switch"
                  type="radio"
                  name="media-type"
                  [disabled]="!selectedNewExerciseValue.urlVideo"
                  [checked]="type === 'video'"
                  (click)="setMediaType('video')"
                />
                <label for="video-switch" class="normal-case cursor-pointer"
                  >Vídeo</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row mb-0">
      <div class="col">
        <div
          class="w-100 d-flex justify-content-between align-items-center px-0 px-md-3"
        >
          <button
            type="button"
            title="Cancelar"
            class="btn btn-outline-primary text-primary px-4"
            (click)="close()"
          >
            Cancelar
          </button>
          <button
            type="button"
            title="Substituir exercício"
            class="btn btn-primary px-4 white"
            (click)="save()"
          >
            Substituir
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
