import { Injectable } from "@angular/core";
import Api from "app/helpers/api";
import { calculateSHA1, fileToArrayBuffer } from "app/util/uploadImage";
import { Observable } from "rxjs";
import {
  BackBlazeUploadResponse,
  BlackBazeHeaderType,
  BlackBazeOptions,
  BlackBazeUploadConfig,
  BlackBazeUploadUrlResponseType,
  CloudStorageServiceInterface,
} from "./cloud-storage.types";

@Injectable()
export class CloudStorageService implements CloudStorageServiceInterface {
  apiCall = this.api.new().silent();

  constructor(private api: Api) {}

  private makeUploadUrl({ fileType, fileExt, SHA1 }: BlackBazeOptions) {
    return new Observable<BlackBazeUploadConfig | null>((observer) => {
      let headers: BlackBazeHeaderType | null = {
        Authorization: "",
        "Content-Type": fileType || "",
        "X-Bz-File-Name": "",
        "X-Bz-Content-Sha1": SHA1 || "",
      };

      this.apiCall
        .get<BlackBazeUploadUrlResponseType | null>("blackbaze/urltoupload")
        .subscribe((response) => {
          const res = response.return;

          if (res) {
            headers = {
              Authorization: res.authorizationToken || "",
              "Content-Type": fileType || "",
              "X-Bz-File-Name": res.uniqid.toString() + `.${fileExt}` || "",
              "X-Bz-Content-Sha1": SHA1 || "",
            };

            const obj = {
              headers,
              uploadOptions: res,
            };

            observer.next(obj);
            observer.complete();
          } else {
            observer.next(null);
            observer.complete();
          }
        });
    });
  }

  public getUrl() {}

  public upload(
    body: File,
    options: Pick<BlackBazeOptions, "fileType" | "fileExt">
  ) {
    return new Observable<string | null>((observer) => {
      fileToArrayBuffer(body).subscribe(
        (arrayBuffer) => {
          this.makeUploadUrl({
            ...options,
            SHA1: calculateSHA1(arrayBuffer),
          }).subscribe((blackbaze) => {
            if (blackbaze) {
              const uploadHeaders = { headers: blackbaze.headers };

              this.api.http
                .post<BackBlazeUploadResponse | null>(
                  blackbaze.uploadOptions.uploadUrl,
                  body,
                  uploadHeaders
                )
                .subscribe(
                  (response) => {
                    if (response) {
                      const blackBazeBaseUrl =
                        "https://files.wecodde.com/file/wecodde/";
                      const fileName = response?.fileName || "";

                      const url =
                        fileName && fileName.length > 0
                          ? blackBazeBaseUrl + fileName
                          : null;

                      observer.next(url);
                      observer.complete();
                    } else {
                      observer.next(null);
                      observer.complete();
                    }
                  },
                  () => {
                    observer.next(null);
                    observer.complete();
                  }
                );
            } else {
              observer.next(null);
              observer.complete();
            }
          });
        },
        () => {
          observer.next(null);
          observer.complete();
        }
      );
    });
  }

  public delete() {}
}
