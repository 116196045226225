import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../shared/auth/auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../../../helpers/me";
import {SocialAuthService} from "angularx-social-login";
import App from "../../../helpers/app";
import Api from "../../../helpers/api";
import {take} from "rxjs/operators";
import {AlunoAvaliacaoComponent} from "../../../aluno/pages/aluno-avaliacao/aluno-avaliacao.component";
import {PersonalAvaliacaoComponent} from "./pages/personal-avaliacao/personal-avaliacao.component";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-avaliacoes',
  templateUrl: './avaliacoes.component.html',
  styleUrls: ['./avaliacoes.component.scss']
})
export class AvaliacoesComponent implements OnInit {
  apiCall = this.api.new().silent();
  user = null;
  avaliations = []
  perguntasPadrao = []
  arquivosPadrao = []
  relations = []
  relationsFiltered = []
  groups = []
  urlToPlay = null
  safeURL = null

  grupoSelecionado
  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private me: Me,
              private socialAuthService: SocialAuthService,
              private _sanitizer: DomSanitizer,
              private app: App,
              private api: Api) {
    this.me.get().pipe(take(1)).toPromise().then((user)=>{
      this.user = user
    })
  }
  async ngOnInit() {

    const self = this
    self.groups = []
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    self.apiCall.get('seller/avaliations').subscribe(async data => {
      if(data.return){
        let allAvaliations = []
        let defaultAvaliation = data.return.avaliations.filter(x => x.type == 2)
        let othersAvaliations = data.return.avaliations.filter(x => x.type != 2)
        if(defaultAvaliation){
          for (let d of defaultAvaliation){
            allAvaliations.push(d)
          }
        }
        if(othersAvaliations){
          for (let o of othersAvaliations){
            allAvaliations.push(o)
          }
        }
        self.avaliations = allAvaliations
      }
      self.spinner.hide()
    });
    self.apiCall.get('seller/avaliations/padrao').subscribe(async data => {
      if(data.return){
        self.perguntasPadrao = data.return.perguntas
        self.arquivosPadrao = data.return.arquivos
      }
      self.spinner.hide()
    });
    self.apiCall.get('sellercustomer' ).subscribe(async data => {
      if(data.return) this.relations = data.return.relations
    });
    const groups = await self.apiCall.get('groupcustomers' ).pipe(take(1)).toPromise()
    if(groups.return) self.groups = groups.return
  }



  duplicarAvaliacao(avaliation){
    const self = this
    self.app.confirm('Confirmação', 'Você deseja duplicar esta avaliação?').then(d=>{
      if(d){
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        self.apiCall.post('avaliation/duplicate', {avaliation : avaliation}).subscribe(async data => {
          self.spinner.hide()
          if(data.success){
            self.ngOnInit()
            await this.app.alert('Tudo certo!',  'Avaliação duplicada com sucesso', 'success')
          }else{
            await this.app.alert('Ops :(', data.message, 'error')
          }
        });
      }
    })
  }
  alunosSelecionadosParaExportarAvaliacao = []
  avaliationToExport = null
  exportandoAvaliacaoAlunos = false
  async exportarAvaliacaoAluno(avaliation, modal) {
    this.exportandoAvaliacaoAlunos = true
    this.avaliationToExport = avaliation
    this.relationsFiltered = this.relations
    this.grupoSelecionado = null
    this.modalService.open(modal, { centered: true });


  }
  async cancelaExportacaoAvaliacaoAlunos(modal) {
    this.exportandoAvaliacaoAlunos = false
    this.avaliationToExport = null
    this.alunosSelecionadosParaExportarAvaliacao = []
    this.grupoSelecionado = null
    modal.close('Close click')
  }

  async exportarAvaliacaoParaAlunos(modal) {
    const self = this
    if (self.alunosSelecionadosParaExportarAvaliacao.length > 7) {
      const confirmacao = await self.app.confirm('Tem certeza?', 'Exportar para mais de 7 alunos pode levar até 1 hora. Para exportar imediatamente exporte para até 7 alunos. Deseja continuar com a exportação para todos os alunos?');
      if (!confirmacao) {
        return;
      }
    }
    if(self.avaliationToExport && self.alunosSelecionadosParaExportarAvaliacao && self.alunosSelecionadosParaExportarAvaliacao.length>0){
      self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      self.apiCall.post('avaliation/export/customer', {avaliation : self.avaliationToExport, relations: self.alunosSelecionadosParaExportarAvaliacao}).subscribe(async data => {
        self.spinner.hide()
        if(data.success){
          await this.cancelaExportacaoAvaliacaoAlunos(modal)
          self.ngOnInit()
          await this.app.alert('Tudo certo!',  data.message, 'success')
        }else{
          await this.app.alert('Ops :(', data.message, 'error')
        }
      });
    }
  }
  copiarAlunosGrupoExpAvaliacao(){
    if(!this.grupoSelecionado || !this.grupoSelecionado.CustomersGroupsUsers){
      return
    }
    for(let t of this.grupoSelecionado.CustomersGroupsUsers){
      const found = this.relations.find(x=>x.id==t.relation)
      if(found){
        let verify = null
        if(this.alunosSelecionadosParaExportarAvaliacao && this.alunosSelecionadosParaExportarAvaliacao.length>0){
          this.alunosSelecionadosParaExportarAvaliacao.find(x=>x.id==found.id)
        }
        if(!verify){
          this.alunosSelecionadosParaExportarAvaliacao.push(found)
        }
      }
    }
    this.alunosSelecionadosParaExportarAvaliacao = [...this.alunosSelecionadosParaExportarAvaliacao]
  }
  deleteAvaliation(avaliation){
    const self = this
    self.app.confirm('Tem certeza?', 'Você realmente deseja deletar esta avaliação?').then(d=>{
      if(d){
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        self.apiCall.delete('avaliations/0/'+avaliation.id).subscribe(async data => {
          self.spinner.hide()
          if(data.success){
            self.ngOnInit()
            await this.app.alert('Tudo certo!',  'Avaliação deletada com sucesso', 'success')
          }else{
            await this.app.alert('Ops :(', data.message, 'error')
          }
        });
      }
    })
  }
  openAvaliacaoComponent(avaliation, type) {
    if(type==2 && !avaliation){
      const search = this.avaliations.find(a=>a.type==2)
      if(search) avaliation = search
    }
    const modalRef = this.modalService.open(PersonalAvaliacaoComponent, { size: 'lg', backdrop : 'static', keyboard : false });
    if(avaliation) modalRef.componentInstance.avaliation = avaliation;
    modalRef.componentInstance.perguntasPadrao = this.perguntasPadrao;
    modalRef.componentInstance.arquivosPadrao = this.arquivosPadrao;
    modalRef.componentInstance.type = type;

    modalRef.result.then((data) => {
      // on close
      this.ngOnInit()
    }, (reason) => {
      // on dismiss
    });
  }

  async tornarAvaliacaoPadrao(avaliation) {
    const self = this
    if(avaliation){
      self.app.confirm('Tem certeza?', 'Você realmente deseja tornar esta avaliação padrão?').then(d => {
        if (d) {
          self.spinner.show(undefined,
            {
              type: 'ball-triangle-path',
              size: 'medium',
              bdColor: 'rgba(0, 0, 0, 0.8)',
              color: '#fff',
              fullScreen: true
            });
          self.apiCall.post('avaliation/tornarPadrao', {avaliation : avaliation}).subscribe(async data => {
            self.spinner.hide()
            if(data.success){
              self.ngOnInit()
              await this.app.alert('Tudo certo!',  'Esta avaliação se tornou padrão', 'success')
            }else{
              await this.app.alert('Ops :(', data.message, 'error')
            }
          });
        }
      })
    }
  }

  onSelectAll() {
    this.alunosSelecionadosParaExportarAvaliacao = this.relations
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  viewMidia(urlToPlay, content){
    this.urlToPlay = urlToPlay
    if(urlToPlay.startsWith('https://www.youtube.com/watch?')){
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(urlToPlay.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));

    }else if(urlToPlay.startsWith('http://www.youtube.com/watch?')){
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(urlToPlay.replace('http://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));
    }
    this.modalService.open(content, { centered: true , size:'lg'});
  }

}
