import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SocialAuthService } from "angularx-social-login";
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from 'sweetalert2';
import Api from "../helpers/api";
import App from "../helpers/app";
import Me from "../helpers/me";
import { AuthService } from "../shared/auth/auth.service";


@Component({
  selector: 'app-admin-opd-academy-aulas',
  templateUrl: './admin-opd-academy-aulas.component.html',
  styleUrls: ['./admin-opd-academy-aulas.component.scss']
})
export class AdminOpdAcademyAulasComponent implements OnInit {
  apiCall = this.api.new().silent();
  aulas = []
  page = 1
  pageSize = 10
  aula = {
    id : null,
    category : null,
    order : null,
    title : null,
    description : null,
    date : null,
    thumbnailUrl : null,
    videoUrl : null,
    status : null,
  }
  categorias = []
  categoriaSelecionada = null
  thumbnail_image: File;
  thumbnail_url: any;
  thumbnail_image_sha1: any;
  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private me: Me,
              private socialAuthService: SocialAuthService,
              private app: App,
              private api: Api) {
  }
  async ngOnInit() {
    const self = this

    self.resetAula()
    self.aulas = []

    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const dataOpdAcademy = await self.apiCall.get('me/opdacademy').pipe(take(1)).toPromise()
    const dataOpdAcademyCategory = await self.apiCall.get('me/opdacademycategory').pipe(take(1)).toPromise()
    if(dataOpdAcademy.return) self.aulas = dataOpdAcademy.return
    const x = 2

    self.categorias = dataOpdAcademyCategory.return
    //self.categoriaSelecionada = this.categorias.find(a => a.id == x)
    console.log("self.categorias", self.categorias)
    // self.categorias = self.aulas.filter((item: any) => item.OpdAcademyCategory.id === x);
    await self.spinner.hide()

    console.log("data", dataOpdAcademy.return)

  }
  async inserirOuAtualizarAula(modal){
    const self = this

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    let chamada

    if (self.thumbnail_image) {
      const dataUrl = await self.apiCall.get('blackbaze/urltoupload').pipe(take(1)).toPromise()
      console.log("URL", dataUrl)
      if (!dataUrl.return) {
        self.spinner.hide()
        return
      }
      if (dataUrl.return && dataUrl.return.authorizationToken && dataUrl.return.uploadUrl && dataUrl.return.uniqid) {
        var optionsB2 = { headers: { 'Authorization': dataUrl.return.authorizationToken, 'Content-Type': this.thumbnail_image.type, 'X-Bz-File-Name': dataUrl.return.uniqid.toString() + '.jpeg', 'X-Bz-Content-Sha1': this.thumbnail_image_sha1 } };
        const returnedUpload = await self.api.http.post(dataUrl.return.uploadUrl, this.thumbnail_image, optionsB2).toPromise()
        if (returnedUpload) self.aula.thumbnailUrl = 'https://files.wecodde.com/file/wecodde/' + JSON.parse(JSON.stringify(returnedUpload)).fileName
      }
    }

    this.aula.category = this.aula.category.id
    this.aula.date = this.formatToDate(this.aula.date)
    let body = {
      Aulas : this.aula
    }
    if(this.aula.id){
      chamada = this.apiCall.put('me/opdacademy', body)

    }else{
      chamada = this.apiCall.post('me/opdacademy', body)

    }
    chamada.subscribe(async data => {
      if(data.success){
        this.spinner.hide()
        await this.app.alert('Tudo certo!', this.aula.id ? 'Aula atualizada com sucesso' : 'Aula cadastrada com sucesso', 'success')
        modal.close('Close click')
        this.ngOnInit()
      }else{
        await this.app.alert('Ops :(', data.message, 'error')
        this.spinner.hide()
      }
    });
  }

  async onDeleteAula(aula){
    const self = this
    self.app.confirm('Tem certeza?', 'Você realmente deseja deletar esta aula?').then(d=>{
      if(d){
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        self.apiCall.delete('me/opdacademy/'+aula.id).subscribe(async data => {
          self.spinner.hide()
          if(data.success){
            self.ngOnInit()
            await this.app.alert('Tudo certo!',  'Aula deletada com sucesso', 'success')
          }else{
            await this.app.alert('Ops :(', data.message, 'error')
          }
        });
      }
    })
  }

  openAula(aula, modal){
    const self = this
    this.resetAula()
    if(aula){
      this.aula = {...aula}
      this.aula.category = this.categorias.find(a => a.id == aula.OpdAcademyCategory.id)
      this.aula.date = moment(this.aula.date).format('DD/MM/YYYY')
      this.thumbnail_url = aula.thumbnailUrl
    }
    const modalRef = this.modalService.open(modal);
  }
  async switchAula(aula){
    const self = this
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if(aula && aula.id){
      aula.status = !aula.status
      self.apiCall.put('me/opdacademy', {
        Aulas : aula
      }).subscribe(async data => {
        await self.spinner.hide()
        await self.ngOnInit()
        if(data.success){
          await this.app.alert('Tudo certo!',  aula.status? 'Aula ativada com sucesso' : 'Aula inativada com sucesso', 'success')
        }else{
          await this.app.alert('Ops :(', data.message, 'error')
        }
      });
    }
  }
  resetAula(){
    this.aula = {
      id : null,
      category : null,
      order : null,
      title : null,
      description : null,
      date : null,
      thumbnailUrl : null,
      videoUrl : null,
      status : null,
    }
    this.thumbnail_url = null
  }

  formatDateWithoutTime(date){
    return moment(date).format('DD/MM/YYYY')
  }

  formatToDate(date){
    if(date){
      return moment(date, 'DDMMYYYY').toDate()
    }else{
      return ''
    }
  }

  async selecionarImagemProduto() {
    const self = this
    let { value: file } = await Swal.fire({
      title: 'Imagem do Produto',
      input: 'file',
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        'accept': 'image/*',
        'aria-label': 'Faça upload de uma imagem.'
      },
      inputValidator: async (value) => {
        if (!value) {
          return 'Você precisa escolher uma imagem!'
        }
        // @ts-ignore
        let file: File = value

        if (file.size > 30000000) {
          return 'São permitidos imagens de até 30MB!'
        }
        if (file.type != 'image/png' && file.type != 'image/jpg' && file.type != 'image/jpeg') {
          return 'São permitidos apenas imagens!'
        }
        self.thumbnail_image = file
        const base64 = await self.fileToBase64(file)
        if (base64) self.thumbnail_url = base64

        var reader = new FileReader(); //define a Reader
        reader.onload = function (f) {
          var file_result = this.result; // this == reader, get the loaded file "result"
          var file_wordArr = CryptoJS.lib.WordArray.create(file_result as ArrayBuffer); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
          var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
          self.thumbnail_image_sha1 = sha1_hash.toString()

        };
        reader.readAsArrayBuffer(file); //read file as ArrayBuffer

      }
    })
  }

  async fileToBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

}
