import Api from "../../helpers/api";
import App from "../../helpers/app";
import { Subject, Subscription } from "rxjs";
import { CARD_ICONS } from "./card-icons";
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";
import { Component, OnInit } from "@angular/core";
import { SharedService } from "../../shared/services/shared.service";
import { PaymentMethodService } from "../../shared/services/metodo-pagamento.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-cartoes",
  templateUrl: "./cartoes.component.html",
  styleUrls: ["./cartoes.component.scss"],
})
export class CartoesComponent implements OnInit {
  apiCall = this.api.new().silent();
  user: any;
  cartoes: any[];
  userId: string;
  listaCartoes: any;
  subscriptions: any;
  cardIcons = CARD_ICONS;
  userSubscription: Subscription;
  subscriptionsSubscription: Subscription;

  constructor(
    private app: App,
    private api: Api,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private sharedService: SharedService,
    private paymentMethodService: PaymentMethodService
  ) {}

  private destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.sharedService
      .getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user) => {
        this.user = user;
        if (this.user) {
          this.credCartList(this.user);
        }
      });

    this.sharedService
      .getSubscriptions()
      .pipe(takeUntil(this.destroy$))
      .subscribe((subscriptions) => {
        this.subscriptions = subscriptions;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.sharedService.clearUserCache();
  }

  credCartList(user: any): void {
    if (this.listaCartoes && this.listaCartoes.userId === user.id) {
      this.spinner.hide();
      return;
    }

    this.apiCall
      .get(`backoffice/${user.iugu_2}/financial/profile/cards`)
      .subscribe(
        (data) => {
          this.cartoes = data.return.payment_methods.map((method: any) => ({
            id: method.id,
            ...method.data,
          }));
          this.paymentMethodService.setPaymentMethod(this.cartoes);
          this.listaCartoes = { ...data.return, userId: this.user.id };
        },
        (error) => {
          console.log("Erro ao obter cartões:", error);
          this.spinner.hide();
        }
      );
  }

  getCardIcon(brand: string): string {
    return this.cardIcons[brand] || "fas fa-credit-card";
  }

  markAsPrimary(cartaoId: string): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const body = {
      customerId: this.user.id,
      paymentMethodId: cartaoId,
    };
    this.apiCall.put(`backoffice/financial/paymentmethod`, body).subscribe(
      (response) => {
        this.listaCartoes.default_payment_method_id = cartaoId;
        console.log("cartaoId", cartaoId);
        if (response.success) {
          this.toastr.success(
            "Cartão principal atualizada com sucesso",
            "Tudo Certo!"
          );
          this.spinner.hide();
        }
      },
      (error) => {
        console.log("Erro ao marcar como principal:", error);
      }
    );
  }

  removePrimary(cartaoId: string): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const body = {
      customerId: this.user.id,
      paymentMethodId: null,
    };
    this.apiCall.put(`backoffice/financial/paymentmethod`, body).subscribe(
      (response) => {
        if (
          this.listaCartoes.default_payment_method_id === cartaoId &&
          response.success
        ) {
          this.listaCartoes.default_payment_method_id = null;
          this.toastr.success(
            "Cartão removido de principal com sucesso",
            "Tudo Certo!"
          );
          this.toastr.success(
            "Cartão removido de principal com sucesso",
            "Tudo Certo!"
          );
        }
        this.spinner.hide();
      },
      (error) => {
        console.log("Erro ao remover de principal:", error);
      }
    );
  }

  deleteCard(method: any): void {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const body = {
      customerId: this.user.id,
      paymentMethodId: method.id,
    };
    this.apiCall
      .delete(
        `backoffice/financial/paymentmethod/${body.customerId}/${body.paymentMethodId}`
      )
      .subscribe(
        (response) => {
          this.spinner.hide();
          if (response.error == true) {
            this.app.alert(
              "Erro",
              "Ocorreu um erro ao deletar o cartão, tente novamente.",
              "error"
            );
          } else {
            this.app.alert("Cartão Removido!", "", "success");
            this.cartoes = this.cartoes.filter((card) => card.id !== method.id);
          }
        },
        (error) => {
          console.log("Erro ao deletar o cartão:", error);
        }
      );
  }
}
