import { Component, OnChanges, Input, SimpleChanges } from "@angular/core";
import { Faturamento } from "app/personal/pages/finance-reports/graphics.types";
@Component({
  selector: "app-invoicing-chart",
  templateUrl: "./invoicing-chart.component.html",
  styleUrls: ["./invoicing-chart.component.scss"],
})
export class InvoicingChartComponent implements OnChanges {
  @Input() data: Faturamento[] | undefined;
  public chartOptions: any = {};

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data && this.data) {
      this.updateChart();
    }
  }

  private updateChart(): void {
    if (this.data && this.data.length > 0) {
      const seriesData = this.data.map((f) => f.amount);
      const categories = this.data.map((f) => f.date);
      if (seriesData && categories) {
      }
      this.chartOptions = {
        series: [
          {
            name: "Faturamento",
            data: seriesData,
          },
        ],
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        colors: ["#FF7F0E"],
        xaxis: {
          categories: categories,
        },
        title: {
          text: "Faturamento",
          align: "left",
        },
      };
    }
  }
}
