import { Component, Input, OnInit } from '@angular/core';
import { DragulaService } from "ng2-dragula";
import { Subscription } from "rxjs";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "../../../helpers/api";
import { NgxSpinnerService } from "ngx-spinner";
import App from "../../../helpers/app";
import { saveAs } from "file-saver";

@Component({
  selector: 'app-aluno-avaliacao-novo',
  templateUrl: './aluno-avaliacao.component.html',
  styleUrls: ['./aluno-avaliacao.component.scss']
})
export class AlunoAvaliacaoComponent implements OnInit {
  apiCall = this.api.new().silent();

  BAG = "bag-one";
  subs = new Subscription();
  listaPerguntas = []
  listaResultados = []
  listaPerguntasDeletadas = []
  @Input() public relation;
  @Input() public avaliation;
  @Input() public canEdit;
  @Input() public perguntasPadrao;
  @Input() public arquivosPadrao;
  @Input() public type;
  avaliationsModels = []
  avaliationsModelSelecionada = null
  listaRequisicoes = []
  listaRequisicoesDeletadas = []
  tipos = [
    { id: 1, name: 'Digite a resposta' },
    { id: 2, name: 'Se sim digite a resposta / Não' },
    { id: 3, name: 'Sim/Não' },
  ];

  tiposFiles = [
    { id: 'image', name: 'Anexar Imagens ou Fotos' },
    { id: 'file', name: 'Anexar Arquivos' },
  ];
  idAvaliacao = null
  idPergunta = 0
  idRequisicao = 0
  tipoSelecionado = null
  isPerguntaObrigatoria = true
  isEnviarParaAluno = false
  isRequisicaoObrigatoria = true
  tipoFileSelecionado = null
  idPerguntaEditada = null
  idRequisicaoEditada = null
  perguntaParaAdicionar = null
  requisicaoParaAdicionar = null
  nomeAvaliacao = null
  urlToShow = null
  tipoAvaliacaoSelecionada = null
  flagAvaliacaoDobras = false
  flagAvaliacaoDobrasCompleta = false
  mensagemAvaliacao = null
  tipoAvaliacao = [
    {
      "id": 1,
      "name": "Anamnese"
    },
    {
      "id": 2,
      "name": "Polock 07 dobras Homem"
    },
    {
      "id": 3,
      "name": "Polock 07 dobras Mulher"
    },
    {
      "id": 4,
      "name": "Polock 03 dobras Homem"
    },
    {
      "id": 5,
      "name": "Polock 03 dobras Mulher"
    },
    // {
    //   "id" : 6,
    //   "name" : "Dotson e Davis Homem"
    // },
    // {
    //   "id" : 7,
    //   "name" : "Dotson e Davis Mulher"
    // },
    {
      "id": 8,
      "name": "Bioimpedância"
    },
    {
      "id": 9,
      "name": "Perímetros"
    }
  ]

  avaliacaoSelecionada = null

  polock07Dobras = {
    questions: [
      { question: 'Idade', measure: 'anos', questionType: 1, order: 1, required: true, response: null },
      { question: 'Peso', measure: 'Kg', questionType: 1, order: 2, required: true, response: null },
      { question: 'Altura', measure: 'm', questionType: 1, order: 3, required: true, response: null },
      { question: 'Subescapular', measure: 'mm', questionType: 1, order: 4, required: true, response: null },
      { question: 'Tricipital', measure: 'mm', questionType: 1, order: 5, required: true, response: null },
      { question: 'Peitoral', measure: 'mm', questionType: 1, order: 6, required: true, response: null },
      { question: 'Axilar-Média', measure: 'mm', questionType: 1, order: 7, required: true, response: null },
      { question: 'Supra-Ilíaca', measure: 'mm', questionType: 1, order: 8, required: true, response: null },
      { question: 'Abdominal', measure: 'mm', questionType: 1, order: 9, required: true, response: null },
      { question: 'Coxa', measure: 'mm', questionType: 1, order: 10, required: true, response: null },
      { question: 'Meta de Gordura', measure: '%', questionType: 1, order: 11, required: true, response: null },
    ],
    results: [
      {
        name: "Gordura Ideal",
        value: null,
        measure: '%'
      },
      {
        name: "Gordura Atual",
        value: null,
        measure: '%'
      },
      {
        name: "Peso Magro",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Gordo",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Meta",
        value: null,
        measure: 'kg'
      },
      {
        name: "IMC",
        value: null,
        measure: null
      },
    ],
  }

  polock03Dobras = {
    questions: [
      { question: 'Idade', measure: 'anos', questionType: 1, order: 1, required: true, response: null },
      { question: 'Peso', measure: 'Kg', questionType: 1, order: 2, required: true, response: null },
      { question: 'Altura', measure: 'm', questionType: 1, order: 3, required: true, response: null },
      { question: 'Peitoral', measure: 'mm', questionType: 1, order: 4, required: true, response: null },
      { question: 'Abdominal', measure: 'mm', questionType: 1, order: 5, required: true, response: null },
      { question: 'Coxa', measure: 'mm', questionType: 1, order: 6, required: true, response: null },
      { question: 'Meta de Gordura', measure: '%', questionType: 1, order: 7, required: true, response: null },
    ],
    results: [
      {
        name: "Gordura Ideal",
        value: null,
        measure: '%'
      },
      {
        name: "Gordura Atual",
        value: null,
        measure: '%'
      },
      {
        name: "Peso Magro",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Gordo",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Meta",
        value: null,
        measure: 'kg'
      },
      {
        name: "IMC",
        value: null,
        measure: null
      },
    ],
  }

  polock03DobrasM = {
    questions: [
      { question: 'Idade', measure: 'anos', questionType: 1, order: 1, required: true, response: null },
      { question: 'Peso', measure: 'Kg', questionType: 1, order: 2, required: true, response: null },
      { question: 'Altura', measure: 'm', questionType: 1, order: 3, required: true, response: null },
      { question: 'Triciptal', measure: 'mm', questionType: 1, order: 4, required: true, response: null },
      { question: 'Suprailíaca', measure: 'mm', questionType: 1, order: 5, required: true, response: null },
      { question: 'Coxa', measure: 'mm', questionType: 1, order: 6, required: true, response: null },
      { question: 'Meta de Gordura', measure: '%', questionType: 1, order: 7, required: true, response: null },
    ],
    results: [
      {
        name: "Gordura Ideal",
        value: null,
        measure: '%'
      },
      {
        name: "Gordura Atual",
        value: null,
        measure: '%'
      },
      {
        name: "Peso Magro",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Gordo",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Meta",
        value: null,
        measure: 'kg'
      },
      {
        name: "IMC",
        value: null,
        measure: null
      },
    ],
  }

  dotsonEDavisH = {
    questions: [
      { question: 'Idade', measure: 'anos', questionType: 1, order: 1, required: true, response: null },
      { question: 'Peso', measure: 'Kg', questionType: 1, order: 2, required: true, response: null },
      { question: 'Altura', measure: 'm', questionType: 1, order: 3, required: true, response: null },
      { question: 'Circunferência de abdomen', measure: 'mm', questionType: 1, order: 4, required: true, response: null },
      { question: 'Circunferência de pescoço', measure: 'mm', questionType: 1, order: 5, required: true, response: null },
      { question: 'Meta de Gordura', measure: '%', questionType: 1, order: 6, required: true, response: null },
    ],
    results: [
      {
        name: "Gordura Ideal",
        value: null,
        measure: '%'
      },
      {
        name: "Gordura Atual",
        value: null,
        measure: '%'
      },
      {
        name: "Peso Magro",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Gordo",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Meta",
        value: null,
        measure: 'kg'
      },
      {
        name: "IMC",
        value: null,
        measure: null
      },
    ],
  }

  dotsonEDavisM = {
    questions: [
      { question: 'Idade', measure: 'anos', questionType: 1, order: 1, required: true, response: null },
      { question: 'Peso', measure: 'Kg', questionType: 1, order: 2, required: true, response: null },
      { question: 'Altura', measure: 'm', questionType: 1, order: 3, required: true, response: null },
      { question: 'Circunferência de abdomen', measure: 'mm', questionType: 1, order: 4, required: true, response: null },
      { question: 'Circunferência de glúteo', measure: 'mm', questionType: 1, order: 5, required: true, response: null },
      { question: 'Circunferência de pescoço', measure: 'mm', questionType: 1, order: 6, required: true, response: null },
      { question: 'Meta de Gordura', measure: '%', questionType: 1, order: 7, required: true, response: null },
    ],
    results: [
      {
        name: "Gordura Ideal",
        value: null,
        measure: '%'
      },
      {
        name: "Gordura Atual",
        value: null,
        measure: '%'
      },
      {
        name: "Peso Magro",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Gordo",
        value: null,
        measure: 'kg'
      },
      {
        name: "Peso Meta",
        value: null,
        measure: 'kg'
      },
      {
        name: "IMC",
        value: null,
        measure: null
      },
    ],
  }

  bioimpedancia = {
    questions: [
      { question: 'Peso', measure: 'kg', questionType: 1, order: 1, required: true, response: null },
      { question: 'Altura', measure: 'm', questionType: 1, order: 2, required: true, response: null },
      { question: 'Meta de Gordura', measure: '%', questionType: 1, order: 3, required: true, response: null },
      { question: 'Gordura', measure: '%', questionType: 1, order: 4, required: true, response: null },
      { question: 'Músculo Esquelético', measure: '%', questionType: 1, order: 5, required: true, response: null },
      { question: 'Idade Corporal', measure: 'anos', questionType: 1, order: 6, required: true, response: null },
      { question: 'Metabolismo Basal', measure: 'kcal', questionType: 1, order: 7, required: true, response: null },
      { question: 'Gordura Visceral', measure: 'nível', questionType: 1, order: 8, required: true, response: null },
      { question: 'Água Corporal', measure: '%', questionType: 1, order: 9, required: true, response: null },
      { question: 'Massa Muscular', measure: 'kg', questionType: 1, order: 10, required: true, response: null },
      { question: 'Massa Óssea', measure: 'kg', questionType: 1, order: 11, required: true, response: null },
    ],
    results: [],
  }

  perimetros = {
    questions: [
      { question: 'Antebraço', measure: 'D', questionType: 1, order: 1, required: true, response: null },
      { question: 'Antebraço', measure: 'E', questionType: 1, order: 2, required: true, response: null },
      { question: 'Braço Relaxado', measure: 'D', questionType: 1, order: 3, required: true, response: null },
      { question: 'Braço Relaxado', measure: 'E', questionType: 1, order: 4, required: true, response: null },
      { question: 'Braço Contraído', measure: 'D', questionType: 1, order: 5, required: true, response: null },
      { question: 'Braço Contraído', measure: 'E', questionType: 1, order: 6, required: true, response: null },
      { question: 'Coxa Distal', measure: 'D', questionType: 1, order: 7, required: true, response: null },
      { question: 'Coxa Distal', measure: 'E', questionType: 1, order: 8, required: true, response: null },
      { question: 'Coxa Medial', measure: 'D', questionType: 1, order: 9, required: true, response: null },
      { question: 'Coxa Medial', measure: 'E', questionType: 1, order: 10, required: true, response: null },
      { question: 'Coxa Proximal', measure: 'D', questionType: 1, order: 11, required: true, response: null },
      { question: 'Coxa Proximal', measure: 'E', questionType: 1, order: 12, required: true, response: null },
      { question: 'Panturrilha', measure: 'D', questionType: 1, order: 13, required: true, response: null },
      { question: 'Panturrilha', measure: 'E', questionType: 1, order: 14, required: true, response: null },
      { question: 'Abdômen', measure: 'cm', questionType: 1, order: 15, required: true, response: null },
      { question: 'Quadril', measure: 'cm', questionType: 1, order: 16, required: true, response: null },
      { question: 'Cintura', measure: 'cm', questionType: 1, order: 17, required: true, response: null },
      { question: 'Torax', measure: 'cm', questionType: 1, order: 18, required: true, response: null },
      { question: 'Ombro', measure: 'cm', questionType: 1, order: 19, required: true, response: null },
      { question: 'Pescoço', measure: 'cm', questionType: 1, order: 20, required: true, response: null },
      { question: 'Punho', measure: 'cm', questionType: 1, order: 21, required: true, response: null },
      { question: 'Umero', measure: 'cm', questionType: 1, order: 22, required: true, response: null },
      { question: 'Femur', measure: 'cm', questionType: 1, order: 23, required: true, response: null },
    ],
    results: [],
  }

  constructor(private dragulaService: DragulaService, private modalService: NgbModal, public activeModal: NgbActiveModal, private spinner: NgxSpinnerService, private app: App, private api: Api) {

    this.subs.add(dragulaService.drag(this.BAG)
      .subscribe(({ el }) => {
        //this.removeClass(el, 'ex-moved');
      })
    );
    this.subs.add(dragulaService.drop(this.BAG)
      .subscribe(({ el }) => {
        //this.addClass(el, 'ex-moved');
      })
    );
    this.subs.add(dragulaService.over(this.BAG)
      .subscribe(({ el, container }) => {
        //this.addClass(container, 'ex-over');
      })
    );
    this.subs.add(dragulaService.out(this.BAG)
      .subscribe(({ el, container }) => {
        //this.removeClass(container, 'ex-over');
      })
    );


  }

  preencheInputsAvaliacao(avaliation) {
    this.nomeAvaliacao = avaliation.name
    avaliation.Itens.sort(function (a, b) {
      return a.order - b.order;
    });
    avaliation.Files.sort(function (a, b) {
      return a.order - b.order;
    });
    this.listaPerguntas = []
    this.listaResultados = []
    this.listaRequisicoes = []
    for (let i of avaliation.Itens) {
      let obj = {
        id: i.id,
        question: i.question,
        questionType: i.questionType,
        order: i.order,
        response: i.response,
        measure: i.measure,
        required: i.required != null && i.required == true,
        avaliation: i.avaliation,
        labelType: this.tipos.find(t => t.id == i.questionType) ? this.tipos.find(t => t.id == i.questionType).name : '',

      }
      this.listaPerguntas.push(obj)
    }
    if (avaliation.category != 1 && avaliation.category != 8 && avaliation.category != 9 && avaliation.category != null) {
      let results = JSON.parse(avaliation.Results[0].result)
      for (let i of results) {
        let obj = {
          question: i.name,
          response: i.value,
          measure: i.measure,
        }
        this.listaResultados.push(obj)
      }
    }

    for (let f of avaliation.Files) {
      let obj = {
        id: f.id,
        urlFile: f.urlFile,
        typeFile: f.typeFile,
        observationFile: f.observationFile,
        order: f.order,
        required: f.required != null && f.required == true,
        labelType: this.tiposFiles.find(t => t.id == f.typeFile) ? this.tiposFiles.find(t => t.id == f.typeFile).name : '',
        avaliation: f.avaliation,
      };
      this.listaRequisicoes.push(obj)
    }
  }
  ngOnInit() {
    if (!this.avaliation) {
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      this.apiCall.get('seller/avaliations').subscribe(async data => {
        if (data.return) {
          this.avaliationsModels = data.return.avaliations.filter(a => a.type == 1)
        }
        this.spinner.hide()
      });
    }
    if (this.avaliation) {
      this.getMensagemAvaliacao(this.avaliation.category)
      this.tipoAvaliacaoSelecionada = this.tipoAvaliacao.find(a => a.id == this.avaliation.category)
      this.idAvaliacao = this.avaliation.id
      this.preencheInputsAvaliacao(this.avaliation)
    }
    if (!this.relation) {
      this.activeModal.close('Close click')
    }
    this.apiCall.get('seller/avaliations/padrao').subscribe(async data => {
      if (data.return) {
        this.perguntasPadrao = data.return.perguntas
        this.arquivosPadrao = data.return.arquivos
      }
    });
  }
  removerRequisicao(req) {
    const self = this
    if (self.idAvaliacao) {
      req.deletedAt = true
      self.listaRequisicoesDeletadas.push(req)
    }
    const index = self.listaRequisicoes.indexOf(req)
    if (index > -1) {
      self.listaRequisicoes.splice(index, 1)
    }
  }
  removerPergunta(pergunta) {
    const self = this
    if (self.idAvaliacao) {
      pergunta.deletedAt = true
      self.listaPerguntasDeletadas.push(pergunta)
    }
    const index = self.listaPerguntas.indexOf(pergunta)
    if (index > -1) {
      self.listaPerguntas.splice(index, 1)
    }
    this.cancelarEdicaoPergunta()
  }
  onselectModel() {
    if (this.avaliationsModelSelecionada) {
      this.preencheInputsAvaliacao(this.avaliationsModelSelecionada)
      this.tipoAvaliacaoSelecionada = this.tipoAvaliacao.find(a => a.id == 1)
      console.log(this.tipoAvaliacaoSelecionada)
    } else {
      if (!this.idAvaliacao) {
        this.nomeAvaliacao = null
        this.listaPerguntas = []
        this.listaRequisicoes = []
      }
    }
  }
  adicionarPergunta() {
    this.idPergunta++
    let obj = {
      idTemp: this.idPergunta,
      question: this.perguntaParaAdicionar,
      questionType: this.tipoSelecionado.id,
      labelType: this.tipoSelecionado.name,
      required: this.isPerguntaObrigatoria
    };
    this.listaPerguntas.push(obj)
    this.tipoSelecionado = null
    this.perguntaParaAdicionar = null
    this.isPerguntaObrigatoria = true

  }
  downloadFile(url) {

    saveAs(url, name)
  }

  downloadImage(url) {
    const filename = url.substring(url.lastIndexOf('/') + 1);
    saveAs(url, filename)

  }
  liberarEdicaoPergunta(pergunta) {
    if (pergunta) {
      console.log('required', pergunta.required)
      this.idPerguntaEditada = pergunta.id ? pergunta.id : pergunta.idTemp
      this.perguntaParaAdicionar = pergunta.question
      this.isPerguntaObrigatoria = pergunta.required
      this.tipoSelecionado = this.tipos.find(t => t.id == pergunta.questionType)
    }
  }
  editarPergunta() {
    const self = this
    for (let p of self.listaPerguntas) {
      if (p.id == this.idPerguntaEditada || p.idTemp == this.idPerguntaEditada) {
        p.question = this.perguntaParaAdicionar
        p.questionType = this.tipoSelecionado.id
        p.labelType = this.tipoSelecionado.name
        p.required = this.isPerguntaObrigatoria

      }
    }
    this.cancelarEdicaoPergunta()
  }
  cancelarEdicaoPergunta() {
    this.idPerguntaEditada = null
    this.perguntaParaAdicionar = null
    this.tipoSelecionado = null
    this.isPerguntaObrigatoria = true
  }
  changeObrigatoriedadeRequisicao($event) {
    this.isRequisicaoObrigatoria = $event
  }
  changeObrigatoriedadePergunta($event) {
    this.isPerguntaObrigatoria = $event
  }
  duplicarPergunta(pergunta) {
    let newPergunta = { ...pergunta };
    newPergunta.id = null
    this.idPergunta++
    newPergunta.idTemp = this.idPergunta
    this.listaPerguntas.push(newPergunta)
  }



  liberarEdicaoRequisicao(requisicao) {
    if (requisicao) {
      this.idRequisicaoEditada = requisicao.id ? requisicao.id : requisicao.idTemp
      this.requisicaoParaAdicionar = requisicao.observationFile
      this.tipoFileSelecionado = this.tiposFiles.find(t => t.id == requisicao.typeFile)
      this.isRequisicaoObrigatoria = requisicao.required
    }
  }
  editarRequsicao() {
    const self = this
    for (let p of self.listaRequisicoes) {
      if (p.id == this.idRequisicaoEditada || p.idTemp == this.idRequisicaoEditada) {
        p.observationFile = this.requisicaoParaAdicionar
        p.typeFile = this.tipoFileSelecionado.id
        p.labelType = this.tipoFileSelecionado.name
        p.required = this.isRequisicaoObrigatoria
      }
    }
    this.cancelarEdicaoRequisicao()
  }
  cancelarEdicaoRequisicao() {
    this.idRequisicaoEditada = null
    this.requisicaoParaAdicionar = null
    this.tipoFileSelecionado = null
    this.isRequisicaoObrigatoria = true

  }
  duplicarRequisicao(requisicao) {
    let newRequisicao = { ...requisicao };
    newRequisicao.id = null
    this.idRequisicao++
    newRequisicao.idTemp = this.idRequisicao
    this.listaRequisicoes.push(newRequisicao)
  }









  isItImage(url) {
    if (!url) {
      return false
    }
    if (url.toLowerCase().endsWith('png') || url.toLowerCase().endsWith('jpg') || url.toLowerCase().endsWith('jpeg')) {
      return true
    } else {
      return false
    }
  }
  viewMidia(urlToShow, content) {
    this.urlToShow = urlToShow
    this.modalService.open(content, { centered: true });
  }
  adicionarRequisicao() {
    this.idRequisicao++
    let obj = {
      idTemp: this.idRequisicao,
      observationFile: this.requisicaoParaAdicionar,
      typeFile: this.tipoFileSelecionado.id,
      labelType: this.tipoFileSelecionado.name,
      required: this.isRequisicaoObrigatoria,
    };
    this.listaRequisicoes.push(obj)
    this.requisicaoParaAdicionar = null
    this.tipoFileSelecionado = null
    this.isRequisicaoObrigatoria = true

  }

  submitAvaliacao() {
    const self = this

    let body = self.preparaBodyAvaliacao()
    if (body) {
      self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      let chamada

      console.log(body)

      if (self.idAvaliacao) {
        chamada = self.apiCall.put('avaliations/' + self.relation.id, body)
      } else {
        chamada = self.apiCall.post('avaliations', body)
      }
      chamada.subscribe(async data => {
        if (data.success) {
          self.spinner.hide()
          await this.app.alert('Tudo certo!', self.idAvaliacao ? 'Sua avaliação foi atualizada com sucesso' : 'Sua avaliação foi criada com sucesso', 'success')
          this.activeModal.close('Close click')
        } else {
          await this.app.alert('Ops :(', data.message, 'error')
          self.spinner.hide()
        }
      });
    }
  }
  preparaBodyAvaliacao() {
    const self = this
    let req = []
    let per = []

    if (self.idAvaliacao) {
      for (let p of self.listaPerguntas) {
        let obj = {
          id: p.id ? p.id : null,
          question: p.question ? p.question : null,
          questionType: p.questionType ? p.questionType : null,
          order: (self.listaPerguntas.indexOf(p) + 1),
          response: p.response ? p.response : null,
          required: p.required ? p.required : false,
          avaliation: this.idAvaliacao,
        };
        per.push(obj)
      }
      for (let p of self.listaPerguntasDeletadas) {
        let obj = {
          id: p.id ? p.id : null,
          question: p.question ? p.question : null,
          questionType: p.questionType ? p.questionType : null,
          order: p.order,
          deletedAt: p.deletedAt,
          response: p.response ? p.response : null,
          required: p.required ? p.required : false,
          avaliation: self.idAvaliacao,
        };
        per.push(obj)
      }
      for (let r of self.listaRequisicoes) {
        let obj = {
          id: r.id ? r.id : null,
          urlFile: r.urlFile ? r.urlFile : null,
          typeFile: r.typeFile ? r.typeFile : 'image',
          observationFile: r.observationFile ? r.observationFile : null,
          required: r.required ? r.required : false,
          order: (self.listaRequisicoes.indexOf(r) + 1),
          avaliation: self.idAvaliacao,
        };
        req.push(obj)
      }
      for (let r of self.listaRequisicoesDeletadas) {
        let obj = {
          id: r.id ? r.id : null,
          urlFile: r.urlFile ? r.urlFile : null,
          typeFile: r.typeFile ? r.typeFile : 'image',
          observationFile: r.observationFile ? r.observationFile : null,
          required: r.required ? r.required : false,
          order: r.order,
          deletedAt: r.deletedAt,
          avaliation: self.idAvaliacao,
        };
        req.push(obj)
      }

      return {
        avaliation: {
          id: self.idAvaliacao,
          sellerCustomer: self.relation.id,
          type: '0',
          name: self.nomeAvaliacao,
          itens: per,
          files: req,
        }
      }
    } else {
      for (let p of self.listaPerguntas) {
        let obj = {
          order: (self.listaPerguntas.indexOf(p) + 1),
          question: p.question,
          questionType: p.questionType,
          required: p.required
        };
        per.push(obj)
      }
      for (let r of self.listaRequisicoes) {
        let obj = {
          order: (self.listaRequisicoes.indexOf(r) + 1),
          observationFile: r.observationFile,
          typeFile: r.typeFile,
          required: r.required
        };
        req.push(obj)
      }

      return {
        avaliation: {
          sellerCustomer: self.relation.id,
          type: '0',
          name: self.nomeAvaliacao,
          itens: per,
          files: req,
          category: 1
        }
      }
    }
  }

  copyFromPlataforma() {
    // this.nomeAvaliacao = 'Avaliação Padrão'
    this.listaPerguntas = []
    this.listaPerguntasDeletadas = []
    this.listaRequisicoes = []
    this.listaRequisicoesDeletadas = []
    if (this.perguntasPadrao) {
      for (let p of this.perguntasPadrao) {
        let obj = {
          order: p.order,
          question: p.question,
          questionType: p.questionType,
          labelType: this.tipos.find(t => t.id == p.questionType) ? this.tipos.find(t => t.id == p.questionType).name : '',
        }
        this.listaPerguntas.push(obj)
      }
    }
    if (this.arquivosPadrao) {
      for (let p of this.arquivosPadrao) {
        let obj = {
          order: p.order,
          observationFile: p.observationFile,
          typeFile: p.typeFile,
          labelType: this.tiposFiles.find(t => t.id == p.typeFile) ? this.tiposFiles.find(t => t.id == p.typeFile).name : '',
        }
        this.listaRequisicoes.push(obj)
      }
    }
  }

  clearQuestions() {
    this.listaPerguntas = null
    this.listaRequisicoes = null
  }

  getPerguntasAvaliacao(avaliacaoTipo) {
    if (avaliacaoTipo == 1) {


    }
  }

  onChangeAvaliacao() {
    const self = this

    this.avaliacaoSelecionada = null
    let avaliacaoId = this.tipoAvaliacaoSelecionada.id

    if(this.tipoAvaliacaoSelecionada.id == 9) {
      this.flagAvaliacaoDobrasCompleta = true
    } else {
      this.flagAvaliacaoDobrasCompleta = false
    }


    this.getMensagemAvaliacao(avaliacaoId)
    switch (avaliacaoId) {
      case 1:
        this.avaliacaoSelecionada = null
        break;
      case 2:
        this.avaliacaoSelecionada = this.polock07Dobras
        break;
      case 3:
        this.avaliacaoSelecionada = this.polock07Dobras
        break;
      case 4:
        this.avaliacaoSelecionada = this.polock03Dobras
        break;
      case 5:
        this.avaliacaoSelecionada = this.polock03DobrasM
        break;
      case 6:
        this.avaliacaoSelecionada = this.dotsonEDavisH
        break;
      case 7:
        this.avaliacaoSelecionada = this.dotsonEDavisM
        break;
      case 8:
        this.avaliacaoSelecionada = this.bioimpedancia
        break;
      case 9:
        this.avaliacaoSelecionada = this.perimetros
        break;

    }

  }

  getResult() {
    let flag = true
    this.flagAvaliacaoDobrasCompleta = true
    if (this.tipoAvaliacaoSelecionada.id != 9) {
      for (let p of this.avaliacaoSelecionada.questions) {
        if (p.question != 'Meta de Gordura') {
          if (p.response == null || p.response == "") {
            flag = false
            this.flagAvaliacaoDobras = false
          }
        }
        if (p.response == null || p.response == "") {
          this.flagAvaliacaoDobrasCompleta = false
        }
      }
    }
    let soma07Dobras = null
    let soma03Dobras = null
    let abdomen = null
    let pescoco = null
    let gluteo = null
    let alturaPol = null
    let idade = null
    let peso = null
    let altura = null
    let meta = null
    let imc = null
    if (flag) {
      this.flagAvaliacaoDobras = true
      if (this.tipoAvaliacaoSelecionada.id == 2 || this.tipoAvaliacaoSelecionada.id == 3) {
        for (let p of this.avaliacaoSelecionada.questions) {
          if (p.question != "Idade" && p.question != "Peso" && p.question != "Altura" && p.question != "Meta de Gordura") {
            soma07Dobras += parseFloat(p.response)
          }

          if (p.question == "Idade") {
            idade = p.response
          }
          if (p.question == "Peso") {
            peso = p.response
          }
          if (p.question == "Altura") {
            altura = p.response
          }
          if (p.question == "Meta de Gordura") {
            meta = p.response
          }
        }
      } else if (this.tipoAvaliacaoSelecionada.id == 4 || this.tipoAvaliacaoSelecionada.id == 5) {
        for (let p of this.avaliacaoSelecionada.questions) {
          if (p.question != "Idade" && p.question != "Peso" && p.question != "Altura" && p.question != "Meta de Gordura") {
            soma03Dobras += parseFloat(p.response)
          }

          if (p.question == "Idade") {
            idade = p.response
          }
          if (p.question == "Peso") {
            peso = p.response
          }
          if (p.question == "Altura") {
            altura = p.response
          }
          if (p.question == "Meta de Gordura") {
            meta = p.response
          }
        }
      } else if (this.tipoAvaliacaoSelecionada.id == 6 || this.tipoAvaliacaoSelecionada.id == 7) {
        for (let p of this.avaliacaoSelecionada.questions) {
          if (p.question == "Idade") {
            idade = p.response
          }
          if (p.question == "Peso") {
            peso = p.response
          }
          if (p.question == "Altura") {
            altura = p.response
          }
          if (p.question == 'Circunferência de abdomen') {
            abdomen = p.response
          }
          if (p.question == 'Circunferência de pescoço') {
            pescoco = p.response
          } if (this.tipoAvaliacaoSelecionada.id == 7 && p.question == 'Circunferência de glúteo') {
            gluteo = p.response
          }
          if (p.question == "Meta de Gordura") {
            meta = p.response
          }
        }
      }
      //result recebe a formula aqui
      let dc = null
      let gPorcent = null
      let gPorcentClass = null
      let avaliacaoId = this.tipoAvaliacaoSelecionada.id
      altura = parseFloat(altura).toFixed(2)
      altura = altura / 100
      imc = peso / (altura * altura)
      imc = parseFloat(imc).toFixed(2)
      imc = imc + this.getClassIMC(imc)
      let alturaConvert = altura
      switch (avaliacaoId) {
        case 2:
          //polock07DobrasH
          dc = 1.112 - 0.00043499 * (soma07Dobras) + 0.00000055 * (soma07Dobras * soma07Dobras) - 0.00028826 * (idade)
          gPorcent = (4.95 / dc - 4.50) * 100
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'h', idade)
          for (let p of this.avaliacaoSelecionada.results) {
            let pesoGordo = peso * (gPorcent / 100)
            let pesoMeta = peso * (meta / 100)
            if (p.name == "Gordura Ideal") {
              p.value = this.getGorduraIdeal(idade)
            }
            if (p.name == "Gordura Atual") {
              p.value = gPorcentClass
            }
            if (p.name == "Peso Magro") {
              p.value = peso - pesoGordo
            }
            if (p.name == "Peso Gordo") {
              p.value = pesoGordo
            }
            if (p.name == "Peso Meta") {
              p.value = peso - pesoMeta
            }
            if (p.name == "IMC") {
              p.value = imc
            }
          }
          break;
        case 3:
          //polock07DobrasM
          dc = 1.0970 - 0.00046971 * (soma07Dobras) + 0.00000056 * (soma07Dobras * soma07Dobras) - 0.00012828 * (idade)
          gPorcent = (4.95 / dc - 4.50) * 100
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'm', idade)
          for (let p of this.avaliacaoSelecionada.results) {
            let pesoGordo = peso * (gPorcent / 100)
            let pesoMeta = peso * (meta / 100)
            if (p.name == "Gordura Ideal") {
              p.value = this.getGorduraIdeal(idade)
            }
            if (p.name == "Gordura Atual") {
              p.value = gPorcentClass
            }
            if (p.name == "Peso Magro") {
              p.value = peso - pesoGordo
            }
            if (p.name == "Peso Gordo") {
              p.value = pesoGordo
            }
            if (p.name == "Peso Meta") {
              p.value = peso - pesoMeta
            }
            if (p.name == "IMC") {
              p.value = imc
            }
          }
          break;
        case 4:
          //polock03DobrasH
          dc = 1.1093800 - 0.0008267 * (soma03Dobras) + 0.0000016 * (soma03Dobras * soma03Dobras) - 0.0002574 * (idade)
          gPorcent = (4.95 / dc - 4.50) * 100
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'h', idade)
          for (let p of this.avaliacaoSelecionada.results) {
            let pesoGordo = peso * (gPorcent / 100)
            let pesoMeta = peso * (meta / 100)
            if (p.name == "Gordura Ideal") {
              p.value = this.getGorduraIdeal(idade)
            }
            if (p.name == "Gordura Atual") {
              p.value = gPorcentClass
            }
            if (p.name == "Peso Magro") {
              p.value = peso - pesoGordo
            }
            if (p.name == "Peso Gordo") {
              p.value = pesoGordo
            }
            if (p.name == "Peso Meta") {
              p.value = peso - pesoMeta
            }
            if (p.name == "IMC") {
              p.value = imc
            }
          }
          break;
        case 5:
          //polock03DobrasM
          dc = 1.099492 - 0.0009929 * (soma03Dobras) + 0.0000023 * (soma03Dobras * soma03Dobras) - 0.0001392 * (idade)
          gPorcent = (4.95 / dc - 4.50) * 100
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'm', idade)
          for (let p of this.avaliacaoSelecionada.results) {
            let pesoGordo = peso * (gPorcent / 100)
            let pesoMeta = peso * (meta / 100)
            if (p.name == "Gordura Ideal") {
              p.value = this.getGorduraIdeal(idade)
            }
            if (p.name == "Gordura Atual") {
              p.value = gPorcentClass
            }
            if (p.name == "Peso Magro") {
              p.value = peso - pesoGordo
            }
            if (p.name == "Peso Gordo") {
              p.value = pesoGordo
            }
            if (p.name == "Peso Meta") {
              p.value = peso - pesoMeta
            }
            if (p.name == "IMC") {
              p.value = imc
            }
          }
          break;
        case 6:
          //dotsonEDavisH
          alturaConvert = altura * 100
          alturaConvert = parseFloat(alturaConvert).toFixed(2)
          alturaPol = (alturaConvert / 2.54).toFixed(2)
          console.log("alturaPol", alturaPol)
          console.log("abdomen", abdomen)
          console.log("pescoco", pescoco)
          console.log("Math.log(abdomen - pescoco)", Math.log(abdomen - pescoco))

          gPorcent = +((85.20969 * Math.log(abdomen - pescoco)) / 2.54) - ((69.73016 * Math.log(alturaPol)) / 2.54) + 37.26673

          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'h', idade)
          for (let p of this.avaliacaoSelecionada.results) {
            let pesoGordo = peso * (gPorcent / 100)
            let pesoMeta = peso * (meta / 100)
            if (p.name == "Gordura Ideal") {
              p.value = this.getGorduraIdeal(idade)
            }
            if (p.name == "Gordura Atual") {
              p.value = gPorcentClass
            }
            if (p.name == "Peso Magro") {
              p.value = peso - pesoGordo
            }
            if (p.name == "Peso Gordo") {
              p.value = pesoGordo
            }
            if (p.name == "Peso Meta") {
              p.value = peso - pesoMeta
            }
            if (p.name == "IMC") {
              p.value = imc
            }
          }
          break;
        case 7:
          //dotsonEDavisM
          alturaConvert = altura * 100
          alturaConvert = parseFloat(alturaConvert).toFixed(2)
          alturaPol = (alturaConvert / 2.54).toFixed(2)
          gPorcent = +(161.27327 * Math.log(abdomen + gluteo + pescoco)) - (100.81032 * Math.log(alturaPol)) + 69.55016
          gPorcentClass = parseFloat(gPorcent).toFixed(2) + this.getClassGorduraAtual(gPorcent, 'm', idade)
          for (let p of this.avaliacaoSelecionada.results) {
            let pesoGordo = peso * (gPorcent / 100)
            let pesoMeta = peso * (meta / 100)
            if (p.name == "Gordura Ideal") {
              p.value = this.getGorduraIdeal(idade)
            }
            if (p.name == "Gordura Atual") {
              p.value = gPorcentClass
            }
            if (p.name == "Peso Magro") {
              p.value = peso - pesoGordo
            }
            if (p.name == "Peso Gordo") {
              p.value = pesoGordo
            }
            if (p.name == "Peso Meta") {
              p.value = peso - pesoMeta
            }
            if (p.name == "IMC") {
              p.value = imc
            }
          }
          console.log("gPorcent", gPorcent)
          break;
        case 8:
          //bioimpedancia
          break;
        case 8:
          //perimetros
          break;
      }
    }
  }

  getGorduraIdeal(idade) {
    const self = this
    let gorduraIdeal = null
    //Pollock 7 e 3 dobras
    if (self.tipoAvaliacaoSelecionada.id == 2 || self.tipoAvaliacaoSelecionada.id == 3 || self.tipoAvaliacaoSelecionada.id == 4 || self.tipoAvaliacaoSelecionada.id == 5) {
      if (self.tipoAvaliacaoSelecionada.id == 2) {
        if (idade >= 20 && idade <= 29) {
          gorduraIdeal = "14 a 20"
        } else if (idade >= 30 && idade <= 39) {
          gorduraIdeal = "15 a 21"
        } else if (idade >= 40 && idade <= 49) {
          gorduraIdeal = "17 a 23"
        } else if (idade >= 50 && idade <= 59) {
          gorduraIdeal = "18 a 24"
        } else if (idade >= 60) {
          gorduraIdeal = "19 a 25"
        }
      } else {
        if (idade >= 20 && idade <= 29) {
          gorduraIdeal = "20 a 28"
        } else if (idade >= 30 && idade <= 39) {
          gorduraIdeal = "21 a 29"
        } else if (idade >= 40 && idade <= 49) {
          gorduraIdeal = "22 a 30"
        } else if (idade >= 50 && idade <= 59) {
          gorduraIdeal = "23 a 31"
        } else if (idade >= 60) {
          gorduraIdeal = "24 a 32"
        }
      }//DotsonEDavis
    } else if (self.tipoAvaliacaoSelecionada.id == 6 || self.tipoAvaliacaoSelecionada.id == 7) {
      if (self.tipoAvaliacaoSelecionada.id == 6) {
        if (idade >= 6 && idade <= 17) {
          gorduraIdeal = "11 a 25"
        } else if (idade >= 18 && idade <= 34) {
          gorduraIdeal = "13 a 21"
        } else if (idade >= 35 && idade <= 55) {
          gorduraIdeal = "18 a 24"
        } else if (idade >= 55) {
          gorduraIdeal = "16 a 22"
        }
      } else {
        if (idade >= 6 && idade <= 17) {
          gorduraIdeal = "16 a 30"
        } else if (idade >= 18 && idade <= 34) {
          gorduraIdeal = "28 a 34"
        } else if (idade >= 35 && idade <= 55) {
          gorduraIdeal = "32 a 37"
        } else if (idade >= 55) {
          gorduraIdeal = "30 a 34"
        }
      }
    }
    return gorduraIdeal
  }

  getClassIMC(imc) {
    let classIMC = null
    if (imc < 18.5) {
      classIMC = " - Baixo Peso"
    } else if (imc >= 18.5 && imc <= 24.9) {
      classIMC = " - Adequado"
    } else if (imc >= 25 && imc <= 29.9) {
      classIMC = " - Sobrepeso"
    } else if (imc >= 30) {
      classIMC = " - Obesidade"
    }
    return classIMC
  }

  getClassGorduraAtual(gPorcent, sexo, idade) {
    let classGorduraAtual = null
    if (sexo == 'h') {
      if (idade >= 20 && idade <= 29) {
        if (gPorcent < 11) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 11 && gPorcent <= 13) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 14 && gPorcent <= 20) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 21 && gPorcent <= 23) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 23) {
          classGorduraAtual = " - Insuficiente"
        }
      } else if (idade >= 30 && idade <= 39) {
        if (gPorcent < 12) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 12 && gPorcent <= 14) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 15 && gPorcent <= 21) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 22 && gPorcent <= 24) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 24) {
          classGorduraAtual = " - Insuficiente"
        }
      } else if (idade >= 40 && idade <= 49) {
        if (gPorcent < 14) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 14 && gPorcent <= 16) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 17 && gPorcent <= 23) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 24 && gPorcent <= 26) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 26) {
          classGorduraAtual = " - Insuficiente"
        }
      } else if (idade >= 50 && idade <= 59) {
        if (gPorcent < 15) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 15 && gPorcent <= 17) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 18 && gPorcent <= 24) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 25 && gPorcent <= 27) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 27) {
          classGorduraAtual = " - Insuficiente"
        }
      } else if (idade >= 60) {
        if (gPorcent < 16) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 16 && gPorcent <= 18) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 19 && gPorcent <= 25) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 26 && gPorcent <= 28) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 28) {
          classGorduraAtual = " - Insuficiente"
        }
      }
    } else {
      if (idade >= 20 && idade <= 29) {
        if (gPorcent < 16) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 16 && gPorcent <= 19) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 20 && gPorcent <= 28) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 29 && gPorcent <= 31) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 31) {
          classGorduraAtual = " - Insuficiente"
        }
      } else if (idade >= 30 && idade <= 39) {
        if (gPorcent < 17) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 17 && gPorcent <= 20) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 21 && gPorcent <= 29) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 30 && gPorcent <= 32) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 32) {
          classGorduraAtual = " - Insuficiente"
        }
      } else if (idade >= 40 && idade <= 49) {
        if (gPorcent < 18) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 18 && gPorcent <= 21) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 22 && gPorcent <= 30) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 31 && gPorcent <= 33) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 33) {
          classGorduraAtual = " - Insuficiente"
        }
      } else if (idade >= 50 && idade <= 59) {
        if (gPorcent < 19) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 19 && gPorcent <= 22) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 23 && gPorcent <= 31) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 32 && gPorcent <= 34) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 34) {
          classGorduraAtual = " - Insuficiente"
        }
      } else if (idade >= 60) {
        if (gPorcent < 20) {
          classGorduraAtual = " - Excelente"
        } else if (gPorcent >= 20 && gPorcent <= 23) {
          classGorduraAtual = " - Bom"
        } else if (gPorcent >= 24 && gPorcent <= 32) {
          classGorduraAtual = " - Média"
        } else if (gPorcent >= 33 && gPorcent <= 35) {
          classGorduraAtual = " - Regular"
        } else if (gPorcent > 35) {
          classGorduraAtual = " - Insuficiente"
        }
      }
    }
    return classGorduraAtual
  }

  submitWebAvaliacao(responseStatus) {
    const self = this

    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });

    let chamada
    if (responseStatus == false && self.avaliacaoSelecionada.results && self.avaliacaoSelecionada.results.length > 0) {
      for (let r of self.avaliacaoSelecionada.results) {
        r.value = null
      }
    }

    for (let p of self.avaliacaoSelecionada.questions) {
      if (p.question == "Altura") {
        p.response = parseFloat(p.response).toFixed(2)
        p.response = (p.response / 100).toFixed(2)
      }
    }

    const body = {
      avaliation: {
        sellerCustomer: self.relation.id,
        type: '0',
        name: self.tipoAvaliacaoSelecionada.name,
        itens: self.avaliacaoSelecionada.questions,
        results: self.avaliacaoSelecionada.results.length > 0 ? self.avaliacaoSelecionada.results : null
      },
      responseStatus: responseStatus,
      category: self.tipoAvaliacaoSelecionada.id
    }

    chamada = self.apiCall.post('avaliation/webresponse/' + self.relation.id, body)

    chamada.subscribe(async data => {
      if (data.success) {
        self.spinner.hide()
        await this.app.alert('Tudo certo!', self.idAvaliacao ? 'Sua avaliação foi atualizada com sucesso' : 'Sua avaliação foi criada com sucesso', 'success')
        this.activeModal.close('Close click')
      } else {
        await this.app.alert('Ops :(', data.message, 'error')
        self.spinner.hide()
      }
    });
  }

  changeEnviarParaAluno($event) {
    this.isEnviarParaAluno = $event
  }

  getMensagemAvaliacao(category) {
    let mensagemPadrao = "Esta avaliação pode ser preenchida pelo personal trainer presencialmente, como por outro profissional capacitado ou até mesmo o aluno que esteja de posse desses dados, de forma remota."
    switch (category) {
      case 1:

        break;
      case 2:
        this.mensagemAvaliacao = "Esta avaliação é para medir o % de gordura corporal e pode ser preenchida pelo personal trainer presencialmente, como por outro profissional capacitado ou até mesmo o aluno que esteja de posse desses dados, de forma remota."
        break;
      case 3:
        this.mensagemAvaliacao = "Esta avaliação é para medir o % de gordura corporal e pode ser preenchida pelo personal trainer presencialmente, como por outro profissional capacitado ou até mesmo o aluno que esteja de posse desses dados, de forma remota."
        break;
      case 4:
        this.mensagemAvaliacao = "Esta avaliação é para medir o % de gordura corporal e pode ser preenchida pelo personal trainer presencialmente, como por outro profissional capacitado ou até mesmo o aluno que esteja de posse desses dados, de forma remota."
        break;
      case 5:
        this.mensagemAvaliacao = "Esta avaliação é para medir o % de gordura corporal e pode ser preenchida pelo personal trainer presencialmente, como por outro profissional capacitado ou até mesmo o aluno que esteja de posse desses dados, de forma remota."
        break;
      case 6:
        this.mensagemAvaliacao = mensagemPadrao
        break;
      case 7:
        this.mensagemAvaliacao = mensagemPadrao
        break;
      case 8:
        this.mensagemAvaliacao = mensagemPadrao
        break;
      case 9:
        this.mensagemAvaliacao = mensagemPadrao
        break;

    }
    console.log(this.mensagemAvaliacao)
  }

}
