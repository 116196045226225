import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DragulaService} from "ng2-dragula";
import {NgxSpinnerService} from "ngx-spinner";
import App from "../../../../../helpers/app";
import Api from "../../../../../helpers/api";
import {DomSanitizer} from "@angular/platform-browser";
import { ExercicioComponent } from 'app/personal/pages/exercicios/pages/exercicio/exercicio.component';

@Component({
  selector: 'app-personal-treino',
  templateUrl: './personal-treino.component.html',
  styleUrls: ['./personal-treino.component.scss']
})
export class PersonalTreinoComponent implements OnInit {
  apiCall = this.api.new().silent();

  BAG = "bag-one";
  subs = new Subscription();
  listaExercicios = []
  listaExerciciosDeletadas = []
  @Input() public training;
  @Input() public isExperimental;
  trainingModels = []
  categoriesAndExercicies = []
  exercicies = []
  trainingModelselecionada = null
  categoriaSelecionada = null
  exercicioSelecionado = null
  listaRequisicoes = []
  listaRequisicoesDeletadas = []
  tipos = [
    { id: 1, name: 'Personalizado' },
    { id: 2, name: 'Circuito' },
    { id: 3, name: 'Intervalado' },
    { id: 4, name: 'Avaliação' },
  ];
  velocidade = [
    'Lento',
    'Moderado',
    'Rápido',
    'Isométrico'
  ]

  switchRepetitionAndDuration = true
  isVideo = null
  isEditingExercise = false
  exercicie = {
    tempId: null,
    id: null,
    name: null,
    url_video: null,
    url_gif: null,
    series: null,
    repetitions: null,
    duration: null,
    load: null,
    speed: null,
    breakTime: null,
    note: null,
    order: null,
    timeDuration: null,
    loadFeedback: null,
    repetitionFeedback: null,
    timeFeedback: null,
    category: null,
    progressions: null,
    showLoadFeedback :  null,
    showRepetitionFeedback :  null,
    showTimeFeedback :  null,
  }
  showFeedback = false

  idTreino = null
  idExercicio = 0
  tipoSelecionado = null
  intervaloTreino = null
  voltasTreino = null
  observacoesTreino = null
  isAddingProgression = false
  progressions = []
  progressionsDeletadas = []
  isEditingProgression = false
  progressionInEdit = null
  newProgression = {
    id: null,
    execution: null,
    series: null,
    repetitions: null,
    duration: null,
    load: null,
    timeDuration: null,
    speed: null,
    breakTime: null,
    status : false
  }
  newTraining = {
    id: null,
    name: null,
    note: null,
    type: null,
    laps: null,
    breakTime: null,
    isExperimental: false,
    isModel: false,
    status: true,
    sellerCustomer: null,
    seller: null,
    previousTraining: null,
    exercises: [],
  }

  nomeTreino = null
  ultimoTipoSelecionado = null
  constructor(private modalService: NgbModal, private _sanitizer: DomSanitizer, private dragulaService: DragulaService, public activeModal: NgbActiveModal, private spinner: NgxSpinnerService, private app: App, private api: Api) {

    this.subs.add(dragulaService.drag(this.BAG)
      .subscribe(({ el }) => {
        //this.removeClass(el, 'ex-moved');
      })
    );
    this.subs.add(dragulaService.drop(this.BAG)
      .subscribe(({ el }) => {
        //this.addClass(el, 'ex-moved');
      })
    );
    this.subs.add(dragulaService.over(this.BAG)
      .subscribe(({ el, container }) => {
        //this.addClass(container, 'ex-over');
      })
    );
    this.subs.add(dragulaService.out(this.BAG)
      .subscribe(({ el, container }) => {
        //this.removeClass(container, 'ex-over');
      })
    );
  }

  liberaCadastroProgressao(){
    this.resetNewProgression()
    this.isAddingProgression = true
  }
  cancelaCadastroProgressao(){
    this.isAddingProgression = false
    this.isEditingProgression = false
    this.progressionInEdit = null
    this.resetNewProgression()
  }
  cadastraProgressao(){
    if(this.newProgression.timeDuration){
      let totalSeconds = 0
      if(this.newProgression.timeDuration.hour) totalSeconds = totalSeconds + (this.newProgression.timeDuration.hour*60*60)
      if(this.newProgression.timeDuration.minute) totalSeconds = totalSeconds + (this.newProgression.timeDuration.minute*60)
      if(this.newProgression.timeDuration.second) totalSeconds = totalSeconds + this.newProgression.timeDuration.second
      this.newProgression.duration =  totalSeconds
    }
    this.progressions.push(this.newProgression)
    this.resetNewProgression()
    this.isAddingProgression = false
  }
  atualizaProgressao(){
    for(let p of this.progressions){
      if(this.progressions.indexOf(p)==this.progressionInEdit){
        p.execution = this.newProgression.execution
        p.series = this.newProgression.series
        p.repetitions = this.newProgression.repetitions
        if(this.newProgression.timeDuration){
          let totalSeconds = 0
          if(this.newProgression.timeDuration.hour) totalSeconds = totalSeconds + (this.newProgression.timeDuration.hour*60*60)
          if(this.newProgression.timeDuration.minute) totalSeconds = totalSeconds + (this.newProgression.timeDuration.minute*60)
          if(this.newProgression.timeDuration.second) totalSeconds = totalSeconds + this.newProgression.timeDuration.second
          p.duration =  totalSeconds
        }
        p.load = this.newProgression.load
        p.speed = this.newProgression.speed
        p.breakTime = this.newProgression.breakTime
        p.status = this.newProgression.status
      }
    }
    this.resetNewProgression()
    this.isEditingProgression = false

  }
  verifyNewProgression(){
    if(
      this.newProgression.execution && this.newProgression.execution>1
      && (
      this.newProgression.series ||
      this.newProgression.repetitions ||
      !(!this.newProgression.timeDuration || (this.newProgression.timeDuration.hour==0 && this.newProgression.timeDuration.minute==0 && this.newProgression.timeDuration.second==0))
      || this.newProgression.load
      || this.newProgression.speed
      || this.newProgression.breakTime)
      && !this.progressions.find(p=>p.execution==this.newProgression.execution && this.progressions.indexOf(p)!=this.progressionInEdit)){
      return true
    }else{
      return false
    }
  }

  resetAllProgression(){
    this.progressions = []
    this.progressionsDeletadas = []
    this.isAddingProgression = false
    this.isEditingProgression = false
    this.progressionInEdit = null
    this.resetNewProgression()
  }

  editProgression(progression){
    this.resetNewProgression()
    this.isEditingProgression = true
    this.progressionInEdit = this.progressions.indexOf(progression)
    let timeDuration = null
    if(progression.duration){
      progression.duration = Number(progression.duration);
      var h = Math.floor(progression.duration / 3600);
      var m = Math.floor(progression.duration % 3600 / 60);
      var s = Math.floor(progression.duration % 3600 % 60);
      timeDuration = { hour: h, minute: m, second: s }
    }
    this.newProgression = {
      id: progression.id ? progression.id : null,
      execution: progression.execution ? progression.execution : null,
      series: progression.series ? progression.series : null,
      repetitions: progression.repetitions ? progression.repetitions : null,
      duration: progression.duration ? progression.duration : null,
      load: progression.load ? progression.load : null,
      speed: progression.speed ? progression.speed : null,
      breakTime: progression.breakTime ? progression.breakTime : null,
      status : progression.status ? progression.status : false,
      timeDuration: timeDuration,
    }
  }
  deleteProgression(progression){
    if(progression.id){
      progression.deletedAt=true
      this.progressionsDeletadas.push(progression)
    }
    this.cancelaCadastroProgressao()
    const found = this.progressions.indexOf(progression)
    if(found>-1) this.progressions.splice(found, 1)
  }

  resetNewProgression(){
    this.newProgression = {
      id: null,
      execution: null,
      series: null,
      repetitions: null,
      duration: null,
      timeDuration: null,
      load: null,
      speed: null,
      breakTime: null,
      status : false
    }
  }

  switchMidia(isVideo){
    if(isVideo){
      this.isVideo = true
      if(!this.exercicie.url_video){
        this.exercicie.url_video = this.exercicioSelecionado.Exercise.url_video
      }
      this.exercicie.url_gif = null
    }else{
      this.isVideo = false
      if(!this.exercicie.url_gif){
        this.exercicie.url_gif = this.exercicioSelecionado.Exercise.url_gif
      }
      this.exercicie.url_video = null
    }
  }
  urlToPlay = null
  safeURL = null

  viewMidia(isVideo, contentVideo, contentGif){
    if(isVideo){
      this.isVideo = true
      if(this.exercicie.url_video){
        this.urlToPlay = this.exercicie.url_video
      }else{
        this.urlToPlay = this.exercicioSelecionado.Exercise.url_video
        this.exercicie.url_video = this.exercicioSelecionado.Exercise.url_video
      }
      this.exercicie.url_gif = null
      if(this.urlToPlay.startsWith('https://www.youtube.com/watch?')){
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.urlToPlay.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));

      }else if(this.urlToPlay.startsWith('http://www.youtube.com/watch?')){
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.urlToPlay.replace('http://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/'));
      }else{
        this.safeURL = null
      }
      this.modalService.open(contentVideo, { centered: true });
    }else{
      this.isVideo = false
      if(this.exercicie.url_gif){
        this.urlToPlay = this.exercicie.url_gif
      }else{
        this.urlToPlay = this.exercicioSelecionado.Exercise.url_gif
        this.exercicie.url_gif = this.exercicioSelecionado.Exercise.url_gif
      }
      this.exercicie.url_video = null
      this.modalService.open(contentGif, { centered: true });
    }
  }

  switchRepAndDur(event){
    this.switchRepetitionAndDuration = event
    this.exercicie.duration = null
    this.exercicie.repetitions = null
  }
  toggleTipoTreino(){
    this.resetaTreinoEExercicios()
    this.classificaLabelTipo(this.tipoSelecionado.id)
  }

  classificaLabelTipo(id){
    switch (id) {
      case 1:
        this.ultimoTipoSelecionado = 'Treino Personalizado'
        break
      case 2:
        this.ultimoTipoSelecionado = 'Treino Circuito'
        break
      case 3:
        this.ultimoTipoSelecionado = 'Treino Intervalado'
        break
      case 4:
        this.ultimoTipoSelecionado = 'Treino de Avaliação'
        break
      default:
        this.ultimoTipoSelecionado = ''
        break
    }
  }
  resetaTreinoEExercicios(){
    this.newTraining = {
      id: null,
      name: null,
      note: null,
      type: null,
      laps: null,
      breakTime: null,
      isExperimental: false,
      isModel: false,
      status: true,
      sellerCustomer: null,
      seller: null,
      previousTraining: null,
      exercises: [],
    }
    this.listaExercicios = []

    this.categoriaSelecionada = null
    this.exercicioSelecionado = null
    this.showFeedback = false

    this.resetaExercicio()
  }

  resetaExercicio(){
    this.exercicie = {
      tempId: null,
      id: null,
      name: null,
      url_video: null,
      url_gif: null,
      series: null,
      repetitions: null,
      duration: null,
      load: null,
      speed: null,
      breakTime: null,
      note: null,
      order: null,
      loadFeedback: null,
      repetitionFeedback: null,
      timeFeedback: null,
      category: null,
      progressions: null,
      timeDuration :  null,
      showLoadFeedback :  null,
      showRepetitionFeedback :  null,
      showTimeFeedback :  null,
    }
  }
  preencheInputsTreino(training){
    this.newTraining = training
    this.tipoSelecionado = this.tipos.find(t=>t.id == training.type)
    this.classificaLabelTipo(this.tipoSelecionado.id)
    this.nomeTreino = training.name
    training.TrainingExercises.sort(function(a, b) {
      return a.order - b.order;
    });

    this.listaExercicios = []
    for (let i of training.TrainingExercises) {
      i.tempId = this.idExercicio++
      this.listaExercicios.push(i)
    }
  }
  ngOnInit() {
    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    this.apiCall.get('training/exercises/categories').subscribe(async data => {
      this.spinner.hide()
      this.categoriesAndExercicies = data.return
    });
    if(this.training) {
      this.idTreino = this.training.id
      this.preencheInputsTreino(this.training)
    }

  }


  onselectExercicio(){
    if(this.exercicioSelecionado){
      this.exercicie.name = this.exercicioSelecionado.Exercise.name
      this.exercicie.url_video = this.exercicioSelecionado.Exercise.url_video
      this.isVideo = true
      this.exercicie.url_gif = null
      this.exercicie.category = this.exercicioSelecionado.category
    }
  }
  onselectCategory(){
    this.exercicioSelecionado = null
    this.exercicies = []
    if(this.categoriaSelecionada && this.categoriaSelecionada.ExercisesCategories && this.categoriaSelecionada.ExercisesCategories.length>0){
      this.exercicies = this.categoriaSelecionada.ExercisesCategories
    }
  }
  onselectModel(){
    if(this.trainingModelselecionada){
      this.preencheInputsTreino(this.trainingModelselecionada)
    }else{
      if(!this.idTreino){
        this.nomeTreino = null
        this.listaExercicios = []
        this.listaRequisicoes = []
      }
    }
  }
  getLabelCategory(category){
    if(category){
      let cat = this.categoriesAndExercicies.find(c=>c.id==category)
      if(cat){
        return cat.name
      }else{
        return ''
      }
    }else{
      return ''
    }
  }

  verificaPreenchimentoExercicio(){

    if(!this.exercicioSelecionado){
      return false
    }
    if(!this.exercicie.url_video && !this.exercicie.url_gif){
      return false
    }
    if(this.tipoSelecionado.id==1 &&
      (!this.exercicie.series || this.exercicie.series==0 || !this.exercicie.breakTime || this.exercicie.breakTime==0 ||
        (
          (!this.switchRepetitionAndDuration || !this.exercicie.timeDuration ||  (this.exercicie.timeDuration.hour==0 && this.exercicie.timeDuration.minute==0 && this.exercicie.timeDuration.second==0)  ) && (this.switchRepetitionAndDuration || !this.exercicie.repetitions || this.exercicie.repetitions==0)
        )
      )
    ){
      return false
    }

    if(this.tipoSelecionado.id==2 && (!this.exercicie.timeDuration || (this.exercicie.timeDuration.hour==0 && this.exercicie.timeDuration.minute==0 && this.exercicie.timeDuration.second==0))){
      return false
    }
    if(this.tipoSelecionado.id==3 && ((!this.exercicie.timeDuration || (this.exercicie.timeDuration.hour==0 && this.exercicie.timeDuration.minute==0 && this.exercicie.timeDuration.second==0) || !this.exercicie.breakTime || this.exercicie.breakTime==0))){
      return false
    }
    if(this.tipoSelecionado.id==4 && !this.exercicie.showRepetitionFeedback && !this.exercicie.showTimeFeedback){
      return false
    }
    return true
  }

  verificaPreenchimentoTreino(){
    if(!this.tipoSelecionado){
      return false
    }

    if(!this.newTraining.name){
      return false
    }

    if((this.tipoSelecionado.id==2 || this.tipoSelecionado.id==3) && (!this.newTraining.laps || this.newTraining.laps==0)){
      return false
    }

    return true
  }

  verificaTreinoDeAvaliacaoPreenchido(){
    if(this.training && this.training.type==4 && this.training.TrainingExecution && this.training.TrainingExecution.length>0){
      return true
    }
    return false
  }

  atualizarExercicio(){
    if(this.exercicie){
      for(let e of this.listaExercicios){
        if(e.tempId == this.exercicie.tempId){
          if(this.exercicie.url_gif){
            this.exercicie.url_video = null
          }else{
            this.exercicie.url_gif = null
          }
          e.tempId = this.exercicie.tempId
          e.id = this.exercicie.id
          e.name = this.exercicie.name
          e.url_video = this.exercicie.url_video
          e.url_gif = this.exercicie.url_gif
          e.series = this.exercicie.series
          e.showLoadFeedback = this.exercicie.showLoadFeedback
          e.showTimeFeedback = this.exercicie.showTimeFeedback
          e.showRepetitionFeedback = this.exercicie.showRepetitionFeedback

          if(this.switchRepetitionAndDuration){
            this.exercicie.repetitions = null
          }else{
            this.exercicie.duration = null
            this.exercicie.timeDuration = null
          }

          e.duration = this.exercicie.duration
          e.repetitions = this.exercicie.repetitions




          if(this.exercicie.timeDuration){
            let totalSeconds = 0
            if(this.exercicie.timeDuration.hour) totalSeconds = totalSeconds + (this.exercicie.timeDuration.hour*60*60)
            if(this.exercicie.timeDuration.minute) totalSeconds = totalSeconds + (this.exercicie.timeDuration.minute*60)
            if(this.exercicie.timeDuration.second) totalSeconds = totalSeconds + this.exercicie.timeDuration.second
            e.duration =  totalSeconds
          }
          e.load = this.exercicie.load
          e.speed = this.exercicie.speed
          e.breakTime = this.exercicie.breakTime
          e.note = this.exercicie.note
          e.order = this.exercicie.order
          e.loadFeedback = this.exercicie.loadFeedback
          e.repetitionFeedback = this.exercicie.repetitionFeedback
          e.timeFeedback = this.exercicie.timeFeedback
          e.category = this.exercicie.category
          let progressionsToSend = []
          for(let p of this.progressions){
            progressionsToSend.push(p)
          }
          for(let p of this.progressionsDeletadas){
            progressionsToSend.push(p)
          }
          e.progressions = progressionsToSend
        }
      }
      this.cancelarAtualizacaoExercicio()
    }
    if(this.idTreino && this.exercicie.id){
      this.cadastrarTreino()
    }
  }
  cancelarAtualizacaoExercicio(){
    this.isEditingExercise = false
    this.resetaExercicio()
    this.resetAllProgression()
    this.switchRepetitionAndDuration = true
    this.categoriaSelecionada = null
    this.exercicioSelecionado = null
    this.showFeedback = false
    this.exercicies = []
  }
  adicionarExercicio(){
    if(this.isVideo){
      this.exercicie.url_gif = null
    }else{
      this.exercicie.url_video = null
    }
    this.exercicie.tempId = this.idExercicio++
    let progressionsToSend = []
    for(let p of this.progressions){
      progressionsToSend.push(p)
    }
    for(let p of this.progressionsDeletadas){
      progressionsToSend.push(p)
    }
    if(this.switchRepetitionAndDuration){
      this.exercicie.repetitions = null
    }else{
      this.exercicie.duration = null
      this.exercicie.timeDuration = null
    }
    if(this.exercicie.timeDuration){
      let totalSeconds = 0
      if(this.exercicie.timeDuration.hour) totalSeconds = totalSeconds + (this.exercicie.timeDuration.hour*60*60)
      if(this.exercicie.timeDuration.minute) totalSeconds = totalSeconds + (this.exercicie.timeDuration.minute*60)
      if(this.exercicie.timeDuration.second) totalSeconds = totalSeconds + this.exercicie.timeDuration.second
      this.exercicie.duration =  totalSeconds
    }

    this.exercicie.progressions = progressionsToSend
    this.listaExercicios.push(this.exercicie)
    this.exercicioSelecionado = null
    this.categoriaSelecionada = null
    this.showFeedback = false
    this.resetaExercicio()
    this.resetAllProgression()

  }
  verificaExercicio(exercicio){
    if(exercicio){
      if(this.idTreino && this.training && this.training.type==4 && (exercicio.loadFeedback || exercicio.timeFeedback || exercicio.repetitionFeedback)){
        return false
      }else{
        return true
      }
    }else if(this.exercicie){
      if(this.idTreino && this.training && this.training.type==4 && (this.exercicie.loadFeedback || this.exercicie.timeFeedback || this.exercicie.repetitionFeedback)){
        return false
      }else{
        return true
      }
    }else{
      return false
    }


  }
  editarExercicio(exercicio){
    if(this.idTreino && this.training && this.training.type==4){
      this.showFeedback = true
    }

    if(exercicio){
      this.categoriaSelecionada = this.categoriesAndExercicies.find(c=>c.id == exercicio.category)
      this.onselectCategory()
      this.exercicioSelecionado = this.exercicies.find(e=>e.Exercise.name == exercicio.name)
      if(!this.exercicioSelecionado){
        let exercicieTemp = {Exercise: {id:'x', name: exercicio.name, url_gif: exercicio.url_gif ? exercicio.url_gif : null, url_video: exercicio.url_video ? exercicio.url_video : null}}
        this.exercicioSelecionado = exercicieTemp
      }
      this.resetaExercicio()
      this.resetAllProgression()

      this.isEditingExercise = true
      if(exercicio.duration){
        this.switchRepetitionAndDuration = true
      } else if(exercicio.repetitions){
        this.switchRepetitionAndDuration = false
      }
      if(exercicio.url_video){
        this.isVideo = true
      }else{
        this.isVideo = false
      }
      let timeDuration = null
      if(exercicio.duration){
        exercicio.duration = Number(exercicio.duration);
        var h = Math.floor(exercicio.duration / 3600);
        var m = Math.floor(exercicio.duration % 3600 / 60);
        var s = Math.floor(exercicio.duration % 3600 % 60);
        timeDuration = { hour: h, minute: m, second: s }
      }

      this.progressions = exercicio.TrainingExercisesProgressions
      this.exercicie = {
        tempId: exercicio.tempId,
        id: exercicio.id,
        name: exercicio.name,
        url_video: exercicio.url_video,
        url_gif: exercicio.url_gif,
        series: exercicio.series,
        repetitions: exercicio.repetitions,
        duration: exercicio.duration,
        load: exercicio.load,
        speed: exercicio.speed,
        breakTime: exercicio.breakTime,
        note: exercicio.note,
        order: exercicio.order,
        loadFeedback: exercicio.loadFeedback,
        repetitionFeedback: exercicio.repetitionFeedback,
        timeFeedback: exercicio.timeFeedback,
        category: exercicio.category,
        progressions: exercicio.TrainingExercisesProgressions,
        timeDuration: timeDuration,
        showLoadFeedback :  exercicio.showLoadFeedback,
        showRepetitionFeedback :  exercicio.showRepetitionFeedback,
        showTimeFeedback :  exercicio.showTimeFeedback,
      }
    }
  }
  removerExercicio(exercicio){
    const self = this
    if(self.idTreino){
      exercicio.deletedAt=true
      self.listaExerciciosDeletadas.push(exercicio)
    }
    const index = self.listaExercicios.indexOf(exercicio)
    if(index>-1){
      self.listaExercicios.splice(index, 1)
    }
    this.resetAllProgression()
  }
  duplicarExercicio(exercicio){
    let newExercicie = {...exercicio};
    newExercicie.tempId = this.idExercicio++
    newExercicie.id = null
    this.listaExercicios.push(newExercicie)
  }

  openExercise(exercise, duplicate) {
    const modalRef = this.modalService.open(ExercicioComponent, { size: 'lg' });
    if(exercise) modalRef.componentInstance.exerciseToEdit = exercise;
    if(duplicate) modalRef.componentInstance.duplicate = duplicate;

    modalRef.result.then((data) => {
      // on close
      this.ngOnInit()
    }, (reason) => {
      // on dismiss
    });

  }


  cadastrarTreino(){
    if(this.listaExercicios){
      let listaParaEnviar = []
      for(let e of this.listaExercicios){
        e.order = this.listaExercicios.indexOf(e)+1
        listaParaEnviar.push(e)
      }
      for(let e of this.listaExerciciosDeletadas){
        listaParaEnviar.push(e)
      }
      this.newTraining.exercises = listaParaEnviar
      this.newTraining.type = this.tipoSelecionado.id
      this.newTraining.isModel = true
      this.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });

      let chamada
      if(this.idTreino){
        chamada = this.apiCall.put('training/personal/0', {training: this.newTraining})
      }else{
        chamada = this.apiCall.post('training/personal/0', {training: this.newTraining})
      }
      chamada.subscribe(async data => {
        if(data.success){
          this.spinner.hide()
          await this.app.alert('Tudo certo!', this.idTreino ? 'Seu treino foi atualizado com sucesso' : 'Seu treino foi cadastrado com sucesso', 'success')
          this.activeModal.close('Close click')

        }else{
          await this.app.alert('Ops :(', data.message, 'error')
          this.spinner.hide()
        }
      });
    }
  }
}
