<div class="pix-container">
  <h4>Pagamento via PIX</h4>
  <div class="pix-info">
    <div class="form-group">
      <label for="pixQRCode">QR Code</label>
      <div class="qr-code-container">
        <img [src]="pix.qrcode" alt="QR Code PIX" class="pix-qrcode">
      </div>
    </div>
    <div class="form-group">
      <label for="pixKey">Chave PIX</label>
      <div class="input-group">
        <input
          type="text"
          id="pixKey"
          class="form-control"
          [(ngModel)]="pixKey"
          name="pixKey"
          readonly
        />
        <div class="input-group-append">
          <button class="btn btn-primary" (click)="copyPixKey()">Copiar</button>
        </div>
      </div>
    </div>
  </div>
  <hr class="divider">
  <div class="dados-boleto">
    <div><strong>Vencimento:</strong>{{ formatDate(invoice.due_date) }}</div>
    <div><strong>Valor:</strong>R$ {{ invoice.items[0].price }}</div>
  </div>
  <hr class="divider">
  <div class="boleto-body">
    <div class="boleto-section">
      <div class="boleto-cedente">
        <div><strong>Cedente:</strong> &nbsp;&nbsp; {{ invoice.sellerName }}</div>
        <div><strong>CPF/CNPJ:</strong> &nbsp;&nbsp; {{ maskedSellerDoc }}</div>
        <div><strong>Endereço:</strong> &nbsp;&nbsp; {{ invoice.sellerAddress }}</div>
      </div>
    </div>
    <hr class="divider">
    <div class="boleto-section">
      <div class="boleto-sacado">
        <div><strong>Sacado:</strong> &nbsp;&nbsp; {{ invoice.payer_name }}</div>
        <div><strong>CPF/CNPJ:</strong> &nbsp;&nbsp; {{ maskedCnpjCpf }}</div>
        <div><strong>Endereço:</strong> &nbsp;&nbsp; {{ invoice.payer_address_street }}, {{ invoice.payer_address_number }}, {{ invoice.payer_address_city }} - {{ invoice.payer_address_state }}</div>
      </div>
    </div>
    <hr class="divider">
    <div class="boleto-section">
      <div class="boleto-descricao">
        <div><strong>Descrição:</strong></div>
        <div>{{ invoice.items[0].description }}</div>
      </div>
    </div>
  </div>
</div>
