import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import Me, { User } from "app/helpers/me";
import * as moment from "moment";
import { CountdownComponent } from "ngx-countdown";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-first-onboarding",
  templateUrl: "./first-onboarding.component.html",
  styleUrls: ["./first-onboarding.component.scss"],
})
export class FirstOnboardingComponent implements OnInit, AfterViewInit {
  @ViewChild("cd", { static: false }) private countdown: CountdownComponent;

  $user = new BehaviorSubject<User>(null);
  get user() {
    return this.$user.value;
  }
  set user(user: User) {
    this.$user.next(user);
  }

  countdownTimeUnits: Array<[string, number]> = [
    ["Y", 1000 * 60 * 60 * 24 * 365], // years
    ["M", 1000 * 60 * 60 * 24 * 30], // months
    ["D", 1000 * 60 * 60 * 24], // days
    ["H", 1000 * 60 * 60], // hours
    ["m", 1000 * 60], // minutes
    ["s", 1000], // seconds
    ["S", 1], // million seconds
  ];
  configTimer = {
    leftTime: 0,
    formatDate: ({ date, formatStr }) => {
      let duration = Number(date || 0);

      return this.countdownTimeUnits.reduce((current, [name, unit]) => {
        if (current.indexOf(name) !== -1) {
          const v = Math.floor(duration / unit);
          duration -= v * unit;
          return current.replace(
            new RegExp(`${name}+`, "g"),
            (match: string) => {
              return v.toString().padStart(match.length, "0");
            }
          );
        }
        return current;
      }, formatStr);
    },
  };

  constructor(private me: Me, private router: Router) {}

  ngOnInit() {
    this.me.get(false).subscribe((user) => {
      if (user) {
        this.user = user;

        if (this.user.firstOnboardingProgress == null) {
          this.user.firstOnboardingProgress = 1;
          this.me.onboardingUpdate(this.user);
        }
      }

      this.configTimer.leftTime = moment()
        .add(15, "minutes")
        .diff(moment(), "seconds");
    });
  }

  ngAfterViewInit() {
    let hasUserData = false;

    const userSubcription = this.$user.subscribe((user) => {
      if (user && !hasUserData) {
        hasUserData = true;

        if (this.countdown) {
          this.countdown.begin();
          userSubcription.unsubscribe();
        }
      }
    });
  }

  async updateOnboardingProgress(step: number, customers, goCheckout: boolean) {
    if (goCheckout === true) {
      (this.user as any).initiateCheckoutOnboarding = true;
    }

    if (customers) {
      (this.user as any).customersAmount = customers;
    }

    this.user.firstOnboardingProgress = step;
    await this.me.onboardingUpdate(this.user);

    if (goCheckout === true) {
      window.location.href =
        "https://opersonaldigital.com.br/oferta-boas-vindas";
      return;
    }

    switch (step) {
      case 3:
        window.location.href = `${window.location.origin}/page?action=generate-training`;
        break;
      case 4:
        window.location.href = `${window.location.origin}/page?action=download-app`;
        break;
      case 5:
        window.location.href = `${window.location.origin}/page?action=add-clients`;
        break;
      case 6:
        window.location.href = `${window.location.origin}/page?ontour=1`;
        break;
    }
  }

  cancelarWhatsApp() {
    (this.user as any).onboardingInviteCustomer = true;
    this.me.onboardingUpdate(this.user);

    window.open(
      `https://api.whatsapp.com/send?text=Tenho uma ótima notícia pra você! A partir de agora você receberá seus treinos através do meu app, bastar clicar no link para baixar e realizar seu cadastro: ${
        (this.user as any).inviteLink
      }.`,
      "_blank"
    );
  }
}
