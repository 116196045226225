import { Location } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as shape from "d3-shape";
import * as moment from "moment";
import { ApexOptions } from "ng-apexcharts";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Api from "../../../helpers/api";
import App from "../../../helpers/app";
import Me from "../../../helpers/me";

var $primary = "#ff6c22",
  $success = "#40C057",
  $info = "#2F8BE6",
  $warning = "#F77E17",
  $danger = "#F55252";
var themeColors = [$primary, $warning, $success, $danger, $info];

@Component({
  selector: "app-treino-historico-carga",
  templateUrl: "./treino-historico-carga.component.html",
  styleUrls: ["./treino-historico-carga.component.scss"],
})
export class TreinoHistoricoCargaComponent implements OnInit {
  apiCall = this.api.new().silent();

  //Chart Data
  @Input() public training;
  pageTr = 1;
  page = 1;
  pageSize = 10;
  groups = [];
  originalGroups = [];
  grupo = {
    id: null,
    group: null,
    relations: [],
  };
  relations = [];
  user = null;
  urlToPlay = null;
  safeURL = null;
  decodedData = null;
  nomeTreino = null;
  lineChartMulti = [];

  lineChartColorScheme = {
    domain: ["#ff6c22", "#FF8D60", "#FF586B", "#AAAAAA"],
  };

  lineChartShowXAxis = true;
  lineChartShowYAxis = true;
  lineChartGradient = false;
  lineChartShowLegend = false;
  lineChartShowXAxisLabel = true;
  lineChartXAxisLabel = "Country";
  lineChartShowYAxisLabel = true;
  lineChartYAxisLabel = "Population";
  lineChartAutoScale = true;

  exercisesFeedbacks = null;
  lineAreaChartOptions: ApexOptions = null;
  lineChartLineInterpolation = shape.curveBasis;

  filterPeriod = [
    { days: 7, description: "Últimos 7 dias" },
    { days: 30, description: "Últimos 30 dias" },
    { days: 60, description: "Últimos 60 dias" },
    { days: 90, description: "Últimos 90 dias" },
    { days: 365, description: "Últimos 12 meses" },
  ];

  trainingExercises = [];

  exerciseSelected = null;

  filterPeriodSelected = 365;

  originalData = null;

  exerciseMobile = null;

  constructor(
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private me: Me,
    private app: App,
    private api: Api,
    private _location: Location
  ) {
    this.me
      .get()
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
      });

    this.route.params.subscribe((params) => {
      if (params["trainingdata"])
        this.decodedData = JSON.parse(atob(params["trainingdata"]));
    });
  }

  async ngOnInit() {
    const self = this;
    let training = null;

    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let startDate = moment().subtract(this.filterPeriodSelected, "days");

    if (this.decodedData.training) {
      if (this.decodedData.relation) {
        training = await self.apiCall
          .get("training/historico/" + this.decodedData.training)
          .pipe(take(1))
          .toPromise();
      }
    }

    if (training && training.return) {
      this.training = training.return;
      this.exercisesFeedbacks = this.training.historicalExercicesFeedback;
      this.originalData = this.exercisesFeedbacks;
    }

    let historicalLoad = [];
    let historicalDate = [];

    if (this.exercisesFeedbacks && this.exercisesFeedbacks.length > 0) {
      for (let e of this.exercisesFeedbacks) {
        e.page = 1;

        this.trainingExercises.push({
          id: e.id,
          name: e.name,
        });

        e.historicalLoad = [];
        e.historicalDate = [];
        e.historicalLoadTable = [];
        e.historicalDateTable = [];
        e.historicalDataforTable = [];

        for (let f of e.feedbackData) {
          let date = moment(f.date).format("DD/MM/YYYY");

          if (f.feedback != null && date != null) {
            e.historicalDataforTable.push({
              date: date,
              feedback: f.feedback,
            });
            e.historicalLoadTable.push(f.feedback);
            e.historicalDateTable.push(date);
          }

          if (
            f.feedback != null &&
            Number.isInteger(f.feedback) &&
            date != null
          ) {
            e.historicalLoad.push(f.feedback);
            e.historicalDate.push(date);
          }
        }
      }
    }

    this.exerciseSelected = this.trainingExercises[0].id;

    this.changeExercise();

    for (let e of this.exercisesFeedbacks) {
      e.feedbackData = e.feedbackData.filter((f) =>
        moment(f.date).isAfter(startDate)
      );
    }

    this.lineAreaChartOptions = {
      chart: {
        height: 350,
        type: "area",
      },
      colors: themeColors,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      series: [
        {
          name: "Carga",
          data: historicalLoad,
        },
      ],
      legend: {
        offsetY: -10,
      },
      xaxis: {
        categories: historicalDate,
        labels: {
          formatter: function (value) {
            return value;
          },
          style: {
            cssClass: "chart-xaxis-label",
          },
          rotate: 0,
        },
        tickAmount: historicalDate.length,
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        title: {
          text: "Kg",
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
        y: {
          formatter: function (value) {
            return value + " kg";
          },
        },
      },
    };

    self.spinner.hide();
  }

  onDeleteGroup(grupo) {
    const self = this;
    self.app
      .confirm("Tem certeza?", "Você realmente deseja deletar este grupo?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("groupcustomers/" + grupo.id)
            .subscribe(async (data) => {
              self.spinner.hide();
              if (data.success) {
                self.ngOnInit();
                await this.app.alert(
                  "Tudo certo!",
                  "Grupo deletado com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }

  openGroup(grupo, modal) {
    this.grupo = {
      id: null,
      group: null,
      relations: [],
    };
    if (grupo) {
      let relations = [];
      for (let t of grupo.CustomersGroupsUsers) {
        const found = this.relations.find((x) => x.id == t.relation);
        if (found) relations.push(found);
      }
      this.grupo = {
        id: grupo.id,
        group: grupo.group,
        relations: relations,
      };
    }
    const modalRef = this.modalService.open(modal, {
      centered: true,
      size: "xl",
    });
  }

  inserirOuAtualizarGrupo(modal) {
    this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    let chamada;
    let body = {
      CustomersGroups: {
        id: this.grupo.id ? this.grupo.id : null,
        group: this.grupo.group,
        CustomersGroupsUsers: this.grupo.relations,
      },
    };
    if (this.grupo.id) {
      chamada = this.apiCall.put("groupcustomers", body);
    } else {
      chamada = this.apiCall.post("groupcustomers", body);
    }
    chamada.subscribe(async (data) => {
      if (data.success) {
        this.spinner.hide();
        await this.app.alert(
          "Tudo certo!",
          this.grupo.id
            ? "Seu grupo foi atualizado com sucesso"
            : "Seu grupo foi cadastrado com sucesso",
          "success"
        );
        modal.close("Close click");
        this.ngOnInit();
      } else {
        await this.app.alert("Ops :(", data.message, "error");
        this.spinner.hide();
      }
    });
  }

  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }

  viewMidia(urlToPlay, content) {
    this.urlToPlay = urlToPlay;
    if (urlToPlay.startsWith("https://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "https://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    } else if (urlToPlay.startsWith("http://www.youtube.com/watch?")) {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
        urlToPlay.replace(
          "http://www.youtube.com/watch?v=",
          "https://www.youtube.com/embed/"
        )
      );
    }
    this.modalService.open(content, { centered: true, size: "lg" });
  }

  async changePeriod() {
    const self = this;

    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    //@ts-ignore
    let startDate = moment().subtract(this.filterPeriodSelected, "days");

    this.exercisesFeedbacks = JSON.parse(JSON.stringify(this.originalData));

    for (let e of this.exercisesFeedbacks) {
      e.feedbackData = e.feedbackData.filter((f) =>
        moment(f.date).isAfter(startDate)
      );
    }

    if (this.exercisesFeedbacks && this.exercisesFeedbacks.length > 0) {
      for (let e of this.exercisesFeedbacks) {
        e.historicalLoad = [];
        e.historicalDate = [];
        e.historicalLoadTable = [];
        e.historicalDateTable = [];
        e.historicalDataforTable = [];

        for (let f of e.feedbackData) {
          let date = moment(f.date).format("DD/MM/YYYY");

          if (f.feedback != null && date != null) {
            e.historicalDataforTable.push({
              date: date,
              feedback: f.feedback,
            });
            e.historicalLoadTable.push(f.feedback);
            e.historicalDateTable.push(date);
          }

          e.historicalLoadTable.push(f.feedback);
          e.historicalDateTable.push(date);

          if (f.feedback != null && Number.isInteger(f.feedback)) {
            e.historicalLoad.push(f.feedback);
            e.historicalDate.push(date);
          }
        }
      }
    }

    if (this.exerciseSelected != null) {
      this.exerciseMobile = this.exercisesFeedbacks.find(
        (e) => e.id == this.exerciseSelected
      );
    }

    self.spinner.hide();
  }

  changeExercise() {
    this.exerciseMobile = this.exercisesFeedbacks.find(
      (e) => e.id == this.exerciseSelected
    );
  }

  backPage() {
    //@ts-ignore
    this._location.back();
  }
}
