import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-image-cropper",
  templateUrl: "./image-cropper.component.html",
  styleUrls: ["./image-cropper.component.scss"],
})
export class ImageCropperComponent implements OnChanges {
  @Input() imageSource: string | ArrayBuffer | null = null;

  @Output() croppedImageEvent = new EventEmitter<ImageCroppedEvent>();

  @Input() aspectRatio: number = null;

  imageChangedEvent: any = "";
  croppedImage: ImageCroppedEvent | null = null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.imageSource && this.imageSource) {
      this.imageChangedEvent = this.imageSource;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("imageCropped", event);
    this.croppedImage = event;
    this.cropImage();
  }

  cropImage() {
    this.croppedImageEvent.emit(this.croppedImage);
  }
}
