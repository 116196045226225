import {Component, Input, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {DragulaService} from "ng2-dragula";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NgxSpinnerService} from "ngx-spinner";
import App from "../../../../../helpers/app";
import Api from "../../../../../helpers/api";

@Component({
  selector: 'app-personal-avaliacao',
  templateUrl: './personal-avaliacao.component.html',
  styleUrls: ['./personal-avaliacao.component.scss']
})
export class PersonalAvaliacaoComponent implements OnInit {
  apiCall = this.api.new().silent();

  BAG = "bag-one";
  subs = new Subscription();
  listaPerguntas = []
  listaPerguntasDeletadas = []
  @Input() public avaliation;
  @Input() public perguntasPadrao;
  @Input() public arquivosPadrao;
  @Input() public type;


  listaRequisicoes = []
  listaRequisicoesDeletadas = []
  tipos = [
    { id: 1, name: 'Digite a resposta' },
    { id: 2, name: 'Se sim digite a resposta / Não' },
    { id: 3, name: 'Sim/Não' },
  ];

  tiposFiles = [
    { id: 'image', name: 'Anexar Imagens ou Fotos' },
    { id: 'file', name: 'Anexar Arquivos' },
  ];
  idAvaliacao = null
  idPergunta = 0
  idRequisicao = 0
  idPerguntaEditada = null
  idRequisicaoEditada = null
  tipoSelecionado = null
  tipoFileSelecionado = null
  perguntaParaAdicionar = null
  requisicaoParaAdicionar = null
  nomeAvaliacao = null
  isPerguntaObrigatoria = true
  isRequisicaoObrigatoria = true
  constructor(private dragulaService: DragulaService, public activeModal: NgbActiveModal, private spinner: NgxSpinnerService, private app: App, private api: Api) {

    this.subs.add(dragulaService.drag(this.BAG)
      .subscribe(({ el }) => {
        //this.removeClass(el, 'ex-moved');
      })
    );
    this.subs.add(dragulaService.drop(this.BAG)
      .subscribe(({ el }) => {
        //this.addClass(el, 'ex-moved');
      })
    );
    this.subs.add(dragulaService.over(this.BAG)
      .subscribe(({ el, container }) => {
        //this.addClass(container, 'ex-over');
      })
    );
    this.subs.add(dragulaService.out(this.BAG)
      .subscribe(({ el, container }) => {
        //this.removeClass(container, 'ex-over');
      })
    );


  }

  copyFromPlataforma(){
    if(!this.idAvaliacao && this.type==2){
      this.nomeAvaliacao = 'Avaliação Padrão'
      this.listaPerguntas = []
      this.listaPerguntasDeletadas = []
      this.listaRequisicoes = []
      this.listaRequisicoesDeletadas = []
      if(this.perguntasPadrao){
        for(let p of this.perguntasPadrao){
          let obj = {
            order: p.order,
            question: p.question,
            questionType: p.questionType,
            labelType: this.tipos.find(t => t.id == p.questionType) ? this.tipos.find(t => t.id == p.questionType).name : '',
          }
          this.listaPerguntas.push(obj)
        }
      }
      if(this.arquivosPadrao){
        for(let p of this.arquivosPadrao){
          let obj = {
            order: p.order,
            observationFile: p.observationFile,
            typeFile: p.typeFile,
            labelType: this.tiposFiles.find(t => t.id == p.typeFile) ? this.tiposFiles.find(t => t.id == p.typeFile).name : '',
          }
            this.listaRequisicoes.push(obj)
        }
      }



    }
  }

  ngOnInit(): void {
    if(!this.type){
      this.activeModal.close('Close click')
    }
    if(this.avaliation) {
      this.idAvaliacao = this.avaliation.id
      this.nomeAvaliacao = this.avaliation.name
      this.avaliation.Itens.sort(function(a, b) {
        return a.order - b.order;
      });
      this.avaliation.Files.sort(function(a, b) {
        return a.order - b.order;
      });
      for (let i of this.avaliation.Itens) {
        let obj = {
          id: i.id,
          question: i.question,
          questionType: i.questionType,
          order: i.order,
          response: i.response,
          avaliation: i.avaliation,
          labelType: this.tipos.find(t => t.id == i.questionType) ? this.tipos.find(t => t.id == i.questionType).name : '',
          required: i.required != null && i.required == true ,

        }
        this.listaPerguntas.push(obj)
      }

      for (let f of this.avaliation.Files) {
        let obj = {
          id: f.id,
          urlFile: f.urlFile,
          typeFile: f.typeFile,
          observationFile: f.observationFile,
          order: f.order,
          avaliation: f.avaliation,
          required: f.required != null && f.required == true ,

          labelType: this.tiposFiles.find(t => t.id == f.typeFile) ? this.tiposFiles.find(t => t.id == f.typeFile).name : '',

        };
        this.listaRequisicoes.push(obj)
      }
    }
  }
  removerRequisicao(req){
    const self = this
    if(self.idAvaliacao){
      req.deletedAt=true
      self.listaRequisicoesDeletadas.push(req)
    }
    const index = self.listaRequisicoes.indexOf(req)
    if(index>-1){
      self.listaRequisicoes.splice(index, 1)
    }
  }
  removerPergunta(pergunta){
    const self = this
    if(self.idAvaliacao){
      pergunta.deletedAt=true
      self.listaPerguntasDeletadas.push(pergunta)
    }
    const index = self.listaPerguntas.indexOf(pergunta)
    if(index>-1){
      self.listaPerguntas.splice(index, 1)
    }
  }
  changeObrigatoriedadeRequisicao($event){
    this.isRequisicaoObrigatoria = $event
  }
  changeObrigatoriedadePergunta($event){
    this.isPerguntaObrigatoria = $event
  }
  adicionarPergunta(){
    this.idPergunta++
    let obj = {
      idTemp: this.idPergunta,
      question: this.perguntaParaAdicionar,
      questionType : this.tipoSelecionado.id,
      labelType : this.tipoSelecionado.name,
    };

    this.listaPerguntas.push(obj)
    this.tipoSelecionado = null
    this.perguntaParaAdicionar = null
    this.isPerguntaObrigatoria = true

  }
  downloadFile(url){

    //saveAs(url, name)
  }

  adicionarRequisicao(){
    this.idRequisicao++
    let obj = {
      idTemp: this.idRequisicao,
      observationFile: this.requisicaoParaAdicionar,
      typeFile : this.tipoFileSelecionado.id,
      labelType : this.tipoFileSelecionado.name,
      required : this.isRequisicaoObrigatoria,

    };
    console.log(obj)
    this.listaRequisicoes.push(obj)
    this.requisicaoParaAdicionar = null
    this.tipoFileSelecionado = null
    this.isRequisicaoObrigatoria = true

  }

  liberarEdicaoPergunta(pergunta){
    if(pergunta){
      this.idPerguntaEditada = pergunta.id ? pergunta.id : pergunta.idTemp
      this.perguntaParaAdicionar = pergunta.question
      this.tipoSelecionado = this.tipos.find(t => t.id == pergunta.questionType)
      this.isPerguntaObrigatoria = pergunta.required

    }
  }
  editarPergunta(){
    const self = this
    for(let p of self.listaPerguntas){
      if(p.id==this.idPerguntaEditada || p.idTemp == this.idPerguntaEditada){
        p.question = this.perguntaParaAdicionar
        p.questionType = this.tipoSelecionado.id
        p.labelType = this.tipoSelecionado.name
        p.required = this.isPerguntaObrigatoria

      }
    }
    this.cancelarEdicaoPergunta()
  }
  cancelarEdicaoPergunta(){
    this.idPerguntaEditada = null
    this.perguntaParaAdicionar = null
    this.tipoSelecionado = null
    this.isPerguntaObrigatoria = true

  }
  duplicarPergunta(pergunta){
    let newPergunta = {...pergunta};
    newPergunta.id = null
    this.idPergunta++
    newPergunta.idTemp = this.idPergunta
    this.listaPerguntas.push(newPergunta)
  }




  liberarEdicaoRequisicao(requisicao){
    if(requisicao){
      this.idRequisicaoEditada = requisicao.id ? requisicao.id : requisicao.idTemp
      this.requisicaoParaAdicionar = requisicao.observationFile
      this.tipoFileSelecionado = this.tiposFiles.find(t => t.id == requisicao.typeFile)
      this.isRequisicaoObrigatoria = requisicao.required

    }
  }
  editarRequsicao(){
    const self = this
    for(let p of self.listaRequisicoes){
      if(p.id==this.idRequisicaoEditada || p.idTemp == this.idRequisicaoEditada){
        p.observationFile = this.requisicaoParaAdicionar
        p.typeFile = this.tipoFileSelecionado.id
        p.labelType = this.tipoFileSelecionado.name
        p.required = this.isRequisicaoObrigatoria

      }
    }
    this.cancelarEdicaoRequisicao()
  }
  cancelarEdicaoRequisicao(){
    this.idRequisicaoEditada = null
    this.requisicaoParaAdicionar = null
    this.tipoFileSelecionado = null
    this.isRequisicaoObrigatoria = true

  }
  duplicarRequisicao(requisicao){
    let newRequisicao = {...requisicao};
    newRequisicao.id = null
    this.idRequisicao++
    newRequisicao.idTemp = this.idRequisicao
    this.listaRequisicoes.push(newRequisicao)
  }







  submitAvaliacao(){
    const self = this

    let body = self.preparaBodyAvaliacao()
    if(body){
      self.spinner.show(undefined,
        {
          type: 'ball-triangle-path',
          size: 'medium',
          bdColor: 'rgba(0, 0, 0, 0.8)',
          color: '#fff',
          fullScreen: true
        });
      let chamada
      if(self.idAvaliacao){
        chamada = self.apiCall.put('avaliations/0', body)
      }else{
        chamada = self.apiCall.post('avaliations', body)
      }
      chamada.subscribe(async data => {
        if(data.success){
          self.spinner.hide()
          await this.app.alert('Tudo certo!', self.idAvaliacao ? 'Sua avaliação foi atualizada com sucesso' : 'Sua avaliação foi criada com sucesso', 'success')
          this.activeModal.close('Close click')
        }else{
          await this.app.alert('Ops :(', data.message, 'error')
          self.spinner.hide()
        }
      });
    }
  }
  preparaBodyAvaliacao(){
    const self = this
    let req = []
    let per = []

    if(self.idAvaliacao){
      for(let p of self.listaPerguntas){
        let obj = {
          id: p.id ? p.id : null,
          question: p.question ? p.question : null,
          questionType: p.questionType ? p.questionType : null,
          order: (self.listaPerguntas.indexOf(p)+1),
          response: p.response ? p.response : null,
          required : p.required ? p.required : false,
          avaliation: this.idAvaliacao,
        };
        per.push(obj)
      }
      for(let p of self.listaPerguntasDeletadas){
        let obj = {
          id: p.id ? p.id : null,
          question: p.question ? p.question : null,
          questionType: p.questionType ? p.questionType : null,
          order: p.order,
          deletedAt: p.deletedAt,
          response: p.response ? p.response : null,
          required : p.required ? p.required : false,
          avaliation: self.idAvaliacao,
        };
        per.push(obj)
      }
      for(let r of self.listaRequisicoes){
        let obj = {
          id: r.id ? r.id : null,
          urlFile: r.urlFile ? r.urlFile : null,
          typeFile: r.typeFile ? r.typeFile : 'image',
          observationFile: r.observationFile ? r.observationFile : null,
          order: (self.listaRequisicoes.indexOf(r)+1),
          required : r.required ? r.required : false,
          avaliation: self.idAvaliacao,
        };
        req.push(obj)
      }
      for(let r of self.listaRequisicoesDeletadas){
        let obj = {
          id: r.id ? r.id : null,
          urlFile: r.urlFile ? r.urlFile : null,
          typeFile: r.typeFile ? r.typeFile : 'image',
          observationFile: r.observationFile ? r.observationFile : null,
          order: r.order,
          deletedAt: r.deletedAt,
          required : r.required ? r.required : false,
          avaliation: self.idAvaliacao,
        };
        req.push(obj)
      }

      return {
        avaliation : {
          id : self.idAvaliacao,
          sellerCustomer : null,
          type : this.type,
          name : self.nomeAvaliacao,
          itens: per,
          files : req
        }
      }
    }else{
      for(let p of self.listaPerguntas){
        let obj = {
          order: (self.listaPerguntas.indexOf(p)+1),
          question: p.question,
          questionType : p.questionType,
          required: p.required
        };
        per.push(obj)
      }
      for(let r of self.listaRequisicoes){
        let obj = {
          order: (self.listaRequisicoes.indexOf(r)+1),
          observationFile: r.observationFile,
          typeFile: r.typeFile,
          required: r.required
        };
        req.push(obj)
      }

      return {
        avaliation : {
          sellerCustomer : null,
          type : this.type,
          name : self.nomeAvaliacao,
          itens: per,
          files : req
        }
      }
    }
  }
}
