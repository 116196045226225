import { Component, OnInit } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../shared/auth/auth.service";
import {NgxSpinnerService} from "ngx-spinner";
import Me from "../helpers/me";
import {SocialAuthService} from "angularx-social-login";
import App from "../helpers/app";
import Api from "../helpers/api";
import {take} from "rxjs/operators";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin-historico-assinaturas',
  templateUrl: './admin-historico-assinaturas.component.html',
  styleUrls: ['./admin-historico-assinaturas.component.scss']
})
export class AdminHistoricoAssinaturasComponent implements OnInit {
  apiCall = this.api.new().silent();
  cupons = []
  page = 1
  pageSize = 10
  cupom = {
    id : null,
    coupon : null,
    description : null,
    percentageFirstMonth : null,
    priceFirstMonth : null,
    status : null,
  }
  selectedCSV: File | undefined;
  constructor(private modalService: NgbModal,
              private router: Router,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private me: Me,
              private socialAuthService: SocialAuthService,
              private app: App,
              private api: Api) {
  }
  async ngOnInit() {
    const self = this

    self.resetCupom()
    self.cupons = []

    await self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    const data = await self.apiCall.get('coupons').pipe(take(1)).toPromise()
    console.log(data)
    if(data.return) self.cupons = data.return
    await self.spinner.hide()

  }
  async verifyFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      let retorno = ''
      reader.onload =  (function(theFile) {
        var image = new Image();
        image.src = theFile.target.result.toString();
        image.onload = function() {
          // access image size here
          if(image.width!=414 || image.height!=150){
            resolve('a proporção da imagem está invalida! É obrigatório utilizar a proporção de 414px x 150px')
          }else{
            resolve(null)
          }
        };
      });
    });
  }
  //var csv is the CSV file with headers
  csvJSON(csv) {
    var lines = csv.split("\r\n");
    var result = [];
    // NOTE: If your columns contain commas in their values, you’ll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    var headers = lines[0].split(";");
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(";");
      if (currentline.length > 1) {
        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentline[j];
        }
        result.push(obj);
      }
    }
    //return result; //JavaScript object
    return JSON.stringify(result); //JSON
  }
  async uploadSimplificado() {
    const self = this;
    const { value: file } = await Swal.fire({
      title: "Upload CSV",
      input: "file",
      inputAttributes: {
        "aria-label": "Selecione a planilha",
      },
      inputValidator: async (value) => {
        if (!value) {
          await this.app.alert(
            "Atenção!",
            "Você deve informar uma planilha!",
            "error"
          );
          return;
        }
        // @ts-ignore
        let file:File = value

        const retorno = await self.verifyFile(file)
        if(retorno){
          return retorno.toString()
        }
      }
    });
    console.log("file", file);
    // if (!file) {
    //   await this.app.alert(
    //     "Atenção!",
    //     "Você deve informar uma planilha!",
    //     "error"
    //   );
    //   return;
    // }
    // if (!file.name.endsWith("csv")) {
    //   await this.app.alert(
    //     "Atenção!",
    //     "Você deve informar planilhas no formato .csv!",
    //     "error"
    //   );
    //   return;
    // }

    // let dataReturn = await this.apiCall.post('uploadHistoricoAssinaturas', file).subscribe(
    //   (response) => {
    //     console.log("response", response);
    //   },
    //   (error) => {
    //     console.error('Erro ao enviar o arquivo:', error);
    //   }
    // );
    if (file) {
      console.log("if file", file)
      var reader = new FileReader(); //define a Reader
      reader.onload = async function (f)  {
        console.log("reader", reader)
        let text = reader.result;
        let csvJson = self.csvJSON(text);
        console.log("1")
        let lista = JSON.parse(csvJson);
        console.log("2")
        let newLista = [];
        for (let l of lista) {
          console.log("3")
          // @ts-ignore
          const trimmed = Object.entries(l).reduce((acc, curr) => {
            let [key, value] = curr;
            // Checking if the key is a string
            acc[typeof key === "string" ? key.trim() : key] = value;
            return acc;
          }, {});
          newLista.push(trimmed);
        }
        console.log("newLista", newLista);
        console.log("file", file);
        return;
      }
    }
    //   lista = [...newLista];
    //   let cols = lista ? Object.keys(lista[0]) : [];
    //   let totalCol = lista ? Object.keys(lista[0]).length : 0;
    //   let totalDeLinhas = lista.length;
    //   let tipo = file.type;
    //   let extension = file.name.split(".").pop().toLowerCase();
    //   const uploadFiles = await this.apiCall
    //     .post("servidor/arquivo/exact/url", {
    //       extension: file.name,
    //       type: tipo,
    //     })
    //     .pipe(take(1))
    //     .toPromise();

    //   if (totalCol != 5) {
    //     await this.app.alert(
    //       "Atenção!",
    //       "Quantidade de colunas do seu arquivo é diferente da esperada!",
    //       "error"
    //     );
    //     return;
    //   }
    //   const array2Sorted = this.colunasEsperadasSimplificado.slice().sort();
    //   const isEqual =
    //     cols.length === this.colunasEsperadasSimplificado.length &&
    //     cols
    //       .slice()
    //       .sort()
    //       .every(function (value, index) {
    //         return value === array2Sorted[index];
    //       });
    //   if (!isEqual) {
    //     await this.app.alert(
    //       "Atenção!",
    //       "O cabeçalho do seu arquivo é diferente do esperado!",
    //       "error"
    //     );
    //     return;
    //   }
    //   let ibgeFound = [];
    //   for (let l of lista) {
    //     if (l.CO_IBGE && !ibgeFound.find((i) => i == l.CO_IBGE)) {
    //       ibgeFound.push(l.CO_IBGE);
    //     }
    //   }
    //   if (ibgeFound.length !== 1) {
    //     await this.app.alert(
    //       "Atenção!",
    //       "O seu arquivo contém mais de um código IBGE!",
    //       "error"
    //     );
    //     return;
    //   }
    //   if (self.cidade && self.cidade.ibge && ibgeFound[0] != self.cidade.ibge) {
    //     await this.app.alert(
    //       "Atenção!",
    //       "O seu arquivo contém código IBGE inválido! o código esperado para esta cidade é " +
    //         self.cidade.ibge,
    //       "error"
    //     );
    //     return;
    //   }
    //   if (!uploadFiles || !uploadFiles.result) {
    //     return;
    //   }
    //   var options = {
    //     headers: { "Content-Type": tipo, "x-amz-acl": "public-read" },
    //   };
    //   const resultado = await self.http
    //     .put<any>(uploadFiles.result.replace(".sa-east-1", ""), file, options)
    //     .toPromise();
    //   let urlArquivo = uploadFiles.result.split("?")[0];
    //   await self.spinner.show(undefined, {
    //     type: "ball-triangle-path",
    //     size: "medium",
    //     bdColor: "rgba(0, 0, 0, 0.8)",
    //     color: "#fff",
    //     fullScreen: true,
    //   });
    //   await this.apiCall
    //     .post(
    //       servidor/arquivo/planilha?tipo=${9}&cidade=${self.idCidade}&url=${urlArquivo}&total_rows=${totalDeLinhas}&simplificada=true
    //     )
    //     .pipe(take(1))
    //     .toPromise();
    //   await self.app.alert(
    //     "Tudo certo!",
    //     "Planilha cadastrada com sucesso!",
    //     "success"
    //   );
    //   await self.spinner.hide();
    //   await self.ngOnInit();
    // };
    // reader.readAsText(file as Blob, "ISO-8859-1");
  }
  onCSVSelected($event: any): void {
    this.selectedCSV = $event.srcElement.files[0];
    console.log("this.selectedCSV", this.selectedCSV)
  }
  async uploadCSV() {
    if (!this.selectedCSV) {
      console.log('Nenhum arquivo selecionado.');
      return;
    }
    let a = {a: "hahahaha"}
    let formData = new FormData();

    formData.append('file', this.selectedCSV, this.selectedCSV.name);
    console.log("formData", formData)

      /*this.apiCall.post('uploadHistoricoAssinaturas', this.selectedCSV).subscribe(
      (response) => {
        console.log("response", response);
      },
      (error) => {
        console.error('Erro ao enviar o arquivo:', error);
      }
    );*/

    let dataReturn = await this.apiCall.post('uploadHistoricoAssinaturas', {file: formData}).toPromise();

    if(dataReturn){

console.log("dataReturn", dataReturn)

    }
  }

  
  inserirOuAtualizarCupom(modal){

    this.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    let chamada
    let body = {
      Coupons : this.cupom
    }
    if(this.cupom.id){
      chamada = this.apiCall.put('coupons', body)

    }else{
      chamada = this.apiCall.post('coupons', body)

    }
    chamada.subscribe(async data => {
      if(data.success){
        this.spinner.hide()
        await this.app.alert('Tudo certo!', this.cupom.id ? 'Cupom atualizado com sucesso' : 'Cupom cadastrado com sucesso', 'success')
        modal.close('Close click')
        this.ngOnInit()
      }else{
        await this.app.alert('Ops :(', data.message, 'error')
        this.spinner.hide()
      }
    });
  }

  onDeleteCupom(cupom){
    const self = this
    self.app.confirm('Tem certeza?', 'Você realmente deseja deletar este cupom?').then(d=>{
      if(d){
        self.spinner.show(undefined,
          {
            type: 'ball-triangle-path',
            size: 'medium',
            bdColor: 'rgba(0, 0, 0, 0.8)',
            color: '#fff',
            fullScreen: true
          });
        self.apiCall.delete('coupons/'+cupom.id).subscribe(async data => {
          self.spinner.hide()
          if(data.success){
            self.ngOnInit()
            await this.app.alert('Tudo certo!',  'Cupom deletado com sucesso', 'success')
          }else{
            await this.app.alert('Ops :(', data.message, 'error')
          }
        });
      }
    })
  }

  openCupom(cupom, modal){
    this.resetCupom()
    if(cupom){
      this.cupom = cupom
    }
    console.log(this.cupom)
    const modalRef = this.modalService.open(modal);
  }
  switchCupom(cupom){
    const self = this
    self.spinner.show(undefined,
      {
        type: 'ball-triangle-path',
        size: 'medium',
        bdColor: 'rgba(0, 0, 0, 0.8)',
        color: '#fff',
        fullScreen: true
      });
    if(cupom && cupom.id){
      cupom.status = !cupom.status
      self.apiCall.put('coupons', {
        Coupons : cupom
      }).subscribe(async data => {
        await self.spinner.hide()
        await self.ngOnInit()
        if(data.success){
          await this.app.alert('Tudo certo!',  cupom.status? 'Cupom ativado com sucesso' : 'Cupom inativado com sucesso', 'success')
        }else{
          await this.app.alert('Ops :(', data.message, 'error')
        }
      });
    }
  }
  resetCupom(){
    this.cupom = {
      id : null,
      coupon : null,
      description : null,
      percentageFirstMonth : null,
      priceFirstMonth : null,
      status : null,
    }
  }

}
