import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import Api from "app/helpers/api";
import App from "app/helpers/app";
import Me from "app/helpers/me";
import * as cep from "cep-promise";
import * as CryptoJS from "crypto-js";
import * as moment from "moment";
import { ClipboardService } from "ngx-clipboard";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";

@Component({
  selector: "app-finance-dashboard",
  templateUrl: "./finance-dashboard.component.html",
  styleUrls: ["./finance-dashboard.component.scss"],
})
export class FinanceDashboardComponent implements OnInit {
  user = null;
  planos = [];
  planosAtivos = [];
  planosInativos = [];
  faturas = [];
  assinaturas = [];
  account = null;
  apiCall = this.api.new().silent();
  pageF = 1;
  pageSizeF = 10;
  pageA = 1;
  pageSizeA = 10;
  pageP = 1;
  pageSizeP = 10;
  idItemInEdit = null;
  itensToDelete = [];
  newInvoice = {
    payable_with: [],
    items: [],
    due_date: null,
    payer: {
      email: null,
      name: null,
      cpf_cnpj: null,
      zip_code: null,
      street: null,
      city: null,
      state: null,
      number: null,
    },
  };
  newSubscribe = {
    subitems: [],
    suspend_on_invoice_expired: true,
    plan_identifier: null,
    expires_at: null,
    customer: {
      email: null,
      name: null,
      cpf_cnpj: null,
      zip_code: null,
      street: null,
      city: null,
      state: null,
      number: null,
      district: null,
    },
  };
  newProduct = {
    id: null,
    name: null,
    plan_type: null,
    payable_with: null,
    description: null,
    identifier: null,
    product_image: null,
    value_cents: null,
    delivery_video: null,
    delivery_logo: null,
    delivery_image: null,
    delivery_instructions: null,
    delivery_link: null,
    checkout_url: null,
    status: null,
    towAccess: null,
  };
  productsPlans = {
    monthly: {
      id: null,
      name: null,
      identifier: null,
      status: true,
      value_cents: null,
      max_cycles: null,
      interval: 1,
      interval_type: "months",
      iugu_id: null,
    },
    quarterly: {
      id: null,
      name: null,
      identifier: null,
      status: false,
      value_cents: null,
      interval: 3,
      interval_type: "months",
      iugu_id: null,
    },
    semiannual: {
      id: null,
      name: null,
      identifier: null,
      status: false,
      value_cents: null,
      interval: 6,
      interval_type: "months",
      iugu_id: null,
    },
    annual: {
      id: null,
      name: null,
      identifier: null,
      status: false,
      value_cents: null,
      interval: 12,
      interval_type: "months",
      iugu_id: null,
    },
  };
  tiposPagamento = [];

  tiposPlanos = [
    {
      id: "0",
      label: "Compra única",
    },
    {
      id: "1",
      label: "Plano de Recorrência",
    },
  ];
  tiposIntervalo = [
    {
      id: "months",
      label: "Mês",
    },
    {
      id: "weeks",
      label: "Semana",
    },
  ];

  parcelado = [
    {
      parcela: 1,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "1x",
    },
    {
      parcela: 2,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "2x",
    },
    {
      parcela: 3,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "3x",
    },
    {
      parcela: 4,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "4x",
    },
    {
      parcela: 5,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "5x",
    },
    {
      parcela: 6,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "6x",
    },
    {
      parcela: 7,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "7x",
    },
    {
      parcela: 8,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "8x",
    },
    {
      parcela: 9,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "9x",
    },
    {
      parcela: 10,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "10x",
    },
    {
      parcela: 11,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "11x",
    },
    {
      parcela: 12,
      mensalidadeComTaxa: 0,
      mensalidadeSemTaxa: 0,
      valorLiquido: 0,
      label: "12x",
    },
  ];

  parcelaSelecionada = null;
  parcelamentos = [];
  tipoPagamentoEscolhido = [];
  tipoIntervaloEscolhido = null;
  tipoPlanoEscolhido = null;
  newInvoiceItem = {
    description: null,
    quantity: null,
    price_cents: null,
  };
  url_video = null;
  sha1_video = null;
  safeURL = null;
  video_file = null;
  product_image = null;
  product_image_url = null;
  product_image_sha1 = null;
  delivery_logo = null;
  delivery_logo_url = null;
  delivery_logo_sha1 = null;
  delivery_image = null;
  delivery_image_url = null;
  delivery_image_sha1 = null;
  checkoutLink = null;
  helperType = null;
  helperImage = null;
  thankYouLPreviewUrl = null;
  isAtrelarAluno = false;
  videoUrl = null;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private spinner: NgxSpinnerService,
    private me: Me,
    private app: App,
    private api: Api,
    private _sanitizer: DomSanitizer,
    private clipboardApi: ClipboardService
  ) {
    this.me
      .get()
      .pipe(take(1))
      .toPromise()
      .then((user) => {
        this.user = user;
      });
  }

  async ngOnInit() {
    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    let planos = null;

    this.videoUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      "https://www.youtube.com/embed/TaZeiVozd6M"
    );
    const subaccount = await this.apiCall
      .get("seller/me/marketplaceaccountlocal")
      .pipe(take(1))
      .toPromise();

    if (!subaccount.return) {
      this.router.navigate(["/page/personal/finance-account"]);
    } else {
      this.account = subaccount.return;
    }

    if (
      (this.account &&
        this.account.identity_validation &&
        this.account.identity_validation != 3) ||
      (this.account && !this.account.identity_validation)
    ) {
      this.router.navigate(["/page/personal/finance-account"]);
    }

    this.resetPaymentMethods();

    planos = await this.apiCall
      .get("seller/me/subconta/planos")
      .pipe(take(1))
      .toPromise();
    if (planos && planos.return) {
      this.planos = planos.return;
      this.planosAtivos = this.planos.filter((x) => x.status == 1);
      this.planosInativos = this.planos.filter((x) => x.status == 0);
    }
    console.log("this.planos", this.planos);

    await this.spinner.hide();
  }
  openModal(template) {
    this.resetPaymentMethods();
    this.resetFatura();
    this.resetItemFatura();
    this.resetPlano();
    this.resetAssinatura();
    this.newSubscribe.expires_at = moment().format("DDMMYYYY");
    this.modalService.open(template, { centered: true, size: "lg" });
  }
  OpenModalXl(content) {
    this.modalService.open(content, { centered: true, size: "xl" });
  }
  updatePlan(plano, modal) {
    this.resetPlano();

    this.newProduct = {
      id: plano.id,
      payable_with: [plano.payable_with],
      name: plano.name,
      plan_type: null,
      description: null,
      product_image: null,
      identifier: plano.identifier,
      value_cents:
        plano.prices[0] && plano.prices[0].value_cents
          ? (plano.prices[0].value_cents / 100)
              .toFixed(2)
              .toString()
              .replace(".", ",")
          : null,
      delivery_video: null,
      delivery_logo: null,
      delivery_link: null,
      delivery_image: null,
      delivery_instructions: null,
      checkout_url: null,
      status: null,
      towAccess: null,
    };

    if (this.newProduct.payable_with)
      this.tipoPagamentoEscolhido = this.tiposPagamento.filter(
        (x) => x.id == this.newProduct.payable_with
      );
    //if (this.newProduct.interval_type) this.tipoIntervaloEscolhido = this.tiposIntervalo.find(x => x.id == this.newProduct.interval_type)
    this.modalService.open(modal);
  }
  resetItemFatura() {
    this.newInvoiceItem = {
      description: null,
      quantity: null,
      price_cents: null,
    };
  }
  resetAssinatura() {
    this.newSubscribe = {
      subitems: [],
      suspend_on_invoice_expired: true,
      plan_identifier: null,
      expires_at: null,
      customer: {
        email: null,
        name: null,
        cpf_cnpj: null,
        zip_code: null,
        street: null,
        city: null,
        state: null,
        number: null,
        district: null,
      },
    };
  }
  resetFatura() {
    this.itensToDelete = [];
    this.tipoPagamentoEscolhido = null;
    this.newInvoice = {
      payable_with: [],
      items: [],
      due_date: null,
      payer: {
        email: null,
        name: null,
        cpf_cnpj: null,
        zip_code: null,
        street: null,
        city: null,
        state: null,
        number: null,
      },
    };
  }
  resetPlano() {
    this.tipoPagamentoEscolhido = null;
    this.tipoIntervaloEscolhido = null;
    this.tipoPlanoEscolhido = null;
    this.product_image_url = null;
    this.delivery_logo_url = null;
    this.url_video = null;
    this.delivery_image_url = null;

    this.newProduct = {
      id: null,
      name: null,
      plan_type: null,
      payable_with: null,
      description: null,
      identifier: null,
      product_image: null,
      value_cents: null,
      delivery_video: null,
      delivery_logo: null,
      delivery_image: null,
      delivery_instructions: null,
      delivery_link: null,
      checkout_url: null,
      status: null,
      towAccess: null,
    };
    this.productsPlans = {
      monthly: {
        id: null,
        name: null,
        identifier: null,
        status: true,
        value_cents: null,
        max_cycles: null,
        interval: 1,
        interval_type: "months",
        iugu_id: null,
      },
      quarterly: {
        id: null,
        name: null,
        identifier: null,
        status: false,
        value_cents: null,
        interval: 3,
        interval_type: "months",
        iugu_id: null,
      },
      semiannual: {
        id: null,
        name: null,
        identifier: null,
        status: false,
        value_cents: null,
        interval: 6,
        interval_type: "months",
        iugu_id: null,
      },
      annual: {
        id: null,
        name: null,
        identifier: null,
        status: false,
        value_cents: null,
        interval: 12,
        interval_type: "months",
        iugu_id: null,
      },
    };
  }

  resetPlans() {
    this.productsPlans = {
      monthly: {
        id: null,
        name: null,
        identifier: null,
        status: false,
        value_cents: null,
        max_cycles: null,
        interval: 1,
        interval_type: "months",
        iugu_id: null,
      },
      quarterly: {
        id: null,
        name: null,
        identifier: null,
        status: false,
        value_cents: null,
        interval: 3,
        interval_type: "months",
        iugu_id: null,
      },
      semiannual: {
        id: null,
        name: null,
        identifier: null,
        status: false,
        value_cents: null,
        interval: 6,
        interval_type: "months",
        iugu_id: null,
      },
      annual: {
        id: null,
        name: null,
        identifier: null,
        status: false,
        value_cents: null,
        interval: 12,
        interval_type: "months",
        iugu_id: null,
      },
    };
  }
  toggleStatusSubscribe(subscribe) {
    const self = this;
    self.app
      .confirm(
        "Atenção!",
        `Você realmente deseja ${
          subscribe.active ? "inativar" : "ativar"
        } essa assinatura?`
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .post("seller/me/subconta/assinatura/status", {
              subscribe: subscribe,
            })
            .subscribe(async (data) => {
              await self.ngOnInit();
              self.spinner.hide();
              if (data.success) {
                await this.app.alert(
                  "Tudo certo!",
                  `Assinatura ${
                    subscribe.active ? "inativada" : "ativada"
                  } com sucesso`,
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  verifyDate(isDuplicating) {
    if (isDuplicating) {
      if (this.invToDuplicate.due_date) {
        return moment(this.invToDuplicate.due_date, "DDMMYYYY").isSameOrAfter(
          moment()
        );
      } else {
        return false;
      }
    } else {
      if (this.newInvoice.due_date) {
        return moment(this.newInvoice.due_date, "DDMMYYYY").isSameOrAfter(
          moment()
        );
      } else {
        return false;
      }
    }
  }
  verifyDateSubscribe() {
    if (this.newSubscribe.expires_at) {
      return moment(this.newSubscribe.expires_at, "DDMMYYYY").isSameOrAfter(
        moment()
      );
    } else {
      return false;
    }
  }

  consultaCep() {
    const self = this;
    if (self.newInvoice.payer.zip_code) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      cep(self.newInvoice.payer.zip_code)
        .then((local) => {
          self.spinner.hide();
          self.newInvoice.payer.street = local.street;
          self.newInvoice.payer.state = local.state;
          self.newInvoice.payer.city = local.city;
        })
        .catch((erro) => {
          self.spinner.hide();
        });
    }
  }
  consultaCepAssinatura() {
    const self = this;
    if (self.newSubscribe.customer.zip_code) {
      if (self.newSubscribe.customer.zip_code) {
        self.spinner.show(undefined, {
          type: "ball-triangle-path",
          size: "medium",
          bdColor: "rgba(0, 0, 0, 0.8)",
          color: "#fff",
          fullScreen: true,
        });
        cep(self.newSubscribe.customer.zip_code)
          .then((local) => {
            self.spinner.hide();
            self.newSubscribe.customer.street = local.street;
            self.newSubscribe.customer.state = local.state;
            self.newSubscribe.customer.city = local.city;
            self.newSubscribe.customer.district = local.neighborhood;
          })
          .catch((erro) => {
            self.spinner.hide();
          });
      }
    }
  }
  async submitSubscribe(modal) {
    const self = this;
    let subToSend = { ...this.newSubscribe };
    subToSend.expires_at = moment(
      this.newSubscribe.expires_at,
      "DDMMYYYY"
    ).format("YYYY-MM-DD");
    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const submit = await this.apiCall
      .post("seller/me/subconta/assinatura", { subscribe: subToSend })
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();
    if (submit.success) {
      await this.app.alert(
        "Tudo certo!",
        "Assinatura criada com sucesso!",
        "success"
      );
      modal.close("Close click");
      await this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", submit.message, "error");
    }
  }
  getPricePlan() {
    if (!this.newSubscribe.plan_identifier) return "";
    const found = this.planos.find(
      (x) => x.identifier == this.newSubscribe.plan_identifier
    );
    if (!found || !found.prices[0]) return "";
    return found.prices[0].value_cents / 100;
  }
  getTotalInvoice(isDuplicating) {
    let total = 0;
    if (isDuplicating) {
      for (let t of this.invToDuplicate.items) {
        total = total + t.price_cents * t.quantity;
      }
    } else {
      for (let t of this.newInvoice.items) {
        total = total + t.price_cents * t.quantity;
      }
    }
    return (
      "Total Fatura: R$" + (total / 100).toFixed(2).toString().replace(".", ",")
    );
  }

  submitItemInvoice(isDuplicating) {
    if (isDuplicating) {
      if (this.idItemInEdit != null) {
        for (let t of this.invToDuplicate.items) {
          if (this.invToDuplicate.items.indexOf(t) == this.idItemInEdit) {
            t.description = this.newInvoiceItem.description;
            t.quantity = this.newInvoiceItem.quantity;
            t.price_cents = this.newInvoiceItem.price_cents * 100;
          }
        }
      } else {
        let inv = { ...this.newInvoiceItem };
        inv.price_cents = inv.price_cents * 100;
        this.invToDuplicate.items.push(inv);
      }
    } else {
      if (this.idItemInEdit != null) {
        for (let t of this.newInvoice.items) {
          if (this.newInvoice.items.indexOf(t) == this.idItemInEdit) {
            t.description = this.newInvoiceItem.description;
            t.quantity = this.newInvoiceItem.quantity;
            t.price_cents = this.newInvoiceItem.price_cents * 100;
          }
        }
      } else {
        let inv = { ...this.newInvoiceItem };
        inv.price_cents = inv.price_cents * 100;
        this.newInvoice.items.push(inv);
      }
    }
    this.resetItemFatura();
  }
  cancelItemInvoice() {
    this.idItemInEdit = null;
    this.resetItemFatura();
  }
  editItemInvoice(inv, i) {
    let invtemp = { ...inv };
    invtemp.price_cents = (invtemp.price_cents / 100)
      .toString()
      .replace(".", ",");
    this.newInvoiceItem = invtemp;
    this.idItemInEdit = i;
  }

  deleteItemInvoice(i, item, isDuplicating) {
    if (isDuplicating) {
      this.idItemInEdit = null;
      this.invToDuplicate.items.splice(i, 1);
      if (item && item.id) {
        this.itensToDelete.push(item);
      }
    } else {
      this.idItemInEdit = null;
      this.newInvoice.items.splice(i, 1);
    }
  }
  cancelInvoice(inv) {
    const self = this;
    if (!inv || !inv.id) return;
    self.app
      .confirm("Tem certeza?", "Você realmente deseja cancelar essa fatura?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .post("seller/me/subconta/fatura/cancel", { id: inv.id })
            .subscribe(async (data) => {
              await self.ngOnInit();
              self.spinner.hide();
              if (data.success) {
                await this.app.alert(
                  "Tudo certo!",
                  "Fatura cancelada com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }
  refundInvoice(inv) {
    const self = this;
    if (!inv || !inv.id) return;
    self.app
      .confirm("Tem certeza?", "Você realmente deseja reembolsar essa fatura?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .post("seller/me/subconta/fatura/refund", { id: inv.id })
            .subscribe(async (data) => {
              await self.ngOnInit();
              self.spinner.hide();
              if (data.success) {
                await this.app.alert(
                  "Tudo certo!",
                  "Fatura reembolsada com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }

  invToDuplicate = null;
  duplicateInvoice(inv, template) {
    const self = this;
    inv.due_date = moment(inv.due_date).format("DDMMYYYY");
    self.invToDuplicate = inv;

    this.modalService.open(template);
  }
  makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async submitUpdatePlanOld(modal) {
    const self = this;
    /* if (!this.tipoPagamentoEscolhido || !this.tipoPagamentoEscolhido.id || !this.tipoIntervaloEscolhido || !this.tipoIntervaloEscolhido.id || !this.newProduct.id) {
       return
     }*/
    let planToSend = { ...this.newProduct };
    planToSend.payable_with = [];
    //planToSend.payable_with.push(this.tipoPagamentoEscolhido.id)
    // planToSend.interval_type = this.tipoIntervaloEscolhido.id
    planToSend.value_cents = (
      +planToSend.value_cents.toString().replace(",", ".") * 100
    ).toFixed(0);
    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const submit = await this.apiCall
      .put("seller/me/subconta/planos", { plan: planToSend })
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();
    if (submit.success) {
      await this.app.alert(
        "Tudo certo!",
        "Plano atualizado com sucesso!",
        "success"
      );
      modal.close("Close click");
      await this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", submit.message, "error");
    }
  }
  async submitPlan(modal) {
    const self = this;
    let planosProduto = [];

    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    /* if(!this.tipoPagamentoEscolhido || !this.tipoPagamentoEscolhido.id  || !this.tipoIntervaloEscolhido  || !this.tipoIntervaloEscolhido.id){
       return
     }*/

    if (self.product_image) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.product_image.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.product_image_sha1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.product_image, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.product_image_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.video_file) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.video_file.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".mp4",
            "X-Bz-Content-Sha1": this.sha1_video,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.video_file, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.url_video =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.delivery_image) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.delivery_image.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.delivery_image_sha1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.delivery_image, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.delivery_image_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.delivery_logo) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.delivery_logo.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.delivery_logo_sha1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.delivery_logo, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.delivery_logo_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    let productToSend = { ...this.newProduct };
    productToSend.payable_with = JSON.stringify(this.tipoPagamentoEscolhido);
    productToSend.plan_type = productToSend.plan_type.id == 0 ? 0 : 1;
    productToSend.identifier = ("plan_" + this.makeid(4)).toLowerCase();
    productToSend.value_cents = (productToSend.value_cents * 100).toFixed(0);
    productToSend.product_image = self.product_image_url
      ? self.product_image_url
      : null;
    productToSend.delivery_video = self.url_video ? self.url_video : null;
    productToSend.delivery_image = self.delivery_image_url
      ? self.delivery_image_url
      : null;
    productToSend.delivery_logo = self.delivery_logo_url
      ? self.delivery_logo_url
      : null;

    if (productToSend.plan_type == 1) {
      if (this.productsPlans.monthly.status == true) {
        this.productsPlans.monthly.name = productToSend.name + " mensal";
        this.productsPlans.monthly.identifier = (
          "planrec_" + this.makeid(4)
        ).toLowerCase();
        this.productsPlans.monthly.value_cents = (
          this.productsPlans.monthly.value_cents * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.monthly);
      }

      if (this.productsPlans.quarterly.status == true) {
        this.productsPlans.quarterly.name = productToSend.name + " trimestral";
        this.productsPlans.quarterly.identifier = (
          "planrec_" + this.makeid(4)
        ).toLowerCase();
        this.productsPlans.quarterly.value_cents = (
          this.productsPlans.quarterly.value_cents * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.quarterly);
      }

      if (this.productsPlans.semiannual.status == true) {
        this.productsPlans.semiannual.name = productToSend.name + " semestral";
        this.productsPlans.semiannual.identifier = (
          "planrec_" + this.makeid(4)
        ).toLowerCase();
        this.productsPlans.semiannual.value_cents = (
          this.productsPlans.semiannual.value_cents * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.semiannual);
      }

      if (this.productsPlans.annual.status == true) {
        this.productsPlans.annual.name = productToSend.name + " anual";
        this.productsPlans.annual.identifier = (
          "planrec_" + this.makeid(4)
        ).toLowerCase();
        this.productsPlans.annual.value_cents = (
          this.productsPlans.annual.value_cents * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.annual);
      }

      productToSend.towAccess = self.isAtrelarAluno;
    }
    const submit = await this.apiCall
      .post("seller/me/subconta/planos", {
        product: productToSend,
        plans: planosProduto,
      })
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();
    if (submit.success) {
      await this.app.alert(
        "Tudo certo!",
        "Plano criado com sucesso!",
        "success"
      );
      modal.close("Close click");
      await this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", submit.message, "error");
    }
  }

  async submitUpdatePlan(modal) {
    const self = this;
    let planosProduto = [];

    /* if(!this.tipoPagamentoEscolhido || !this.tipoPagamentoEscolhido.id  || !this.tipoIntervaloEscolhido  || !this.tipoIntervaloEscolhido.id){
       return
     }*/

    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    if (self.product_image) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.product_image.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.product_image_sha1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.product_image, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.product_image_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.video_file) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.video_file.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".mp4",
            "X-Bz-Content-Sha1": this.sha1_video,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.video_file, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.url_video =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.delivery_image) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.delivery_image.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.delivery_image_sha1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.delivery_image, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.delivery_image_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    if (self.delivery_logo) {
      const dataUrl = await self.apiCall
        .get("blackbaze/urltoupload")
        .pipe(take(1))
        .toPromise();
      if (!dataUrl.return) {
        self.spinner.hide();
        return;
      }
      if (
        dataUrl.return &&
        dataUrl.return.authorizationToken &&
        dataUrl.return.uploadUrl &&
        dataUrl.return.uniqid
      ) {
        var optionsB2 = {
          headers: {
            Authorization: dataUrl.return.authorizationToken,
            "Content-Type": this.delivery_logo.type,
            "X-Bz-File-Name": dataUrl.return.uniqid.toString() + ".jpeg",
            "X-Bz-Content-Sha1": this.delivery_logo_sha1,
          },
        };
        const returnedUpload = await self.api.http
          .post(dataUrl.return.uploadUrl, this.delivery_logo, optionsB2)
          .toPromise();
        if (returnedUpload)
          self.delivery_logo_url =
            "https://files.wecodde.com/file/wecodde/" +
            JSON.parse(JSON.stringify(returnedUpload)).fileName;
      }
    }

    let productToSend = { ...this.newProduct };
    productToSend.payable_with = JSON.stringify(this.tipoPagamentoEscolhido);
    productToSend.plan_type = productToSend.plan_type.id;
    productToSend.value_cents = productToSend.value_cents
      ? (+productToSend.value_cents.toString().replace(",", ".") * 100).toFixed(
          0
        )
      : null;
    productToSend.product_image = self.product_image_url
      ? self.product_image_url
      : null;
    productToSend.delivery_video = self.url_video ? self.url_video : null;
    productToSend.delivery_image = self.delivery_image_url
      ? self.delivery_image_url
      : null;
    productToSend.delivery_logo = self.delivery_logo_url
      ? self.delivery_logo_url
      : null;

    if (productToSend.plan_type == 1) {
      if (
        this.productsPlans.monthly.status == true ||
        this.productsPlans.monthly.id != null
      ) {
        this.productsPlans.monthly.name = productToSend.name + " mensal";
        this.productsPlans.monthly.identifier = this.productsPlans.monthly.id
          ? this.productsPlans.monthly.identifier
          : ("planrec_" + this.makeid(4)).toLowerCase();
        this.productsPlans.monthly.value_cents = (
          this.productsPlans.monthly.value_cents.toString().replace(",", ".") *
          100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.monthly);
      }

      if (
        this.productsPlans.quarterly.status == true ||
        this.productsPlans.quarterly.id != null
      ) {
        this.productsPlans.quarterly.name = productToSend.name + " trimestral";
        this.productsPlans.quarterly.identifier = this.productsPlans.quarterly
          .id
          ? this.productsPlans.quarterly.identifier
          : ("planrec_" + this.makeid(4)).toLowerCase();
        this.productsPlans.quarterly.value_cents = (
          this.productsPlans.quarterly.value_cents
            .toString()
            .replace(",", ".") * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.quarterly);
      }
      if (
        this.productsPlans.semiannual.status == true ||
        this.productsPlans.semiannual.id != null
      ) {
        this.productsPlans.semiannual.name = productToSend.name + " semestral";
        this.productsPlans.semiannual.identifier = this.productsPlans.semiannual
          .id
          ? this.productsPlans.semiannual.identifier
          : ("planrec_" + this.makeid(4)).toLowerCase();
        this.productsPlans.semiannual.value_cents = (
          this.productsPlans.semiannual.value_cents
            .toString()
            .replace(",", ".") * 100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.semiannual);
      }
      if (
        this.productsPlans.annual.status == true ||
        this.productsPlans.annual.id != null
      ) {
        this.productsPlans.annual.name = productToSend.name + " anual";
        this.productsPlans.annual.identifier = this.productsPlans.annual.id
          ? this.productsPlans.annual.identifier
          : ("planrec_" + this.makeid(4)).toLowerCase();
        this.productsPlans.annual.value_cents = (
          this.productsPlans.annual.value_cents.toString().replace(",", ".") *
          100
        ).toFixed(0);
        planosProduto.push(this.productsPlans.annual);
      }

      productToSend.towAccess = self.isAtrelarAluno;
    }

    const submit = await this.apiCall
      .put("seller/me/subconta/planos", {
        product: productToSend,
        plans: planosProduto,
      })
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();
    if (submit.success) {
      await this.app.alert(
        "Tudo certo!",
        "Plano editado com sucesso!",
        "success"
      );
      modal.close("Close click");
      await this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", submit.message, "error");
    }
  }

  async submitDuplicateInvoice(modal) {
    const self = this;
    let invToSend = { ...this.invToDuplicate };
    if (this.itensToDelete && this.itensToDelete.length > 0) {
      for (let it of this.itensToDelete) {
        it._destroy = true;
        invToSend.items.push(it);
      }
    }
    for (let it of invToSend.items) {
      it.price_cents = it.price_cents.toFixed(0);
    }

    invToSend.due_date = moment(
      this.invToDuplicate.due_date,
      "DDMMYYYY"
    ).format("YYYY-MM-DD");
    await this.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    const submit = await this.apiCall
      .post("seller/me/subconta/fatura/duplicate", invToSend)
      .pipe(take(1))
      .toPromise();
    await this.spinner.hide();
    if (submit.success) {
      modal.close("Close click");
      await this.app.alert(
        "Tudo certo!",
        "Segunda Via criada com sucesso!",
        "success"
      );
      await this.ngOnInit();
    } else {
      await this.app.alert("Ops :(", submit.message, "error");
    }
  }

  removePlan(plan) {
    const self = this;
    if (!plan || !plan.id) return;
    self.app
      .confirm("Tem certeza?", "Você realmente deseja remover este plano?")
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });
          self.apiCall
            .delete("seller/me/subconta/planos/" + plan.id)
            .subscribe(async (data) => {
              await self.ngOnInit();
              self.spinner.hide();
              if (data.success) {
                await this.app.alert(
                  "Tudo certo!",
                  "Plano removido com sucesso",
                  "success"
                );
              } else {
                await this.app.alert("Ops :(", data.message, "error");
              }
            });
        }
      });
  }

  async toggleVideoOrGif(isVideo, isUrl) {
    const self = this;
    if (isVideo) {
      if (isUrl) {
        let { value: file } = await Swal.fire({
          title: "Url do Vídeo do YouTube",
          input: "text",
          showCancelButton: true,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Salvar`,
          inputValidator: async (value) => {
            if (value && value.startsWith("https://youtu.be/")) {
              value = value.replace(
                "https://youtu.be/",
                "https://www.youtube.com/watch?v="
              );
            }
            if (!value) {
              return "Você precisa informar a url do YouTube!";
            }
            if (
              !value.startsWith("https://www.youtube.com/watch?") &&
              !value.startsWith("http://www.youtube.com/watch?")
            ) {
              return 'Você precisa informar uma url válida do Youtube! esta url deve começar com "https://www.youtube.com/watch?"';
            }
            self.url_video = value;
            if (value.startsWith("https://www.youtube.com/watch?")) {
              self.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
                self.url_video.replace(
                  "https://www.youtube.com/watch?v=",
                  "https://www.youtube.com/embed/"
                )
              );
            } else {
              self.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
                self.url_video.replace(
                  "http://www.youtube.com/watch?v=",
                  "https://www.youtube.com/embed/"
                )
              );
            }
            self.video_file = null;
          },
        });
      } else {
        let { value: file } = await Swal.fire({
          title: "Vídeo Página de entrega",
          html: '<small style="color:red">O Tamanho máximo do arquivo é de 30mb</small>',
          input: "file",
          showCancelButton: true,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Salvar`,
          inputAttributes: {
            accept: "video/mp4",
            "aria-label": "Faça upload de um vídeo de até 30 segundos.",
          },
          inputValidator: async (value) => {
            if (!value) {
              return "Você precisa escolher um vídeo!";
            }
            // @ts-ignore
            let file: File = value;

            if (file.size > 30000000) {
              return "São permitidos vídeos de até 30MB!";
            }

            const isValid = await self.verifyDuration(file);
            if (!isValid) {
              return "São permitidos vídeos de até 30 segundos!";
            }
            self.video_file = file;
            const base64 = await self.fileToBase64(file);
            if (base64) self.url_video = base64;
            var reader = new FileReader(); //define a Reader
            reader.onload = function (f) {
              var file_result = this.result; // this == reader, get the loaded file "result"
              var file_wordArr = CryptoJS.lib.WordArray.create(
                file_result as ArrayBuffer
              ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
              var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
              self.sha1_video = sha1_hash.toString();
            };
            reader.readAsArrayBuffer(file); //read file as ArrayBuffer
          },
        });
      }
    } else {
      let { value: file } = await Swal.fire({
        title: "Imagem da página de entrega",
        input: "file",
        showCancelButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Salvar`,
        inputAttributes: {
          accept: "image/*",
          "aria-label": "Faça upload de uma imagem.",
        },
        inputValidator: async (value) => {
          if (!value) {
            return "Você precisa escolher uma imagem!";
          }
          // @ts-ignore
          let file: File = value;

          if (file.size > 30000000) {
            return "São permitidos imagens de até 30MB!";
          }
          if (
            file.type != "image/png" &&
            file.type != "image/jpg" &&
            file.type != "image/jpeg"
          ) {
            return "São permitidos apenas imagens!";
          }
          self.delivery_image = file;
          const base64 = await self.fileToBase64(file);
          if (base64) self.delivery_image_url = base64;

          var reader = new FileReader(); //define a Reader
          reader.onload = function (f) {
            var file_result = this.result; // this == reader, get the loaded file "result"
            var file_wordArr = CryptoJS.lib.WordArray.create(
              file_result as ArrayBuffer
            ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
            var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
            self.delivery_image_sha1 = sha1_hash.toString();
          };
          reader.readAsArrayBuffer(file); //read file as ArrayBuffer
        },
      });
    }
  }
  async fileToBase64(file) {
    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
  async verifyDuration(file) {
    return await new Promise<boolean>((res, rej) => {
      var video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        if (duration > 31) {
          res(false);
        } else {
          res(true);
        }
      };
    });
  }
  async selecionarImagemProduto() {
    const self = this;
    let { value: file } = await Swal.fire({
      title: "Imagem do Produto",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma imagem.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher uma imagem!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos imagens de até 30MB!";
        }
        if (
          file.type != "image/png" &&
          file.type != "image/jpg" &&
          file.type != "image/jpeg"
        ) {
          return "São permitidos apenas imagens!";
        }
        self.product_image = file;
        const base64 = await self.fileToBase64(file);
        if (base64) self.product_image_url = base64;

        var reader = new FileReader(); //define a Reader
        reader.onload = function (f) {
          var file_result = this.result; // this == reader, get the loaded file "result"
          var file_wordArr = CryptoJS.lib.WordArray.create(
            file_result as ArrayBuffer
          ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
          var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
          self.product_image_sha1 = sha1_hash.toString();
        };
        reader.readAsArrayBuffer(file); //read file as ArrayBuffer
      },
    });
  }
  async selecionarImagemLogoObrigado() {
    const self = this;
    let { value: file } = await Swal.fire({
      title: "Imagem do Produto",
      input: "file",
      showCancelButton: true,
      cancelButtonText: `Cancelar`,
      confirmButtonText: `Salvar`,
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Faça upload de uma imagem.",
      },
      inputValidator: async (value) => {
        if (!value) {
          return "Você precisa escolher uma imagem!";
        }
        // @ts-ignore
        let file: File = value;

        if (file.size > 30000000) {
          return "São permitidos imagens de até 30MB!";
        }
        if (
          file.type != "image/png" &&
          file.type != "image/jpg" &&
          file.type != "image/jpeg"
        ) {
          return "São permitidos apenas imagens!";
        }
        self.delivery_logo = file;
        const base64 = await this.fileToBase64(file);
        if (base64) self.delivery_logo_url = base64;

        var reader = new FileReader(); //define a Reader
        reader.onload = function (f) {
          var file_result = this.result; // this == reader, get the loaded file "result"
          var file_wordArr = CryptoJS.lib.WordArray.create(
            file_result as ArrayBuffer
          ); //convert blob to WordArray , see https://code.google.com/p/crypto-js/issues/detail?id=67
          var sha1_hash = CryptoJS.SHA1(file_wordArr); //calculate SHA1 hash
          self.delivery_logo_sha1 = sha1_hash.toString();
        };
        reader.readAsArrayBuffer(file); //read file as ArrayBuffer
      },
    });
  }
  async switchProductStatus(product) {
    const self = this;

    self.app
      .confirm(
        "Tem certeza?",
        product.status
          ? "Ao inativar o produto, não será possível realizar novas vendas do mesmo"
          : "Ao ativar o produto, o mesmo ficará disponível para vendas"
      )
      .then((d) => {
        if (d) {
          self.spinner.show(undefined, {
            type: "ball-triangle-path",
            size: "medium",
            bdColor: "rgba(0, 0, 0, 0.8)",
            color: "#fff",
            fullScreen: true,
          });

          let chamada;
          let body = {
            product: product.id,
            status: product.status ? false : true,
          };

          chamada = self.apiCall.put(
            "seller/me/subconta/switchproductstatus/",
            body
          );

          chamada.subscribe(async (data) => {
            await self.spinner.hide();
            if (data.success) {
              self.ngOnInit();
              await this.app.alert(
                "Tudo certo!",
                product.status
                  ? "Produto Desativado com Sucesso!"
                  : "Produto Ativado com Sucesso! ",
                "success"
              );
            } else {
              await this.app.alert("Ops :(", data.message, "error");
              self.spinner.hide();
            }
          });
        }
      });
  }

  async editProduct(product, modal) {
    const self = this;

    this.resetPlano();
    this.newProduct = {
      id: product.id,
      name: product.name,
      payable_with: product.payable_with,
      plan_type: this.tiposPlanos.find((p) => p.id == product.plan_type),
      description: product.description,
      identifier: product.identifier,
      value_cents: product.value_cents
        ? (product.value_cents / 100).toFixed(2).toString().replace(".", ",")
        : null,
      product_image: product.product_image,
      delivery_video: product.delivery_video,
      delivery_logo: product.delivery_logo,
      delivery_image: product.delivery_image,
      delivery_instructions: product.delivery_instructions,
      delivery_link: product.delivery_link,
      checkout_url: product.checkout_url,
      status: product.status,
      towAccess: product.towAccess,
    };

    if (product.plan_type == 1) {
      this.resetPlans();

      let planoMensal = null;
      let planoTrimestral = null;
      let planoSemestral = null;
      let planoAnual = null;

      planoMensal = product.Plans.find((x) => x.interval == 1);
      planoTrimestral = product.Plans.find((x) => x.interval == 3);
      planoSemestral = product.Plans.find((x) => x.interval == 6);
      planoAnual = product.Plans.find((x) => x.interval == 12);

      this.productsPlans = {
        monthly: {
          id: planoMensal && planoMensal.id ? planoMensal.id : null,
          name: planoMensal && planoMensal.name ? planoMensal.name : null,
          identifier:
            planoMensal && planoMensal.identifier
              ? planoMensal.identifier
              : null,
          status:
            planoMensal && planoMensal.status ? planoMensal.status : false,
          value_cents:
            planoMensal && planoMensal.value_cents
              ? (planoMensal.value_cents / 100)
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")
              : null,
          max_cycles:
            planoMensal && planoMensal.max_cycles ? planoMensal.max_cycles : 0,
          interval: 1,
          interval_type: "months",
          iugu_id:
            planoMensal && planoMensal.iugu_id ? planoMensal.iugu_id : null,
        },
        quarterly: {
          id: planoTrimestral && planoTrimestral.id ? planoTrimestral.id : null,
          name:
            planoTrimestral && planoTrimestral.name
              ? planoTrimestral.name
              : null,
          identifier:
            planoTrimestral && planoTrimestral.identifier
              ? planoTrimestral.identifier
              : null,
          status:
            planoTrimestral && planoTrimestral.status
              ? planoTrimestral.status
              : false,
          value_cents:
            planoTrimestral && planoTrimestral.value_cents
              ? (planoTrimestral.value_cents / 100)
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")
              : null,
          interval: 3,
          interval_type: "months",
          iugu_id:
            planoTrimestral && planoTrimestral.iugu_id
              ? planoTrimestral.iugu_id
              : null,
        },
        semiannual: {
          id: planoSemestral && planoSemestral.id ? planoSemestral.id : null,
          name:
            planoSemestral && planoSemestral.name ? planoSemestral.name : null,
          identifier:
            planoSemestral && planoSemestral.identifier
              ? planoSemestral.identifier
              : null,
          status:
            planoSemestral && planoSemestral.status
              ? planoSemestral.status
              : false,
          value_cents:
            planoSemestral && planoSemestral.value_cents
              ? (planoSemestral.value_cents / 100)
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")
              : null,
          interval: 6,
          interval_type: "months",
          iugu_id:
            planoSemestral && planoSemestral.iugu_id
              ? planoSemestral.iugu_id
              : null,
        },
        annual: {
          id: planoAnual && planoAnual.id ? planoAnual.id : null,
          name: planoAnual && planoAnual.name ? planoAnual.name : null,
          identifier:
            planoAnual && planoAnual.identifier ? planoAnual.identifier : null,
          status: planoAnual && planoAnual.status ? planoAnual.status : false,
          value_cents:
            planoAnual && planoAnual.value_cents
              ? (planoAnual.value_cents / 100)
                  .toFixed(2)
                  .toString()
                  .replace(".", ",")
              : null,
          interval: 12,
          interval_type: "months",
          iugu_id: planoAnual && planoAnual.iugu_id ? planoAnual.iugu_id : null,
        },
      };
    }

    if (product.payable_with)
      this.tipoPagamentoEscolhido = JSON.parse(product.payable_with);
    this.tipoPlanoEscolhido = this.tiposPlanos.find(
      (x) => x.id == this.newProduct.plan_type
    );
    if (this.newProduct.product_image)
      this.product_image_url = this.newProduct.product_image;
    if (this.newProduct.delivery_image)
      this.delivery_image_url = this.newProduct.delivery_image;
    if (this.newProduct.delivery_video) {
      if (
        this.newProduct.delivery_video.startsWith(
          "https://www.youtube.com/watch?"
        ) ||
        this.newProduct.delivery_video.startsWith(
          "http://www.youtube.com/watch?"
        )
      ) {
        self.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(
          this.newProduct.delivery_video.replace(
            "https://www.youtube.com/watch?v=",
            "https://www.youtube.com/embed/"
          )
        );
      }
      this.url_video = this.newProduct.delivery_video;
    }
    if (this.newProduct.delivery_logo)
      this.delivery_logo_url = this.newProduct.delivery_logo;
    const modalRef = this.modalService.open(modal, {
      centered: true,
      size: "lg",
    });
  }

  checkFormasPagamento() {
    const self = this;

    if (this.tipoPagamentoEscolhido.length == 0) {
      this.resetPaymentMethods();
    } else {
      if (this.tipoPagamentoEscolhido.filter((x) => x.id == "all").length > 0) {
        this.tipoPagamentoEscolhido = [
          {
            id: "all",
            label: "Todas as Formas",
          },
        ];

        this.tiposPagamento = [
          {
            id: "all",
            label: "Todas as Formas",
          },
        ];
      }
    }
  }

  compartilharProduto(plano, modal) {
    const self = this;

    this.checkoutLink = plano.checkout_url;

    this.clipboardApi.copyFromContent(this.checkoutLink);

    this.modalService.open(modal, { centered: true });
  }

  viewThankyouPage(plano) {
    const self = this;

    this.thankYouLPreviewUrl =
      "https://app.opersonaldigital.com.br/pages/posvenda/" +
      btoa(JSON.stringify(plano.id)).replace("==", "").replace("=", "") +
      "/" +
      "view" +
      "/?sellerview=1";

    window.open(this.thankYouLPreviewUrl, "_blank");
  }

  copyCheckoutLink(checkoutLink) {
    this.clipboardApi.copyFromContent(checkoutLink);
  }

  resetPaymentMethods() {
    const self = this;

    this.tiposPagamento = [];

    if (this.account.creditcard_payment) {
      this.tiposPagamento.push({
        id: "credit_card",
        label: "Cartão de Crédito",
      });
    }

    if (this.account.bankslip_payment) {
      this.tiposPagamento.push({
        id: "bank_slip",
        label: "Boleto",
      });
    }

    if (this.account.pix_payment) {
      this.tiposPagamento.push({
        id: "pix",
        label: "PIX",
      });
    }

    if (this.tiposPagamento.length == 3) {
      this.tiposPagamento.push({
        id: "all",
        label: "Todas as formas",
      });
    }
  }

  helperInfos(info, modal) {
    const self = this;

    if (info == 1) {
      this.helperType = 1;
      this.helperImage =
        "https://files.wecodde.com/file/wecodde/tela_checkout.png";
    } else if (info == 2) {
      this.helperType = 2;
      this.helperImage =
        "https://files.wecodde.com/file/wecodde/OPD+P%C3%A1gina+Agradecimento+(1).jpg";
    }

    this.modalService.open(modal, { centered: true, size: "xl" });
  }

  getTabelaParcelamento() {
    for (let p of this.parcelado) {
      p.mensalidadeSemTaxa = 0;
    }
    this.parcelamentos = [];
    let valorSemTaxa = this.newProduct.value_cents;
    let valorComTaxa =
      this.newProduct.value_cents - (this.newProduct.value_cents * 5.1) / 100;
    valorComTaxa = valorComTaxa - 0.4;
    // let parcelasAtuaisSemTaxa = valorSemTaxa / this.parcelaSelecionada.parcela
    // let parcelasAtuaisComTaxa = valorComTaxa / this.parcelaSelecionada.parcela
    //Pagamentos com cartão de crédito: 5,1% sob o valor do pagamento + R$ 0,40 (por transação) do valor total
    //3.45% cada mensalidade
    //aplicar a taxa a cada parcela? ou em cima do calcula de cima?
    //no for jogar pro array o valor de cada parcela
    //valor liquido tirando as taxas e a parte do app
    for (let p of this.parcelado) {
      if (p.parcela <= this.parcelaSelecionada.parcela) {
        let parcelasAtuaisSemTaxa = valorSemTaxa / p.parcela;
        let parcelasAtuaisComTaxa = valorComTaxa / p.parcela;
        let valorMensalComTaxa = (
          parcelasAtuaisComTaxa -
          (parcelasAtuaisComTaxa * 3.45) / 100
        ).toFixed(2);
        p.mensalidadeSemTaxa = Number(parcelasAtuaisSemTaxa);
        p.mensalidadeComTaxa = Number(valorMensalComTaxa);
        p.valorLiquido = Number(valorMensalComTaxa) * p.parcela;
        this.parcelamentos.push(valorMensalComTaxa);
      }
    }

    // switch (this.parcelaSelecionada) {
    //   case 1:

    //       break;
    //   case 2:

    //       break;
    //   case 3:

    //       break;
    //   case 4:

    //       break;
    //   case 5:

    //       break;

    // }
  }

  formatPreco(valor) {
    let retorno = valor.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
    return retorno;
  }

  changeAtrelarAluno($event) {
    this.isAtrelarAluno = $event;
  }
}
