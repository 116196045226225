<div class="row text-left">
  <div class="col-12">
    <div class="d-flex justify-content-between">
      <h2 class="text-bold-300" style="padding: 1.5rem">Banco de Treinos</h2>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="d-flex justify-content-between">
    <div class="col-sm-12">
      <button
        class="btn btn-warning border-right-warning mb-2 mb-md-0 pulsingButton"
        (click)="OpenModalXl(contentTutoriais)"
      >
        <i class="fa fa-play-circle-o"></i> Ver Vídeo Tutorial
      </button>
    </div>
  </div>
</div>
<div class="row mb-2">
  <div class="col">
    <ng-select
      [notFoundText]="'Nenhuma pasta encontrada'"
      [items]="folders"
      bindLabel="folder"
      name="pasta"
      placeholder="Selecionar Pasta"
      id="pasta"
      (change)="onFilterFolder()"
      [(ngModel)]="folderFiltered"
    >
      <ng-template ng-label-tmp let-item="item">
        <span>{{ item.folder }}</span>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-index="index"
        let-search="searchTerm"
      >
        <span
          >{{ item.folder
          }}<button
            type="button"
            class="btn btn-sm btn-outline-warning ml-2 float-right"
            (click)="onEditFolder(item, updatePasta)"
          >
            <i class="fa fa-edit"></i></button
          ><button
            type="button"
            class="btn btn-sm btn-outline-danger ml-2 float-right"
            (click)="onDeleteFolder(item)"
          >
            <i class="fa fa-trash"></i></button
        ></span>
      </ng-template>
    </ng-select>
  </div>

  <div class="col" *ngIf="folderFiltered">
    <button
      type="button"
      (click)="exportarPastaTreinos(exportFolderDetail)"
      class="btn btn-block btn-outline-warning btn-icon"
    >
      <i class="fa fa-users"></i>
    </button>
  </div>
  <div class="col">
    <div class="d-md-none" [ngClass]="folderFiltered && 'mt-2'" role="group">
      <div class="btn-group btn-group-lg float-right" role="group">
        <button
          type="button"
          class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
          (click)="openCadastroFolder(cadastroPasta)"
        >
          <i class="fa fa-folder-open"></i>
        </button>
        <button
          type="button"
          class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
          (click)="
            openCreationTypeModal(null, false, trainingCreationTypeModal)
          "
        >
          <i class="fa fa-plus"></i>
        </button>
      </div>
    </div>

    <div class="btn-group btn-group-lg d-none d-md-block" role="group">
      <div class="btn-group btn-group-lg float-right" role="group">
        <button
          type="button"
          class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
          (click)="openCadastroFolder(cadastroPasta)"
        >
          Nova Pasta
        </button>
        <button
          type="button"
          class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
          (click)="
            openCreationTypeModal(null, false, trainingCreationTypeModal)
          "
        >
          Novo Treino
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row mb-2">
  <div class="col-6">
    <input
      type="text"
      id="volt"
      class="form-control"
      style="background-color: white"
      placeholder="Procurar treino"
      name="volt"
      [(ngModel)]="treinoProcurado"
      (keyup)="onSearchTreino()"
    />
  </div>
  <div *ngIf="user && !user.getTrainingsModels" class="col-6 text-right">
    <button
      type="button"
      (click)="copymodeltrainings()"
      class="btn btn-lg btn-outline-warning d-none d-md-block float-right btn-copy-trainings"
    >
      Copiar Treinos Modelo
      <span class="badge badge-pill badge-danger">Novo</span>
    </button>
    <button
      type="button"
      (click)="copymodeltrainings()"
      class="btn btn-lg btn-outline-warning btn-icon d-md-none btn-copy-trainings"
    >
      <i class="fa fa-copy"></i>
    </button>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body pr-0 pl-0">
        <ul class="list-group">
          <li
            *ngFor="
              let training of trainingsFiltered
                | slice
                  : (pageTr - 1) * pageSize
                  : (pageTr - 1) * pageSize + pageSize
            "
            class="list-group-item"
          >
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">{{ training.name }}</h5>
                <span class="text-muted">{{
                  getNameOfTypeTraing(training.type)
                }}</span>
              </div>

              <div class="d-md-none" role="group">
                <div class="dropdown btn-group-sm mr-1 mb-1 mt-1" ngbDropdown>
                  <button
                    type="button"
                    class="btn btn-outline-warning btn-sm dropdown-toggle"
                    ngbDropdownToggle
                  >
                    <i class="ft-more-vertical"></i>
                  </button>
                  <div ngbDropdownMenu class="dropdown-menu" role="menu">
                    <a
                      class="dropdown-item"
                      (click)="openFoldersOfTreino(pastasDoTreino, training)"
                      >Visualizar Pastas</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="
                        viewExercicios(training, contentExerciciosTreino)
                      "
                      >Visualizar Exercícios</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="exportarTreinoAluno(training, exportDetail)"
                      >Exportar para alunos</a
                    >
                    <a
                      *ngIf="training.type != 4"
                      class="dropdown-item"
                      (click)="
                        exportarTreinoPrograma(training, exportProgramaDetail)
                      "
                      >Exportar para programa ou desafio</a
                    >
                    <a class="dropdown-item" (click)="duplicarTreino(training)"
                      >Duplicar este treino</a
                    >
                    <a
                      class="dropdown-item"
                      (click)="
                        openTreino(
                          training,
                          training.isExperimental ? true : false
                        )
                      "
                      >Editar este treino</a
                    >
                    <a class="dropdown-item" (click)="deletarTreino(training)"
                      >Deletar este treino</a
                    >
                  </div>
                </div>
              </div>
              <div
                class="btn-group btn-group-lg d-none d-md-block"
                role="group"
              >
                <button
                  type="button"
                  placement="top"
                  (click)="openFoldersOfTreino(pastasDoTreino, training)"
                  ngbTooltip="Visualizar Pastas."
                  class="btn btn-outline-warning btn-icon"
                >
                  <i class="fa fa-folder"></i>
                </button>
                <button
                  type="button"
                  placement="top"
                  (click)="viewExercicios(training, contentExerciciosTreino)"
                  ngbTooltip="Visualizar exercícios."
                  class="btn btn-outline-warning btn-icon"
                >
                  <i class="fa fa-eye"></i>
                </button>
                <button
                  type="button"
                  placement="top"
                  (click)="exportarTreinoAluno(training, exportDetail)"
                  ngbTooltip="Exportar este treino para outros alunos."
                  class="btn btn-outline-warning btn-icon"
                >
                  <i class="fa fa-users"></i>
                </button>
                <button
                  *ngIf="training.type != 4"
                  type="button"
                  placement="top"
                  (click)="
                    exportarTreinoPrograma(training, exportProgramaDetail)
                  "
                  ngbTooltip="Exportar este treino para um programa ou desafio."
                  class="btn btn-sm btn-outline-warning btn-icon"
                >
                  <i class="fa fa-th-large"></i>
                </button>
                <button
                  type="button"
                  placement="top"
                  (click)="duplicarTreino(training)"
                  ngbTooltip="Duplicar este treino."
                  class="btn btn-outline-warning btn-icon"
                >
                  <i class="fa fa-copy"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-lg btn-outline-warning mb-2 mb-md-0"
                  (click)="
                    openTreino(training, training.isExperimental ? true : false)
                  "
                  placement="top"
                  ngbTooltip="Editar este treino"
                >
                  <i class="fa fa-edit"></i>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-warning"
                  (click)="deletarTreino(training)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="trainingsFiltered.length > 0">
  <div class="col">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        [(page)]="pageTr"
        [pageSize]="pageSize"
        [maxSize]="2"
        [rotate]="true"
        [boundaryLinks]="true"
        size="sm"
        [collectionSize]="trainingsFiltered.length"
      ></ngb-pagination>
    </div>
  </div>
</div>
<div class="row" *ngIf="trainings.length == 0">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item">
            <div class="media">
              <div class="media-body">
                <h5 class="mt-0">
                  Você ainda não cadastrou um treino para seu banco de dados.
                </h5>
                <span>Cadastre o primeiro treino!</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<ng-template #contentExerciciosTreino>
  <div class="form-row" style="margin: 5px">
    <div class="table-responsive">
      <table class="table table-bordered">
        <thead class="thead-dark">
          <tr>
            <th class="text-center p-2">Exercício</th>
            <th
              class="text-center p-2"
              *ngIf="typeTrainingInView && typeTrainingInView == 1"
            >
              Conjunto
            </th>
            <th class="text-center p-2">Séries</th>
            <th class="text-center p-2">Duração (seg)</th>
            <th class="text-center p-2">Repetições</th>
            <th class="text-center p-2">Carga Total</th>
            <th class="text-center p-2">Velocidade</th>
            <th class="text-center p-2">Descanso (seg)</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let exercicio of listaExercicios
                | slice
                  : (pageEx - 1) * pageSizeExec
                  : (pageEx - 1) * pageSizeExec + pageSizeExec
            "
          >
            <td class="text-center p-2">{{ exercicio.name }}</td>
            <td
              class="text-center p-2"
              *ngIf="typeTrainingInView && typeTrainingInView == 1"
            >
              {{ getNameExercise(exercicio) }}
            </td>
            <td class="text-center p-2">
              {{ exercicio.series ? exercicio.series : "--" }}
            </td>
            <td class="text-center p-2">
              {{ exercicio.duration ? exercicio.duration : "--" }}
            </td>
            <td class="text-center p-2">
              {{ exercicio.repetitions ? exercicio.repetitions : "--" }}
            </td>
            <td class="text-center p-2">
              {{ exercicio.load ? exercicio.load : "--" }}
            </td>
            <td class="text-center p-2">
              {{ exercicio.speed ? exercicio.speed : "--" }}
            </td>
            <td class="text-center p-2">
              {{ exercicio.breakTime ? exercicio.breakTime : "--" }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="form-row" style="margin: 5px">
    <div class="d-flex justify-content-center">
      <ngb-pagination
        style="margin-top: 10px"
        *ngIf="listaExercicios.length > 0"
        [(page)]="pageEx"
        [pageSize]="pageSizeExec"
        [maxSize]="2"
        [rotate]="true"
        [boundaryLinks]="true"
        size="sm"
        [collectionSize]="listaExercicios.length"
      ></ngb-pagination>
    </div>
  </div>
</ng-template>
<ng-template #updatePasta let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Pasta</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-2">
                <label for="folderForUpdateName">Nome da Pasta</label>
                <input
                  type="text"
                  id="folderForUpdateName"
                  class="form-control"
                  name="folderForUpdateName"
                  [(ngModel)]="folderForUpdate.folder"
                  #folderForUpdateName="ngModel"
                  required
                />
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-danger"
                    (click)="cancelaPasta(modal)"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="atualizarPasta(modal)"
                    [disabled]="
                      !folderForUpdate ||
                      !folderForUpdate.id ||
                      !folderForUpdate.folder
                    "
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #pastasDoTreino let-modal>
  <div class="card">
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-2 scrollable-box-1">
                <label for="folder">Pastas</label>
                <ng-select
                  [appendTo]="'body'"
                  [notFoundText]="'Nenhuma pasta encontrada'"
                  [items]="folders"
                  bindLabel="folder"
                  name="folder"
                  placeholder="Selecionar Pastas"
                  id="folder"
                  [(ngModel)]="foldersOfTreino"
                  multiple="true"
                ></ng-select>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-danger"
                    (click)="cancelaPasta(modal)"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="atualizarPastasTreino(modal)"
                    [disabled]="!foldersOfTreino || foldersOfTreino.length == 0"
                  >
                    Atualizar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #cadastroPasta let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Pasta</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-2">
                <label for="nomePasta">Nome da Pasta</label>
                <input
                  type="text"
                  id="nomePasta"
                  class="form-control"
                  name="nomePasta"
                  [(ngModel)]="nomePasta"
                  #inputName="ngModel"
                  required
                />
              </div>
              <div class="form-group mb-2 scrollable-box-2">
                <label for="trainings">Treinos</label>
                <ng-select
                  [appendTo]="'.scrollable-box-2'"
                  [notFoundText]="'Nenhum treino encontrado'"
                  [items]="trainings"
                  bindLabel="name"
                  name="trainings"
                  placeholder="Selecionar Treinos"
                  id="trainings"
                  [(ngModel)]="treinosSelecionadosPasta"
                  multiple="true"
                >
                  <ng-template
                    ng-multi-label-tmp
                    let-items="items"
                    let-clear="clear"
                  >
                    <div
                      *ngIf="treinosSelecionadosPasta.length == 1"
                      class="ng-values-list"
                    >
                      <div
                        class="ng-value"
                        *ngFor="let item of items | slice : 0 : 1"
                      >
                        <span class="ng-value-label">{{ item.name }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                          >×</span
                        >
                      </div>
                    </div>

                    <div
                      *ngIf="treinosSelecionadosPasta.length > 1"
                      class="ng-summary-list"
                    >
                      <div class="ng-value">
                        <span class="ng-value-label"
                          >{{
                            treinosSelecionadosPasta.length
                          }}
                          Selecionados</span
                        >
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-danger"
                    (click)="cancelaPasta(modal)"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="cadastrarPasta(modal)"
                    [disabled]="
                      !nomePasta ||
                      !treinosSelecionadosPasta ||
                      treinosSelecionadosPasta.length == 0
                    "
                  >
                    Cadastrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #exportFolderDetail let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">
        Exportar para Alunos pasta {{ folderFiltered.folder }}
      </h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-2 scrollable-box-3">
                <label for="trainings">Treinos</label>
                <ng-select
                  [appendTo]="'body'"
                  [notFoundText]="'Nenhum treino encontrado'"
                  [items]="trainings"
                  bindLabel="name"
                  name="trainings"
                  placeholder="Selecionar Treinos"
                  id="trainings"
                  [(ngModel)]="treinosSelecionadosParaExportar"
                  multiple="true"
                ></ng-select>
              </div>
              <div class="form-group mb-2 scrollable-box-4">
                <label for="grupoSelecionado">Copiar Alunos de Grupo:</label>
                <ng-select
                  [appendTo]="'body'"
                  [notFoundText]="'Nenhum grupo encontrado'"
                  [items]="groups"
                  bindLabel="group"
                  name="grupoSelecionado"
                  placeholder="Selecionar Grupo"
                  id="grupoSelecionado"
                  (change)="copiarAlunosGrupoExpTreino()"
                  [(ngModel)]="grupoSelecionado"
                ></ng-select>
              </div>
              <div class="form-group mb-2 scrollable-box-5">
                <label for="aluno">Alunos</label>
                <ng-select
                  [appendTo]="'body'"
                  [notFoundText]="'Nenhum aluno encontrado'"
                  [items]="relationsFiltered"
                  bindLabel="Customer.name"
                  name="model"
                  placeholder="Selecionar Alunos"
                  id="aluno"
                  [(ngModel)]="alunosSelecionadosParaExportar"
                  multiple="true"
                ></ng-select>
              </div>
              <div class="form-group mb-2">
                <label for="agend">Agendamento</label>
                <div class="input-group">
                  <input
                    type="text"
                    id="agend"
                    placeholder="00/00/0000"
                    class="form-control"
                    name="agend"
                    [(ngModel)]="agendamentoParaExportar"
                    ngbDatepicker
                    #agend="ngbDatepicker"
                    [container]="'body'"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-secondary input-group-text"
                      (click)="agend.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group mb-2">
                <label for="venc">Vencimento</label>
                <div class="input-group">
                  <input
                    type="text"
                    id="venc"
                    placeholder="00/00/0000"
                    class="form-control"
                    name="venc"
                    [(ngModel)]="vencimentoParaExportar"
                    ngbDatepicker
                    #venc="ngbDatepicker"
                    [container]="'body'"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-secondary input-group-text"
                      (click)="venc.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-danger"
                    (click)="cancelaExport(modal)"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="exportarPastaAlunos(modal)"
                    [disabled]="
                      !agendamentoParaExportar ||
                      !alunosSelecionadosParaExportar ||
                      alunosSelecionadosParaExportar.length == 0 ||
                      !treinosSelecionadosParaExportar ||
                      treinosSelecionadosParaExportar.length == 0
                    "
                  >
                    Exportar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #exportDetail let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Exportar para Alunos</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-2 scrollable-box-6">
                <label for="grupoSelecionado">Copiar Alunos de Grupo:</label>
                <ng-select
                  [appendTo]="'.scrollable-box-6'"
                  [notFoundText]="'Nenhum grupo encontrado'"
                  [items]="groups"
                  bindLabel="group"
                  name="grupoSelecionado"
                  placeholder="Selecionar Grupo"
                  id="grupoSelecionado"
                  (change)="copiarAlunosGrupoExpTreino()"
                  [(ngModel)]="grupoSelecionado"
                ></ng-select>
              </div>
              <div class="form-group mb-2 scrollable-box-7">
                <label for="aluno">Alunos</label>
                <ng-select
                  [appendTo]="'.scrollable-box-7'"
                  [notFoundText]="'Nenhum aluno encontrado'"
                  [items]="relationsFiltered"
                  bindLabel="Customer.name"
                  name="model"
                  placeholder="Selecionar Alunos"
                  id="aluno"
                  [(ngModel)]="alunosSelecionadosParaExportar"
                  multiple="true"
                >
                  <ng-template
                    ng-multi-label-tmp
                    let-items="items"
                    let-clear="clear"
                  >
                    <div
                      *ngIf="alunosSelecionadosParaExportar.length == 1"
                      class="ng-values-list"
                    >
                      <div
                        class="ng-value"
                        *ngFor="let item of items | slice : 0 : 1"
                      >
                        <span class="ng-value-label">{{
                          item.Customer.name
                        }}</span>
                        <span class="ng-value-icon right" (click)="clear(item)"
                          >×</span
                        >
                      </div>
                    </div>
                    <div
                      *ngIf="alunosSelecionadosParaExportar.length > 1"
                      class="ng-summary-list"
                    >
                      <div class="ng-value">
                        <span class="ng-value-label"
                          >{{
                            alunosSelecionadosParaExportar.length
                          }}
                          Selecionados</span
                        >
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-group mb-2">
                <label for="scheduledDate">Agendamento:</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="00/00/0000"
                    name="scheduledDate"
                    [(ngModel)]="scheduledDate"
                    ngbDatepicker
                    #d2="ngbDatepicker"
                    [container]="'body'"
                    [minDate]="minScheduledDate"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-secondary input-group-text"
                      (click)="d2.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-group mb-2">
                <label for="dueDate">Vencimento:</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="00/00/0000"
                    name="dueDate"
                    [(ngModel)]="dueDate"
                    ngbDatepicker
                    #d3="ngbDatepicker"
                    [container]="'body'"
                    [ngClass]="{
                      'is-invalid': dueDateError
                    }"
                  />
                  <div class="input-group-append">
                    <button
                      type="button"
                      class="btn btn-secondary input-group-text"
                      (click)="d3.toggle()"
                    >
                      <i class="fa fa-calendar"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-danger"
                    (click)="cancelaExportacaoAlunos(modal)"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    (click)="exportarAlunos(modal)"
                    [disabled]="
                      !alunosSelecionadosParaExportar ||
                      alunosSelecionadosParaExportar.length == 0 ||
                      !scheduledDate
                    "
                  >
                    Exportar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>
<ng-template #exportProgramaDetail let-modal>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Exportar para Programas ou Desafios</h4>
    </div>
    <div class="card-content">
      <div class="card-body">
        <form #exForm="ngForm" class="editForm" novalidate>
          <div class="form-row">
            <div class="col-12">
              <br />
              <div class="form-group mb-2 scrollable-box-8">
                <label for="grupoSelecionado"
                  >Escolha o Programa ou Desafio:</label
                >
                <ng-select
                  [appendTo]="'.scrollable-box-8'"
                  [notFoundText]="'Nenhum programa ou desafio encontrados'"
                  [items]="groupsTrainings"
                  bindLabel="title"
                  name="programaDesafioSelecionado"
                  placeholder="Selecionar Programa ou Desafio"
                  id="programaDesafioSelecionado"
                  [(ngModel)]="programaDesafioSelecionado"
                ></ng-select>
              </div>

              <div class="form-row mt-4">
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg btn-block btn-danger"
                    (click)="cancelaExportacaoProgramas(modal)"
                  >
                    Cancelar
                  </button>
                </div>
                <div class="col-6 text-center">
                  <button
                    type="button"
                    class="btn btn-lg gradient-mint btn-block"
                    [disabled]="!programaDesafioSelecionado"
                    (click)="exportarProgramasDesafios(modal)"
                  >
                    Exportar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
</ng-template>

<ng-template #contentVideo>
  <ng-template
    [ngIf]="
      urlToPlay.startsWith('https://www.youtube.com/') ||
      urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <div class="embed-container">
      <iframe [src]="safeURL" frameborder="0" allowfullscreen></iframe>
    </div>
  </ng-template>
  <ng-template
    [ngIf]="
      !urlToPlay.startsWith('https://www.youtube.com/') &&
      !urlToPlay.startsWith('http://www.youtube.com/')
    "
  >
    <video controls style="width: 100%">
      <source [src]="safeURL" type="video/mp4" />
    </video>
  </ng-template>
</ng-template>

<ng-template #contentTutoriais let-modal>
  <div class="card-header">
    <h4 class="card-title">Tutoriais</h4>
  </div>
  <button
    class="btn btn-primary btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center m-2"
    (click)="modal.close('Close click')"
  >
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>
  <div class="form-row" style="margin: 5px">
    <ul class="list-group mb-2" style="width: 100%">
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">1</span>
          <div class="media-body">
            <h5 class="mt-0">Como adicionar um novo treino</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=iZ5Ci7O8ols',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=iZ5Ci7O8ols',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">2</span>
          <div class="media-body">
            <h5 class="mt-0">Periodização automatizada</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=brg5wo1VHjA',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=brg5wo1VHjA',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">3</span>
          <div class="media-body">
            <h5 class="mt-0">Biset/triset</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=oc5Ek3sjcxk',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=oc5Ek3sjcxk',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">4</span>
          <div class="media-body">
            <h5 class="mt-0">Como montar circuitos e intervalados</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=AQ1g_qVnVfg',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=AQ1g_qVnVfg',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">5</span>
          <div class="media-body">
            <h5 class="mt-0">Como criar treino avaliativo</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=FVtWmcRTRok',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=FVtWmcRTRok',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">6</span>
          <div class="media-body">
            <h5 class="mt-0">Como criar pastas e seu banco de treinos</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=0S5mr2NWJRE',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=0S5mr2NWJRE',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">7</span>
          <div class="media-body">
            <h5 class="mt-0">Como exportar um treino para outro aluno</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=krjQV6R2Kj4',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=krjQV6R2Kj4',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <div class="media">
          <span class="dotModal align-middle mr-2">8</span>
          <div class="media-body">
            <h5 class="mt-0">Como gerar um treino em PDF</h5>
            <span></span>
          </div>
          <div class="d-none d-md-block">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=c_hityO0H3U',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
          <div class="d-md-none">
            <button
              type="button"
              class="btn btn-outline-warning mb-2 mb-md-0"
              (click)="
                viewMidia(
                  'https://www.youtube.com/watch?v=c_hityO0H3U',
                  contentVideo
                )
              "
            >
              Vídeo
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #trainingCreationTypeModal let-modal>
  <div class="card m-0 rounded-lg">
    <div class="card-header py-3 bg-primary position-relative">
      <h1 class="card-title text-center white font-medium-4 m-0">
        Novo treino
      </h1>
      <button
        type="button"
        title="Fechar"
        class="btn btn-primary btn-sm position-absolute close-button p-1 d-flex flex-column justify-content-center align-items-center"
        (click)="modal.close('Close click')"
      >
        <i class="fa fa-times font-bold-400 white" aria-hidden="true"></i>
      </button>
    </div>
    <div class="card-content">
      <div class="card-body">
        <div
          class="w-100 flex-grow-1 d-flex flex-column justify-content-center align-items-center pt-1"
        >
          <h2 class="black font-medium-3 font-bold-400 mb-3">
            Ta sabendo da novidade?
          </h2>
          <div class="w-100 d-flex justify-content-center align-items-end mb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="84.77"
              viewBox="0 0 131 138"
              fill="none"
              class="training-creation-type__svg"
            >
              <g clip-path="url(#clip0_653_332)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M71.125 28.6875H62.6875V37.125C62.6875 38.0531 61.9332 38.8125 61 38.8125C60.0668 38.8125 59.3125 38.0531 59.3125 37.125V28.6875H50.875C49.9418 28.6875 49.1875 27.9281 49.1875 27C49.1875 26.0719 49.9418 25.3125 50.875 25.3125H59.3125V16.875C59.3125 15.9469 60.0668 15.1875 61 15.1875C61.9332 15.1875 62.6875 15.9469 62.6875 16.875V25.3125H71.125C72.0582 25.3125 72.8125 26.0719 72.8125 27C72.8125 27.9281 72.0582 28.6875 71.125 28.6875ZM61 0C46.0876 0 34 12.0825 34 27C34 41.9175 46.0876 54 61 54C75.9124 54 88 41.9175 88 27C88 12.0825 75.9124 0 61 0Z"
                  fill="#FF5E19"
                />
              </g>
              <path
                d="M93.0248 107.31L87.9653 107.382C84.5234 107.402 83.112 104.991 84.8148 102L90.3647 92.1756L49.4389 92.3756L43.8505 102.208C42.8352 103.786 41.4621 105.103 39.8426 106.051C38.2231 106.999 36.4029 107.552 34.5297 107.665L29.4702 107.736C26.0283 107.757 24.6169 105.346 26.3197 102.355L43.2837 72.4805C44.2989 70.9023 45.6721 69.5857 47.2916 68.6376C48.9111 67.6895 50.7313 67.1367 52.6045 67.0239L57.6532 66.9587C61.1059 66.9318 62.5173 69.3427 60.8037 72.3402L54.6226 83.1846L95.568 82.9442L101.76 72.0935C102.775 70.5152 104.148 69.1986 105.768 68.2506C107.387 67.3025 109.208 66.7497 111.081 66.6369L116.14 66.5653C119.582 66.5447 120.994 68.9557 119.291 71.9469L102.302 101.778C101.299 103.365 99.9366 104.692 98.3247 105.653C96.7129 106.615 94.8971 107.182 93.0248 107.31Z"
                fill="#E0E0E0"
              />
              <path
                d="M41.3768 54.8558C35.4909 49.4427 24.0626 48.806 17.6164 55.47C16.6021 56.5212 15.7274 57.6987 15.0139 58.9734C10.0539 67.6578 10.1484 81.6928 15.2017 90.3248C16.0305 91.7969 17.0842 93.1305 18.3248 94.2772C24.1999 99.6967 35.6201 100.295 42.06 93.62C43.0743 92.5688 43.949 91.3913 44.6625 90.1166C49.6117 81.4386 49.5216 67.3864 44.4683 58.7544C43.6427 57.3048 42.6001 55.99 41.3768 54.8558Z"
                fill="#455A64"
              />
              <path
                d="M44.6454 90.1122C49.5946 81.4342 49.5045 67.3821 44.4512 58.7501C39.3979 50.1181 31.2906 50.1675 26.3413 58.8455C21.3921 67.5236 21.4759 81.5649 26.5292 90.1969C31.5825 98.8289 39.7025 98.801 44.6454 90.1122Z"
                fill="#37474F"
              />
              <path
                d="M31.9486 68.4238C33.852 65.084 36.9644 65.0684 38.9114 68.3942C39.8704 70.2557 40.377 72.3171 40.3901 74.4112C40.4031 76.5052 39.9223 78.5728 38.9866 80.4462C37.0832 83.786 33.9708 83.8016 32.0301 80.4866C31.0703 78.6236 30.5627 76.5607 30.5485 74.465C30.5344 72.3692 31.014 70.2997 31.9486 68.4238Z"
                fill="#455A64"
              />
              <path
                d="M36.6419 72.3677C36.9713 73.0049 37.1459 73.7108 37.1516 74.4281C37.1572 75.1454 36.9937 75.854 36.6743 76.4963C36.5757 76.7191 36.4237 76.9141 36.2318 77.0643C36.04 77.2144 35.8141 77.315 35.5741 77.3571L35.4009 77.3574C35.1685 77.3179 34.9485 77.2244 34.7587 77.0846C34.5689 76.9447 34.4146 76.7623 34.308 76.5521C33.9821 75.9123 33.8103 75.2051 33.8066 74.4871C33.8028 73.7691 33.9671 73.0602 34.2863 72.4171C34.386 72.1949 34.5393 72.0009 34.7324 71.8526C34.9255 71.7042 35.1524 71.6061 35.3928 71.567L35.5597 71.556C35.7912 71.5968 36.0097 71.6916 36.1977 71.8326C36.3857 71.9736 36.5379 72.1569 36.6419 72.3677Z"
                fill="#37474F"
              />
              <path
                d="M74.8878 72.1323C75.2163 72.7698 75.3904 73.4756 75.396 74.1928C75.4016 74.9099 75.2387 75.6184 74.9203 76.261C74.7763 76.5457 74.556 76.7847 74.284 76.9514C74.0725 77.0697 73.8334 77.1299 73.5911 77.1258L35.5741 77.3571L35.4009 77.3574C35.1685 77.3179 34.9485 77.2244 34.7587 77.0846C34.5689 76.9447 34.4146 76.7623 34.308 76.5521C33.9821 75.9123 33.8103 75.2051 33.8066 74.4871C33.8028 73.7691 33.9671 73.0602 34.2863 72.4171C34.386 72.1949 34.5393 72.0009 34.7324 71.8526C34.9255 71.7042 35.1524 71.6061 35.3928 71.567L35.5597 71.556L73.5767 71.3246C73.8492 71.3221 74.1167 71.3966 74.3487 71.5395C74.5806 71.6824 74.7675 71.8879 74.8878 72.1323Z"
                fill="#FAFAFA"
              />
              <path
                d="M102 54.4831C96.125 49.0636 84.6859 48.4332 78.2461 55.108C77.2299 56.1626 76.3518 57.3419 75.6328 58.6177C70.6836 67.2957 70.7736 81.3479 75.8269 89.9799C76.661 91.4484 77.7141 92.7812 78.95 93.9322C84.8359 99.3454 96.2453 99.9499 102.691 93.2858C103.708 92.2368 104.583 91.059 105.294 89.7824C110.243 81.1044 110.153 67.0522 105.106 58.431C104.28 56.963 103.232 55.6313 102 54.4831Z"
                fill="#455A64"
              />
              <path
                d="M105.28 89.7343C110.229 81.0563 110.139 67.0041 105.092 58.3829C100.045 49.7617 91.9315 49.8003 86.9823 58.4784C82.0331 67.1564 82.1124 81.2148 87.1764 89.8405C92.2405 98.4662 100.326 98.4294 105.28 89.7343Z"
                fill="#37474F"
              />
              <path
                d="M92.5892 68.0565C94.4927 64.7166 97.5988 64.6902 99.5458 68.016C100.505 69.8776 101.011 71.939 101.024 74.033C101.038 76.1271 100.557 78.1947 99.621 80.068C97.7176 83.4079 94.5944 83.4298 92.6538 80.1148C91.6963 78.2513 91.1915 76.1884 91.1803 74.0933C91.1691 71.9983 91.6518 69.9301 92.5892 68.0565Z"
                fill="#455A64"
              />
              <path
                d="M97.3201 71.9643C97.6486 72.6018 97.8227 73.3076 97.8284 74.0247C97.834 74.7419 97.671 75.4503 97.3526 76.0929C97.254 76.3157 97.102 76.5107 96.9101 76.6609C96.7182 76.811 96.4924 76.9116 96.2524 76.9538C96.1953 76.9654 96.1363 76.9655 96.0791 76.954C95.8467 76.9145 95.6268 76.821 95.437 76.6812C95.2472 76.5413 95.0929 76.3589 94.9863 76.1487C94.6566 75.5083 94.4823 74.7993 94.4775 74.0791C94.4726 73.3589 94.6373 72.6477 94.9583 72.0029C95.0587 71.7839 95.2109 71.5926 95.4018 71.4456C95.5927 71.2986 95.8166 71.2003 96.054 71.1592L96.2273 71.1589C96.4607 71.1955 96.6819 71.2879 96.8721 71.4281C97.0624 71.5682 97.216 71.7521 97.3201 71.9643Z"
                fill="#37474F"
              />
              <path
                d="M103.087 71.941C103.416 72.5767 103.589 73.2811 103.594 73.9968C103.599 74.7124 103.434 75.419 103.113 76.0588C102.969 76.3435 102.749 76.5826 102.477 76.7493C102.268 76.8737 102.027 76.9343 101.784 76.9236L96.2524 76.9538C96.1953 76.9654 96.1363 76.9655 96.0791 76.954C95.8467 76.9145 95.6268 76.821 95.437 76.6812C95.2472 76.5413 95.0929 76.3589 94.9863 76.1487C94.6566 75.5083 94.4823 74.7993 94.4775 74.0791C94.4726 73.3589 94.6373 72.6477 94.9583 72.0029C95.0587 71.7839 95.2109 71.5926 95.4018 71.4456C95.5927 71.2986 95.8166 71.2003 96.054 71.1592L96.2273 71.1589L101.764 71.1117C102.04 71.1103 102.311 71.1875 102.546 71.3343C102.78 71.4811 102.968 71.6915 103.087 71.941Z"
                fill="#FAFAFA"
              />
              <path
                d="M103.074 71.9189C103.405 72.5573 103.581 73.2647 103.588 73.9839C103.595 74.7031 103.432 75.4138 103.113 76.0583C103.01 76.2786 102.856 76.4709 102.664 76.6189C102.471 76.7669 102.246 76.8662 102.006 76.9083L101.833 76.9086C101.6 76.871 101.379 76.7783 101.189 76.6382C100.999 76.4981 100.845 76.3147 100.74 76.1032C100.413 75.4638 100.241 74.7565 100.237 74.0384C100.234 73.3202 100.398 72.6111 100.719 71.9683C100.817 71.7455 100.969 71.5505 101.161 71.4003C101.353 71.2502 101.579 71.1496 101.819 71.1075L101.992 71.1072C102.223 71.148 102.442 71.2428 102.63 71.3838C102.818 71.5248 102.97 71.7081 103.074 71.9189Z"
                fill="#E0E0E0"
              />
              <defs>
                <clipPath id="clip0_653_332">
                  <rect
                    width="54"
                    height="54"
                    fill="white"
                    transform="translate(34)"
                  />
                </clipPath>
              </defs>
            </svg>
            <h3
              class="text-secondary text-start font-medium-1 font-bold-400 ml-3 mb-3"
            >
              Prescreva treinos em até 2 minutos com a ajuda do I.A.GO
              (Inteligência artificial).
            </h3>
          </div>
          <div
            class="d-flex w-100 flex-column justify-content-center align-items-center"
          >
            <button
              type="button"
              class="btn btn-primary font-small-2 text-bold-600 d-flex justify-content-center align-items-center mb-2"
              (click)="
                openTrainingType(
                  modal,
                  OpenPersonalGPTModal(trainingGeneratorModal)
                )
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="#ffffff"
              >
                <path
                  d="M6.73315 13.5469L7.51761 15.1158C7.7831 15.6468 7.91584 15.9123 8.09318 16.1423C8.25054 16.3465 8.43356 16.5295 8.6377 16.6869C8.86777 16.8642 9.13325 16.997 9.66423 17.2624L11.2332 18.0469L9.66423 18.8314C9.13325 19.0968 8.86777 19.2296 8.6377 19.4069C8.43356 19.5643 8.25054 19.7473 8.09318 19.9515C7.91584 20.1815 7.7831 20.447 7.51761 20.978L6.73315 22.5469L5.94869 20.978C5.6832 20.447 5.55046 20.1815 5.37312 19.9515C5.21576 19.7473 5.03274 19.5643 4.8286 19.4069C4.59853 19.2296 4.33305 19.0968 3.80207 18.8314L2.23315 18.0469L3.80207 17.2624C4.33305 16.997 4.59853 16.8642 4.8286 16.6869C5.03274 16.5295 5.21576 16.3465 5.37312 16.1423C5.55046 15.9123 5.6832 15.6468 5.94869 15.1158L6.73315 13.5469Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15.2332 2.54688L16.4118 5.6113C16.6938 6.34453 16.8348 6.71114 17.0541 7.01952C17.2485 7.29283 17.4872 7.53163 17.7606 7.72597C18.0689 7.94524 18.4356 8.08625 19.1688 8.36826L22.2332 9.54688L19.1688 10.7255C18.4356 11.0075 18.0689 11.1485 17.7606 11.3678C17.4872 11.5622 17.2485 11.8009 17.0541 12.0743C16.8348 12.3826 16.6938 12.7493 16.4118 13.4825L15.2332 16.5469L14.0546 13.4825C13.7726 12.7493 13.6316 12.3826 13.4123 12.0743C13.2179 11.8009 12.9792 11.5622 12.7058 11.3678C12.3975 11.1485 12.0308 11.0075 11.2976 10.7255L8.23315 9.54688L11.2976 8.36826C12.0308 8.08625 12.3975 7.94524 12.7058 7.72597C12.9792 7.53163 13.2179 7.29283 13.4123 7.01952C13.6316 6.71114 13.7726 6.34453 14.0546 5.6113L15.2332 2.54688Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span class="ml-2 white">
                {{
                  planStatus.plano_ativo && planStatus.plano_em_dia
                    ? "Usar o I.A.GO"
                    : "Usar o I.A.GO"
                }}
              </span>
            </button>
            <button
              type="button"
              class="btn font-small-2 text-bold-600 d-flex justify-content-center align-items-center shadow-none"
              (click)="openTrainingType(modal, openTreino(null, true))"
            >
              <span class="mr-2 text-secondary">
                Continuar na forma tradicional
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none"
              >
                <path
                  d="M1.09277 1.76562L8.46631 9.13916L1.09277 16.5127"
                  stroke="#9E9E9E"
                  stroke-width="1.7556"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #trainingGeneratorModal let-modal>
  <app-training-wizard
    [customer]="customer"
    [oldTrainningsRaw]="trainings"
    [modalRef]="modal"
    [isModel]="true"
  ></app-training-wizard>
</ng-template>
<ngx-spinner></ngx-spinner>
