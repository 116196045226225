import { Injectable } from "@angular/core";
import { AbstractControl, Validators } from "@angular/forms";
import { CustomValidators } from "../../helpers/functions/customValidators";
import {
  InputsModeServiceInterface,
  LoadModeValue,
  RepDurModeValue,
} from "./inputs-mode.types";

@Injectable({
  providedIn: "root",
})
export class InputsModeService implements InputsModeServiceInterface {
  repDurMode: Map<number | string, RepDurModeValue> = new Map<
    number | string,
    RepDurModeValue
  >();
  loadMode: Map<number | string, LoadModeValue> = new Map<
    number | string,
    LoadModeValue
  >();

  constructor() {}

  setRepDurMode(key: number | string, value: RepDurModeValue): void {
    this.repDurMode.set(key, value);
  }

  setLoadMode(key: number | string, value: LoadModeValue): void {
    this.loadMode.set(key, value);
  }

  setRepDurModeControl(
    key: number | string,
    repControl: AbstractControl,
    durControl: AbstractControl
  ): void {
    if (!this.getRepDurMode(key)) return;

    if (this.getRepDurMode(key) === "dur") {
      durControl
        ?.get("hour")
        ?.setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(59),
        ]);
      durControl
        ?.get("minute")
        ?.setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(59),
        ]);
      durControl
        ?.get("second")
        ?.setValidators([
          Validators.required,
          Validators.min(0),
          Validators.max(59),
        ]);
      repControl?.clearValidators();
    } else if (this.getRepDurMode(key) === "rep") {
      repControl?.clearValidators();
      repControl?.setValidators([
        Validators.required,
        CustomValidators.notZero,
      ]);

      durControl?.get("hour")?.clearValidators();
      durControl?.get("minute")?.clearValidators();
      durControl?.get("second")?.clearValidators();
    }

    repControl?.updateValueAndValidity();
    durControl?.get("hour")?.updateValueAndValidity();
    durControl?.get("minute")?.updateValueAndValidity();
    durControl?.get("second")?.updateValueAndValidity();

    // console.log(durControl, repControl)
  }

  toggleRepDurMode(key: number | string): void {
    if (this.getRepDurMode(key) === "rep") {
      this.setRepDurMode(key, "dur");
    } else if (this.getRepDurMode(key) === "dur") {
      this.setRepDurMode(key, "rep");
    }
  }

  toggleLoadMode(key: number | string): void {
    if (this.getLoadMode(key) === "load") {
      this.setLoadMode(key, "corporalLoad");
    } else if (this.getLoadMode(key) === "corporalLoad") {
      this.setLoadMode(key, "load");
    }
  }

  getRepDurMode(key: number | string): RepDurModeValue | undefined {
    return this.repDurMode.get(key);
  }

  getLoadMode(key: number | string): LoadModeValue | undefined {
    return this.loadMode.get(key);
  }

  resetRepDurMode(): void {
    this.repDurMode.clear();
  }

  resetLoadMode(): void {
    this.loadMode.clear();
  }
}
