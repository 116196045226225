<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col"><h4 class="card-title">{{ avaliation ? (type==1 ? 'Edição Avaliação Modelo' : 'Edição Avaliação Padrão') : (type==1 ? 'Nova Avaliação Modelo' : 'Nova Avaliação Padrão')}}</h4></div>
      <div class="col"><button type="button" class="btn btn-outline-info float-right" placement="bottom" ngbTooltip="Clique para copiar a avaliação padrão da plataforma." *ngIf="!idAvaliacao && type==2" (click)="copyFromPlataforma()">Copiar da Plataforma</button></div>
    </div>
  </div>
  <div class="card-content">
    <div class="card-body">
      <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
        <aw-wizard-step [stepTitle]="'Avaliação'" [navigationSymbol]="{ symbol: '&#xf05a;', fontFamily: 'FontAwesome' }">
          <form #firstForm="ngForm" class="editForm" novalidate>
            <br/>
            <div class="form-row">
              <div class="col-12">
                <div class="form-group mb-2">
                  <label for="nome">Nome</label>
                  <input type="text" id="nome" class="form-control" name="company" [(ngModel)]="nomeAvaliacao" #inputAvaliacao="ngModel" required>
                  <small class="form-text text-muted danger" *ngIf="inputAvaliacao.errors?.required">Informe um nome para a avaliação</small>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-lg  mr-2 btn-danger" (click)="activeModal.close('Close click')">Cancelar</button>
                <button type="button" class="btn btn-lg gradient-mint" [disabled]="!firstForm.valid" awNextStep>Próximo Passo</button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step  [stepTitle]="'Perguntas'" [navigationSymbol]="{ symbol: '&#xf059;', fontFamily: 'FontAwesome' }">
          <form #workForm="ngForm" class="editForm" novalidate>
            <h4 class="head text-center mt-3">Informe as perguntas para o aluno</h4>
            <br/>
            <div  class="form-row mb-2">
              <div class="col-md-4 col-12">
                <div class="form-group mb-2">
                  <label for="basic-form-2">Pergunta</label>
                  <input type="text" id="basic-form-2" class="form-control" name="fname" [(ngModel)]="perguntaParaAdicionar">
                </div>
              </div>
              <div class="col-md-4 col-12">
                <label for="basic-form-1">Tipo de Resposta</label>
                <ng-select [items]="tipos" bindLabel="name" name="fname2"  placeholder="Selecionar tipo" id="basic-form-1" [(ngModel)]="tipoSelecionado"></ng-select>
              </div>
              <div class="col-md-3 col-12">
                <div class="form-group">
                  <label for="ttt" >Obrigatoriedade</label>
                  <ui-switch class="switchery switchery-warning"  id="ttt"   [checked]="isPerguntaObrigatoria" (change)="changeObrigatoriedadePergunta($event)"></ui-switch>
                </div>
              </div>
            </div>
            <div  class="form-row mb-2">
              <div class="col-md-6 col-6" *ngIf="idPerguntaEditada">
                <button class="btn btn-danger float-left" type="button" [disabled]="!perguntaParaAdicionar || !tipoSelecionado" (click)="cancelarEdicaoPergunta()">
                  Cancelar</button>
              </div>
              <div [ngClass]="idPerguntaEditada ? 'col-md-6 col-6' : 'col-md-12 col-12'">
                <button class="btn gradient-mint float-right" type="button" [disabled]="!perguntaParaAdicionar || !tipoSelecionado" (click)="idPerguntaEditada ? editarPergunta() : adicionarPergunta()">
                  {{idPerguntaEditada ? 'Atualizar' : 'Adicionar'}}</button>
              </div>
            </div>
            <div class="form-row">
              <ul class="list-group mb-2" [dragula]='"bag-one"' [(dragulaModel)]="listaPerguntas" style="width: 100%">
                <li  *ngFor="let pergunta of listaPerguntas" class="list-group-item draggable">
                  <div class="media">
                    <span class="dot align-middle mr-2">{{listaPerguntas.indexOf(pergunta)+1}}</span>
                    <div class="media-body">
                      <h5 class="mt-0">{{pergunta.question}}</h5>
                      <span>{{pergunta.labelType}}</span>
                    </div>
                    <div class="d-none d-md-block">
                      <i class="fa fa-reorder mr-2 text-muted" placement="top" ngbTooltip="Arraste para reordenar esta pergunta."></i>
                      <button  type="button" class="btn btn-outline-secondary btn-icon round mr-1 mb-1" placement="top" ngbTooltip="Duplicar pergunta." (click)="duplicarPergunta(pergunta)"><i class="fa fa-copy"></i></button>
                    </div>
                    <button  type="button" class="btn btn-outline-primary btn-icon round mr-1 mb-1" placement="top" [ngbTooltip]="'Editar pergunta.'" (click)="liberarEdicaoPergunta(pergunta)"><i class="fa fa-edit"></i></button>
                    <button  type="button" class="btn btn-outline-danger btn-icon round mr-1 mb-1" (click)="removerPergunta(pergunta)"><i class="fa fa-trash"></i></button>
                  </div>
                </li>
              </ul>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-lg btn-secondary mr-2"awPreviousStep>Voltar</button>
                <button type="button" class="btn btn-lg gradient-mint" [disabled]="!workForm.valid || listaPerguntas.length==0" awNextStep>Próximo Passo</button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
        <aw-wizard-step [stepTitle]="'Fotos/Arquivos'"  [navigationSymbol]="{ symbol: '&#xf1c6;', fontFamily: 'FontAwesome' }">
          <form #addressForm="ngForm" class="editForm" novalidate>
            <h4 class="head text-center mt-3">Requisite fotos ou arquivos para o aluno (opcional)</h4>
            <br/>
            <div  class="form-row mb-2">
              <div class="col-md-4 col-12">
                <div class="form-group mb-2">
                  <label for="basic-form-9">Requisição</label>
                  <input type="text" id="basic-form-9" class="form-control" name="fname" [(ngModel)]="requisicaoParaAdicionar" placeholder="Ex.: Por favor tire uma foto de seu corpo de frente">

                </div>
              </div>
              <div class="col-md-4 col-12">
                <label for="basic-form-2">Tipo de Resposta</label>
                <ng-select [items]="tiposFiles" bindLabel="name" name="fname2"  placeholder="Selecionar tipo de resposta" id="basic-types-2" [(ngModel)]="tipoFileSelecionado"></ng-select>

              </div>
              <div class="col-md-3 col-12">
                <div class="form-group">
                  <label for="t2" >Obrigatoriedade</label>
                  <ui-switch class="switchery switchery-warning"  id="t2"   [checked]="isRequisicaoObrigatoria" (change)="changeObrigatoriedadeRequisicao($event)"></ui-switch>
                </div>
              </div>
            </div>
            <div  class="form-row mb-2">
              <div class="col-md-6 col-6" *ngIf="idRequisicaoEditada">
                <button class="btn btn-danger float-left" type="button" [disabled]="!requisicaoParaAdicionar || !tipoFileSelecionado" (click)="cancelarEdicaoRequisicao()">
                  Cancelar</button>
              </div>
              <div [ngClass]="idRequisicaoEditada ? 'col-md-6 col-6' : 'col-md-12 col-12'">
                <button class="btn gradient-mint float-right" type="button" [disabled]="!requisicaoParaAdicionar || !tipoFileSelecionado" (click)="idRequisicaoEditada ? editarRequsicao() : adicionarRequisicao()">
                  {{idRequisicaoEditada ? 'Atualizar' : 'Adicionar'}}</button>
              </div>
            </div>
            <div class="form-row">
              <ul class="list-group mb-2" [dragula]='"bag-one"' [(dragulaModel)]="listaRequisicoes" style="width: 100%">
                <li  *ngFor="let req of listaRequisicoes" class="list-group-item draggable">
                  <div class="media">
                    <span class="dot align-middle mr-2">{{listaRequisicoes.indexOf(req)+1}}</span>
                    <div class="media-body">
                      <h5 class="mt-0">{{req.observationFile}}</h5>
                      <span>{{req.labelType}}</span>
                    </div>
                    <div class="d-none d-md-block">
                      <i class="fa fa-reorder mr-2 text-muted" placement="top" ngbTooltip="Arraste para reordenar esta requisição."></i>
                      <button  type="button" class="btn btn-outline-secondary btn-icon round mr-1 mb-1" placement="top" ngbTooltip="Duplicar requisição." (click)="duplicarRequisicao(req)"><i class="fa fa-copy"></i></button>
                    </div>
                    <button  type="button" class="btn btn-outline-primary btn-icon round mr-1 mb-1" placement="top" [ngbTooltip]="'Editar requisição.'" (click)="liberarEdicaoRequisicao(req)"><i class="fa fa-edit"></i></button>
                    <button type="button" class="btn btn-outline-danger btn-icon round mr-1 mb-1" (click)="removerRequisicao(req)"><i class="fa fa-trash"></i></button>
                  </div>
                </li>
              </ul>
            </div>
            <div class="form-row">
              <div class="col-12 text-center">
                <button type="button" class="btn btn-lg btn-secondary mr-2" awPreviousStep>Voltar</button>
                <button type="button" class="btn btn-lg gradient-mint" [disabled]="!workForm.valid" awNextStep (click)="submitAvaliacao()">{{this.idAvaliacao ? 'Atualizar' : 'Finalizar'}}</button>
              </div>
            </div>
          </form>
        </aw-wizard-step>
      </aw-wizard>
    </div>
  </div>
</div>
<ngx-spinner></ngx-spinner>
