import { HttpClient } from "@angular/common/http";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as cep from "cep-promise";
import * as moment from "moment";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { CookieService } from "ngx-cookie-service";
import { CountdownComponent } from "ngx-countdown";
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from "ngx-intl-tel-input";
import { NgxSpinnerService } from "ngx-spinner";
import { take } from "rxjs/operators";
import Swal from "sweetalert2";
import Api from "../helpers/api";
import App from "../helpers/app";
import Me from "../helpers/me";
import { AuthService } from "../shared/auth/auth.service";
declare var Iugu: any;
declare let fbq: Function;
declare let gtag: Function;

type HoveredBloonType = "course" | "ment" | "months" | "trainings" | null;

@Component({
  selector: "app-checkout-mpd",
  templateUrl: "./checkout-mpd.component.html",
  styleUrls: ["./checkout-mpd.component.scss"],
})
export class CheckoutMpdComponent implements OnInit {
  apiCall = this.api.new().silent();
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  hidecupom = true;
  @ViewChild("cd", { static: false }) private countdown: CountdownComponent;
  planos = [
    {
      id: "C3F2B1576C9843318029E7E3C4CAE196",
      identifier: "meu_personal_digital_silver_treino",
      name: "Plano Silver (Treino)",
      price: "R$ 97,00  por mês",
      priceAlert: "R$ 97,00",
      parcelas: 1,
      pfb: 97.00,
      order: 0,
      paymentMethod: "credit_card"
    },
    {
      id: "862A29AA69034FA8831A39165D8CFEDE",
      identifier: "meu_personal_digital_silver_alimentar",
      name: "Plano Silver (Plano Alimentar)",
      price: "R$ 97,00  por mês",
      priceAlert: "R$ 97,00",
      parcelas: 1,
      pfb: 97.00,
      order: 0,
      paymentMethod: "credit_card"
    },
    {
      id: "EF5FC6CAC26E469BA0B28FED8961A031",
      identifier: "meu_personal_digital_gold",
      name: "Plano Gold",
      price: "R$ 167,00 por mês",
      priceAlert: "R$ 167,00",
      parcelas: 1,
      pfb: 167.00,   
      order: 1,
      paymentMethod: "credit_card"
    },
    {
      id: "AF5AFD4902084D358A54FFCD1536974F",
      identifier: "meu_personal_digital_diamond",
      name: "Meu Personal Digital Diamond",
      price: "R$ 147,00 por mês durante 3 meses",
      priceAlert: "R$ 147,00",
      parcelas: 3,
      pfb: 441.00,
      order: 2,
      paymentMethod: "credit_card"
    },
    {
      id: "C3F2B1576C9843318029E7E3C4CAE196",
      identifier: "meu_personal_digital_silver_treino",
      name: "Plano Silver (Treino)",
      price: "R$ 97,00  por mês",
      priceAlert: "R$ 97,00",
      parcelas: 1,
      pfb: 97.00,
      order: 0,
      paymentMethod: "bank_slip"
    },
    {
      id: "862A29AA69034FA8831A39165D8CFEDE",
      identifier: "meu_personal_digital_silver_alimentar",
      name: "Plano Silver (Plano Alimentar)",
      price: "R$ 97,00  por mês",
      priceAlert: "R$ 97,00",
      parcelas: 1,
      pfb: 97.00,
      order: 0,
      paymentMethod: "bank_slip"
    },
    {
      id: "EF5FC6CAC26E469BA0B28FED8961A031",
      identifier: "meu_personal_digital_gold",
      name: "Plano Gold",
      price: "R$ 167,00 por mês",
      priceAlert: "R$ 167,00",
      parcelas: 1,
      pfb: 167.00,   
      order: 1,
      paymentMethod: "bank_slip"
    },    
    {
      id: "AF5AFD4902084D358A54FFCD1536974F",
      identifier: "meu_personal_digital_diamond",
      name: "Meu Personal Digital Diamond",
      price: "R$ 441,00 a cada 3 meses",
      priceAlert: "R$ 441,00",
      parcelas: 3,
      pfb: 441.00,
      order: 2,
      paymentMethod: "bank_slip"
    }   
  ];
  planosFiltered = [];
  formas = [
    {
      id: "credit_card",
      name: "Cartão de Crédito",
    },
    {
      id: "bank_slip",
      name: "Boleto",
    },
    {
      id: "pix",
      name: "PIX",
    },
  ];
  formasFiltered = [];
  novoCartao = {
    number: null,
    cvv: null,
    date: null,
    name: null,
  };
  usuario = {
    nome: null,
    email: null,
    phone: null,
    senha: null,
  };

  usuario_email;
  perdeuFocoEmail = false;

  cupomDigitado;
  cupomRetornado;

  endereco = {
    cpf_cnpj: null,
    zip_code: null,
    number: null,
    street: null,
    city: null,
    state: null,
    district: null,
  };
  userToLogin = {
    email: null,
    senha: null,
  };
  user = null;
  planoParam = null;
  planoEscolhido = null;
  logged = false;
  formaEscolhida = null;
  erroCartao = null;
  utmSource = null;
  utmMedium = null;
  utmCampaing = null;
  utmContent = null;
  ipAddress = null;
  fbpCookie = null;
  userAgent = null;
  estados = [
    "AC",
    "AL",
    "AP",
    "AM",
    "BA",
    "CE",
    "DF",
    "ES",
    "GO",
    "MA",
    "MT",
    "MS",
    "MG",
    "PA",
    "PB",
    "PR",
    "PE",
    "PI",
    "RJ",
    "RN",
    "RS",
    "RO",
    "RR",
    "SC",
    "SP",
    "SE",
    "TO",
  ];
  eventID = null;
  promo1 = false;
  promo2 = false;
  promo3 = false;
  promo4 = false;
  promoParc1 = false;
  promoParc2 = false;
  promoParc3 = false;
  promoParc4 = false;
  promoParc5 = false;
  promoParc6 = false;
  promoParc10 = false;
  isPromoParc = false;
  newUser = false;
  acceptTerms = false;
  venceuDiasGratuitos = false;
  parcelado = false;
  activePromo = null;
  hoveredBonusBloon: HoveredBloonType = null;
  countdownTimeUnits: Array<[string, number]> = [
    ["Y", 1000 * 60 * 60 * 24 * 365], // years
    ["M", 1000 * 60 * 60 * 24 * 30], // months
    ["D", 1000 * 60 * 60 * 24], // days
    ["H", 1000 * 60 * 60], // hours
    ["m", 1000 * 60], // minutes
    ["s", 1000], // seconds
    ["S", 1], // million seconds
  ];
  configTimer = {
    leftTime: 0,
    formatDate: ({ date, formatStr }) => {
      let duration = Number(date || 0);

      return this.countdownTimeUnits.reduce((current, [name, unit]) => {
        if (current.indexOf(name) !== -1) {
          const v = Math.floor(duration / unit);
          duration -= v * unit;
          return current.replace(
            new RegExp(`${name}+`, "g"),
            (match: string) => {
              return v.toString().padStart(match.length, "0");
            }
          );
        }
        return current;
      }, formatStr);
    },
  };
  sellerId: any;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private me: Me,
    private app: App,
    private api: Api,
    public cookieService: CookieService,
    private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    Iugu.setAccountID("B0C64ACE25DF962FF353AA716E9B82CE");
    this.route.queryParams.subscribe((params) => {

      if (params["plano"]) {
        this.planoParam = params["plano"];
      }
   
      if (params["hidecupom"] && params["hidecupom"] == "true") {
        this.hidecupom = true;
      } else {
        this.hidecupom = false;
      }

      if (params["utm_source"]) {
        this.utmSource = params["utm_source"];
      }

      if (params["utm_medium"]) {
        this.utmMedium = params["utm_medium"];
      }

      if (params["utm_campaign"]) {
        this.utmCampaing = params["utm_campaign"];
      }

      if (params["utm_campaing"]) {
        this.utmCampaing = params["utm_campaing"];
      }

      if (params["utm_content"]) {
        this.utmContent = params["utm_content"];
      }
      
      if(params["sellerId"]){
        this.sellerId = params["sellerId"];
      }
     
    });
  }

  iniciaCheckoutFb() {
    this.generateEventID()
    fbq('init', '544083090982331')
    fbq('trackSingle', '544083090982331', 'PageView', {}, { eventID: this.eventID });
    this.sendFbEventMVP('PageView', null);
    fbq('trackSingle', '544083090982331', 'InitiateCheckout', {}, { eventID: this.eventID });
    this.sendFbEventMVP('InitiateCheckout', null);
  }

  async generateEventID() {
    const min = Math.ceil(1000000);
    const max = Math.floor(1000000000);
    this.eventID = "FBPL" + Math.floor(Math.random() * (max - min + 1)) + min;
  }


  async sendFbEventMVP(event, value) {
    const self = this

    this.ipAddress = await this.getIPAddress()

    this.getUserAgent()

    let body = {
      event: event,
      value: value ? value : null,
      fbpCookie: this.fbpCookie ? this.fbpCookie : null,
      ip: this.ipAddress ? this.ipAddress : null,
      userAgent: this.userAgent ? this.userAgent : null,
      eventID: this.eventID
    }

    self.apiCall.post('send/conversionMVP', body).subscribe();

  }


  generateRandomString() {
    let code = Math.random().toString(36).substr(2, 4).toUpperCase();

    return code;
  }

  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  async ngOnInit() {
    const self = this;
    self.cupomRetornado = null;

    this.fbpCookie = this.cookieService.get("_fbp");

    if (this.fbpCookie == "") {
      this.fbpCookie = null;
    }

    const user = await self.me.get().pipe(take(1)).toPromise();
    self.user = user;


    if (self.planoParam) {
      self.planoEscolhido = self.planos.find(
        (x) => x.identifier.toLowerCase() == self.planoParam.toLowerCase()
      );
    } else {
      self.planoEscolhido = self.planos.find(
        (x) => x.identifier.toLowerCase() == "meu_personal_digital_diamond"
      );
    }
    this.formasFiltered = this.formas;
    this.formaEscolhida = this.formas.find((x) => x.id == "credit_card");
    this.planosFiltered = this.planos.filter(
      (x) => x.paymentMethod == "credit_card" 
    );

    self.planosFiltered = self.planosFiltered.sort((a, b) => a.order - b.order);
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });


    if (self.user) {

      self.usuario_email = self.user.email;
      await self.verificaEmail();
      self.perdeuFocoEmail = true;
      self.userToLogin.senha = JSON.parse(JSON.stringify(user)).password;
      await self.login();
    }
    
    await self.spinner.hide();

      self.iniciaCheckoutFb();


    this.planosFiltered = this.planosFiltered.sort((a, b) => a.order - b.order);
  }

  ngAfterViewInit() {
    const self = this;
    console.log("planoParam", self.planoParam);
    if (self.planoParam) {
      self.planoEscolhido = self.planos.find(
        (x) => x.identifier.toLowerCase() == self.planoParam.toLowerCase()
      );
    } else {
      self.planoEscolhido = self.planos.find(
        (x) => x.identifier.toLowerCase() == "plano_3"
      );
    }
    console.log("planoEscolhido", self.planoEscolhido);
    self.formaEscolhida = self.formas.find((x) => x.id == "credit_card");
  }
  isEmailInvalido = false;
  isEmailCadastrado = false;
  async verificaEmail() {
    const self = this;
    self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    self.reinicializaVariaveis();
    self.usuario.email = self.usuario_email;
    if (self.usuario_email) {
      const verificacao = await self.apiCall
        .get("seller/me/verificacao-email/" + self.usuario_email)
        .pipe(take(1))
        .toPromise();
      if (verificacao.return && verificacao.return.existente == true) {
        self.isEmailCadastrado = true;
        self.userToLogin.email = self.usuario_email;
      } else {
        self.usuario.email = self.usuario_email;
      }
      if (
        !self.validateEmail(self.usuario_email) ||
        self.usuario_email.includes(".com.com")
      ) {
        self.isEmailInvalido = true;
      }
    }
    self.spinner.hide();
  }

  cupomfbprice = null;

  reinicializaVariaveis() {
    const self = this;
    self.user = null;
    self.loginInvalido = false;
    self.userToLogin = {
      email: null,
      senha: null,
    };
    self.usuario = {
      nome: null,
      email: null,
      phone: null,
      senha: null,
    };
    self.isEmailCadastrado = false;
    self.isEmailInvalido = false;
  }
  priceToShow() {
    const self = this;

    if (self.cupomRetornado) {
      let retorno = "";
      if (self.planoEscolhido.identifier.toLowerCase().includes("boleto")) {
        if (self.cupomRetornado.percentageFirstMonth != null) {
          let aux =
            self.planoEscolhido.pfb -
            (self.planoEscolhido.pfbaux *
              self.cupomRetornado.percentageFirstMonth) /
              100;
          aux = Math.round((aux + Number.EPSILON) * 100) / 100;
          self.cupomfbprice = aux;
          if (aux != null) {
            retorno =
              "R$ " +
              aux.toFixed(2).toString().replace(".", ",") +
              self.planoEscolhido.labelaux;
          }
        } else if (self.cupomRetornado.priceFirstMonth != null) {
          let aux =
            self.planoEscolhido.pfb -
            self.planoEscolhido.pfbaux +
            self.cupomRetornado.priceFirstMonth;
          aux = Math.round((aux + Number.EPSILON) * 100) / 100;
          self.cupomfbprice = aux;
          if (aux != null) {
            retorno =
              "R$ " +
              aux.toFixed(2).toString().replace(".", ",") +
              self.planoEscolhido.labelaux;
          }
        }
      } else {
        if (self.cupomRetornado.percentageFirstMonth != null) {
          let aux =
            self.planoEscolhido.pfb -
            (self.planoEscolhido.pfb *
              self.cupomRetornado.percentageFirstMonth) /
              100;
          aux = Math.round((aux + Number.EPSILON) * 100) / 100;
          self.cupomfbprice = aux;
          if (aux != null) {
            retorno =
              "R$ " +
              aux.toFixed(2).toString().replace(".", ",") +
              " no primeiro mês";
          }
        } else if (self.cupomRetornado.priceFirstMonth != null) {
          self.cupomfbprice = self.cupomRetornado.priceFirstMonth;
          retorno =
            "R$ " +
            self.cupomRetornado.priceFirstMonth
              .toFixed(2)
              .toString()
              .replace(".", ",") +
            " no primeiro mês";
        }
      }
      return retorno;
    } else if (
      self.planoEscolhido &&
      self.activePromo &&
      self.activePromo == 1
    ) {
      return self.planoEscolhido.price_p1;
    } else if (
      self.planoEscolhido &&
      self.activePromo &&
      self.activePromo == 2
    ) {
      return self.planoEscolhido.price_p2;
    } else if (
      self.planoEscolhido &&
      self.activePromo &&
      self.activePromo == 3
    ) {
      return self.planoEscolhido.price_p3;
    } else if (
      self.planoEscolhido &&
      self.activePromo &&
      self.activePromo == 4
    ) {
      return self.planoEscolhido.price_p4;
    } else {
      return "";
    }
  }
  isCupomInvalido = false;


  formatPreco(plano) {
    return (plano.prices[0].value_cents / 100).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  loginInvalido = false;

  async login() {
    const self = this;
    self.loginInvalido = false;
    if (self.userToLogin.email && self.userToLogin.senha) {
      self.usuario.senha = self.userToLogin.senha;
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });

      await localStorage.setItem('user', JSON.stringify({email: self.userToLogin.email, password: self.userToLogin.senha}));

      const login = await self.apiCall
      .get('customer/me').pipe(take(1)).toPromise();

      console.log(login)

      if (login) {
        this.user = login.return;
       
        self.spinner.hide();
        
          await self.apiCall
            .get("seller/me/initiatecheckout")
            .pipe(take(1))
            .toPromise();
        
      } else {
        self.spinner.hide();
        self.loginInvalido = true;
      }
    }
  }
  getCreditCard() {
    if (
      this.novoCartao.number &&
      Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number)
    ) {
      return Iugu.utils.getBrandByCreditCardNumber(this.novoCartao.number);
    } else {
      return "";
    }
  }

  changePlano() {
    if (
      this.planoEscolhido &&
      this.formaEscolhida &&
      this.formaEscolhida.id == "credit_card"
    ) {
      if (this.planoEscolhido.paymentMethod != "credit_card") {
        this.planoEscolhido = this.planos.find(
          (x) => x.identifier == this.planoEscolhido.identifier && x.paymentMethod == 'credit_card'
        );
      }
    }
    if (
      this.planoEscolhido &&
      this.formaEscolhida &&
      (this.formaEscolhida.id == "bank_slip" || this.formaEscolhida.id == "pix")
    ) {
      if (this.planoEscolhido.paymentMethod == "credit_card") {
        this.planoEscolhido = this.planos.find(
          (x) => x.identifier == this.planoEscolhido.identifier && x.paymentMethod == 'bank_slip'
        );
      }
    }
  }

  changeForma() {
    const self = this;

    if (
      self.formaEscolhida &&
      (self.formaEscolhida.id == "bank_slip" || self.formaEscolhida.id == "pix")
    ) {
      self.planosFiltered = self.planos.filter((x) =>
        x.paymentMethod.toLowerCase().includes("bank_slip")
      );
    }else {
      self.planosFiltered = self.planos.filter(
        (x) => !x.paymentMethod.toLowerCase().includes("bank_slip")
      );
    }

    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      self.formaEscolhida.id == "credit_card"
    ) {

      if (self.planoEscolhido.paymentMethod != 'credit_card') {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == self.planoEscolhido.identifier && x.paymentMethod == 'credit_card'
        );
      }
    }

    if (
      self.planoEscolhido &&
      self.formaEscolhida &&
      (self.formaEscolhida.id == "bank_slip" || self.formaEscolhida.id == "pix")
    ) {

      if (self.planoEscolhido.paymentMethod != 'bank_slip') {
        self.planoEscolhido = self.planos.find(
          (x) => x.identifier == self.planoEscolhido.identifier && x.paymentMethod == 'bank_slip'
        );
      }
    }

    self.planosFiltered = self.planosFiltered.sort((a, b) => a.order - b.order);
    console.log("Plano escolhido", self.planoEscolhido);

  }


  consultaCep() {
    const self = this;
    if (self.endereco.zip_code) {
      self.spinner.show(undefined, {
        type: "ball-triangle-path",
        size: "medium",
        bdColor: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
        fullScreen: true,
      });
      cep(self.endereco.zip_code)
        .then((local) => {
          self.spinner.hide();
          self.endereco.street = local.street;
          self.endereco.district = local.neighborhood;
          self.endereco.state = local.state;
          self.endereco.city = local.city;
        })
        .catch((erro) => {
          self.spinner.hide();
        });
    }
  }

  cartaoCadastrado = null;
  async assinar() {
    const self = this;
    if (
      !self.planoEscolhido ||
      !self.planoEscolhido.identifier ||
      !self.formaEscolhida ||
      !self.formaEscolhida.id
    ) {
      return;
    }
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });

    let data = null;

    let captchaToken = null;

    let secret = this.generateRandomString();

    captchaToken = await this.generateCaptchaToken(secret);

    secret = btoa(secret);

    let endpoint = 'mpd/subscription'

 
      data = await self.apiCall
        .post(endpoint, {
          user: this.user,
          name: !this.user ? self.usuario.nome : null,
          email: !this.user ? self.usuario.email : null,
          password: !this.user ? self.usuario.senha : null,
          phone: !this.user ? self.usuario.phone.e164Number : null,
          plan_identifier: self.planoEscolhido.identifier,
          parcelas: self.planoEscolhido.parcelas,
          sellerId: this.sellerId,
          tipo_pagamento: self.formaEscolhida.id,         
          endereco: self.formaEscolhida.id == "bank_slip" ? self.endereco : null,
          cartao: self.cartaoCadastrado ? self.cartaoCadastrado : null,
          utmSourceCheckout: this.utmSource ? this.utmSource : null,
          utmMediumCheckout: this.utmMedium ? this.utmMedium : null,
          utmCampaingCheckout: this.utmCampaing ? this.utmCampaing : null,
          utmContentCheckout: this.utmContent ? this.utmContent : null,
          captchaToken: captchaToken,
          key: secret,
        })
        .pipe(take(1))
        .toPromise();
   
    if (data.success) {
      let valor = this.planoEscolhido.pfb;

      this.generateEventID()
        fbq('trackSingle', '544083090982331', 'Purchase', { value: valor.toFixed(2), currency: 'BRL' }, { eventID: this.eventID });
        this.sendFbEventMVP('Purchase', valor.toFixed(2));
     
    }
    setTimeout(async () => {

      if (data.success) {

        await self.app.alert(
          "Tudo certo!",
          "Plano assinado com sucesso!",
          "success"
        );

        if(data.return.redirectUrl){
          window.location.href = data.return.redirectUrl;
        }else if(data.return.subscriptionPaid){
          window.location.href = "https://opersonaldigital.com.br/mpd-m-obrigado/";
        }
        
      } else {
        if (data.message && JSON.parse(JSON.stringify(data))) {
          let retorno = null;
          try {
            retorno = JSON.parse(data.message);
          } catch (e) {
            retorno = JSON.parse(JSON.stringify(data));
          }

          if (retorno.lr == "51") {
            Swal.fire({
              title: "Não foi possivel realizar a sua assinatura",
              text: "Parece que o cartão que você cadastrou está sem limite disponível para realizar a compra, você possui outro cartão com limite disponível?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#00b214",
              cancelButtonColor: "#d33",
              confirmButtonText: "Sim, Possuo!",
              cancelButtonText: "Não Possuo Outro Cartão",
            }).then(async (result) => {
              if (result.isConfirmed) {
                self.ngOnInit();
              } else if (result.isDismissed) {
                await self.app.alert(
                  "Que pena!",
                  "Não tem problema, você ainda pode assinar a plataforma de forma recorrente (com cobranças mensais), porém não receberá os bônus, para realizar uma assinatura recorrente, clique no botão abaixo",
                  "success"
                );
                window.location.href =
                  "https://app.opersonaldigital.com.br/pages/checkout/";
              }
            });
          } else {
            await self.app.alert("Ops :(", retorno.message, "error");
          }
        } else {
          await self.app.alert("Ops :(", data.message, "error");
        }
      }
    }, 3000);
    await self.spinner.hide();
  }
  disableAssinar() {
    if (
      !this.planoEscolhido ||
      !this.formaEscolhida ||
      ((!this.usuario.email ||
        // || !this.usuario.nome
        !this.usuario.senha ||
        !this.usuario.phone) &&
        !this.user) ||
      (this.formaEscolhida.id == "credit_card" &&
        (!this.novoCartao.number ||
          !this.novoCartao.cvv ||
          !this.novoCartao.date ||
          !this.novoCartao.name)) ||
      (this.formaEscolhida.id == "bank_slip" &&
        (!this.endereco.number ||
          !this.endereco.zip_code ||
          !this.endereco.state ||
          !this.endereco.street ||
          !this.endereco.district ||
          !this.endereco.city ||
          !this.endereco.cpf_cnpj))
    ) {
      return true;
    } else {
      console.log("this.usuario", this.usuario);
      console.log("this.user", this.user);
      return false;
    }
  }

  checkAssinar() {
    console.log(this.acceptTerms);
  }

  async cadastrarCartaoEAssinar() {
    const self = this;
    self.erroCartao = "";
    let name = "";
    let cvv = "";
    let date = "";
    let number = "";

    if (self.novoCartao.cvv) {
      cvv = self.novoCartao.cvv.replace(/\s/g, "");
    }
    name = self.novoCartao.name;

    if (self.novoCartao.number) {
      number = self.novoCartao.number.replace(/\s/g, "");
    }
    if (self.novoCartao.date) {
      date = self.novoCartao.date.replace(/\s/g, "");
    }

    if (!Iugu.utils.validateCreditCardNumber(number)) {
      self.erroCartao = "Número de cartão inválido";
      return;
    }
    if (!Iugu.utils.validateExpirationString(date)) {
      self.erroCartao = "Data de validade inválida";
      return;
    }
    if (
      !Iugu.utils.getBrandByCreditCardNumber(number) ||
      !Iugu.utils.validateCVV(
        cvv,
        Iugu.utils.getBrandByCreditCardNumber(number)
      )
    ) {
      self.erroCartao = "Data de validade inválida";
      return;
    }

    let cc = Iugu.CreditCard(
      number,
      date.substring(0, 2),
      date.substring(3, 7),
      name.split(" ")[0],
      name.substring(name.indexOf(" ") + 1),
      cvv
    );
    await self.spinner.show(undefined, {
      type: "ball-triangle-path",
      size: "medium",
      bdColor: "rgba(0, 0, 0, 0.8)",
      color: "#fff",
      fullScreen: true,
    });
    Iugu.createPaymentToken(cc, async function (response) {
      if (response.errors) {
        await self.spinner.hide();
        self.erroCartao = "Erro ao validar seu cartão.";
        return;
      } else {
        self.cartaoCadastrado = response.id;
        await self.spinner.hide();
        await self.assinar();
      }
    });
  }

  async sendFbEvent(event, value, logged) {
    const self = this;

    this.ipAddress = await this.getIPAddress();

    this.getUserAgent();

    let body = {
      event: event,
      value: value ? value : null,
      fbpCookie: this.fbpCookie ? this.fbpCookie : null,
      ip: this.ipAddress ? this.ipAddress : null,
      userAgent: this.userAgent ? this.userAgent : null,
      eventID: this.eventID,
    };

    if (logged == true) {
      self.apiCall.post("send/conversionLogged", body).subscribe();
    } else {
      self.apiCall.post("send/conversion", body).subscribe();
    }
  }

  async getIPAddress() {
    const self = this;
    return new Promise<void>((resolve, reject) => {
      self.http
        .get("https://api.ipify.org/?format=json")
        .subscribe((res: any) => {
          resolve(res.ip);
        });
    });
  }

  getUserAgent() {
    this.userAgent = window.navigator.userAgent;
  }

  async generateCaptchaToken(secret) {
    try {
      const token = await this.recaptchaV3Service
        .execute("purchasePersonal" + secret)
        .pipe(take(1))
        .toPromise();
      return token;
    } catch (error) {
      return null;
    }
  }

  onMouseEnterBloon(bloon: HoveredBloonType) {
    if (!bloon) {
      return;
    } else if (this.hoveredBonusBloon == bloon) {
      return;
    }
    this.hoveredBonusBloon = bloon;
  }

  onMouseLeaveBloon() {
    this.hoveredBonusBloon = null;
  }
}
