import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-order-bump',
  templateUrl: './order-bump.component.html',
  styleUrls: ['./order-bump.component.scss']
})
export class OrderBumpComponent {
  @Input() title: string = 'Imersão Consultoria On-line Express - 08/12 - 15h00 às 17h00';
  @Input() description: string = 'Uma imersão prática, ao vivo e online, onde você vai descobrir  o passo a passo para criar e entregar sua consultoria no app Personal Digital, definir preços, fidelizar clientes e conquistar suas primeiras 10 a 20 vendas.';
  @Input() price: string = 'R$ 49,90';
  @Input() imageUrl: string = 'path/to/image.jpg';
  
  @Output() selectionChange = new EventEmitter<boolean>();

  isChecked: boolean = false;

  onCheckboxChange() {
    this.selectionChange.emit(this.isChecked);
  }
}
