<div class="row text-left">
    <div class="col-sm-12">
      <div class="d-flex justify-content-between mb-0">
        <h1 class="text-bold-300" style="padding: 1.5rem;">Acompanhar Contestações</h1>
      </div>
    </div>
  </div>
  <div class="row mb-2 mt-2" *ngIf="chargebacks.length > 0">
    <div class="col">
      <div class="card-content">
        <div class="card-body p-0">
          <div class="table text-center m-0">
            <table class="table-striped" style="width: 100%;">
              <thead>
                <tr>
                  <th>Id Chargeback</th>
                  <th>Id Invoice</th>
                  <th>Id Account</th>
                  <th>Data Expiração</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let s of chargebacks| slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                  <td>{{s.chargebackId}}</td>
                  <td>{{s.invoiceId}}</td>
                  <td>{{s.accountId}}</td>
                  <td>{{formatDate(s.expiresAt)}}</td>
                  <td>
                    <div class="dropdown btn-group-sm mr-1 mb-1" ngbDropdown>
                      <button type="button" class="btn btn-outline-warning btn-sm dropdown-toggle" ngbDropdownToggle>
                        <i class="ft-more-vertical"></i>
                      </button>
                      <div ngbDropdownMenu class="dropdown-menu" role="menu">
                        <a class="dropdown-item" (click)="selecionarChargeback(s, chargebackData)">Ver Arquivos</a>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="form-row" style="margin: 5px">
          <div class="d-flex justify-content-center" style="margin-left: auto; margin-right: auto;">
            <ngb-pagination style="margin-top: 10px" *ngIf="chargebacks.length>0" [(page)]="page" [pageSize]="pageSize"
              [maxSize]="2" [rotate]="true" [boundaryLinks]="true" size="sm" [collectionSize]="chargebacks.length">
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="chargebacks.length == 0">
    <div class="col">
      <div class="card-content">
        <div class="card-body p-0 mt-2">
          <ul class="list-group">
            <li class="list-group-item">
              <div class="media">
                <div class="media-body">
                  <h5 class="mt-0">Nenhuma contestação pendente.</h5>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner>
  
  <ng-template #chargebackData let-modal>
    <div class="card">
      <div class="card-header">
        <h2 class="card-title">Visualização da Contestação</h2>
      </div>
      <div class="card-content">
        <div class="card-body">
          <h6>Dados da Contestação</h6><br>
          <div class="form-row">                      
              <div class="col-12">
                <b>Id Chargeback:</b> {{chargebackSelected.chargebackId ? chargebackSelected.chargebackId : "---"}}                
              </div>
              <div class="col-12">
                <b>Id Invoice:</b> {{chargebackSelected.invoiceId ? chargebackSelected.invoiceId : "---"}}                
              </div>
              </div>
              <div class="form-row">
              <div class="col-8">
                <b>Id Account:</b> {{chargebackSelected.accountId ? chargebackSelected.accountId : "---"}}                
              </div>
              <div class="col-4">
                <b>Data Expiração:</b> {{chargebackSelected.expiresAt ? formatDate(chargebackSelected.expiresAt) : "---"}}                
              </div>
            </div>
            <hr>
            <div class="form-row" *ngIf="chargebackSelected.Files.length > 0">
              <div class="col-12"><h4>Imagens Enviadas</h4></div>
              <div class="col-md-4 col-6" *ngFor="let f of chargebackSelected.Files">
                <img [src]="f.file" style="width:100%">
              </div>
            </div>
            <div class="form-row mt-4">
              <div class="col-6 text-center">
                <button type="button" class="btn btn-lg btn-block btn-danger"
                  (click)="modal.close('Close click')">Cancelar</button>
              </div>
              <div class="col-6 text-center">
                <button type="button" class="btn btn-lg gradient-mint btn-block" (click)="confirmarContestacao(chargebackSelected)"
                  >Confirmar Contestação</button>
              </div>
            </div>
       
        </div>
      </div>
    </div>
  </ng-template>
  <ngx-spinner></ngx-spinner>